import axios from "axios";
import SnackbarUtils from "utils/SnackbarUtils";

const listOfCompany = window.config.arrCompany;
var { apiUrl } = window.config;

let url = new URL(window.location.href);
let callFromDesktopApp = url.searchParams.get("ShowOnlyDashboard") === "true";
let desktopUrlApi = url.searchParams.get("urlApi");
let tkFromDesktopApp = url.searchParams.get("tk");

const axiosAuth = axios.create({
  baseURL: !callFromDesktopApp ? window.config.apiUrl : desktopUrlApi,
});

axiosAuth.interceptors.request.use(
  async (config) => {
    config.baseURL = await getBaseUrl();
    return config;
  },
  (error) => Promise.reject(error)
);

export async function getBaseUrl() {
  let url = new URL(window.location.href);
  let tkFromDesktopApp = url.searchParams.get("tk");
  let desktopUrlApi = url.searchParams.get("urlApi");
  if (tkFromDesktopApp) {
    return desktopUrlApi;
  } else {
    let aToken = localStorage.getItem("adminToken");
    if (aToken) {
      let obj = listOfCompany.find((i) => i.adminToken === aToken);
      apiUrl = obj.apiUrl;
      return apiUrl;
    } else {
      apiUrl = window.config.apiUrl;
      return apiUrl;
    }
  }
}

if (!callFromDesktopApp) {
  axiosAuth.defaults.headers.common["Authorization"] = localStorage.getItem("AccessToken");
} else {
  axiosAuth.defaults.headers.common["Authorization"] = tkFromDesktopApp;
}
//set true switch to showsnackbar
let showSnack = false;

const errorResponseHandler = (error) => {
  if (error.message === "Network Error") {
    let url = new URL(window.location.origin);
    if (url) {
      let callFromDesktopApp = url.searchParams.get("ShowOnlyDashboard") === "true";
      if (callFromDesktopApp) {
        return false;
      } else {
        if (!showSnack) {
          // SnackbarUtils.error("Session Expired", function () {
          //   let url = new URL(window.location.origin);
          //   if (window.location.pathname !== "/") {
          //     url = url + window.location.pathname;
          //   }
          //   window.location.replace(url + "#/login");
          // });
          window.location.replace(`${url}#/login`);
          showSnack = true;
        }
        return error;
      }
    }
  }
  if (error) {
    switch (error.response?.status) {
      case 303:
        if (error.response.data.Code === -100) {
          SnackbarUtils.warning(error.response.data.UserMessage);
        }
        if (error.response.data.Code === -200) {
          SnackbarUtils.warning(error.response.data.UserMessage);
        }
        if (error.response.data.Code === -300) {
          SnackbarUtils.warning(error.response.data.UserMessage);
        }
        if (error.response.data.Code === -400) {
          SnackbarUtils.warning(error.response.data.UserMessage);
        }
        // if (error.response.data.Code === -500) {
        //   SnackbarUtils.warning(error.response.data.UserMessage);
        // }
        if (error.response.data.Code === -1000) {
          SnackbarUtils.warning(error.response.data.UserMessage);
        }
        break;
      case 400:
        SnackbarUtils.error(error.response.data);
        break;
      case 403:
        SnackbarUtils.error(`${error.response?.data.UserMessage}<br/>${error.response?.data.InternalMessage}`);
        break;
      case 500:
        if (error.response?.data?.Message) {
          return error;
        }
        if (error.response?.data?.UserMessage) {
          if (!showSnack) {
            SnackbarUtils.error(error.response?.data.UserMessage ?? error.response?.data.ExceptionMessage);
          }
        } else {
          console.error(`No response from ${error.config.url}`);
        }
        break;
      default:
        let url = new URL(window.location.href);
        if (url) {
          let callFromDesktopApp = url.searchParams.get("ShowOnlyDashboard") === "true";
          if (callFromDesktopApp) {
            return false;
          } else {
            if (!showSnack) {
              if (error.response?.data?.UserMessage) {
                SnackbarUtils.error(error.response.data.Message);
              } else {
                if (error.code === "ECONNABORTED") {
                  SnackbarUtils.error(error.message);
                  return error;
                }
                let url = new URL(window.location.origin);
                window.location.replace(`${url}#/login`);
                showSnack = true;
              }
            }
            return error;
          }
        }
        // Something happened in setting up the request that triggered an Error
        return error;
    }
  }

  return error.response;
};

// Add a response interceptor
axiosAuth.interceptors.response.use((response) => response, errorResponseHandler);

export default axiosAuth;
