/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { useTranslate } from "react-admin";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Tooltip, Button, Divider } from "@material-ui/core";
import MeterialtableTranslate from "components/MeterialtableTranslate";
import NumberFormatInput from "components/NumberFormatInput";
import { getInvoiceNewUnpaid } from "services/accountPayable";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 6,
  },
  numberBox: {
    textAlign: "right",
    fontSize: 14,
    //paddingRight: 4,
    margin: 0,
    width: 120,
  },
  numberBoxError: {
    border: "2px solid red !important",
    "&:hover, &:focus": {
      outline: "none",
      outlineOffset: 0,
      border: "2px solid red !important",
    },
  },
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  statusNormal: {
    backgroundColor: "#2196f3",
    color: "white",
    cursor: "pointer",
  },
}));

const PopupSettlement = ({
  code,
  unselectInvoice,
  paymentDate,
  paymentCurCode,
  paymentRate,
  children,
  open,
  save,
  cancel,
  initialValues,
  maxWidth = "md",
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { settingAll, DateToString, NumberFormat, ToNumber } = useContext(GblContext);
  const { SettingSystem } = settingAll;
  const [dataPopup, setDataPopup] = useStateWithCallbackLazy([]);
  const [originalData, setOriginalData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const theme = useTheme();
  const isBaseCurrency = SettingSystem.DefaultCurrencyCode === paymentCurCode;
  const fetchInvUppaidByVnCode = useCallback(
    async (mounted) => {
      //never select
      if (unselectInvoice.length === 0) {
        const paymentDateString = new Date(paymentDate);
        const dd = paymentDateString.getDate();
        const MM = paymentDateString.getMonth() + 1;
        const yy = paymentDateString.getFullYear();
        let unpaidList = await getInvoiceNewUnpaid(code, `${yy}-${MM < 10 ? `0${MM}` : MM}-${dd < 10 ? `0${dd}` : dd}`);
        if (unpaidList.length === 0) {
          unpaidList = [];
        }
        unpaidList = unpaidList.filter((el) => {
          el.InvhInvDate = el.InvhInvDate.includes("T") ? DateToString(el.InvhInvDate) : el.InvhInvDate;
          el.InvhDueDate = el.InvhDueDate.includes("T") ? DateToString(el.InvhDueDate) : el.InvhDueDate;
          return el;
        });

        if (isBaseCurrency) {
          unpaidList.forEach((el) => (el.Unpaid = ToNumber(NumberFormat(el.Unpaid * el.CurRate))));
        } else {
          unpaidList.forEach((el) => (el.Unpaid = ToNumber(NumberFormat(el.Unpaid))));
        }

        //keepOld Data before loop
        const original = _.cloneDeep(initialValues);
        setOriginalData(original);
        const newInit = initialValues.filter((element) => {
          const isHaveUnpaid = unpaidList.find((u) => u.InvhSeq === element.InvhSeq && u.InvdSeq === element.InvdSeq);
          const isHaveUnpaidIndex = unpaidList.findIndex(
            (u) => u.InvhSeq === element.InvhSeq && u.InvdSeq === element.InvdSeq
          );
          //invoice ถูกจ่ายไปหมดแล้ว
          if (!isHaveUnpaid) {
            element.IsPaid = true;
            element.tableData = { checked: true };
            // element.Paid = element.PaidAmt;
            // element.PaidBAmt = element.Paid * element.CurRate;
            element.Unpaid = ToNumber(element.PaidBAmt);
            if (element.Info) {
              element.InvhInvNo = element.Info.InvoiceHeader?.InvhInvNo;
              element.InvhInvDate = element.Info.InvoiceHeader?.InvhInvDate?.includes("T")
                ? element.Info.InvoiceHeader?.InvhInvDate
                  ? DateToString(element.Info.InvoiceHeader?.InvhInvDate)
                  : null
                : element.Info.InvoiceHeader?.InvhInvDate;
              element.InvhDueDate = element.Info.InvoiceHeader?.InvhDueDate?.includes("T")
                ? element.Info.InvoiceHeader?.InvhDueDate
                  ? DateToString(element.Info.InvoiceHeader?.InvhDueDate)
                  : null
                : element.Info.InvoiceHeader?.InvhDueDate;
              element.NetBaseAmt = element.Info.InvoiceDetail?.NetBaseAmt;
              element.InvdTaxA1 = element.Info.InvoiceDetail?.InvdTaxA1;
              element.InvdTaxA2 = element.Info.InvoiceDetail?.InvdTaxA2;
              element.InvdTaxC1 = element.Info.InvoiceDetail?.InvdTaxC1;
              element.InvdTaxC2 = element.Info.InvoiceDetail?.InvdTaxC2;
              element.InvdDesc = element.Info.InvoiceDetail?.InvdDesc;
              element.TotalPrice = element.Info.InvoiceDetail?.TotalPrice;
            }
          }
          // invoice ถูกจ่ายไปบางส่วน
          else {
            element["IsPaid"] = true;
            element.tableData = { checked: true };
            if (element.Info) {
              const newUnPaid = ToNumber(element.Info.Unpaid) + ToNumber(element.Paid);
              element.InvhInvNo = element.Info.InvoiceHeader?.InvhInvNo;
              element.InvhInvDate = element.Info.InvoiceHeader?.InvhInvDate.includes("T")
                ? element.Info.InvoiceHeader?.InvhInvDate
                  ? DateToString(element.Info.InvoiceHeader?.InvhInvDate)
                  : null
                : element.Info.InvoiceHeader?.InvhInvDate;
              element.InvhDueDate = element.Info.InvoiceHeader?.InvhDueDate.includes("T")
                ? element.Info.InvoiceHeader?.InvhDueDate
                  ? DateToString(element.Info.InvoiceHeader?.InvhDueDate)
                  : null
                : element.Info.InvoiceHeader?.InvhDueDate;
              element.NetBaseAmt = element.Info.InvoiceDetail?.NetBaseAmt;
              element.InvdTaxA1 = element.Info.InvoiceDetail?.InvdTaxA1;
              element.InvdTaxA2 = element.Info.InvoiceDetail?.InvdTaxA2;
              element.InvdTaxC1 = element.Info.InvoiceDetail?.InvdTaxC1;
              element.InvdTaxC2 = element.Info.InvoiceDetail?.InvdTaxC2;
              element.InvdDesc = element.Info.InvoiceDetail?.InvdDesc;
              element.TotalPrice = element.Info.InvoiceDetail?.TotalPrice;

              if (isBaseCurrency) {
                element.Unpaid =
                  newUnPaid !== undefined
                    ? ToNumber(newUnPaid)
                    : ToNumber(element.Info.Unpaid) + ToNumber(element.Paid);
              } else {
                element.Unpaid =
                  newUnPaid !== undefined
                    ? ToNumber(newUnPaid) + ToNumber(element.Paid)
                    : ToNumber(element.Info.InvoiceDetail.UnPaid) + ToNumber(element.Paid);
              }
              //element.Unpaid = NumberFormat(element.Unpaid);
            } else {
              const newUnPaid = ToNumber(element.NewUnPaid) + ToNumber(element.Paid);
              if (isBaseCurrency) {
                element.Unpaid =
                  newUnPaid !== undefined ? ToNumber(newUnPaid) : ToNumber(element.Unpaid) + ToNumber(element.Paid);
              } else {
                element.Unpaid =
                  newUnPaid !== undefined
                    ? ToNumber(newUnPaid) + ToNumber(element.Paid)
                    : ToNumber(element.Unpaid) + ToNumber(element.Paid);
              }
              //element.Unpaid = NumberFormat(element.Unpaid);
            }
            unpaidList.splice(isHaveUnpaidIndex, 1);
          }

          return element;
        });
        setDataPopup([...newInit, ...unpaidList]);
      } else {
        //keepOld Data before loop
        const original = _.cloneDeep(initialValues);
        const newDataPopup = _.cloneDeep([...initialValues, ...unselectInvoice]);
        setOriginalData(original);
        setDataPopup(newDataPopup);
      }

      if (mounted) {
        setLoading(false);
      }
    },
    [code]
  );

  useEffect(() => {
    let mounted = true;
    fetchInvUppaidByVnCode(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const select = () => {
    let error = false;
    dataPopup.forEach((item) => {
      if (isBaseCurrency) {
        if (ToNumber(item.PaidBAmt) === 0 && item.PaidBAmt) {
          SnackbarUtils.error(translate("ra.apInvoice.invoiceAmountWarnningZero"));
          error = true;
          return;
        }
        if (ToNumber(item.PaidBAmt) === 0) {
          item.IsPaid = false;
        } else {
          item.IsPaid = true;
          item.Amount = item.TotalPrice;
          const iPaid = ToNumber(item.PaidBAmt);
          const iUnpaid = ToNumber(item.Unpaid);
          if (iPaid > iUnpaid && iPaid > 0) {
            SnackbarUtils.error(translate("ra.apInvoice.invoiceAmountWarnning1"));
            error = true;
            return;
          } else if (iPaid > 0 && iUnpaid < 0) {
            SnackbarUtils.error(translate("ra.apInvoice.invoiceAmountWarnning1"));
            error = true;
            return;
          } else if (iPaid > iUnpaid && iUnpaid > 0) {
            SnackbarUtils.error(translate("ra.apInvoice.invoiceAmountWarnning1"));
            error = true;
            return;
          } else if (iPaid < iUnpaid && iUnpaid < 0) {
            SnackbarUtils.error(translate("ra.apInvoice.invoiceAmountWarnning1"));
            error = true;
            return;
          }
        }
        if (!item.Info) {
          item.Info = {
            ...item,
            InvoiceHeader: {
              InvhInvNo: item.InvhInvNo,
              InvhInvDate: item.InvhInvDate,
              InvhDueDate: item.InvhDueDate,
            },
            InvoiceDetail: {
              NetBaseAmt: item.NetBaseAmt,
              InvdTaxA1: item.InvdTaxA1,
              InvdTaxA2: item.InvdTaxA2,
              InvdTaxC1: item.InvdTaxC1,
              InvdTaxC2: item.InvdTaxC2,
              InvdDesc: item.InvdDesc,
              TotalPrice: item.TotalPrice,
            },
          };
        }
        item.Paid = NumberFormat(item.PaidBAmt / item.CurRate);
      } else {
        if (ToNumber(item.Paid) === 0 && item.Paid) {
          SnackbarUtils.error(translate("ra.apInvoice.invoiceAmountWarnningZero"));
          error = true;
          return;
        }
        if (ToNumber(item.Paid) === 0) {
          item.IsPaid = false;
        } else {
          item.IsPaid = true;
          item.Amount = item.TotalPrice;
          const iPaid = ToNumber(item.Paid);
          const iUnpaid = ToNumber(item.Unpaid);
          if (iPaid > iUnpaid && iPaid > 0) {
            SnackbarUtils.error(translate("ra.apInvoice.invoiceAmountWarnning1"));
            error = true;
            return;
          } else if (iPaid > 0 && iUnpaid < 0) {
            SnackbarUtils.error(translate("ra.apInvoice.invoiceAmountWarnning1"));
            error = true;
            return;
          } else if (iPaid > iUnpaid && iUnpaid > 0) {
            SnackbarUtils.error(translate("ra.apInvoice.invoiceAmountWarnning1"));
            error = true;
            return;
          } else if (iPaid < iUnpaid && iUnpaid < 0) {
            SnackbarUtils.error(translate("ra.apInvoice.invoiceAmountWarnning1"));
            error = true;
            return;
          }
        }
        if (!item.Info) {
          item.Info = {
            ...item,
            InvoiceHeader: {
              InvhInvNo: item.InvhInvNo,
              InvhInvDate: item.InvhInvDate,
              InvhDueDate: item.InvhDueDate,
            },
            InvoiceDetail: {
              NetBaseAmt: item.NetBaseAmt,
              InvdTaxA1: item.InvdTaxA1,
              InvdTaxA2: item.InvdTaxA2,
              InvdTaxC1: item.InvdTaxC1,
              InvdTaxC2: item.InvdTaxC2,
              InvdDesc: item.InvdDesc,
              TotalPrice: item.TotalPrice,
            },
          };
        }
        item.PaidBAmt = NumberFormat(item.Paid * item.CurRate);
      }
      item.Info.Unpaid = item.NewUnPaid === 0 ? 0 : item.NewUnPaid;
      //item.Unpaid = item.NewUnPaid === 0 ? 0 : item.NewUnPaid;
      item.NewUnPaid = item.NewUnPaid <= 0 ? 0 : item.NewUnPaid;
    });

    if (!error) {
      //update paid
      setDataPopup([...dataPopup]);
      const newData = Object.assign([], dataPopup);
      save(newData);
    }
  };

  const handleFocus = (e) => {
    e.target.select();
    setTimeout(function () {
      e.target.setSelectionRange(0, e.target.value.length);
    }, 0);
  };

  const columns = [
    {
      title: "Invoice No.",
      field: "InvhInvNo",
      sorting: true,
      render: (rowData) => (
        <Tooltip title={rowData.InvhInvNo}>
          <div
            style={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {rowData.InvhInvNo}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Invoice Date",
      field: "InvhInvDate",
      sorting: true,
    },
    {
      title: "Due Date",
      field: "InvhDueDate",
      sorting: true,
    },
    {
      title: "Description",
      field: "InvdDesc",
      sorting: true,
      render: (rowData) => (
        <div
          style={{
            width: 200,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {rowData.InvdDesc}
        </div>
      ),
    },
    { title: "Currency", field: "CurCode", sorting: true },
    {
      title: "Rate",
      field: "CurRate",
      sorting: true,
      render: (rowData) => rowData.CurRate,
    },
    {
      title: "Amount",
      field: "TotalPrice",
      sorting: true,
      render: (rowData) => NumberFormat(rowData.TotalPrice),
    },
    {
      title: "Base Amt.",
      field: "BaseUnpaid",
      sorting: true,
      render: (rowData) => NumberFormat(ToNumber(rowData.TotalPrice) * ToNumber(rowData.CurRate)),
    },
    {
      title: "Unpaid",
      field: "Unpaid",
      type: "numeric",
      sorting: false,
      hidden: isBaseCurrency,
      render: (rowData) => {
        const paidAmt = ToNumber(rowData.Paid);
        const calPaid = rowData.Unpaid - paidAmt;
        if (calPaid < 0 && rowData.Unpaid > 0) {
          rowData.Unpaid = ToNumber(rowData.Unpaid);
          return NumberFormat(rowData.Unpaid);
        } else if (calPaid <= 0 && rowData.Unpaid <= 0) {
          //สำหรับ invoice ติดลบ เช็ค < ถึง error
          rowData.NewUnPaid = ToNumber(rowData.Unpaid) - paidAmt;
          if (rowData.NewUnPaid < rowData.Unpaid) {
            return NumberFormat(rowData.Unpaid);
          } else {
            return NumberFormat(rowData.NewUnPaid);
          }
        } else {
          //สำหรับ invoice ค่าบวก เช็ค > ถึง error
          rowData.NewUnPaid = ToNumber(rowData.Unpaid) - paidAmt;
          if (rowData.NewUnPaid > rowData.Unpaid) {
            return NumberFormat(rowData.Unpaid);
          } else {
            return NumberFormat(rowData.NewUnPaid);
          }
        }
      },
    },
    {
      title: "Base Unpaid",
      field: "Unpaid",
      type: "numeric",
      sorting: false,
      hidden: !isBaseCurrency,
      render: (rowData) => {
        const paidBAmt = ToNumber(rowData.PaidBAmt);
        const calPaid = rowData.Unpaid - paidBAmt;
        if (calPaid < 0 && rowData.Unpaid > 0) {
          rowData.Unpaid = ToNumber(rowData.Unpaid);
          return NumberFormat(rowData.Unpaid);
        } else if (calPaid <= 0 && rowData.Unpaid <= 0) {
          //สำหรับ invoice ติดลบ เช็ค < ถึง error
          rowData.NewUnPaid = ToNumber(rowData.Unpaid) - paidBAmt;
          if (rowData.NewUnPaid < rowData.Unpaid) {
            return NumberFormat(rowData.Unpaid);
          } else {
            return NumberFormat(rowData.NewUnPaid);
          }
        } else {
          //สำหรับ invoice ค่าบวก เช็ค > ถึง error
          rowData.NewUnPaid = ToNumber(rowData.Unpaid) - paidBAmt;
          if (rowData.NewUnPaid > rowData.Unpaid) {
            return NumberFormat(rowData.Unpaid);
          } else {
            return NumberFormat(rowData.NewUnPaid);
          }
        }
      },
    },
    {
      title: "Paid",
      field: "Paid",
      type: "numeric",
      sorting: false,
      hidden: isBaseCurrency,
      render: (rowData) => {
        const checkValue = () => {
          if (rowData.Paid === 0) {
            return true;
          } else if (rowData.Paid < 0 && rowData.NewUnPaid > 0) {
            return true;
          } else if (rowData.Paid > 0 && rowData.NewUnPaid < 0) {
            return true;
          } else if (rowData.Paid > rowData.NewUnPaid && rowData.NewUnPaid > 0) {
            return true;
          } else if (rowData.Paid < rowData.NewUnPaid && rowData.NewUnPaid < 0) {
            return true;
          } else {
            return false;
          }
        };
        if (rowData.tableData.checked) {
          return (
            <NumberFormatInput
              className={clsx(classes.numberBox, {
                [classes.numberBoxError]: checkValue(),
              })}
              value={rowData.Paid}
              onChange={(e) => {
                const value = NumberFormat(ToNumber(e.target.value));
                //dataPopup[rowData.tableData.id].Paid = ToNumber(value);
                //setDataPopup([...dataPopup]);
                return value;
              }}
              onBlur={(e) => {
                const value = NumberFormat(ToNumber(e.target.value));
                dataPopup[rowData.tableData.id].Paid = ToNumber(value);
                setDataPopup([...dataPopup]);
                return value;
              }}
              onFocus={handleFocus}
              decimal={SettingSystem.CurrencyBaseDecimal}
              decimalSep={SettingSystem.DecimalSeparator}
              thousandSep={SettingSystem.ThousandSeparator}
            />
          );
        } else {
          return <div>{NumberFormat(rowData.Paid)}</div>;
        }
      },
    },
    {
      title: "Base Paid",
      field: "PaidBAmt",
      type: "numeric",
      sorting: false,
      hidden: !isBaseCurrency,
      render: (rowData) => {
        const checkValue = () => {
          if (rowData.PaidBAmt === 0) {
            return true;
          } else if (rowData.PaidBAmt < 0 && rowData.Unpaid > 0) {
            return true;
          } else if (rowData.PaidBAmt > 0 && rowData.Unpaid < 0) {
            return true;
          } else if (rowData.PaidBAmt > rowData.Unpaid && rowData.Unpaid > 0) {
            return true;
          } else if (rowData.PaidBAmt < rowData.Unpaid && rowData.Unpaid < 0) {
            return true;
          } else {
            return false;
          }
        };
        if (rowData.tableData.checked) {
          return (
            <NumberFormatInput
              className={clsx(classes.numberBox, {
                [classes.numberBoxError]: checkValue(),
              })}
              value={rowData.PaidBAmt}
              onChange={(e) => {
                const value = NumberFormat(ToNumber(e.target.value));
                //dataPopup[rowData.tableData.id].PaidBAmt = ToNumber(value);
                //setDataPopup([...dataPopup]);
                return value;
              }}
              onBlur={(e) => {
                const value = NumberFormat(ToNumber(e.target.value));
                dataPopup[rowData.tableData.id].PaidBAmt = ToNumber(value);
                setDataPopup([...dataPopup]);
                return value;
              }}
              onFocus={handleFocus}
              decimal={SettingSystem.CurrencyBaseDecimal}
              decimalSep={SettingSystem.DecimalSeparator}
              thousandSep={SettingSystem.ThousandSeparator}
            />
          );
        } else {
          return <div>{NumberFormat(rowData.PaidBAmt)}</div>;
        }
      },
    },
  ];

  const options = {
    draggable: false,
    headerStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF",
    },
    rowStyle: (item) => {
      if (item.tableData.id % 2) {
        return { backgroundColor: "#f2f2f2" };
      }
    },
    padding: "dense",
    paging: true,
    pageSize: 15,
    pageSizeOptions: [15, 50, 100],
    selection: true,
    search: true,
    selectionProps: () => ({
      color: "primary",
    }),
  };

  const handleCancel = () => {
    setDataPopup(originalData);
    cancel(originalData);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCancel(event);
          }
        }}
        scroll={"paper"}
        maxWidth={maxWidth}
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          {dataPopup?.InvdSeq > 0 ? `Edit Transaction ${dataPopup?.InvdSeq}` : "Add Transaction"}
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <MeterialtableTranslate
            title=""
            columns={columns}
            data={dataPopup}
            options={options}
            isLoading={isLoading}
            onSelectionChange={(rows, selectedRows) => {
              //Select or UnSelect 1 Item
              if (selectedRows) {
                if (selectedRows.tableData.checked) {
                  selectedRows["IsPaid"] = true;
                  selectedRows.Paid = ToNumber(selectedRows.Unpaid);
                  selectedRows.PaidBAmt = ToNumber(selectedRows.Unpaid);
                } else {
                  selectedRows.Paid = 0;
                  selectedRows.PaidBAmt = 0;
                }
                dataPopup[selectedRows.tableData.id] = selectedRows;
                setDataPopup([...dataPopup]);
              }
              //Select All
              if (rows.length > 0 && !selectedRows) {
                rows.forEach((item) => {
                  item.IsPaid = true;
                  item.Paid = ToNumber(item.Unpaid);
                  item.PaidBAmt = ToNumber(item.Unpaid);
                });
                setDataPopup(rows);
              }
              //UnSelect All
              if (rows.length === 0 && !selectedRows) {
                dataPopup.forEach((item) => {
                  item.IsPaid = false;
                  item.Paid = 0;
                  item.PaidBAmt = 0;
                });
                setDataPopup([...dataPopup]);
              }
            }}
          />
          {children}
          <Divider />
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(dataPopup, 0, 2) : ""}</pre>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={select}>
            {translate("ra.actionMenu.OK")}
          </Button>
          <Button variant="outlined" className={classes.textCancel} onClick={handleCancel}>
            {translate("ra.action.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PopupSettlement;
