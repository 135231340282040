/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { callStoreProcedurePost, getReportList, showReport, saveReport, exportReport } from "./services";
import DatePicker from "./controls/DatePicker";
import Lookup from "./controls/Lookup";
import Text from "./controls/Text";
import Label from "./controls/Label";
import Check from "./controls/Check";

import {
  Fab,
  Dialog,
  DialogTitle,
  Paper,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Divider,
  Avatar,
  Icon,
  Backdrop,
  CircularProgress,
  Hidden,
  makeStyles,
  SwipeableDrawer,
  Box,
} from "@material-ui/core";
//import DialogExportCSV from "components/DialogExportCSV";
/* Material Icons*/
// import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
// import ShareIcon from "@material-ui/icons/Share";
// import DescriptionIcon from "@material-ui/icons/Description";
// import SaveAltIcon from "@material-ui/icons/SaveAlt";
// import ImportExportIcon from "@material-ui/icons/ImportExport";

import clsx from "clsx";
import SnackbarUtils from "utils/SnackbarUtils";

export default function Report(props) {
  const [reportList, setReportList] = useState();
  const [report, setReport] = useState({});
  const [listData, setListData] = useState([]);
  const [listControl, setListControl] = useState([]);
  const [param, setParam] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showExport, setshowExport] = useState(false);
  // const [showCustomExport, setShowCustomExport] = useState(false);
  // const [showCustomExportDialog, setShowCustomExportDialog] = useState(false);
  // const [paramForDialog, setParamForDialog] = useState();
  const [drawerState, setDrawerSate] = React.useState(false);

  useEffect(() => {
    async function fetch() {
      const groupName = props.options.groupName;
      const list = await getReportList(groupName);
      setReportList(list);
    }
    fetch();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      //backgroundColor: theme.palette.background.paper,
      flexGrow: 1,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));

  const classes = useStyles();
  const default_DateFormat = "dd/MM/yyyy";
  const setParameter = (index, name, value) => {
    const newParam = { id: index, name: name, value: value };
    setParam((p) => {
      const old = p.filter((item) => item.name !== name);
      return [...old, newParam];
    });
  };

  const report_Click = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    setParam([]);
    setListControl([]);
    await getListControls(id);
  };

  const reportParameter = (paramDialog) => {
    const parameters = [];
    // Ordering paramters by id
    paramDialog.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));
    // Create parameters in postData
    paramDialog.map((item) => parameters.push({ Name: item.name, Value: item.value }));

    return parameters;
  };

  const preview_Click = async (e) => {
    e.preventDefault();

    setLoading(true);
    const parameters = reportParameter(param);
    const error = await showReport(report, parameters, "pdf");
    setLoading(false);
    if (error) SnackbarUtils.warning(error);
  };

  const saveFile_click = async (fileFormat) => {
    setLoading(true);
    const parameters = reportParameter(param);
    const error = await saveReport(report, parameters, fileFormat);
    setLoading(false);
    if (error) SnackbarUtils.warning(error);
  };

  const export_Click = async () => {
    //e.preventDefault();

    setLoading(true);
    const parameters = reportParameter(param);
    const error = await exportReport(report, parameters);
    setLoading(false);
    if (error) SnackbarUtils.warning(error);
  };

  const getListControls = async (id) => {
    if (+id > 0) {
      const result = reportList.Data && reportList.Data.filter((o) => o.Id === parseInt(id));

      const rpt = result ? result[0] : {};

      setReport(rpt);

      let xml = rpt.Dialog ? rpt.Dialog.replace(/^\s+|\s+$/gm, "") : undefined;

      if (xml) {
        const parser = new DOMParser();
        const dom = parser.parseFromString(xml, "text/xml");
        let nodes = dom.getElementsByTagName("Data")[0].childNodes;
        const dataSet = [];

        let exportCount = dom.getElementsByTagName("Export");
        setshowExport(exportCount.length > 0);

        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          const name = node.getAttribute("Name");
          const spName = node.getAttribute("StoredProcedureName");

          dataSet.push({
            name: name,
            data: await callStoreProcedurePost(spName),
          });
        }
        setListData(dataSet);

        let listControls = [];

        // Dialog
        nodes = dom.getElementsByTagName("Dialog")[0].childNodes;
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          const nodeName = node.nodeName.toLowerCase();

          const newControl = {
            type: nodeName,
            name: node.hasAttribute("Name") ? node.getAttribute("Name") : "",
            visble: node.hasAttribute("Visible") ? node.getAttribute("Visible").toLowerCase() === "true" : true,
            text: node.hasAttribute("Text") ? node.getAttribute("Text") : "",
            value: node.hasAttribute("Value") ? node.getAttribute("Value") : "",
            items: node.hasAttribute("Items") ? node.getAttribute("Items").split("~") : [],
            values: node.hasAttribute("Values") ? node.getAttribute("Values").split("~") : [],
            dataSource: node.hasAttribute("DataSource") ? node.getAttribute("DataSource") : "",
            dataValue: node.hasAttribute("DataValue") ? node.getAttribute("DataValue") : "",
            displayValue: node.hasAttribute("DisplayValue") ? node.getAttribute("DisplayValue") : "",
            displayColumns: node.hasAttribute("DisplayColumns") ? node.getAttribute("DisplayColumns") : "",
          };

          if (newControl.visble) listControls.push(newControl);
        }
        // for loop
        setListControl(listControls);
        // end if
      } else setReport({});

      //TODO: set status show new custom export btn
      //202 = Budget
      //203 = GL_Jv
      //301 = AP_Invoice
      //302 = AP_Payment
      //305 = Vendor
      //507 = AR_Invoice
      //508 = AR_Receipt
      // if (id === 202 || id === 203 || id === 301 || id === 302 || id === 305 || id === 507 || id === 509) {
      //   setShowCustomExport(true);
      // } else {
      //   setShowCustomExport(false);
      // }
      setLoading(false);
    } else {
      setReport({});
      setLoading(false);
    }
  };

  const createControls = (item, index) => {
    let tag = "";

    switch (item.type.toLowerCase()) {
      case "label":
        tag = <Label key={index} text={item.text} />;
        break;
      case "text":
        tag = <Text key={index} controlNo={index} name={item.name} value={item.text} setParameter={setParameter} />;
        break;
      case "date":
        tag = (
          <DatePicker
            key={index}
            controlNo={index}
            name={item.name}
            value={item.value}
            dateFormat={default_DateFormat}
            setParameter={setParameter}
            showTodayButton
          />
        );
        break;
      case "check":
      case "checkbox":
        tag = (
          <div>
            <Check
              key={index}
              controlNo={index}
              name={item.name}
              value={item.value}
              text={item.text}
              setParameter={setParameter}
            />
          </div>
        );
        break;
      case "lookup":
        const dataSet =
          item.dataSource !== "" && listData.find((x) => x.name.toLowerCase() === item.dataSource.toLowerCase());

        tag = (
          <Lookup
            key={index}
            controlNo={index}
            name={item.name}
            value={item.value}
            //dataSource={item.dataSource}
            dataSet={dataSet}
            dataValue={item.dataValue}
            displayValue={item.displayValue}
            items={item.items}
            values={item.values}
            setParameter={setParameter}
          />
        );
        break;
      default:
        tag = <div key={index}></div>;
        break;
    }
    return tag;
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerSate(open);
  };

  const shareList = () => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: true,
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button key="pdf" onClick={() => saveFile_click("pdf")}>
          <ListItemIcon>
            {/* <PictureAsPdfIcon style={{ color: "tomato" }} /> */}
            <Icon style={{ color: "tomato" }}>picture_as_pdf</Icon>
          </ListItemIcon>
          <ListItemText primary="PDF" secondary="Save as the PDF file (*.pdf)." />
        </ListItem>
        <ListItem button key="docx" onClick={() => saveFile_click("docx")}>
          <ListItemIcon>
            {/* <SaveAltIcon style={{ color: "darkblue" }} /> */}
            <Icon style={{ color: "darkblue" }}>save_alt</Icon>
          </ListItemIcon>
          <ListItemText primary="Word" secondary="Save as the Word Document (*.docx)." />
        </ListItem>
        <ListItem button key="xlsx" onClick={() => saveFile_click("xlsx")}>
          <ListItemIcon>
            {/* <SaveAltIcon style={{ color: "green" }} /> */}
            <Icon style={{ color: "green" }}>save_alt</Icon>
          </ListItemIcon>
          <ListItemText primary="Excel" secondary="Save as the Excel Workbook (*.xlsx)." />
        </ListItem>
      </List>
      <Divider />
      {showExport && (
        <List>
          <ListItem button key="Export" onClick={() => export_Click()}>
            <ListItemIcon>
              <Icon style={{ color: "primary" }}>import_export</Icon>
            </ListItemIcon>
            <ListItemText primary="Export" secondary="Export data to a file." />
          </ListItem>
        </List>
      )}
      {/* {showCustomExport && (
        <List>
          <ListItem
            button
            key="Export"
            onClick={() => {
              setParamForDialog(reportParameter(param));
              setShowCustomExportDialog(true);
            }}
          >
            <ListItemIcon>
              <Icon style={{ color: "primary" }}>import_export</Icon>
            </ListItemIcon>
            <ListItemText primary="Export" secondary="Export data to a csv file." />
          </ListItem>
        </List>
      )} */}
    </div>
  );

  const createDialog = () => {
    return (
      <>
        <Paper
          elevation={3}
          style={{
            paddingTop: 5,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 20,
          }}
        >
          <Typography variant="h6" className={classes.title}>
            {report.ReportName}
          </Typography>
          <Divider />
          <br />
          {listControl && listControl.map((item, index) => createControls(item, index))}
        </Paper>
        <Box component="span" display="flex" paddingTop={2} justifyContent="flex-start">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<Icon>print</Icon>}
            onClick={(e) => preview_Click(e)}
          >
            Preview
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Fab color="secondary" aria-label="share" size="medium" onClick={toggleDrawer(true)}>
            <Icon>download</Icon>
          </Fab>
        </Box>
        <SwipeableDrawer anchor="right" open={drawerState} onClose={toggleDrawer(false)}>
          {shareList()}
        </SwipeableDrawer>
      </>
    );
  };

  const getTitle = (groupName) => {
    switch (groupName.toLowerCase()) {
      case "gl":
        return "General Ledger";
      case "ap":
        return "Account Payable";
      case "ar":
        return "Account Receivable";
      case "asset":
        return "Asset Managment";
      default:
        return "";
    }
  };

  // --------------------------------------------
  // Modal to display report list when is on mobile (sm)
  // --------------------------------------------

  function ReportListModal(props) {
    const { onClose, open } = props;

    const handleClose_ReportListDialog = () => {
      onClose();
    };

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose_ReportListDialog}
        aria-labelledby="dialog-title"
        open={open}
      >
        <DialogTitle id="dialog-title">{props.title}</DialogTitle>

        <List component="nav" aria-label="report list">
          {reportList &&
            reportList.Data.map((item) => (
              <ListItem
                button
                key={item.Id}
                onClick={(e) => {
                  report_Click(e, item.Id);
                  handleClose_ReportListDialog();
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <Icon>Desscription</Icon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.ReportName} secondary={item.Description} />
              </ListItem>
            ))}
        </List>
      </Dialog>
    );
  }

  const showModal = () => {
    setOpenDialog(true);
  };

  const hideModal = (value) => {
    setOpenDialog(false);
  };
  // --------------------------------------------

  // Display UI

  return (
    <div className={classes.root}>
      <br />
      <Hidden only={["md", "lg", "xl"]}>
        <div style={{ textAlign: "center" }}>
          <Fab variant="extended" onClick={showModal}>
            {getTitle(props.options.groupName)}
          </Fab>
        </div>
        <ReportListModal title={getTitle(props.options.groupName)} open={openDialog} onClose={hideModal} />
        <br />
      </Hidden>

      <Grid container spacing={3}>
        <Hidden only={["xs", "sm"]}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} style={{ paddingTop: 5, paddingLeft: 20, paddingRight: 20 }}>
              <Typography variant="h6" className={classes.title}>
                {getTitle(props.options.groupName)}
              </Typography>
              <Divider />
              <List component="nav" aria-label="report list">
                {reportList &&
                  reportList.Data.map((item) => (
                    <ListItem button key={item.Id} onClick={(e) => report_Click(e, item.Id)}>
                      <ListItemAvatar>
                        <Avatar>
                          <Icon>description</Icon>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.ReportName} secondary={item.Description} />
                    </ListItem>
                  ))}
              </List>
            </Paper>
          </Grid>
        </Hidden>

        <Grid item xs={12} sm={12} md={6}>
          {report.Id && createDialog()}
        </Grid>
      </Grid>
      {/* {showCustomExportDialog && (
        <DialogExportCSV
          open={showCustomExportDialog}
          reportId={report.Id}
          param={paramForDialog}
          close={() => {
            setShowCustomExportDialog(!showCustomExportDialog);
          }}
        />
      )} */}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
