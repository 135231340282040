import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import {
  TextFieldInForm,
  NumberFormatInForm,
  CheckBoxInForm,
  MuiAutosuggest,
  DateInForm,
  DescInForm,
  SelectInForm,
} from "components/Form";
import { Typography } from "@material-ui/core";
import { getAccountCodeList, getDepartmentList, getApPaymentTypeList, getMailProfile } from "services/setting";
import { getWorkflowByCode, getWorkflowStep } from "services/workflow";
import { getEnumApInvoiceStatus } from "services/enum";
import { getLookupCurrency } from "services/lookup";
import { getApVendorList, getApWhtServiceSearchList, getApWhtTypeSearchList } from "services/accountPayable";
import List from "./List";
import Show from "./Show";
import Edit from "./Edit";
import Create from "./Create";
import { makeStyles } from "@material-ui/core/styles";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import { WithholdingTaxRefOption } from "utils/options";
// import Hidden from "@material-ui/core/Hidden";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 12,
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  bold: {
    fontWeight: 600,
  },
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  drawerOpen: {
    marginRight: drawerWidth,
  },
  drawerClose: {
    marginRight: 54,
  },
  borderTableTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  borderTable: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  content: {
    padding: 4,
  },
  wfBtn: {
    padding: "8px 0 8px 8px",
    marginBottom: "10px",
    textAlign: "right",
  },
}));

const ViewMode = (props) => {
  const [wfActive, setWfActive] = useState(false);
  const [wfSteps, setWfSteps] = useState();
  const [wfEnumStatus, setWfEnumStatus] = useState();
  const [hideMailBtn, setHideMailBtn] = useState(false);
  const fetchMail = useCallback(async (pCode) => {
    const r = await getMailProfile(pCode);
    if (r?.Active) {
      setHideMailBtn(false);
    } else {
      setHideMailBtn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchWorkflowByCode = useCallback(async (wfCode) => {
    const r = await getWorkflowByCode(wfCode);
    if (r?.data?.Active) {
      setWfActive(true);
      const enumData = await getEnumApInvoiceStatus();
      setWfEnumStatus(enumData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchWorkflowSteps = useCallback(async (wfCode) => {
    const { data } = await getWorkflowStep(wfCode);
    setWfSteps(data);
  }, []);

  useEffect(() => {
    fetchMail("AP_Payment");
    fetchWorkflowByCode("AP_PAYMENT");
    fetchWorkflowSteps("AP_PAYMENT");
  }, [fetchMail, fetchWorkflowByCode, fetchWorkflowSteps]);

  return (
    <Show
      {...props}
      useStyles={useStyles}
      hideMailBtn={hideMailBtn}
      wfActive={wfActive}
      wfEnumStatus={wfEnumStatus}
      wfSteps={wfSteps}
    />
  );
};

const SwitchActionMode = (props) => {
  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();
  const { authenticated } = useAuthState();
  const [wfSteps, setWfSteps] = useState();
  const [wfEnumStatus, setWfEnumStatus] = useState();
  const { settingAll, ToMySqlDate, ToNumber } = useContext(GblContext);
  const { SettingClosePeriod, SettingSystem } = settingAll;
  const { ClosePeriodAp } = SettingClosePeriod;
  const newClosePeriodAp = addDays(new Date(ClosePeriodAp), 1);
  const [oldAccList, setOldAccList] = useState([]);
  const addMode = props.location.pathname.search("create") !== -1;
  const [lookupList, setLookupList] = useState({
    accountCodeListPA: [],
    accountCodeListGA: [],
    accountCodeListWA: [],
    departmentList: [],
    currencyList: [],
    vendorList: [],
    apPaymentTypeList: [],
    apWhtList: [],
    apWhtTypeList: [],
  });
  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList("Ap");
    setLookupList((state) => ({
      ...state,
      accountCodeListPA: Data,
      accountCodeListGA: Data,
      accountCodeListWA: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  }, []);
  const fetchCurrencyLookup = useCallback(async () => {
    const p = {
      Module: "AP",
      CurrDate: ToMySqlDate(new Date()),
    };
    const arr = await getLookupCurrency(p);
    setLookupList((state) => ({
      ...state,
      currencyList: arr,
    }));
  }, [ToMySqlDate]);
  const fetchVendorLookup = useCallback(async () => {
    const { Data } = await getApVendorList();
    setLookupList((state) => ({
      ...state,
      vendorList: Data,
    }));
  }, []);
  const fetchPaymentTypeLookup = useCallback(async () => {
    const { Data } = await getApPaymentTypeList();
    setLookupList((state) => ({
      ...state,
      apPaymentTypeList: Data,
    }));
  }, []);
  const fetchApWhtLookup = useCallback(async () => {
    const { Data } = await getApWhtServiceSearchList();
    setLookupList((state) => ({
      ...state,
      apWhtList: Data,
    }));
  }, []);
  const fetchApWhtTypeLookup = useCallback(async () => {
    const { Data } = await getApWhtTypeSearchList();
    setLookupList((state) => ({
      ...state,
      apWhtTypeList: Data,
    }));
  }, []);
  const fetchWorkflowByCode = useCallback(async (wfCode) => {
    const r = await getWorkflowByCode(wfCode);
    if (r?.data?.Active) {
      const enumData = await getEnumApInvoiceStatus();
      setWfEnumStatus(enumData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchWorkflowSteps = useCallback(async (wfCode) => {
    const { data } = await getWorkflowStep(wfCode);
    setWfSteps(data);
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchAccLookup();
      fetchDeptLookup();
      fetchVendorLookup();
      fetchCurrencyLookup();
      fetchPaymentTypeLookup();
      fetchApWhtLookup();
      fetchApWhtTypeLookup();
      fetchWorkflowByCode("AP_PAYMENT");
      fetchWorkflowSteps("AP_PAYMENT");
    }
  }, [
    authenticated,
    fetchAccLookup,
    fetchDeptLookup,
    fetchVendorLookup,
    fetchCurrencyLookup,
    fetchPaymentTypeLookup,
    fetchApWhtLookup,
    fetchApWhtTypeLookup,
    fetchWorkflowByCode,
    fetchWorkflowSteps,
  ]);

  const formFieldsEdit = [
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Payment No.")}
          name="PyhSeq"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
          InputProps={{
            readOnly: true,
          }}
          disabled={!addMode}
        />
      ),
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={translate("ra.field.Payment Date")}
          name="PyhDate"
          minDate={addMode ? new Date(newClosePeriodAp) : false}
          minDateMessage={"Date must be more than close period"}
          //for custom advance update field
          useFncUpdate={true}
          fncUpdate={async (value, methods) => {
            const p = {
              Module: "AP",
              CurrDate: ToMySqlDate(value),
            };
            const newCurrList = await getLookupCurrency(p);
            const currentCurCode = methods.watch("CurCode");
            const currentCurRate = methods.watch("CurRate");
            const newCurrRate = newCurrList.find((i) => i.CurrCode === currentCurCode).CurrRate;
            if (newCurrRate !== ToNumber(currentCurRate)) {
              SnackbarUtils.confirm(translate("ra.question.currencyWarning"), function () {
                methods.setValue("CurRate", newCurrRate);
              });
            }
            setLookupList((state) => ({
              ...state,
              currencyList: newCurrList,
            }));
          }}
          required={addMode}
          disabled={!addMode}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Vendor")}`}
          name="VnCode"
          optKey="VnCode"
          optDesc="VnName"
          options={lookupList["vendorList"]}
          updateOtherField={[
            { key: "VnName", optKey: "VnName" },
            { key: "AppyCode", optKey: "VnPayType" },
            { key: "AppyDesc", optKey: "VnPayTypeDesc" },
            { key: "DeptPayCode", optKey: "VnPayDeptCode" },
            { key: "DeptPayDesc", optKey: "VnPayDeptDesc" },
            { key: "PyhCr", optKey: "VnCrAccCode" },
            { key: "PyhCrDesc", optKey: "VnCrAccDesc" },
            { key: "WhtTypeCode", optKey: "VnWhtTypeCode" },
            { key: "Payee", optKey: "VnPayee" },
            { key: "PrePayee", optKey: "PreVnPayee" },
            { key: "Address", optKey: "VnAdd1", useFncUpdate: true },
            { key: "TaxId", optKey: "VnTaxNo" },
            { key: "BranchNo", optKey: "BranchNo" },
            { key: "WhtDeptCode", optKey: "VnWhtDeptCode" },
            { key: "WhtDeptDesc", optKey: "VnWhtDeptDesc" },
            { key: "WhtTaxCr", optKey: "VnWhtAccCode" },
            { key: "WhtTaxCrDesc", optKey: "VnWhtAccDesc" },    
          ]}
          disabled={!addMode}
          //for custom advance update field
          fncUpdate={(value) => {
            const v = value?.VnAdd1?.concat(value?.VnAdd2 ?? "", value?.VnAdd3 ?? "", value?.VnAdd4 ?? "");
            return v;
          }}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "VnName",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="VnName"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Currency")}`}
          name="CurCode"
          optKey="CurrCode"
          optDesc="CurrRate"
          options={lookupList["currencyList"]}
          updateOtherField={[{ key: "CurRate", optKey: "CurrRate" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Rate")}`}
          name="CurRate"
          rule={{
            min: {
              value: 0.000001,
              message: "* Required",
            },
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.CurrencyRateDecimal}
          // decimalSep={SettingSystem.DecimalSeparator}
          // thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.fieldAbbr.cheqNoFrom")}
          name="PyhChqFr"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 10,
              message: "maximum length is 10",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.fieldAbbr.cheqNoTo")}
          name="PyhChqTo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 10,
              message: "maximum length is 10",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Payment Type")}`}
          name="AppyCode"
          optKey="Code"
          optDesc="Desc"
          options={lookupList["apPaymentTypeList"]}
          updateOtherField={[{ key: "AppyDesc", optKey: "Desc" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "AppyDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="AppyDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Description")}
          name="PyhDesc"
          variant="outlined"
          margin="dense"
          multiline
          rows={5}
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={translate("ra.field.Cheque Date")}
          name="PyhChqDt"
          minDate={new Date(newClosePeriodAp)}
          customMinDate={"PyhDate"}
          minDateMessage={"Date must be more than close period"}
          clearable
        />
      ),
      style: { marginTop: -70 },
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={translate("ra.field.Clearing Date")}
          name="PyhClearDt"
          minDate={new Date(newClosePeriodAp)}
          //customMinDate={addMode ? "PyhChqDt" : false}
          customMinDate={"PyhChqDt"}
          minDateMessage={"Date must be more than close period"}
          clearable
        />
      ),
      style: { marginTop: -70 },
    },
  ];

  const formFieldsPaymentAccount = [
    {
      size: 2,
      field: <Typography className={classes.heading}>{translate("ra.field.Payment Account")}</Typography>,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Dept. Code")}`}
          name="DeptPayCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "DeptPayDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListPA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("PyhCr");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("PyhCr", "");
                methods.setValue("PyhCrDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListPA: oldAccList,
              }));
            }
          }}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      name: "DeptPayDesc",
      field: (
        <DescInForm
          name="DeptPayDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Acc. Code")}`}
          name="PyhCr"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListPA"]}
          updateOtherField={[
            {
              key: "PyhCrDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      name: "PyhCrDesc",
      field: (
        <DescInForm
          name="PyhCrDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  const formFieldsGainLossAccount = [
    {
      size: 2,
      field: <Typography className={classes.heading}>{translate("ra.field.Gain/Loss Account")}</Typography>,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Dept. Code")}
          name="GainLossDept"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "GainLossDeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListGA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("GainLossAcc");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("GainLossAcc", "");
                methods.setValue("GainLossAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListGA: oldAccList,
              }));
            }
          }}
          clearable
        />
      ),
    },
    {
      size: 3,
      name: "GainLossDeptDesc",
      field: (
        <DescInForm
          name="GainLossDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Acc. Code")}
          name="GainLossAcc"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListGA"]}
          updateOtherField={[
            {
              key: "GainLossAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          clearable
        />
      ),
    },
    {
      size: 3,
      name: "GainLossAccDesc",
      field: (
        <DescInForm
          name="GainLossAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  const formFiledWhtAccount = [
    {
      size: 2,
      field: <Typography className={classes.heading}>{translate("ra.field.WHT. Account")}</Typography>,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Dept. Code")}
          name="WhtDeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "WhtDeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListWA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("WhtTaxCr");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("WhtTaxCr", "");
                methods.setValue("WhtTaxCrDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListWA: oldAccList,
              }));
            }
          }}
        />
      ),
    },
    {
      size: 3,
      name: "WhtDeptDesc",
      field: (
        <DescInForm
          name="WhtDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Acc. Code")}
          name="WhtTaxCr"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListWA"]}
          updateOtherField={[
            {
              key: "WhtTaxCrDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
        />
      ),
    },
    {
      size: 3,
      name: "WhtTaxCrDesc",
      field: (
        <DescInForm
          name="WhtTaxCrDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  const formFieldsWht = [
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.WHT. Form")}
          name="WhtTypeCode"
          noCode
          optKey="Description"
          optDesc="Description"
          options={lookupList["apWhtTypeList"]}
          updateOtherField={[{ key: "WhtTypeDesc", optKey: "Description" }]}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.TAX ID.")}
          name="TaxId"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
          //disabled={!addMode}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Branch No.")}
          name="BranchNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 20,
              message: "maximum length is 20",
            },
          }}
        />
      ),
    },
    {
      size: 1,
      field: <TextFieldInForm label={translate("ra.field.Title")} name="PrePayee" variant="outlined" margin="dense" />,
    },
    {
      size: 2,
      field: <TextFieldInForm label={translate("ra.field.WHT. Name")} name="Payee" variant="outlined" margin="dense" />,
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Address")}
          name="Address"
          variant="outlined"
          margin="dense"
          //multiline
          //rows={4}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.WHT. No")}
          name="WhtNo"
          variant="outlined"
          margin="dense"
          disabled={true}
        />
      ),
    },
    {
      size: 2,
      field: <CheckBoxInForm label="Auto" name="WhtNoAuto" disabled={!addMode} />,
    },
    {
      size: 2,
      field: (
        <SelectInForm
          label={translate("ra.field.WHT Condition")}
          name="WhtRemark1"
          options={WithholdingTaxRefOption}
          keyInDesc
          style={{ "&.MuiInputLabel-shrink": { transform: "translate(14px, -2px) scale(0.75)" } }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextFieldInForm label={translate("ra.field.Remark")} name="WhtRemark2" variant="outlined" margin="dense" />
      ),
    },
  ];

  const formFieldsWhtDetail = [
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={`* Code`}
          name="WhtCode"
          optKey="WhtCode"
          optDesc="Description"
          options={lookupList["apWhtList"]}
          updateOtherField={[
            { key: "WhtDesc", optKey: "Description" },
            { key: "WhtRate", optKey: "WhtRate" },
          ]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="WhtDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Tax Rate %")}
          name="WhtRate"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Total") + " " + translate("ra.field.Base Amt.")}
          name="Amount"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
          toolTip="SuggestionAmt"
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Tax")}
          name="TaxAmt"
          //InputProps={{ readOnly: true }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
  ];

  if (addMode) {
    return (
      <Create
        {...props}
        formFields={formFieldsEdit}
        formFieldsPaymentAccount={formFieldsPaymentAccount}
        formFieldsGainLossAccount={formFieldsGainLossAccount}
        formFiledWhtAccount={formFiledWhtAccount}
        formFieldsWht={formFieldsWht}
        formFieldsWhtDetail={formFieldsWhtDetail}
        vendorList={lookupList.vendorList}
        apWhtList={lookupList.apWhtList}
        useStyles={useStyles}
      />
    );
  } else {
    return (
      <Edit
        {...props}
        formFields={formFieldsEdit}
        formFieldsPaymentAccount={formFieldsPaymentAccount}
        formFieldsGainLossAccount={formFieldsGainLossAccount}
        formFiledWhtAccount={formFiledWhtAccount}
        formFieldsWht={formFieldsWht}
        formFieldsWhtDetail={formFieldsWhtDetail}
        wfEnumStatus={wfEnumStatus}
        wfSteps={wfSteps}
        useStyles={useStyles}
      />
    );
  }
};

export default {
  list: List,
  show: ViewMode,
  edit: SwitchActionMode,
  create: SwitchActionMode,
};
