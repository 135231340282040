export default {
  FileId: 0,
  DateFormat: "",
  Language: "",
  AppyCode: "",
  ColumnOfCheqNo: 0,
  ColumnOfClearingDate: 0,
  ColumnOfClearingAmount: 0,
  UserModified: localStorage.getItem("UserName"),
};
