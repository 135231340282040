/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { Button, Typography, CircularProgress } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { postInfPostARsaveToFolio } from "services/interface";

import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default function DialogPostingResult(props) {
  const classes = useStyles();
  const { DateToString, NumberFormat } = useContext(GblContext);
  const { open, onClose, data, columnData, checkDuplicate } = props;
  const dataTable = data;
  const [columns, setColumns] = useState();
  const [isLoading, setLoading] = useState(false);
  const CheckResultShowMessage = async ({ Code, InternalMessage, UserMessage }) => {
    if (Code === null) {
      SnackbarUtils.warning("File Not Found");
    }
    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
    }
    if (Code === -1) {
      SnackbarUtils.warning(InternalMessage);
    }
    if (Code === -204) {
      SnackbarUtils.warning(UserMessage);
    }
  };

  const ConfirmToSave = () => {
    let selectedData = dataTable;
    if (selectedData?.length > 0) {
      // var foundItemNull = selectedData.filter((item) => item.ArNo === "" || item.ArNo === null);
      // if (foundItemNull?.length > 0) {
      //   SnackbarUtils.warning("A/R No. can't be empty");
      //   return [];
      // }
      // let numberOfPost = selectedData.filter((x) => x.IsPass === true).length;
      // let numberOfNotpost = selectedData.filter((x) => x.IsPass === false).length;

      // let all = numberOfPost + numberOfNotpost;

      // let msg = `Found updated data : ${all} transactions
      // (Y) Can post : ${numberOfPost}
      // (N) Cannot post : ${numberOfNotpost}`;

      // return await SnackbarUtils.confirm(
      //   msg,
      //   function () {
      //     return selectedData;
      //   },
      //   function () {
      //     return [];
      //   }
      // );

      // let dialog = window.confirm(msg);
      // if (dialog) {
      //   return selectedData;
      // } else {
      //   return [];
      // }
      return selectedData;
    }
  };

  const Save = async () => {
    setLoading(true);
    let selectedData = ConfirmToSave();
    let param = {
      Code: props.id,
      InterfaceData: selectedData,
      CheckDuplicateFolio: checkDuplicate,
      UserModified: localStorage.getItem("UserName"),
    };

    if (selectedData.length > 0) {
      var r = await postInfPostARsaveToFolio(param);
      setLoading(false);
      CheckResultShowMessage(r);
      onClose();
    }
  };

  // const filterOptions = (options, { inputValue }, optKey, optDesc) => {
  //   return matchSorter(options, inputValue, {
  //     keys: [`${optKey}`, `${optDesc}`],
  //   });
  // };

  useEffect(() => {
    const newColumns = columnData.map((item) => {
      if (item.ColumnName === "IsPass") {
        return {
          name: "IsPass",
          label: " ",
          options: {
            filter: false,
            sort: false,
            display: item.Show,
            customBodyRender: (value) => {
              if (value) {
                return <Icon style={{ color: "green" }}>check_circle</Icon>;
              } else {
                return <Icon style={{ color: "red" }}>cancel</Icon>;
              }
            },
          },
        };
      } else if (item.ColumnName === "ArNo") {
        return {
          name: "ArNo",
          label: "A/R No.",
          options: {
            filter: false,
            sort: false,
            display: item.Show,
            // customBodyRender: (value, tableMeta, updateValue) => {
            //   return (
            //     <Autocomplete
            //       size="small"
            //       //id="arProfileList"
            //       disableClearable={false}
            //       disableListWrap
            //       ListboxComponent={ListBox}
            //       PopperComponent={PopperListBox}
            //       classes={{
            //         option: classes.option,
            //       }}
            //       options={lookupList["arProfileList"]}
            //       autoHighlight
            //       freeSolo={value === "" || "string" ? true : false}
            //       value={value}
            //       getOptionLabel={(option) => {
            //         return typeof option === "object" ? option.ArNo : option;
            //       }}
            //       onChange={(e, newItem) => {
            //         if (newItem) {
            //           var tempData = [...dataTable];
            //           tempData[tableMeta.rowIndex].ArNo = newItem.ArNo;
            //           tempData[tableMeta.rowIndex].FirstName = newItem.FirstName;
            //           tempData[tableMeta.rowIndex].LastName = newItem.LastName;
            //           tempData[tableMeta.rowIndex].Company = newItem.Company;
            //           setDataTable(tempData);
            //         }
            //       }}
            //       style={{ width: 200, display: "inline-flex" }}
            //       renderInput={(params) => {
            //         return (
            //           <TextField
            //             {...params}
            //             variant="outlined"
            //             label="* A/R No"
            //             name="ArNo"
            //             margin="dense"
            //             placeholder="A/R No"
            //             value={params.inputProps.value}
            //             error={params.inputProps.value === ""}
            //             helperText={params.inputProps.value === "" ? "* Required" : null}
            //           />
            //         );
            //       }}
            //       renderOption={(option, { inputValue }) => {
            //         let mergestring = `${option.ArNo} ${option.Company ? `: ${option.Company}` : ""}`;
            //         const matches = match(mergestring, inputValue);
            //         const parts = parse(mergestring, matches);
            //         return (
            //           <div>
            //             {parts.map((part, index) => (
            //               <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            //                 {part.text}
            //               </span>
            //             ))}
            //           </div>
            //         );
            //       }}
            //       filterOptions={(options, { inputValue }) => filterOptions(options, { inputValue }, "ArNo", "Company")}
            //       // filterOptions={(options, { inputValue }) => filterOptions(options, { inputValue }, "Code", "Desc")}
            //     />
            //   );
            //},
          },
        };
      } else if (item.ColumnType === "System.Nullable`1[System.DateTime]") {
        return {
          name: item.ColumnName,
          label: item.ColumnName,
          options: {
            filter: false,
            sort: true,
            display: item.Show,
            customBodyRender: (value) => {
              return value ? DateToString(new Date(value)) : null;
            },
          },
        };
      } else if (item.ColumnType === "System.Nullable`1[System.Decimal]") {
        return {
          name: item.ColumnName,
          label: item.ColumnName,
          options: {
            filter: false,
            sort: false,
            display: item.Show,
            setCellHeaderProps: () => ({
              align: "right",
            }),
            setCellProps: () => ({
              style: {
                textAlign: "right",
              },
            }),
            customBodyRender: (value) => {
              return NumberFormat(value);
            },
          },
        };
      } else {
        return {
          name: item.ColumnName,
          label: item.ColumnName,
          options: {
            filter: false,
            sort: false,
            display: item.Show,
          },
        };
      }
    });
    setColumns(newColumns);
  }, []);

  const options = {
    responsive: "standard",
    selectableRows: "none",
    fixedHeader: true,
    tableBodyHeight: "500px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [15, 50, 100],
  };

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event);
          }
        }}
        scroll={"paper"}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle id="scroll-dialog-title" onClose={onClose}>
          A/R Posting From {props.title}
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <MUIDataTable data={dataTable} columns={columns} options={options} />
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(dataTable, 0, 2) : ""}</pre>
        </DialogContent>
        <DialogActions>
          <LoadingButton text="OK" disabled={isLoading} fnc={() => Save()} />
          <Button variant="outlined" className={classes.textCancel} onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
