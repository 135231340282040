import React, { useState } from "react";
import html2canvas from "html2canvas";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Card, CardHeader, CardContent, Typography, Menu, MenuItem, Icon, IconButton } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { Chart } from "react-google-charts";
import { makeStyles } from "@material-ui/core/styles";
import ConfigDashboard from "./Dialog/ConfigDashboard";
import { addDays, addYears } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import { showReportPDF, deleteWidgetById } from "services/callStoreProcedure";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    display: "flex",
    flexDirection: "column",
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  main: () => ({
    overflow: "inherit",
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      color: theme.palette.type === "dark" ? "inherit" : "#dc2440",
    },
  }),
  cardHeader: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  cardContent: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
}));

const BudgetBarChart = (props) => {
  const classes = useStyles();
  const [param, setParam] = useState({
    Id: props.Id,
    // prevDate: props.prevDate,
    // date: props.date,
    name: props.name,
    Seq: props.Seq,
    FromDate: props.FromDate,
    ToDate: props.ToDate,
    Width: props.Width,
    configName: props.configName,
    Config: props.Config,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openWidgetMenu = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const { name, data,dragHandle } = props;
  const updateChart = async (param) => {
    setLoading(true);
    const paramChart = {
      ...props,
      Id: param.Id,
      name: param.name,
      // prevDate: param.prevDate,
      // date: param.date,
      Seq: parseInt(param.Seq),
      FromDate: param.FromDate,
      ToDate: param.ToDate,
      Width: param.Width,
      Config: param.Config,
    };
    //if (param.date !== "Invalid Date") {
    props.updateChart(paramChart);
    //}
  };

  const closeConfig = () => {
    setOpen(false);
  };

  const callReport = async (paramReport) => {
    const data = await showReportPDF(paramReport);
    const blob = new Blob([data], { type: "application/pdf" });
    const objUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objUrl;
    link.target = "_blank";
    link.click();
  };

  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        const chartSelect = chartWrapper.getChart().getSelection();
        if (chartSelect.length > 0) {
          const { row, column } = chartSelect[0];
          if (row) {
            const lastYear = param.FromDate.getFullYear();
            const currentYear = param.ToDate.getFullYear();

            const lastDate = `${lastYear}-${row + 1}`;
            const currentDate = `${currentYear}-${row + 1}`;

            const mysqlDate = column % 2 === 0 ? currentDate : column === 3 ? currentDate : lastDate;

            const accList = param.Config.Account.Value.split(",");
            const deptList = param.Config.Department.Value.split(",");

            const paramReportTrialBalance = {
              Id: 205,
              Type: "Report",
              FileFormat: "pdf",
              Parameters: [
                { Name: "DateFrom", Value: mysqlDate },
                { Name: "DateTo", Value: mysqlDate },
                { Name: "DeptCodeFrom", Value: deptList[0] },
                { Name: "DeptCodeTo", Value: deptList[deptList.length - 1] },
                { Name: "AccCodeFrom", Value: accList[0] },
                { Name: "AccCodeTo", Value: accList[accList.length - 1] },
                { Name: "AccType", Value: "A" },
                { Name: "AccStatus", Value: "All" },
                { Name: "GroupBy", Value: "None" },
              ],
            };

            const paramReportBudget = {
              Id: 202,
              Type: "Report",
              FileFormat: "pdf",
              Parameters: [
                { Name: "YearFrom", Value: currentYear },
                { Name: "YearTo", Value: currentYear },
                { Name: "AccCodeFrom", Value: accList[0] },
                { Name: "AccCodeTo", Value: accList[accList.length - 1] },
                { Name: "DeptFrom", Value: deptList[0] },
                { Name: "DeptTo", Value: deptList[deptList.length - 1] },
                { Name: "Revision", Value: "0" },
                { Name: "ViewStatus", Value: "All" },
              ],
            };
            const msg = "Do you want to open report?";
            SnackbarUtils.confirm(msg, async function () {
              if (column === 3) {
                callReport(paramReportBudget);
              } else {
                callReport(paramReportTrialBalance);
              }
            });
          }
        }
      },
    },
  ];

  function downloadBase64File(linkSource, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const SaveDashboardToImg = (id) => {
    const divBox = document.getElementById(id);
    html2canvas(divBox, {
      backgroundColor: "white",
      ignoreElements: (element) => {
        /* Remove element with id="MyElementIdHere" */
        if ("noPrint" === element.id) {
          return true;
        }
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      downloadBase64File(imgData, name);
    });
  };

  return (
    <>
      {loading ? (
        <div className={classes.circulLoading}>
          <CircularProgress />
        </div>
      ) : (
        <div id={name}>
          <Card className={classes.card} elevation={6}>
            <CardHeader
              className={classes.cardHeader}
              action={
                <div id="noPrint">
                  <IconButton
                    aria-label="print report Trial Balance"
                    onClick={async () => {
                      const currentYear = param.FromDate.getFullYear();
                      const currentMonth = param.ToDate.getMonth() + 1;
                      const fromDate = `${currentYear}-01`;
                      const toDate = `${currentYear}-${currentMonth}`;

                      const accList = param.Config.Account.Value.split(",");
                      const deptList = param.Config.Department.Value.split(",");

                      const paramReportTrialBalance = {
                        Id: 205,
                        Type: "Report",
                        FileFormat: "pdf",
                        Parameters: [
                          { Name: "DateFrom", Value: fromDate },
                          { Name: "DateTo", Value: toDate },
                          { Name: "DeptCodeFrom", Value: deptList[0] },
                          { Name: "DeptCodeTo", Value: deptList[deptList.length - 1] },
                          { Name: "AccCodeFrom", Value: accList[0] },
                          { Name: "AccCodeTo", Value: accList[accList.length - 1] },
                          { Name: "AccType", Value: "A" },
                          { Name: "AccStatus", Value: "All" },
                          { Name: "GroupBy", Value: "None" },
                        ],
                      };

                      const data = await showReportPDF(paramReportTrialBalance);
                      const blob = new Blob([data], { type: "application/pdf" });
                      const objUrl = window.URL.createObjectURL(blob);
                      const link = document.createElement("a");
                      link.href = objUrl;
                      link.target = "_blank";
                      link.click();
                    }}
                  >
                    <Icon>print</Icon>
                  </IconButton>
                  <IconButton aria-label="settings" onClick={() => SaveDashboardToImg(name)}>
                    <Icon>save_alt_icon</Icon>
                  </IconButton>
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      param.FromDate = addYears(addDays(new Date(), -1), -1);
                      param.ToDate = addDays(new Date(), -1);
                      updateChart(param);
                    }}
                  >
                    <Icon>refresh</Icon>
                  </IconButton>
                  <IconButton
                    id="basic-button"
                    aria-controls={openWidgetMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openWidgetMenu ? "true" : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <IconButton id="drag-button" style={{ cursor: "row-resize" }}>
                    {dragHandle}
                  </IconButton>
                  {open && (
                    <ConfigDashboard
                      open={open}
                      close={closeConfig}
                      updateChart={updateChart}
                      param={param}
                      updateParam={setParam}
                    />
                  )}
                </div>
              }
              title={
                <Typography variant="body1">
                  <b>{name}</b>
                </Typography>
              }
              subheader={
                <Typography variant="body2">
                  {param.FromDate ? new Date(param.FromDate).getFullYear() : ""} -{" "}
                  {param.ToDate ? new Date(param.ToDate).getFullYear() : ""}
                </Typography>
              }
            />
            <Menu id="widget-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem
                key={1}
                onClick={() => {
                  setOpen(true);
                  setAnchorEl(null);
                }}
              >
                Edit Widget
              </MenuItem>
              <MenuItem
                key={2}
                onClick={() => {
                  const msg = "Are you sure delete this widget?";
                  SnackbarUtils.loadingConfirm(
                    msg,
                    async function () {
                      await deleteWidgetById(props.Id);
                      return props.Id;
                    },
                    async function (id) {
                      props.removeChart(id);
                    }
                  );
                  setAnchorEl(null);
                }}
              >
                Delete Widget
              </MenuItem>
            </Menu>
            <CardContent className={classes.cardContent}>
              {data ? (
                <Chart
                  width="100%"
                  height="300px"
                  chartType="ColumnChart"
                  //chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={data}
                  options={{
                    bars: "vertical",
                    vAxis: { format: isXSmall ? "short" : "decimal" },
                    colors: ["#bdbdbd", "#2196f3", "#FEDE00"],
                    legend: { position: "bottom" },
                    chartArea: {
                      width: "85%",
                    },
                  }}
                  chartEvents={chartEvents}
                />
              ) : (
                ""
              )}
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default BudgetBarChart;
