/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from "react";
//import { useHistory } from "react-router-dom";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Grid, Checkbox, Switch, Divider, Typography } from "@material-ui/core";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import { AccTypeOptions, AccModuleOptions } from "utils/options";
import { TextFieldInForm, SelectInForm } from "components/Form";
import {
	getAccountCodeDetail,
	createAccountCodeDetail,
	updateAccountCodeDetail,
	delAccountCodeDetail,
} from "services/setting";
import Model from "models/accountCode";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: 20,
	},
	appBar: {
		position: "relative",
		backgroundColor: theme.palette.primary.main,
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	content: {
		padding: 4,
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

const DialogTitle = (props) => {
	const { children, onClose, ...other } = props;
	const classes = useStyles();
	return (
		<MuiDialogTitle disableTypography {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

const DialogItem = (props) => {
	const classes = useStyles();
	//const history = useHistory();
	const {
		children,
		id,
		permission,
		title,
		mode,
		setMode,
		open,
		onClose,
		translate,
	} = props;
	const [isBtnLoading, setBtnLoading] = React.useState(false);
	const [data, setData] = useStateWithCallbackLazy(Model);

	const methods = useForm({ defaultValues: data });

	const { handleSubmit, reset, control } = methods;

	const fetchAccById = useCallback(async () => {
		if (id && id !== 0) {
			const response = await getAccountCodeDetail(id);
			if (response) {
				response.Type =
					response.Type === "Income"
						? "Income Statement"
						: response.Type === "BalanceSheet"
						? "Balance Sheet"
						: response.Type;
				setData(response);
				reset(response);
			}
		} else {
			setData(Model);
			reset(Model);
		}
		setBtnLoading(false);
	}, [id, reset]);

	useEffect(() => {
		fetchAccById();
	}, [fetchAccById]);

	const disableFormEnter = (e) => {
		if (e.key === "Enter" && e.target.localName !== "textarea")
			e.preventDefault();
	};

	const onSubmit = (values) => {
		setBtnLoading(true);
		if (
			values.UseInAp ||
			values.UseInAr ||
			values.UseInAsset ||
			values.UseInGl
		) {
			//Adjust parameter before save
			setData(
				(state) => ({
					...state,
					...values,
					UserModified: Model.UserModified,
				}),
				(nextState) => Save(nextState),
			);
		} else {
			SnackbarUtils.warning("Please select module.");
			setBtnLoading(false);
			return;
		}
	};

	const Save = async (values) => {
		//values.Type = values.Type === "Income Statement" ? "Income" : values.Type;
		values.Type =
			values.Type === "Income Statement"
				? "Income"
				: values.Type === "Balance Sheet"
				? "BalanceSheet"
				: values.Type;
		if (mode === "edit") {
			values.Id = id;
			//Update
			const { Code, InternalMessage, UserMessage } =
				await updateAccountCodeDetail(values);
			if (Code === 0) {
				SnackbarUtils.success(UserMessage, function () {
					handleClose(id);
				});
				setBtnLoading(false);
			} else {
				setBtnLoading(false);
				if (InternalMessage) {
					SnackbarUtils.error(InternalMessage);
				} else {
					SnackbarUtils.warning(UserMessage);
				}
			}
		} else {
			const { Code, InternalMessage, UserMessage } =
				await createAccountCodeDetail(values);
			if (Code === 0) {
				SnackbarUtils.success(UserMessage, function () {
					handleClose(parseInt(InternalMessage));
				});
				setBtnLoading(false);
			} else {
				setBtnLoading(false);
				if (InternalMessage) {
					SnackbarUtils.error(InternalMessage);
				} else {
					SnackbarUtils.warning(UserMessage);
				}
			}
		}
	};

	const handleClose = (value) => {
		onClose(value);
	};

	const menuControlProp = [
		// {
		//   name: "View Account Summary",
		//   fnc: () => {
		//     localStorage.setItem("viewFromAccCode", data.AccCode);
		//     localStorage.setItem("viewToAccCode", data.AccCode);
		//     history.push("/accountSummary");
		//   },
		//   disabled: mode !== "view",
		// },
		{
			name: "Edit",
			fnc: () => {
				setMode("edit");
			},
			disabled: mode !== "view",
		},
		{
			name: "Delete",
			fnc: () => DelOrVoid(id),
			disabled: mode !== "view",
		},
	];

	const DelOrVoid = async (id) => {
		let msg = translate("ra.question.confirmDel");
		SnackbarUtils.delConfirm(msg, async function () {
			const { Code, UserMessage } = await delAccountCodeDetail(id);
			if (Code === 0) {
				SnackbarUtils.success(UserMessage, function () {
					handleClose(id);
				});
			}
		});
	};

	const CheckBtn = () => {
		if (mode === "view") {
		  return true;
		} else {
		  if (data.Used) {
			return true;
		  } else {
			return false;
		  }
		}
	  };

	return (
		<div>
			<Dialog
				open={open}
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						onClose(event);
					}
				}}
				scroll={"paper"}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle id="scroll-dialog-title" onClose={onClose}>
					{title}
				</DialogTitle>
				<DialogContent dividers className={classes.content}>
					<ActionMenu
						menuControl={menuControlProp}
						permission={permission}
						justifyContent="flex-start"
					/>
					<Divider />
					<form onKeyDown={disableFormEnter}>
						<div className={classes.root}>
							<Grid container spacing={1} justifyContent="flex-start">
								<Grid item xs={6} elevation={2}>
									<TextFieldInForm
										label={`* ${translate("ra.fieldAbbr.accountCode")}`}
										name="AccCode"
										variant="outlined"
										margin="dense"
										methods={methods}
										disabled={mode !== "add"}
										rule={{
											required: {
												value: true,
												message: `* ${translate("ra.validation.required")}`,
											},
											maxLength: {
												value: 15,
												message: `${translate("ra.validation.maxLength", 15)}`,
											},
										}}
									/>
								</Grid>
								<Grid item xs={4} elevation={2}>
									<FormControl fullWidth variant="outlined">
										<Controller
											name="Active"
											control={control}
											defaultValue=""
											render={(props) => {
												return (
													<FormControlLabel
														value={props.value}
														control={
															<Switch
																checked={
																	typeof props.value === "boolean"
																		? props.value
																		: false
																}
																onChange={(e, newValue) =>
																	props.onChange(newValue)
																}
																disabled={mode === "view"}
															/>
														}
														label={
															props.value
																? `${translate("ra.fieldAbbr.active")}`
																: `${translate("ra.fieldAbbr.inactive")}`
														}
														labelPlacement="right"
														color="primary"
														style={{ marginLeft: 14 }}
													/>
												);
											}}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} elevation={2}>
									<TextFieldInForm
										label={`${translate("ra.fieldAbbr.desc")}`}
										name="Description"
										variant="outlined"
										margin="dense"
										methods={methods}
										disabled={mode === "view"}
										rule={{
											maxLength: {
												value: 255,
												message: `${translate("ra.validation.maxLength", 255)}`,
											},
										}}
									/>
								</Grid>
								<Grid item xs={12} elevation={2}>
									<TextFieldInForm
										label={`${translate("ra.fieldAbbr.desc")} (Local)`}
										name="Description2"
										variant="outlined"
										margin="dense"
										methods={methods}
										disabled={mode === "view"}
										rule={{
											maxLength: {
												value: 255,
												message: `${translate("ra.validation.maxLength", 255)}`,
											},
										}}
									/>
								</Grid>
								<Grid item xs={6} elevation={2}>
									<SelectInForm
										label={translate("ra.fieldAbbr.nature")}
										name="Nature"
										options={["Debit", "Credit"]}
										methods={methods}
										rules={{
											required: "Required",
										}}
										disabled={CheckBtn()}
									/>
								</Grid>
								<Grid item xs={6} elevation={2}>
									<SelectInForm
										label={translate("ra.fieldAbbr.type")}
										name="Type"
										options={AccTypeOptions}
										methods={methods}
										disabled={CheckBtn()}
									/>
								</Grid>
								<Divider />
								<Grid item xs={12} elevation={2} style={{ marginTop: 4 }}>
									<FormLabel component="legend">Available in modules</FormLabel>
									{AccModuleOptions.map((item, idx) => (
										<FormControl component="fieldset" key={idx}>
											<Controller
												name={item.value}
												control={control}
												defaultValue=""
												render={(props) => {
													return (
														<FormControlLabel
															value={props.value}
															control={
																<Checkbox
																	checked={
																		typeof props.value === "boolean"
																			? props.value
																			: false
																	}
																	onChange={(e, newValue) =>
																		props.onChange(newValue)
																	}
																	disabled={mode === "view"}
																/>
															}
															label={item.name}
														/>
													);
												}}
											/>
										</FormControl>
									))}
								</Grid>
							</Grid>
						</div>
						{children}
					</form>
					<pre>
						{process.env.NODE_ENV === "development"
							? JSON.stringify(data, 0, 2)
							: ""}
					</pre>
				</DialogContent>
				{mode !== "view" ? (
					<DialogActions>
						<ButtonFooter
							noBorder
							disabled={isBtnLoading}
							SaveFnc={handleSubmit(onSubmit)}
							CancelFnc={onClose}
						/>
					</DialogActions>
				) : (
					""
				)}
			</Dialog>
		</div>
	);
};

export default DialogItem;
