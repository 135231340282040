import React, { useContext, useState } from "react";
import { GblContext } from "providers/formatter";
import html2canvas from "html2canvas";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  Menu,
  MenuItem,
  Icon,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ConfigDashboard from "./Dialog/ConfigDashboard";
import DisplayValuePercent from "components/DisplayValuePercent";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import { deleteWidgetById } from "services/callStoreProcedure";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    display: "flex",
    flexDirection: "column",
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  cardHeader: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  cardContent: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
  large: {
    margin: "0 auto",
    width: theme.spacing(7),
    height: theme.spacing(7),
    //backgroundColor: theme.palette.secondary.main,
    color: theme.palette.type === "dark" ? "inherit" : "rgba(0, 0, 0, 0.87)",
  },
}));

const SectionCardChart = (props) => {
  const classes = useStyles();
  const [param, setParam] = useState({
    Id: props.Id,
    //date: props.date,
    name: props.name,
    Seq: props.Seq,
    TypeOfDate:
      new Date().setUTCHours(0, 0, 0, 0) > new Date(props.ToDate).setUTCHours(0, 0, 0, 0) ? "Custom" : props.TypeOfDate,
    FromDate: props.FromDate,
    ToDate: props.ToDate,
    Width: props.Width,
    configName: props.configName,
    Config: props.Config,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openWidgetMenu = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const { DateToString, CurrencyFormat } = useContext(GblContext);

  if (!props.data) {
    return "";
  }

  const { name, data, configName, Width, dragHandle } = props;

  const updateChart = async (param) => {
    setLoading(true);
    const paramChart = {
      ...props,
      Id: param.Id,
      name: param.name,
      //date: param.date,
      Seq: parseInt(param.Seq),
      TypeOfDate: param.TypeOfDate,
      FromDate: param.FromDate,
      ToDate: param.ToDate,
      Width: param.Width,
      Config: param.Config,
    };

    //if (param.date !== "Invalid Date") {
    props.updateChart(paramChart);
    //}
  };

  const closeConfig = () => {
    setOpen(false);
  };

  function downloadBase64File(linkSource, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const SaveDashboardToImg = (id) => {
    const divBox = document.getElementById(id);
    html2canvas(divBox, {
      backgroundColor: "white",
      ignoreElements: (element) => {
        /* Remove element with id="MyElementIdHere" */
        if ("noPrint" === element.id) {
          return true;
        }
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      downloadBase64File(imgData, name);
    });
  };

  return (
    <>
      {loading ? (
        <div className={classes.circulLoading}>
          <CircularProgress />
        </div>
      ) : (
        <div id={name}>
          <Card className={classes.card} elevation={6}>
            <CardHeader
              className={classes.cardHeader}
              action={
                <div id="noPrint">
                  <IconButton aria-label="settings" onClick={() => SaveDashboardToImg(name)}>
                    <Icon>save_alt_icon</Icon>
                  </IconButton>
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      //param.date = addDays(new Date(), -1);
                      param.TypeOfDate = "Yesterday";
                      param.FromDate = addDays(new Date(), -1);
                      param.ToDate = addDays(new Date(), -1);
                      updateChart(param);
                    }}
                  >
                    <Icon>refresh</Icon>
                  </IconButton>
                  <IconButton
                    id="basic-button"
                    aria-controls={openWidgetMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openWidgetMenu ? "true" : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <IconButton id="drag-button" style={{ cursor: "row-resize" }}>
                    {dragHandle}
                  </IconButton>
                  {open && (
                    <ConfigDashboard
                      open={open}
                      close={closeConfig}
                      updateChart={updateChart}
                      param={param}
                      updateParam={setParam}
                      size={configName === "Statistical Highlight" ? "md" : "sm"}
                    />
                  )}
                </div>
              }
              title={
                <Typography variant="body1">
                  <b>{name}</b>
                </Typography>
              }
              subheader={
                <Typography variant="body2">
                  {configName === "Statistical Highlight"
                    ? `${DateToString(param.FromDate ?? new Date())} - ${DateToString(param.ToDate ?? new Date())}`
                    : `${
                        param.FromDate.getMonth() < 9
                          ? `0${(param.FromDate.getMonth() + 1)}`
                          : param.FromDate.getMonth() + 1
                      }/${param.FromDate.getFullYear()}`}
                </Typography>
              }
            />
            <Menu id="widget-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem
                key={1}
                onClick={() => {
                  setOpen(true);
                  setAnchorEl(null);
                }}
              >
                Edit Widget
              </MenuItem>
              <MenuItem
                key={2}
                onClick={() => {
                  const msg = "Are you sure delete this widget?";
                  SnackbarUtils.loadingConfirm(
                    msg,
                    async function () {
                      await deleteWidgetById(props.Id);
                      return props.Id;
                    },
                    async function (id) {
                      props.removeChart(id);
                    }
                  );
                  setAnchorEl(null);
                }}
              >
                Delete Widget
              </MenuItem>
            </Menu>
            <CardContent className={classes.cardContent}>
              {configName === "Statistical Highlight" ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  // spacing={3}
                >
                  {data.map((item,idx) => (
                    <React.Fragment key={idx}>
                      {isXSmall && (
                        <Grid item xs={6} sm={2} key={`smallBox-${idx}`} style={{ padding: 6 }}>
                          <Box>
                            <Avatar variant="rounded" className={classes.large} style={{ background: item.color }}>
                              <Icon fontSize="large">{item.icon}</Icon>
                            </Avatar>
                          </Box>
                        </Grid>
                      )}
                      {Width === "50" && !isXSmall && (
                        <Grid item xs={6} sm={6} key={`smallBox-${idx}`} style={{ padding: 6 }}>
                          <Box>
                            <Avatar variant="rounded" className={classes.large} style={{ background: item.color }}>
                              <Icon fontSize="large">{item.icon}</Icon>
                            </Avatar>
                          </Box>
                        </Grid>
                      )}

                      <Grid item xs={6} sm={Width === "50" ? 6 : 2} key={`text-${idx}`} style={{ padding: 6 }}>
                        <Box>
                          <Typography variant="body1" component="h6">
                            <Box textAlign="right" style={{ textTransform: "uppercase" }}>
                              {item.name}
                            </Box>
                          </Typography>
                          <Box textAlign="right">
                            <Typography variant="body1" component="h6">
                              <b>{CurrencyFormat(item.value1, item.unit) ?? 0}</b>
                            </Typography>
                          </Box>
                          <Box textAlign="right">
                            <Typography variant="body2" component="h6">
                              {item.value2 !== null ? `${CurrencyFormat(item.value2) ?? 0} %` : ""}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      {item.icon != null && !isXSmall && Width === "100" ? (
                        <Grid item xs={12} sm={1} key={`icon-${idx}`}>
                          <Box textAlign="center">
                            <Avatar variant="rounded" className={classes.large} style={{ background: item.color }}>
                              <Icon fontSize="large">{item.icon}</Icon>
                            </Avatar>
                          </Box>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ))}
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="flex-start"
                  spacing={2}
                  className={classes.main}
                >
                  {data.map((item,idx) => (
                    <React.Fragment key={idx}>
                      <Grid item xs={12} sm={Width === "50" ? 6 : 2} key={`text-${idx}`} style={{ padding: 6 }}>
                        <Box>
                          <Box textAlign="center">
                            <Tooltip title="% Increase from last month" placement="top">
                              <div>
                                <DisplayValuePercent unit={item.unit} value={item.value2} />
                              </div>
                            </Tooltip>
                          </Box>
                          <Box textAlign="center">
                            <b>{CurrencyFormat(item.value1 ?? 0, item.unit)}</b>
                          </Box>
                          <Typography variant="body2" component="h6">
                            <Box textAlign="center" style={{ textTransform: "uppercase" }}>
                              {item.name}
                            </Box>
                          </Typography>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              )}
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default SectionCardChart;
