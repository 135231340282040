export const glJv = {
  JvhSeq: -1,
  JvhDate: new Date(),
  Prefix: "",
  JvhNo: "Auto",
  JvhSource: "",
  Status: "Draft",
  Description: "",
  Detail: [],
  DimHList: {
    Dim: [],
  },
  UserModified: localStorage.getItem("UserName"),
};

export default glJv;
