import {
  add,
  addDays,
  format,
  eachMonthOfInterval,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from "date-fns";

export const DimensionType = ["Text", "Number", "Date", "List", "Boolean"];

export const AccTypeOptions = ["Header", "Balance Sheet", "Income Statement", "Statistic"];

export const AccModuleOptions = [
  { name: "Account Payable", value: "UseInAp" },
  { name: "Account Receivable", value: "UseInAr" },
  { name: "General Ledger", value: "UseInGl" },
  { name: "Asset Management", value: "UseInAsset" },
];

export const AssetNumberFormat = [
  { key: "Manual", value: "Manual" },
  { key: "Category+Department", value: "Auto Category + Department" },
  { key: "Category", value: "Auto Category" },
];

export const AssetLifeOptions = ["Year", "Month", "Day"];

export const DisposalTypeOptions = ["Quantity", "Amount"];

export const VatTypeOptions = ["None", "Add", "Include"];

export const InvoiceTaxStatusOptions = ["None", "Confirm", "Pending", "Unclaimed"];

export const VatTypeAR = ["None", "OutputVat"];

export const WithholdingTaxRefOption = [
  { key: "", value: "none" },
  { key: "1", value: "หัก ณ ที่จ่าย" },
  { key: "2", value: "ออกให้ตลอดไป" },
  { key: "3", value: "ออกให้ครั้งเดียว" },
];

export const AgingPeriodOptions = [
  { key: "InvoiceCreateDate", value: "by Input Date" },
  { key: "InvoiceDueDate", value: "by Due Date" },
  { key: "InvoiceDate", value: "by Invoice Date" },
];

export const PaymentPostingDateOptions = [
  { key: "PaymentDate", value: "Payment Date" },
  { key: "ChequeDate", value: "Cheque Date" },
  { key: "ChequeClearingDate", value: "ChequeClearing Date" },
];

export const MailProfileCode = ["NONE", "NOREPLY", "AP_Invoice", "AP_Payment", "AR_Invoice", "AR_Receipt"];

export const PeriodList = (date = new Date()) => {
  const startDate = add(date, { years: -3 });
  const endDate = add(date, { years: 3 });
  const arrDate = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  });
  return arrDate.map((item) => format(item, "MM/yyyy"));
};

// rome-ignore lint/style/useDefaultParameterLast: <explanation>
export const PeriodListWithYear = (date = new Date(), countStartDate, countEndDate) => {
  const startDate = add(date, { years: countStartDate });
  const endDate = add(date, { years: countEndDate });

  const arrDate = eachMonthOfInterval({
    start: startDate,
    end: endDate,
  });

  return arrDate.map((item) => format(endOfMonth(item), "dd/MM/yyyy"));
};

export const PeriodWithText = () => {
  return [
    { name: "Today", start: new Date(), end: new Date() },
    { name: "Yesterday", start: addDays(new Date(), -1), end: addDays(new Date(), -1) },
    { name: "This Week", start: startOfWeek(new Date()), end: endOfWeek(new Date()) },
    { name: "This Month", start: startOfMonth(new Date()), end: endOfMonth(new Date()) },
    { name: "This Year", start: startOfYear(new Date()), end: endOfYear(new Date()) },
    { name: "MTD", start: startOfMonth(new Date()), end: new Date() },
    { name: "YTD", start: startOfYear(new Date()), end: new Date() },
    { name: "Custom", start: "", end: "" },
  ];
};

export const GetWfStatus = (wfSteplist, listEnum, status) => {
  //check step
  if (status && listEnum) {
    if (status === "Step0") {
      return status;
    }
    const haveItem = wfSteplist?.find((item) => item.StepNo === parseInt(listEnum[status]));
    if (haveItem && status === "Effective") {
      return wfSteplist[wfSteplist.length - 1].StepName;
    }
    if (haveItem) {
      return haveItem.StepName;
    } else {
      return status;
    }
  }
};

export const convertDescModule = (code) => {
  const dataDic = [
    {
      code: "GL_Jv",
      desc: "Journal Voucher",
      url: "gljv",
    },
    {
      code: "GL_AmortizeJv",
      desc: "Amortization Voucher",
      url: "amortizeJv",
    },

    {
      code: "GL_AllocationJv",
      desc: "Allocation Voucher",
      url: "allocateJv",
    },
    {
      code: "GL_JvFr",
      desc: "Template Journal Voucher",
      url: "glJvFr",
    },
    {
      code: "Budget",
      desc: "Budget",
      url: "budget",
    },
    {
      code: "Vendor",
      desc: "Vendor",
      url: "apVendor",
    },
    {
      code: "AP_Invoice",
      desc: "AP Invoice",
      url: "apInvoice",
    },
    {
      code: "AP_Payment",
      desc: "AP Payment",
      url: "apPayment",
    },
    {
      code: "AR_Profile",
      desc: "AR Profile",
      url: "arProfile",
    },
    {
      code: "AR_Invoice",
      desc: "AR Invoice",
      url: "arInvoice",
    },
    {
      code: "AR-Receipt",
      desc: "AR Receipt",
      url: "arReceipt",
    },
    {
      code: "AST_Reg",
      desc: "Asset Register",
      url: "assetRegister",
    },
    {
      code: "AST_Disposal",
      desc: "Asset Disposal",
      url: "assetDisposal",
    },
  ];
  //return resultItem
  const resultItem = dataDic.find((i) => i.code === code);
  if (resultItem) {
    return resultItem;
  } else {
    return null;
  }
};
