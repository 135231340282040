export default {
  index: -1,
  ArContractDId: -1,
  ArContractHId:-1,
  ConDDesc: "",
  DeptCode: "",
  CrAcc: "",
  DrAcc: "",
  Qty: 1,
  Unit: "",
  Price: 0,
  NetAmt: 0,
  NetBaseAmt: 0,
  TotalAmt: 0,
  TotalBaseAmt: 0,
  TaxAcc1: "",
  TaxType1: "None",
  TaxRate1: 0,
  TaxAmt1: 0,
  TaxBaseAmt1: 0,
  TaxOverwrite1: "",
  TaxAcc2: "",
  TaxType2: "None",
  TaxRate2: 0,
  TaxAmt2: 0,
  TaxBaseAmt2: 0,
  TaxOverwrite2: "",
  ServiceCharge: 0,
  UserModified: localStorage.getItem("UserName"),
  DimList: {},
};
