import React from "react";
import clsx from "clsx";
import { Box, Typography, Chip, Popover } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import FilterMenu from "components/FilterMenu";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  statusDraft: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  statusNormal: {
    backgroundColor: "#2196f3",
    color: "white",
  },
  statusVoid: {
    backgroundColor: "#e57373",
    color: "white",
  },
  statusActive: {
    backgroundColor: "green",
    color: "white",
  },
  statusInactive: {
    backgroundColor: "#e57373",
    color: "white",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const BoxHeader = ({ header, data, source, status, showSearch, searchOption, module, wfStatus }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div>
      <Box display="flex" bgcolor="background.paper">
        <Box p={1} flexGrow={1}>
          <Typography variant="h6" gutterBottom style={{ display: "inline-block" }}>
            {translate(`ra.module.${header}`)}
          </Typography>
        </Box>

        {showSearch ? <FilterMenu searchOption={searchOption} module={module} /> : ""}
        {source ? (
          <Box p={1}>
            <Chip label={source} className={classes.statusDraft} />
          </Box>
        ) : (
          ""
        )}
        {status !== undefined ? (
          <Box p={1}>
            {typeof status === "boolean" ? (
              <Chip
                label={status ? translate("ra.field.Active") : translate("ra.field.In-Active")}
                className={clsx({
                  [classes.statusActive]: status === true,
                  [classes.statusInactive]: status === false,
                })}
              />
            ) : (
              <>
                <Chip
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  label={wfStatus ? wfStatus : translate(`ra.actionMenu.${status}`)}
                  className={clsx(classes.statusDraft, {
                    [classes.statusNormal]: status === "Normal" || status === "Effective",
                    [classes.statusVoid]: status === "Void",
                  })}
                />
                {status === "Void" && (
                  <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Alert severity="error">
                      <AlertTitle>
                        Voided by <span style={{ color: "#2196f3" }}> {data?.UserModified}</span>
                      </AlertTitle>
                      {data?.VoidRemark}
                    </Alert>
                  </Popover>
                )}
              </>
            )}
          </Box>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
};

export default BoxHeader;
