import React, { useState } from "react";
import DialogItem from "./Dialog/Workflow";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { Paper, Typography, Icon } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    cursor: "pointer",
  },
  paperNoClick: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function CustomizedTimeline(props) {
  const classes = useStyles();
  const { data, refreshData } = props;
  const [dataDetail, setDataDetail] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleModeView = (item) => {
    setDataDetail(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = (value) => {
    setOpenDialog(false);
    refreshData();
  };

  return (
    <>
      {/* <Timeline align="right">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              <div style={{ padding: "2px 7.2px" }}>0</div>
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paperNoClick}>
              <Typography variant="h6" component="h1">
                Revise
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      </Timeline> */}
      <Timeline align="alternate">
        {data.map((item, idx) => (
          <TimelineItem key={idx}>
            <TimelineSeparator>
              <TimelineDot>
                <div style={{ padding: "2px 7.2px" }}>{item.StepNo}</div>
              </TimelineDot>
              {idx === data.length - 1 ? null : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} className={classes.paper} onClick={() => handleModeView(item)}>
                <Typography variant="h6" component="h1">
                  {item.StepName} {idx === data.length - 1 ? <>(Effective)</> : null}
                </Typography>
                <Typography variant="caption" component="p">
                  {item.Description}
                </Typography>
                <Typography>
                  {item.Users?.length > 0 && <Icon style={{ lineHeight: 1.4 }}>how_to_reg_icon</Icon>}{" "}
                  <span>{item.Users?.join(", ")}</span>
                </Typography>
                {/* <Typography>{JSON.stringify(item.Rule, 0, 2)}</Typography> */}
              </Paper>
            </TimelineContent>
          </TimelineItem>
        ))}
        <br />
        {openDialog && (
          <DialogItem dataDetail={dataDetail} title={"Workflow"} open={openDialog} onClose={handleCloseDialog} />
        )}
      </Timeline>
    </>
  );
}
