import axiosAuth from "utils/request";

//---------------------------- List ----------------------------//

export async function getApVendorList() {
  const { data } = await axiosAuth.get(`/api/vendor?q=${encodeURI("{}")}`);
  return data;
}

//---------------------------- Search ----------------------------//

export async function getApVendorSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/vendor/search", uriQueryString);
  return data;
}

export async function getApInvoiceSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/apInvoice/search", uriQueryString);
  return data;
}

export async function getApPaymentSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/apPayment/search", uriQueryString);
  return data;
}

export async function getApWhtServiceSearchList(uriQueryString) {
  if (typeof uriQueryString === "undefined") {
    uriQueryString = {
      Limit: 0,
    };
  }

  const { data } = await axiosAuth.post("/api/apWht/search", uriQueryString);
  return data;
}

export async function getApWhtTypeSearchList(uriQueryString) {
  if (typeof uriQueryString === "undefined") {
    uriQueryString = {
      Limit: 0,
    };
  }
  const { data } = await axiosAuth.post("/api/apWhtType/search", uriQueryString);
  return data;
}

//---------------------------- Detail ----------------------------//

export async function getWhtTypeDetail(Id) {
  const { data } = await axiosAuth.get(`/api/apWhtType/${Id}`);
  return data;
}

export async function createWhtTypeDetail(param) {
  const { data } = await axiosAuth.post("/api/apWhtType", param);
  return data;
}

export async function updateWhtTypeDetail(param) {
  const { data } = await axiosAuth.put(`/api/apWhtType/${param.Id}`, param);
  return data;
}

export async function delWhtTypeDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/apWhtType/${Id}`);
  return data;
}

export async function getWhtServiceDetail(Id) {
  const { data } = await axiosAuth.get(`/api/apWht/${Id}`);
  return data;
}

export async function createWhtServiceDetail(param) {
  const { data } = await axiosAuth.post("/api/apWht", param);
  return data;
}

export async function updateWhtServiceDetail(param) {
  const { data } = await axiosAuth.put(`/api/apWht/${param.Id}`, param);
  return data;
}

export async function delWhtServiceDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/apWht/${Id}`);
  return data;
}

export async function getVendorDetail(Id) {
  const { data } = await axiosAuth.get(`/api/vendor/${Id}`);
  return data;
}

export async function createVendorDetail(param) {
  const { data } = await axiosAuth.post("/api/vendor", param);
  return data;
}

export async function updateVendorDetail(param) {
  const { data } = await axiosAuth.put(`/api/vendor/${param.Id}`, param);
  return data;
}

export async function delVendorDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/vendor/${Id}`);
  return data;
}

export async function getInvoiceDetail(Id) {
  const { data } = await axiosAuth.get(`/api/apInvoice/${Id}`);
  if (data?.Detail?.length > 0) {
    data.Detail.forEach((i, idx) => {
      i.index = idx;
      if (i.InvdTaxT1 === "Include") {
        i.NetAmt = !i.NetAmt ? (i.InvdPrice * i.InvdQty * data.CurRate - i.InvdTaxA1) / data.CurRate : i.NetAmt;
        i.NetBaseAmt = !i.NetBaseAmt ? i.InvdPrice * i.InvdQty * data.CurRate - i.InvdTaxA1 : i.NetBaseAmt;
      } else {
        i.NetAmt = !i.NetAmt ? i.InvdAbfTax : i.NetAmt;
        i.NetBaseAmt = !i.NetBaseAmt ? i.InvdPrice * i.InvdQty * data.CurRate : i.NetBaseAmt;
      }
      i.TotalAmt = i.TotalPrice;
    });
  }
  return data;
}

export async function getInvoiceUnpaid(vnCode) {
  const { data } = await axiosAuth.get(`/api/apInvoice/unpaid?vnCode=${vnCode}`);
  return data;
}

export async function getInvoiceNewUnpaid(vnCode, paymentDate) {
  const { data } = await axiosAuth.get(`/api/apInvoice/newunpaid?vnCode=${vnCode}&paymentDate=${paymentDate}`);
  return data;
}

export async function createInvoiceDetail(param) {
  const { data } = await axiosAuth.post("/api/apInvoice", param);
  return data;
}

export async function updateInvoiceDetail(param) {
  const { data } = await axiosAuth.put(`/api/apInvoice/${param.InvhSeq}`, param);
  return data;
}

export async function delInvoiceDetail(InvhSeq, username, remark) {
  const { data } = await axiosAuth.delete(`/api/apInvoice/${InvhSeq}?user=${username}&voidRemark=${remark}`);
  return data;
}

export async function getPaymentDetail(Id) {
  const { data } = await axiosAuth.get(`/api/apPayment/${Id}`);
  if (data) {
    if (data?.Detail?.length > 0) {
      data.Detail.forEach((i, idx) => {
        i.index = idx;
      });
    }
    if (data?.PayWht.Items.length > 0) {
      data.PayWht.Items.forEach((i, idx) => (i.index = idx));
    }
  }

  return data;
}

export async function createPaymentDetail(param) {
  const { data } = await axiosAuth.post("/api/apPayment", param);
  return data;
}

export async function updatePaymentDetail(param) {
  const { data } = await axiosAuth.put(`/api/apPayment/${param.PyhSeq}`, param);
  return data;
}

export async function delPaymentDetail(PvhSeq, username, remark) {
  const { data } = await axiosAuth.delete(`/api/apPayment/${PvhSeq}?user=${username}&voidRemark=${remark}`);
  return data;
}

//---------------------------- Other ----------------------------//

export async function getApPaymentEmailTempById(id) {
  const { data } = await axiosAuth.get(`/api/apPayment/getEmail/${id}`);
  return data;
}

export async function sendApPaymentSendEmail(param) {
  const r = await axiosAuth.post("/api/apPayment/sendEmail", param);
  if (typeof r === "string") {
    return r;
  }
  return r.data;
}
