/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import WorkflowDetail from "./WorkflowDetail";
import AccessDenied from "layout/AccessDenied";
import {
	Box,
	Typography,
	Grid,
	FormControl,
	Select,
	InputLabel,
	MenuItem,
	Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ButtonFooter from "components/ButtonFooter";
import ActionMenu from "components/ActionMenu";
import { getEnumWorkflowModule } from "services/enum";
import {
	getWorkflowByCode,
	getWorkflowStepByUser,
	getWorkflowDetailByCode,
	postInitWorkflow,
	updateStatusWorkflow,
} from "services/workflow";
import { useForm } from "react-hook-form";
import { NumberFormatInForm, SwitchInForm } from "components/Form";
import SnackbarUtils from "utils/SnackbarUtils";
import { permissionName } from "utils/constants";

const useStyles = makeStyles((theme) => ({
	tabPanel: { width: "100%", margin: "0 20px" },
	button: { textTransform: "none" },
	circulLoading: {
		margin: 50,
		display: "flex",
		justifyContent: "center",
	},
}));

const Workflow = (props) => {
	const { children, value, index, permissions } = props;

	const classes = useStyles();
	const [isBtnLoading, setBtnLoading] = useState(false);
	const [data, setData] = useState([]);
	//const [detail, setDetail] = useState([]);
	const [moduleOption, setModuleOption] = useState([]);
	const [selectedModule, setSelectedModule] = useState("AP_INVOICE");
	const [mode, setMode] = useState("view");
	const methods = useForm({ defaultValues: data });
	const { handleSubmit, reset } = methods;

	const fetch = async () => {
		const moduleList = await getEnumWorkflowModule();
		setModuleOption(Object.keys(moduleList) ?? []);

		const { data } = await getWorkflowByCode(selectedModule);
		if (data) {
			const responseDetail = await getWorkflowDetailByCode(selectedModule);
			data.Detail = responseDetail?.data;
			setData(data);
			reset(data);
		} else {
			const initData = {
				WfCode: "",
				StepCount: 2,
				Active: true,
			};
			setData(initData);
			reset(initData);
		}
		const wfVendor = await getWorkflowStepByUser("AP_VENDOR");
		const wfApInvoice = await getWorkflowStepByUser("AP_INVOICE");
		const wfApPayment = await getWorkflowStepByUser("AP_PAYMENT");
		const wfArInvoice = await getWorkflowStepByUser("AR_INVOICE");
		const wfArReceipt = await getWorkflowStepByUser("AR_Receipt");

		switch (selectedModule) {
			case "AP_VENDOR":
				if (wfVendor) {
					localStorage.setItem(
						"defaultWfVendor",
						wfVendor.data
							? wfVendor.data[0]
								? wfVendor.data[0].StepNo
								: "E"
							: "All",
					);
				} else {
					localStorage.removeItem("defaultWfVendor");
				}
				break;
			case "AP_INVOICE":
				if (wfApInvoice) {
					localStorage.setItem(
						"defaultWfApInvoice",
						wfApInvoice.data
							? wfApInvoice.data[0]
								? wfApInvoice.data[0].StepNo
								: "E"
							: "All",
					);
				} else {
					localStorage.removeItem("defaultWfApInvoice");
				}
				break;
			case "AP_PAYMENT":
				if (wfApPayment) {
					localStorage.setItem(
						"defaultWfApPayment",
						wfApPayment.data
							? wfApPayment.data[0]
								? wfApPayment.data[0].StepNo
								: "E"
							: "All",
					);
				} else {
					localStorage.removeItem("defaultWfApPayment");
				}
				break;
			case "AR_INVOICE":
				if (wfArInvoice) {
					localStorage.setItem(
						"defaultWfArInvoice",
						wfArInvoice.data
							? wfArInvoice.data[0]
								? wfArInvoice.data[0].StepNo
								: "E"
							: "All",
					);
				} else {
					localStorage.removeItem("defaultWfArInvoice");
				}
				break;
			case "AR_Receipt":
				if (wfArReceipt) {
					localStorage.setItem(
						"defaultWfwfArReceipt",
						wfArReceipt.data
							? wfArReceipt.data[0]
								? wfArReceipt.data[0].StepNo
								: "E"
							: "All",
					);
				} else {
					localStorage.removeItem("defaultWfwfArReceipt");
				}
				break;
			default:
				break;
		}
		setBtnLoading(false);
	};

	React.useEffect(() => {
		if (value === index) {
			fetch();
		}
	}, [value, selectedModule]);

	//   const handleModeView = (id) => {
	//     setItemId(id);
	//     setMode("view");
	//     setOpenDialog(true);
	//   };

	const disableFormEnter = (e) => {
		if (e.key === "Enter" && e.target.localName !== "textarea")
			e.preventDefault();
	};

	const onSubmit = async (values) => {
		const param = {
			WfCode: selectedModule,
			...values,
		};
		setBtnLoading(true);
		//if have stepCount update only status
		if (data.StepCount !== 0) {
			const { Code, UserMessage } = await updateStatusWorkflow(param);
			if (Code === 0) {
				SnackbarUtils.success(UserMessage);
				setMode("view");
				fetch();
			} else {
				SnackbarUtils.warning(UserMessage);
				setBtnLoading(false);
			}
		} else {
			const { Code, UserMessage } = await postInitWorkflow(param);
			if (Code === 0) {
				SnackbarUtils.success(UserMessage);
				setMode("view");
				fetch();
			} else {
				SnackbarUtils.warning(UserMessage);
				setBtnLoading(false);
			}
		}
	};

	const CancelFnc = () => {
		setMode("view");
		fetch();
	};

	const menuControlProp = [
		{
			name: "Edit",
			fnc: () => {
				setMode("edit");
			},
			disabled: mode !== "view",
		},
	];

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			className={classes.tabPanel}
		>
			{value === index ? (
				<>
					<Box p={3}>
						<Typography>
							<b>{children}</b>
						</Typography>
					</Box>
					<div style={{ marginTop: -74 }}>
						<ActionMenu
							menuControl={menuControlProp}
							permission={permissions.find(
								(i) => i.Name === permissionName["Sys.Workflow"],
							)}
						/>
					</div>
					<Divider />
					<form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
						<Grid
							container
							justifyContent="flex-start"
							spacing={1}
							style={{ marginTop: 20, marginBottom: 20 }}
						>
							<Grid item xs={4} md={3} style={{ marginTop: 4 }}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel id="m">Module</InputLabel>
									<Select
										variant="outlined"
										margin="dense"
										labelId="m"
										label="Module"
										value={selectedModule}
										onChange={(e) => {
											setSelectedModule(e.target.value);
										}}
										//disabled={mode !== "view"}
									>
										{moduleOption.map((item, idx) => (
											<MenuItem key={idx} value={item}>
												{item}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							{data && (
								<>
									<Grid item sm={2} md={2}>
										<NumberFormatInForm
											label="StepCount"
											name="StepCount"
											methods={methods}
											disabled={mode === "view" || data.StepCount !== 0}
											decimal={0}
											rule={{
												min: {
													value: 2,
													message: "minimum length is 2",
												},
												max: {
													value: 9,
													message: "maximum length is 9",
												},
											}}
										/>
									</Grid>
									<Grid item sm={4} md={4}>
										<SwitchInForm
											name="Active"
											methods={methods}
											disabled={mode === "view"}
											defaultChecked
										/>
									</Grid>
								</>
							)}
						</Grid>
						{data?.Detail && (
							<WorkflowDetail data={data?.Detail} refreshData={fetch} />
						)}
						{mode !== "view" && (
							<ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />
						)}
					</form>
					<pre>
						{process.env.NODE_ENV === "development"
							? JSON.stringify(data, 0, 2)
							: ""}
					</pre>
					<pre>
						{process.env.NODE_ENV === "development"
							? JSON.stringify(data.Detail, 0, 2)
							: ""}
					</pre>
				</>
			) : (
				<AccessDenied />
			)}
		</div>
	);
};

export default Workflow;
