/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
//import clsx from "clsx";
import { useRedirect, useTranslate } from "react-admin";
import { Paper, Grid, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
//import NavRight from "components/NavRightSide";
import { createAmortizeStdJvDetail } from "services/generalLedger";
import SnackbarUtils from "utils/SnackbarUtils";
import { startOfMonth, endOfMonth, differenceInHours, differenceInCalendarMonths } from "date-fns";

const Create = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { basePath, formFields, formFieldsDaily, formFieldsMonthly, formFieldsInfo, formFieldsAccount } = props;
  const redirect = useRedirect();
  const { ToNumber } = useContext(GblContext);
  const [initData, setInitData] = useStateWithCallbackLazy({
    FJvhFreq: 1,
    Prefix: "",
    TypeOfAmortize: "Day In Month",
    StartDate: new Date(),
    EndDate: new Date(),
    CurCode: "",
    CurRate: "",
    AmortizeAmt: 0,
    AmortizeBAmt: 0,
    DayCount: 1,
    Description: "",
    DrDeptCode: "",
    DrAccCode: "",
    CrDeptCode: "",
    CrAccCode: "",
    UserModified: localStorage.getItem("UserName"),
  });
  const [isBtnLoading, setBtnLoading] = useState(false);

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Delete", disabled: true },
    // { name: "Print", disabled: true },
  ];

  const methods = useForm({ defaultValues: initData });

  const { handleSubmit, getValues } = methods;

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async () => {
    const values = getValues();

    setBtnLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setInitData(
      (state) => ({
        ...state,
        ...values,
        StartDate: initData.StartDate,
        EndDate: initData.EndDate,
        DayCount: initData.DayCount,
        TypeOfAmortize: initData.TypeOfAmortize === "Day In Month" ? "DayInMonth" : initData.TypeOfAmortize,
      }),
      (nextState) => Save(nextState)
    );
  };

  //const [openDim, setOpenDim] = useState(false);

  const UpdateForm = (e) => {
    const values = getValues();
    if (Object.keys(values).length !== 0) {
      if (e.target.name === "TypeOfAmortize") {
        if (values.TypeOfAmortize === "Monthly") {
          const fDate = startOfMonth(new Date(initData.StartDate));
          const eDate = endOfMonth(new Date(initData.EndDate));
          const diffMonth = Math.abs(differenceInCalendarMonths(fDate, eDate));
          setInitData((state) => ({
            ...state,
            StartDate: fDate,
            EndDate: eDate,
            TypeOfAmortize: values.TypeOfAmortize,
            DayCount: diffMonth + 1,
          }));
        } else {
          const fDate = new Date(initData.StartDate);
          const eDate = new Date(initData.EndDate);
          const diffDate = Math.abs(differenceInHours(fDate, eDate) / 24) | 0;
          setInitData((state) => ({
            ...state,
            TypeOfAmortize: values.TypeOfAmortize,
            DayCount: diffDate + 1,
          }));
        }
      }

      if (values.TypeOfAmortize === "Day In Month") {
        const fDate = new Date(values.StartDate);
        const eDate = new Date(values.EndDate);
        const diffDate = Math.abs(differenceInHours(fDate, eDate) / 24) | 0;

        setInitData((state) => ({
          ...state,
          TypeOfAmortize: values.TypeOfAmortize,
          StartDate: fDate,
          EndDate: eDate,
          DayCount: diffDate + 1,
        }));
      }

      if (values.TypeOfAmortize === "Monthly") {
        const fDate = startOfMonth(new Date(values.StartDate));
        const eDate = endOfMonth(new Date(values.EndDate));
        const diffMonth = Math.abs(differenceInCalendarMonths(fDate, eDate));
        setInitData((state) => ({
          ...state,
          TypeOfAmortize: values.TypeOfAmortize,
          StartDate: fDate,
          EndDate: eDate,
          DayCount: diffMonth + 1,
        }));
      }

      if (e.target.name === "AmortizeAmt") {
        const amortizeAmt = ToNumber(values.AmortizeAmt);
        const rate = ToNumber(values.CurRate);
        setInitData((state) => ({
          ...state,
          AmortizeBAmt: amortizeAmt * rate,
        }));
        methods.setValue("AmortizeBAmt", amortizeAmt * rate);
      }

      if (e.target.name === "CurRate") {
        const amortizeAmt = ToNumber(values.AmortizeAmt);
        const rate = ToNumber(values.CurRate);
        setInitData((state) => ({
          ...state,
          AmortizeBAmt: amortizeAmt * rate,
        }));
        methods.setValue("AmortizeBAmt", amortizeAmt * rate);
      }
    }
  };

  const CheckValueBeforePost = (values) => {
    if (ToNumber(values.AmortizeAmt) === 0) {
      SnackbarUtils.info(translate("ra.gl.amortizeAmtWarnningZero"));
      setBtnLoading(false);
      return false;
    } else {
      return true;
    }
  };

  const Save = async (values) => {
    const isPass = CheckValueBeforePost(values);
    if (isPass) {
      SaveFnc(values);
    } else {
      setBtnLoading(false);
    }
  };

  const SaveFnc = async (values) => {
    values.TypeOfAmortize = values.TypeOfAmortize.trim();
    const { Code, InternalMessage, UserMessage } = await createAmortizeStdJvDetail(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage, function () {
        redirect(`${InternalMessage}/show`);
      });
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
    }
  };

  // const ShowDim = () => {
  //   setOpenDim(!openDim);
  // };

  const CancelFnc = () => {
    redirect("list", basePath);
  };

  return (
    <div
    // className={clsx(classes.drawer, {
    //   [classes.drawerOpen]: openDim,
    //   [classes.drawerClose]: !openDim,
    // })}
    >
      <ActionMenu menuControl={menuControlProp} />

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Paper style={{ padding: 16 }}>
          <BoxHeader header={"Amortization Voucher"} status={initData.Status} />
          <Grid container alignItems="flex-start" spacing={1}>
            {formFields
              ? formFields.map((item, idx) => (
                  <Grid item xs={item.size} key={idx}>
                    {React.createElement(item.field.type, {
                      ...{
                        ...item.field.props,
                        methods,
                        key: item.field.props.name,
                        onChange: UpdateForm,
                      },
                    })}
                  </Grid>
                ))
              : ""}
            {formFieldsDaily && methods.watch("TypeOfAmortize") === "Day In Month"
              ? formFieldsDaily.map((item, idx) => (
                  <Grid item xs={item.size} key={idx} style={item.style}>
                    {React.createElement(item.field.type, {
                      ...{
                        ...item.field.props,
                        methods,
                        key: item.field.props.name,
                        onChange: UpdateForm,
                      },
                    })}
                  </Grid>
                ))
              : ""}

            {formFieldsMonthly && methods.watch("TypeOfAmortize") === "Monthly"
              ? formFieldsMonthly.map((item, idx) => (
                  <Grid item xs={item.size} key={idx} style={item.style}>
                    {React.createElement(item.field.type, {
                      ...{
                        ...item.field.props,
                        methods,
                        key: item.field.props.name,
                        onChange: UpdateForm,
                      },
                    })}
                  </Grid>
                ))
              : ""}

            {formFieldsInfo
              ? formFieldsInfo.map((item, idx) => (
                  <Grid item xs={item.size} key={idx} style={item.style}>
                    {React.createElement(item.field.type, {
                      ...{
                        ...item.field.props,
                        methods,
                        key: item.field.props.name,
                        onChange: UpdateForm,
                      },
                    })}
                  </Grid>
                ))
              : ""}
          </Grid>
          <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
            <Grid item xs={12}>
              <Typography className={classes.heading} style={{ marginLeft: 4 }}>
                {translate("ra.module.Amortize Account")}
              </Typography>
              <br />
              <Grid container spacing={1} alignItems="center">
                {formFieldsAccount
                  ? formFieldsAccount.map((item, idx) => (
                      <Grid item xs={item.size} key={idx}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(initData, 0, 2) : ""}</pre>
        <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />
      </form>

      {/* <NavRight open={openDim} close={() => setOpenDim(false)} ShowDim={() => ShowDim()} dataDim={[]} /> */}
    </div>
  );
};

export default Create;
