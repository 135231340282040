import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import {
  TextFieldInForm,
  NumberFormatInForm,
  MuiAutosuggest,
  DescInForm,
  SwitchInForm,
  SelectInForm,
} from "components/Form";
import { getWorkflowByCode, getWorkflowStepByUser } from "services/workflow";
import { getEnumApInvoiceStatus } from "services/enum";
import { getAccountCodeList, getDepartmentList, getVdCategoryList, getApPaymentTypeList } from "services/setting";
import { getApWhtServiceSearchList, getApWhtTypeSearchList } from "services/accountPayable";
import List from "./List";
import Show from "./Show";
import Edit from "./Edit";
import Create from "./Create";
import { VatTypeOptions } from "utils/options";
import Hidden from "@material-ui/core/Hidden";

const ViewMode = (props) => {
  const [wfActive, setWfActive] = useState(false);
  const [wfSteps, setWfSteps] = useState();
  const [wfEnumStatus, setWfEnumStatus] = useState();

  const fetchWorkflowByCode = useCallback(async (wfCode) => {
    const r = await getWorkflowByCode(wfCode);
    if (r?.data?.Active) {
      setWfActive(true);
      const enumData = await getEnumApInvoiceStatus();
      setWfEnumStatus(enumData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchWorkflowSteps = useCallback(async (wfCode) => {
    const { data } = await getWorkflowStepByUser(wfCode);
    setWfSteps(data);
  }, []);

  useEffect(() => {
    fetchWorkflowByCode("AP_VENDOR");
    fetchWorkflowSteps("AP_VENDOR");
  }, [fetchWorkflowByCode, fetchWorkflowSteps]);

  return <Show {...props} wfActive={wfActive} wfEnumStatus={wfEnumStatus} wfSteps={wfSteps} />;
};

const SwitchActionMode = (props) => {
  const locale = useLocale();
  const translate = useTranslate();
  const { authenticated } = useAuthState();
  const { settingAll } = useContext(GblContext);
  const { SettingAp, SettingSystem } = settingAll;
  const [oldAccList, setOldAccList] = useState([]);
  const addMode = props.location.pathname.search("create") !== -1;
  const [lookupList, setLookupList] = useState({
    accountCodeList: [],
    accountCodeListPA: [],
    accountCodeListWA: [],
    departmentList: [],
    vdCategory: [],
    apPaymentType: [],
    apWhtList: [],
    apWhtTypeList: [],
  });
  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList("Ap");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
      accountCodeListPA: Data,
      accountCodeListWA: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      department: Data,
    }));
  }, []);
  const fetchVdCategoryLookup = useCallback(async () => {
    const { Data } = await getVdCategoryList();
    setLookupList((state) => ({
      ...state,
      vdCategory: Data,
    }));
  }, []);
  const fetchPaymentTypeLookup = useCallback(async () => {
    const { Data } = await getApPaymentTypeList();
    setLookupList((state) => ({
      ...state,
      apPaymentType: Data,
    }));
  }, []);
  const fetchApWhtLookup = useCallback(async () => {
    const { Data } = await getApWhtServiceSearchList();
    setLookupList((state) => ({
      ...state,
      apWhtList: Data,
    }));
  }, []);
  const fetchApWhtTypeLookup = useCallback(async () => {
    const { Data } = await getApWhtTypeSearchList();
    setLookupList((state) => ({
      ...state,
      apWhtTypeList: Data,
    }));
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchAccLookup();
      fetchDeptLookup();
      fetchVdCategoryLookup();
      fetchPaymentTypeLookup();
      fetchApWhtLookup();
      fetchApWhtTypeLookup();
    }
  }, [
    authenticated,
    fetchAccLookup,
    fetchDeptLookup,
    fetchVdCategoryLookup,
    fetchPaymentTypeLookup,
    fetchApWhtLookup,
    fetchApWhtTypeLookup,
  ]);

  const [wfActive, setWfActive] = useState(false);

  const fetchWorkflowByCode = useCallback(async (wfCode) => {
    const r = await getWorkflowByCode(wfCode);
    if (r?.data?.Active) {
      setWfActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchWorkflowByCode("AP_VENDOR");
  }, [fetchWorkflowByCode]);

  const formFieldsVendor = [];

  const formFieldsInfo = [
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={`${!SettingAp.AutoRunVendorCode ? "*" : ""} ${translate("ra.field.Vendor No.")}`}
          name="VnCode"
          variant="outlined"
          margin="dense"
          //disabled={!addMode}
          disabled={!addMode ? true : SettingAp.AutoRunVendorCode}
          rule={{
            required: {
              value: !SettingAp.AutoRunVendorCode,
              message: "* Required",
            },
            maxLength: {
              value: 10,
              message: "maximun length is 10",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={`*${translate("ra.field.Vendor Name")}`}
          name="VnName"
          variant="outlined"
          margin="dense"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Category")}`}
          name="VnCateCode"
          optKey="VnCateCode"
          optDesc="VnCateDesc"
          options={lookupList["vdCategory"]}
          updateOtherField={[{ key: "VnCateDesc", optKey: "VnCateDesc" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "VnCateDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="VnCateDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      name: "Active",
      field: (
        <SwitchInForm
          name="Active"
          labelOn={translate("ra.fieldAbbr.active")}
          labelOff={translate("ra.fieldAbbr.inactive")}
          defaultChecked
          disabled={wfActive}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Title")}
          name="PreVnPayee"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 50,
              message: "maximum length is 50",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={translate("ra.field.WHT. Name")}
          name="VnPayee"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 100,
              message: "maximum length is 100",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Chq. Name")}
          name="Vn2Payee"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 100,
              message: "maximum length is 100",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Contact Name")}
          name="VnAttn"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.TAX ID.")}
          name="VnTaxNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Branch No.")}
          name="BranchNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 20,
              message: "maximum length is 20",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Bus Reg No.")}
          name="VnRegNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 20,
              message: "maximum length is 20",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Credit Term")}
          name="VnTerm"
          decimal={0}
          rule={{
            maxLength: {
              value: 3,
              message: "maximum length is 3",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Discount Term")}
          name="VnDisTrm"
          decimal={0}
          rule={{
            maxLength: {
              value: 3,
              message: "maximum length is 3",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Less (%)")}
          name="VnDisPct"
          decimal={0}
          rule={{
            maxLength: {
              value: 3,
              message: "maximum length is 3",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Reference")}
          name="VnReference"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Mapping Ref.")}
          name="VnMapRef"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
        />
      ),
    },
  ];

  const formFieldsAddress = [
    {
      size: 6,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Address1")}
          name="mergeVnAdd"
          variant="outlined"
          margin="dense"
          multiline
          rows={4}
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Address2")}
          name="mergeVn2Add"
          variant="outlined"
          margin="dense"
          multiline
          rows={4}
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Telephone")}
          name="VnTel"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 50,
              message: "maximun length is 50",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Telephone")}
          name="Vn2Tel"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 50,
              message: "maximun length is 50",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Fax")}
          name="VnFax"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 50,
              message: "maximun length is 50",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Fax")}
          name="Vn2Fax"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 50,
              message: "maximun length is 50",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Email")}
          name="VnEmail"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 50,
              message: "maximun length is 50",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Email")}
          name="Vn2Email"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 50,
              message: "maximun length is 50",
            },
          }}
        />
      ),
    },
  ];

  const formFieldsBank = [
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Bank Name")}
          name="BankName"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Acc Name")}
          name="BankAccountName"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Acc No")}
          name="BankAccountNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Bank Ref") + "1"}
          name="BankRef1"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Bank Ref") + "2"}
          name="BankRef2"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Bank Ref") + "3"}
          name="BankRef3"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximun length is 255",
            },
          }}
        />
      ),
    },
  ];

  const formFieldsInvoice = [
    {
      size: 6,
      field: <SelectInForm label={translate("ra.field.Vat Type 1")} name="VnVat1" options={VatTypeOptions} />,
    },
    {
      size: 6,
      field: <NumberFormatInForm label={translate("ra.field.Vat Rate 1 (%)")} name="VnTaxR1" decimal={0} />,
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Dept. Dr Vat 1")}
          name="VnVat1DrDeptCode"
          optKey="DeptCode"
          optDesc={"Description"}
          options={lookupList["department"]}
          updateOtherField={[{ key: "VnVat1DrDeptDesc", optKey: "Description" }]}
        />
      ),
    },
    {
      size: 3,
      name: "VnVat1DrDeptDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="VnVat1DrDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Acc. Dr Vat 1")}
          name="VnVat1DrAccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[{ key: "VnVat1DrAccDesc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
        />
      ),
    },
    {
      size: 3,
      name: "VnVat1DrAccDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="VnVat1DrAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 6,
      field: <SelectInForm label={translate("ra.field.Vat Type 2")} name="VnVat2" options={VatTypeOptions} />,
    },
    {
      size: 6,
      field: <NumberFormatInForm label={translate("ra.field.Vat Rate 2 (%)")} name="VnTaxR2" decimal={0} />,
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Dept. Dr Vat 2")}
          name="VnVat2DrDeptCode"
          optKey="DeptCode"
          optDesc={"Description"}
          options={lookupList["department"]}
          updateOtherField={[{ key: "VnVat2DrDeptDesc", optKey: "Description" }]}
        />
      ),
    },
    {
      size: 3,
      name: "VnVat2DrDeptDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="VnVat2DrDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Acc. Dr Vat 2")}
          name="VnVat2DrAccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[{ key: "VnVat2DrAccDesc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
        />
      ),
    },
    {
      size: 3,
      name: "VnVat2DrAccDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="VnVat2DrAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Cr A/P Dept.")}`}
          name="VnCrDeptCode"
          optKey="DeptCode"
          optDesc={"Description"}
          options={lookupList["department"]}
          updateOtherField={[{ key: "VnCrDeptDesc", optKey: "Description" }]}
          // rule={{
          //   required: {
          //     value: true,
          //     message: "* Required",
          //   },
          // }}
        />
      ),
    },
    {
      size: 3,
      name: "VnCrDeptDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="VnCrDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Cr A/P Acc.")}`}
          name="VnVatCrAccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[{ key: "VnVatCrAccDesc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
          // rule={{
          //   required: {
          //     value: true,
          //     message: "* Required",
          //   },
          // }}
        />
      ),
    },
    {
      size: 3,
      name: "VnVatCrAccDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="VnVatCrAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  const formFieldsPayment = [
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Type")}`}
          name="VnPayType"
          optKey="Code"
          optDesc="Desc"
          options={lookupList["apPaymentType"]}
          updateOtherField={[{ key: "VnPayTypeDesc", optKey: "Desc" }]}
          // rule={{
          //   required: {
          //     value: true,
          //     message: "* Required",
          //   },
          // }}
        />
      ),
    },
    {
      size: 6,
      name: "VnPayTypeDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="VnPayTypeDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Cr. Payment Dept.")}`}
          name="VnPayDeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["department"]}
          updateOtherField={[{ key: "VnPayDeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListPA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("VnCrAccCode");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("VnCrAccCode", "");
                methods.setValue("VnCrAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListPA: oldAccList,
              }));
            }
          }}
          // rule={{
          //   required: {
          //     value: true,
          //     message: "* Required",
          //   },
          // }}
        />
      ),
    },
    // {
    //   size: 6,
    //   name: "VnPayDeptDesc",
    //   field: (
    //     <DescInForm
    //       style={{ marginTop: 8 }}
    //       name="VnPayDeptDesc"
    //       InputProps={{
    //         readOnly: true,
    //       }}
    //     />
    //   ),
    // },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Cr. Payment Acc.")}`}
          name="VnCrAccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListPA"]}
          updateOtherField={[{ key: "VnCrAccDesc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
          // rule={{
          //   required: {
          //     value: true,
          //     message: "* Required",
          //   },
          // }}
        />
      ),
    },
    // {
    //   size: 6,
    //   name: "VnCrAccDesc",
    //   field: (
    //     <DescInForm
    //       style={{ marginTop: 8 }}
    //       name="VnCrAccDesc"
    //       InputProps={{
    //         readOnly: true,
    //       }}
    //     />
    //   ),
    // },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.WHT. Form")}
          name="VnWhtTypeCode"
          noCode
          optKey="Description"
          optDesc="Description"
          options={lookupList["apWhtTypeList"]}
        />
      ),
    },
    {
      size: 6,
      field: <div></div>,
      implementation: "css",
      smDown: true,
      component: { Hidden },
    },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.WHT. Service Type")}`}
          name="VnWhtCode"
          optKey="Description"
          options={lookupList["apWhtList"]}
          updateOtherField={[{ key: "VnWhtRate", optKey: "WhtRate" }]}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Tax Rate %")}
          name="VnWhtRate"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Cr. WHT. Dept.")}
          name="VnWhtDeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["department"]}
          updateOtherField={[{ key: "VnWhtDeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListWA: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("VnWhtAccCode");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("VnWhtAccCode", "");
                methods.setValue("VnWhtAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListWA: oldAccList,
              }));
            }
          }}
          // rule={{
          //   required: {
          //     value: true,
          //     message: "* Required",
          //   },
          // }}
        />
      ),
    },
    // {
    //   size: 6,
    //   name: "VnWhtDeptDesc",
    //   field: (
    //     <DescInForm
    //       style={{ marginTop: 8 }}
    //       name="VnWhtDeptDesc"
    //       InputProps={{
    //         readOnly: true,
    //       }}
    //     />
    //   ),
    // },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Cr. WHT. Acc.")}
          name="VnWhtAccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListWA"]}
          updateOtherField={[{ key: "VnWhtAccDesc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
          // rule={{
          //   required: {
          //     value: true,
          //     message: "* Required",
          //   },
          // }}
        />
      ),
    },
    // {
    //   size: 6,
    //   name: "VnWhtAccDesc",
    //   field: (
    //     <DescInForm
    //       style={{ marginTop: 8 }}
    //       name="VnWhtAccDesc"
    //       InputProps={{
    //         readOnly: true,
    //       }}
    //     />
    //   ),
    // },
  ];

  if (addMode) {
    return (
      <Create
        {...props}
        formFieldsVendor={formFieldsVendor}
        formFieldsInfo={formFieldsInfo}
        formFieldsAddress={formFieldsAddress}
        formFieldsBank={formFieldsBank}
        formFieldsInvoice={formFieldsInvoice}
        formFieldsPayment={formFieldsPayment}
        wfActive={wfActive}
      />
    );
  } else {
    return (
      <Edit
        {...props}
        formFieldsVendor={formFieldsVendor}
        formFieldsInfo={formFieldsInfo}
        formFieldsAddress={formFieldsAddress}
        formFieldsBank={formFieldsBank}
        formFieldsInvoice={formFieldsInvoice}
        formFieldsPayment={formFieldsPayment}
        wfActive={wfActive}
      />
    );
  }
};

export default {
  list: List,
  show: ViewMode,
  edit: SwitchActionMode,
  create: SwitchActionMode,
};
