/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import {
  getMappingInfByCode,
  updateMappingInfByCode,
  //getMappingHotelogixAr,
  //updateMappingHotelogixAr,
  //getMappingHotelogixArB,
  //updateMappingHotelogixArB,
} from "services/interface";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { TableHead } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Typography } from "@material-ui/core";
import MUIDataTable, { TableHead as MuiTableHead } from "mui-datatables";
import { MuiAutosuggest, TextFieldInForm } from "components/Form";
import fileReader from "utils/fileReader";
import PopupTable from "components/PopupTable";
import SnackbarUtils from "utils/SnackbarUtils";
import ButtonUpload from "components/ButtonUpload";
import csv from "csvtojson";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default function DialogItem(props) {
  const classes = useStyles();
  const translate = useTranslate();
  const [isLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { open, onClose, lookupList } = props;
  const [data, setData] = useState();
  const [newFormField, setNewFormField] = useState([]);
  const [columns, setColumns] = useState();
  const [showAdd, setShowAdd] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [fileMapping, setFileMapping] = useState();

  const fetchMappingList = async () => {
    setLoading(true);
    var r = await getMappingInfByCode(props.id, props.docType, "AP");
    const { Columns, Rows } = r;
    //if have Columns,Rows mean r = datatable
    if (Columns) {
      const newColumns = Columns.map(({ ColumnName: name, Caption: label }) => ({
        name,
        label,
      }));
      newColumns.unshift({
        name: "index",
        label: " ",
        options: {
          filter: false,
          viewColumns: false,
          download: false,
          customBodyRender: (value) => {
            return (
              <>
                <EditIcon
                  fontSize="small"
                  color="primary"
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  onClick={() => UpdateRow(value)}
                />
              </>
            );
          },
        },
      });
      Rows.forEach((item, idx) => {
        item.index = idx;
      });

      let newArr = [
        {
          size: 6,
          field: <TextFieldInForm label="GuestNo" name="GuestNo" variant="outlined" margin="dense" />,
        },
        {
          size: 6,
          field: <TextFieldInForm label="Counter Account" name="CounterAccount" variant="outlined" margin="dense" />,
        },
        {
          size: 6,
          field: (
            <MuiAutosuggest
              label="* A/R No."
              name="ArNo"
              optKey="Code"
              optDesc="Desc"
              options={lookupList["arProfileList"]}
              rule={{
                required: {
                  value: true,
                  message: "* Required",
                },
              }}
            />
          ),
        },
        {
          size: 6,
          field: <div></div>,
          implementation: "css",
          smDown: true,
          component: { Hidden },
        },
        {
          size: 12,
          field: (
            <TextFieldInForm
              label="FirstName"
              name="FirstName"
              variant="outlined"
              margin="dense"
              InputProps={{
                readOnly: true,
              }}
            />
          ),
        },
        {
          size: 12,
          field: (
            <TextFieldInForm
              label="LastName"
              name="LastName"
              variant="outlined"
              margin="dense"
              InputProps={{
                readOnly: true,
              }}
            />
          ),
        },
        {
          size: 12,
          field: (
            <TextFieldInForm
              label="Company"
              name="Company"
              variant="outlined"
              margin="dense"
              InputProps={{
                readOnly: true,
              }}
            />
          ),
        },
      ];
      setNewFormField(newArr);
      setColumns(newColumns);
      setData(Rows);
    }
    // r = Json
    else {
      if (r.length > 0) {
        var newColumns = [];
        let keyArr = Object.keys(r[0]);
        keyArr.forEach((k) => {
          newColumns.push({
            name: k,
            label: k,
          });
        });
        newColumns.unshift({
          name: "index",
          label: " ",
          options: {
            filter: false,
            viewColumns: false,
            download: false,
            customBodyRender: (value) => {
              return (
                <>
                  <EditIcon
                    fontSize="small"
                    color="primary"
                    style={{ cursor: "pointer", marginLeft: 10 }}
                    onClick={() => UpdateRow(value)}
                  />
                </>
              );
            },
          },
        });
        r.forEach((item, idx) => {
          item.index = idx;
        });

        setColumns(newColumns);
        setData(r);
      }
    }

    setTimeout(() => {
      setLoading(false);
    }, 600);
  };

  useEffect(() => {
    fetchMappingList();
  }, []);

  const Save = async () => {
    setBtnLoading(true);
    // var foundItemNull = data?.filter((item) => item.ArNo === "" || item.ArNo === null);
    // if (foundItemNull?.length > 0) {
    //   setBtnLoading(false);
    //   SnackbarUtils.warning("A/R No. can't be empty");
    //   return [];
    // }

    const newData = data?.map(({ OriginalRow, RowState, ...rest }) => {
      return rest;
    });

    // if (props.postType === "Json") {
    //   r = await updateMappingInfByCode(props.id, props.docType, "AR", newData);
    // } else {
    //   if (props.id === "Hotelogix.PMS") {
    //     r = await updateMappingHotelogixAr(newData);
    //   }
    //   if (props.id === "Hotelogix2.PMS") {
    //     r = await updateMappingHotelogixArB(newData);
    //   }
    // }
    try {
      const { Code, UserMessage } = await updateMappingInfByCode(props.id, props.docType, "AP", newData);
      if (Code === 0) {
        setBtnLoading(false);
        SnackbarUtils.success(UserMessage, function () {
          onClose();
        });
      }
    } catch (error) {
      setBtnLoading(false);
    }
  };

  const options = {
    responsive: "standard",
    selectableRows: "multiple",
    fixedHeader: true,
    tableBodyHeight: "500px",
    search: false,
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
    },
    downloadOptions: {
      separator: ",",
      filename: "AccountMapping.csv",
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.dataIndex);
      const d = data.filter((i) => !removeArray.includes(i.index));
      setData(d);
    },
  };

  const UpdateRow = (value) => {
    setEditIndex(value);
    setShowAdd(true);
  };

  const SaveFromPopup = (arr, row) => {
    const index = arr.findIndex((el) => el.index === editIndex);
    if (editIndex !== "") {
      //update
      arr[index] = row;
      setShowAdd(false);
    } else {
      //create
      row.index = arr.length;
      arr = [...arr, row];
      setShowAdd(false);
    }
    setData(arr);
  };

  const CancelFromPopup = () => {
    setShowAdd(false);
  };

  const CustomHeader = (props) => {
    return (
      <>
        <TableHead>
          {/* <TableRow>
            <TableCell align="center" colSpan={1}>
              <IconButton size={"small"} onClick={() => AddNewRow()} style={{ marginLeft: 6 }}>
                <AddIcon />
              </IconButton>
            </TableCell>
            <TableCell align="right" colSpan={10}></TableCell>
          </TableRow> */}
        </TableHead>
        <MuiTableHead {...props} />
      </>
    );
  };

  const uploadFile = async (e) => {
    if (e.target.files.length >= 1) {
      let file = e.target.files[0];
      setFileMapping(file);

      var csvData = await fileReader.ToBase64(file);
      const json = await csv().fromString(csvData);
      //remove last array if empty
      if (Object.values(json[json.length - 1]).length === 1) {
        json.pop();
      }
      //add index
      for (const [i] of json.entries()) {
        json[i].index = i;
      }
      setData(json);
    } else {
      if (e.target.id === "fileMapping") {
        document.getElementById("fileMapping").value = "";
      }
    }
  };

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event);
          }
        }}
        scroll={"paper"}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="scroll-dialog-title" onClose={onClose}>
          Mapping code
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          {loading ? (
            <div className={classes.circulLoading}>
              <CircularProgress />
            </div>
          ) : (
            <MUIDataTable
              title={
                <div style={{ textAlign: "left" }}>
                  <ButtonUpload
                    id="fileMapping"
                    name="fileMapping"
                    style={{ display: "none" }}
                    type="file"
                    onChange={uploadFile}
                    accept=".csv,(*.*)"
                    fileUpload={fileMapping?.name}
                    btntext={"Import file mapping"}
                    noCaption
                    cancelUpload={() => {
                      setFileMapping();
                      document.getElementById("fileMapping").value = "";
                    }}
                  />
                </div>
              }
              data={data}
              columns={columns}
              options={options}
              components={{
                TableHead: CustomHeader,
              }}
            />
          )}
          {showAdd && (
            <PopupTable
              initialValues={data[editIndex]}
              formFields={newFormField}
              update={() => {}}
              open={showAdd}
              save={(row) => SaveFromPopup(data, row)}
              cancel={CancelFromPopup}
            />
          )}
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
        </DialogContent>
        <DialogActions>
          <LoadingButton text="OK" disabled={isLoading} fnc={() => Save(data)} />
          <Button variant="outlined" className={classes.textCancel} onClick={onClose}>
            {translate("ra.action.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
