/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { Loading, Error, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid, Tooltip } from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MuiTranslateTable from "components/MuiTranslateTable";
import TextTopInGrid from "components/TextTopInGrid";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import NavRight from "components/NavRightSide";
import DialogReceipt from "./DialogReceipt";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { permissionName } from "utils/constants";
import { GetWfStatus } from "utils/options";
import { showReportByName } from "pages/Report/services";
import { getArInvoiceDetail, delArInvoiceDetail } from "services/accountReceivable";
import { getWorkflowHistory } from "services/workflow";
import DialogSendEmail from "components/DialogSendEmail";
import SnackbarUtils from "utils/SnackbarUtils";
import CustomToolbarSelect from "./CustomToolbarSelect";
import ReportBackDrop from "components/ReportBackDrop";
import DialogViewARInvoiceDetail from "../../../components/DialogViewARInvoiceDetail";

const Show = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { settingAll, DateToString, NumberFormat, ToNumber } = useContext(GblContext);
  const { SettingClosePeriod } = settingAll;
  const { basePath, id, permissions, hideMailBtn, wfActive, wfEnumStatus, wfSteps } = props;
  const redirect = useRedirect();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [openDim, setOpenDim] = useState(false);
  const [dataDim, setDataDim] = useState();
  const [dataTax, setDataTax] = useState();
  const [dataView, setDataView] = useState();
  const [wfHisList, setWfHisList] = useState();
  const [openReceiptDialog, setOpenReceiptDialog] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);

  const CheckDisableEditBtn = () => {
    if (wfActive) {
      if (data?.InvhStatus === "Effective") {
        return true;
      }
      //check step
      if (data?.InvhStatus && wfEnumStatus) {
        if (data?.InvhStatus === "Step0") {
          return false;
        }
        const haveItem = wfSteps?.find((item) => item.StepNo === parseInt(wfEnumStatus[data?.InvhStatus]));
        if (typeof haveItem === "undefined") {
          return true;
        }
      }
    }
    if (data?.InvhStatus === "Void") {
      return true;
    }
    return false;
  };

  const IsSettled = () => {
    let total = data.InvhTotal ?? 0;
    let unpaid = data.InvhUnpaid ?? 0;
    if (total !== unpaid) {
      return true;
    }
    return false;
  };

  const CheckEdit = () => {
    if (data.InvhDate < SettingClosePeriod.ClosePeriodAr) {
      //ปิด period ไปแล้ว
      SnackbarUtils.warning(translate("ra.closePeriod.warning", { name: "invoice", action: "edit" }));
    } else {
      //ยังไม่ถูกตัดจ่าย
      if (IsSettled() === false) {
        redirect("edit", basePath, id);
      }
      //ตัดจ่ายไปแล้ว
      else {
        SnackbarUtils.warning("This invoice has already have a receipt. Only Comment can process.");
      }
    }
  };

  const CheckDelete = () => {
    if (data.InvhDate < SettingClosePeriod.ClosePeriodAr) {
      //ปิด period ไปแล้ว
      SnackbarUtils.warning(translate("ra.closePeriod.warning", { name: "invoice", action: "void" }));
    } else {
      //ยังไม่ถูกตัดจ่าย
      if (IsSettled() === false) {
        DelOrVoid();
      }
      //ตัดจ่ายไปแล้ว
      else {
        SnackbarUtils.warning("This invoice has already have a receipt. Cannot void", {
          variant: "warning",
          autoHideDuration: 3000,
        });
      }
    }
  };

  const onCloseDialogEmail = (statusCode, UserMessage) => {
    setLoading(true);
    if (statusCode === undefined) {
      setOpenEmailDialog(false);
      setLoading(false);
    }
    if (statusCode === 0) {
      SnackbarUtils.success(UserMessage);
      setOpenEmailDialog(false);
      setLoading(false);
    }
    if (statusCode !== 0 && statusCode !== undefined) {
      SnackbarUtils.error(UserMessage);
      setOpenEmailDialog(false);
      setLoading(false);
    }
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", fnc: () => redirect("create", basePath) },
    {
      name: "Edit",
      disabled: CheckDisableEditBtn(),
      fnc: CheckEdit,
    },
    { name: "Void", disabled: CheckDisableEditBtn(), fnc: CheckDelete },
    { name: "Copy", fnc: () => redirect(`copy`, basePath) },
    {
      name: "Print",
      fnc: () => {
        props.showReportLoader();
        showReportByName("AR_Invoice", [{ Name: "List", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
    {
      name: "Print Voucher",
      fnc: () => {
        props.showReportLoader();
        showReportByName("AR_INVOICE_VOUCHER", [{ Name: "List", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
    { name: "Receipt", fnc: () => setOpenReceiptDialog(true) },
    { name: "Send Email", fnc: () => setOpenEmailDialog(true), hide: hideMailBtn },
  ];

  const fetchInvoiceById = useCallback(
    async (mounted) => {
      const response = await getArInvoiceDetail(id);
      if (response) {
        setData(response);
        let dataRow = response?.Detail[0];

        let tempDataTax = {
          ...dataRow,
        };
        setDataTax(tempDataTax);
      }
      if (mounted) {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [id]
  );

  const fetchWorkflowHistory = useCallback(
    async (wfCode) => {
      const { data } = await getWorkflowHistory(wfCode, id);
      if (data) {
        setWfHisList(data);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    fetchInvoiceById(mounted);
    fetchWorkflowHistory("AR_INVOICE");
    return function cleanup() {
      mounted = false;
    };
  }, [fetchInvoiceById]);

  const DelOrVoid = async () => {
    let msg = translate("ra.question.confirmVoid", { module: "A/R Invoice" });
    SnackbarUtils.voidConfirm(msg, async function (remark) {
      const { Code, InternalMessage, UserMessage } = await delArInvoiceDetail(
        id,
        localStorage.getItem("UserName"),
        remark
      );
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          fetchInvoiceById(id);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    });
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          let dataRow = data.Detail[value];
          return (
            <>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenDialogDetail(!openDialogDetail);
                  setDataView(dataRow);
                }}
              />
            </>
          );
        },
      },
    },
    {
      name: "DeptCode",
      label: "Dept.",
      options: {
        sort: true,
        customHeadRender: ({ label }) => {
          return (
            <TableCell className={classes.colorCell} style={{ width: 80 }}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (val, tableMeta) => {
          let deptDesc = tableMeta.rowData[2];
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{deptDesc}</p>} arrow placement="top">
              <div>
                <div className={classes.cellStyleEllipsis}>{val}</div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "DeptDesc",
      label: "Department Name",
      options: {
        display: false,
      },
    },
    {
      name: "CrAcc",
      label: "Account #",
      options: {
        sort: true,
        customHeadRender: ({ label }) => {
          return (
            <TableCell className={classes.colorCell} style={{ width: 100 }}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (val, tableMeta) => {
          let accDesc = tableMeta.rowData[4];
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{accDesc}</p>} arrow placement="top">
              <div>
                <div className={classes.cellStyleEllipsis}>{val}</div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "CrAccDesc",
      label: "Account Name",
      options: {
        display: false,
      },
    },
    {
      name: "InvdDesc",
      label: "Comment",
      options: {
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "Qty",
      label: "Qty",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "qty");
        },
      },
    },
    {
      name: "Price",
      label: "Price/Unit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "unit");
        },
      },
    },
    {
      name: "NetAmt",
      label: "Net Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "TaxAmt1",
      label: "TAX",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          return NumberFormat(value + tableMeta.rowData[10]);
        },
      },
    },
    {
      name: "TaxAmt2",
      label: "Tax 2",
      options: {
        display: false,
      },
    },
    {
      name: "TotalAmt",
      label: "Total",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "Unpaid",
      label: "Unpaid",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    responsive: "standard",
    selectableRows: "none",
    // serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "500px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      let sumNet = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumTax1 = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumTotal = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[11]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumUnpaid = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[12]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                switch (col.name) {
                  case "NetAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumNet}
                      </TableCell>
                    );
                  case "TaxAmt1":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTax1}
                      </TableCell>
                    );
                  case "TotalAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTotal}
                      </TableCell>
                    );
                  case "Unpaid":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumUnpaid}
                      </TableCell>
                    );
                  default:
                    return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (var i = removeArray.length - 1; i >= 0; i--) data.Detail.splice(removeArray[i], 1);
    },
  };

  const ShowDim = (values) => {
    if (!values) {
      setDataDim(data.Detail[0].DimList.Dim);
      setOpenDim(true);
    } else {
      setDataDim(values);
      setOpenDim(true);
    }
  };

  const SearchByNo = (e) => {
    if (e) {
      redirect("show", basePath, e.InvhSeq);
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <div
      // className={clsx({
      //   [classes.drawerOpen]: openDim,
      //   [classes.drawerClose]: !openDim,
      // })}
      className={classes.drawerClose}
    >
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["AR.Invoice"])}
      />
      {data &&
        wfEnumStatus &&
        data.InvhStatus !== "Effective" &&
        data.InvhStatus !== "Void" &&
        data.InvhStatus !== "Step0" && (
          <CustomToolbarSelect
            selectedRows={{ data: data }}
            status={parseInt(wfEnumStatus[data.InvhStatus])}
            wfCode={"AR_INVOICE"}
            fncSuccess={() => {
              fetchInvoiceById();
              fetchWorkflowHistory("AR_INVOICE");
            }}
          />
        )}
      <Paper className={classes.root}>
        <BoxHeader
          header={`Invoice`}
          showSearch
          searchOption={{
            placeholder: `${translate("ra.fieldAbbr.searchby")} ${translate("ra.field.Invoice No.")}`,
            update: SearchByNo,
            module: "AR_Invoice",
            keyCode: "InvNo",
            keyDesc: "InvhDesc",
          }}
          data={data}
          source={data.InvhSource}
          wfStatus={GetWfStatus(wfSteps, wfEnumStatus, data.InvhStatus)}
          status={
            GetWfStatus(wfSteps, wfEnumStatus, data.InvhStatus)
              ? GetWfStatus(wfSteps, wfEnumStatus, data.InvhStatus)
              : data.InvhStatus
          }
        />
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
          <TextTopInGrid sizeSm={2} label="Invoice No." value={data.InvNo} />
          <TextTopInGrid sizeSm={2} label="Date" value={DateToString(data.InvhDate)} />
          <TextTopInGrid
            sizeSm={4}
            label="A/R No."
            value={data.ArNo ? `${data.ArNo} : ${data.Company ? data.Company : data.FirstName}` : ""}
          />
          <TextTopInGrid sizeSm={2} label="Currency" value={data.CurrCode} />
          <TextTopInGrid sizeSm={2} label="Rate" value={NumberFormat(data.CurrRate, "currency")} />
          <TextTopInGrid sizeSm={2} label="Prefix" value={data.InvPrefix} />
          <TextTopInGrid sizeSm={2} label="Tax Inv No." value={data.InvhTaxNo} />
          <TextTopInGrid sizeSm={2} label="Tax Status" value={data.TaxType} />
          <TextTopInGrid sizeSm={2} label="Due Date" value={DateToString(data.InvhDueDate)} />
          <Grid item xs={4}></Grid>
          <TextTopInGrid sizeSm={12} label="Description" value={data.InvhDesc} />
        </Grid>
        <MuiTranslateTable data={data.Detail} columns={columns} options={options} />
      </Paper>

      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => ShowDim()}
        dataDim={dataDim}
        dataTax={dataTax}
        module={"AR_Invoice"}
        moduleId={id}
        wfHisList={wfHisList}
      />
      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
      {openReceiptDialog && (
        <DialogReceipt open={openReceiptDialog} onClose={() => setOpenReceiptDialog(false)} InvhSeq={data.InvhSeq} />
      )}
      {openEmailDialog && (
        <DialogSendEmail
          title={" Send A/R Invoice To Email"}
          open={openEmailDialog}
          onClose={onCloseDialogEmail}
          data={data}
          module={"AR_Invoice"}
          keyId="InvhSeq"
        />
      )}
      {openDialogDetail && (
        <DialogViewARInvoiceDetail
          open={openDialogDetail}
          onClose={() => setOpenDialogDetail(false)}
          data={dataView}
          CurrRate={data.CurrRate}
        />
      )}
    </div>
  );
};

export default ReportBackDrop(Show);
