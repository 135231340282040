import React, { Component } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

const ReportBackDrop = (WrappedComponent) => {
  const Loader = class ReportBackDrop extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
      };

      this.showReportLoader = this.showReportLoader.bind(this);
      this.hideReportLoader = this.hideReportLoader.bind(this);
    }

    showReportLoader() {
      this.setState({ isLoading: true });
    }
    hideReportLoader() {
      this.setState({ isLoading: false });
    }

    render() {
      const { classes } = this.props;
      return this.state.isLoading ? (
        <Backdrop className={classes.backdrop} open={this.state.isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <WrappedComponent
          {...this.props}
          showReportLoader={this.showReportLoader}
          hideReportLoader={this.hideReportLoader}
        />
      );
    }
  };

  return withStyles(styles)(Loader);
};

export default ReportBackDrop;
