import React, { useState, useContext, useEffect } from "react";
import { Loading, useLocale } from "react-admin";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import { Grid, IconButton, Icon, TextField } from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  //getListGlPeriod,
  getAccCodeListWithSp,
  getReportTrialBalance,
  showReportPDF,
} from "services/callStoreProcedure";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiTranslateTable from "components/MuiTranslateTable";
//import MUIDataTable from "mui-datatables";
//import { PeriodWithText } from "utils/options";
import ListBox from "components/ListBox";
import DatePickerFormat from "components/DatePickerFormat";
import PopperListBox from "components/PopperListBox";
//import { PeriodListWithYear } from "utils/options";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { matchSorter } from "match-sorter";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const TrialBalance = (props) => {
  const locale = useLocale();
  const classes = useStyles();
  const { basePath, translate } = props;
  //initial Value with window.sessionStorage
  const ws = window.sessionStorage;
  const filterValue =
    ws.getItem("basePath") === basePath && ws.getItem("searchDetails")
      ? JSON.parse(ws.getItem("searchDetails")).filterValue
      : undefined;
  const searchResults =
    ws.getItem("basePath") === basePath && ws.getItem("searchDetails")
      ? JSON.parse(ws.getItem("searchDetails")).searchResults
      : undefined;

  const [loading, setLoading] = useState(true);
  const [accountList, setAccountCodeList] = useState([]);
  //const optionList = PeriodWithText();
  const [fromAcc, setFromAcc] = useState(filterValue ? filterValue.fromAcc : localStorage.getItem("viewFromAccCode"));
  const [toAcc, setToAcc] = useState(filterValue ? filterValue.toAcc : localStorage.getItem("viewToAccCode"));
  const { settingAll, NumberFormat, ToNumber, ToMySqlPeriod, StringToDate, DateToString } = useContext(GblContext);
  const { SettingClosePeriod } = settingAll;
  const { ClosePeriodGl } = SettingClosePeriod;
  //const periodList = PeriodListWithYear(addDays(new Date(ClosePeriodGl), 1), -2, 2);
  const [filterDate, setFilterDate] = useState({
    from: filterValue ? StringToDate(filterValue.from) : addDays(new Date(ClosePeriodGl), 1),
    to: filterValue ? StringToDate(filterValue.to) : addDays(new Date(ClosePeriodGl), 1),
  });

  const [data, setData] = useState(searchResults ? searchResults : []);

  async function getAccountList() {
    const accList = await getAccCodeListWithSp();
    setAccountCodeList(accList);
  }

  async function getReportDetail() {
    setLoading(true);
    const splitFDate = DateToString(filterDate.from).split("/");
    const fPeriod = `${splitFDate[2]}-${splitFDate[1]}`;
    const splitTDate = DateToString(filterDate.to).split("/");
    const tPeriod = `${splitTDate[2]}-${splitTDate[1]}`;
    const reportDetail = await getReportTrialBalance(
      localStorage.getItem("viewFromAccCode"),
      localStorage.getItem("viewToAccCode"),
      fPeriod,
      tPeriod
    );
    reportDetail.forEach((element) => {
      element.AccDesc = locale === "en-US" ? element.AccDesc : element.AccDescT;
    });
    setData(reportDetail);
  }

  useEffect(() => {
    if (localStorage.getItem("viewFromAccCode")) {
      getReportDetail().then(() => setLoading(false));
    } else {
      setLoading(false);
    }
    //
    //getPeriodList();
    getAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromAcc, toAcc, filterDate]);

  const columns = [
    {
      name: "AccCode",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          const stringFDate = DateToString(filterDate.from);
          const splitFDate = DateToString(filterDate.from).split("/");
          const fPeriod = `${splitFDate[2]}-${splitFDate[1]}-01`;
          const stringTDate = DateToString(filterDate.to);
          const splitTDate = DateToString(filterDate.to).split("/");
          const tPeriod = `${splitTDate[2]}-${splitTDate[1]}-${splitTDate[0]}`;
          return (
            <a href={`#${basePath}/${value}/show?from=${fPeriod}&to=${tPeriod}`}>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  ws.setItem("basePath", basePath);
                  ws.setItem(
                    "searchDetails",
                    JSON.stringify({
                      filterValue: {
                        fromAcc: fromAcc,
                        toAcc: toAcc,
                        from: stringFDate,
                        to: stringTDate,
                      },
                      searchDetails: data,
                    })
                  );
                }}
              />
            </a>
          );
        },
      },
    },
    {
      name: "AccCode",
      label: "Account #",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: locale === "en-US" ? "AccDesc" : "AccDescT",
      label: "Account Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "OpeningBalance",
      label: "Opening Balance",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DrAmt",
      label: "Debit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrAmt",
      label: "Credit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CurrentBalance",
      label: "This Month",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "ClosingBalance",
      label: "Closing Balance",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    responsive: "standard",
    selectableRows: "none",
    pagination: false,
    serverSide: true,
    confirmFilters: true,
   
    search: false,
    print: false,
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
    },
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    customToolbar: ({ displayData }) => {
      const param = {
        Id: 205,
        Type: "Report",
        FileFormat: "pdf",
        Parameters: [
          {
            Name: "DateFrom",
            Value: ToMySqlPeriod(typeof filterDate.from === "string" ? StringToDate(filterDate.from) : filterDate.from),
          },
          {
            Name: "DateTo",
            Value: ToMySqlPeriod(typeof filterDate.to === "string" ? StringToDate(filterDate.to) : filterDate.to),
          },
          { Name: "DeptCodeFrom", Value: "" },
          { Name: "DeptCodeTo", Value: "" },
          {
            Name: "AccCodeFrom",
            Value: localStorage.getItem("viewFromAccCode"),
          },
          { Name: "AccCodeTo", Value: localStorage.getItem("viewToAccCode") },
          { Name: "AccType", Value: "A" },
          { Name: "AccStatus", Value: "Active" },
          { Name: "GroupBy", Value: "None" },
        ],
      };
      return (
        <IconButton
          aria-label="print report Trial Balance"
          onClick={async () => {
            setLoading(true);
            const data = await showReportPDF(param);
            if (typeof data === "string") {
              SnackbarUtils.error("No data");
              setLoading(false);
              return;
            }
            const blob = new Blob([data], { type: "application/pdf" });
            const objUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = objUrl;
            link.target = "_blank";
            link.click();
            setLoading(false);
          }}
        >
          <Icon>print</Icon>
        </IconButton>
      );
    },
    customTableBodyFooterRender: function (opts) {
      const sumOpening = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[3]);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumDebit = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[4]);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumCredit = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[5]);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumThisMonth = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[6]);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumClosing = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[7]);
        return NumberFormat(s ?? 0);
      }, 0);

      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            <TableCell className={footerClasses} style={{ textAlign: "center" }} colSpan={3}>
              {translate("ra.field.Total")}
            </TableCell>
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                switch (col.name) {
                  case "OpeningBalance":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumOpening}
                      </TableCell>
                    );
                  case "DrAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumDebit}
                      </TableCell>
                    );
                  case "CrAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumCredit}
                      </TableCell>
                    );
                  case "CurrentBalance":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumThisMonth}
                      </TableCell>
                    );
                  case "ClosingBalance":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumClosing}
                      </TableCell>
                    );
                  default:
                    return null;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
  };

  const filterOptions = (options, { inputValue }, optKey, optDesc) => {
    return matchSorter(options, inputValue, {
      keys: [`${optKey}`, `${optDesc}`],
    });
  };

  const CustomFilterList = () => {
    return (
      <Grid container spacing={1}>
        <Grid item style={{ marginLeft: 6 }}>
          <Autocomplete
            size="small"
            disableClearable={false}
            disableListWrap
            ListboxComponent={ListBox}
            PopperComponent={PopperListBox}
            classes={{
              option: classes.option,
            }}
            options={accountList}
            autoHighlight
            freeSolo={fromAcc === "" || "string" ? true : false}
            value={fromAcc}
            getOptionLabel={(option) => {
              return typeof option === "object" ? option.AccCode : option;
            }}
            onChange={(e, newItem) => {
              if (newItem) {
                setFromAcc(newItem.AccCode);
                localStorage.setItem("viewFromAccCode", newItem.AccCode);
                //getReportDetail(newItem.AccCode);
              }
            }}
            style={{ width: 160, display: "inline-flex" }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  label={translate("ra.field.FromAcc")}
                  placeholder={translate("ra.field.FromAcc")}
                  style={{ marginTop: -2 }}
                />
              );
            }}
            renderOption={(option, { inputValue }) => {
              //const mergestring = `${option.AccCode} ${option.Desc ? `: ${option.Desc}` : ""}`;
              const o = locale === "en-US" ? option.AccFullName : option.AccTFullName;
              const matches = match(o, inputValue);
              const parts = parse(o, matches);
              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            filterOptions={(options, { inputValue }) =>
              filterOptions(options, { inputValue }, "AccCode", "AccFullName")
            }
          />
        </Grid>
        <Grid item style={{ marginLeft: 6 }}>
          <Autocomplete
            size="small"
            disableClearable={false}
            disableListWrap
            ListboxComponent={ListBox}
            PopperComponent={PopperListBox}
            classes={{
              option: classes.option,
            }}
            options={accountList}
            autoHighlight
            freeSolo={toAcc === "" || "string" ? true : false}
            value={toAcc}
            getOptionLabel={(option) => {
              return typeof option === "object" ? option.AccCode : option;
            }}
            onChange={(e, newItem) => {
              if (newItem) {
                setToAcc(newItem.AccCode);
                localStorage.setItem("viewToAccCode", newItem.AccCode);
                //getReportDetail(newItem.AccCode);
              }
            }}
            style={{ width: 160, display: "inline-flex" }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  label={translate("ra.field.ToAcc")}
                  placeholder={translate("ra.field.ToAcc")}
                  style={{ marginTop: -2 }}
                />
              );
            }}
            renderOption={(option, { inputValue }) => {
              //const mergestring = `${option.AccCode} ${option.Desc ? `: ${option.Desc}` : ""}`;
              const o = locale === "en-US" ? option.AccFullName : option.AccTFullName;
              const matches = match(o, inputValue);
              const parts = parse(o, matches);
              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            filterOptions={(options, { inputValue }) =>
              filterOptions(options, { inputValue }, "AccCode", "AccFullName")
            }
          />
        </Grid>
        {/* <Grid item>
          <FormControl variant="outlined">
            <InputLabel id="viewMode">{translate("ra.fieldAbbr.date")}</InputLabel>
            <Select
              variant="outlined"
              margin="dense"
              labelId="viewMode"
              label={translate("ra.fieldAbbr.date")}
              value={mode}
              onChange={(e) => {
                setMode(e.target.value);
                const newPeriod = optionList.find((i) => i.name === e.target.value);
                setFilterDate((state) => ({
                  ...state,
                  from: newPeriod.start !== "" ? newPeriod.start : filterDate.from,
                  to: newPeriod.end !== "" ? newPeriod.end : filterDate.to,
                }));
              }}
              style={{ width: 160 }}
            >
              {optionList
                ? optionList.map((item, idx) => {
                    return (
                      <MenuItem key={idx} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })
                : ""}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item>
          {/* <FormControl variant="outlined" fullWidth>
            <InputLabel id="simple-select-outlined-label">
              {translate("ra.field.From")} {translate("ra.field.Period")}
            </InputLabel>
            <Select
              labelId="simple-select-filled-label"
              variant="outlined"
              margin="dense"
              label={translate("ra.field.Period") + "From"}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              defaultValue={periodList && periodList[0]}
              value={new Date()}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  from: e.target.value,
                  to: e.target.value > filterDate.to ? e.target.value : filterDate.to,
                }));
              }}
              style={{ width: 160 }}
            >
              {periodList &&
                periodList.map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}

          <DatePickerFormat
            label={`${translate("ra.field.From")} ${translate("ra.field.Period")}`}
            value={filterDate.from}
            onChange={(e) => {
              setFilterDate((state) => ({
                ...state,
                from: e,
                to: e > filterDate.to ? e : filterDate.to,
              }));
              //setMode("Custom");
            }}
            style={{ width: 160, margin: "0 10px" }}
          />
        </Grid>
        <Grid item>
          <DatePickerFormat
            label={`${translate("ra.field.To")} ${translate("ra.field.Period")}`}
            value={filterDate.to}
            onChange={(e) => {
              setFilterDate((state) => ({
                ...state,
                to: e,
              }));
              //setMode("Custom");
            }}
            minDate={filterDate.from}
            minDateMessage={"Date must be more than from date"}
            style={{ width: 160, margin: "0 10px 0 0" }}
          />
          {/* <FormControl variant="outlined" fullWidth>
            <InputLabel id="simple-select-outlined-label">
              {translate("ra.field.To")} {translate("ra.field.Period")}{" "}
            </InputLabel>
            <Select
              labelId="simple-select-filled-label"
              variant="outlined"
              margin="dense"
              label={translate("ra.field.Period") + "To"}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              defaultValue={periodList && periodList[0]}
              value={filterDate.to}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  to: e.target.value,
                }));
              }}
              style={{ width: 160, margin: "0 10px 0 0" }}
            >
              {periodList &&
                periodList.map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
        </Grid>
      </Grid>
    );
  };

  if (loading) return <Loading />;

  return (
    <div>
      <CustomFilterList />
      {loading ? (
        <Loading />
      ) : (
        <div style={{ marginTop: 6 }}>
          <MuiTranslateTable
            notTranslateTitle
            // title={
            //   <>
            //     <Typography variant="body1" component="p">
            //       <span style={{ display: "inline-block", width: 120 }}>{translate("ra.field.FromAcc")}</span>
            //       <b>{accountList?.find((i) => i.AccCode === fromAcc)?.AccFullName ?? ""}</b>
            //     </Typography>
            //     <Typography variant="body1" component="p">
            //       <span style={{ display: "inline-block", width: 120 }}>{translate("ra.field.ToAcc")}</span>
            //       <b>{accountList?.find((i) => i.AccCode === toAcc)?.AccFullName ?? ""}</b>
            //     </Typography>
            //   </>
            // }
            data={data}
            columns={columns}
            options={options}
          />
        </div>
      )}
    </div>
  );
};

export default TrialBalance;
