import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { GblContext } from "providers/formatter";
import { Grid, IconButton, Typography, TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import { useTranslate } from "react-admin";
import TextTopInGrid from "components/TextTopInGrid";
import TextView from "components/TextView";

function DialogArContractTax(props) {
  const translate = useTranslate();
  const { data, open, onClose, CurRate } = props;
  const { NumberFormat } = useContext(GblContext);
  const dimList = data?.DimList.Dim || [];
  function dimensionContent() {
    if (dimList.length === 0) {
      return null;
    } else {
      const DimensionText = dimList.map((dim, index) => (
        <Grid style={{ marginBottom: 15 }}>
          <TextView key={index} sizeSm={12} label={dim.Caption} value={dim.Value} style={{ paddingRight: 7 }} />
        </Grid>
      ));
      return (
        <>
          <Grid xs={12} style={{ marginBottom: 7 }}>
            <Typography variant="h6" style={{ marginLeft: 7 }}>
              {translate("ra.module.Dimension")}
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: 7 }}>
            {DimensionText}
          </Grid>
        </>
      );
    }
  }

  const labelList = {
    Tax: translate("ra.field.Tax"),
    DrAcc: translate("ra.field.Dr Acc. Code"),
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "10px 24px" }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {"A/R Contract Invoice"}
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 5 }}>
            <TextTopInGrid
              sizeSm={12}
              label={"Department"}
              value={
                data.DeptCode !== null && data.DeptCode !== "" && data.DeptCode !== undefined
                  ? data.DeptDesc !== null && data.DeptDesc !== "" && data.DeptDesc !== undefined
                    ? data.DeptCode + " : " + data.DeptDesc
                    : data.DeptCode
                  : ""
              }
            />
            <TextTopInGrid sizeSm={12} label={"Comment"} value={data.ConDDesc} />
            <TextTopInGrid sizeSm={4} label={"Unit"} value={data.Unit} />{" "}
            <TextTopInGrid
              sizeSm={4}
              label={"Qty"}
              value={NumberFormat(data.Qty)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={4}
              label={"Price/Unit"}
              value={NumberFormat(data.Price)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={8}
              label={"Dr Acc. Code"}
              value={
                data.DrAcc !== null && data.DrAcc !== "" && data.DrAcc !== undefined
                  ? data.DrAccDesc !== null && data.DrAccDesc !== "" && data.DrAccDesc !== undefined
                    ? data.DrAcc + " : " + data.DrAccDesc
                    : data.DrAcc
                  : ""
              }
            />
            <TextTopInGrid
              sizeSm={4}
              label={"Amount"}
              value={NumberFormat(data.NetAmt)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={8}
              label={"Cr Acc. Code"}
              value={
                data.CrAcc !== null && data.CrAcc !== "" && data.CrAcc !== undefined
                  ? data.CrAccDesc !== null && data.CrAccDesc !== "" && data.CrAccDesc !== undefined
                    ? data.CrAcc + " : " + data.CrAccDesc
                    : data.CrAcc
                  : ""
              }
            />
            <TextTopInGrid
              sizeSm={4}
              label={"Total"}
              value={NumberFormat(data.TotalAmt)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            {data.TaxType1 !== "None" && (
              <>
                <Grid xs={12}>
                  <Typography variant="h6" style={{ marginLeft: 7 }}>
                    {translate("ra.field.Tax 1")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={`${translate("ra.field.Tax Type")}1`}
                    value={data.TaxType1}
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={`${translate("ra.field.Tax Rate")}1`}
                    value={NumberFormat(data.TaxRate1)}
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </Grid>

                {data.TaxOverwrite1 === "O" ? (
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      label="Overwrite"
                      control={<Checkbox checked={data.TaxOverwrite1 === "O"} color="primary" size="small" />}
                    />
                    <br />
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item xs={12} sm={8}>
                  <TextField
                    label={`${labelList["DrAcc"]} (${labelList["Tax"]}1)`}
                    value={
                      data.TaxAcc1 !== null && data.TaxAcc1 !== "" && data.TaxAcc1 !== undefined
                        ? data.TaxAcc1Desc !== null && data.TaxAcc1Desc !== "" && data.TaxAcc1Desc !== undefined
                          ? data.TaxAcc1 + " : " + data.TaxAcc1Desc
                          : data.TaxAcc1
                        : ""
                    }
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={`${translate("ra.field.Tax 1")}`}
                    value={NumberFormat(data.TaxBaseAmt1)}
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </Grid>
              </>
            )}
            {data.TaxType2 !== "None" && (
              <>
                <Grid xs={12}>
                  <Typography variant="h6" style={{ marginLeft: 7 }}>
                    {translate("ra.field.Tax 2")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={`${translate("ra.field.Tax Type")}2`}
                    value={data.TaxType2}
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={`${translate("ra.field.Tax Rate")}2`}
                    value={NumberFormat(data.TaxRate2)}
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    sizeSm={8}
                    label={`${labelList["DrAcc"]} (${labelList["Tax"]}2)`}
                    value={
                      data.TaxAcc2 !== null && data.TaxAcc2 !== "" && data.TaxAcc2 !== undefined
                        ? data.TaxAcc2Desc !== null && data.TaxAcc2Desc !== "" && data.TaxAcc2Desc !== undefined
                          ? data.TaxAcc2 + " : " + data.TaxAcc2Desc
                          : data.TaxAcc2
                        : ""
                    }
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={`${translate("ra.field.Tax 2")}`}
                    value={NumberFormat(data.TaxBaseAmt2)}
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid xs={12} container direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" style={{ marginLeft: 7 }}>
                {translate("ra.field.Base Summary")}
              </Typography>
              <Typography variant="body" style={{ marginRight: 7 }}>
                {translate("ra.field.Rate")} : {NumberFormat(CurRate)}
              </Typography>
            </Grid>
            <TextTopInGrid
              sizeSm={12}
              label={"Net Amount"}
              value={NumberFormat(data.NetBaseAmt)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={12}
              label={"Tax 1"}
              value={NumberFormat(data.TaxBaseAmt1)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            {data.TaxType2 !== "None" && (
              <TextTopInGrid
                sizeSm={12}
                label={"Tax 2"}
                value={NumberFormat(data.TaxBaseAmt2)}
                inputProps={{
                  style: { textAlign: "right" },
                }}
              />
            )}
            <TextTopInGrid
              sizeSm={12}
              label={"Total"}
              value={NumberFormat(data.NetBaseAmt + data.TaxBaseAmt1 + data.TaxBaseAmt2)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            {dimensionContent()}
          </Grid>
        </DialogContent>
        <br />
      </Dialog>
    </>
  );
}

export default DialogArContractTax;
