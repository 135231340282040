/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Loading, Error, useRedirect, useTranslate } from "react-admin";
import { useMediaQuery, Paper, Grid, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import TextTopInGrid from "components/TextTopInGrid";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import NavRight from "components/NavRightSide";
import { GetWfStatus } from "utils/options";
import { getVendorDetail, delVendorDetail } from "services/accountPayable";
import { getWorkflowHistory } from "services/workflow";
import { showReportByName } from "pages/Report/services";
import { permissionName } from "utils/constants";
import SnackbarUtils from "utils/SnackbarUtils";
import CustomToolbarSelect from "./CustomToolbarSelect";
import ReportBackDrop from "components/ReportBackDrop";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 12,
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  drawerOpen: {
    marginRight: drawerWidth,
  },
  drawerClose: {
    marginRight: 54,
  },
}));

const Show = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const redirect = useRedirect();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [openDim, setOpenDim] = useState(false);
  const [wfHisList, setWfHisList] = useState();

  const { basePath, id, permissions, wfEnumStatus, wfSteps } = props;

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", fnc: () => redirect("create", basePath) },
    { name: "Edit", fnc: () => redirect("edit", basePath, id) },
    { name: "Delete", fnc: () => DelOrVoid() },
    {
      name: "Print",
      fnc: async () => {
        props.showReportLoader();
        await showReportByName("AP_Vendor", [{ Name: "VendorCode", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
  ];

  const fetchVendorById = useCallback(
    async (mounted) => {
      const response = await getVendorDetail(id);
      if (response) {
        setData(response);
      }
      if (mounted) {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [id]
  );

  const fetchWorkflowHistory = useCallback(
    async (wfCode) => {
      const { data } = await getWorkflowHistory(wfCode, id);
      if (data) {
        setWfHisList(data);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    fetchVendorById(mounted);
    fetchWorkflowHistory("AP_VENDOR");
    return function cleanup() {
      mounted = false;
    };
  }, [fetchVendorById]);

  const DelOrVoid = async () => {
    const msg = translate("ra.question.confirmDel");
    SnackbarUtils.delConfirm(msg, async function () {
      const { Code, InternalMessage, UserMessage } = await delVendorDetail(id);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect("list", basePath);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    });
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  const InformationContent = () => {
    return (
      <>
        <BoxHeader header={"Information"} />
        <Grid container alignItems="flex-start" spacing={1} style={{ paddingLeft: 4, margin: "0 0 12px 0" }}>
          <Grid container item xs={12} sm={12} spacing={1}>
            <TextTopInGrid sizeSm={6} label="Vendor No." value={`${data.VnCode} : ${data.VnName}`} />
            <TextTopInGrid
              sizeSm={6}
              label="Vendor Category"
              value={`${data.VnCateCode} ${data.VnCateDesc ? `: ${data.VnCateDesc}` : ""}`}
            />
            <TextTopInGrid sizeSm={2} label="Title" value={`${data.PreVnPayee ? data.PreVnPayee : ""}`} />
            <TextTopInGrid sizeSm={4} label="WHT. Name" value={`${data.VnPayee ? data.VnPayee : ""}`} />
            <TextTopInGrid sizeSm={4} label="Chq. Name" value={data.Vn2Payee} />
            <TextTopInGrid sizeSm={2} label="Contact Name" value={data.VnAttn} />

            <TextTopInGrid sizeSm={2} label="TAX ID." value={data.VnTaxNo} />
            <TextTopInGrid sizeSm={2} label="Branch No." value={data.BranchNo} />
            <TextTopInGrid sizeSm={2} label="Bus Reg No." value={data.VnRegNo} />
            <TextTopInGrid sizeSm={2} label="Credit Term" value={data.VnTerm} />
            <TextTopInGrid sizeSm={2} label="Discount Term" value={data.VnDisTrm} />
            <TextTopInGrid sizeSm={2} label="Less (%)" value={data.VnDisPct} />
            <TextTopInGrid sizeSm={2} label="Reference" value={data.VnReference} />
            <TextTopInGrid sizeSm={2} label="Mapping Ref." value={data.VnMapRef} />
            <Grid item xs={8} sm={8} />
            <TextTopInGrid
              sizeSm={6}
              label="Address1"
              value={
                data?.VnAdd1 && data?.VnAdd1 !== ""
                  ? `${data?.VnAdd1 ?? ""} \n ${data?.VnAdd2 ?? ""} \n ${data?.VnAdd3 ?? ""} \n ${data?.VnAdd4 ?? ""}`
                  : ""
              }
            />
            <TextTopInGrid
              sizeSm={6}
              label="Address2"
              value={
                data?.Vn2Add1 && data?.Vn2Add1 !== ""
                  ? `${data?.Vn2Add1 ?? ""} \n ${data?.Vn2Add2 ?? ""} \n ${data?.Vn2Add3 ?? ""} \n ${
                      data?.Vn2Add4 ?? ""
                    }`
                  : ""
              }
            />

            <TextTopInGrid sizeSm={6} label="Telephone" value={data.VnTel} />

            <TextTopInGrid sizeSm={6} label="Telephone" value={data.Vn2Tel} />

            <TextTopInGrid sizeSm={6} label="Fax" value={data.VnFax} />
            <TextTopInGrid sizeSm={6} label="Fax" value={data.Vn2Fax} />
            <TextTopInGrid sizeSm={6} label="Email" value={data.VnEmail} />

            <TextTopInGrid sizeSm={6} label="Email" value={data.Vn2Email} />
          </Grid>
        </Grid>
      </>
    );
  };

  const InvoiceDefault = () => {
    return (
      <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
        <TextTopInGrid sizeSm={6} label="Vat Type 1" value={data.VnVat1} />
        <TextTopInGrid sizeSm={6} label="Vat Rate 1 (%)" value={data.VnTaxR1} />
        <TextTopInGrid
          sizeSm={6}
          label="Dept. Dr Vat 1"
          value={
            data.VnVat1DrDeptCode
              ? `${data.VnVat1DrDeptCode} ${data.VnVat1DrDeptDesc ? `: ${data.VnVat1DrDeptDesc}` : ""}`
              : ""
          }
        />
        <TextTopInGrid
          sizeSm={6}
          label="Acc. Dr Vat 1"
          value={
            data.VnVat1DrAccCode
              ? `${data.VnVat1DrAccCode} ${data.VnVat1DrAccDesc ? `: ${data.VnVat1DrAccDesc}` : ""}`
              : ""
          }
        />

        <TextTopInGrid sizeSm={6} label="Vat Type 2" value={data.VnVat2} />
        <TextTopInGrid sizeSm={6} label="Vat Rate 2 (%)" value={data.VnTaxR2} />
        <TextTopInGrid
          sizeSm={6}
          label="Dept. Dr Vat 2"
          value={
            data.VnVat2DrDeptCode
              ? `${data.VnVat2DrDeptCode} ${data.VnVat2DrDeptDesc ? `: ${data.VnVat2DrDeptDesc}` : ""}`
              : ""
          }
        />
        <TextTopInGrid
          sizeSm={6}
          label="Acc. Dr Vat 2"
          value={
            data.VnVat2DrAccCode
              ? `${data.VnVat2DrAccCode} ${data.VnVat2DrAccDesc ? `: ${data.VnVat2DrAccDesc}` : ""}`
              : ""
          }
        />
        <TextTopInGrid
          sizeSm={6}
          label="Cr A/P Dept."
          value={data.VnCrDeptCode ? `${data.VnCrDeptCode} ${data.VnCrDeptDesc ? `: ${data.VnCrDeptDesc}` : ""}` : ""}
        />
        <TextTopInGrid
          sizeSm={6}
          label="Cr A/P Acc."
          value={
            data.VnVatCrAccCode ? `${data.VnVatCrAccCode} ${data.VnVatCrAccDesc ? `: ${data.VnVatCrAccDesc}` : ""}` : ""
          }
        />
      </Grid>
    );
  };

  const PaymentDefault = () => {
    return (
      <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
        <TextTopInGrid
          sizeSm={12}
          label="Type"
          value={`${data.VnPayType} ${data.VnPayTypeDesc ? `: ${data.VnPayTypeDesc}` : ""}`}
        />
        <TextTopInGrid
          sizeSm={6}
          label="Cr. Payment Dept."
          value={
            data.VnPayDeptCode ? `${data.VnPayDeptCode} ${data.VnPayDeptDesc ? `: ${data.VnPayDeptDesc}` : ""}` : ""
          }
        />
        <TextTopInGrid
          sizeSm={6}
          label="Cr. Payment Acc."
          value={data.VnCrAccCode ? `${data.VnCrAccCode} ${data.VnCrAccDesc ? `: ${data.VnCrAccDesc}` : ""}` : ""}
        />
        <TextTopInGrid sizeSm={12} label="WHT. Form" value={data?.VnWhtTypeCode} />
        <TextTopInGrid sizeSm={6} label="WHT. Service Type" value={data?.VnWhtCode} />
        <TextTopInGrid sizeSm={6} label="Tax Rate %" value={data?.VnWhtRate} />
        <TextTopInGrid
          sizeSm={6}
          label="Cr. WHT. Dept."
          value={
            data.VnWhtDeptCode ? `${data.VnWhtDeptCode} ${data.VnWhtDeptDesc ? `: ${data.VnWhtDeptDesc}` : ""}` : ""
          }
        />
        <TextTopInGrid
          sizeSm={6}
          label="Cr. WHT. Acc."
          value={data.VnWhtAccCode ? `${data.VnWhtAccCode} ${data.VnWhtAccDesc ? `: ${data.VnWhtAccDesc}` : ""}` : ""}
        />
      </Grid>
    );
  };

  const AutoPayInfo = () => {
    return (
      <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
        <TextTopInGrid sizeSm={12} label="Bank Name" value={data.BankName} />
        <TextTopInGrid sizeSm={12} label="Acc Name" value={data.BankAccountName} />
        <TextTopInGrid sizeSm={12} label="Acc No" value={data.BankAccountNo} />
        <TextTopInGrid sizeSm={12} label="Bank Ref 1" value={data.BankRef1 ?? ""} />
        <TextTopInGrid sizeSm={12} label="Bank Ref 2" value={data.BankRef2 ?? ""} />
        <TextTopInGrid sizeSm={12} label="Bank Ref 3" value={data.BankRef3 ?? ""} />
      </Grid>
    );
  };

  const SearchByNo = (e) => {
    if (e) {
      redirect("show", basePath, e.Id);
    }
  };

  return (
    <div
      // className={clsx(classes.drawer, {
      //   [classes.drawerOpen]: openDim,
      //   [classes.drawerClose]: !openDim,
      // })}
      className={classes.drawerClose}
    >
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["AP.Vendor"])}
      />
      {data &&
        wfEnumStatus &&
        data.InvhStatus !== "Effective" &&
        data.InvhStatus !== "Void" &&
        data.InvhStatus !== "Step0" && (
          <CustomToolbarSelect
            selectedRows={{ data: data }}
            status={parseInt(wfEnumStatus[data.VdStatus])}
            wfCode={"AP_VENDOR"}
            fncSuccess={() => {
              fetchVendorById();
              fetchWorkflowHistory("AP_VENDOR");
            }}
          />
        )}

      <Paper className={classes.root}>
        <BoxHeader
          header={"Vendor"}
          showSearch
          searchOption={{
            placeholder: `${translate("ra.fieldAbbr.searchby")} ${translate("ra.field.Vendor No.")}`,
            update: SearchByNo,
            module: "AP_Vendor",
            keyCode: "VnCode",
            keyDesc: "VnName",
          }}
          data={data}
          wfStatus={GetWfStatus(wfSteps, wfEnumStatus, data.VdStatus)}
          status={
            GetWfStatus(wfSteps, wfEnumStatus, data.VdStatus)
              ? GetWfStatus(wfSteps, wfEnumStatus, data.VdStatus)
              : data.Active
          }
        />
        <Grid container justifyContent="flex-start" spacing={1}>
          <Grid item xs={12}>
            <InformationContent />
          </Grid>
        </Grid>
        {/* <Grid container spacing={1} justifyContent="flex-start">
          <Grid item xs={6}>
            <BoxHeader header={"Invoice Default"} />
          </Grid>
          <Grid item xs={6}>
            <BoxHeader header={"Payment Default"} />
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid container item xs={6} justifyContent="flex-start">
            <InvoiceDefault />
          </Grid>
          <Grid container item xs={6} justifyContent="flex-start">
            <PaymentDefault />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BoxHeader header={"AutoPay Information"} />
        </Grid>
        <Grid container item xs={12} spacing={1} justifyContent="flex-start">
          <Grid item xs={12}>
            <AutoPayInfo />
          </Grid>
        </Grid> */}
      </Paper>

      <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
        <Grid item xs={6}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
              <Typography className={classes.heading}>{translate("ra.module.Invoice Default")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvoiceDefault />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={6}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <Typography className={classes.heading}>{translate("ra.module.Payment Default")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PaymentDefault />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
              <Typography className={classes.heading}>{translate("ra.module.AutoPay Information")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AutoPayInfo />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      {data &&
        wfEnumStatus &&
        data.VdStatus !== "Effective" &&
        data.VdStatus !== "Void" &&
        data.VdStatus !== "Step0" &&
        isXSmall && (
          <CustomToolbarSelect
            selectedRows={{ data: data }}
            status={parseInt(wfEnumStatus[data.VdStatus])}
            wfCode={"AP_VENDOR"}
            fncSuccess={() => {
              fetchVendorById();
              fetchWorkflowHistory("AP_VENDOR");
            }}
          />
        )}

      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => setOpenDim(true)}
        module={"Vendor"}
        moduleId={id}
        wfHisList={wfHisList}
      />

      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
    </div>
  );
};

export default ReportBackDrop(Show);
