import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
//import clsx from "clsx";
import { Loading, Error, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid, Typography } from "@material-ui/core";
import TextTopInGrid from "components/TextTopInGrid";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import NavRight from "components/NavRightSide";
import { getAmortizeStdJvDetail, delAmortizeStdJvDetail } from "services/generalLedger";
import { permissionName } from "utils/constants";
import SnackbarUtils from "utils/SnackbarUtils";
import HisAmortize from "./HisAmortize";

const Show = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { basePath, id, permissions } = props;
  const { DateToString, NumberFormat } = useContext(GblContext);
  const redirect = useRedirect();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [openDim, setOpenDim] = useState(false);

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", fnc: () => redirect("create", basePath) },
    { name: "Edit", fnc: () => redirect("edit", basePath, id) },
    { name: "Delete", fnc: () => DelOrVoid() },
    // { name: "Print" },
  ];

  const DelOrVoid = async () => {
    let msg = translate("ra.question.confirmDel");
    SnackbarUtils.delConfirm(msg, async function () {
      const { Code, InternalMessage, UserMessage } = await delAmortizeStdJvDetail(id);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect("list", basePath);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    });
  };

  const fetchJvFrById = useCallback(
    async (mounted) => {
      const response = await getAmortizeStdJvDetail(id).catch((error) => {
        setError(error);
      });
      if (response) {
        response.TypeOfAmortize = response.TypeOfAmortize === "DayInMonth" ? "Day In Month" : response.TypeOfAmortize;
        setData(response);
      }

      if (mounted) {
        setLoading(false);
      }
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    fetchJvFrById(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, [fetchJvFrById]);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  const ShowDim = () => {
    setOpenDim(!openDim);
  };

  return (
    <div
      // className={clsx(classes.drawer, {
      //   [classes.drawerOpen]: openDim,
      //   [classes.drawerClose]: !openDim,
      // })}
      className={classes.drawerClose}
    >
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["GL.AmortizationStdJv"])}
      />

      <Paper style={{ padding: 12, marginBottom: 12 }}>
        <BoxHeader header={"Amortization Voucher"} />
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
          <TextTopInGrid sizeSm={2} label="Prefix" value={data.Prefix} />
          <TextTopInGrid sizeSm={2} label="Amortized by" value={data.TypeOfAmortize} />
          {data.TypeOfAmortize === "Day In Month" ? (
            <>
              <TextTopInGrid sizeSm={2} label="Start Date" value={DateToString(data.StartDate ?? new Date())} />
              <TextTopInGrid sizeSm={2} label="End Date" value={DateToString(data.EndDate ?? new Date())} />
            </>
          ) : (
            <>
              <TextTopInGrid sizeSm={2} label="Start Date" value={DateToString(data.StartDate, "MM/yyyy")} />
              <TextTopInGrid sizeSm={2} label="End Date" value={DateToString(data.EndDate, "MM/yyyy")} />
            </>
          )}
          <TextTopInGrid sizeSm={2} label="Currency" value={data.CurCode} />
          <TextTopInGrid sizeSm={2} label="Rate" value={NumberFormat(data.CurRate)} />
          <TextTopInGrid sizeSm={8} label="Description" value={data.Description} />
          <TextTopInGrid sizeSm={2} label="Amount to Amortize" value={NumberFormat(data.AmortizeAmt)} />
          <TextTopInGrid sizeSm={2} label="Base Amount to Amortize" value={NumberFormat(data.AmortizeBAmt)} />
        </Grid>
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
          <Grid item xs={12}>
            <Typography className={classes.heading} style={{ marginLeft: 4 }}>
              {translate("ra.module.Amortize Account")}
            </Typography>
            <br />
          </Grid>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={2}>
              <Typography className={classes.heading} style={{ marginLeft: 6 }}>
                {translate("ra.field.Dr Account")}
              </Typography>
            </Grid>
            <TextTopInGrid sizeSm={5} label="Dr Dept. Code" value={`${data.DrDeptCode} : ${data.DrDeptDesc}`} />
            <TextTopInGrid sizeSm={5} label="Dr Acc. Code" value={`${data.DrAccCode} : ${data.DrAccDesc}`} />
            <Grid item xs={2}>
              <Typography className={classes.heading} style={{ marginLeft: 6 }}>
                {translate("ra.field.Cr Account")}
              </Typography>
            </Grid>
            <TextTopInGrid sizeSm={5} label="Cr Dept. Code" value={`${data.CrDeptCode} : ${data.CrDeptDesc}`} />
            <TextTopInGrid sizeSm={5} label="Cr Acc. Code" value={`${data.CrAccCode} : ${data.CrAccDesc}`} />
          </Grid>
        </Grid>
      </Paper>

      <HisAmortize Id={id} />

      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => ShowDim()}
        dataDim={[]}
        module={"GL_AmortizeStdJv"}
        moduleId={id}
      />
      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
    </div>
  );
};

export default Show;
