import React, { useState, useEffect, useCallback } from "react";
//import { GblContext } from "providers/formatter";
import { Grid, TextField, InputBase, Button, CircularProgress, Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import DatePickerFormat from "components/DatePickerFormat";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { matchSorter } from "match-sorter";
import ListBox from "components/ListBox";
import PopperListBox from "components/PopperListBox";
import { getAstDepartment, getAstLocation, changeLocation } from "services/asset";
//import { addDays } from "date-fns";
import { getLookup } from "services/lookup";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  option: {
    width: 500,
    fontSize: 14,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogEditAfterClsPeriod = (props) => {
  const classes = useStyles();
  //const { settingAll } = useContext(GblContext);
  //const { SettingClosePeriod } = settingAll;
  //const { ClosePeriodAsset } = SettingClosePeriod;
  //let newClosePeriodAsset = addDays(new Date(ClosePeriodAsset), 1);
  const { onClose, open, RegId, DepartmentCode, AstDeptDesc, LocationCode, AstLocDesc, TransferDate } = props;
  const [isLoading, setLoading] = useState(false);
  const [transferDate, SetTransferDate] = useState(new Date(TransferDate));
  const [deptCode, setDeptCode] = useState(DepartmentCode);
  const [deptDesc, setDeptDesc] = useState(AstDeptDesc);
  const [locCode, setLocCode] = useState(LocationCode);
  const [locDesc, setLocDesc] = useState(AstLocDesc);
  const [depreDeptCode, setDepreDeptCode] = useState(props.DepreDeptCode);
  const [depreDeptDesc, setDepreDeptDesc] = useState(props.DepreDeptDesc);
  const [depreAccCode, setDepreAccCode] = useState(props.DepreAccCode);
  const [depreAccDesc, setDepreAccDesc] = useState(props.DepreAccDesc);

  const [costDeptCode, setCostDeptCode] = useState(props.CostDeptCode);
  const [costDeptDesc, setCostDeptDesc] = useState(props.CostDeptDesc);
  const [costAccCode, setCostAccCode] = useState(props.CostAccCode);
  const [costAccDesc, setCostAccDesc] = useState(props.CostAccDesc);

  const [lookupList, setLookupList] = useState({
    departmentList: [],
    accountCodeList: [],
    astDepartmentList: [],
    astLocationList: [],
  });

  const fetchDepartmentLookup = useCallback(async () => {
    const deptArr = await getLookup("Department");
    setLookupList((state) => ({
      ...state,
      departmentList: deptArr,
    }));
  }, []);

  const fetchAccountCodeLookup = useCallback(async () => {
    const accArr = await getLookup("Account");
    setLookupList((state) => ({
      ...state,
      accountCodeList: accArr,
    }));
  }, []);

  const fetchAstDepartmentLookup = useCallback(async () => {
    const { Data } = await getAstDepartment();
    setLookupList((state) => ({
      ...state,
      astDepartmentList: Data,
    }));
  }, []);

  const fetchAstLocationLookup = useCallback(async () => {
    const { Data } = await getAstLocation();
    setLookupList((state) => ({
      ...state,
      astLocationList: Data,
    }));
  }, []);

  useEffect(() => {
    fetchDepartmentLookup();
    fetchAccountCodeLookup();
    fetchAstDepartmentLookup();
    fetchAstLocationLookup();
  }, [fetchDepartmentLookup, fetchAccountCodeLookup, fetchAstDepartmentLookup, fetchAstLocationLookup]);

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = async () => {
    setLoading(true);
    let param = {
      FromRegId: RegId,
      DepartmentCode: deptCode,
      LocationCode: locCode,
      TransferDate: transferDate,
      CostDeptCode: costDeptCode,
      CostAccCode: costAccCode,
      DepreDeptCode: depreDeptCode,
      DepreAccCode: depreAccCode,
      UserModified: localStorage.getItem("UserName"),
    };
    const { Code, UserMessage, InternalMessage } = await changeLocation(param);
    if (Code === 0) {
      setLoading(false);
      setLoading(false);
      SnackbarUtils.success(UserMessage, function () {
        onClose(RegId);
      });
    } else {
      setLoading(false);
      SnackbarUtils.error(InternalMessage);
    }
  };

  const filterOptions = (options, { inputValue }, optKey, optDesc) => {
    return matchSorter(options, inputValue, {
      keys: [`${optKey}`, `${optDesc}`],
    });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">Edit</DialogTitle>
      <DialogContent dividers>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={4}>
            <Autocomplete
              options={lookupList["astDepartmentList"]}
              disableClearable
              disableListWrap
              defaultValue={deptCode}
              value={deptCode}
              onChange={(e, newItem) => {
                setDeptCode(newItem.DepartmentCode);
                setDeptDesc(newItem.Description);
              }}
              ListboxComponent={ListBox}
              PopperComponent={PopperListBox}
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => {
                return typeof option === "object" ? option.DepartmentCode : option;
              }}
              getOptionSelected={(option, value) => option.DepartmentCode === value}
              renderOption={(option, { inputValue }) => {
                const mergestring = `${option.DepartmentCode} : ${option.Description}`;

                const matches = match(mergestring, inputValue);
                const parts = parse(mergestring, matches);
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Department" margin="dense" placeholder="Department" />
              )}
              filterOptions={(options, { inputValue }) =>
                filterOptions(options, { inputValue }, "DepartmentCode", "Description")
              }
            />
          </Grid>
          <Grid item xs={8}>
            <InputBase fullWidth value={deptDesc} />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={lookupList["astLocationList"]}
              disableClearable
              disableListWrap
              defaultValue={locCode}
              value={locCode}
              onChange={(e, newItem) => {
                setLocCode(newItem.LocCode);
                setLocDesc(newItem.Description);
              }}
              ListboxComponent={ListBox}
              PopperComponent={PopperListBox}
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => {
                return typeof option === "object" ? option.LocCode : option;
              }}
              getOptionSelected={(option, value) => option.LocCode === value}
              renderOption={(option, { inputValue }) => {
                const mergestring = `${option.LocCode} : ${option.Description}`;
                const matches = match(mergestring, inputValue);
                const parts = parse(mergestring, matches);
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Location" margin="dense" placeholder="Location" />
              )}
              filterOptions={(options, { inputValue }) =>
                filterOptions(options, { inputValue }, "LocCode", "Description")
              }
            />
          </Grid>
          <Grid item xs={8}>
            <InputBase fullWidth value={locDesc} />
          </Grid>
          <Grid item xs={12}>
            <DatePickerFormat
              label="TransferDate"
              //minDate={new Date(newClosePeriodAsset)}
              //minDateMessage={"Date must be more than close period"}
              value={transferDate}
              onChange={(e) => SetTransferDate(e)}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={lookupList["departmentList"]}
              disableClearable
              disableListWrap
              defaultValue={depreDeptCode}
              value={depreDeptCode}
              onChange={(e, newItem) => {
                setDepreDeptCode(newItem.Code);
                setDepreDeptDesc(newItem.Desc);
              }}
              ListboxComponent={ListBox}
              PopperComponent={PopperListBox}
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => {
                return typeof option === "object" ? option.Code : option;
              }}
              getOptionSelected={(option, value) => option.Code === value}
              renderOption={(option, { inputValue }) => {
                const mergestring = `${option.Code} : ${option.Desc}`;

                const matches = match(mergestring, inputValue);
                const parts = parse(mergestring, matches);
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Depre Dept." margin="dense" placeholder="Department" />
              )}
              filterOptions={(options, { inputValue }) => filterOptions(options, { inputValue }, "Code", "Desc")}
            />
          </Grid>
          <Grid item xs={8}>
            <InputBase fullWidth value={depreDeptDesc} />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={lookupList["accountCodeList"]}
              disableClearable
              disableListWrap
              defaultValue={depreAccCode}
              value={depreAccCode}
              onChange={(e, newItem) => {
                setDepreAccCode(newItem.Code);
                setDepreAccDesc(newItem.Desc);
              }}
              ListboxComponent={ListBox}
              PopperComponent={PopperListBox}
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => {
                return typeof option === "object" ? option.Code : option;
              }}
              getOptionSelected={(option, value) => option.Code === value}
              renderOption={(option, { inputValue }) => {
                const mergestring = `${option.Code} : ${option.Desc}`;
                const matches = match(mergestring, inputValue);
                const parts = parse(mergestring, matches);
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Depre Acc." margin="dense" placeholder="Depre Acc." />
              )}
              filterOptions={(options, { inputValue }) => filterOptions(options, { inputValue }, "Code", "Desc")}
            />
          </Grid>
          <Grid item xs={8}>
            <InputBase fullWidth value={depreAccDesc} />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={lookupList["departmentList"]}
              disableClearable
              disableListWrap
              defaultValue={costDeptCode}
              value={costDeptCode}
              onChange={(e, newItem) => {
                setCostDeptCode(newItem.Code);
                setCostDeptDesc(newItem.Desc);
              }}
              ListboxComponent={ListBox}
              PopperComponent={PopperListBox}
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => {
                return typeof option === "object" ? option.Code : option;
              }}
              getOptionSelected={(option, value) => option.Code === value}
              renderOption={(option, { inputValue }) => {
                const mergestring = `${option.Code} : ${option.Desc}`;

                const matches = match(mergestring, inputValue);
                const parts = parse(mergestring, matches);
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Cost Dept." margin="dense" placeholder="Department" />
              )}
              filterOptions={(options, { inputValue }) => filterOptions(options, { inputValue }, "Code", "Desc")}
            />
          </Grid>
          <Grid item xs={8}>
            <InputBase fullWidth value={costDeptDesc} />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={lookupList["accountCodeList"]}
              disableClearable
              disableListWrap
              defaultValue={costAccCode}
              value={costAccCode}
              onChange={(e, newItem) => {
                setCostAccCode(newItem.Code);
                setCostAccDesc(newItem.Desc);
              }}
              ListboxComponent={ListBox}
              PopperComponent={PopperListBox}
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => {
                return typeof option === "object" ? option.Code : option;
              }}
              getOptionSelected={(option, value) => option.Code === value}
              renderOption={(option, { inputValue }) => {
                const mergestring = `${option.Code} : ${option.Desc}`;
                const matches = match(mergestring, inputValue);
                const parts = parse(mergestring, matches);
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Cost Acc." margin="dense" placeholder="Cost Acc." />
              )}
              filterOptions={(options, { inputValue }) => filterOptions(options, { inputValue }, "Code", "Desc")}
            />
          </Grid>
          <Grid item xs={8}>
            <InputBase fullWidth value={costAccDesc} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton text="OK" disabled={isLoading} fnc={handleOk} />
        <Button variant="outlined" className={classes.textCancel} onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEditAfterClsPeriod;
