import React, { useState } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import {
  getAllocationJvSearchList,
  getJvFrSearchList,
  getJvFrDetail,
  getAllocationJvDetail,
} from "services/generalLedger";
import MUIDataTable from "mui-datatables";
import CustomTablePagination from "components/CustomTablePagination";
import {
  Paper,
  Box,
  Button,
  Dialog,
  Radio,
  RadioGroup,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import CloseIcon from "@material-ui/icons/Close";
import StepLabel from "@material-ui/core/StepLabel";
import ModelUriQueryString from "models/uriQueryString";
import FormEditTemplate from "./FormFieldTemplate/FormEditTemplate";
import FormEditAllocation from "./FormFieldTemplate/FormEditAllocation";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function getSteps() {
  return ["Select Template Type", "Select Template", "Adjust Template"];
}

const selectOptions = [
  {
    value: "1",
    desc: "Select from template",
  },
  {
    value: "2",
    desc: "Select from allocation",
  },
];

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogSelectTemplate = (props) => {
  const classes = useStyles();
  //const translate = useTranslate();
  const { onClose, open } = props;
  const [Data, setData] = useStateWithCallbackLazy();
  const [Paging, setPaging] = useState();
  const [uriQueryString, setUriQueryString] = useState({
    Limit: ModelUriQueryString.Limit,
    Page: ModelUriQueryString.Page,
    OrderBy: ModelUriQueryString.OrderBy,
    WhereGroupList: ModelUriQueryString.WhereGroupList,
    Exclude: ModelUriQueryString.Exclude,
    WhereRaw: ModelUriQueryString.WhereRaw,
    WhereLike: ModelUriQueryString.WhereLike,
    WhereLikeFields: ModelUriQueryString.WhereLikeFields,
  });
  const [searchText, setSearchText] = useState();
  const [adjustData, setAdjustData] = useStateWithCallbackLazy();
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ value: "1" });
  const [selectedRow, setSelectedRow] = useState();
  const radioGroupRef = React.useRef(null);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  React.useEffect(() => {
    if (!open) {
      setSelectedOption(selectOptions[0]);
    }
  }, [open]);

  const fetchSearchList = async (uriQueryString, templateType) => {
    uriQueryString.Exclude = ["Detail", "DimHList"];
    if (templateType === "1") {
      uriQueryString.WhereGroupList = [
        {
          AndOr: "And",
          ConditionList: [
            {
              AndOr: "And",
              Field: "FJvhFreq",
              Operator: "=",
              Value: 0,
            },
          ],
        },
      ];
      const { Data, Paging } = await getJvFrSearchList(uriQueryString);
      if (Data) {
        setLoadingBtn(false);
        setData(Data);
        setPaging(Paging);
        setUriQueryString(uriQueryString);
      } else {
        setLoadingBtn(false);
        setData([]);
      }
    }

    if (templateType === "2") {
      uriQueryString.WhereGroupList = [];
      const { Data, Paging } = await getAllocationJvSearchList(uriQueryString);
      if (Data) {
        Data.forEach((element) => {
          element.Type = "Allocation";
        });
      }

      if (Data) {
        setLoadingBtn(false);
        setData(Data);
        setPaging(Paging);
        setUriQueryString(uriQueryString);
      } else {
        setLoadingBtn(false);
        setData([]);
      }
    }
  };

  const handleRequestSort = (property, order) => {
    uriQueryString.OrderBy = { [property]: order };
    fetchSearchList(uriQueryString, selectedOption?.value);
  };

  const handleChangePage = (e, newPage) => {
    uriQueryString.Page = newPage + 1;
    fetchSearchList(uriQueryString, selectedOption?.value);
  };

  const handleChangeRowsPerPage = (e) => {
    let newRowsPerPage = parseInt(e.target.value);
    uriQueryString.Limit = newRowsPerPage;
    fetchSearchList(uriQueryString, selectedOption?.value);
  };

  const handleTableChange = (action, tableState) => {
    if (action === "onSearchClose") {
      setSearchText();
      uriQueryString.WhereLike = "";
      fetchSearchList(uriQueryString, selectedOption?.value);
    }
  };

  const columns = [
    {
      name: "FJvhSeq",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        display: false,
      },
    },
    {
      name: "Prefix",
      label: "Prefix",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Type",
      label: "Type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    responsive: "standard",
    selectableRows: "single",
    serverSide: true,
    viewColumns: false,
    print: false,
    download: false,
    searchText: searchText,
    searchProps: {
      onKeyUp: (e) => {
        if (e.keyCode === 13) {
          setSearchText(e.target.value);
          uriQueryString.WhereLike = `%${e.target.value}%`;
          //uriQueryString.WhereLikeFields = ["FPrefix", "FJvhDesc"];
          uriQueryString.Page = 1;
          fetchSearchList(uriQueryString, selectedOption?.value);
        }
      },
    },
    onTableChange: (action, tableState) => handleTableChange(action, tableState),

    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      if (rowsSelected.length > 0) {
        setSelectedRow(Data[rowsSelected[0]]);
      } else {
        setSelectedRow();
      }
    },
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    sortOrder: {
      name: Object.keys(uriQueryString.OrderBy)[0],
      direction: Object.values(uriQueryString.OrderBy)[0],
    },
    onColumnSortChange: (changedColumn, direction) => {
      //wait fix api sort
      handleRequestSort(changedColumn, direction);
    },
    customToolbarSelect: () => <Box sx={{ padding: 24 }}></Box>,
    customFooter: () => {
      return (
        <CustomTablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={Paging?.TotalRecordCount ?? 0}
          rowsPerPage={Paging?.Limit ?? 15}
          page={Paging?.Page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    },
  };

  const handleCancel = () => {
    onClose();
  };

  const handleChange = (event, value) => {
    event.persist();
    const name = event.target.name;
    setSelectedOption({ ...selectedOption, [name]: value });
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setSelectedRow();
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = async () => {
    setLoadingBtn(true);

    if (activeStep === 0) {
      await fetchSearchList(uriQueryString, selectedOption?.value);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep === 1 && selectedRow) {
      if (selectedOption.value === "1") {
        const response = await getJvFrDetail(selectedRow.FJvhSeq);
        setAdjustData(response);
        localStorage.setItem("template", JSON.stringify(response));
        localStorage.setItem("templateType", selectedOption?.value);
      }
      if (selectedOption.value === "2") {
        const response = await getAllocationJvDetail(selectedRow.AJvhSeq);
        setAdjustData(response);
        localStorage.setItem("template", JSON.stringify(response));
        localStorage.setItem("templateType", selectedOption?.value);
      }

      setLoadingBtn(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep === 2) {
      setLoadingBtn(false);
      onClose(adjustData, selectedOption.value);
    }
  };

  const LoadingButton = ({ text, disabled, loading, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button disabled={disabled} variant="contained" color="primary" onClick={fnc}>
          {text}
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <Dialog disableEscapeKeyDown fullWidth maxWidth="lg" aria-labelledby="confirmation-dialog-title" open={open}>
      <DialogTitle id="confirmation-dialog-title" onClose={handleCancel}>
        Select Template
      </DialogTitle>
      <DialogContent dividers>
        <Stepper activeStep={activeStep} style={{ marginTop: -20 }}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Paper elevation={3}>
          {activeStep === 0 && (
            <Box sx={{ padding: 20, display: "flex", justifyContent: "center" }}>
              <RadioGroup
                ref={radioGroupRef}
                aria-label="copyType"
                name="value"
                value={selectedOption.value}
                onChange={handleChange}
              >
                {selectOptions.map((option, idx) => (
                  <FormControlLabel label={option.desc} key={option.value} value={option.value} control={<Radio />} />
                ))}
              </RadioGroup>
            </Box>
          )}
          {activeStep === 1 && <MUIDataTable data={Data} columns={columns} options={options} />}
          {activeStep === 2 && selectedRow && (
            <>
              {selectedOption.value === "1" ? (
                <FormEditTemplate templateId={selectedRow.FJvhSeq} setAdjustData={setAdjustData} />
              ) : (
                <FormEditAllocation templateId={selectedRow.AJvhSeq} setAdjustData={setAdjustData} />
              )}
            </>
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <LoadingButton
          text={activeStep === steps.length - 1 ? "Finish" : "Next"}
          disabled={selectedRow ? false : activeStep === 1 ? true : false}
          loading={isLoadingBtn}
          fnc={handleNext}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DialogSelectTemplate;
