import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogOption = (props) => {
  const classes = useStyles();
  const { headerText, open, onClose, isLoading } = props;
  const [selectedLang, setSelectedLang] = React.useState("en-US");

  const handleCancel = () => {
    onClose();
  };

  const handleOk = (e) => {
    e.preventDefault();
    onClose(selectedLang);
  };

  const handleChange = (e) => {
    setSelectedLang(e.target.value);
  };

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event);
        }
      }}
    >
      <DialogTitle id="confirmation-dialog-title">{headerText}</DialogTitle>
      <DialogContent dividers>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="label">Language</InputLabel>
          <Select
            labelId="label"
            variant="outlined"
            margin="dense"
            label="Language"
            value={selectedLang}
            defaultValue={{ key: "en-US", label: "English" }}
            onChange={handleChange}
          >
            {[
              { key: "en-US", label: "English" },
              { key: "th-TH", label: "Thai (ไทย)" },
            ].map((item) => (
              <MenuItem key={item?.toString()} value={item.key}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <LoadingButton text="OK" disabled={isLoading} fnc={handleOk} />
        <Button variant="outlined" className={classes.textCancel} onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogOption;
