import React from "react";
import { CircularProgress, TextField, Avatar, Grid, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { getJvSearchList, getAllocationJvSearchList } from "services/generalLedger";
import { getAssetRegisterSearchList } from "services/asset";
import { getApVendorSearchList, getApInvoiceSearchList, getApPaymentSearchList } from "services/accountPayable";
import { getArProfileSearchList, getArInvoiceSearchList, getArReceiptSearchList } from "services/accountReceivable";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    padding: "4px !important",
  },
  option: {
    width: 500,
    fontSize: 14,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
}));

const FilterMenu = ({ searchOption }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const fetch = React.useMemo(
    () =>
      throttle(async (request, callback) => {
        var whereGroupList;
        var param;
        switch (searchOption.module) {
          case "AST_Reg":
            whereGroupList = [
              {
                AndOr: "And",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "AstId",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
              {
                AndOr: "Or",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "AstName",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
            ];
            param = {
              Limit: 100,
              Page: 1,
              OrderBy: { AstRegId: "asc" },
              WhereGroupList: whereGroupList,
              WhereLike: `%${request.input}%`,
              Exclude: [
                "Barcode",
                "Spec",
                "SerialNo",
                "CurRate",
                "Qty",
                "Salvage",
                "InitAccu",
                "TotalCost",
                "Life",
                "LifeType",
                "RemainDay",
                "RemainNet",
                "RemainTotalCost",
                "DimList",
                "RemainInfo",
                "RemainQty",
                "Remark",
                "TransferDate",
                "UnitCode",
                "LocationCode",
                "DepreAccCode",
                "DepreAccDesc",
                "DepreDeptCode",
                "DepreDeptDesc",
                "DepartmentCode",
                "CategoryCode",
                "CostAccCode",
                "CostAccDesc",
                "CostDeptCode",
                "CostDeptDesc",
                "AstCateDesc",
                "AstDeptDesc",
                "AstLocDesc",
                "AstPhoto",
                "AstUnitDesc",
                "AccuAccCode",
                "AccuAccDesc",
                "AccuDeptCode",
                "AccuDeptDesc",
                "AcquireDate",
                "Amount",
              ],
            };
            getAssetRegisterSearchList(param).then(callback);
            break;
          case "GL_Jv":
            whereGroupList = [
              {
                AndOr: "And",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "Prefix",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
              {
                AndOr: "Or",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "JvhNo",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
            ];
            param = {
              Limit: 100,
              Page: 1,
              OrderBy: { JvhSeq: "asc" },
              WhereGroupList: whereGroupList,
              Exclude: ["Detail", "DimHList"],
              WhereRaw: "JvhSource is NULL or JvhSource NOT LIKE ('ALOC')",
            };
            getJvSearchList(param).then(callback);
            break;
          case "Allocation_Jv":
            whereGroupList = [
              {
                AndOr: "And",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "Prefix",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
              {
                AndOr: "Or",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "JvhNo",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
            ];
            param = {
              Limit: 100,
              Page: 1,
              OrderBy: { JvhSeq: "asc" },
              WhereGroupList: whereGroupList,
              Exclude: ["Detail", "DimHList"],
            };
            getAllocationJvSearchList(param).then(callback);
            break;
          case "AP_Vendor":
            whereGroupList = [
              {
                AndOr: "And",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "VnCode",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
            ];
            param = {
              Limit: 100,
              Page: 1,
              OrderBy: { VnCode: "asc" },
              WhereGroupList: whereGroupList,
            };
            getApVendorSearchList(param).then(callback);
            break;
          case "AP_Invoice":
            whereGroupList = [
              {
                AndOr: "And",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "InvhInvNo",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
            ];
            param = {
              Limit: 100,
              Page: 1,
              OrderBy: { InvhSeq: "asc" },
              WhereGroupList: whereGroupList,
              Exclude: ["InvWht", "Detail", "DimHList"],
              //WhereRaw: "",
              //WhereLike: `%${request.input}%`,
              //WhereLikeFields: ["Prefix", "JvhNo"],
            };
            getApInvoiceSearchList(param).then(callback);
            break;
          case "AP_Payment":
            whereGroupList = [
              {
                AndOr: "And",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "PyhSeq",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
            ];
            param = {
              Limit: 100,
              Page: 1,
              OrderBy: { PyhSeq: "asc" },
              WhereGroupList: whereGroupList,
              Exclude: ["PayWht", "Detail", "DimHList"],
            };
            getApPaymentSearchList(param).then(callback);
            break;
          case "AR_Profile":
            whereGroupList = [
              {
                AndOr: "And",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "ArNo",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
            ];
            param = {
              Limit: 100,
              Page: 1,
              OrderBy: { ArNo: "asc" },
              WhereGroupList: whereGroupList,
            };
            getArProfileSearchList(param).then(callback);
            break;
          case "AR_Invoice":
            whereGroupList = [
              {
                AndOr: "And",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "InvNo",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
            ];
            param = {
              Limit: 100,
              Page: 1,
              OrderBy: { InvNo: "asc" },
              WhereGroupList: whereGroupList,
              Exclude: ["InvWht", "Detail", "DimHList"],
              //WhereRaw: "",
              //WhereLike: `%${request.input}%`,
              //WhereLikeFields: ["Prefix", "JvhNo"],
            };
            getArInvoiceSearchList(param).then(callback);
            break;
          case "AR_Receipt":
            whereGroupList = [
              {
                AndOr: "And",
                ConditionList: [
                  {
                    AndOr: "And",
                    Field: "RcptRefNo",
                    Operator: "Like",
                    Value: `%${request.input}%`,
                  },
                ],
              },
            ];
            param = {
              Limit: 100,
              Page: 1,
              OrderBy: { RcptRefNo: "asc" },
              WhereGroupList: whereGroupList,
              Exclude: ["Detail", "DimHList"],
            };
            getArReceiptSearchList(param).then(callback);
            break;
          default:
            break;
        }
      }, 200),
    [searchOption.module]
  );

  React.useEffect(() => {
    let active = true;
    setLoading(true);
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      setLoading(false);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        const { Data } = results;
        if (Data?.length > 0) {
          let newOptions = [];
          newOptions = [...Data];
          setOptions(newOptions);
        }
        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const RenderOptionByKeyName = (option) => {
    const matches = match(option[searchOption.keyName], inputValue);
    const parts = parse(option[searchOption.keyName], matches);
    return (
      <Grid container alignItems="center">
        <Grid item>
          <Avatar className={classes.icon}>{option.Prefix}</Avatar>
        </Grid>
        <Grid item xs>
          {!option.Prefix && (
            <Typography variant="body2" style={{ fontWeight: 400 }} display="inline">
              {option[searchOption.keyCode]} :{" "}
            </Typography>
          )}

          {parts.map((part, index) => (
            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
              {part.text}
            </span>
          ))}

          <Typography variant="body2" color="textSecondary">
            {option[searchOption.keyDesc]}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const RenderOptionByKeyCode = (option) => {
    const matches = match(option[searchOption.keyCode], inputValue);
    const parts = parse(option[searchOption.keyCode], matches);
    return (
      <Grid container alignItems="center">
        <Grid item xs>
          {parts.map((part, index) => (
            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
              {part.text}
            </span>
          ))}
          {searchOption.keyCode2 && <span> - {option[searchOption.keyCode2]}</span>}
          <Typography variant="body2" color="textSecondary">
            {option[searchOption.keyDesc]}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const LabelOption = (option) => {
    if (searchOption.keyName) {
      return typeof option === "string" ? option : option[searchOption.keyCode].concat(option[searchOption.keyName]);
    } else {
      return option[searchOption.keyCode].toString();
    }
  };

  const SelectedOption = (option) => {
    if (searchOption.module === "GL_Jv") {
      return typeof option === "string" ? option : option[searchOption.keyCode].concat(option[searchOption.keyName]);
    } else {
      return option[searchOption.keyCode].toString();
    }
  };

  return (
    <Autocomplete
      size="small"
      style={{ width: 260, marginTop: 7 }}
      getOptionLabel={LabelOption}
      getOptionSelected={SelectedOption}
      options={options}
      classes={{
        inputRoot: classes.inputRoot,
        option: classes.option,
      }}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        searchOption.update(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={searchOption.placeholder}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} style={{ marginRight: 24 }} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={searchOption.keyName ? RenderOptionByKeyName : RenderOptionByKeyCode}
    />
  );
};

export default FilterMenu;
