import React, { useContext, useState } from "react";
import { useTranslate } from "react-admin";
import html2canvas from "html2canvas";
import { GblContext } from "providers/formatter";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Menu,
  MenuItem,
  Icon,
  IconButton,
} from "@material-ui/core";
import { List, ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import { Chart } from "react-google-charts";
import { makeStyles } from "@material-ui/core/styles";
import ConfigDashboard from "./Dialog/ConfigDashboard";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import { showReportPDF, deleteWidgetById } from "services/callStoreProcedure";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  card: {
    minHeight: 52,
    display: "flex",
    flexDirection: "column",
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  cardHeader: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  content1: {
    paddingLeft: 16,
  },
  content2: {
    paddingLeft: 16,
  },
  boxColor: {
    height: 0,
    width: "1.2rem",
    paddingBottom: "1.2rem",
    marginRight: 10,
  },
  main: () => ({
    overflow: "inherit",
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      color: theme.palette.type === "dark" ? "inherit" : "#dc2440",
    },
    background: theme.palette.type === "dark" ? "inherit" : "white",
  }),
  circulLoading: {
    margin: 100,
    display: "flex",
    justifyContent: "center",
  },
}));

const StackedBarChart = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [param, setParam] = useState({
    Id: props.Id,
    //date: props.date,
    FromDate: new Date(props.FromDate),
    name: props.name,
    Seq: props.Seq,
    Width: props.Width,
    configName: props.configName,
    Config: props.Config,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openWidgetMenu = Boolean(anchorEl);
  const { ToMySqlDate, NumberFormat, CurrencyFormat } = useContext(GblContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { name, totalUnPaid, data1, data2, colors1, colors2, rawData, configName, dragHandle } = props;

  const updateChart = async (param) => {
    setLoading(true);
    let paramChart = {
      ...props,
      Id: param.Id,
      //date: param.date,
      name: param.name,
      Seq: parseInt(param.Seq),
      FromDate: param.FromDate,
      Width: param.Width,
      Config: param.Config,
    };
    //if (param.date !== "Invalid Date") {
    props.updateChart(paramChart);
    //}
  };

  const closeConfig = () => {
    setOpen(false);
  };

  const callReport = async (paramReportReceivable) => {
    let data = await showReportPDF(paramReportReceivable);
    var blob = new Blob([data], { type: "application/pdf" });
    const objUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objUrl;
    link.target = "_blank";
    link.click();
  };

  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        let chartSelect = chartWrapper.getChart().getSelection();
        if (chartSelect.length > 0) {
          const paramReportPayable = {
            Id: 310,
            Type: "Report",
            FileFormat: "pdf",
            Parameters: [
              { Name: "DateAt", Value: ToMySqlDate(param.FromDate) },
              { Name: "VendorCategoryFrom", Value: "" },
              { Name: "VendorCategoryTo", Value: "" },
              { Name: "VendorFrom", Value: "" },
              { Name: "VendorTo", Value: "" },
              { Name: "AccCodeFrom", Value: "" },
              { Name: "AccCodeTo", Value: "" },
              { Name: "GroupBy", Value: "VnCode" },
              { Name: "OrderBy", Value: "InvhInvDate" },
              { Name: "StatusBy", Value: "Active" },
              { Name: "AsPeriod", Value: "0" },
              { Name: "p1", Value: "30" },
              { Name: "p2", Value: "60" },
              { Name: "p3", Value: "90" },
            ],
          };

          const paramReportReceivable = {
            Id: 503,
            Type: "Report",
            FileFormat: "pdf",
            Parameters: [
              { Name: "DateType", Value: "I" },
              { Name: "DateAt", Value: ToMySqlDate(param.FromDate) },
              { Name: "ArNoFrom", Value: "" },
              { Name: "ArNoTo", Value: "" },
              { Name: "ArTypeFrom", Value: "" },
              { Name: "ArTypeTo", Value: "" },
              { Name: "AccCodeFrom", Value: "" },
              { Name: "AccCodeTo", Value: "" },
              { Name: "OrderBy", Value: "A" },
              { Name: "p1", Value: "30" },
              { Name: "p2", Value: "60" },
              { Name: "p3", Value: "90" },
            ],
          };

          let msg = "Do you want to open report?";
          SnackbarUtils.confirm(msg, async function () {
            if (configName === "Payable") {
              callReport(paramReportPayable);
            }
            if (configName === "Receivable") {
              callReport(paramReportReceivable);
            }
          });
        }
      },
    },
  ];

  function downloadBase64File(linkSource, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const SaveDashboardToImg = (id) => {
    const divBox = document.getElementById(id);
    html2canvas(divBox, {
      backgroundColor: "white",
      ignoreElements: (element) => {
        /* Remove element with id="MyElementIdHere" */
        if ("noPrint" === element.id) {
          return true;
        }
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      downloadBase64File(imgData, name);
    });
  };

  const paramReportPayable = {
    Id: 309,
    Type: "Report",
    FileFormat: "pdf",
    Parameters: [
      { Name: "DateAt", Value: ToMySqlDate(param.FromDate) },
      { Name: "VendorCategoryFrom", Value: "" },
      { Name: "VendorCategoryTo", Value: "" },
      { Name: "VendorFrom", Value: "" },
      { Name: "VendorTo", Value: "" },
      { Name: "AccCodeFrom", Value: "" },
      { Name: "AccCodeTo", Value: "" },
      { Name: "StatusBy", Value: "Active" },
      { Name: "GroupBy", Value: "VnCode" },
      { Name: "ViewBy", Value: "Base" },
      { Name: "AsPeriod", Value: "0" },
      { Name: "p1", Value: "30" },
      { Name: "p2", Value: "60" },
      { Name: "p3", Value: "90" },
    ],
  };

  const paramReportReceivable = {
    Id: 504,
    Type: "Report",
    FileFormat: "pdf",
    Parameters: [
      { Name: "DateType", Value: "I" },
      { Name: "DateAt", Value: ToMySqlDate(param.FromDate) },
      { Name: "ArNoFrom", Value: "" },
      { Name: "ArNoTo", Value: "" },
      { Name: "ArTypeFrom", Value: "" },
      { Name: "ArTypeTo", Value: "" },
      { Name: "AccCodeFrom", Value: "" },
      { Name: "AccCodeTo", Value: "" },
      { Name: "ViewBy", Value: "B" },
      { Name: "OrderBy", Value: "A" },
      { Name: "p1", Value: "30" },
      { Name: "p2", Value: "60" },
      { Name: "p3", Value: "90" },
    ],
  };

  return (
    <>
      {loading ? (
        <div className={classes.circulLoading}>
          <CircularProgress />
        </div>
      ) : (
        <div id={name}>
          <Card className={classes.card} elevation={6}>
            <CardHeader
              className={classes.cardHeader}
              action={
                <div id="noPrint">
                  {configName === "Payable" && (
                    <IconButton
                      aria-label="print report AP Aging Summary"
                      onClick={async () => {
                        let data = await showReportPDF(paramReportPayable);
                        var blob = new Blob([data], { type: "application/pdf" });
                        const objUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = objUrl;
                        link.target = "_blank";
                        link.click();
                      }}
                    >
                      <Icon>print</Icon>
                    </IconButton>
                  )}
                  {configName === "Receivable" && (
                    <IconButton
                      aria-label="print report AR Aging Summary"
                      onClick={async () => {
                        let data = await showReportPDF(paramReportReceivable);
                        var blob = new Blob([data], { type: "application/pdf" });
                        const objUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = objUrl;
                        link.target = "_blank";
                        link.click();
                      }}
                    >
                      <Icon>print</Icon>
                    </IconButton>
                  )}

                  <IconButton aria-label="settings" onClick={() => SaveDashboardToImg(name)}>
                    <Icon>save_alt_icon</Icon>
                  </IconButton>
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      param.FromDate = addDays(new Date(), -1);
                      updateChart(param);
                    }}
                  >
                    <Icon>refresh</Icon>
                  </IconButton>
                  <IconButton
                    id="basic-button"
                    aria-controls={openWidgetMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openWidgetMenu ? "true" : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <IconButton id="drag-button" style={{ cursor: "row-resize" }}>
                    {dragHandle}
                  </IconButton>
                  {open && (
                    <ConfigDashboard
                      open={open}
                      close={closeConfig}
                      updateChart={updateChart}
                      param={param}
                      updateParam={setParam}
                    />
                  )}
                </div>
              }
              title={
                <Typography variant="body1">
                  <b>{name}</b>
                </Typography>
              }
              subheader={
                <Typography variant="subtitle2" style={{ marginTop: 6 }}>
                  {name === "Payable"
                    ? `${translate("ra.field.Total Unpaid")}  ${CurrencyFormat(totalUnPaid ?? 0, "THB")}`
                    : `${translate("ra.field.Total Outstanding")}  ${CurrencyFormat(totalUnPaid ?? 0, "THB")}`}
                </Typography>
              }
            />
            <Divider />
            <Menu id="widget-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem
                key={1}
                onClick={() => {
                  setOpen(true);
                  setAnchorEl(null);
                }}
              >
                Edit Widget
              </MenuItem>
              <MenuItem
                key={2}
                onClick={() => {
                  let msg = "Are you sure delete this widget?";
                  SnackbarUtils.loadingConfirm(
                    msg,
                    async function () {
                      await deleteWidgetById(props.Id);
                      return props.Id;
                    },
                    async function (id) {
                      props.removeChart(id);
                    }
                  );
                  setAnchorEl(null);
                }}
              >
                Delete Widget
              </MenuItem>
            </Menu>
            <CardContent className={classes.cardContent} style={{ padding: 0 }}>
              {data1 ? (
                <Chart
                  width="100%"
                  height={"100px"}
                  chartType="BarChart"
                  loader={<div>Loading Chart</div>}
                  data={data1}
                  options={{
                    chartArea: { width: "90%", height: 40, top: 10 },
                    isStacked: true,
                    colors: colors1 ?? [],
                    hAxis: {
                      minValue: 0,
                      maxValue: totalUnPaid,
                      textPosition: "bottom",
                      format: "short",
                      baselineColor: { color: "white" },
                      //gridlines: { color: "white" },
                    },
                    vAxis: {
                      textPosition: "none",
                      //gridlines: { color: "white" },
                    },
                    legend: { position: "none" },
                  }}
                  chartEvents={chartEvents}
                />
              ) : (
                ""
              )}
              <List dense className={classes.root}>
                <ListItem>
                  <div
                    className={classes.boxColor}
                    style={{
                      backgroundColor: colors1 ? colors1[0] : [],
                    }}
                  ></div>
                  <ListItemText primary={translate("ra.field.Undue")} />
                  <ListItemSecondaryAction>
                    <Typography variant="body2">{NumberFormat(rawData?.Undue ?? 0)}</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <div
                    className={classes.boxColor}
                    style={{
                      backgroundColor: colors1 ? colors1[1] : [],
                    }}
                  ></div>
                  <ListItemText primary={translate("ra.field.Overdue")} />
                  <ListItemSecondaryAction>
                    <Typography variant="body2">{NumberFormat(rawData?.Overdue ?? 0)}</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary={<b>{translate("ra.field.Summary")}</b>} />
                  <ListItemSecondaryAction>
                    <Typography variant="body2">
                      <b>{NumberFormat(rawData && rawData?.Undue + rawData?.Overdue)}</b>
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
              <Divider />
              {data2 ? (
                <Chart
                  width="100%"
                  height={"100px"}
                  chartType="BarChart"
                  loader={<div>Loading Chart</div>}
                  data={data2}
                  options={{
                    chartArea: { width: "90%", height: 40, top: 10 },
                    isStacked: true,
                    colors: colors2 ?? [],
                    hAxis: {
                      minValue: 0,
                      maxValue: totalUnPaid,
                      textPosition: "bottom",
                      format: "short",
                      baselineColor: { color: "white" },
                      //gridlines: { color: "white" },
                    },
                    vAxis: {
                      textPosition: "none",
                      //gridlines: { color: "white" },
                    },
                    legend: { position: "none" },
                  }}
                  chartEvents={chartEvents}
                />
              ) : (
                ""
              )}
            </CardContent>
            <List dense className={classes.root} style={{ marginTop: 10 }}>
              <ListItem>
                <div
                  className={classes.boxColor}
                  style={{
                    backgroundColor: colors2 ? colors2[0] : [],
                  }}
                ></div>
                <ListItemText primary={translate("ra.field.Current")} />
                <ListItemSecondaryAction>
                  <Typography variant="body2">{NumberFormat(rawData?.Current)}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ListItem>
                <div
                  className={classes.boxColor}
                  style={{
                    backgroundColor: colors2 ? colors2[1] : [],
                  }}
                ></div>
                <ListItemText primary={"<= 30"} />
                <ListItemSecondaryAction>
                  <Typography variant="body2">{NumberFormat(rawData?.Age30)}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ListItem>
                <div
                  className={classes.boxColor}
                  style={{
                    backgroundColor: colors2 ? colors2[2] : [],
                  }}
                ></div>
                <ListItemText primary={"<= 60"} />
                <ListItemSecondaryAction>
                  <Typography variant="body2">{NumberFormat(rawData?.Age60)}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ListItem>
                <div
                  className={classes.boxColor}
                  style={{
                    backgroundColor: colors2 ? colors2[3] : [],
                  }}
                ></div>
                <ListItemText primary={"<= 90"} />

                <ListItemSecondaryAction>
                  <Typography variant="body2">{NumberFormat(rawData?.Age90)}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ListItem>
                <div
                  className={classes.boxColor}
                  style={{
                    backgroundColor: colors2 ? colors2[4] : [],
                  }}
                ></div>
                <ListItemText primary={"> 90"} />
                <ListItemSecondaryAction>
                  <Typography variant="body2">{NumberFormat(rawData?.Age91)}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary={<b>{translate("ra.field.Summary")}</b>} />
                <ListItemSecondaryAction>
                  <Typography variant="body2">
                    <b>
                      {NumberFormat(
                        rawData &&
                          (rawData?.Current ?? 0) + rawData?.Age30 + rawData?.Age60 + rawData?.Age90 + rawData?.Age91
                      )}
                    </b>
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Card>
        </div>
      )}
    </>
  );
};

export default StackedBarChart;
