/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Loading, useLocale, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
//import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useForm } from "react-hook-form";
import { getAccountCodeList, getDepartmentList } from "services/setting";
import { getArProfileList } from "services/accountReceivable";
import {
  uploadFileInfPostAR,
  //getSettingInfHotelogixAr,
  //uploadFileHotelogixAr,
  //getSettingInfHotelogixArB,
  //uploadFileHotelogixArB,
  postInfPostAR,
} from "services/interface";
import { Typography, Box, Button, CircularProgress } from "@material-ui/core";
import { MuiAutosuggest, SelectInForm, NumberFormatInForm, DescInForm } from "components/Form";
// import DialogMappingHotelogix from "../Dialog/DialogMappingHotelogix";
import DialogMapping from "../Dialog/DialogMapping";
import DialogPostingResult from "../DialogPostingResult";
import { VatTypeOptions } from "utils/options";
import fileReader from "utils/fileReader";
import SnackbarUtils from "utils/SnackbarUtils";
import ButtonUpload from "components/ButtonUpload";
import DatePickerFormat from "components/DatePickerFormat";
import gbl from "utils/formatter";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CardItem(props) {
  const translate = useTranslate();
  const classes = useStyles();
  const locale = useLocale();
  //const { ToMySqlDate } = useContext(GblContext);
  const [loading, setLoading] = useState(false);
  const [openMapping, setOpenMapping] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const [lookupList, setLookupList] = useState({
    accountCodeList: [],
    departmentList: [],
    arProfileList: [],
  });
  const [oldAccList, setOldAccList] = useState([]);
  const [idForPost, setIdForPost] = useState();
  const [filterDate, setFilterDate] = useState({
    from: new Date(),
    to: new Date(),
  });
  const [configuration, setConfiguration] = useStateWithCallbackLazy();
  const [postType, setPostType] = useState();
  const [fileUpload, setFileUpload] = useState();
  const [postData, setPostData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [checkDuplicate, setCheckDuplicate] = useState([]);

  const methods = useForm({ defaultValues: configuration });

  const { handleSubmit, reset } = methods;

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const fetchAccLookup = async () => {
    const { Data } = await getAccountCodeList("Gl");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
    }));
    setOldAccList(Data);
  };
  const fetchDeptLookup = async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  };
  const fetchArNoLookup = async () => {
    setLoading(true);
    const { Data } = await getArProfileList();
    setLookupList((state) => ({
      ...state,
      arProfileList: Data,
    }));
    setLoading(false);
  };

  const fetchSetting = useCallback(async () => {
    const r = props.setting;
    if (r) {
      setPostType(r.PostType);
      setConfiguration(r.Configuration ?? false);
      reset(r.Configuration);
    }
    // if (props.id === "Hotelogix.PMS") {
    //   const config = await getSettingInfHotelogixAr();
    //   setConfig(config);
    //   reset(config);
    // }
    // if (props.id === "Hotelogix2.PMS") {
    //   const config = await getSettingInfHotelogixArB();
    //   setConfig(config);
    //   reset(config);
    // }
  }, []);

  useEffect(() => {
    fetchAccLookup();
    fetchDeptLookup();
    fetchArNoLookup();
  }, []);

  useEffect(() => {
    fetchSetting();
  }, [fetchSetting]);

  const CheckResultShowMessage = async ({ Code, InternalMessage, UserMessage }) => {
    if (Code === null) {
      SnackbarUtils.warning("File Not Found");
      setLoading(false);
      return false;
    }
    if (Code === 0 || Code === -204 || Code === -400) {
      SnackbarUtils.warning(UserMessage);
      setLoading(false);
      return false;
    }
    if (Code === -1) {
      SnackbarUtils.warning(InternalMessage);
      setLoading(false);
      return false;
    }
    if (Code === -500) {
      SnackbarUtils.warning(UserMessage, function () {
        setOpenMapping(true);
      });
      setLoading(false);
      return false;
    }
    if (Code === -2146233079) {
      SnackbarUtils.error(UserMessage);
      setLoading(false);
      return false;
    }

    setLoading(false);
    return true;
  };

  const onSubmit = (values) => {
    setLoading(true);
    //Adjust parameter before save
    setConfiguration(
      (state) => ({
        ...state,
        ...values,
      }),
      async (nextState) => {
        // if (postType !== "Json") {
        //   if (!fileUpload) {
        //     SnackbarUtils.warning("File Not Found");
        //     return;
        //   }
        //   let paramUploadFile = {
        //     FileData: nextState.DataFilePath,
        //     UserModified: localStorage.getItem("UserName"),
        //     LastModified: new Date(),
        //   };
        //   var r;
        //   if (props.id === "Hotelogix.PMS") {
        //     r = await uploadFileHotelogixAr(paramUploadFile);
        //   }
        //   if (props.id === "Hotelogix2.PMS") {
        //     r = await uploadFileHotelogixArB(paramUploadFile);
        //   }
        //   CheckResultShowMessage(r);
        //   if (Array.isArray(r)) {
        //     Save(r);
        //   }
        // } else {
        let paramPostToAR = {
          id: idForPost,
          Code: props.id,
          DateFrom: filterDate.from,
          DateTo: filterDate.to,
          UserModified: localStorage.getItem("UserName"),
        };

        let r = await postInfPostAR(paramPostToAR, false);
        if (r.Code === -200) {
          let msg = "Data already post. Do you want to repost ?";
          SnackbarUtils.loadingConfirm(
            msg,
            async function () {
              const rr = await postInfPostAR(paramPostToAR, true);
              let isPass = await CheckResultShowMessage(rr);
              if (isPass) {
                Save(rr);
              }
            },
            setLoading(false)
          );
        } else {
          let isPass = await CheckResultShowMessage(r);
          if (isPass) {
            Save(r);
          }
        }
      }
    );
  };

  const uploadFile = async (e) => {
    if (e.target.files.length >= 1) {
      let msg = fileReader.CheckLimitImgSize(e.target.files);
      if (msg) {
        document.getElementById("fileCsv").value = "";
        SnackbarUtils.error(translate(msg, { size: "5" }));
        return;
      }
      if (e.target.files.length > 1) {
        let arrName = Object.values(e.target.files)
          .map((i) => i.name)
          .join(", ");
        setFileUpload(arrName);
      } else {
        setFileUpload(e.target.files[0].name);
      }
      var fileType = "";
      let files = Array.from(e.target.files);
      const filePathsPromises = [];
      files.forEach((file) => {
        if (file.type === "application/json") {
          filePathsPromises.push(fileReader.fileToJSON(file));
          fileType = file.type;
        } else {
          filePathsPromises.push(fileReader.ToBase64(file));
          fileType = file.type;
        }
      });
      var param = [];
      var txtArray = await Promise.all(filePathsPromises);
      if (fileType === "application/json") {
        txtArray.forEach((item, idx) => {
          param.push({
            FileName: files[idx].name,
            FileBase64: JSON.stringify(item),
          });
        });
      } else {
        txtArray.forEach((item, idx) => {
          param.push({
            FileName: files[idx].name,
            FileBase64: gbl.UnicodeToBase64(item),
          });
        });
        const txt = await Promise.all(filePathsPromises);
        let base64 = gbl.UnicodeToBase64(txt.join(""));
        setConfiguration((state) => ({
          ...state,
          DataFilePath: base64,
        }));
      }
      apiUploadFile(props.id, "AR", param);
    }
  };

  const apiUploadFile = async (id, type, file) => {
    if (file.length > 0) {
      let r = await uploadFileInfPostAR(id, type, file);
      if (r) {
        setIdForPost(r.id);
        if (r.Code) {
          SnackbarUtils.error(r.UserMessage);
          return;
        }
        setFilterDate({
          from: new Date(r.DateFrom),
          to: new Date(r.DateTo),
        });
        // SnackbarUtils.success(
        //   `Upload data from ${ToMySqlDate(new Date(r.DateFrom))} to ${ToMySqlDate(new Date(r.DateTo))}`
        // );
      }
    } else {
      SnackbarUtils.warning("File Not Found");
    }
  };

  const Save = async (values) => {
    setCheckDuplicate(values.CheckDuplicateFolio);
    //let item = values.InterfaceData.find((item) => item.IsPost === true);
    // let item = values.InterfaceData;
    // if (item.length > 0) {
    // let msg = "The data already exists. Would you like to repost?";
    // SnackbarUtils.confirm(msg, function () {
    //   setPostData(values.InterfaceData);
    //   setColumnData(values.InterfaceColumn);
    //   setOpenResult(true);
    // });
    // } else {
    //   SnackbarUtils.info("No Data Found");
    // }
    setPostData(values.InterfaceData);
    setColumnData(values.InterfaceColumn);
    setOpenResult(true);
  };

  return (
    <div>
      <form onKeyDown={disableFormEnter}>
        {postType === "Json" ? (
          <Box p={1} align="center">
            <DatePickerFormat
              label="From"
              value={filterDate.from}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  from: e,
                  to: e > filterDate.to ? e : filterDate.to,
                }));
              }}
              style={{ width: 160, margin: "0 10px" }}
            />
            <DatePickerFormat
              label="To"
              value={filterDate.to}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  to: e,
                }));
              }}
              minDate={filterDate.from}
              minDateMessage={"Date must be more than from date"}
              style={{ width: 160, margin: "0 10px" }}
            />
          </Box>
        ) : (
          <Box p={1} display="flex">
            <Box p={1} flexGrow={1}>
              <ButtonUpload
                id="fileCsv"
                name="fileCsv"
                style={{ display: "none" }}
                type="file"
                onChange={uploadFile}
                accept=".csv,.txt,application/JSON,(*.*)"
                multiple
                fileUpload={fileUpload}
                cancelUpload={() => {
                  setFileUpload();
                  document.getElementById("fileCsv").value = "";
                }}
              />
            </Box>
            <Box p={1}></Box>
          </Box>
        )}
        {!loading ? (
          <>
            <Box p={1} align="left">
              <Box px={1} align="left">
                <Typography variant="body2">
                  <b>Select Balance Account</b>
                </Typography>
              </Box>
              <Box p={1} display="flex">
                <MuiAutosuggest
                  label={translate("ra.field.Department")}
                  name="DeptCode"
                  optKey="DeptCode"
                  optDesc="Description"
                  options={lookupList["departmentList"]}
                  updateOtherField={[{ key: "DeptDesc", optKey: "Description" }]}
                  useFncUpdate={true}
                  fncUpdate={(value, methods) => {
                    let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                    if (daccList?.length > 0) {
                      setLookupList((state) => ({
                        ...state,
                        accountCodeList: daccList,
                      }));
                      //remove acc if not in defaultaccount
                      let drAcc = methods.getValues("DrAccCode");
                      let crAcc = methods.getValues("CrAccCode");
                      if (drAcc !== "" && !daccList.find((i) => i.AccCode === drAcc)) {
                        methods.setValue("DrAccCode", "");
                        methods.setValue("DrAccDesc", "");
                      }
                      if (crAcc !== "" && !daccList.find((i) => i.AccCode === crAcc)) {
                        methods.setValue("CrAccCode", "");
                        methods.setValue("CrAccDesc", "");
                      }
                    } else {
                      setLookupList((state) => ({
                        ...state,
                        accountCodeList: oldAccList,
                      }));
                    }
                  }}
                  methods={methods}
                  style={{ width: 200 }}
                />
                <DescInForm
                  style={{ paddingLeft: 20 }}
                  name="DeptDesc"
                  methods={methods}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box p={1} display="flex">
                <MuiAutosuggest
                  label={translate("ra.field.Dr Acc. Code")}
                  name="DrAccCode"
                  optKey="AccCode"
                  optDesc={locale === "en-US" ? "Description" : "Description2"}
                  options={lookupList["accountCodeList"]}
                  updateOtherField={[
                    {
                      key: "DrAccDesc",
                      optKey: locale === "en-US" ? "Description" : "Description2",
                    },
                  ]}
                  methods={methods}
                  style={{ width: 200 }}
                />
                <DescInForm
                  style={{ paddingLeft: 20 }}
                  name="DrAccDesc"
                  methods={methods}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box p={1} display="flex">
                <MuiAutosuggest
                  label={translate("ra.field.Cr Acc. Code")}
                  name="CrAccCode"
                  optKey="AccCode"
                  optDesc={locale === "en-US" ? "Description" : "Description2"}
                  options={lookupList["accountCodeList"]}
                  updateOtherField={[
                    {
                      key: "CrAccDesc",
                      optKey: locale === "en-US" ? "Description" : "Description2",
                    },
                  ]}
                  methods={methods}
                  style={{ width: 200 }}
                />
                <DescInForm
                  style={{ paddingLeft: 20 }}
                  name="CrAccDesc"
                  methods={methods}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box p={1} display="flex">
                <MuiAutosuggest
                  label={translate("ra.field.Tax")}
                  name="TaxAccCode"
                  optKey="AccCode"
                  optDesc={locale === "en-US" ? "Description" : "Description2"}
                  options={lookupList["accountCodeList"]}
                  updateOtherField={[
                    {
                      key: "TaxAccDesc",
                      optKey: locale === "en-US" ? "Description" : "Description2",
                    },
                  ]}
                  methods={methods}
                  style={{ width: 200 }}
                />
                <DescInForm
                  style={{ paddingLeft: 20 }}
                  name="TaxAccDesc"
                  methods={methods}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box p={1} display="flex" style={{ width: 160 }}>
                <SelectInForm
                  label={translate("ra.field.Tax Type")}
                  name="TaxType"
                  methods={methods}
                  options={VatTypeOptions}
                  style={{ margin: 0 }}
                />
              </Box>
              <Box p={1} display="flex" style={{ width: 160 }}>
                <NumberFormatInForm label={translate("ra.field.Tax Rate")} methods={methods} name="TaxRate" />
              </Box>
            </Box>
            <Box py={1} align="center">
              <Button variant="outlined" onClick={() => setOpenMapping(true)}>
                Mapping code
              </Button>
            </Box>
            <Box py={1} align="center">
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  {translate("ra.actionMenu.POST")}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </Box>
          </>
        ) : (
          <Loading />
        )}
      </form>

      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(methods.watch(), 0, 2) : ""}</pre>

      {openMapping && (
        <DialogMapping
          id={props.id}
          open={openMapping}
          onClose={() => setOpenMapping(false)}
          lookupList={{
            arProfileList: lookupList["arProfileList"],
          }}
          postType={postType}
          docType={"AR"}
        />
      )}
      {openResult && (
        <DialogPostingResult
          id={props.id}
          title={props.title}
          open={openResult}
          onClose={() => setOpenResult(false)}
          data={postData}
          columnData={columnData}
          checkDuplicate={checkDuplicate}
        />
      )}
    </div>
  );
}
