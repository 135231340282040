import React, { useContext, useState, useEffect } from "react";
import { useTranslate, useLocale } from "react-admin";
import AccessDenied from "layout/AccessDenied";
import { GblContext } from "providers/formatter";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Divider } from "@material-ui/core";
import { SelectInForm, SwitchInForm, MuiAutosuggest } from "components/Form";
import { getSettingAp, updateSettingAp, getUnitList, getAccountCodeList, getDepartmentList } from "services/setting";
import { AgingPeriodOptions, PaymentPostingDateOptions, InvoiceTaxStatusOptions } from "utils/options";
import DialogVendorCate from "./Dialog/VendorCategory";
import DialogWHTType from "./Dialog/WHTType";
import DialogWHTService from "./Dialog/WHTService";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  tabPanel: { width: "100%", margin: "0 20px" },
  button: { textTransform: "none" },
}));

const AccountPayable = (props) => {
  const { permissions, value, index } = props;
  const translate = useTranslate();
  const locale = useLocale();
  const checkPermission = permissions?.find(
    (i) => i.Name === "AP.VendorCate" || i.Name === "AP.Config.Wht" || i.Name === "AP.Config.WhtType"
  )?.View;

  const classes = useStyles();
  const { UpdateSettingAll } = useContext(GblContext);
  const [lookupList, setLookupList] = useState({
    unitList: [],
    accountCodeList: [],
    departmentList: [],
  });
  const [setting, setSetting] = useState();
  const [mode, setMode] = useState("view");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [openVendorCate, setOpenVendorCate] = useState(false);
  const [openWHTt, setOpenWHTt] = useState(false);
  const [openWHTs, setOpenWHTs] = useState(false);
  const [oldAccList, setOldAccList] = useState([]);
  const methods = useForm({ defaultValues: setting });

  const { handleSubmit, reset } = methods;

  const fetchUnitLookup = async () => {
    const { Data } = await getUnitList();
    setLookupList((state) => ({
      ...state,
      unitList: Data,
    }));
  };

  const fetchAccLookup = async () => {
    const { Data } = await getAccountCodeList("Gl");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
    }));
    setOldAccList(Data);
  };

  const fetchDeptLookup = async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  };

  const fetchItem = async () => {
    const setting = await getSettingAp();
    if (setting) {
      setSetting(setting);
      reset(setting);
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    if (value === index) {
      fetchItem();
      fetchUnitLookup();
      fetchAccLookup();
      fetchDeptLookup();
    }
  }, [value, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async (values) => {
    setBtnLoading(true);
    values.ShowWithholdingTaxAt = "Payment";
    const settingAll = JSON.parse(localStorage.getItem("SettingAll"));
    settingAll.SettingAp = values;
    const { Code, UserMessage } = await updateSettingAp(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
      localStorage.setItem("SettingAll", JSON.stringify(settingAll));
      UpdateSettingAll(settingAll);
      setMode("view");
      fetchItem();
    } else {
      SnackbarUtils.warning(UserMessage);
      setBtnLoading(false);
    }
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
    },
  ];

  const CancelFnc = () => {
    reset();
    setMode("view");
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {checkPermission && value === index ? (
        <>
          <ActionMenu menuControl={menuControlProp} justifyContent="flex-start" />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ap.Aging Period")}</Typography>
                {/* <Typography variant="caption">The start date of aging period calculation</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                {methods.watch("AgingPeriodBy") && (
                  <SelectInForm
                    label={translate("ra.ap.Aging Period")}
                    name="AgingPeriodBy"
                    options={AgingPeriodOptions}
                    methods={methods}
                    disabled
                  />
                )}
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ap.Payment Posting")}</Typography>
                {/* <Typography variant="caption">
                  Date of payment will be effect with the AP aging and posting to GL
                </Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                {methods.watch("PaymentPostingBy") && (
                  <SelectInForm
                    label={translate("ra.field.Date")}
                    name="PaymentPostingBy"
                    options={PaymentPostingDateOptions}
                    methods={methods}
                    disabled
                  />
                )}
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ap.Default Tax Invoice Status")}</Typography>
                {/* <Typography variant="caption">Set default tax invoice status when create invoice</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                {methods.watch("DefaultTaxInvoiceStatus") && (
                  <SelectInForm
                    label={translate("ra.field.Status")}
                    name="DefaultTaxInvoiceStatus"
                    options={InvoiceTaxStatusOptions}
                    methods={methods}
                    disabled={mode === "view"}
                  />
                )}
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ap.Default Unit")}</Typography>
                {/* <Typography variant="caption">Set default unit</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Unit")}
                  name="DefaultUnit"
                  optKey="UnitCode"
                  optDesc="Description"
                  options={lookupList["unitList"]}
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.field.Setup Lookup")}</Typography>
                {/* <Typography variant="caption">add edit delete lookup</Typography> */}
              </Box>
              <Box px={1}>
                <Button variant="outlined" onClick={() => setOpenVendorCate(true)}>
                  {translate("ra.field.Vendor Category")}
                </Button>
              </Box>
              <Box px={1}>
                <Button variant="outlined" onClick={() => setOpenWHTt(true)}>
                  {translate("ra.field.WHT. Form")}
                </Button>
              </Box>
              <Box px={1}>
                <Button variant="outlined" onClick={() => setOpenWHTs(true)}>
                  {translate("ra.field.WHT. Service Type")}
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{"Auto Running Vendor Code"}</Typography>
                {/* <Typography variant="caption">Allow duplicate Cheque No.</Typography> */}
              </Box>
              <Box>
                <SwitchInForm
                  name="AutoRunVendorCode"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ap.Allow duplicate Cheque No.")}</Typography>
                {/* <Typography variant="caption">Allow duplicate Cheque No.</Typography> */}
              </Box>
              <Box>
                <SwitchInForm
                  name="AllowDuplicateChequeNo"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ap.Allow to edit posting invoice")}</Typography>
                {/* <Typography variant="caption">Allow to edit posting invoice</Typography> */}
              </Box>
              <Box>
                <SwitchInForm
                  name="AllowEditPostingInvoice"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ap.Allow to delete posting invoice")}</Typography>
                {/* <Typography variant="caption">Allow to delete posting invoice</Typography> */}
              </Box>
              <Box>
                <SwitchInForm
                  name="AllowDeletePostingInvoice"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ap.Allow re-print form cheque")}</Typography>
                {/* <Typography variant="caption">Allow re-print form cheque</Typography> */}
              </Box>
              <Box>
                <SwitchInForm name="AllowRePrintCheque" methods={methods} disabled={mode === "view"} defaultChecked />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">
                  {translate("ra.ap.Allow create duplicate TaxID on vendor profile")}
                </Typography>
                {/* <Typography variant="caption">Allow create duplicate TaxID on vendor profile</Typography> */}
              </Box>
              <Box>
                <SwitchInForm
                  name="AllowVendorDuplicateTaxId"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ap.Enable Thai Tax")}</Typography>
                {/* <Typography variant="caption">Enable Thai Tax</Typography> */}
              </Box>
              <Box>
                <SwitchInForm
                  name="EnableThaiTaxReconcile"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">
                  <b>{translate("ra.ap.Auto Posting Vat Reconcile JV")}</b>
                </Typography>
                {/* <Typography variant="caption">Auto Posting Vat Reconcile JV</Typography> */}
              </Box>
              <Box>
                <SwitchInForm name="AutoPostingToGl" methods={methods} disabled={mode === "view"} />
              </Box>
            </Box>
            {methods.watch("AutoPostingToGl") && (
              <>
                <Box p={1} display="flex" alignItems="center" justifyContent="space-between" style={{ marginLeft: 20 }}>
                  <Box flexGrow={1}>
                    <Typography variant="subtitle1"> - {translate("ra.field.Department")}</Typography>
                    {/* <Typography variant="caption">Set default department when vatreconcile post to gl</Typography> */}
                  </Box>
                  <Box style={{ width: 160 }}>
                    <MuiAutosuggest
                      label={translate("ra.field.Department")}
                      name="PostingDepartment"
                      optKey="DeptCode"
                      optDesc="Description"
                      options={lookupList["departmentList"]}
                      updateOtherField={[{ key: "PostingDepartmentDesc", optKey: "Description" }]}
                      useFncUpdate={true}
                      fncUpdate={(value, methods) => {
                        const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                        if (daccList?.length > 0) {
                          setLookupList((state) => ({
                            ...state,
                            accountCodeList: daccList,
                          }));
                          //remove acc if not in defaultaccount
                          const acc = methods.getValues("PostingAccount");
                          if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                            methods.setValue("PostingAccount", "");
                          }
                        } else {
                          setLookupList((state) => ({
                            ...state,
                            accountCodeList: oldAccList,
                          }));
                        }
                      }}
                      methods={methods}
                      disabled={mode === "view"}
                    />
                  </Box>
                  {/* <Box px={1}>
                    <DescInForm
                      style={{ marginTop: 8 }}
                      name="PostingDepartmentDesc"
                      methods={methods}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box> */}
                </Box>
                <Box p={1} display="flex" alignItems="center" justifyContent="space-between" style={{ marginLeft: 20 }}>
                  <Box flexGrow={1}>
                    <Typography variant="subtitle1"> - {translate("ra.field.Account Code")}</Typography>
                    {/* <Typography variant="caption">Set default accountcode when vatreconcile post to gl</Typography> */}
                  </Box>
                  <Box style={{ width: 160 }}>
                    <MuiAutosuggest
                      label={translate("ra.field.Account Code")}
                      name="PostingAccount"
                      optKey="AccCode"
                      optDesc={locale === "en-US" ? "Description" : "Description2"}
                      options={lookupList["accountCodeList"]}
                      updateOtherField={[
                        {
                          key: "PostingAccountDesc",
                          optKey: locale === "en-US" ? "Description" : "Description2",
                        },
                      ]}
                      methods={methods}
                      disabled={mode === "view"}
                    />
                  </Box>
                  {/* <Box px={1}>
                    <DescInForm
                      style={{ marginTop: 8 }}
                      name="PostingAccountDesc"
                      methods={methods}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box> */}
                </Box>
              </>
            )}
            {mode !== "view" && <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />}
          </form>
          <DialogVendorCate
            title={translate("ra.field.Vendor Category")}
            open={openVendorCate}
            onClose={() => setOpenVendorCate(false)}
          />
          <DialogWHTType title={translate("ra.field.WHT. Form")} open={openWHTt} onClose={() => setOpenWHTt(false)} />
          <DialogWHTService
            title={translate("ra.field.WHT. Service Type")}
            open={openWHTs}
            onClose={() => setOpenWHTs(false)}
          />
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(methods.watch(), 0, 2) : ""}</pre>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default AccountPayable;
