export default {
  CurrId: 0,
  CurrCode: "",
  CurrDate: new Date(),
  BuyingRate: 1,
  SellingRate: 1,
  AverageRate: 1,
  CreateBy: localStorage.getItem("UserName"),
  CreateDate: null,
  UserModified: localStorage.getItem("UserName"),
  LastModified: new Date(),
};
