/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import clsx from "clsx";
import { Loading, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { TableHead, TableFooter, TableRow, TableCell } from "@material-ui/core";
import { TableHead as MuiTableHead } from "mui-datatables";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
import NavRight from "components/NavRightSide";
import PopupTable from "components/PopupTable";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { format } from "date-fns";
import { getJvFrDetail, updateJvFrDetail } from "services/generalLedger";
import { getActiveDimListByModuleName } from "services/dimension";
import SnackbarUtils from "utils/SnackbarUtils";

const Edit = (props) => {
	const translate = useTranslate();
	const classes = props.useStyles();
	const { basePath, id, formFields, formFieldsRecurring, formFieldsDetail } =
		props;
	const redirect = useRedirect();
	const { settingAll, DateToString, NumberFormat, ToNumber, FixedSumValue } =
		useContext(GblContext);
	const { SettingSystem } = settingAll;
	const [data, setData] = useStateWithCallbackLazy();
	const [loading, setLoading] = useState(true);
	const [showAdd, setShowAdd] = useState(false);
	const [editIndex, setEditIndex] = useState("");
	const [openDim, setOpenDim] = useState(false);
	const [dataDim, setDataDim] = useState();

	const [initNewRow, setInitNewRow] = useState({
		index: -1,
		JvhSeq: -1,
		JvdSeq: -1,
		DeptCode: "",
		AccCode: "",
		Description: "",
		CurCode: SettingSystem.DefaultCurrencyCode,
		CurRate: SettingSystem.DefaultCurrencyRate,
		CrAmount: 0,
		CrBase: 0,
		DrAmount: 0,
		DrBase: 0,
		IsOverWrite: true,
		DimList: {
			Dim: [],
		},
	});

	const menuControlProp = [
		{ name: "Back", fnc: () => redirect("list", basePath) },
		{ name: "Add", disabled: true },
		{ name: "Edit", disabled: true },
		{ name: "Delete", disabled: true },
		{ name: "Print", disabled: true },
	];

	const methods = useForm({ defaultValues: data });

	const { handleSubmit, getValues, reset } = methods;

	const fetchJvFrById = useCallback(async () => {
		setLoading(true);
		const response = await getJvFrDetail(id);
		if (response) {
			if (response.Type === "Template") {
				response.FJvhFrom = format(new Date(), "MM/yyyy");
				response.FJvhTo = format(new Date(), "MM/yyyy");
				response.FJvhFreq = 1;
			} else {
				response.FJvhFromPr = `${response.FJvhFromPr}`;
				response.FJvhFrom = `${response.FJvhFromPr}/${response.FJvhFromYr}`;
				response.FJvhToPr = `${response.FJvhToPr}`;
				response.FJvhTo = `${response.FJvhToPr}/${response.FJvhToYr}`;
			}

			setData(response);
			reset(response);
		}
		setTimeout(() => {
			setLoading(false);
		}, 500);
	}, [id, reset]);

	const fetchDimListByModule = useCallback(async () => {
		const { Data } = await getActiveDimListByModuleName(10, 1, "GL-D");
		setInitNewRow((state) => ({
			...state,
			DimList: {
				Dim: Data,
			},
		}));
	}, []);

	useEffect(() => {
		fetchJvFrById();
		fetchDimListByModule();
	}, [fetchJvFrById, fetchDimListByModule]);

	function useForceUpdate() {
		const [value, setState] = useState(true);
		return () => setState(!value);
	}

	const forceUpdate = useForceUpdate();

	const disableFormEnter = (e) => {
		if (e.key === "Enter" && e.target.localName !== "textarea")
			e.preventDefault();
	};

	const onSubmit = async () => {
		const values = getValues();
		setLoading(true);
		await new Promise((resolve) => setTimeout(resolve, 500));

		if (values.Type === "Recurring") {
			const splitFromMonthYear = values.FJvhFrom.split("/");
			values.FJvhFromPr = splitFromMonthYear[0];
			values.FJvhFromYr = splitFromMonthYear[1];
			const splitToMonthYear = values.FJvhTo.split("/");
			values.FJvhToPr = splitToMonthYear[0];
			values.FJvhToYr = splitToMonthYear[1];
		} else {
			values.FJvhFreq = 0;
		}
		setData(
			(state) => ({
				...state,
				...values,
			}),
			(nextState) => Save(nextState),
		);
	};

	const AddNewRow = () => {
		setEditIndex("");
		setShowAdd(true);
	};

	const UpdateRow = (value) => {
		setEditIndex(value);
		setShowAdd(true);
	};

	const SaveFromPopup = (arr, row) => {
		const index = arr.Detail.findIndex((el) => el.index === editIndex);
		row.DrBase = NumberFormat(row.DrBase);
		row.CrBase = NumberFormat(row.CrBase);

		if (editIndex !== "") {
			//update
			arr.Detail[index] = row;
			setShowAdd(false);
		} else {
			//create
			row.index = arr.Detail.length;
			arr.Detail = [...arr.Detail, row];
			setShowAdd(false);
		}
	};

	const CancelFromPopup = () => {
		setShowAdd(false);
	};

	const columns = [
		{
			name: "index",
			label: " ",
			options: {
				filter: false,
				viewColumns: false,
				customBodyRender: (value) => {
					return (
						<>
							<EditIcon
								fontSize="small"
								color="primary"
								style={{ cursor: "pointer", marginLeft: 10 }}
								onClick={() => UpdateRow(value)}
							/>
						</>
					);
				},
			},
		},
		{
			name: "DeptCode",
			label: "Dept.",
		},
		{
			name: "AccCode",
			label: "Account #",
		},
		{
			name: "AccDesc",
			label: "Account Name",
		},
		{
			name: "Description",
			label: "Comment",
			options: {
				display: false,
			},
		},
		{
			name: "CurCode",
			label: "Currency",
		},
		{
			name: "CurRate",
			label: "Rate",
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => ({
					align: "right",
				}),
				setCellProps: () => ({
					style: {
						textAlign: "right",
					},
				}),
				customBodyRender: (value) => {
					return NumberFormat(value, "currency");
				},
			},
		},
		{
			name: "DrAmount",
			label: "Dr. Amount",
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => ({
					align: "right",
				}),
				setCellProps: () => ({
					style: {
						textAlign: "right",
					},
				}),
				customBodyRender: (value) => {
					return NumberFormat(value);
				},
			},
		},
		{
			name: "CrAmount",
			label: "Cr. Amount",
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => ({
					align: "right",
				}),
				setCellProps: () => ({
					style: {
						textAlign: "right",
					},
				}),
				customBodyRender: (value) => {
					return NumberFormat(value);
				},
			},
		},
		{
			name: "DrBase",
			label: "Dr. Base",
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => ({
					align: "right",
				}),
				setCellProps: () => ({
					style: {
						textAlign: "right",
					},
				}),
				customBodyRender: (value) => {
					return NumberFormat(value);
				},
			},
		},
		{
			name: "CrBase",
			label: "Cr. Base",
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => ({
					align: "right",
				}),
				setCellProps: () => ({
					style: {
						textAlign: "right",
					},
				}),
				customBodyRender: (value) => {
					return NumberFormat(value);
				},
			},
		},
		{
			name: "DimList",
			label: "DimList",
			options: {
				display: false,
			},
		},
	];

	const footerClasses = clsx({
		[classes.footerCell]: true,
		[classes.stickyFooterCell]: true,
	});

	const options = {
		responsive: "standard",
		selectableRows: "multiple",
		fixedHeader: true,
		tableBodyHeight: "580px",
		search: false,
		download: false,
		filter: false,
		print: false,
		viewColumns: false,
		elevation: 0,
		setTableProps: () => {
			return {
				size: "small",
			};
		},
		pagination: false,
		customTableBodyFooterRender: function (opts) {
			const sumDrAmt = opts.data.reduce((accu, item) => {
				const s = ToNumber(accu) + ToNumber(item.data[7]);
				return NumberFormat(s ?? 0);
			}, 0);

			const sumCrAmt = opts.data.reduce((accu, item) => {
				const s = ToNumber(accu) + ToNumber(item.data[8]);
				return NumberFormat(s ?? 0);
			}, 0);

			const sumDrBase = opts.data.reduce((accu, item) => {
				const s = ToNumber(accu) + ToNumber(item.data[9]);
				return NumberFormat(s ?? 0);
			}, 0);

			const sumCrBase = opts.data.reduce((accu, item) => {
				const s = ToNumber(accu) + ToNumber(item.data[10]);
				return NumberFormat(s ?? 0);
			}, 0);
			return (
				<TableFooter className={footerClasses}>
					<TableRow>
						{/* Add TableCellEmpty For Summary Space */}
						<TableCell className={footerClasses} />
						{opts.columns.map((col, index) => {
							if (col.display === "true") {
								if (col.name === "DrAmount") {
									return (
										<TableCell key={index} className={footerClasses}>
											{sumDrAmt}
										</TableCell>
									);
								} else if (col.name === "CrAmount") {
									return (
										<TableCell key={index} className={footerClasses}>
											{sumCrAmt}
										</TableCell>
									);
								} else if (col.name === "DrBase") {
									return (
										<TableCell key={index} className={footerClasses}>
											{sumDrBase}
										</TableCell>
									);
								} else if (col.name === "CrBase") {
									return (
										<TableCell key={index} className={footerClasses}>
											{sumCrBase}
										</TableCell>
									);
								} else {
									return <TableCell key={index} className={footerClasses} />;
								}
							}
							return null;
						})}
					</TableRow>
				</TableFooter>
			);
		},
		onRowsDelete: (rowsDeleted) => {
			const removeArray = rowsDeleted.data.map((i) => i.index);
			for (let i = removeArray.length - 1; i >= 0; i--)
				data.Detail.splice(removeArray[i], 1);
		},
	};

	const CustomHeader = (props) => {
		return (
			<>
				<TableHead>
					<TableRow>
						<TableCell align="center" colSpan={1}>
							<IconButton
								size={"small"}
								onClick={() => AddNewRow()}
								style={{ marginLeft: 6 }}
							>
								<AddIcon />
							</IconButton>
						</TableCell>
						<TableCell align="right" colSpan={10} />
					</TableRow>
				</TableHead>
				<MuiTableHead {...props} />
			</>
		);
	};

	const CheckDrCr = (field, m, data) => {
		const CurRate = ToNumber(data["CurRate"]);
		const DrAmount = ToNumber(data["DrAmount"]);
		const CrAmount = ToNumber(data["CrAmount"]);

		m.setValue("DrBase", DrAmount * CurRate);
		m.setValue("CrBase", CrAmount * CurRate);
		if (field === "DrAmount" && data[field] != 0) {
			m.setValue("DrBase", DrAmount * CurRate);
			m.setValue("CrAmount", 0);
			m.setValue("CrBase", 0);
		}
		if (field === "CrAmount" && data[field] != 0) {
			m.setValue("CrBase", CrAmount * CurRate);
			m.setValue("DrAmount", 0);
			m.setValue("DrBase", 0);
		}
	};

	const CheckValueBeforePost = (values) => {
		if (values.Type === "Recurring") {
			const sumDrBase = values.Detail.reduce((total, cur) => {
				return FixedSumValue(total + ToNumber(cur.DrBase));
			}, 0);
			const sumCrBase = values.Detail.reduce((total, cur) => {
				return FixedSumValue(total + ToNumber(cur.CrBase));
			}, 0);

			if (sumDrBase !== sumCrBase) {
				SnackbarUtils.warning(translate("ra.info.Unbalanced"));
				setLoading(false);
				return false;
			}
			if (values.Detail.length > 0) {
				const foundZeroItem = values.Detail.filter(
					(item) =>
						ToNumber(item.DrAmount) === 0 && ToNumber(item.CrAmount) === 0,
				);
				if (foundZeroItem.length > 0) {
					const msg = `Amount cannot be zero, would you like to remove transaction(s) ? 
        Note: All transactions with no amounts will be deleted.`;
					SnackbarUtils.confirm(msg, function () {
						const indexList = foundZeroItem.map((i) => i.index);
						for (let i = indexList.length - 1; i >= 0; i--) {
							values.Detail.splice(indexList[i], 1);
						}
						forceUpdate();
						Save(values);
					});
				} else {
					return true;
				}
			} else {
				return true;
			}
		} else {
			return true;
		}

		if (values.Detail.length === 0) {
			SnackbarUtils.info(translate("ra.info.notransaction"));
			setLoading(false);
			return false;
		}
	};

	const Save = async (values) => {
		const isPass = CheckValueBeforePost(values);
		if (isPass) {
			SaveFnc(values);
		} else {
			setLoading(false);
		}
	};

	const SaveFnc = async (values) => {
		const { Code, InternalMessage, UserMessage } = await updateJvFrDetail(
			values,
		);
		if (Code === 0) {
			SnackbarUtils.success(UserMessage, function () {
				redirect("show", basePath, id, values);
			});
			setLoading(false);
		} else {
			setLoading(false);
			if (InternalMessage) {
				SnackbarUtils.error(InternalMessage);
			} else {
				SnackbarUtils.warning(UserMessage);
			}
		}
	};

	const ShowDim = (values) => {
		if (!values) {
			setDataDim(data.DimHList.Dim);
			setOpenDim(!openDim);
		} else {
			setDataDim(values);
			setOpenDim(!openDim);
		}
	};

	const CancelFnc = () => {
		redirect("show", basePath, id);
	};

	if (loading) return <Loading />;
	if (!data) return null;

	return (
		<div
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: openDim,
				[classes.drawerClose]: !openDim,
			})}
		>
			<ActionMenu menuControl={menuControlProp} />

			<form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
				<Paper style={{ padding: 16 }}>
					<BoxHeader header={"Recurring Voucher"} status={data.Status} />
					<Grid container alignItems="flex-start" spacing={1}>
						{formFields
							? formFields.map((item, idx) => (
								<Grid item xs={item.size} key={idx}>
									{React.createElement(item.field.type, {
										...{
											...item.field.props,
											methods,
											key: item.field.props.name,
										},
									})}
								</Grid>
							))
							: ""}
						{formFieldsRecurring && methods.watch("Type") === "Recurring"
							? formFieldsRecurring.map((item, idx) => (
								<Grid item xs={item.size} key={idx} style={item.style}>
									{React.createElement(item.field.type, {
										...{
											...item.field.props,
											methods,
											key: item.field.props.name,
										},
									})}
								</Grid>
							))
							: ""}

						<Grid item xs={12}>
							<MuiTranslateTable
								data={data.Detail}
								columns={columns}
								options={options}
								components={{
									TableHead: CustomHeader,
								}}
							/>
							{showAdd && (
								<PopupTable
									initialValues={
										editIndex !== ""
											? data.Detail.find((i) => i.index === editIndex)
											: initNewRow
									}
									formFields={formFieldsDetail}
									update={CheckDrCr}
									open={showAdd}
									save={(row) => SaveFromPopup(data, row)}
									cancel={CancelFromPopup}
									showDim
								/>
							)}
						</Grid>
					</Grid>
				</Paper>
				<pre>
					{process.env.NODE_ENV === "development"
						? JSON.stringify(data, 0, 2)
						: ""}
				</pre>
				<ButtonFooter CancelFnc={CancelFnc} />
			</form>

			<NavRight
				open={openDim}
				close={() => setOpenDim(false)}
				ShowDim={() => ShowDim()}
				dataDim={dataDim}
				update={(item, value) => {
					dataDim.forEach((i) => {
						if (i.Id === item.Id) {
							i.Value = value;
							if (i.Type === "Date") {
								i.Value = DateToString(value);
							}
						}
					});
					setDataDim(dataDim);
					//methods.trigger();
				}}
				modify
				module={"GL_JvFr"}
				moduleId={id}
			/>
		</div>
	);
};

export default Edit;
