import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
// rome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import { Loading, Error, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid, Typography, Card, CardContent, Box } from "@material-ui/core";
import TextTopInGrid from "components/TextTopInGrid";
import CardImage from "components/CardImage";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import { permissionName } from "utils/constants";
import {
  getAssetDisDetail,
  getAssetDisposalAccuDepre,
  getAssetRegisterSearchList,
  delAssetDisDetail,
} from "services/asset";
import { showReportByName } from "pages/Report/services";
import DialogAssetRegister from "./DialogAssetRegister";
import DialogSelectType from "./DialogSelectType";
import ModelUriQueryString from "models/uriQueryString";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import ReportBackDrop from "components/ReportBackDrop";

const Show = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { settingAll, DateToString, NumberFormat } = useContext(GblContext);
  const { SettingClosePeriod } = settingAll;
  const { ClosePeriodAsset } = SettingClosePeriod;
  let newClosePeriodAsset = addDays(new Date(ClosePeriodAsset), 1);
  const { basePath, id, permissions } = props;
  const redirect = useRedirect();
  const [data, setData] = useState();
  const [remainValue, setRemainValue] = useState();
  const [regData, setRegData] = useState();
  const [loading, setLoading] = useState(true);
  const [error] = useState();

  const CheckEditBtn = () => {
    if (data) {
      if (new Date(newClosePeriodAsset) > new Date(data.DisposalDate)) {
        var msgClosePeriod = translate("ra.closePeriod.warning", { name: "asset", action: "delete" });
        SnackbarUtils.warning(msgClosePeriod);
      } else {
        redirect("edit", basePath, id);
      }
    }
  };

  const CheckDeleteBtn = () => {
    if (data) {
      if (new Date(newClosePeriodAsset) > new Date(data.DisposalDate)) {
        var msgClosePeriod = translate("ra.closePeriod.warning", { name: "asset", action: "delete" });
        SnackbarUtils.warning(msgClosePeriod);
      } else {
        DelOrVoid();
      }
    }
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", fnc: props.FncAddButton },
    {
      name: "Edit",
      fnc: () => CheckEditBtn(),
    },
    {
      name: "Delete",
      fnc: () => CheckDeleteBtn(),
    },
    {
      name: "Print",
      fnc: () => {
        props.showReportLoader();
        showReportByName("AS_Disposal", [{ Name: "DisposalId", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
  ];

  const fetchAssetById = useCallback(
    async (mounted) => {
      const response = await getAssetDisDetail(id);
      if (response) {
        setData(response);
        let paramAstDisposalQuery = {
          DisposalDate: response.DisposalDate,
          Id: response.Id,
          No: response.No,
          Type: response.Type,
          Qty: response.Qty,
          ReduceAmt: response.AstAmt,
        };
        const resRemainValue = await getAssetDisposalAccuDepre(paramAstDisposalQuery);
        if (resRemainValue) {
          if (response.Type === "Quantity") {
            setRemainValue(resRemainValue);
          }
        }
        //let whereRaw = `AstId = '${response.Id}' and AstNo = '${response.No}'`;
        let whereGroupList = [
          {
            AndOr: "And",
            ConditionList: [
              {
                AndOr: "And",
                Field: "AstId",
                Operator: "=",
                Value: response.Id,
              },
              {
                AndOr: "And",
                Field: "AstNo",
                Operator: "=",
                Value: response.No,
              },
            ],
          },
        ];
        let excludeModel = [
          "Barcode",
          "Spec",
          "SerialNo",
          "AstPhoto",
          "CurRate",
          "Qty",
          "Salvage",
          "InitAccu",
          "TotalCost",
          "Life",
          "LifeType",
          "RemainDay",
          "RemainNet",
          "RemainTotalCost",
          "DimList",
          "RemainInfo",
        ];

        const resRegisterAccount = await getAssetRegisterSearchList({
          Limit: ModelUriQueryString.Limit,
          Page: ModelUriQueryString.Page,
          OrderBy: ModelUriQueryString.OrderBy,
          WhereGroupList: whereGroupList,
          Exclude: excludeModel,
          WhereRaw: ModelUriQueryString.WhereRaw,
          WhereLike: ModelUriQueryString.WhereLike,
          WhereLikeFields: ModelUriQueryString.WhereLikeFields,
        });

        //find assetgister follow whereraw AstId,AstNo
        if (resRegisterAccount) {
          setRegData(resRegisterAccount.Data[0]);
        }
        if (mounted) {
          setLoading(false);
        }
      }
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    fetchAssetById(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, [fetchAssetById]);

  const DelOrVoid = async () => {
    let msg = translate("ra.question.confirmDel");
    SnackbarUtils.delConfirm(msg, async function () {
      const { Code, InternalMessage, UserMessage } = await delAssetDisDetail(id);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect("list", basePath);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    });
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  const RemainValue = () => {
    if (remainValue) {
      return (
        <Grid container direction="column" spacing={1} style={{ marginBottom: 12 }}>
          <TextTopInGrid sizeXs={12} sizeSm={12} label="LastCost" value={NumberFormat(remainValue.RemainLastCost)} />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={12}
            label="Available"
            value={NumberFormat(remainValue.RemainAvailable, "qty")}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={12}
            label="Total Value"
            value={NumberFormat(remainValue.RemainTotalAssetValue)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={12}
            label="Accu Depre."
            value={NumberFormat(remainValue.RemainAccuDepre)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={12}
            label="Net Book Value"
            value={NumberFormat(remainValue.RemainNetBookValue)}
          />
        </Grid>
      );
    } else {
      return "";
    }
  };

  const DisposalValue = () => {
    if (data.Type === "Quantity") {
      return (
        <Grid container direction="column" spacing={1} style={{ marginBottom: 12 }}>
          <TextTopInGrid sizeXs={12} sizeSm={12} label="Disposal Type" value={data.Type} />
          <TextTopInGrid sizeXs={12} sizeSm={12} label="Disposal Date" value={DateToString(data.DisposalDate)} />
          <TextTopInGrid sizeXs={12} sizeSm={12} label="Amount/Unit" value={NumberFormat(data.AstAmt, "unit")} />
          <TextTopInGrid sizeXs={12} sizeSm={12} label="Qty" value={NumberFormat(data.Qty, "qty")} />
          <TextTopInGrid sizeXs={12} sizeSm={12} label="Total Asset Value" value={NumberFormat(data.TotalCost)} />
        </Grid>
      );
    } else {
      return null;
    }
  };

  const SaleValue = () => {
    if (data.Type === "Quantity") {
      return (
        <Grid container direction="column" spacing={1} style={{ marginBottom: 12 }}>
          <TextTopInGrid sizeXs={12} sizeSm={12} label="Sale Amount" value={NumberFormat(data.SaleAmt)} />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={12}
            label="Net Book Value"
            value={NumberFormat(data.Type === "Quantity" ? remainValue.SaleNetBookValue ?? 0 : data.NetBook)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={12}
            label="Gain/Loss Amount"
            value={NumberFormat(
              data.Type === "Quantity" ? data.SaleAmt - remainValue.SaleNetBookValue : data.SaleAmt - data.NetBook
            )}
          />
        </Grid>
      );
    } else {
      return null;
    }
  };

  const CheckCodeNull = (code, desc) => {
    if (desc) {
      return `${code} : ${desc}`;
    } else {
      return code;
    }
  };

  const AssetAccount1 = () => {
    if (regData) {
      return (
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Asset Department"
            value={CheckCodeNull(regData?.CostDeptCode, regData?.CostDeptDesc)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Accu Department"
            value={CheckCodeNull(regData?.AccuDeptCode, regData?.AccuDeptDesc)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Asset Account"
            value={CheckCodeNull(regData?.CostAccCode, regData?.CostAccDesc)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Accu Account"
            value={CheckCodeNull(regData?.AccuAccCode, regData?.AccuAccDesc)}
          />
        </Grid>
      );
    } else {
      return "";
    }
  };

  const DisposalAccount = () => {
    if (data.Type === "Quantity") {
      return (
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Gain/Loss Department"
            value={CheckCodeNull(data.GainLossDeptCode, data.GainLossDeptDesc)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Sale Department"
            value={CheckCodeNull(data.SaleDeptCode, data.SaleDeptDesc)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Gain/Loss Account"
            value={CheckCodeNull(data.GainLossAccCode, data.GainLossAccDesc)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Sale Account"
            value={CheckCodeNull(data.SaleAccCode, data.SaleAccDesc)}
          />
        </Grid>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={classes.marginContent}>
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["Ast.Disposal"])}
      />
      <Card>
        <CardContent>
          <BoxHeader header={"Asset Disposal"} status={data.Status} />
          <Box height={5} />
          <Grid container alignItems="flex-start" spacing={1}>
            <Grid item xs={9}>
              <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
                <TextTopInGrid sizeXs={2} sizeSm={4} label="Asset No." value={`${data.Id} - ${data.No}`} />
                <TextTopInGrid sizeXs={2} sizeSm={8} label="Name" value={data.Name} />
                <TextTopInGrid
                  sizeXs={12}
                  sizeSm={4}
                  label="Input Date"
                  value={DateToString(regData?.InputDate ?? new Date())}
                />
                <TextTopInGrid
                  sizeXs={12}
                  sizeSm={4}
                  label="Category"
                  value={`${regData?.CategoryCode} : ${regData?.AstCateDesc}`}
                />
                <TextTopInGrid
                  sizeXs={12}
                  sizeSm={4}
                  label="Department"
                  value={`${regData?.DepartmentCode} : ${regData?.AstDeptDesc}`}
                />
                <TextTopInGrid
                  sizeXs={12}
                  sizeSm={4}
                  label="Acquire Date"
                  value={DateToString(regData?.AcquireDate ?? new Date())}
                />
                <TextTopInGrid
                  sizeXs={12}
                  sizeSm={4}
                  label="Location"
                  value={`${regData?.LocationCode} : ${regData?.AstLocDesc}`}
                />
                <TextTopInGrid sizeXs={12} sizeSm={4} label="Serial No." value={regData?.SerialNo} />
                <TextTopInGrid sizeXs={12} sizeSm={4} label="Specification" value={regData?.Spec} />
                <TextTopInGrid sizeXs={12} sizeSm={8} label="Remark" value={regData?.Remark} />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{ padding: 8, textAlign: "center" }}>
                {data.AstPhoto ? (
                  <CardImage base64Src={data.AstPhoto} noBorder={true} customSize={{ height: 200 }} />
                ) : (
                  ""
                )}
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Box height={4} />
      <Card>
        <CardContent>
          <Grid container alignItems="flex-start" spacing={1}>
            <Grid item xs={4}>
              {remainValue && <Typography className={classes.heading}>Remain Value</Typography>}
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.heading}>{translate("ra.actionMenu.Disposal")}</Typography>
            </Grid>
            <Grid item xs={4}>
              {data.Type === "Quantity" && <Typography className={classes.heading}>Sale Value</Typography>}
            </Grid>
            <Grid item xs={4}>
              <RemainValue />
            </Grid>
            <Grid item xs={4}>
              <DisposalValue />
            </Grid>
            <Grid item xs={4}>
              <SaleValue />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box height={4} />
      <Card>
        <CardContent>
          <Grid container alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.heading}>{translate("ra.field.Asset Account")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <AssetAccount1 />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box height={4} />
      <Card>
        <CardContent>
          <Grid container alignItems="flex-start" spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.heading}>{translate("ra.field.Disposal Account")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <DisposalAccount />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {props.openSelectType && (
        <DialogSelectType open={props.openSelectType} onClose={props.onCloseSelectType} basePath={basePath} />
      )}
      {props.open && <DialogAssetRegister open={props.open} onClose={props.onClose} basePath={basePath} />}
      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
    </div>
  );
};

export default ReportBackDrop(Show);
