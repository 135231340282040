import React from "react";
import clsx from "clsx";
import { useTranslate } from "react-admin";
import { Grid, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.type === "dark" ? "inherit" : "white",
    border: theme.palette.type === "dark" ? "1px solid white" : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 8,
  },
  // footerNoBorder: {
  //   position: "sticky",
  //   bottom: 0,
  //   zIndex: 100,
  //   backgroundColor: theme.palette.type === "dark" ? "inherit" : "white",
  // },
  textCancel: {
    color: "inherit",
    border: theme.palette.type === "dark" ? "1px solid white" : "1px solid rgba(0, 0, 0, 0.23)",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default ({ SaveFnc, CancelFnc, saveTitle, cancelTitle, noBorder, disabled }) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (noBorder) {
    return (
      <>
        <Button variant="contained" color="primary" disabled={disabled} type="submit" onClick={SaveFnc}>
          {saveTitle ? saveTitle : translate("ra.action.save")}
        </Button>
        <Button variant="outlined" className={classes.textCancel} onClick={CancelFnc}>
          {cancelTitle ? cancelTitle : translate("ra.action.cancel")}
        </Button>
      </>
    );
  } else {
    return (
      <div
        className={clsx(classes.drawer, {
          [classes.footer]: !noBorder,
        })}
      >
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <div className={classes.wrapper}>
              <Button variant="contained" color="primary" disabled={disabled} type="submit" onClick={SaveFnc}>
                {saveTitle ? saveTitle : translate("ra.action.save")}
              </Button>
              {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            {/* <Button variant="contained" color="primary" type="submit" onClick={SaveFnc}>
              {saveTitle ? saveTitle : translate("ra.action.save")}
            </Button> */}
          </Grid>
          <Grid item>
            <Button variant="outlined" className={classes.textCancel} onClick={CancelFnc}>
              {cancelTitle ? cancelTitle : translate("ra.action.cancel")}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
};
