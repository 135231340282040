export default {
  ArProfileId: 0,
  ArNo: "",
  FirstName: "",
  LastName: "",
  Title: "",
  TaxNo: "",
  RegDate: new Date(),
  Status: "",
  StatusCode: "",
  ArType: "",
  Company: "",
  AddressInfo: {
    Address1: "",
    Address2: "",
    Address3: "",
  },
  Position: "",
  BillTo: "Address1",
  MailTo: "Address1",
  TaxTo: "Address1",
  Tel: "",
  Fax: "",
  Email: "",
  LateChg: 0,
  CreditTerm: 0,
  CreditLimit: 0,
  Remark: "",
  PaymentCode: "",
  StatementType: "",
  StatementDays: 0,
  StatementDate: 0,
  UserModified: localStorage.getItem("UserName"),
  ARSource: "",
  BranchNo: "",
  Active: true,
  ContractDetail: [],
};
