import React, { useState } from "react";
import { usePermissions, useTranslate } from "react-admin";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography } from "@material-ui/core";
import ProductLicense from "./ProductLicense";
import CompanyProfile from "./CompanyProfile";
import SystemPreference from "./SystemPreference";
import Users from "./Users";
import Permission from "./Permission";
import Currency from "./Currency";
import Department from "./Department";
import PaymentType from "./PaymentType";
import Dimension from "./Dimension";
import Unit from "./Unit";
import Interface from "./Interface";
import Email from "./Email";
import EmailTemplate from "./EmailTemplate";
import Workflow from "./Workflow";
import ChartOfAccount from "./ChartOfAccount";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	title: {
		margin: theme.spacing(2, 1, 2),
	},
	container: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: "flex",
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
}));

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		"aria-controls": `vertical-tabpanel-${index}`,
	};
}

const CustomTab = withStyles((theme) => ({
	root: {
		textTransform: "none",
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(15),
		marginRight: theme.spacing(1),
		"&:focus": {
			opacity: 1,
		},
	},
	wrapper: {
		alignItems: "start",
	},
}))((props) => <Tab disableRipple {...props} />);

const Configuration = () => {
	const translate = useTranslate();
	const { permissions } = usePermissions();

	const classes = useStyles();

	const [value, setValue] = useState(1);

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			<Typography variant="h6" className={classes.title}>
				{translate("ra.module.Configuration")}
			</Typography>
			<div className={classes.container}>
				<Tabs
					orientation="vertical"
					variant="fullWidth"
					value={value}
					onChange={handleChange}
					className={classes.tabs}
				>
					<CustomTab
						label={translate("ra.module.Product License")}
						{...a11yProps(0)}
					/>
					<CustomTab
						label={translate("ra.module.Company Profile")}
						{...a11yProps(1)}
					/>
					<CustomTab
						label={translate("ra.module.System Preference")}
						{...a11yProps(2)}
					/>
					<CustomTab label={translate("ra.module.Users")} {...a11yProps(3)} />
					<CustomTab
						label={translate("ra.module.Permissions")}
						{...a11yProps(4)}
					/>
					<CustomTab
						label={translate("ra.module.Currency")}
						{...a11yProps(5)}
					/>
					<CustomTab
						label={translate("ra.module.Department")}
						{...a11yProps(6)}
					/>
					<CustomTab
						label={translate("ra.module.Chart of accounts")}
						{...a11yProps(7)}
					/>
					<CustomTab
						label={translate("ra.module.Payment Type")}
						{...a11yProps(8)}
					/>
					<CustomTab
						label={translate("ra.module.Dimension")}
						{...a11yProps(9)}
					/>
					<CustomTab label={translate("ra.module.Unit")} {...a11yProps(10)} />
					<CustomTab
						label={translate("ra.module.Interface")}
						{...a11yProps(11)}
					/>
					<CustomTab label={translate("ra.module.Email")} {...a11yProps(12)} />
					<CustomTab
						label={translate("ra.module.EmailTemplate")}
						{...a11yProps(13)}
					/>
					<CustomTab
						label={translate("ra.module.Workflow")}
						{...a11yProps(14)}
					/>
				</Tabs>
				<ProductLicense value={value} index={0} permissions={permissions}>
					Product License
				</ProductLicense>
				<CompanyProfile value={value} index={1} permissions={permissions}>
					Company Profile
				</CompanyProfile>
				<SystemPreference value={value} index={2} permissions={permissions}>
					System Preference
				</SystemPreference>
				<Users value={value} index={3} permissions={permissions}>
					Users
				</Users>
				<Permission value={value} index={4} permissions={permissions}>
					Permissions
				</Permission>
				<Currency value={value} index={5} permissions={permissions}>
					Currency
				</Currency>
				<Department value={value} index={6} permissions={permissions}>
					Department
				</Department>
				<ChartOfAccount value={value} index={7} permissions={permissions}>
					Chart of accounts
				</ChartOfAccount>
				<PaymentType value={value} index={8} permissions={permissions}>
					PaymentType
				</PaymentType>
				<Dimension value={value} index={9} permissions={permissions}>
					Dimension
				</Dimension>
				<Unit value={value} index={10} permissions={permissions}>
					Unit
				</Unit>
				<Interface value={value} index={11} permissions={permissions}>
					Interface
				</Interface>
				<Email value={value} index={12} permissions={permissions}>
					Email
				</Email>
				<EmailTemplate value={value} index={13} permissions={permissions}>
					EmailTemplate
				</EmailTemplate>
				<Workflow value={value} index={14} permissions={permissions}>
					Workflow
				</Workflow>
			</div>
		</div>
	);
};

export default Configuration;
