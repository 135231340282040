/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { Loading, useRedirect, useTranslate } from "react-admin";
import { useForm } from "react-hook-form";
import { Paper, Grid, Typography, TextField, IconButton, Card, CardContent, Box } from "@material-ui/core";
import NumberFormatInput from "components/NumberFormatInput";
import TextTopInGrid from "components/TextTopInGrid";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
import CardImage from "components/CardImage";
import DialogAssetRegister from "./DialogAssetRegister";
import SearchIcon from "@material-ui/icons/Search";
import PreviewImage from "assets/previewImage.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import { createAssetDisDetail, getAssetRegDetail, getAssetDisposalAccuDepre } from "services/asset";
import Model from "models/assetDisposal";
import SnackbarUtils from "utils/SnackbarUtils";
import { addDays } from "date-fns";

const Create = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { DateToString, NumberFormat, ToNumber } = useContext(GblContext);
  const [openRegDialog, setOpenRegDialog] = useState(false);
  const redirect = useRedirect();
  const { basePath, formFieldsDisposalQty, formFieldsSaleQty, formFieldsAccountQty, formFieldsDisposalImp, regId } =
    props;
  const [loading, setLoading] = useState(false);
  const [remainValue, setRemainValue] = useState();
  const [initData, setInitData] = useStateWithCallbackLazy(Model);
  const [regData, setRegData] = useState();
  const [originalQty, setOriginalQty] = useState();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Delete", disabled: true },
    { name: "Print", disabled: true },
  ];

  const methods = useForm({ defaultValues: initData });

  const { handleSubmit, getValues, reset } = methods;

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async (v) => {
    CheckAccount();
    var b = CheckQty(v);
    //Adjust parameter before save
    if (Object.keys(methods.errors).length === 0 && b) {
      const values = getValues();
      setBtnLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
      setInitData(
        (state) => ({
          ...state,
          ...values,
          Name: regData.Name,
          GainLossAmt: ToNumber(values["GainLossAmt"]),
          NetBook: ToNumber(values["NetBook"]),
          SaleAmt: ToNumber(values["SaleAmt"]),
          ReduceAmt: values["Type"] === "Quantity" ? 0 : ToNumber(values["SaleAmt"]),
          TotalCost: values.TotalCost,
        }),
        (nextState) => Save(nextState)
      );
    } else {
      return false;
    }
  };

  const CalculateQDisposal = (type = "Quantity", disposalDate, astAmt, saleQty) => {
    methods.setValue("TotalCost", astAmt * saleQty);

    let paramAstDisposalQuery = {
      DisposalDate: disposalDate,
      Id: remainValue["Id"],
      No: remainValue["No"],
      Type: type,
      Qty: saleQty < remainValue.Available ? saleQty : remainValue.Available,
      ReduceAmt: astAmt,
    };

    GetDataWhenChangeAstNo(paramAstDisposalQuery, initData);
  };

  const UpdateForm = (e) => {
    const values = getValues();
    let type = values["Type"] ?? methods.watch("Type");
    let saleQty = ToNumber(values["Qty"] ?? methods.watch("Qty"));
    let astAmt = ToNumber(values["AstAmt"] ?? methods.watch("AstAmt"));
    let saleAmt = ToNumber(values["SaleAmt"] ?? methods.watch("SaleAmt"));
    let saleNetBook = ToNumber(values["NetBook"] ?? methods.watch("NetBook"));

    if (e.target?.name === "SaleAmt") {
      if (type === "Quantity") {
        methods.setValue("GainLossAmt", saleAmt - saleNetBook);
        setInitData((state) => ({
          ...state,
          SaleAmt: saleAmt,
          GainLossAmt: saleAmt - saleNetBook,
        }));
        CheckAccount();
      }
    }

    if (e.target?.name === "DisposalDate") {
      var newDisposalDate = e.target.value;
      if (newDisposalDate < initData.LastDisposalDate) {
        methods.setError("DisposalDate", {
          type: "min",
          message: "DisposalDate must be more than last disposal",
        });
        return;
      } else {
        methods.clearErrors("DisposalDate");
      }

      if (type === "Quantity") {
        CalculateQDisposal(type, newDisposalDate, astAmt, saleQty);
      }
      methods.clearErrors("Qty");
    }

    if (e.target?.name === "Qty") {
      var b = CheckQty(values);
      if (b) {
        CalculateQDisposal(type, methods.watch("DisposalDate"), astAmt, saleQty);
      }
    }
  };

  const CheckQty = (v) => {
    let type = methods.watch("Type");
    const saleQty = ToNumber(v.Qty ?? methods.watch("Qty"));
    //Method QTY
    if (saleQty <= 0 && type === "Quantity") {
      methods.setError("Qty", {
        type: "min",
        message: "Qty must be more than zero",
      });
      return false;
    }

    if (saleQty > originalQty) {
      methods.setError("Qty", {
        type: "max",
        message: "Qty must be less than availiable",
      });
      return false;
    }

    if (saleQty > originalQty) {
      methods.clearErrors("Qty");
    }

    return true;
  };

  const CheckAccount = () => {
    const vGlDept = methods.watch("GainLossDeptCode");
    const vGlAcc = methods.watch("GainLossAccCode");
    const vSaleDept = methods.watch("SaleDeptCode");
    const vSaleAcc = methods.watch("SaleAccCode");

    if (!vGlDept) {
      methods.setError("GainLossDeptCode", {
        type: "required",
        message: "*Required",
      });
    } else {
      methods.clearErrors("GainLossDeptCode");
    }
    if (!vGlAcc) {
      methods.setError("GainLossAccCode", {
        type: "required",
        message: "*Required",
      });
    } else {
      methods.clearErrors("GainLossAccCode");
    }
    if (!vSaleDept) {
      methods.setError("SaleDeptCode", {
        type: "required",
        message: "*Required",
      });
    } else {
      methods.clearErrors("SaleDeptCode");
    }
    if (!vSaleAcc) {
      methods.setError("SaleAccCode", {
        type: "required",
        message: "*Required",
      });
    } else {
      methods.clearErrors("SaleAccCode");
    }

    if (ToNumber(methods.watch("GainLossAmt")) === 0) {
      methods.clearErrors("GainLossDeptCode");
      methods.clearErrors("GainLossAccCode");
    }
  };

  const fetchAssetRegItem = async (id) => {
    setLoading(true);
    //setNewRegId
    let newRegId = regId;
    let newType = localStorage.getItem("disposalType");
    if (id) {
      newRegId = id;
    }
    const response = await getAssetRegDetail(newRegId);
    if (response) {
      let lastDisposalDate = response.RemainInfo.LastDisposalDate
        ? addDays(new Date(response.RemainInfo.LastDisposalDate), 1)
        : null;
      setRegData(response);
      setInitData((state) => ({
        ...state,
        Type: newType ?? initData.Type,
        InputDate: response.InputDate,
        Qty: response.RemainQty,
        DisposalDate: new Date(),
        LastDisposalDate: lastDisposalDate === null ? response.InputDate : lastDisposalDate,
      }));

      let paramAstDisposalQuery = {
        DisposalDate: new Date(),
        Id: response.Id,
        No: response.No,
        Type: newType ?? initData.Type,
        Qty: response.RemainQty,
        ReduceAmt: newType === "Quantity" ? initData.AstAmt : response.RemainTotalCost / response.RemainQty,
      };

      GetDataWhenChangeAstNo(paramAstDisposalQuery, response, "first");

      localStorage.removeItem("regId");
      localStorage.removeItem("disposalType");
    }
  };

  const GetDataWhenChangeAstNo = async (param, data) => {
    const resRemainValue = await getAssetDisposalAccuDepre(param);

    if (resRemainValue) {
      if (resRemainValue.Type === "Quantity") {
        setRemainValue(resRemainValue);
        setOriginalQty(resRemainValue.Available);
        setInitData(
          (state) => ({
            ...state,
            DisposalDate: resRemainValue.DisposalDate,
            Type: resRemainValue.Type,
            Id: resRemainValue.Id,
            No: resRemainValue.No,
            Qty: param.Qty < resRemainValue.Available ? param.Qty : resRemainValue.Available,
            AstAmt: resRemainValue.LastCost,
            TotalCost: resRemainValue.TotalAssetValue,
            NetBook: resRemainValue.SaleNetBookValue,
            LastCost: resRemainValue.LastCost,
            Depre: resRemainValue.Depre,
            AccuDepreAmt: resRemainValue.AccuDepre,
            GainLossAmt: ToNumber(state.SaleAmt) - resRemainValue.SaleNetBookValue,
          }),
          (nextState) => {
            reset(nextState);
            if (ToNumber(nextState.GainLossAmt) !== 0) {
              methods.setError("GainLossDeptCode", {
                type: "required",
                message: "*Required",
              });
              methods.setError("GainLossAccCode", {
                type: "required",
                message: "*Required",
              });
            }
            if (!nextState.SaleDeptCode) {
              methods.setError("SaleDeptCode", {
                type: "required",
                message: "*Required",
              });
            }
            if (!nextState.SaleAccCode) {
              methods.setError("SaleAccCode", {
                type: "required",
                message: "*Required",
              });
            }
            setLoading(false);
          }
        );
      }
    }
    CheckAccount(data);
  };

  const FindAssetRegisterById = () => {
    setOpenRegDialog(true);
  };

  const CloseRegisterDialog = (regId) => {
    setOpenRegDialog(false);
    if (regId) {
      fetchAssetRegItem(regId);
    }
  };

  useEffect(() => {
    if (regId !== 0) {
      fetchAssetRegItem();
    } else {
      redirect("list", basePath);
    }
  }, []);

  const Save = async (values) => {
    const { Code, InternalMessage, UserMessage } = await createAssetDisDetail(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage, function () {
        redirect(`${InternalMessage}/show`);
      });
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
    }
  };

  const CancelFnc = () => {
    redirect("list", basePath);
  };

  if (loading) return <Loading />;
  if (!initData) return null;

  const RegisterValue = () => {
    if (regData) {
      return (
        <Grid container justifyContent="flex-start" spacing={1} style={{ marginBottom: 12 }}>
          <Grid item xs={12} sm={4}>
            <TextField
              name="AssetNo"
              label="AssetNo"
              variant="outlined"
              margin="dense"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="Find Asset Register" onClick={FindAssetRegisterById}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                readOnly: true,
              }}
              value={`${regData?.Id}-${regData?.No}`}
            />
          </Grid>
          <TextTopInGrid sizeXs={12} sizeSm={8} label="Name" value={regData?.Name} />
          <TextTopInGrid sizeXs={12} sizeSm={4} label="Input Date" value={DateToString(regData?.InputDate)} />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={4}
            label="Category"
            value={`${regData?.CategoryCode} : ${regData?.AstCateDesc}`}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={4}
            label="Department"
            value={`${regData?.DepartmentCode} : ${regData?.AstDeptDesc}`}
          />
          <TextTopInGrid sizeXs={12} sizeSm={4} label="Acquire Date" value={DateToString(regData?.AcquireDate)} />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={4}
            label="Location"
            value={`${regData?.LocationCode} : ${regData?.AstLocDesc}`}
          />
          <TextTopInGrid sizeXs={12} sizeSm={4} label="Serial No." value={regData?.SerialNo} />
          <TextTopInGrid sizeXs={12} sizeSm={4} label="Specification" value={regData?.Spec} />
          <TextTopInGrid sizeXs={12} sizeSm={8} label="Remark" value={regData?.Remark} />
        </Grid>
      );
    } else {
      return "";
    }
  };

  const CheckCodeNull = (code, desc) => {
    if (desc) {
      return `${code} : ${desc}`;
    } else {
      return code;
    }
  };

  const RegisterAccount = () => {
    if (regData) {
      return (
        <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={1}>
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Asset Department"
            value={CheckCodeNull(regData?.CostDeptCode, regData?.CostDeptDesc)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Accu Department"
            value={CheckCodeNull(regData?.AccuDeptCode, regData?.AccuDeptDesc)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Asset Account"
            value={CheckCodeNull(regData?.CostAccCode, regData?.CostAccDesc)}
          />
          <TextTopInGrid
            sizeXs={12}
            sizeSm={6}
            label="Accu Account"
            value={CheckCodeNull(regData?.AccuAccCode, regData?.AccuAccDesc)}
          />
        </Grid>
      );
    } else {
      return "";
    }
  };

  const TextFieldNumber = (props) => {
    return (
      <TextField
        fullWidth
        {...props}
        variant="filled"
        margin="dense"
        InputProps={{
          inputComponent: NumberFormatInput,
        }}
        inputProps={{
          style: { textAlign: "right" },
          decimal: 2,
          readOnly: true,
        }}
        style={{ marginBottom: 12.5 }}
      />
    );
  };

  return (
    <div>
      <ActionMenu menuControl={menuControlProp} />

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Card>
          <CardContent>
            <BoxHeader header={"Asset Disposal"} />
            <Grid container spacing={1} style={{ marginBottom: 12 }}>
              <Grid item xs={9}>
                <RegisterValue />
              </Grid>
              <Grid item xs={3}>
                <Paper style={{ padding: 8, textAlign: "center" }}>
                  {regData?.AstPhoto ? (
                    <CardImage base64Src={regData?.AstPhoto} noBorder={true} customSize={{ height: 200 }} />
                  ) : (
                    <CardImage imgSrc={PreviewImage} noBorder={true} customSize={{ height: 200 }} />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box height={5} />

        <Card>
          <CardContent>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 10 }}>
              <Grid item xs={4}>
                <Typography className={classes.heading}>Remain Value</Typography>
                <Box height={"0.5rem"} />
                <TextFieldNumber
                  name="LastCost"
                  label={translate("ra.field.LastCost")}
                  value={NumberFormat(remainValue?.RemainLastCost ?? 0)}
                />
                <TextFieldNumber
                  name="Qty"
                  label={translate("ra.field.Available")}
                  value={NumberFormat(remainValue?.RemainAvailable ?? 0, "qty")}
                />
                <TextFieldNumber
                  name="TotalValue"
                  label={translate("ra.field.Total Value")}
                  value={NumberFormat(remainValue?.RemainTotalAssetValue ?? 0)}
                />
                <TextFieldNumber
                  name="AccuDepreAmt"
                  label={translate("ra.field.Accu Depre.")}
                  value={NumberFormat(remainValue?.RemainAccuDepre ?? 0)}
                />
                <TextFieldNumber
                  name="NetBookValue"
                  label={translate("ra.field.Net Book Value")}
                  value={NumberFormat(remainValue?.RemainNetBookValue ?? 0)}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.heading}>{translate("ra.actionMenu.Disposal")}</Typography>
                <Box height={"0.5rem"} />
                <Grid container alignItems="flex-start" spacing={1}>
                  {methods.watch("Type") === "Quantity"
                    ? formFieldsDisposalQty.map((item) => (
                        <Grid item xs={item.size} key={item.field.props.name?.toString()} style={item.style}>
                          {item.field.props.name === "AstAmt" || item.field.props.name === "Qty"
                            ? React.createElement(item.field.type, {
                                ...{
                                  ...item.field.props,
                                  methods,
                                  key: item.field.props.name,
                                  onChange: UpdateForm,
                                  forceSetReadOnly: false,
                                },
                              })
                            : React.createElement(item.field.type, {
                                ...{
                                  ...item.field.props,
                                  methods,
                                  key: item.field.props.name,
                                  onChange: UpdateForm,
                                },
                              })}
                        </Grid>
                      ))
                    : formFieldsDisposalImp.map((item) => (
                        <Grid item xs={item.size} key={item.field.props.name?.toString()} style={item.style}>
                          {item.field.props.name === "AstAmt" || item.field.props.name === "Qty"
                            ? React.createElement(item.field.type, {
                                ...{
                                  ...item.field.props,
                                  methods,
                                  key: item.field.props.name,
                                  onChange: UpdateForm,
                                  forceSetReadOnly: true,
                                },
                              })
                            : React.createElement(item.field.type, {
                                ...{
                                  ...item.field.props,
                                  methods,
                                  key: item.field.props.name,
                                  onChange: UpdateForm,
                                },
                              })}
                        </Grid>
                      ))}
                </Grid>
              </Grid>
              {methods.watch("Type") === "Quantity" ? (
                <Grid item xs={4}>
                  <Typography className={classes.heading}>Sale Value</Typography>
                  <Box height={"0.5rem"} />
                  <Grid container alignItems="flex-start" spacing={1} style={{ marginTop: 97 }}>
                    {formFieldsSaleQty
                      ? formFieldsSaleQty.map((item) => (
                          <Grid item xs={item.size} key={item.field.props.name?.toString()} style={item.style}>
                            {React.createElement(item.field.type, {
                              ...{
                                ...item.field.props,
                                methods,
                                key: item.field.props.name,
                                onChange: UpdateForm,
                              },
                            })}
                          </Grid>
                        ))
                      : ""}
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </CardContent>
        </Card>
        <Box height={5} />

        <Card>
          <CardContent>
            <Grid container alignItems="center" spacing={1} style={{ marginBottom: 14 }}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>{translate("ra.field.Asset Account")}</Typography>
                <Box height={"0.5rem"} />
                <RegisterAccount />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box height={5} />
        <Card>
          <CardContent>
            <Grid container alignItems="center" spacing={1} style={{ marginBottom: 10 }}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>{translate("ra.field.Disposal Account")}</Typography>
                <Box height={"0.5rem"} />
                <Grid container alignItems="center" spacing={1}>
                  {formFieldsAccountQty
                    ? formFieldsAccountQty.map((item) => (
                        <Grid
                          item
                          xs={item.size}
                          key={item.field.props.name?.toString()}
                          style={{ marginBottom: "auto" }}
                        >
                          {React.createElement(item.field.type, {
                            ...{
                              ...item.field.props,
                              methods,
                              key: item.field.props.name,
                              onChange: CheckAccount,
                            },
                          })}
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box height={5} />
        {openRegDialog && (
          <DialogAssetRegister open={openRegDialog} onClose={CloseRegisterDialog} basePath={basePath} />
        )}
        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(initData, 0, 2) : ""}</pre>
        <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />
      </form>
    </div>
  );
};

export default Create;
