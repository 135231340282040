/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useTranslate } from "react-admin";
import AccessDenied from "layout/AccessDenied";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Divider,
  IconButton,
  Icon,
  FormGroup,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import ButtonUpload from "components/ButtonUpload";
import { TextFieldInForm, DateInForm } from "components/Form";
import CardImage from "components/CardImage";
import PreviewImage from "assets/previewImage.png";
import { getCompany, updateCompany } from "services/setting";
import Model from "models/company";
import SnackbarUtils from "utils/SnackbarUtils";
import fileReader from "utils/fileReader";
import { permissionName } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  tabPanel: { width: "100%", margin: "0 20px" },
  button: { textTransform: "none" },
}));

const CompanyProfile = (props) => {
  const translate = useTranslate();
  const { permissions, value, index } = props;

  const checkPermission = permissions?.find((i) => i.Name === "Sys.Company")?.View;
  const [isBtnLoading, setBtnLoading] = useState(false);
  const classes = useStyles();
  const [mode, setMode] = useState("view");
  const [data, setData] = useStateWithCallbackLazy(Model);
  const methods = useForm({ defaultValues: data });

  const { handleSubmit, reset } = methods;

  function useForceUpdate() {
    const [value, setState] = useState(true);
    return () => setState(!value);
  }

  const forceUpdate = useForceUpdate();

  const fetch = async () => {
    const response = await getCompany();
    if (response) {
      response.mergeHotelAddress =
        response.HotelAdd1 && response.HotelAdd1 !== ""
          ? `${response.HotelAdd1 ?? ""}\n${response.HotelAdd2 ?? ""}\n${response.HotelAdd3 ?? ""}`
          : "";
      response.mergeRegAddress =
        response.RegAdd1 && response.RegAdd1 !== ""
          ? `${response.RegAdd1 ?? ""}\n${response.RegAdd2 ?? ""}\n${response.RegAdd3 ?? ""}`
          : "";

      if (!response.BranchInfo) {
        response.BranchInfo = [
          {
            id: 1,
            BranchID: response.BranchNo,
            Address: response.mergeRegAddress,
            Active: true,
          },
        ];
      }

      if (typeof response.BranchInfo === "string") {
        response.BranchInfo = JSON.parse(response.BranchInfo);
      }

      setData(response);
      reset(response);
      setBtnLoading(false);
    } else {
      setData();
      setBtnLoading(false);
    }
  };

  React.useEffect(() => {
    if (value === index) {
      fetch();
    }
  }, [value]);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async (values) => {
    if (data.BranchInfo) {
      const newBrachD = data.BranchInfo.find((i) => i.Active);
      values.mergeRegAddress = newBrachD.Address;
      values.BranchNo = newBrachD.BranchID;
      values.BranchInfo = JSON.stringify(data.BranchInfo);
    }

    setBtnLoading(true);
    values.Logo = data.Logo;
    const vnHotelAddress = values.mergeHotelAddress ? values?.mergeHotelAddress.split("\n") : "";
    const vnRegAddress = values.mergeRegAddress ? values?.mergeRegAddress?.split("\n") : "";
    //adjust address
    values.HotelAdd1 = vnHotelAddress ? vnHotelAddress[0] : "";
    values.HotelAdd2 = vnHotelAddress ? vnHotelAddress[1] : "";
    values.HotelAdd3 = vnHotelAddress ? vnHotelAddress[2] : "";

    values.RegAdd1 = vnRegAddress ? vnRegAddress[0] : "";
    values.RegAdd2 = vnRegAddress ? vnRegAddress[1] : "";
    values.RegAdd3 = vnRegAddress ? vnRegAddress[2] : "";

    const { Code, UserMessage } = await updateCompany(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
      setMode("view");
      fetch();
    } else {
      SnackbarUtils.warning(UserMessage);
      setBtnLoading(false);
    }
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
      disabled: mode !== "view",
    },
  ];

  const CancelFnc = () => {
    fetch();
    setMode("view");
  };

  const UploadImg = (e) => {
    if (e.target.files[0]) {
      const msg = fileReader.CheckLimitImgSize(e.target.files);
      if (msg) {
        document.getElementById("company_img").value = "";
        SnackbarUtils.error(translate(msg, { size: "5" }));
        return;
      }
      const reader = new FileReader();
      reader.onloadend = (readerEvent) => {
        const image = new Image();
        image.onload = function (imageEvent) {
          // Resize the image
          const canvas = document.createElement("canvas");
          const max_size = 320;
          let width = image.width;
          let height = image.height;
          if (width > height) {
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
          } else {
            if (height > max_size) {
              width *= max_size / height;
              height = max_size;
            }
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext("2d").drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL("image/png");
          const base64String = dataUrl.replace("data:", "").replace(/^.+,/, "");
          setData((state) => ({
            ...state,
            Logo: base64String,
          }));
        };
        image.src = readerEvent.target.result;
        // const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        // setData((state) => ({
        //   ...state,
        //   Logo: base64String,
        // }));
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const HelperText = (props) => {
    if (props.rule?.maxLength) {
      var countCharacter;
      if (props.idx !== "") {
        countCharacter = `${props.dataIndex?.length ?? 0}/${props.rule?.maxLength.value}`;
      } else {
        countCharacter = `0/${props.rule?.maxLength.value}`;
      }
    }

    return (
      <span className={classes.helperTextContainer}>
        <span>{props.msg ? props.msg.message : ""}</span>
        <span>{countCharacter ? countCharacter : ""}</span>
      </span>
    );
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {checkPermission && value === index ? (
        <>
          <ActionMenu
            justifyContent="flex-end"
            menuControl={menuControlProp}
            permission={permissions.find((i) => i.Name === permissionName["Sys.Company"])}
          />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} style={{ textAlign: "center", marginTop: 20 }}>
                {data?.Logo ? (
                  <CardImage base64Src={data.Logo} noBorder={true} />
                ) : (
                  <CardImage imgSrc={PreviewImage} noBorder={true} customSize={{ height: 200 }} />
                )}
              </Grid>
              {mode !== "view" && (
                <Grid item xs={12} md={12} style={{ textAlign: "center", marginTop: "1vh" }}>
                  <ButtonUpload
                    id="company_img"
                    name="company_img"
                    style={{ display: "none" }}
                    type="file"
                    onChange={UploadImg}
                    accept="image/png, image/gif, image/jpeg"
                    btntext="Change Image"
                  />
                </Grid>
              )}
            </Grid>
            <Box p={1}>
              <Typography variant="h6">{translate("ra.module.Hotel Information")}</Typography>
            </Box>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={6}>
                <TextFieldInForm
                  label={translate("ra.field.Hotel Name")}
                  name="HotelName"
                  variant="outlined"
                  margin="dense"
                  methods={methods}
                  disabled={mode === "view"}
                  rule={{
                    maxLength: {
                      value: 255,
                      message: "maximun length is 255",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldInForm
                  label={translate("ra.field.Telephone")}
                  name="HotelTel"
                  variant="outlined"
                  margin="dense"
                  methods={methods}
                  disabled={mode === "view"}
                  rule={{
                    maxLength: {
                      value: 20,
                      message: "maximun length is 20",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldInForm
                  label={translate("ra.field.Address")}
                  name="mergeHotelAddress"
                  variant="outlined"
                  margin="dense"
                  methods={methods}
                  disabled={mode === "view"}
                  multiline
                  rows={5}
                  rule={{
                    maxLength: {
                      value: 255,
                      message: "maximun length is 255",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldInForm
                  label={translate("ra.field.Email")}
                  name="HotelEmail"
                  variant="outlined"
                  margin="dense"
                  methods={methods}
                  disabled={mode === "view"}
                  rule={{
                    maxLength: {
                      value: 60,
                      message: "maximun length is 60",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Box p={1}>
              <Typography variant="h6">{translate("ra.module.Company Information")}</Typography>
            </Box>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12} md={6}>
                <TextFieldInForm
                  label={translate("ra.field.Register Name")}
                  name="RegName"
                  variant="outlined"
                  margin="dense"
                  methods={methods}
                  disabled={mode === "view"}
                  rule={{
                    maxLength: {
                      value: 255,
                      message: "maximun length is 255",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldInForm
                  label={translate("ra.field.Telephone")}
                  name="RegTel"
                  variant="outlined"
                  margin="dense"
                  methods={methods}
                  // InputProps={{
                  //   readOnly: mode === "view",
                  // }}
                  disabled={mode === "view"}
                  rule={{
                    maxLength: {
                      value: 20,
                      message: "maximun length is 20",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldInForm
                  label={translate("ra.field.Company Register ID")}
                  name="RegId"
                  variant="outlined"
                  margin="dense"
                  methods={methods}
                  // InputProps={{
                  //   readOnly: mode === "view",
                  // }}
                  disabled={mode === "view"}
                  rule={{
                    maxLength: {
                      value: 20,
                      message: "maximun length is 20",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateInForm
                  label={translate("ra.field.Register Date")}
                  name="RegDate"
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <TextFieldInForm
                  label={translate("Fax")}
                  name="ContactFax"
                  variant="outlined"
                  margin="dense"
                  methods={methods}
                  // InputProps={{
                  //   readOnly: mode === "view",
                  // }}
                  disabled={mode === "view"}
                  rule={{
                    maxLength: {
                      value: 20,
                      message: "maximun length is 20",
                    },
                  }}
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <TextFieldInForm
                  label={translate("ra.field.TAX ID.")}
                  name="RegTaxId"
                  variant="outlined"
                  margin="dense"
                  methods={methods}
                  disabled={mode === "view"}
                  rule={{
                    maxLength: {
                      value: 30,
                      message: "maximun length is 30",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldInForm
                  label={translate("ra.field.Email")}
                  name="RegEmail"
                  variant="outlined"
                  margin="dense"
                  methods={methods}
                  // InputProps={{
                  //   readOnly: mode === "view",
                  // }}
                  disabled={mode === "view"}
                  rule={{
                    maxLength: {
                      value: 60,
                      message: "maximun length is 60",
                    },
                  }}
                />
              </Grid>
            </Grid>

            {data.BranchInfo?.map((item, idx) => (
              <div key={idx}>
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="center"
                  spacing={1}
                  style={{ marginBottom: 20 }}
                >
                  <Grid item xs={1} md={1} style={{ display: "flex", justifyContent: "center" }}>
                    <IconButton
                      component="span"
                      style={{ padding: 6 }}
                      onClick={() => {
                        data.BranchInfo.push({
                          id: data.BranchInfo.length + 1,
                          BranchID: "",
                          Address: "",
                          Active: false,
                        });
                        setData((state) => ({
                          ...state,
                          BranchInfo: data.BranchInfo,
                        }));
                      }}
                      disabled={mode === "view"}
                    >
                      <Icon>add</Icon>
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      fullWidth
                      label={translate("ra.field.Branch ID")}
                      //name="BranchNo"
                      variant="outlined"
                      margin="dense"
                      //methods={methods}
                      value={item.BranchID}
                      onChange={(e) => {
                        data.BranchInfo[idx].BranchID = e.target.value;
                        setData((state) => ({
                          ...state,
                          BranchInfo: [...data.BranchInfo],
                        }));
                      }}
                      disabled={mode === "view"}
                      inputProps={{
                        maxLength: 20,
                      }}
                      helperText={
                        <HelperText
                          dataIndex={data.BranchInfo[idx].BranchID}
                          msg={"maximun length is 20"}
                          rule={{
                            maxLength: {
                              value: 20,
                              message: "maximun length is 20",
                            },
                          }}
                        />
                      }
                    />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={item.Active ? true : false}
                            onChange={(e) => {
                              data.BranchInfo.forEach((i) => (i.Active = false));
                              item.Active = e.target.checked;
                              setData((state) => ({
                                ...state,
                                BranchInfo: [...data.BranchInfo],
                              }));
                              forceUpdate();
                            }}
                            disabled={mode === "view"}
                          />
                        }
                        label={item.Active ? "Use as default" : "Not used"}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      fullWidth
                      label={translate("ra.field.Address")}
                      //name="mergeRegAddress"
                      variant="outlined"
                      margin="dense"
                      //methods={methods}
                      value={item.Address}
                      onChange={(e) => {
                        data.BranchInfo[idx].Address = e.target.value;
                        setData((state) => ({
                          ...state,
                          BranchInfo: [...data.BranchInfo],
                        }));
                      }}
                      disabled={mode === "view"}
                      multiline
                      rows={5}
                      inputProps={{
                        maxLength: 255,
                      }}
                      helperText={
                        <HelperText
                          dataIndex={data.BranchInfo[idx].Address}
                          msg={"maximun length is 255"}
                          rule={{
                            maxLength: {
                              value: 255,
                              message: "maximun length is 255",
                            },
                          }}
                        />
                      }
                    />
                  </Grid>
                  {idx === 0 ? (
                    <Grid item xs={1} md={1} />
                  ) : (
                    <Grid item xs={1} md={1} style={{ display: "flex", justifyContent: "flex-start" }}>
                      <IconButton
                        component="span"
                        style={{ padding: 6 }}
                        onClick={() => {
                          data.BranchInfo.splice(idx, 1);
                          setData((state) => ({
                            ...state,
                            BranchInfo: data.BranchInfo,
                          }));
                        }}
                        disabled={mode === "view"}
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </div>
            ))}

            {mode !== "view" && <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />}
          </form>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default CompanyProfile;
