import React, { useState } from "react";
import { Avatar, Link, Button, CssBaseline, TextField, Box, Container, Typography } from "@material-ui/core";
import { makeStyles, ThemeProvider, createTheme } from "@material-ui/core/styles";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import authProviders from "providers/auth";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
}));

const ResetPassword = ({ theme }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [sentStatus, setSentStatus] = useState(false);
  const [username, setUsername] = useState("");
  const [adminToken, setAdminToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  // const [showRePassword, setShowRePassword] = useState(false);
  const [showError, setShowError] = useState({
    letter: false,
    capital: false,
    number: false,
    length: false,
  });

  React.useEffect(() => {
    let u = localStorage.getItem("UserName");
    let a = localStorage.getItem("adminToken");
    if (u) {
      setUsername(u);
      setAdminToken(a);
    }
  }, []);

  const ResetPwdFnc = async (e) => {
    e.preventDefault();
    setLoading(true);
    let s = localStorage.getItem("SelectTenant");
    const r = await authProviders.getForgotPasswordCode(adminToken, username, s);
    if (r?.Code === 0) {
      SnackbarUtils.success(r.UserMessage, function () {
        setLoading(false);
        setSentStatus(true);
      });
    } else {
      setLoading(false);
      setSentStatus(false);
    }
  };

  const UpdatePassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (showError.capital && showError.letter && showError.length && showError.number) {
      let param = {
        UserName: username,
        NewPassword: newPassword,
        AuthenticationCode: verificationCode,
      };
      authProviders.resetPassword(adminToken, param).then((r) => {
        if (r?.Code === 0) {
          SnackbarUtils.success(`<b>Password changed.</b><br>You can now sign in with your new password`, function () {
            let url = new URL(window.location.origin);
            window.location.replace(url + "#/login");
          });
        } else {
          setLoading(false);
        }
      });
    }

    setLoading(false);
  };

  const keyUpPassword = (e) => {
    var letter = document.getElementById("letter");
    var capital = document.getElementById("capital");
    var number = document.getElementById("number");
    var length = document.getElementById("length");
    // Validate lowercase letters
    var lowerCaseLetters = /[a-z]/g;

    if (e.target.value.match(lowerCaseLetters)) {
      letter.classList.remove("invalid");
      letter.classList.add("valid");
      setShowError((state) => ({
        ...state,
        letter: true,
      }));
    } else {
      letter.classList.remove("valid");
      letter.classList.add("invalid");
      setShowError((state) => ({
        ...state,
        letter: false,
      }));
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if (e.target.value.match(upperCaseLetters)) {
      capital.classList.remove("invalid");
      capital.classList.add("valid");
      setShowError((state) => ({
        ...state,
        capital: true,
      }));
    } else {
      capital.classList.remove("valid");
      capital.classList.add("invalid");
      setShowError((state) => ({
        ...state,
        capital: false,
      }));
    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if (e.target.value.match(numbers)) {
      number.classList.remove("invalid");
      number.classList.add("valid");
      setShowError((state) => ({
        ...state,
        number: true,
      }));
    } else {
      number.classList.remove("valid");
      number.classList.add("invalid");
      setShowError((state) => ({
        ...state,
        number: false,
      }));
    }

    // Validate length
    if (e.target.value.length >= 8) {
      length.classList.remove("invalid");
      length.classList.add("valid");
      setShowError((state) => ({
        ...state,
        length: true,
      }));
    } else {
      setShowError((state) => ({
        ...state,
        length: false,
      }));
      length.classList.remove("valid");
      length.classList.add("invalid");
    }

    setNewPassword(e.target.value);
  };

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOpenIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <form
            className={classes.form}
            autoComplete="new-password"
            onSubmit={!sentStatus ? ResetPwdFnc : UpdatePassword}
          >
            {!sentStatus && (
              <div className={classes.root}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={"Username"}
                  name="LoginUsername"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  autoFocus
                  autoComplete="off"
                />
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                  >
                    {loading && <CircularProgress className={classes.circularProgress} size={20} />}
                    send otp to email
                  </Button>
                </Box>
              </div>
            )}
            {sentStatus && (
              <div className={classes.root}>
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  type="text"
                  label="Verification Code"
                  name="verificationCode"
                  value={verificationCode}
                  autoComplete="off"
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  id="NewPassword"
                  label="New Password"
                  name="newPassword"
                  autoComplete="off"
                  onKeyUp={(e) => keyUpPassword(e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ marginRight: 6 }}
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                  >
                    {loading && <CircularProgress className={classes.circularProgress} size={20} />}
                    reset password
                  </Button>
                </Box>
                <div id="message">
                  <h3>Password must contain the following:</h3>
                  <p id="letter" className="invalid">
                    A <b>lowercase</b> letter
                  </p>
                  <p id="capital" className="invalid">
                    A <b>capital (uppercase)</b> letter
                  </p>
                  <p id="number" className="invalid">
                    A <b>number</b>
                  </p>
                  <p id="length" className="invalid">
                    Minimum <b>8 characters</b>
                  </p>
                </div>
                <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(showError) : ""}</pre>
                <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(newPassword) : ""}</pre>
                <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(verificationCode) : ""}</pre>
              </div>
            )}
            <Link href="/#/login" variant="body2">
              Back to Sign In
            </Link>
          </form>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPassword;
