/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Loading, useTranslate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import TrialBalance from "./TrialBalance";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = withStyles({
  root: {
    textTransform: "none",
  },
})((props) => <Tab {...props} disabled={props.label === "Account Detail"} />);

const Report = (props) => {
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);

  const [valueOfTab, setValueOfTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setLoading(false);
    setValueOfTab(newValue);
  };

  if (loading) return <Loading style={{ marginTop: 50 }} />;

  return (
    <div style={{ marginBottom: 50 }}>
      <Tabs
        value={valueOfTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        style={{ marginBottom: 12, border: "1px solid #e0e0e3", borderRadius: "10px" }}
      >
        <StyledTab label={translate("ra.module.Trial Balance")} {...a11yProps(0)} />
      </Tabs>
      <TabPanel value={valueOfTab} index={0}>
        <TrialBalance basePath={props.basePath} handleChangeTab={handleChangeTab} translate={translate} />
      </TabPanel>
    </div>
  );
};

export default Report;
