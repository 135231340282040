/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useForm } from "react-hook-form";
import { useRedirect, useTranslate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Tabs, Tab } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";

import { createArProfileDetail } from "services/accountReceivable";
import Model from "models/arProfile";
import ModelDetail from "models/arContractDetail";
import SnackbarUtils from "utils/SnackbarUtils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = withStyles({
  root: {
    textTransform: "none",
  },
})((props) => <Tab {...props} />);

const Create = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const {
    basePath,
    //formFields,
    formFieldsInfo,
    formFieldsBilling,
  } = props;
  const redirect = useRedirect();

  const { settingAll, ToNumber } = useContext(GblContext);
  const { SettingSystem } = settingAll;
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [initData, setInitData] = useStateWithCallbackLazy(Model);
  const [initNewRow, setInitNewRow] = useStateWithCallbackLazy(ModelDetail);

  const [valueOfTab, setValueOfTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueOfTab(newValue);
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Delete", disabled: true },
    { name: "Copy", disabled: true },
    { name: "Print", disabled: true },
  ];

  const methods = useForm({ defaultValues: initData });

  const { handleSubmit, getValues, reset } = methods;

  //set default value
  useEffect(() => {
    //reset ContracDetail When Add
    initData.ContractDetail = [];
    initNewRow.CurCode = SettingSystem.DefaultCurrencyCode;
    initNewRow.CurRate = SettingSystem.DefaultCurrencyRate;
    setInitNewRow(initNewRow);
    const cloneModel = _.cloneDeep(initData);
    setInitData(cloneModel);
    reset(cloneModel);
  }, []);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async () => {
    const values = getValues();
    setBtnLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    //Adjust parameter before save
    setInitData(
      (state) => ({
        ...state,
        ...values,
        CurRate: ToNumber(values.CurRate),
      }),
      (nextState) => Save(nextState)
    );
  };

  const Save = async (values) => {
    //Validate & CheckDetail
    const { Code, InternalMessage, UserMessage } = await createArProfileDetail(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage, function () {
        redirect(`${InternalMessage}/show`);
      });
    }
    setBtnLoading(false);
  };

  const CancelFnc = () => {
    redirect("list", basePath);
  };

  return (
    <div>
      <ActionMenu menuControl={menuControlProp} />
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Paper className={classes.root}>
          <BoxHeader header={"A/R Profile"} />
          <Tabs
            value={valueOfTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            style={{
              marginTop: 20,
              marginBottom: 12,
              border: "1px solid #e0e0e3",
              borderRadius: "10px",
            }}
          >
            <StyledTab label={translate("ra.module.Information")} {...a11yProps(0)} />
          </Tabs>
          <TabPanel value={valueOfTab} index={0}>
            {/* Information */}
            <Grid container alignItems="flex-start" spacing={1} style={{ margin: "0 0 12px 0" }}>
              <Grid container item xs={12} sm={12} spacing={1}>
                {formFieldsInfo
                  ? formFieldsInfo.map((item, idx) => (
                      <Grid item xs={item.size} key={idx} style={item.style}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                            //onChange: UpdateForm,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
          </TabPanel>
        </Paper>
        {/* Billing */}
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                <Typography className={classes.heading}>{translate("ra.field.Billing")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container alignItems="flex-start" spacing={1} style={{ margin: "0 0 12px 0" }}>
                  {formFieldsBilling
                    ? formFieldsBilling.map((item, idx) => (
                        <Grid item xs={item.size} key={idx} style={item.style}>
                          {React.createElement(item.field.type, {
                            ...{
                              ...item.field.props,
                              methods,
                              key: item.field.props.name,
                              //onChange: UpdateForm,
                            },
                          })}
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(initData, 0, 2) : ""}</pre>
        <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />
      </form>
    </div>
  );
};

export default Create;
