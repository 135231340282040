import React, { useContext, useState } from "react";
import { useTranslate } from "react-admin";
import html2canvas from "html2canvas";
import { GblContext } from "providers/formatter";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  Menu,
  MenuItem,
  Icon,
  IconButton,
} from "@material-ui/core";
import { List, ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import { Chart } from "react-google-charts";
import { makeStyles } from "@material-ui/core/styles";
import ConfigDashboard from "./Dialog/ConfigDashboard";
import SnackbarUtils from "utils/SnackbarUtils";
import { deleteWidgetById } from "services/callStoreProcedure";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  cardHeader: {
    padding: "10px 16px",
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  cardContent: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  txtRight: {
    textAlign: "right",
  },
  card: {
    minHeight: 52,
    display: "flex",
    flexDirection: "column",
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },

  main: () => ({
    overflow: "inherit",
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      color: theme.palette.type === "dark" ? "inherit" : "#dc2440",
    },
  }),
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
}));

const UsageChart = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [param, setParam] = useState({
    Id: props.Id,
    //date: props.date,
    name: props.name,
    Seq: props.Seq,
    TypeOfDate:
      new Date().setUTCHours(0, 0, 0, 0) > new Date(props.ToDate).setUTCHours(0, 0, 0, 0) ? "Custom" : props.TypeOfDate,
    FromDate: props.FromDate,
    ToDate: props.ToDate,
    Width: props.Width,
    configName: props.configName,
    Config: props.Config,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openWidgetMenu = Boolean(anchorEl);
  const { DateToString, NumberFormat } = useContext(GblContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!props) {
    return "";
  }

  const { name, data, rawData, dragHandle } = props;

  const updateChart = async (param) => {
    setLoading(true);
    let paramChart = {
      ...props,
      Id: param.Id,
      name: param.name,
      //date: param.date,
      Seq: parseInt(param.Seq),
      TypeOfDate: param.TypeOfDate,
      FromDate: param.FromDate,
      ToDate: param.ToDate,
      Width: param.Width,
      Config: param.Config,
    };
    // if (param.date !== "Invalid Date") {
    props.updateChart(paramChart);
    // }
  };

  const closeConfig = () => {
    setOpen(false);
  };

  function downloadBase64File(linkSource, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const SaveDashboardToImg = (id) => {
    const divBox = document.getElementById(id);
    html2canvas(divBox, {
      backgroundColor: "white",
      ignoreElements: (element) => {
        /* Remove element with id="MyElementIdHere" */
        if ("noPrint" === element.id) {
          return true;
        }
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      downloadBase64File(imgData, name);
    });
  };

  // const callReport = async () => {
  //   // let currentYear = new Date(param.date).getFullYear();
  //   // let currentMonth = new Date(param.date).getMonth() + 1;

  //   let deptList = param.Config.Department.Value.split(",");
  //   let accList = param.Config.Account.Value.split(",");

  //   let paramReport = {
  //     Id: 205,
  //     Type: "Report",
  //     FileFormat: "pdf",
  //     Parameters: [
  //       { Name: "DateFrom", Value: ToMySqlDate(param?.FromDate ?? new Date()) },
  //       { Name: "DateTo", Value: ToMySqlDate(param?.ToDate ?? new Date()) },
  //       { Name: "DeptCodeFrom", Value: deptList[0] },
  //       { Name: "DeptCodeTo", Value: deptList[deptList.length - 1] },
  //       { Name: "AccCodeFrom", Value: accList[0] },
  //       { Name: "AccCodeTo", Value: accList[accList.length - 1] },
  //       { Name: "AccType", Value: "A" },
  //       { Name: "AccStatus", Value: "All" },
  //       { Name: "GroupBy", Value: "None" },
  //     ],
  //   };

  //   //console.log(paramReport);

  //   let data = await showReportPDF(paramReport);
  //   var blob = new Blob([data], { type: "application/pdf" });
  //   const objUrl = window.URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = objUrl;
  //   link.target = "_blank";
  //   link.click();
  // };

  return (
    <>
      {loading ? (
        <div className={classes.circulLoading}>
          <CircularProgress />
        </div>
      ) : (
        <div id={name}>
          <Card className={classes.card} elevation={6}>
            <CardHeader
              className={classes.cardHeader}
              action={
                <div id="noPrint">
                  {/* <IconButton aria-label="print report TrialBalance" onClick={() => callReport()}>
                    <Icon>print</Icon>
                  </IconButton> */}
                  <IconButton aria-label="settings" onClick={() => SaveDashboardToImg(name)}>
                    <Icon>save_alt_icon</Icon>
                  </IconButton>
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      param.TypeOfDate = "YTD";
                      param.FromDate = new Date(`${new Date().getFullYear()}-01-01`);
                      param.ToDate = new Date();
                      updateChart(param);
                    }}
                  >
                    <Icon>refresh</Icon>
                  </IconButton>
                  <IconButton
                    id="basic-button"
                    aria-controls={openWidgetMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openWidgetMenu ? "true" : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <IconButton id="drag-button" style={{ cursor: "row-resize" }}>
                    {dragHandle}
                  </IconButton>
                  {open && (
                    <ConfigDashboard
                      open={open}
                      close={closeConfig}
                      updateChart={updateChart}
                      param={param}
                      updateParam={setParam}
                    />
                  )}
                </div>
              }
              title={
                <Typography variant="body1">
                  <b>{name}</b>
                </Typography>
              }
              subheader={
                <Typography variant="body2">
                  {DateToString(param.FromDate ?? new Date())} - {DateToString(param.ToDate ?? new Date())}
                </Typography>
              }
            />
            <Menu id="widget-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem
                key={1}
                onClick={() => {
                  setOpen(true);
                  setAnchorEl(null);
                }}
              >
                Edit Widget
              </MenuItem>
              <MenuItem
                key={2}
                onClick={() => {
                  let msg = "Are you sure delete this widget?";
                  SnackbarUtils.loadingConfirm(
                    msg,
                    async function () {
                      await deleteWidgetById(props.Id);
                      return props.Id;
                    },
                    async function (id) {
                      props.removeChart(id);
                    }
                  );
                  setAnchorEl(null);
                }}
              >
                Delete Widget
              </MenuItem>
            </Menu>
            <CardContent className={classes.cardContent}>
              {data ? (
                <Chart
                  width="100%"
                  height="300px"
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={data}
                  options={{
                    // pieHole: 0.3,
                    chartArea: { width: "100%" },
                    is3D: true,
                    // pieSliceText: "value",
                    // sliceVisibilityThreshold: 0.0001,
                    // tooltip: {
                    //   text: "value",
                    // },
                    backgroundColor: "white",
                  }}
                />
              ) : (
                <div>No Data</div>
              )}
            </CardContent>
            <List dense className={classes.root}>
              <ListItem>
                <ListItemText
                  style={{ textAlign: "left" }}
                  primary={<b>{rawData?.length > 0 && rawData[0].DimName}</b>}
                />
                <ListItemText
                  style={{ textAlign: "right", paddingRight: "5rem" }}
                  primary={<b>{translate("ra.field.Amount")}</b>}
                />
                <ListItemSecondaryAction>
                  <Typography variant="body2">
                    <b>{translate("ra.fieldAbbr.percent")}</b>
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              {rawData
                ? rawData.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <ListItem>
                        <ListItemText primary={`${item.DimValue}` ?? "Empty name"} />
                        <ListItemText
                          style={{ textAlign: "right", paddingRight: "5rem" }}
                          primary={NumberFormat(item.Amount)}
                        />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">{NumberFormat(item.Rate)} %</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))
                : ""}
              <ListItem>
                <ListItemText primary={<b>{translate("ra.field.Summary")}</b>} />
                <ListItemText
                  style={{
                    textAlign: "right",
                    paddingRight: "5rem",
                  }}
                  primary={<b>{NumberFormat(rawData ? rawData.reduce((a, c) => a + c.Amount, 0) : 0)}</b>}
                />
                <ListItemSecondaryAction>
                  <Typography variant="body2">
                    <b>{NumberFormat(rawData && rawData.reduce((a, c) => a + c.Rate, 0), "percent")} %</b>
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Card>
        </div>
      )}
    </>
  );
};

export default UsageChart;
