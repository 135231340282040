export default {
  InvhSeq: "Auto",
  InvhSeqAuto: true,
  InvhDate: new Date(),
  InvhDueDate: new Date(),
  InvhSource: "",
  CreditTerm: 0,
  InvNo: "Auto",
  InvPrefix: "",
  InvhTaxNo: "",
  TaxType: "None",
  ArNo: "",
  InvhType: "",
  InvhOwner: "",
  InvhDesc: "",
  CurrRate: 0,
  CurrCode: "",
  InvhTotal: 0,
  ProjectCode: "",
  InvhStatus: "Effective",
  ArContractHId: -1,
  UserModified: localStorage.getItem("UserName"),
  Detail: [],
  RunNoType: true,
  RunTaxType: false,
};
