import axiosAuth from "utils/request";

export async function closePeriodAp(closeDate) {
  const { data } = await axiosAuth.post(`/api/apProcedure/closePeriod?closeDate=${closeDate}`);
  return data;
}

export async function postReceiving(param, forceUpdate = false) {
  const { data } = await axiosAuth.post(`/api/interfaceBlueLedgers/postReceiving?forceUpdate=${forceUpdate}`, param);
  return data;
}
