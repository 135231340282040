/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { Loading, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Box, FormControl, Select, InputLabel, MenuItem } from "@material-ui/core";
import { getArFolioSearchList } from "services/accountReceivable";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MuiTranslateTable from "components/MuiTranslateTable";
import CustomTablePagination from "components/CustomTablePagination";
import DatePickerFormat from "components/DatePickerFormat";
import { addDays, startOfMonth } from "date-fns";
import ModelUriQueryString from "models/uriQueryString";
import RightSideDetail from "./RightSideDetail";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DialogFolioDetail from "./DialogFolio";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const List = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const { settingAll, DateToString, ToMySqlDate, NumberFormat, ToNumber } = useContext(GblContext);
  const { ClosePeriodAr } = settingAll.SettingClosePeriod;
  const [Data, setData] = useState();
  const [Paging, setPaging] = useState();
  const [uriQueryString, setUriQueryString] = useState({
    Limit: ModelUriQueryString.Limit,
    Page: ModelUriQueryString.Page,
    OrderBy: ModelUriQueryString.OrderBy,
    WhereGroupList: ModelUriQueryString.WhereGroupList,
    Exclude: ModelUriQueryString.Exclude,
    WhereRaw: ModelUriQueryString.WhereRaw,
    WhereLike: ModelUriQueryString.WhereLike,
    WhereLikeFields: ModelUriQueryString.WhereLikeFields,
  });
  const [searchText, setSearchText] = useState();
  const [loading, setLoading] = useState(true);
  const [optionDate, setOptionDate] = useState("Folio Date");
  const [openDetail, setOpenDetail] = useState(false);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState();
  const [status, setStatus] = useState("A");
  const [filterDate, setFilterDate] = useState({
    from: startOfMonth(addDays(new Date(ClosePeriodAr), 1)),
    to: new Date(),
  });

  const handleFilterList = () => {
    let fromMySqlDate = ToMySqlDate(filterDate.from);
    let toMySqlDate = ToMySqlDate(filterDate.to);

    switch (optionDate) {
      case "Folio Date":
        uriQueryString.WhereGroupList = [
          {
            AndOr: "And",
            ConditionList: [
              {
                AndOr: "And",
                Field: "FolioDate",
                Operator: ">=",
                Value: fromMySqlDate,
              },
              {
                AndOr: "And",
                Field: "FolioDate",
                Operator: "<=",
                Value: toMySqlDate,
              },
            ],
          },
        ];
        break;
      default:
        uriQueryString.WhereGroupList = [
          {
            AndOr: "And",
            ConditionList: [],
          },
        ];
        break;
    }

    uriQueryString.WhereLikeFields = [
      "PfmSeq",
      "FolioNo",
      "GuestNo",
      "FolioDate",
      "ArNo",
      "Description",
      "Remark",
      "Source",
    ];

    var condition = uriQueryString.WhereGroupList[0].ConditionList.find((item) => item.Field === "Selected");
    switch (status) {
      case "S":
        if (!condition) {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "Selected",
            Operator: "=",
            Value: true,
          });
        }
        break;
      case "U":
        if (!condition) {
          uriQueryString.WhereGroupList[0].ConditionList.push({
            AndOr: "And",
            Field: "Selected",
            Operator: "=",
            Value: false,
          });
        }
        break;
      default:
        break;
    }
  };

  const fetchSearchList = async (uriQueryString, mounted = true) => {
    setLoading(true);
    handleFilterList();
    uriQueryString.Exclude = ["UserModified"];
    const { Data, Paging } = await getArFolioSearchList(uriQueryString);
    if (Data) {
      setData(Data);
      setPaging(Paging);
      setUriQueryString(uriQueryString);
    } else {
      setData([]);
    }
    if (mounted) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchSearchList(uriQueryString, mounted);
  }, [uriQueryString, filterDate, optionDate, status]);

  const handleRequestSort = (property, order) => {
    uriQueryString.OrderBy = { [property]: order };
    fetchSearchList(uriQueryString);
  };

  if (loading) return <Loading />;
  if (!Data) return null;

  const handleChangePage = (e, newPage) => {
    uriQueryString.Page = newPage + 1;
    fetchSearchList(uriQueryString);
  };

  const handleChangeRowsPerPage = (e) => {
    let newRowsPerPage = parseInt(e.target.value);
    uriQueryString.Limit = newRowsPerPage;
    fetchSearchList(uriQueryString);
  };

  const handleTableChange = (action, tableState) => {
    if (action === "onSearchClose") {
      setSearchText();
      uriQueryString.WhereLike = "";
      fetchSearchList(uriQueryString);
    }
  };

  const columns = [
    {
      name: "PfmSeq",
      label: " ",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (Data[tableMeta.rowIndex].Selected) {
            return (
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDataDetail(Data[tableMeta.rowIndex]);
                  // setOpenDetail(true);
                  setOpenDialogDetail(!openDialogDetail);
                }}
              />
            );
          } else {
            return null;
          }
        },
      },
    },
    {
      name: "FolioDate",
      label: "Folio Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value)) : null;
        },
      },
    },
    {
      name: "FolioNo",
      label: "Folio No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "GuestNo",
      label: "Guest No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ArNo",
      label: "A/R No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Description",
      label: "Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Remark",
      label: "Remark",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "Qty",
    //   label: "Qty",
    //   options: {
    //     filter: true,
    //     sort: true,

    //     setCellHeaderProps: () => ({
    //       align: "right",
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         textAlign: "right",
    //       },
    //     }),
    //     customBodyRender: (value) => {
    //       return NumberFormat(value, "qty");
    //     },
    //   },
    // },
    // {
    //   name: "Unit",
    //   label: "Unit",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "Amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,

        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "TotalAmt",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "Unpaid",
      label: "Unpaid",
      options: {
        filter: true,
        sort: true,

        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "Source",
      label: "Source",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Selected",
      label: "Selected",
      options: {
        viewColumns: false,
        display: false,
        filter: false,
        sort: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    confirmFilters: true,
    searchText: searchText,
    searchProps: {
      onKeyUp: (e) => {
        if (e.keyCode === 13) {
          setSearchText(e.target.value);
          uriQueryString.WhereLike = `%${e.target.value}%`;
          uriQueryString.WhereLikeFields = [
            "PfmSeq",
            "FolioNo",
            "GuestNo",
            "DATE_FORMAT(FolioDate, '%d/%m/%Y')",
            "ArNo",
            "Description",
            "Remark",
            "Source",
          ];
          uriQueryString.Page = 1;
          fetchSearchList(uriQueryString);
        }
      },
    },
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
    },
    onTableChange: (action, tableState) => handleTableChange(action, tableState),
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    sortOrder: {
      name: Object.keys(uriQueryString.OrderBy)[0],
      direction: Object.values(uriQueryString.OrderBy)[0],
    },
    onColumnSortChange: (changedColumn, direction) => {
      handleRequestSort(changedColumn, direction);
    },
    customTableBodyFooterRender: function (opts) {
      const sumAmount = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[7]);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumTotal = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumUnpaid = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            <TableCell className={footerClasses} style={{ textAlign: "center" }} colSpan={7}>
              {translate("ra.field.Total")}
            </TableCell>
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                switch (col.name) {
                  case "Amount":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumAmount}
                      </TableCell>
                    );
                  case "TotalAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTotal}
                      </TableCell>
                    );
                  case "Unpaid":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumUnpaid}
                      </TableCell>
                    );
                  default:
                    return null;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    customFooter: () => {
      return (
        <CustomTablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={Paging?.TotalRecordCount ?? 0}
          rowsPerPage={Paging?.Limit ?? 15}
          page={Paging?.Page ? Paging.Page - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    },
  };

  const CustomFilterList = () => {
    return (
      <Box display="flex">
        <Box p={1}>
          <FormControl variant="outlined">
            <InputLabel id="viewMode">{translate("ra.field.ViewBy")}</InputLabel>
            <Select
              labelId="viewMode"
              label={translate("ra.field.ViewBy")}
              variant="outlined"
              margin="dense"
              value={optionDate}
              defaultValue={optionDate}
              onChange={(e) => {
                setOptionDate(e.target.value);
              }}
            >
              <MenuItem value={"All"}>{translate("ra.field.All")}</MenuItem>
              <MenuItem value={"Folio Date"}>{translate("ra.field.Folio Date")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {optionDate !== "All" && (
          <Box p={1}>
            <DatePickerFormat
              label={translate("ra.field.From")}
              value={filterDate.from}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  from: e,
                  to: e > filterDate.to ? e : filterDate.to,
                }));
              }}
              style={{ width: 160, margin: "0 10px" }}
            />
            <DatePickerFormat
              label={translate("ra.field.To")}
              value={filterDate.to}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  to: e,
                }));
              }}
              minDate={filterDate.from}
              minDateMessage={"Date must be more than from date"}
              style={{ width: 160, margin: "0 10px" }}
            />
          </Box>
        )}
        <Box p={1}>
          <FormControl variant="outlined">
            <InputLabel id="status">{translate("ra.field.Status")}</InputLabel>
            <Select
              variant="outlined"
              margin="dense"
              labelId="status"
              label={translate("ra.field.Status")}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              style={{ width: 200 }}
            >
              <MenuItem value={"A"}>{translate("ra.field.All")}</MenuItem>
              <MenuItem value={"S"}>{translate("ra.fieldAbbr.Selected Folio")}</MenuItem>
              <MenuItem value={"U"}>{translate("ra.fieldAbbr.Unselected Folio")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <MuiTranslateTable
        title={"A/R Folio"}
        data={Data}
        columns={columns}
        options={options}
        components={{
          TableFilterList: CustomFilterList,
        }}
      />
      {openDialogDetail && (
        <DialogFolioDetail open={openDialogDetail} onClose={() => setOpenDialogDetail(false)} data={dataDetail} />
      )}

      <RightSideDetail open={openDetail} onClose={() => setOpenDetail(false)} dataDetail={dataDetail} />
    </div>
  );
};

export default List;
