import React, { useContext, useState } from "react";
import { useTranslate, useLocale } from "react-admin";
import html2canvas from "html2canvas";
import { GblContext } from "providers/formatter";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Menu,
  MenuItem,
  Icon,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import ConfigDashboard from "./Dialog/ConfigDashboard";
import DisplayValuePercent from "components/DisplayValuePercent";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import { deleteWidgetById } from "services/callStoreProcedure";

const useStyles = makeStyles((theme) => ({
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
  cardHeader: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  cardContent: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  button: {
    textTransform: "none",
    borderRadius: 4,
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
  },
  selected: {
    color: "white !important",
    backgroundColor: `${theme.palette.primary.main}!important`,
  },
  divDept: {
    position: "relative",
    height: "20px",
    width: "140px",
  },
  divAcc: {
    position: "relative",
    height: "20px",
    width: "300px",
  },
  parentStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100%",
  },
  cellStyleEllipsis: {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const ListingChart = (props) => {
  const theme = useTheme();
  const [param, setParam] = useState({
    Id: props.Id,
    date: props.date,
    name: props.name,
    TypeOfDate:
      new Date().setUTCHours(0, 0, 0, 0) > new Date(props.ToDate).setUTCHours(0, 0, 0, 0) ? "Custom" : props.TypeOfDate,
    FromDate: props.FromDate,
    ToDate: props.ToDate,
    Seq: props.Seq,
    Width: props.Width,
    configName: props.configName,
    Config: props.Config,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openWidgetMenu = Boolean(anchorEl);
  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();
  const { DateToString, NumberFormat } = useContext(GblContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, data, dragHandle } = props;

  const updateChart = async (param) => {
    setLoading(true);
    let paramChart = {
      ...props,
      Id: param.Id,
      date: param.date,
      name: param.name,
      Seq: parseInt(param.Seq),
      TypeOfDate: param.TypeOfDate,
      FromDate: param.FromDate,
      ToDate: param.ToDate,
      Width: param.Width,
      Config: param.Config,
    };
    if (param.date !== "Invalid Date") {
      props.updateChart(paramChart);
    }
  };

  const closeConfig = () => {
    setOpen(false);
  };

  function downloadBase64File(linkSource, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const SaveDashboardToImg = (id) => {
    const divBox = document.getElementById(id);
    html2canvas(divBox, {
      backgroundColor: "white",
      ignoreElements: (element) => {
        /* Remove element with id="MyElementIdHere" */
        if ("noPrint" === element.id) {
          return true;
        }
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      downloadBase64File(imgData, name);
    });
  };

  const columns = [
    {
      label: translate("ra.field.Dept."),
      name: "DeptCode",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className={classes.divDept}>
              <div className={classes.parentStyle}>
                <HtmlTooltip
                  title={<React.Fragment>{tableMeta.tableData[tableMeta.rowIndex].DeptDesc}</React.Fragment>}
                  placement="top"
                >
                  <div className={classes.cellStyleEllipsis}>
                    {tableMeta.tableData[tableMeta.rowIndex].DeptDesc
                      ? `${value} : ${tableMeta.tableData[tableMeta.rowIndex].DeptDesc}`
                      : ""}
                  </div>
                </HtmlTooltip>
              </div>
            </div>
          );
        },
      },
    },
    {
      label: translate("ra.field.Acc."),
      name: "AccCode",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className={classes.divAcc}>
              <div className={classes.parentStyle}>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      {locale === "en-US"
                        ? tableMeta.tableData[tableMeta.rowIndex].AccDesc
                        : tableMeta.tableData[tableMeta.rowIndex].AccDescT}
                    </React.Fragment>
                  }
                  placement="top"
                >
                  <div className={classes.cellStyleEllipsis}>
                    {locale === "en-US"
                      ? tableMeta.tableData[tableMeta.rowIndex].AccDesc
                        ? `${value} : ${tableMeta.tableData[tableMeta.rowIndex].AccDesc}`
                        : ""
                      : tableMeta.tableData[tableMeta.rowIndex].AccDescT
                      ? `${value} : ${tableMeta.tableData[tableMeta.rowIndex].AccDescT}`
                      : ""}
                  </div>
                </HtmlTooltip>
              </div>
            </div>
          );
        },
      },
    },
    {
      label: translate("ra.module.Budget"),
      name: "BudgetAmt",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },

    {
      label: translate("ra.field.Actual"),
      name: "ActualAmt",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      label: translate("ra.field.Variance (%)"),
      name: "ActualRate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return <DisplayValuePercent value={value} />;
        },
      },
    },
    {
      label: translate("ra.field.Last Year"),
      name: "LastYearAmt",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
  ];

  const options = {
    elevation: 0,
    responsive: "standard",
    selectableRows: "none",
    //selectableRows: "multiple",
    serverSide: true,
    setTableProps: () => {
      return {
        background: theme.palette.type === "dark" ? "inherit" : "white",
        size: "small",
      };
    },
    viewColumns: false,
    download: false,
    filter: false,
    print: false,
    search: false,
    pagination: false,
    customToolbar: null,
  };

  if (!props.data) {
    return "";
  }

  return (
    <>
      {loading ? (
        <div className={classes.circulLoading}>
          <CircularProgress />
        </div>
      ) : (
        <div id={name}>
          <Card className={classes.card} elevation={6}>
            <CardHeader
              className={classes.cardHeader}
              action={
                <div id="noPrint">
                  <IconButton aria-label="settings" onClick={() => SaveDashboardToImg(name)}>
                    <Icon>save_alt_icon</Icon>
                  </IconButton>
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      param.TypeOfDate = "Yesterday";
                      param.FromDate = addDays(new Date(), -1);
                      param.ToDate = addDays(new Date(), -1);
                      updateChart(param);
                    }}
                  >
                    <Icon>refresh</Icon>
                  </IconButton>
                  <IconButton
                    id="basic-button"
                    aria-controls={openWidgetMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openWidgetMenu ? "true" : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <IconButton id="drag-button" style={{ cursor: "row-resize" }}>
                    {dragHandle}
                  </IconButton>
                  {open && (
                    <ConfigDashboard
                      open={open}
                      close={closeConfig}
                      updateChart={updateChart}
                      param={param}
                      updateParam={setParam}
                    />
                  )}
                </div>
              }
              title={
                <Typography variant="body1">
                  <b>{name}</b>
                </Typography>
              }
              subheader={
                <Typography variant="body2">
                  {DateToString(param.FromDate ?? new Date())} - {DateToString(param.ToDate ?? new Date())}
                </Typography>
              }
            />
            <Menu id="widget-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem
                key={1}
                onClick={() => {
                  setOpen(true);
                  setAnchorEl(null);
                }}
              >
                Edit Widget
              </MenuItem>
              <MenuItem
                key={2}
                onClick={() => {
                  let msg = "Are you sure delete this widget?";
                  SnackbarUtils.loadingConfirm(
                    msg,
                    async function () {
                      await deleteWidgetById(props.Id);
                      return props.Id;
                    },
                    async function (id) {
                      props.removeChart(id);
                    }
                  );
                  setAnchorEl(null);
                }}
              >
                Delete Widget
              </MenuItem>
            </Menu>
            <CardContent className={classes.cardContent} style={{ padding: 0 }}>
              <MUIDataTable data={data} columns={columns} options={options} />
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default ListingChart;
