import React, { useState, useEffect } from "react";
import { useLogin, useLocale, useSetLocale, useTranslate, Notification } from "react-admin";
import {
  Grid,
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Box,
  MenuItem,
  Container,
  Typography,
} from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, ThemeProvider, createTheme } from "@material-ui/core/styles";
import authProviders from "providers/auth";
import SnackbarUtils from "utils/SnackbarUtils";
import { Autocomplete } from "@material-ui/lab";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://carmensoftware.com/">
        Carmen Software Co.,Ltd.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
}));

const LoginPage = ({ theme }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(localStorage.getItem("UserName") ?? "");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [adminToken, setAdminToken] = useState(window.config.arrCompany[0]);
  const [tenant, setTenant] = useState();
  const [tenantList, setTenantList] = useState([]);
  const [userRight, setUserRight] = useState(false);
  const login = useLogin();
  const language = useLocale();

  const setLocale = useSetLocale();

  const languageList = [
    {
      value: "en-US",
      label: "English (United States)",
    },
    {
      value: "th-TH",
      label: "ไทย",
    },
    {
      value: "vi-VN",
      label: "Việt Nam",
    },
  ];

  const checkUserRight = (e) => {
    e.preventDefault();
    setLoading(true);
    authProviders
      .getTenantByUserName({
        Username: username,
      })
      .then((r) => {
        setTenantList(r);
        if (r) {
          let selectDefaultTenant = r.find((item) => item.IsDefault)?.Tenant;
          if (selectDefaultTenant) {
            localStorage.setItem("SelectTenant", selectDefaultTenant);
            setTenant(selectDefaultTenant);
          } else {
            localStorage.setItem("SelectTenant", r[0]?.Tenant);
            setTenant(r[0]?.Tenant);
          }
        }
        setTimeout(() => {
          setLoading(false);
          setUserRight(true);
        }, 1000);
      })
      .catch((e) => {
        SnackbarUtils.error(`${translate("ra.page.not_found")} ${translate("ra.field.User")}`);
        setLoading(false);
        setTenantList([]);
      });
  };

  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    setLocale(language);
    if (tenant) {
      login({
        Username: username,
        Password: password,
        Tenant: tenant,
        Language: language,
      }).catch((e) => {
        SnackbarUtils.error(e.message);
        setLoading(false);
      });
    } else {
      SnackbarUtils.warning("Please Select Business unit");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userLastSeenUpdateVersion") || localStorage.getItem("GlowCookies")) {
      localStorage.clear();
      localStorage.setItem("userLastSeenUpdateVersion", window.VersionList[0].Version);
      localStorage.setItem("GlowCookies", 1);
    } else {
      localStorage.clear();
    }
  }, []);

  const fetchGetVersion = async () => {
    await authProviders.getAPIVersion();
  };

  useEffect(() => {
    async function fetchDefaultTenant() {
      var username = localStorage.getItem("UserName");
      if (username != null) {
        setLoading(true);
        authProviders
          .getTenantByUserName({
            Username: username,
          })
          .then((r) => {
            setTenantList(r);
            if (r) {
              let selectDefaultTenant = r.find((item) => item.IsDefault)?.Tenant;
              setTenant(selectDefaultTenant);
            }
            setUserRight(true);
          })
          .catch((e) => {
            SnackbarUtils.error("Not found User");
          });
        setLoading(false);
      }
    }
    fetchDefaultTenant();
    fetchGetVersion();
  }, []);

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {translate("ra.auth.sign_in")}
          </Typography>
          <form
            className={classes.form}
            autoComplete="new-password"
            noValidate
            onSubmit={userRight ? signIn : checkUserRight}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={translate("ra.field.Username")}
              name="LoginUsername"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              onBlur={userRight ? checkUserRight : () => ""}
              autoFocus
              autoComplete="off"
            />
            {userRight ? (
              <>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="LoginPassword">{translate("ra.field.Password")}</InputLabel>
                  <OutlinedInput
                    variant="outlined"
                    required
                    type={showPassword ? "text" : "password"}
                    label={translate("ra.field.Password")}
                    name="LoginPassword"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  select
                  id="tenant"
                  label={translate("ra.field.Business Unit")}
                  value={tenant}
                  onChange={(e) => setTenant(e.target.value)}
                >
                  {tenantList.map((option) => (
                    <MenuItem key={option.TenantId} value={option.Tenant}>
                      {option.Description}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  select
                  id="language"
                  label={translate("ra.field.Language")}
                  value={language}
                  onChange={(e) => setLocale(e.target.value)}
                >
                  {languageList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Box display="flex" justifyContent="space-between">
                  <Button variant="text" color="primary" className={classes.submit} onClick={() => setUserRight(false)}>
                    <u>{translate("ra.action.back")}</u>
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    //fullWidth
                    className={classes.submit}
                    disabled={loading || tenantList.length === 0}
                  >
                    {loading && <CircularProgress className={classes.circularProgress} size={20} />}
                    {translate("ra.auth.sign_in")}
                  </Button>
                </Box>
              </>
            ) : (
              <Box display="flex" justifyContent="space-between">
                <Button
                  variant="text"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {
                    let url = new URL(window.location.origin);
                    window.location.replace(url);
                  }}
                >
                  <u>{translate("ra.action.back")}</u>
                </Button>
                <Button
                  type="submit"
                  //fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                >
                  {loading && <CircularProgress className={classes.circularProgress} size={20} />}
                  {translate("ra.navigation.next")}
                </Button>
              </Box>
            )}
            {userRight && (
              <Grid container>
                <Grid item xs>
                  <Link href="/#/resetpassword" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item />
              </Grid>
            )}
          </form>
          <Notification />
        </div>
        {window.config.env === "dev" && (
          <Box mt={8} justifyContent="center">
            {!userRight ? (
              <>
                <Typography variant="h5" align="center">
                  ห้ามเลือก
                </Typography>

                <Autocomplete
                  freeSolo
                  value={adminToken}
                  onChange={(e, newInputValue) => {
                    if (newInputValue) {
                      localStorage.setItem("adminToken", newInputValue.adminToken);
                      setAdminToken(newInputValue);
                      fetchGetVersion();
                    }
                  }}
                  getOptionLabel={(option) => {
                    return typeof option === "object" ? option.name : option;
                  }}
                  options={window.config.arrCompany}
                  renderInput={(params) => {
                    return (
                      <>
                        <TextField label="Company" {...params} variant="outlined" margin="dense" fullWidth />
                      </>
                    );
                  }}
                />
              </>
            ) : (
              ""
            )}
          </Box>
        )}
        <br />
        <Copyright />
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
