/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useRedirect } from "react-admin";
import { getAssetRegisterSearchList, getAstDepartment, getAstLocation, changeLocation } from "services/asset";
import { Tooltip, Icon, IconButton } from "@material-ui/core";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import PopupTable from "components/PopupTable";
import { TextFieldInForm, MuiAutosuggest } from "components/Form";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rootTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogCopyResult = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const redirect = useRedirect();
  const { onClose, open, basePath, adjustUriQueryString } = props;
  const [showAdd, setShowAdd] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [Data, setData] = useState();
  const [isLoading, setLoading] = useState(true);
  const [uriQueryString, setUriQueryString] = useState(adjustUriQueryString);
  const [lookupList, setLookupList] = useState({
    astDepartmentList: [],
    astLocationList: [],
  });

  const fetchSearchList = async (mounted) => {
    setLoading(true);
    uriQueryString.Exclude = [
      "Barcode",
      "CurRate",
      "Qty",
      "Salvage",
      "InitAccu",
      "TotalCost",
      "Life",
      "LifeType",
      "RemainDay",
      "RemainNet",
      "RemainTotalCost",
      "DimList",
      "RemainInfo",
    ];
    const { Data } = await getAssetRegisterSearchList(uriQueryString);
    if (Data) {
      setData(Data);
      setUriQueryString(uriQueryString);
      setLoading(false);
    } else {
      setData([]);
      setLoading(false);
    }
    if (mounted) {
      setLoading(false);
    }
  };

  const fetchAstDepartmentLookup = useCallback(async () => {
    const { Data } = await getAstDepartment();
    setLookupList((state) => ({
      ...state,
      astDepartmentList: Data,
    }));
  }, []);

  const fetchAstLocationLookup = useCallback(async () => {
    const { Data } = await getAstLocation();
    setLookupList((state) => ({
      ...state,
      astLocationList: Data,
    }));
  }, []);

  useEffect(() => {
    let mounted = true;
    fetchSearchList(mounted);
    fetchAstDepartmentLookup();
    fetchAstLocationLookup();
    return function cleanup() {
      mounted = false;
    };
  }, [fetchAstDepartmentLookup, fetchAstLocationLookup]);

  const columns = [
    {
      title: " ",
      field: "RegId",
      sorting: false,
      render: (rowData) => {
        return (
          <div style={{ display: "flex" }}>
            <a href={`#${basePath}/${rowData.RegId}/show`}>
              <IconButton
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onClose();
                  redirect("show", basePath, rowData.RegId);
                }}
              >
                <Icon>visibility_icon</Icon>
              </IconButton>
            </a>
            <Tooltip title={`Edit ${rowData.RegId}`}>
              <IconButton
                size="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => UpdateRow(rowData.RegId)}
              >
                <Icon>edit</Icon>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    { title: "Asset Id", field: "Id", sorting: true },
    { title: "Asset No.", field: "No", sorting: false },
    {
      title: "Department",
      field: "DepartmentCode",
      sorting: false,
      render: (rowData) => `${rowData.DepartmentCode} : ${rowData.AstDeptDesc}`,
    },
    {
      title: "Location",
      field: "LocationCode",
      sorting: false,
      render: (rowData) => `${rowData.LocationCode} : ${rowData.AstLocDesc}`,
    },
    { title: "Serial No.", field: "SerialNo", sorting: true },
    { title: "Specification", field: "Spec", sorting: false },
  ];

  const options = {
    headerStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF",
    },
    rowStyle: (item) => {
      if (item.tableData.id % 2) {
        return { backgroundColor: "#f2f2f2" };
      }
    },
    padding: "dense",
    paging: true,
    pageSize: 15,
    pageSizeOptions: [15, 50, 100],
    selection: false,
    selectionProps: () => ({
      color: "primary",
    }),
  };

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.rootTitle} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const handleCancel = () => {
    onClose();
  };

  const UpdateRow = (index) => {
    setEditIndex(index);
    setShowAdd(true);
  };

  const UpdateFromPopup = (currentField, m, data) => {
    m.setValue(currentField, data[currentField]);
    // if (currentField === "BfTaxAmt") {
    //   let TaxAmt = FindTaxAmount("Add", 7, data["BfTaxAmt"]);
    //   m.setValue("TaxAmt", TaxAmt);
    //   m.setValue("TotalAmt", ToNumber(data["BfTaxAmt"]) + ToNumber(TaxAmt));
    // }
    // if (currentField === "TaxAmt") {
    //   //let TaxAmt = FindTaxAmount("Add", data["TaxAmt"], data["BfTaxAmt"]);
    //   m.setValue("TotalAmt", ToNumber(data["BfTaxAmt"]) + ToNumber(data["TaxAmt"]));
    // }
  };

  const SaveFromPopup = async (arr, row) => {
    let param = {
      FromRegId: row.RegId,
      DepartmentCode: row.DepartmentCode,
      LocationCode: row.LocationCode,
      TransferDate: row.TransferDate,
      DepreDeptCode: row.DepreDeptCode,
      DepreAccCode: row.DepreAccCode,
      SerialNo: row.SerialNo,
      Spec: row.Spec,
      UserModified: localStorage.getItem("UserName"),
    };
    //update
    const { Code, InternalMessage, UserMessage } = await changeLocation(param);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage, function () {
        setShowAdd(false);
        fetchSearchList();
      });
    } else {
      SnackbarUtils.warning(InternalMessage, function () {
        setShowAdd(false);
        fetchSearchList();
      });
    }
  };

  const CancelFromPopup = () => {
    setShowAdd(false);
  };

  const formFieldsDetail = [
    {
      size: 10,
      field: <TextFieldInForm label="* Asset Id" name="Id" variant="outlined" margin="dense" disabled={true} />,
    },
    {
      size: 2,
      field: <TextFieldInForm label="* Asset No." name="No" variant="outlined" margin="dense" disabled={true} />,
    },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label="* Dept."
          name="DepartmentCode"
          optKey="DepartmentCode"
          optDesc="Description"
          options={lookupList["astDepartmentList"]}
          updateOtherField={[{ key: "AstDeptDesc", optKey: "Description" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      name: "AstDeptDesc",
      field: <TextFieldInForm label={"Dept. Description"} name="AstDeptDesc" disabled />,
    },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label="* Location"
          name="LocationCode"
          optKey="LocCode"
          optDesc="Description"
          options={lookupList["astLocationList"]}
          updateOtherField={[{ key: "AstLocDesc", optKey: "Description" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      name: "AstLocDesc",
      field: <TextFieldInForm label={"Location Description"} name="AstLocDesc" disabled />,
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label="Serial No."
          name="SerialNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label="Specification"
          name="Spec"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
  ];

  return (
    <Dialog fullWidth maxWidth="md" aria-labelledby="confirmation-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
        {props.title}
      </DialogTitle>
      <DialogContent dividers>
        <MaterialTable title="" data={Data} columns={columns} options={options} isLoading={isLoading} />
        {showAdd && (
          <PopupTable
            initialValues={Data.find((i) => i.RegId === editIndex)}
            formFields={formFieldsDetail}
            open={showAdd}
            update={UpdateFromPopup}
            save={(row) => SaveFromPopup(Data, row)}
            cancel={CancelFromPopup}
            maxWidth={"sm"}
          ></PopupTable>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialogCopyResult;
