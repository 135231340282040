export const glJv = {
  AJvhSeq: -1,
  JvhDate: new Date(),
  Prefix: "",
  AllocateAmt: 0,
  AllocateUnit: 0,
  Description: "",
  Detail: [],
  DimHList: {
    Dim: [],
  },
  UserModified: localStorage.getItem("UserName"),
};

export default glJv;
