export default {
  HotelName: "",
  HotelTel: "",
  HotelEmail: "",
  HotelAdd1: "",
  HotelAdd2: "",
  HotelAdd3: "",
  RegName: "",
  RegTel: "",
  RegEmail: "",
  RegAdd1: "",
  RegAdd2: "",
  RegAdd3: "",
  RegDate: new Date(),
  RegId: "",
  RegTaxId: "",
  BranchNo: "",
  BranchInfo: null,
  ContactName: "",
  ContactTel: "",
  ContactEmail: "",
  ContactFax: "",
  ContactModem: "",
  Logo: "",
  UserModified: localStorage.getItem("UserName"),
};
