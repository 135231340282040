import React from "react";
import MUIDataTable from "mui-datatables";
import { useTranslate } from "react-admin";

const MuiTranslateTable = (props) => {
  const translate = useTranslate();
  const notTranslateTitle = props.notTranslateTitle;
  props.columns.forEach((item) => (item.label = item.label !== "" ? translate(`ra.field.${item.label}`) : " "));
  return (
    <MUIDataTable
      {...props}
      title={notTranslateTitle ? props.title : props.title ? translate(`ra.module.${props.title}`) : ""}
    />
  );
};

export default MuiTranslateTable;
