import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import {
  TextFieldInForm,
  NumberFormatInForm,
  MuiAutosuggest,
  DateInForm,
  DescInForm,
  SelectInForm,
  CheckBoxInForm,
  SwitchInForm,
} from "components/Form";

import { getAccountCodeList, getDepartmentList, getArPaymentTypeList, getUnitList } from "services/setting";
import { getLookupCurrency } from "services/lookup";
import { getArTitleList, getArTypeList, getArProjectList, getArOwnerList } from "services/accountReceivable";

import List from "./List";
import Show from "./Show";
import Edit from "./Edit";
import Create from "./Create";
import { VatTypeOptions } from "utils/options";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 12,
    marginBottom: 12,
  },
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  borderTableTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  borderTable: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  pdLeft0: {
    paddingLeft: 0,
  },
  content: {
    padding: 4,
  },
  drawerOpen: {
    marginRight: drawerWidth,
  },
  drawerClose: {
    marginRight: 54,
  },
}));

const SwitchActionMode = (props) => {
  const locale = useLocale();
  const translate = useTranslate();
  const { authenticated } = useAuthState();
  const { settingAll, ToMySqlDate, ToNumber } = useContext(GblContext);
  const { SettingClosePeriod, SettingSystem } = settingAll;
  const { ClosePeriodAr } = SettingClosePeriod;
  const newClosePeriodAr = addDays(new Date(ClosePeriodAr), 1);
  const [oldAccList, setOldAccList] = useState([]);
  const addMode = props.location.pathname.search("create") !== -1;
  const [lookupList, setLookupList] = useState({
    accountCodeList: [],
    departmentList: [],
    currencyList: [],
    arTitleList: [],
    arTypeList: [],
    arProjectList: [],
    arOwnerList: [],
    arPaymentTypeList: [],
    unitList: [],
  });
  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList("Ar");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  }, []);
  const fetchCurrencyLookup = useCallback(async () => {
    const p = {
      Module: "AR",
      CurrDate: ToMySqlDate(new Date()),
    };
    const arr = await getLookupCurrency(p);
    setLookupList((state) => ({
      ...state,
      currencyList: arr,
    }));
  }, [ToMySqlDate]);
  const fetchPaymentTypeLookup = useCallback(async () => {
    const { Data } = await getArPaymentTypeList();
    setLookupList((state) => ({
      ...state,
      arPaymentTypeList: Data,
    }));
  }, []);
  const fetchArTitleLookup = useCallback(async () => {
    const { Data } = await getArTitleList();
    setLookupList((state) => ({
      ...state,
      arTitleList: Data,
    }));
  }, []);
  const fetchArTypeLookup = useCallback(async () => {
    const { Data } = await getArTypeList();
    setLookupList((state) => ({
      ...state,
      arTypeList: Data,
    }));
  }, []);
  const fetchArProjectLookup = useCallback(async () => {
    const { Data } = await getArProjectList();
    setLookupList((state) => ({
      ...state,
      arProjectList: Data,
    }));
  }, []);
  const fetchArOwnerLookup = useCallback(async () => {
    const { Data } = await getArOwnerList();
    setLookupList((state) => ({
      ...state,
      arOwnerList: Data,
    }));
  }, []);
  const fetchUnitLookup = useCallback(async () => {
    const { Data } = await getUnitList();
    setLookupList((state) => ({
      ...state,
      unitList: Data,
    }));
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchAccLookup();
      fetchDeptLookup();
      fetchCurrencyLookup();
      fetchPaymentTypeLookup();
      fetchArTitleLookup();
      fetchArTypeLookup();
      fetchArProjectLookup();
      fetchArOwnerLookup();
      fetchUnitLookup();
    }
  }, [
    authenticated,
    fetchAccLookup,
    fetchDeptLookup,
    fetchCurrencyLookup,
    fetchPaymentTypeLookup,
    fetchArTitleLookup,
    fetchArTypeLookup,
    fetchArProjectLookup,
    fetchArOwnerLookup,
    fetchUnitLookup,
  ]);

  const formFieldsInfo = [
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={`*${translate("ra.field.A/R No.")}`}
          name="ArNo"
          variant="outlined"
          margin="dense"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
            maxLength: {
              value: 20,
              message: "maximum length is 20",
            },
          }}
          disabled={!addMode}
        />
      ),
    },
    {
      size: 2,
      field: <DateInForm label={translate("ra.field.Register Date")} name="RegDate" required />,
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Title")}
          name="Title"
          optKey="ArTitleCode"
          optDesc="Description"
          options={lookupList["arTitleList"]}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Type")}`}
          name="ArType"
          optKey="ArTypeCode"
          optDesc="Description"
          options={lookupList["arTypeList"]}
          updateOtherField={[{ key: "ArTypeDesc", optKey: "Description" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "ArTypeDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="ArTypeDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      name: "Active",
      field: (
        <SwitchInForm
          name="Active"
          labelOn={translate("ra.fieldAbbr.active")}
          labelOff={translate("ra.fieldAbbr.inactive")}
          defaultChecked
        />
      ),
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.First Name")}
          name="FirstName"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Last Name")}
          name="LastName"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Company Name")}
          name="Company"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Position")}
          name="Position"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Telephone")}
          name="Tel"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Fax")}
          name="Fax"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.TAX ID.")}
          name="TaxNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Branch No.")}
          name="BranchNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 20,
              message: "maximum length is 20",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Email")}
          name="Email"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Remark")}
          name="Remark"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Address1")}
          name="AddressInfo.Address1"
          variant="outlined"
          margin="dense"
          multiline
          rows={5}
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Address2")}
          name="AddressInfo.Address2"
          variant="outlined"
          margin="dense"
          multiline
          rows={5}
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Address3")}
          name="AddressInfo.Address3"
          variant="outlined"
          margin="dense"
          multiline
          rows={5}
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
  ];

  const formFieldsBilling = [
    {
      size: 3,
      field: (
        <SelectInForm
          label={translate("ra.field.Billing To")}
          name="BillTo"
          options={["Address1", "Address2", "Address3"]}
        />
      ),
    },
    {
      size: 3,
      field: (
        <SelectInForm
          label={translate("ra.field.Mailing To")}
          name="MailTo"
          options={["Address1", "Address2", "Address3"]}
          defaultValue="Address1"
        />
      ),
    },
    {
      size: 3,
      field: (
        <SelectInForm
          label={translate("ra.field.Tax Invoice Address")}
          name="TaxTo"
          options={["Address1", "Address2", "Address3"]}
          defaultValue="Address1"
        />
      ),
    },
    {
      size: 3,
      field: <div />,
      implementation: "css",
      smDown: true,
      component: { Hidden },
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Payment Type")}`}
          name="PaymentCode"
          optKey="Code"
          optDesc="Desc"
          options={lookupList["arPaymentTypeList"]}
          updateOtherField={[{ key: "PaymentDesc", optKey: "Desc" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      name: "PaymentDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="PaymentDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Credit Limit")}
          name="CreditLimit"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
          rule={{
            max: 99999999999,
            maxLength: {
              value: 11,
              message: "maximum length is 11",
            },
          }}
        />
      ),
    },
    {
      size: 1,
      field: <div style={{ marginTop: 12 }}>THB</div>,
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Credit Term")}
          name="CreditTerm"
          decimal={0}
          rule={{
            max: 999,
            maxLength: {
              value: 3,
              message: "maximum length is 3",
            },
          }}
        />
      ),
    },
    {
      size: 1,
      field: <div style={{ marginTop: 12 }}>Day</div>,
    },
  ];

  const formFieldsDetail = [
    {
      size: 2,
      field: (
        <TextFieldInForm
          label={`*${translate("ra.field.Contract No.")}`}
          name="ContractNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={translate("ra.field.Start Contract")}
          name="ConStart"
          minDate={new Date(newClosePeriodAr)}
          minDateMessage={"Date must be more than close period"}
          //for custom advance update field
          useFncUpdate={true}
          fncUpdate={async (value, methods) => {
            const p = {
              Module: "AR",
              CurrDate: ToMySqlDate(value),
            };
            const newCurrList = await getLookupCurrency(p);
            const currentCurCode = methods.watch("CurCode");
            const currentCurRate = methods.watch("CurRate");
            const newCurrRate = newCurrList.find((i) => i.CurrCode === currentCurCode).CurrRate;
            if (newCurrRate !== ToNumber(currentCurRate)) {
              SnackbarUtils.confirm(translate("ra.question.currencyWarning"), function () {
                methods.setValue("CurRate", newCurrRate);
              });
            }
            setLookupList((state) => ({
              ...state,
              currencyList: newCurrList,
            }));
          }}
          required
        />
      ),
    },
    {
      size: 2,
      field: (
        <DateInForm
          label={translate("ra.field.End Contract")}
          name="ConEnd"
          minDate={new Date(newClosePeriodAr)}
          customMinDate={"ConStart"}
          minDateMessage={"Date must be more than close period"}
          clearable
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Charge Every Month")}
          name="PeriodicMonth"
          decimal={0}
          rule={{
            min: {
              value: 1,
              message: "* Charge Every 1 - 12 Month",
            },
            max: {
              value: 12,
              message: "* Charge Every 1 - 12 Month",
            },
            maxLength: 2,
          }}
        />
      ),
    },
    {
      size: 2,
      name: "Active",
      field: (
        <SwitchInForm
          name="Active"
          labelOn={translate("ra.fieldAbbr.active")}
          labelOff={translate("ra.fieldAbbr.inactive")}
          defaultChecked
        />
      ),
    },
    {
      size: 2,
      field: <div></div>,
      implementation: "css",
      smDown: true,
      component: { Hidden },
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Owner")}
          name="Owner"
          optKey="ArOwnerCode"
          optDesc="Description"
          options={lookupList["arOwnerList"]}
          updateOtherField={[{ key: "OwnerDesc", optKey: "Description" }]}
        />
      ),
    },
    {
      size: 2,
      name: "OwnerDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="OwnerDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Project")}
          name="ProjectCode"
          optKey="ArPrjCode"
          optDesc="Description"
          options={lookupList["arProjectList"]}
          updateOtherField={[{ key: "ProjectDesc", optKey: "Description" }]}
        />
      ),
    },
    {
      size: 2,
      name: "ProjectDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="ProjectDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Currency")}`}
          name="CurCode"
          optKey="CurrCode"
          optDesc="CurrRate"
          options={lookupList["currencyList"]}
          updateOtherField={[{ key: "CurRate", optKey: "CurrRate" }]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Rate")}`}
          name="CurRate"
          rule={{
            min: {
              value: 0.000001,
              message: "* Required",
            },
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.CurrencyRateDecimal}
          // decimalSep={SettingSystem.DecimalSeparator}
          // thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm label={translate("ra.field.Description")} name="ConHDesc" variant="outlined" margin="dense" />
      ),
    },
  ];

  const formFieldsContractDetail = [
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Department")}`}
          name="DeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "DeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeList: daccList,
              }));
              //remove acc if not in defaultaccount
              const drAcc = methods.getValues("DrAcc");
              const crAcc = methods.getValues("CrAcc");
              const crTax1Acc = methods.getValues("TaxAcc1");
              const crTax2Acc = methods.getValues("TaxAcc2");
              if (drAcc !== "" && !daccList.find((i) => i.AccCode === drAcc)) {
                methods.setValue("DrAcc", "");
                methods.setValue("DrAccDesc", "");
              }
              if (crAcc !== "" && !daccList.find((i) => i.AccCode === crAcc)) {
                methods.setValue("CrAcc", "");
                methods.setValue("CrAccDesc", "");
              }
              if (crTax1Acc !== "" && !daccList.find((i) => i.AccCode === crTax1Acc)) {
                methods.setValue("TaxAcc1", "");
                methods.setValue("TaxAcc1Desc", "");
              }
              if (crTax2Acc !== "" && !daccList.find((i) => i.AccCode === crTax2Acc)) {
                methods.setValue("TaxAcc2", "");
                methods.setValue("TaxAcc2Desc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeList: oldAccList,
              }));
            }
          }}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      name: "DeptDesc",
      field: (
        <DescInForm
          name="DeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm label={translate("ra.field.Comment")} name="ConDDesc" variant="outlined" margin="dense" />
      ),
    },
    {
      size: 4,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Unit")}`}
          name="Unit"
          optKey="UnitCode"
          optDesc="Description"
          options={lookupList["unitList"]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Qty")}`}
          name="Qty"
          rule={{
            min: {
              value: 1,
              message: "* Required",
            },
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.BaseQtyDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Price/Unit")}`}
          name="Price"
          rule={{
            min: {
              value: 1,
              message: "* Required",
            },
            required: {
              value: true,
              message: "* Required",
            },
          }}
          decimal={SettingSystem.CostPerUnitBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 4,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Dr Acc. Code")}`}
          name="DrAcc"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[
            {
              key: "DrAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          rule={{
            required: {
              value: true,
              message: "*Required",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      name: "DrAccDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="DrAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 4,
      name: "NetAmt",
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Amount")}
          name="NetAmt"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
          readOnly
        />
      ),
    },
    {
      size: 4,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Cr Acc. Code")}`}
          name="CrAcc"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[
            {
              key: "CrAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 4,
      name: "CrAccDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="CrAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 4,
      name: "TotalAmt",
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Total")}
          name="TotalAmt"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
          readOnly
        />
      ),
    },
  ];

  const formFieldsTax1 = [
    {
      size: 4,
      field: <SelectInForm label={`${translate("ra.field.Tax Type")}1`} name="TaxType1" options={VatTypeOptions} />,
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`${translate("ra.field.Tax Rate")}1`}
          name="TaxRate1"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },

    {
      size: 4,
      field: <CheckBoxInForm label={translate("ra.field.Overwrite")} name="TaxOverwrite1" />,
    },

    {
      size: 4,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Dr Acc. Code")}(${translate("ra.field.Tax")} 1)`}
          name="TaxAcc1"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[
            {
              key: "TaxAcc1Desc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
        />
      ),
    },
    {
      size: 4,
      name: "TaxAcc1Desc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="TaxAcc1Desc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`${translate("ra.field.Tax")}1`}
          name="TaxAmt1"
          rule={{
            required: {
              value: true,
              message: "*Required",
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
  ];
  const formFieldsTax2 = [
    {
      size: 4,
      field: <SelectInForm label={`${translate("ra.field.Tax Type")}2`} name="TaxType2" options={VatTypeOptions} />,
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`${translate("ra.field.Tax Rate")}2`}
          name="TaxRate2"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 4,
      field: <CheckBoxInForm label={translate("ra.field.Overwrite")} name="TaxOverwrite2" />,
    },
    {
      size: 4,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Dr Acc. Code")}(${translate("ra.field.Tax")} 2)`}
          name="TaxAcc2"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[
            {
              key: "TaxAcc2Desc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
        />
      ),
    },
    {
      size: 4,
      name: "TaxAcc2Desc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="TaxAcc2Desc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`${translate("ra.field.Tax")}2`}
          name="TaxAmt2"
          rule={{
            required: {
              value: true,
              message: "*Required",
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
  ];

  if (addMode) {
    return (
      <Create
        {...props}
        formFieldsInfo={formFieldsInfo}
        formFieldsBilling={formFieldsBilling}
        formFieldsDetail={formFieldsDetail}
        formFieldsContractDetail={formFieldsContractDetail}
        formFieldsTax1={formFieldsTax1}
        formFieldsTax2={formFieldsTax2}
        useStyles={useStyles}
      />
    );
  } else {
    return (
      <Edit
        {...props}
        formFieldsInfo={formFieldsInfo}
        formFieldsBilling={formFieldsBilling}
        formFieldsDetail={formFieldsDetail}
        formFieldsContractDetail={formFieldsContractDetail}
        formFieldsTax1={formFieldsTax1}
        formFieldsTax2={formFieldsTax2}
        useStyles={useStyles}
      />
    );
  }
};

const ViewMode = (props) => {
  return <Show {...props} useStyles={useStyles} />;
};

export default {
  list: List,
  show: ViewMode,
  edit: SwitchActionMode,
  create: SwitchActionMode,
};
