/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { Loading, Error } from "react-admin";
import { Grid, IconButton, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MuiTranslateTable from "components/MuiTranslateTable";
import TextTopInGrid from "components/TextTopInGrid";
import BoxHeader from "components/BoxHeader";
import { getArInvoiceDetail } from "services/accountReceivable";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 12,
    marginBottom: 12,
  },
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },

  borderTableTop: {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  borderTable: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  content: {
    padding: 4,
  },
  divComment: {
    position: "relative",
    height: "20px",
    width: "200px",
  },
  parentStyle: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100%",
  },
  cellStyleEllipsis: {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const DialogViewARInvoice = (props) => {
  const classes = useStyles();
  const { DateToString, NumberFormat, ToNumber } = useContext(GblContext);
  const { id, open, onClose } = props;

  const [data, setData] = useState();

  const [error] = useState();

  const fetchInvoiceById = useCallback(async () => {
    const response = await getArInvoiceDetail(id);
    if (response) {
      setData(response);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    fetchInvoiceById();
  }, [fetchInvoiceById]);

  const columns = [
    {
      name: "DeptCode",
      label: "Dept.",
      options: {
        sort: true,
      },
    },
    {
      name: "CrAcc",
      label: "Account #",
      options: {
        sort: true,
      },
    },
    {
      name: "InvdDesc",
      label: "Comment",
      options: {
        display: false,
      },
    },
    {
      name: "Unit",
      label: "Unit",
    },
    {
      name: "Qty",
      label: "Qty",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "qty");
        },
      },
    },
    {
      name: "Price",
      label: "Price/Unit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "unit");
        },
      },
    },
    {
      name: "NetAmt",
      label: "Net Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "TaxAmt1",
      label: "Tax 1",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "TaxAmt2",
      label: "Tax 2",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "TotalAmt",
      label: "Total",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "Unpaid",
      label: "Unpaid",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    responsive: "standard",
    selectableRows: "none",
    // serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "500px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      let sumNet = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[6]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumTax1 = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[7]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumTax2 = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumTotal = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumUnpaid = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[10]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                switch (col.name) {
                  case "NetAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumNet}
                      </TableCell>
                    );
                  case "TaxAmt1":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTax1}
                      </TableCell>
                    );
                  case "TaxAmt2":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTax2}
                      </TableCell>
                    );
                  case "TotalAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTotal}
                      </TableCell>
                    );
                  case "Unpaid":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumUnpaid}
                      </TableCell>
                    );
                  default:
                    return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (var i = removeArray.length - 1; i >= 0; i--) data.Detail.splice(removeArray[i], 1);
    },
  };

  if (error)
    return (
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
      >
        <Error />
      </Dialog>
    );

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "10px 24px" }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {"A/R Invoice"}
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />
        {data ? (
          <DialogContent>
            <BoxHeader header={`Invoice`} />
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 23 }}>
              <TextTopInGrid sizeSm={2} label="Invoice No." value={data.InvNo} />
              <TextTopInGrid sizeSm={2} label="Date" value={DateToString(data.InvhDate)} />
              <TextTopInGrid
                sizeSm={4}
                label="A/R No."
                value={data.ArNo ? `${data.ArNo} : ${data.Company ? data.Company : data.FirstName}` : ""}
              />
              <TextTopInGrid sizeSm={2} label="Currency" value={data.CurrCode} />
              <TextTopInGrid sizeSm={2} label="Rate" value={NumberFormat(data.CurrRate, "currency")} />
              <TextTopInGrid sizeSm={2} label="Prefix" value={data.InvPrefix} />
              <TextTopInGrid sizeSm={2} label="Tax Inv No." value={data.InvhTaxNo} />
              <TextTopInGrid sizeSm={2} label="Tax Status" value={data.TaxType} />
              <TextTopInGrid sizeSm={2} label="Due Date" value={DateToString(data.InvhDueDate)} />
              <Grid item xs={4}></Grid>
              <TextTopInGrid sizeSm={12} label="Description" value={data.InvhDesc} />
            </Grid>

            <MuiTranslateTable data={data.Detail} columns={columns} options={options} />
          </DialogContent>
        ) : (
          <DialogContent style={{ padding: 200 }}>
            <Loading />
          </DialogContent>
        )}
      </Dialog>

      {/* <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre> */}
    </>
  );
};

export default DialogViewARInvoice;
