/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { GblContext } from "providers/formatter";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Dialog, DialogActions, DialogContent, Button } from "@material-ui/core";
import { getArContractListByDate, applyContract } from "services/accountReceivable";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { MTableToolbar } from "material-table";
import MeterialtableTranslate from "components/MeterialtableTranslate";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DatePickerFormat from "components/DatePickerFormat";
import DialogARInvoiceList from "./DialogARInvoiceList";
import SnackbarUtils from "utils/SnackbarUtils";
import { useTranslate } from "react-admin";
import { endOfMonth } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rootTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
}));

const DialogARContractList = (props) => {
  const translate = useTranslate();
  const theme = useTheme();
  const classes = useStyles();
  const { ToMySqlDate, DateToString, NumberFormat } = useContext(GblContext);
  const { onClose, open } = props;
  const [selectedRow, setSelectedRow] = useState([]);

  const [filterDate, setFilterDate] = useState({
    from: new Date(),
    to: endOfMonth(new Date()),
  });

  const [data, setData] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [invoicePaging, setInvoicePaging] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const fetchSearchList = async (mounted) => {
    const fromMySqlDate = ToMySqlDate(filterDate.from);
    const toMySqlDate = ToMySqlDate(filterDate.to);
    const r = await getArContractListByDate(fromMySqlDate, toMySqlDate);
    if (r) {
      setData(r);
      setSelectedRow([]);
    } else {
      setData([]);
    }
    if (mounted) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchSearchList(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, [filterDate]);

  const handleSelect = (rows, selectedRows) => {
    //Select or UnSelect 1 Item
    if (selectedRows) {
      if (selectedRows.tableData.checked) {
        selectedRows.IsSelect = true;
      } else {
        selectedRows.IsSelect = false;
      }
      data[selectedRows.tableData.id] = selectedRows;
      setData([...data]);
    }
    //Select All
    if (rows.length > 0 && !selectedRows) {
      rows.forEach((item) => {
        item.IsSelect = true;
      });
      setData(rows);
    }
    //UnSelect All
    if (rows.length === 0 && !selectedRows) {
      data.forEach((item) => {
        item.IsSelect = false;
      });
      setData(data);
    }

    setSelectedRow(rows);
  };

  const columns = [
    {
      title: "",
      field: "IsSelect",
      hidden: true,
    },
    {
      title: "A/R No.",
      field: "ArNo",
      sorting: true,
    },
    { title: "Contract No.", field: "ContractNo", sorting: true },
    {
      title: "Currency",
      field: "CurCode",
      sorting: true,
    },
    {
      title: "Description",
      field: "ConHDesc",
      sorting: false,
      render: (rowData) => {
        if (rowData.tableData.checked) {
          return (
            <input
              style={{ width: "100%" }}
              type="text"
              value={rowData.ConHDesc}
              onChange={(e) => {
                rowData.ConHDesc = e.target.value;
                const newData = [...data];
                newData[rowData.tableData.id].ConHDesc = e.target.value;
                setData([...newData]);
              }}
            />
          );
        }

        return rowData.ConHDesc;
      },
      // editComponent: (props) => (
      //   <input type="text" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
      // ),
    },
    {
      title: "Start Contract",
      field: "ConStart",
      sorting: true,
      render: (rowData) =>
        rowData.ConStart ? <div style={{ width: 60 }}>{DateToString(rowData?.ConStart)}</div> : null,
    },
    {
      title: "End Contract",
      field: "ConEnd",
      sorting: true,
      render: (rowData) => (rowData.ConEnd ? <div style={{ width: 60 }}>{DateToString(rowData?.ConEnd)}</div> : null),
    },
    {
      title: "Amount",
      field: "TotalBaseAmount",
      type: "numeric",
      sorting: false,
      render: (rowData) => NumberFormat(rowData.TotalBaseAmount),
    },
    {
      title: "Period",
      field: "PeriodicMonth",
      sorting: false,
      width: "1%",
      align: "right",
    },
  ];

  const options = {
    draggable: false,
    headerStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF",
    },
    rowStyle: (item) => {
      if (item.tableData.id % 2) {
        return { backgroundColor: "#f2f2f2" };
      }
    },
    padding: "dense",
    paging: true,
    pageSize: 15,
    pageSizeOptions: [15, 50, 100],
    selection: true,
    search: true,
    selectionProps: () => ({
      color: "primary",
    }),
  };

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.rootTitle} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const CustomFilterList = (props) => {
    return (
      <>
        <MTableToolbar {...props} />
        <Box display="flex">
          <Box p={1}>
            <DatePickerFormat
              label={translate("ra.field.From")}
              value={filterDate.from}
              onChange={(e) => {
                setFilterDate((state) => ({
                  ...state,
                  from: e,
                  to: endOfMonth(e),
                }));
              }}
              style={{ width: 160, margin: "0 10px" }}
            />
          </Box>
          <Box p={1}>
            <DatePickerFormat
              label={translate("ra.field.To")}
              value={filterDate.to}
              disabled
              style={{ width: 160, margin: "0 10px" }}
            />
          </Box>
        </Box>
      </>
    );
  };

  //FIXME: wait for change dialog design

  const ConfirmToSave = () => {
    let selectedData = data.filter((item) => item.IsSelect);
    var checkPosted = data.find((item) => item.IsApplyInvoice === true);
    if (checkPosted) {
      let msg = "This period contract has already applied. Do you want to remove the existing contract invoices ?";
      let dialog = window.confirm(msg);
      if (dialog) {
        return selectedData;
      } else {
        return false;
      }
    }
    return selectedData;
  };

  const handleSave = async () => {
    let selectedData = ConfirmToSave();

    if (selectedData.length > 0) {
      let paramArContractList = [];
      selectedData.forEach((element) => {
        paramArContractList.push({
          ArContractHId: element.ArContractHId,
          ConHDesc: element.ConHDesc,
          Active: element.Active,
          ArNo: element.ArNo,
          AVPayment: element.AVPayment,
          ConDue: element.ConDue,
          ConEnd: element.ConEnd,
          ConhStatus: element.ConhStatus,
          ConLive: element.ConLive,
          ConNext: element.ConNext,
          ConStart: element.ConStart,
          ContractDate: element.ContractDate,
          ContractNo: element.ContractNo,
          CurCode: element.CurCode,
          CurRate: element.CurRate,
          Detail: element.Detail,
          DtlNo: element.DtlNo,
          IsApplyInvoice: element.IsApplyInvoice ?? false,
          IsVoid: element.IsVoid ?? false,
          LastDue: element.LastDue,
          LastInvSeq: element.LastInvSeq,
          Owner: element.Owner,
          ProjectCode: element.ProjectCode,
          PeriodicMonth: element.PeriodicMonth,
          Periodic: element.Periodic,
          RunNoType: element.RunNoType,
          RunTaxType: element.RunTaxType,
          TotalAmount: element.TotalAmount,
          TotalBaseAmount: element.TotalAmount * element.CurRate,
          UserModified: localStorage.getItem("UserName"),
        });
      });

      let param = {
        frDate: filterDate,
        ParamArContract: paramArContractList,
        UserModified: localStorage.getItem("UserName"),
      };
      const r = await applyContract(param);
      if (r.Data?.length > 0) {
        SnackbarUtils.success("Success");
        setInvoiceData(r.Data);
        setInvoicePaging(r.Paging);
        setOpenDialog(true);
      } else {
        SnackbarUtils.warning(r.UserMessage);
      }
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xl" aria-labelledby="confirmation-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
        {translate("ra.module.A/R Contract")}
      </DialogTitle>
      <DialogContent dividers>
        <MeterialtableTranslate
          title=""
          columns={columns}
          data={data}
          options={options}
          isLoading={isLoading}
          onSelectionChange={(rows, selectedRows) => handleSelect(rows, selectedRows)}
          components={{
            Toolbar: CustomFilterList,
          }}
        />
      </DialogContent>
      {selectedRow.length > 0 && (
        <DialogActions>
          <Button variant="contained" onClick={handleSave} color="primary">
            {translate("ra.module.Apply Contract")}
          </Button>
          <Button variant="outlined" className={classes.textCancel} onClick={handleCancel} color="primary">
            {translate("ra.action.cancel")}
          </Button>
        </DialogActions>
      )}
      {openDialog && (
        <DialogARInvoiceList
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          data={invoiceData}
          paging={invoicePaging}
        />
      )}
    </Dialog>
  );
};

export default DialogARContractList;
