import React from "react";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "react-admin";

const ButtonUpload = (props) => {
  const translate = useTranslate();
  return (
    <>
      <label htmlFor={props.id}>
        <input {...props}></input>
        <Button variant="outlined" component="span">
          {props.btntext ? props.btntext : "Choose Files"}
        </Button>
      </label>
      {props.fileUpload && (
        <>
          <span style={{ margin: "0 10px" }}>{props.fileUpload}</span>{" "}
          <Button style={{ marginRight: 10 }} variant="outlined" color="default" onClick={props.cancelUpload}>
            <Icon>delete</Icon>
          </Button>
        </>
      )}
      {!props.noCaption && (
        <Typography variant="caption" component="div" style={{ margin: "6px 0 6px 0" }}>
          {translate("ra.info.maxSizeUpload", { size: "5" })}
        </Typography>
      )}
    </>
  );
};

export default ButtonUpload;
