import React, { useState, useEffect, useCallback } from "react";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import { MuiAutosuggest, DescInForm } from "components/Form";
import { getAccountCodeList, getDepartmentList } from "services/setting";
import List from "./List";
import Show from "./Show";
import Edit from "./Edit";
import Create from "./Create";
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 12,
    marginBottom: 12,
  },
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  pdrTextFooter: {
    paddingRight: "10px !important",
  },
  flexRightSide: {
    display: "flex",
    justifyContent: "flex-end",
  },
  drawerOpen: {
    marginRight: drawerWidth,
  },
  drawerClose: {
    marginRight: 54,
  },
}));

const SwitchActionMode = (props) => {
  const locale = useLocale();
  const translate = useTranslate();
  const { authenticated } = useAuthState();
  const addMode = props.location.pathname.search("create") !== -1;
  const viewMode = props.location.pathname.search("show") !== -1;
  const [lookupList, setLookupList] = useState({
    accountCodeList: [],
    department: [],
  });
  const [oldAccList, setOldAccList] = useState([]);
  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList("Gl");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      department: Data,
    }));
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchAccLookup();
      fetchDeptLookup();
    }
  }, [authenticated, fetchAccLookup, fetchDeptLookup]);

  const labelList = {
    Description: translate("ra.fieldAbbr.desc"),
    Department: translate("ra.fieldAbbr.dept"),
    "Account #": translate("ra.fieldAbbr.account1"),
    Comment: translate("ra.fieldAbbr.comment"),
    Currency: translate("ra.fieldAbbr.currency"),
    Rate: translate("ra.fieldAbbr.rate"),
    Amount: translate("ra.fieldAbbr.amount"),
    Base: translate("ra.fieldAbbr.base"),
    "Dr.": translate("ra.fieldAbbr.dr"),
    "Cr.": translate("ra.fieldAbbr.cr"),
  };

  const formFieldsEdit = [
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`* ${labelList["Department"]}`}
          name="DeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["department"]}
          updateOtherField={[{ key: "DeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeList: daccList,
              }));
              //remove acc if not in defaultaccount
              let acc = methods.getValues("AccCode");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("AccCode", "");
                methods.setValue("AccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeList: oldAccList,
              }));
            }
          }}
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "DeptDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="DeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 2,
      field: (
        <MuiAutosuggest
          label={`* ${labelList["Account #"]}`}
          name="AccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[{ key: "AccDesc", optKey: locale === "en-US" ? "Description" : "Description2" }]}
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
        />
      ),
    },
    {
      size: 2,
      name: "AccDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="AccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  if (addMode) {
    return <Create {...props} formFields={formFieldsEdit} lookupList={lookupList} useStyles={useStyles} />;
  } else if (viewMode) {
    return <Show {...props} useStyles={useStyles} />;
  } else {
    return <Edit {...props} formFields={formFieldsEdit} lookupList={lookupList} useStyles={useStyles} />;
  }
};

export default {
  list: List,
  show: SwitchActionMode,
  edit: SwitchActionMode,
  create: SwitchActionMode,
};
