import React, { useContext, useState } from "react";
import { useLocale, useTranslate } from "react-admin";
import html2canvas from "html2canvas";
import { GblContext } from "providers/formatter";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Menu,
  MenuItem,
  Icon,
  IconButton,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import ConfigDashboard from "./Dialog/ConfigDashboard";
import DisplayValuePercent from "components/DisplayValuePercent";
import { useMediaQuery } from "@material-ui/core";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import { deleteWidgetById } from "services/callStoreProcedure";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  cardHeader: {
    padding: "10px 16px",
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  cardContent: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  txtRight: {
    textAlign: "right",
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
  option: {
    width: 500,
    fontSize: 14,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

const StackCardChart = (props) => {
  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();
  const [param, setParam] = useState({
    Id: props.Id,
    //date: props.date,
    name: props.name,
    TypeOfDate:
      new Date().setUTCHours(0, 0, 0, 0) > new Date(props.ToDate).setUTCHours(0, 0, 0, 0) ? "Custom" : props.TypeOfDate,
    FromDate: props.FromDate,
    ToDate: props.ToDate,
    Seq: props.Seq,
    Width: props.Width,
    configName: props.configName,
    Config: props.Config,
  });
  const { DateToString, NumberFormat } = useContext(GblContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openWidgetMenu = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  //const [accValue, setAccValue] = useState();
  const { name, data, dragHandle } = props;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const updateChart = async (param) => {
    setLoading(true);
    let paramChart = {
      ...props,
      Id: param.Id,
      name: param.name,
      //date: param.date,
      Seq: parseInt(param.Seq),
      TypeOfDate: param.TypeOfDate,
      FromDate: param.FromDate,
      ToDate: param.ToDate,
      Width: param.Width,
      Config: param.Config,
    };
    if (param.date !== "Invalid Date") {
      props.updateChart(paramChart);
    }
  };

  const closeConfig = () => {
    setOpen(false);
  };

  function downloadBase64File(linkSource, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const SaveDashboardToImg = (id) => {
    const divBox = document.getElementById(id);
    html2canvas(divBox, {
      backgroundColor: "white",
      ignoreElements: (element) => {
        /* Remove element with id="MyElementIdHere" */
        if ("noPrint" === element.id) {
          return true;
        }
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      downloadBase64File(imgData, name);
    });
  };

  return (
    <>
      {loading ? (
        <div className={classes.circulLoading}>
          <CircularProgress />
        </div>
      ) : (
        <div id={name}>
          <Card elevation={6}>
            <CardHeader
              className={classes.cardHeader}
              action={
                <div id="noPrint">
                  <IconButton aria-label="settings" onClick={() => SaveDashboardToImg(name)}>
                    <Icon>save_alt_icon</Icon>
                  </IconButton>
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      param.TypeOfDate = "Yesterday";
                      param.FromDate = addDays(new Date(), -1);
                      param.ToDate = addDays(new Date(), -1);
                      updateChart(param);
                    }}
                  >
                    <Icon>refresh</Icon>
                  </IconButton>
                  <IconButton
                    id="basic-button"
                    aria-controls={openWidgetMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openWidgetMenu ? "true" : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <IconButton id="drag-button" style={{ cursor: "row-resize" }}>
                    {dragHandle}
                  </IconButton>
                  {open && (
                    <ConfigDashboard
                      open={open}
                      close={closeConfig}
                      updateChart={updateChart}
                      param={param}
                      updateParam={setParam}
                    />
                  )}
                </div>
              }
              title={
                <Typography variant="body1">
                  <b>{name}</b>
                </Typography>
              }
              subheader={
                <Typography variant="body2">
                  {DateToString(param.FromDate ?? new Date())} - {DateToString(param.ToDate ?? new Date())}
                </Typography>
              }
            />
            <Menu id="widget-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem
                key={1}
                onClick={() => {
                  setOpen(true);
                  setAnchorEl(null);
                }}
              >
                Edit Widget
              </MenuItem>
              <MenuItem
                key={2}
                onClick={() => {
                  let msg = "Are you sure delete this widget?";
                  SnackbarUtils.loadingConfirm(
                    msg,
                    async function () {
                      await deleteWidgetById(props.Id);
                      return props.Id;
                    },
                    async function (id) {
                      props.removeChart(id);
                    }
                  );
                  setAnchorEl(null);
                }}
              >
                Delete Widget
              </MenuItem>
            </Menu>
            <CardContent className={classes.cardContent} style={{ padding: 0 }}>
              <List dense className={classes.root}>
                {data && data?.length > 0 ? (
                  <ListItem>
                    <ListItemText style={{ paddingLeft: 60 }} primary={<b>{translate("ra.field.Account")}</b>} />
                    <ListItemText
                      style={{
                        textAlign: "right",
                        paddingRight: isXSmall ? "2rem" : "5rem",
                      }}
                      primary={
                        <Tooltip title="Total as of today" placement="top">
                          <div>
                            <Typography variant="body2">
                              <b>{translate("ra.field.Amount")}</b>
                            </Typography>
                          </div>
                        </Tooltip>
                      }
                    />
                    <ListItemSecondaryAction style={{ top: "48%" }}>
                      <Tooltip title="% Increase from yesterday" placement="top-end">
                        <div>
                          <Typography variant="body2">
                            <b>{translate("ra.field.Previous Day")}</b>
                          </Typography>
                        </div>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ) : (
                  ""
                )}
                <Divider />
                {data
                  ? data.map((item, idx) => (
                      <ListItem key={idx}>
                        <ListItemIcon style={{ minWidth: 30 }}>
                          <ImageIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${item.AccCode} : ${locale === "en-US" ? item.AccDesc : item.AccDescT}`} />
                        <ListItemText
                          style={{
                            textAlign: "right",
                            paddingRight: isXSmall ? "2rem" : "5rem",
                          }}
                          primary={NumberFormat(item.Amount)}
                        />
                        <ListItemSecondaryAction>
                          <DisplayValuePercent value={item.Percentage} />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  : ""}
              </List>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default StackCardChart;
