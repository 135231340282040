export default {
  Year: new Date().getFullYear(),
  DeptCode: "",
  AccCode: "",
  LastYearAmt: {
    Amt1: 0,
    Amt2: 0,
    Amt3: 0,
    Amt4: 0,
    Amt5: 0,
    Amt6: 0,
    Amt7: 0,
    Amt8: 0,
    Amt9: 0,
    Amt10: 0,
    Amt11: 0,
    Amt12: 0,
  },
  ThisYearAmt: {
    Amt1: 0,
    Amt2: 0,
    Amt3: 0,
    Amt4: 0,
    Amt5: 0,
    Amt6: 0,
    Amt7: 0,
    Amt8: 0,
    Amt9: 0,
    Amt10: 0,
    Amt11: 0,
    Amt12: 0,
  },
  NextYearAmt: {
    Amt1: 0,
    Amt2: 0,
    Amt3: 0,
    Amt4: 0,
    Amt5: 0,
    Amt6: 0,
    Amt7: 0,
    Amt8: 0,
    Amt9: 0,
    Amt10: 0,
    Amt11: 0,
    Amt12: 0,
  },
  Revisions: [
    {
      Revision: 1,
      Caption: "Revision 1",
      Amt1: 0,
      Amt2: 0,
      Amt3: 0,
      Amt4: 0,
      Amt5: 0,
      Amt6: 0,
      Amt7: 0,
      Amt8: 0,
      Amt9: 0,
      Amt10: 0,
      Amt11: 0,
      Amt12: 0,
    },
    {
      Revision: 2,
      Caption: "Revision 2",
      Amt1: 0,
      Amt2: 0,
      Amt3: 0,
      Amt4: 0,
      Amt5: 0,
      Amt6: 0,
      Amt7: 0,
      Amt8: 0,
      Amt9: 0,
      Amt10: 0,
      Amt11: 0,
      Amt12: 0,
    },
    {
      Revision: 3,
      Caption: "Revision 3",
      Amt1: 0,
      Amt2: 0,
      Amt3: 0,
      Amt4: 0,
      Amt5: 0,
      Amt6: 0,
      Amt7: 0,
      Amt8: 0,
      Amt9: 0,
      Amt10: 0,
      Amt11: 0,
      Amt12: 0,
    },
    {
      Revision: 4,
      Caption: "Revision 4",
      Amt1: 0,
      Amt2: 0,
      Amt3: 0,
      Amt4: 0,
      Amt5: 0,
      Amt6: 0,
      Amt7: 0,
      Amt8: 0,
      Amt9: 0,
      Amt10: 0,
      Amt11: 0,
      Amt12: 0,
    },
  ],
  DimList: {
    Dim: [],
  },
  UserModified: localStorage.getItem("UserName"),
};
