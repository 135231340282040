/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Grid, Switch, Divider, Typography } from "@material-ui/core";
import { TextFieldInForm } from "components/Form";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import {
	getCurrencyDetail,
	createCurrencyDetail,
	updateCurrencyDetail,
	delCurrencyDetail,
} from "services/setting";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: 20,
	},
	appBar: {
		position: "relative",
		backgroundColor: theme.palette.primary.main,
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	content: {
		padding: 4,
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

const DialogTitle = (props) => {
	const { children, onClose, ...other } = props;
	const classes = useStyles();
	return (
		<MuiDialogTitle disableTypography {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
};

const DialogItem = (props) => {
	const translate = useTranslate();
	const classes = useStyles();
	const { children, id, mode, setMode, open, onClose } = props;
	const [isBtnLoading, setBtnLoading] = React.useState(false);
	const [data, setData] = useStateWithCallbackLazy({
		Active: true,
		Country: "",
		CurCode: "",
		CurRate: 1,
		SubUnitStr: "",
		UnitStr: "",
		UserModified: localStorage.getItem("UserName"),
	});

	const methods = useForm({ defaultValues: data });

	const { handleSubmit, reset, control } = methods;

	const fetchDetailById = useCallback(async () => {
		if (id && id !== 0) {
			const response = await getCurrencyDetail(id);
			if (response) {
				setData(response);
				reset(response);
			}
		} else {
			const Model = {
				Active: true,
				Country: "",
				CurCode: "",
				CurRate: 1,
				SubUnitStr: "",
				UnitStr: "",
				UserModified: localStorage.getItem("UserName"),
			};
			setData(Model);
			reset(Model);
		}
		setBtnLoading(false);
	}, [id, reset]);

	useEffect(() => {
		fetchDetailById();
	}, [fetchDetailById]);

	const disableFormEnter = (e) => {
		if (e.key === "Enter" && e.target.localName !== "textarea")
			e.preventDefault();
	};

	const onSubmit = (values) => {
		setBtnLoading(true);
		//Adjust parameter before save
		setData(
			(state) => ({
				...state,
				...values,
				UserModified: localStorage.getItem("UserName"),
			}),
			(nextState) => Save(nextState),
		);
	};

	const Save = async (values) => {
		if (mode === "edit") {
			values.Id = id;
			//Update
			const { Code, UserMessage, InternalMessage } = await updateCurrencyDetail(
				values,
			);
			if (Code === 0) {
				SnackbarUtils.success(UserMessage, function () {
					handleClose(id);
				});
				setBtnLoading(false);
			} else {
				setBtnLoading(false);
				if (InternalMessage) {
					SnackbarUtils.error(InternalMessage);
				} else {
					SnackbarUtils.warning(UserMessage);
				}
			}
		} else {
			const { Code, UserMessage, InternalMessage } = await createCurrencyDetail(
				values,
			);
			if (Code === 0) {
				SnackbarUtils.success(UserMessage, function () {
					handleClose(parseInt(InternalMessage));
				});
				setBtnLoading(false);
			} else {
				setBtnLoading(false);
				if (InternalMessage) {
					SnackbarUtils.error(InternalMessage);
				} else {
					SnackbarUtils.warning(UserMessage);
				}
			}
		}
	};

	const handleClose = (value) => {
		onClose(value);
	};

	const menuControlProp = [
		{
			name: "Edit",
			fnc: () => {
				setMode("edit");
			},
			disabled: mode !== "view",
		},
		{
			name: "Delete",
			fnc: () => DelOrVoid(id),
			disabled: mode !== "view",
		},
	];

	const DelOrVoid = async (id) => {
		const msg = translate("ra.question.confirmDel");
		SnackbarUtils.delConfirm(msg, async function () {
			const { Code, UserMessage } = await delCurrencyDetail(id);
			if (Code === 0) {
				SnackbarUtils.success(UserMessage, function () {
					handleClose(id);
				});
			}
		});
	};

	const CancelFnc = () => {
		if (id === 0) {
			onClose();
			return;
		}
		fetchDetailById();
		setMode("view");
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						onClose(event);
					}
				}}
				scroll={"paper"}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle id="scroll-dialog-title" onClose={onClose}>
					{props.title}
				</DialogTitle>
				<DialogContent dividers className={classes.content}>
					<ActionMenu
						menuControl={menuControlProp}
						justifyContent="flex-start"
					/>
					<Divider />
					<form onKeyDown={disableFormEnter}>
						<div className={classes.root}>
							<Grid container spacing={1} justifyContent="flex-start">
								<Grid item xs={6} elevation={2}>
									<TextFieldInForm
										label="* Code"
										name="CurCode"
										variant="outlined"
										margin="dense"
										methods={methods}
										disabled={mode !== "add"}
										rule={{
											required: {
												value: true,
												message: "* Required",
											},
											maxLength: {
												value: 26,
												message: "maximum length is 26",
											},
										}}
										autoFocus
									/>
								</Grid>
								<Grid item xs={6} elevation={2}>
									<FormControl fullWidth variant="outlined">
										<Controller
											name="Active"
											control={control}
											defaultValue=""
											render={(props) => {
												return (
													<FormControlLabel
														value={props.value}
														control={
															<Switch
																checked={
																	typeof props.value === "boolean"
																		? props.value
																		: false
																}
																onChange={(e, newValue) =>
																	props.onChange(newValue)
																}
																disabled={mode === "view"}
															/>
														}
														label={
															props.value
																? translate("ra.field.Active")
																: translate("ra.field.In-Active")
														}
														labelPlacement="start"
														color="primary"
													/>
												);
											}}
										/>
									</FormControl>
								</Grid>
								<Grid item xs={6} elevation={2}>
									<TextFieldInForm
										label="Currency"
										name="UnitStr"
										variant="outlined"
										margin="dense"
										methods={methods}
										disabled={mode === "view"}
										rule={{
											maxLength: {
												value: 10,
												message: "maximum length is 10",
											},
										}}
									/>
								</Grid>
								<Grid item xs={6} elevation={2}>
									<TextFieldInForm
										label="Decimal Currency"
										name="SubUnitStr"
										variant="outlined"
										margin="dense"
										methods={methods}
										disabled={mode === "view"}
										rule={{
											maxLength: {
												value: 10,
												message: "maximum length is 10",
											},
										}}
									/>
								</Grid>
							</Grid>
						</div>
						{children}
					</form>
					<pre>
						{process.env.NODE_ENV === "development"
							? JSON.stringify(data, 0, 2)
							: ""}
					</pre>
				</DialogContent>
				{mode !== "view" ? (
					<DialogActions>
						<ButtonFooter
							noBorder
							disabled={isBtnLoading}
							SaveFnc={handleSubmit(onSubmit)}
							CancelFnc={CancelFnc}
						/>
					</DialogActions>
				) : (
					""
				)}
			</Dialog>
		</div>
	);
};

export default DialogItem;
