/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { Tooltip } from "@material-ui/core";
import { Loading } from "react-admin";
import { GblContext } from "providers/formatter";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import CustomTablePagination from "components/CustomTablePagination";
import ModelUriQueryString from "models/uriQueryString";
import { getProcessLogList } from "services/setting";
import { convertDescModule } from "utils/options";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const Activity = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState();
  const [Paging, setPaging] = useState();
  const { DateToString } = useContext(GblContext);
  const [searchText, setSearchText] = useState();
  const [uriQueryString, setUriQueryString] = useState({
    Limit: ModelUriQueryString.Limit,
    Page: ModelUriQueryString.Page,
    OrderBy: ModelUriQueryString.OrderBy,
    WhereGroupList: ModelUriQueryString.WhereGroupList,
    Exclude: ModelUriQueryString.Exclude,
    WhereRaw: ModelUriQueryString.WhereRaw,
    WhereLike: ModelUriQueryString.WhereLike,
    WhereLikeFields: ModelUriQueryString.WhereLikeFields,
  });

  const handleFilterList = () => {
    let username = localStorage.getItem("UserName");
    // let whereRaw = `UserModified = '${username}' and Action != 'logout' and Module != 'logout'`;
    let whereGroupList = [
      {
        AndOr: "And",
        ConditionList: [
          {
            AndOr: "And",
            Field: "UserModified",
            Operator: "=",
            Value: username,
          },
        ],
      },
    ];
    uriQueryString.WhereGroupList = whereGroupList;
    uriQueryString.OrderBy = { LogDate: "desc" };
  };

  const fetchSearchList = async (uriQueryString, mounted = true) => {
    handleFilterList();
    const { Data, Paging } = await getProcessLogList(uriQueryString);
    if (Data) {
      setData(Data);
      setPaging(Paging);
      setUriQueryString(uriQueryString);
    } else {
      setData([]);
    }

    if (mounted) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchSearchList(uriQueryString, mounted);
    return function cleanup() {
      mounted = false;
    };
  }, [uriQueryString]);

  const handleRequestSort = (property, order) => {
    uriQueryString.OrderBy = { [property]: order };
    fetchSearchList(uriQueryString);
  };

  const handleChangePage = (e, newPage) => {
    uriQueryString.Page = newPage + 1;
    fetchSearchList(uriQueryString);
  };

  const handleChangeRowsPerPage = (e) => {
    let newRowsPerPage = parseInt(e.target.value);
    uriQueryString.Limit = newRowsPerPage;
    fetchSearchList(uriQueryString);
  };

  const handleTableChange = (action, tableState) => {
    if (action === "onSearchClose") {
      setSearchText();
      uriQueryString.WhereLike = "";
      fetchSearchList(uriQueryString);
    }
  };

  const columns = [
    // {
    //   name: "InvhSeq",
    //   label: " ",
    //   options: {
    //     filter: false,
    //     viewColumns: false,
    //     customBodyRender: (value) => {
    //       return (
    //         <a href={`#${basePath}/${value}/show`}>
    //           <VisibilityIcon
    //             fontSize="small"
    //             color="primary"
    //             style={{ cursor: "pointer" }}
    //             onClick={() => redirect("show", basePath, value)}
    //           />
    //         </a>
    //       );
    //     },
    //   },
    // },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const item = Data[tableMeta.rowIndex];

          if (item.Id) {
            if (typeof item.Id === "number") {
              const dataDicItem = convertDescModule(item.DataRef.Module);
              if (dataDicItem) {
                return <>{value + ` (${dataDicItem.desc})`}</>;
              } else {
                return <>{value + ` (${item.Module})`}</>;
              }
            } else {
              return value;
            }
          } else {
            return value;
          }
        },
      },
    },
    {
      name: "Description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const item = Data[tableMeta.rowIndex];

          if (item.DataRef?.Id) {
            if (typeof item.DataRef?.Id === "number") {
              const dataDicItem = convertDescModule(item.DataRef.Module);
              if (dataDicItem) {
                return (
                  <>
                    <>User</>
                    <span style={{ color: "#2196f3" }}>{` [${item.UserModified}] `}</span>
                    <>{`${item.Action} ${dataDicItem.desc} Id = `}</>
                    <Tooltip title={"click to see detail"}>
                      <a href={`#${dataDicItem.url}/${item.DataRef?.Id}/show`}>{item.DataRef?.Id}</a>
                    </Tooltip>
                  </>
                );
              } else {
                return (
                  <>
                    <>User</>
                    <span style={{ color: "#2196f3" }}>{` [${item.UserModified}] `}</span>
                    {value}
                  </>
                );
              }
            }
            return (
              <>
                <>User</>
                <span style={{ color: "#2196f3" }}>{` [${item.UserModified}] `}</span>
                {value}
              </>
            );
          } else {
            return (
              <>
                <>User</>
                <span style={{ color: "#2196f3" }}>{` [${item.UserModified}] `}</span>
                {item.Action}
              </>
            );
          }
        },
      },
    },
    {
      name: "LastModified",
      label: "Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value), "dd/MM/yyyy (HH:mm)") : null;
        },
      },
    },
  ];

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    confirmFilters: true,
    searchText: searchText,
    searchProps: {
      onKeyUp: (e) => {
        if (e.keyCode === 13) {
          setSearchText(e.target.value);
          uriQueryString.WhereLike = `%${e.target.value}%`;
          uriQueryString.Page = 1;
          fetchSearchList(uriQueryString);
        }
      },
    },
    onTableChange: (action, tableState) => handleTableChange(action, tableState),
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    sortOrder: {
      name: Object.keys(uriQueryString.OrderBy)[0],
      direction: Object.values(uriQueryString.OrderBy)[0],
    },
    onColumnSortChange: (changedColumn, direction) => {
      handleRequestSort(changedColumn, direction);
    },
    customFooter: () => {
      return (
        <CustomTablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={Paging?.TotalRecordCount ?? 0}
          rowsPerPage={Paging?.Limit ?? 15}
          page={Paging?.Page ? Paging.Page - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    },
  };

  if (loading) return <Loading />;
  if (!Data) return null;

  return (
    <div className={classes.root}>
      {/* <BoxHeader header={"Activity Log"} /> */}
      <MUIDataTable title={"Activity Log"} data={Data} columns={columns} options={options} />
    </div>
  );
};

export default Activity;
