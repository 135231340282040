import React, { useState, useEffect } from "react";
import { useAuthState, Loading, useTranslate, usePermissions } from "react-admin";
import {
  Grid,
  Container,
  IconButton,
  Icon,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Divider,
} from "@material-ui/core";
import ErrorDashboard from "layout/ErrorDashboard";
// import CardChart from "./CardChart";
import UsageChart from "./UsageChart";
import BudgetBarChart from "./BudgetBarChart";
import SectionCardChart from "./SectionCardChart";
import StackCardChart from "./StackCardChart";
import StackedBarChart from "./StackedBarChart";
import ListingChart from "./ListingChart";
import IncomeChart from "./IncomeChart";
import ComboChart from "./ComboChart";
import ConfigDashboard from "./Dialog/ConfigDashboard";
import ManageDashboard from "./Dialog/ManageDashboard";
import { getDashboard, getWidgetByDashboardId, deleteDashboard } from "services/callStoreProcedure";
import chartapi from "services/callStoreProcedure";
import { PeriodWithText } from "utils/options";
import SnackbarUtils from "utils/SnackbarUtils";
import { sortableContainer, sortableElement, sortableHandle, arrayMove } from "react-sortable-hoc";

const Dashboard = () => {
  const optionList = PeriodWithText();
  const translate = useTranslate();
  const { loaded, authenticated } = useAuthState();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [open, setOpen] = useState(false);
  const [modeManageDashboard, setModeManageDashboard] = useState("");
  const [openManageDashboard, setOpenManageDashboard] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [param, setParam] = useState({});
  const [dashboard, setDashboard] = useState();
  const [dashboardList, setDashboardList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenuDashboard = Boolean(anchorEl);
  const permissionDashboard = permissions?.find((i) => i.Name === "Sys.Dashboard");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function useForceUpdate() {
    const [value, setState] = useState(true);
    return () => setState(!value);
  }

  function upsert(array, item) {
    // (1)
    const i = array.findIndex((_item) => _item.Id === item.Id);
    if (i > -1) array[i] = item;
    else array.push(item);
  }

  const forceUpdate = useForceUpdate();

  const addChartInWidget = (w) => {
    const newWidgetsArr = [];
    let newobj = {};
    let idx = 0;
    if (w.length > 0) {
      w.forEach(async (element, index, array) => {
        switch (element.Type) {
          case "Statistical Highlight":
            newobj = await chartapi.getFrontSummary(element.FromDate, element.ToDate, element);
            newWidgetsArr.push(newobj);
            break;
          case "P&L Summary":
            newobj = await chartapi.getProfitSummary(element.FromDate, element);
            newWidgetsArr.push(newobj);
            break;
          case "Dimension Analysis":
            newobj = await chartapi.getDimensionAnalysis(element.FromDate, element.ToDate, element);
            newWidgetsArr.push(newobj);
            break;
          case "Annual":
            newobj = await chartapi.getYearcomparision(element.FromDate, element.ToDate, element);
            newWidgetsArr.push(newobj);
            break;
          case "Daily Monitor Account":
            newobj = await chartapi.getDaily(element.FromDate, element.ToDate, element);
            newWidgetsArr.push(newobj);
            break;
          case "ActualBudget":
            newobj = await chartapi.getActualbudget(element.FromDate, element.ToDate, element);
            newWidgetsArr.push(newobj);
            break;
          case "Payable":
            newobj = await chartapi.getPayable(null, element);
            newWidgetsArr.push(newobj);
            break;
          case "Receivable":
            newobj = await chartapi.getReceivable(null, element);
            newWidgetsArr.push(newobj);
            break;
          case "Income Statement":
            newobj = await chartapi.getIncomeStatement(null, element);
            newWidgetsArr.push(newobj);
            break;
          case "Budget Trend":
            newobj = await chartapi.getBudgetTrend(element.FromDate, element.ToDate, element);
            newWidgetsArr.push(newobj);
            break;
          default:
            break;
        }
        idx++;
        if (idx === array.length) {
          newWidgetsArr.sort((a, b) => a.Seq - b.Seq);
          setWidgets(newWidgetsArr);
          setLoading(false);
        }
      });
    } else {
      newWidgetsArr.sort((a, b) => a.Seq - b.Seq);
      setWidgets(newWidgetsArr);
      setLoading(false);
    }
  };

  const updateChart = async (param) => {
    let res, r;
    const newParam = {
      Id: param.Id,
      Name: param.name,
      Type: param.configName,
      Width: param.Width,
      Seq: parseInt(param.Seq),
      TypeOfDate: param.TypeOfDate,
      FromDate: param.FromDate,
      ToDate: param.ToDate,
      Config: param.Config,
    };
    //update & get newId
    switch (param.configName) {
      case "Statistical Highlight":
        r = await chartapi.updateWidgetByDashboardId(dashboard.Id, newParam);
        newParam.Id = r.data;
        res = await chartapi.getFrontSummary(param.FromDate, param.ToDate, newParam);
        break;
      case "P&L Summary":
        r = await chartapi.updateWidgetByDashboardId(dashboard.Id, newParam);
        newParam.Id = r.data;
        res = await chartapi.getProfitSummary(param.FromDate, newParam);
        break;
      case "Dimension Analysis":
        r = await chartapi.updateWidgetByDashboardId(dashboard.Id, newParam);
        newParam.Id = r.data;
        res = await chartapi.getDimensionAnalysis(param.FromDate, param.ToDate, newParam);
        break;
      case "Annual":
        r = await chartapi.updateWidgetByDashboardId(dashboard.Id, newParam);
        newParam.Id = r.data;
        res = await chartapi.getYearcomparision(param.FromDate, param.ToDate, newParam);
        break;
      case "Daily Monitor Account":
        r = await chartapi.updateWidgetByDashboardId(dashboard.Id, newParam);
        newParam.Id = r.data;
        res = await chartapi.getDaily(param.FromDate, param.ToDate, newParam);
        break;
      case "ActualBudget":
        r = await chartapi.updateWidgetByDashboardId(dashboard.Id, newParam);
        newParam.Id = r.data;
        res = await chartapi.getActualbudget(param.FromDate, param.ToDate, newParam);
        break;
      case "Payable":
        r = await chartapi.updateWidgetByDashboardId(dashboard.Id, newParam);
        newParam.Id = r.data;
        res = await chartapi.getPayable(param.FromDate, newParam);
        break;
      case "Receivable":
        r = await chartapi.updateWidgetByDashboardId(dashboard.Id, newParam);
        newParam.Id = r.data;
        res = await chartapi.getReceivable(param.FromDate, newParam);
        break;
      case "Income Statement":
        r = await chartapi.updateWidgetByDashboardId(dashboard.Id, newParam);
        newParam.Id = r.data;
        res = await chartapi.getIncomeStatement(param.FromDate, newParam);
        break;
      case "Budget Trend":
        r = await chartapi.updateWidgetByDashboardId(dashboard.Id, newParam);
        newParam.Id = r.data;
        res = await chartapi.getBudgetTrend(param.FromDate, param.ToDate, newParam);
        break;
      // no default
    }
    upsert(widgets, res);
    widgets.sort((a, b) => a.Seq - b.Seq);
    setWidgets(widgets);
    forceUpdate();
  };

  const removeChart = async (id) => {
    const indexOfObject = widgets.findIndex((object) => object.Id === id);
    widgets.splice(indexOfObject, 1);
    setWidgets(widgets);
    forceUpdate();
  };

  const fetchDashboard = async () => {
    const { data } = await getDashboard();
    let defaultDbIdCurrentState = "";
    if (data.length > 0) {
      if (data.filter((i) => i.IsDefault)?.length === 0) {
        setDashboard(data[0]);
        defaultDbIdCurrentState = data[0].Id;
      }
      data.forEach((element) => {
        if (element.IsDefault === true) {
          setDashboard(element);
          defaultDbIdCurrentState = element.Id;
        }
      });
    }

    setDashboardList(data);

    if (defaultDbIdCurrentState) {
      const r = await getWidgetByDashboardId(defaultDbIdCurrentState);
      //r.data.sort((a, b) => a.Seq - b.Seq);
      //setWidgets(r.data);
      addChartInWidget(r.data);
    } else {
      setWidgets([]);
      addChartInWidget([]);
    }
  };

  const fetchDashboardById = async (id) => {
    const { data } = await getDashboard();
    let defaultDbIdCurrentState = "";
    if (data.length > 0) {
      data.forEach((element) => {
        if (element.Id === id) {
          setDashboard(element);
          defaultDbIdCurrentState = element.Id;
        }
      });
    }

    setDashboardList(data);
    if (defaultDbIdCurrentState) {
      const r = await getWidgetByDashboardId(defaultDbIdCurrentState);
      //r.data.sort((a, b) => a.Seq - b.Seq);
      //setWidgets(r.data);
      addChartInWidget(r.data);
    } else {
      setWidgets([]);
      addChartInWidget([]);
    }
  };

  useEffect(() => {
    if (authenticated && loaded) {
      fetchDashboard();
    }
  }, [authenticated, loaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeConfig = () => {
    setOpen(false);
  };

  const closeManageDashboard = (id) => {
    if (typeof id !== "number") {
      setOpenManageDashboard(false);
      fetchDashboard();
    }

    if (id && typeof id === "number") {
      setOpenManageDashboard(false);
      fetchDashboardById(id);
    }
  };

  if (loading) return <Loading />;
  if (error) return <ErrorDashboard />;

  if (!permissionDashboard?.View) {
    return null;
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    widgets[oldIndex].Seq = newIndex;
    widgets[newIndex].Seq = oldIndex;
    const newPosWidgets = arrayMove(widgets, oldIndex, newIndex);
    setWidgets(newPosWidgets);
  };

  const DragHandle = sortableHandle(() => <Icon>drag_indicator</Icon>);

  const SortableItem = sortableElement((item) => {
    switch (item.configName) {
      case "Statistical Highlight":
      case "P&L Summary":
        return (
          <Grid item xs={12} sm={item.Width === "100" ? 12 : 6} key={item.Seq}>
            <SectionCardChart
              {...item}
              updateChart={updateChart}
              removeChart={removeChart}
              dragHandle={<DragHandle />}
            />
          </Grid>
        );
      case "Dimension Analysis":
        return (
          <Grid item xs={12} sm={item.Width === "100" ? 12 : 6} key={item.Seq}>
            <UsageChart {...item} updateChart={updateChart} removeChart={removeChart} dragHandle={<DragHandle />} />
          </Grid>
        );
      case "Annual":
        return (
          <Grid item xs={12} sm={item.Width === "100" ? 12 : 6} key={item.Seq}>
            <BudgetBarChart {...item} updateChart={updateChart} removeChart={removeChart} dragHandle={<DragHandle />} />
          </Grid>
        );
      case "Daily Monitor Account":
        return (
          <Grid item xs={12} sm={item.Width === "100" ? 12 : 6} key={item.Seq}>
            <StackCardChart {...item} updateChart={updateChart} removeChart={removeChart} dragHandle={<DragHandle />} />
          </Grid>
        );
      case "Payable":
      case "Receivable":
        return (
          <Grid item xs={12} sm={item.Width === "100" ? 12 : 6} key={item.Seq}>
            <StackedBarChart
              {...item}
              updateChart={updateChart}
              removeChart={removeChart}
              dragHandle={<DragHandle />}
            />
          </Grid>
        );
      case "ActualBudget":
        return (
          <Grid item xs={12} sm={item.Width === "100" ? 12 : 6} key={item.Seq}>
            <ListingChart {...item} updateChart={updateChart} removeChart={removeChart} dragHandle={<DragHandle />} />
          </Grid>
        );
      case "Income Statement":
        return (
          <Grid item xs={12} sm={item.Width === "100" ? 12 : 6} key={item.Seq}>
            <IncomeChart {...item} updateChart={updateChart} removeChart={removeChart} dragHandle={<DragHandle />} />
          </Grid>
        );
      case "Budget Trend":
        return (
          <Grid item xs={12} sm={item.Width === "100" ? 12 : 6} key={item.Seq}>
            <ComboChart {...item} updateChart={updateChart} removeChart={removeChart} dragHandle={<DragHandle />} />
          </Grid>
        );
      default:
        return <></>;
    }
  });

  const SortableList = sortableContainer(({ items }) => {
    return (
      <Grid container spacing={3}>
        {items?.map((item, idx) => (
          <SortableItem key={`item-${idx}`} index={idx} {...item} />
        ))}
      </Grid>
    );
  });
  return (
    <Container style={{ marginBottom: 100 }}>
      <FormControl variant="outlined" margin="dense" style={{ margin: "10px 0 10px 0" }}>
        <InputLabel id="demo-simple-select">{translate("ra.page.dashboard")}</InputLabel>
        <Select
          labelId="demo-simple-select"
          id="demo-simple-select"
          value={dashboard}
          defaultValue={dashboard}
          onClick={async (e) => {
            if (e.target.value) {
              setDashboard(e.target.value);
              const r = await getWidgetByDashboardId(e.target.value.Id);

              addChartInWidget(r.data);
            } else {
              e.preventDefault();
            }
          }}
          style={{ width: 200 }}
          label={translate("ra.page.dashboard")}
        >
          {dashboardList.map((item, idx) => (
            <MenuItem key={idx} value={item}>
              {item.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {permissionDashboard?.Add === true && (
        <IconButton aria-label="settings" aria-controls="dashboard-menu" onClick={handleClick} style={{ marginTop: 3 }}>
          <Icon>more_vert</Icon>
        </IconButton>
      )}
      <Menu id="dashboard-menu" anchorEl={anchorEl} open={openMenuDashboard} onClose={handleClose}>
        {permissionDashboard?.Add && (
          <MenuItem
            key={"opt0"}
            onClick={(e) => {
              setModeManageDashboard(e.target.innerText);
              setOpenManageDashboard(true);
              handleClose();
            }}
          >
            Create Dashboard
          </MenuItem>
        )}
        {permissionDashboard?.Update && dashboard?.Id && (
          <MenuItem
            key={"opt1"}
            onClick={(e) => {
              setModeManageDashboard(e.target.innerText);
              setOpenManageDashboard(true);
              handleClose();
            }}
          >
            Manage Dashboard
          </MenuItem>
        )}
        {permissionDashboard?.Delete && dashboard?.Id && (
          <MenuItem
            key={"opt2"}
            onClick={() => {
              const msg = "Are you sure delete this dashboard?";
              SnackbarUtils.loadingConfirm(
                msg,
                async function () {
                  await deleteDashboard(dashboard.Id);
                },
                async function () {
                  fetchDashboard();
                  setDashboard();
                }
              );
              setAnchorEl(null);
            }}
          >
            Delete Dashboard
          </MenuItem>
        )}
        {permissionDashboard?.Add && dashboard?.Id && <Divider />}
        {permissionDashboard?.Add && dashboard?.Id && (
          <MenuItem
            key={"opt3"}
            onClick={() => {
              setParam({
                Id: 0,
                TypeOfDate: "Yesterday",
                FromDate: optionList[1].start,
                ToDate: optionList[1].end,
                name: "",
                Seq: 0,
                Width: "100",
                configName: "",
                Config: {},
              });
              setOpen(true);
              handleClose();
            }}
          >
            Add Widget
          </MenuItem>
        )}
        {/* <MenuItem key={2} onClick={handleClose}>
          Edit Dashboard
        </MenuItem> */}
      </Menu>
      {open && (
        <ConfigDashboard
          open={open}
          close={closeConfig}
          updateChart={updateChart}
          param={param}
          updateParam={setParam}
        />
      )}
      {openManageDashboard && (
        <ManageDashboard
          open={openManageDashboard}
          close={(e) => closeManageDashboard(e)}
          modeManageDashboard={modeManageDashboard}
          dashboardId={dashboard?.Id ?? 0}
          userId={dashboard?.user_Id ?? 0}
        />
      )}
      <SortableList items={widgets} onSortEnd={(e) => onSortEnd(e)} useDragHandle />
    </Container>
  );
};

export default Dashboard;
