/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { GblContext } from "providers/formatter";
import { Loading, useRedirect, useTranslate } from "react-admin";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import clsx from "clsx";
import { Paper, Grid, Box, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { TableHead, TableFooter, TableRow, TableCell, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { TableHead as MuiTableHead } from "mui-datatables";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
import PopupTable from "components/PopupTable";
import { addDays, format } from "date-fns";
import { createInvoiceDetail } from "services/accountPayable";
import { getActiveDimListByModuleName } from "services/dimension";
import Model from "models/apInvoice";
import ModelDetail from "models/apInvoiceDetail";
import SnackbarUtils from "utils/SnackbarUtils";

const Create = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { basePath, formFields, formFieldsDetail, formFieldsTax1, formFieldsTax2 } = props;
  const redirect = useRedirect();

  const { settingAll, NumberFormat, ToNumber, FindTaxAmount } = useContext(GblContext);
  const { SettingSystem, SettingAp } = settingAll;
  const [loading, setLoading] = useState(true);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [initData, setInitData] = useStateWithCallbackLazy(Model);
  const [initNewRow, setInitNewRow] = useStateWithCallbackLazy(ModelDetail);
  const [summaryValue, setSummaryValue] = useState({
    netAmt: 0,
    taxAmt1: 0,
    taxAmt2: 0,
    total: 0,
    baseNetAmt: 0,
    baseTaxAmt1: 0,
    baseTaxAmt2: 0,
    baseTotal: 0,
  });

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Void", disabled: true },
    { name: "Copy", disabled: true },
    { name: "Print", disabled: true },
  ];
  const [originalData, setOriginalData] = useState();
  const methods = useForm({ defaultValues: initData });

  const { handleSubmit, getValues, setValue, reset } = methods;

  useEffect(() => {
    Model.CurCode = SettingSystem.DefaultCurrencyCode;
    Model.CurRate = SettingSystem.DefaultCurrencyRate;
    Model.TaxStatus = SettingAp.DefaultTaxInvoiceStatus;
    ModelDetail.UnitCode = SettingAp.DefaultUnit;
    const cloneModel = _.cloneDeep(Model);
    setInitData(cloneModel);
    reset(cloneModel);
  }, []);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async () => {
    const values = getValues();
    setBtnLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    //Adjust parameter before save
    setInitData(
      (state) => ({
        ...state,
        ...values,
        CurRate: ToNumber(values.CurRate),
      }),
      (nextState) => Save(nextState)
    );
  };

  const fetchDimListByModule = useCallback(async (mounted) => {
    if (originalData) {
      setInitNewRow((state) => ({
        ...state,
        DimList: {
          Dim: originalData,
        },
      }));
    } else {
      const { Data } = await getActiveDimListByModuleName(10, 1, "AP-ID");

      const original = _.cloneDeep(Data);
      setOriginalData(original);

      setInitNewRow((state) => ({
        ...state,
        DimList: {
          Dim: Data,
        },
      }));
    }

    if (mounted) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    fetchDimListByModule(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const [showAdd, setShowAdd] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <>
              <EditIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => UpdateRow(value)}
              />
            </>
          );
        },
      },
    },
    {
      name: "DeptCode",
      label: "Dept.",
      options: {
        sort: true,
        customHeadRender: ({ label }) => {
          return (
            <TableCell className={classes.colorCell} style={{ width: 80 }}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (val, tableMeta) => {
          let deptDesc = tableMeta.rowData[2];
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{deptDesc}</p>} arrow placement="top">
              <div>
                <div className={classes.cellStyleEllipsis}>{val}</div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "DeptDesc",
      label: "Department Name",
      options: {
        display: false,
      },
    },
    {
      name: "InvdBTaxDr",
      label: "Account #",
      options: {
        sort: true,
        customHeadRender: ({ label }) => {
          return (
            <TableCell className={classes.colorCell} style={{ width: 100 }}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (val, tableMeta) => {
          let accDesc = tableMeta.rowData[4];
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{accDesc}</p>} arrow placement="top">
              <div>
                <div className={classes.cellStyleEllipsis}>{val}</div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "InvdBTaxDrDesc",
      label: "Account Name",
      options: {
        display: false,
      },
    },
    {
      name: "InvdDesc",
      label: "Comment",
      options: {
        display: true,
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "InvdQty",
      label: "Qty",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "qty");
        },
      },
    },
    {
      name: "InvdPrice",
      label: "Price/Unit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "unit");
        },
      },
    },
    {
      name: "NetAmt",
      label: "Net Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "InvdTaxC1",
      label: "TAX",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          return NumberFormat(ToNumber(value) + ToNumber(tableMeta.rowData[10]));
        },
      },
    },
    {
      name: "InvdTaxC2",
      label: "Tax 2",
      options: {
        display: false,
      },
    },
    {
      name: "TotalPrice",
      label: "Total",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "UnPaid",
      label: "Unpaid",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    responsive: "standard",
    selectableRows: "multiple",
    fixedHeader: true,
    tableBodyHeight: "500px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      const sumNet = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumTax1 = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumTotal = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[11]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumUnpaid = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[12]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            <TableCell className={footerClasses} />
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                switch (col.name) {
                  case "NetAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumNet}
                      </TableCell>
                    );
                  case "InvdTaxC1":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTax1}
                      </TableCell>
                    );
                  case "TotalPrice":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTotal}
                      </TableCell>
                    );
                  case "UnPaid":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumUnpaid}
                      </TableCell>
                    );
                  default:
                    return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (let i = removeArray.length - 1; i >= 0; i--) initData.Detail.splice(removeArray[i], 1);
    },
  };

  const CheckSelectedVendor = () => {
    const vnCode = getValues("VnCode");
    const CurRate = getValues("CurRate");
    const vdItem = JSON.parse(localStorage.getItem("vdItem"));
    if (vnCode && vnCode !== "") {
      setSummaryValue((state) => ({
        ...state,
        netAmt: ToNumber(state.netAmt),
        taxAmt1: ToNumber(state.taxAmt1),
        taxAmt2: ToNumber(state.taxAmt2),
        total: ToNumber(state.netAmt + state.taxAmt1 + state.taxAmt2),
        baseNetAmt: ToNumber(state.netAmt * CurRate),
        baseTaxAmt1: ToNumber(state.taxAmt1 * CurRate),
        baseTaxAmt2: ToNumber(state.taxAmt2 * CurRate),
        baseTotal: ToNumber((state.netAmt + state.taxAmt1 + state.taxAmt2) * CurRate),
      }));
      let taxType1 = vdItem?.VnVat1 ?? "None";
      let taxType2 = vdItem?.VnVat2 ?? "None";
      setInitNewRow(
        (state) => ({
          ...state,
          InvdBTaxCr1DeptCode: vdItem?.VnCrDeptCode,
          InvdBTaxCr1DeptDesc: vdItem?.VnCrDeptDesc,
          InvdBTaxCr1: vdItem?.VnVatCrAccCode,
          InvdBTaxCr1Desc: vdItem?.VnVatCrAccDesc,
          InvdTaxT1: vdItem?.VnVat1 ?? "None",
          InvdT1DrDeptCode: vdItem?.VnVat1DrDeptCode,
          InvdT1DrDeptDesc: vdItem?.VnVat1DrDeptDesc,
          InvdT1Dr: vdItem?.VnVat1DrAccCode,
          InvdT1DrDesc: vdItem?.VnVat1DrAccDesc,
          InvdTaxR1: taxType1 === "None" ? 0 : vdItem?.VnTaxR1,
          InvdTaxT2: vdItem?.VnVat2 ?? "None",
          InvdT2DrDeptCode: vdItem?.VnVat2DrDeptCode,
          InvdT2DrDeptDesc: vdItem?.VnVat2DrDeptDesc,
          InvdT2Dr: vdItem?.VnVat2DrAccCode,
          InvdT2DrDesc: vdItem?.VnVat2DrAccDesc,
          InvdTaxR2: taxType2 === "None" ? 0 : vdItem?.VnTaxR2,
        }),
        () => AddNewRow()
      );
    } else {
      SnackbarUtils.warning(translate("ra.permission.selectVendorFirst"));
    }
  };

  const CustomHeader = (props) => {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={1}>
              <IconButton size={"small"} onClick={CheckSelectedVendor} style={{ marginLeft: 6 }}>
                <AddIcon />
              </IconButton>
            </TableCell>
            <TableCell align="right" colSpan={10} />
          </TableRow>
        </TableHead>
        <MuiTableHead {...props} />
      </>
    );
  };

  const AddNewRow = () => {
    setSummaryValue({
      netAmt: 0,
      taxAmt1: 0,
      taxAmt2: 0,
      total: 0,
      baseNetAmt: 0,
      baseTaxAmt1: 0,
      baseTaxAmt2: 0,
      baseTotal: 0,
    });
    setEditIndex("");
    setShowAdd(true);
  };

  const UpdateRow = (index) => {
    const dataRow = initData.Detail.find((i) => i.index === index);
    const CurRate = getValues("CurRate");
    const Qty = ToNumber(dataRow["InvdQty"]);
    const Price = ToNumber(dataRow["InvdPrice"]);
    const TaxRate1 = ToNumber(dataRow["InvdTaxR1"]);
    const TaxRate2 = ToNumber(dataRow["InvdTaxR2"]);
    let NetAmt = Qty * Price;
    //check TaxType
    let TaxAmt1 = FindTaxAmount(dataRow["InvdTaxT1"], TaxRate1, NetAmt);
    let TaxAmt2 = FindTaxAmount(dataRow["InvdTaxT2"], TaxRate2, NetAmt);

    //check overwrite
    if (dataRow["InvdT1Cr"]) {
      TaxAmt1 = ToNumber(dataRow["InvdTaxC1"]);
    }
    if (dataRow["InvdT2Cr"]) {
      TaxAmt2 = ToNumber(dataRow["InvdTaxC2"]);
    }

    if (dataRow["InvdTaxT1"] === "Include") {
      NetAmt = NetAmt - TaxAmt1;
    }

    if (dataRow["InvdTaxT2"] === "Include") {
      NetAmt = NetAmt - TaxAmt2;
    }

    const Total = NetAmt + TaxAmt1 + TaxAmt2;

    setSummaryValue({
      netAmt: ToNumber(NetAmt),
      taxAmt1: ToNumber(TaxAmt1),
      taxAmt2: ToNumber(TaxAmt2),
      total: ToNumber(Total),
      baseNetAmt: ToNumber(NetAmt * CurRate),
      baseTaxAmt1: ToNumber(TaxAmt1 * CurRate),
      baseTaxAmt2: ToNumber(TaxAmt2 * CurRate),
      baseTotal: ToNumber(Total * CurRate),
    });
    setValue("NetAmt", NetAmt);
    setValue("TotalAmt", Total);
    setEditIndex(index);
    setShowAdd(true);
  };

  const SaveFromPopup = (arr, row) => {
    const index = arr.Detail.findIndex((el) => el.index === editIndex);
    row.InvdAbfTax = NumberFormat(summaryValue.baseNetAmt);
    row.NetBaseAmt = NumberFormat(summaryValue.baseNetAmt);
    row.NetAmt = NumberFormat(summaryValue.netAmt);
    row.InvdTaxA1 = NumberFormat(summaryValue.baseTaxAmt1);
    row.InvdTaxA2 = NumberFormat(summaryValue.baseTaxAmt2);
    row.TotalPrice = NumberFormat(summaryValue.total);
    row.UnPaid = NumberFormat(summaryValue.total);
    if (editIndex !== "") {
      //update
      arr.Detail[index] = row;
      setInitData(arr);
      setShowAdd(false);
    } else {
      //create
      if (arr.Detail) {
        row.index = arr.Detail.length;
        arr.Detail = [...arr.Detail, row];
        setInitData(arr);
        setShowAdd(false);
      }
    }
    //reset dimension value
    fetchDimListByModule(true);
  };

  const CancelFromPopup = () => {
    setSummaryValue({
      netAmt: ToNumber(0),
      taxAmt1: ToNumber(0),
      taxAmt2: ToNumber(0),
      total: ToNumber(0),
      baseNetAmt: ToNumber(0),
      baseTaxAmt1: ToNumber(0),
      baseTaxAmt2: ToNumber(0),
      baseTotal: ToNumber(0),
    });
    setShowAdd(false);
  };

  const CheckTaxType = (m, data) => {
    if (data["InvdTaxT1"] !== "None" && !!data["InvdT1DrDeptCode"] === false) {
      m.setError("InvdT1DrDeptCode", {
        type: "required",
        message: "* Required",
      });
    }
    if (data["InvdTaxT1"] !== "None" && !!data["InvdT2DrDeptCode"] === false) {
      m.setError("InvdT2DrDeptCode", {
        type: "required",
        message: "* Required",
      });
    }
    if (data["InvdTaxT1"] !== "None" && !!data["InvdT1Dr"] === false) {
      m.setError("InvdT1Dr", {
        type: "required",
        message: "* Required",
      });
    }
    if (data["InvdTaxT2"] !== "None" && !!data["InvdT2Dr"] === false) {
      m.setError("InvdT2Dr", {
        type: "required",
        message: "* Required",
      });
    }
    if (data["InvdTaxT1"] !== "None" && ToNumber(data["InvdTaxR1"]) === 0) {
      m.setError("InvdTaxR1", {
        type: "required",
        message: "* Required",
      });
    } else {
      m.clearErrors("InvdTaxR1");
    }
    if (data["InvdTaxT2"] !== "None" && ToNumber(data["InvdTaxR2"]) === 0) {
      m.setError("InvdTaxR2", {
        type: "required",
        message: "* Required",
      });
    } else {
      m.clearErrors("InvdTaxR2");
    }
    if (data["InvdTaxT1"] === "None") {
      m.clearErrors("InvdT1DrDeptCode");
      m.clearErrors("InvdT1Dr");
      m.clearErrors("InvdTaxR1");
      m.setValue("InvdT1DrDeptCode", "");
      m.setValue("InvdT1Cr", "");
      m.setValue("InvdT1Dr", "");
      m.setValue("InvdTaxR1", 0);
    }
    if (data["InvdTaxT2"] === "None") {
      m.clearErrors("InvdT2DrDeptCode");
      m.clearErrors("InvdT2Dr");
      m.clearErrors("InvdTaxR2");
      m.setValue("InvdT2DrDeptCode", "");
      m.setValue("InvdT2Cr", "");
      m.setValue("InvdT2Dr", "");
      m.setValue("InvdTaxR2", 0);
    }
    if (data["InvdT1Dr"]) {
      m.clearErrors("InvdT1Dr");
    }
    if (data["InvdT2Dr"]) {
      m.clearErrors("InvdT2Dr");
    }
    if (data["InvdT1DrDeptCode"]) {
      m.clearErrors("InvdT1DrDeptCode");
    }
    if (data["InvdT2DrDeptCode"]) {
      m.clearErrors("InvdT2DrDeptCode");
    }
  };

  const UpdateFromPopup = (currentField, m, detail) => {
    //Verify & Set Detail to FormValue
    const CurRate = getValues("CurRate");
    const Qty = ToNumber(detail["InvdQty"]);
    const Price = ToNumber(detail["InvdPrice"]);
    const TaxRate1 = ToNumber(detail.InvdTaxT1 !== "None" ? detail["InvdTaxR1"] : 0);
    const TaxRate2 = ToNumber(detail.InvdTaxT2 !== "None" ? detail["InvdTaxR2"] : 0);
    let NetAmt = Qty * Price;
    let TaxAmt1 = FindTaxAmount(detail["InvdTaxT1"], TaxRate1, NetAmt);
    let TaxAmt2 = FindTaxAmount(detail["InvdTaxT2"], TaxRate2, NetAmt);

    CheckTaxType(m, detail);

    //InvdT1Cr = Overwrite Tax1
    if (detail.InvdT1Cr || detail.InvdT1Cr === "O") {
      m.setValue("InvdTaxC1", detail["InvdTaxC1"]);
      TaxAmt1 = ToNumber(detail["InvdTaxC1"]);
    } else {
      m.setValue("InvdTaxC1", TaxAmt1);
    }
    //InvdT2Cr = Overwrite Tax2
    if (detail.InvdT2Cr || detail.InvdT2Cr === "O") {
      m.setValue("InvdTaxC2", detail["InvdTaxC2"]);
      TaxAmt2 = ToNumber(detail["InvdTaxC2"]);
    } else {
      m.setValue("InvdTaxC2", TaxAmt2);
    }

    if (detail["InvdTaxT1"] === "Include") {
      NetAmt = NetAmt - TaxAmt1;
    }
    if (detail["InvdTaxT2"] === "Include") {
      NetAmt = NetAmt - TaxAmt2;
    }

    const Total = NetAmt + TaxAmt1 + TaxAmt2;
    setSummaryValue((state) => ({
      ...state,
      netAmt: ToNumber(NetAmt),
      taxAmt1: ToNumber(TaxAmt1),
      taxAmt2: ToNumber(TaxAmt2),
      total: ToNumber(Total),
      baseNetAmt: ToNumber(NetAmt * CurRate),
      baseTaxAmt1: ToNumber(TaxAmt1 * CurRate),
      baseTaxAmt2: ToNumber(TaxAmt2 * CurRate),
      baseTotal: ToNumber(Total * CurRate),
    }));
    m.setValue("NetAmt", NetAmt);
    m.setValue("NetBaseAmt", NetAmt * CurRate);
    m.setValue("TotalAmt", Total);
  };

  const UpdateForm = (e) => {
    const values = getValues();
    if (e.target.name === "CurRate") {
      const rate = ToNumber(e.target.value);
      initData.Detail.forEach((i, idx) => {
        i.index = idx;
        if (i.InvdTaxT1 === "Include") {
          i.NetAmt = !i.NetAmt ? (i.InvdPrice * i.InvdQty * rate - i.InvdTaxC1 * rate) / initData.CurRate : i.NetAmt;
          i.InvdAbfTax = i.InvdPrice * i.InvdQty * rate - i.InvdTaxC1 * rate;
          i.NetBaseAmt = i.InvdPrice * i.InvdQty * rate - i.InvdTaxC1 * rate;
        } else {
          i.NetAmt = !i.NetAmt ? i.InvdAbfTax : i.NetAmt;
          i.InvdAbfTax = i.InvdPrice * i.InvdQty * rate;
          i.NetBaseAmt = i.InvdPrice * i.InvdQty * rate;
        }
        i.InvdTaxA1 = i.InvdTaxC1 * rate;
        i.InvdTaxA2 = i.InvdTaxC2 * rate;
      });
    }
    //add only
    if (e.target.name === "InvhInvNo") {
      setValue("InvhTInvNo", e.target.value);
    }
    if (e.target.name === "InvhDate") {
      const newDate = new Date(e.target.value);
      const creditTerm = values.InvhCredit;
      const newDueDate = addDays(new Date(newDate), parseInt(creditTerm));
      setValue("InvhDate", newDate);
      setValue("InvhInvDate", newDate);
      setValue("InvhTInvDt", newDate);
      setValue("InvhDueDate", newDueDate);
      setValue("TaxPeriod", format(newDate, "MM/yyyy"));
    }
    if (e.target.name === "InvhInvDate") {
      const newDate = new Date(e.target.value);
      const creditTerm = values.InvhCredit;
      const newDueDate = addDays(new Date(newDate), parseInt(creditTerm));
      setValue("InvhInvDate", newDate);
      setValue("InvhTInvDt", newDate);
      setValue("InvhDueDate", newDueDate);
      setValue("TaxPeriod", format(newDate, "MM/yyyy"));
    }
    if (e.target.name === "InvhTInvDt") {
      const taxInvDate = new Date(e.target.value);
      setValue("TaxPeriod", format(taxInvDate, "MM/yyyy"));
    }
    if (e.target.name === "InvhCredit") {
      const creditTerm = values.InvhCredit === "" ? 0 : values.InvhCredit;
      const newDueDate = addDays(new Date(values.InvhInvDate), parseInt(creditTerm));
      setValue("InvhDueDate", newDueDate);
    }
  };

  const Save = async (values) => {
    //Validate & CheckDetail
    const sumTax1 = values.Detail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.InvdTaxA1);
      return s;
    }, 0);

    if (values.TaxStatus === "Pending" && sumTax1 === 0) {
      values.TaxStatus = "None";
    }
    if (sumTax1 > 0 && values.TaxStatus === "None") {
      values.TaxStatus = SettingAp.DefaultTaxInvoiceStatus;
    }

    const sumTotal = values.Detail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.TotalAmt);
      return s;
    }, 0);
    values.InvhTotalAmt = sumTotal;
    values.InvhStatus = "Effective";

    if (values.Detail.length > 0) {
      values.Detail.forEach((item) => {
        if (item["InvdT1Cr"]) {
          item["InvdT1Cr"] = "O";
        } else {
          item["InvdT1Cr"] = "";
        }
        if (item["InvdT2Cr"]) {
          item["InvdT2Cr"] = "O";
        } else {
          item["InvdT2Cr"] = "";
        }
      });
      const { Code, InternalMessage, UserMessage } = await createInvoiceDetail(values);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect(`${InternalMessage}/show`);
        });
        localStorage.removeItem("vdItem");
        setBtnLoading(false);
      } else {
        localStorage.removeItem("vdItem");
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    } else {
      SnackbarUtils.info(translate("ra.info.notransaction"));
      setBtnLoading(false);
      return;
    }
    setLoading(false);
  };

  const CancelFnc = () => {
    localStorage.removeItem("vdItem");
    redirect("list", basePath);
  };

  if (loading) return <Loading />;
  if (!initData) return null;

  return (
    <div>
      <ActionMenu menuControl={menuControlProp} />

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Paper style={{ padding: 16 }}>
          <BoxHeader header={"Invoice"} status={initData.InvhStatus} />
          <Grid container alignItems="flex-start" spacing={1}>
            {formFields
              ? formFields.map((item, idx) => (
                  <Grid item xs={item.size} key={idx} style={item.style}>
                    {React.createElement(item.field.type, {
                      ...{
                        ...item.field.props,
                        methods,
                        key: item.field.props.name,
                        onChange: UpdateForm,
                      },
                    })}
                  </Grid>
                ))
              : ""}
          </Grid>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              <MuiTranslateTable
                data={initData.Detail}
                columns={columns}
                options={options}
                components={{
                  TableHead: CustomHeader,
                }}
              />
            </Grid>
          </Grid>
          {showAdd && (
            <PopupTable
              initialValues={editIndex !== "" ? initData.Detail.find((i) => i.index === editIndex) : initNewRow}
              formFields={formFieldsDetail}
              formFieldsTax1={formFieldsTax1}
              formFieldsTax2={formFieldsTax2}
              checkTaxType={CheckTaxType}
              update={UpdateFromPopup}
              open={showAdd}
              save={(row) => SaveFromPopup(initData, row)}
              cancel={CancelFromPopup}
              //maxWidth={"md"}
              showDim
            >
              <Box style={{ margin: 16 }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={6} style={{ display: "none" }}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="h6" gutterBottom>
                          {translate("ra.field.Summary")}
                        </Typography>
                      </Box>
                    </Box>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Net Amount")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.netAmt)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Tax 1")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.taxAmt1)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Tax 2")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.taxAmt2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Total")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.total)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="h6" gutterBottom>
                          {translate("ra.field.Base Summary")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Rate")} : {NumberFormat(getValues("CurRate"), "currency")}
                        </Typography>
                      </Box>
                    </Box>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Net Amount")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.baseNetAmt)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Tax 1")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.baseTaxAmt1)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Tax 2")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.baseTaxAmt2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Total")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.baseTotal)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </PopupTable>
          )}
        </Paper>
        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(initData, 0, 2) : ""}</pre>
        <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />
      </form>
    </div>
  );
};

export default Create;
