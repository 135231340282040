/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AccessDenied from "layout/AccessDenied";
import { Box, Button, Typography, Grid, FormControl, Select, InputLabel, MenuItem, Divider } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { getMailTemplate, updateMailTemplate } from "services/setting";
import { getDataDic } from "services/lookup";
import ButtonFooter from "components/ButtonFooter";
import ActionMenu from "components/ActionMenu";
import { MailProfileCode } from "utils/options";
import { TextFieldInForm } from "components/Form";
import SnackbarUtils from "utils/SnackbarUtils";
import { permissionName } from "utils/constants";
import EmailEditor from "react-email-editor";

const useStyles = makeStyles((theme) => ({
  tabPanel: { width: "100%", margin: "0 20px" },
  button: { textTransform: "none" },
}));

const EmailTemplate = (props) => {
  const { children, value, index, permissions } = props;

  let checkPermission = permissions && permissions.find((i) => i.Name === "Sys.Email")?.View;

  const classes = useStyles();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [mode, setMode] = useState("view");
  const [profileCode, setProfileCode] = useState(MailProfileCode[0]);
  const [data, setData] = useState({
    Body: null,
    Subject: null,
    Layout: null,
  });
  const [dataDic, setDataDic] = useState();
  const [openAlert, setAlertOpen] = useState(false);
  const methods = useForm({ defaultValues: data });
  const { handleSubmit, reset } = methods;
  const emailEditorRef = React.useRef(null);

  const onReady = () => {
    if (data.Layout) {
      emailEditorRef.current.editor.loadDesign(data.Layout);
    }
  };

  const fetch = async () => {
    let u = profileCode.toUpperCase();
    const response = await getMailTemplate(u);
    if (response) {
      setData(response);
      reset(response);
      if (response.Layout && emailEditorRef.current) {
        emailEditorRef.current.editor.loadDesign(response.Layout);
      }
    } else {
      setData();
    }
    setBtnLoading(false);
  };

  const fetchDataDic = async () => {
    const response = await getDataDic(profileCode);
    if (response) {
      //let fieldArray = response.map((i) => i.Field);

      let uniqArr = [...new Set(response)];
      setDataDic(uniqArr);
      //setDataDic(uniqArr.join(", "));
    } else {
      setDataDic();
    }
  };

  React.useEffect(async () => {
    if (profileCode !== "NONE") {
      fetch();
      if (profileCode !== "NOREPLY") {
        fetchDataDic();
      } else {
        let authenCode = [{ Field: "AuthenKey", Caption: "Key OTP Code" }];
        setDataDic(authenCode);
      }
    }
  }, [profileCode]);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async (values) => {
    setBtnLoading(true);
    //let u = profileCode.toUpperCase();
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;
      let u = profileCode.toUpperCase();
      values.Body = html;
      values.Layout = design;
      const { Code, UserMessage } = await updateMailTemplate(u, values);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage);
        setMode("view");
        fetch();
      } else {
        SnackbarUtils.warning(UserMessage);
        setBtnLoading(false);
      }
    });
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
      disabled: mode !== "view",
    },
  ];

  const CancelFnc = () => {
    setProfileCode("NONE");
    setMode("view");
    setData({
      Body: null,
      Subject: null,
      Layout: null,
    });
  };

  function copyText(id) {
    // Get the text field
    var copyText = document.getElementById(id);
    navigator.clipboard.writeText(copyText.textContent);
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {checkPermission && value === index ? (
        <>
          <Box p={3}>
            <Typography>
              <b>{children}</b>
            </Typography>
          </Box>
          <div style={{ marginTop: -74 }}>
            <ActionMenu
              menuControl={menuControlProp}
              permission={permissions.find((i) => i.Name === permissionName["Sys.Email"])}
            />
          </div>
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
            <Grid container justifyContent="flex-start" spacing={1} style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid item xs={12} style={{ marginTop: 4, marginBottom: 20 }}>
                <FormControl variant="outlined">
                  <InputLabel id="m">Available Module</InputLabel>
                  <Select
                    variant="outlined"
                    margin="dense"
                    labelId="m"
                    label="Available Module"
                    value={profileCode}
                    onChange={(e) => {
                      setProfileCode(e.target.value);
                    }}
                    style={{ width: 240 }}
                    disabled={mode === "view" || profileCode !== "NONE"}
                  >
                    {MailProfileCode
                      ? MailProfileCode.map((item, idx) => (
                          <MenuItem key={idx} value={item}>
                            {item}
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                </FormControl>
              </Grid>
              {profileCode !== "NONE" && (
                <>
                  <Grid item xs={12}>
                    <TextFieldInForm
                      label="Subject"
                      name="Subject"
                      variant="outlined"
                      margin="dense"
                      methods={methods}
                      rule={{
                        maxLength: {
                          value: 255,
                          message: "maximum length is 255",
                        },
                      }}
                      disabled={mode === "view"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {mode === "view" && (
                      <TextFieldInForm
                        label="Body"
                        name="Body"
                        variant="outlined"
                        margin="dense"
                        methods={methods}
                        multiline
                        rows={10}
                        emailBox
                        //onFocus={() => setCurrentFocus("Body")}
                        disabled={mode === "view"}
                      />
                    )}
                    {mode !== "view" && (
                      <EmailEditor
                        //projectId={109522}
                        ref={emailEditorRef}
                        // onLoad={onLoad}
                        onReady={onReady}
                        // options={{
                        //   customJS: [window.location.protocol + "//" + window.location.host + "/custom.js"],
                        // }}
                      />
                    )}
                    <br />
                  </Grid>
                </>
              )}
              {profileCode !== "NONE" && (
                <Box p={1} style={{ width: "100%" }}>
                  <Typography>
                    <b>Available key</b>
                  </Typography>
                  {dataDic &&
                    dataDic.map((item, idx) => (
                      <div key={idx} style={{ padding: "4px 4px" }}>
                        {/* <Button
                          variant="outlined"
                          style={{ textTransform: "none" }}
                          onClick={(e) => addText(e, item.Field)}
                        >
                          {item.Field}
                        </Button> */}
                        <span style={{ padding: "0px 4px" }}>{item.Caption}</span>
                        <span id={item.Field + idx} style={{ display: "none" }}>
                          {"${"}
                          {item.Field}
                          {"}"}
                        </span>
                        <Button
                          onClick={() => {
                            setAlertOpen(true);
                            copyText(item.Field + idx);
                          }}
                        >
                          📋
                        </Button>
                      </div>
                    ))}
                  <Snackbar open={openAlert} autoHideDuration={1000} onClose={() => setAlertOpen(false)}>
                    <MuiAlert elevation={6} variant="filled" children="Copied" />
                  </Snackbar>
                </Box>
              )}
            </Grid>
            {profileCode === "NONE" ? null : <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />}
          </form>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default EmailTemplate;
