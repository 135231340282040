/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useContext } from "react";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import {
  Grid,
  Divider,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { NumberFormatInForm, DateInForm } from "components/Form";
import ActionMenu from "components/ActionMenu";
import {
  getCurrencyExDetail,
  getExchangeRateByProvider,
  createCurrencyExDetail,
  updateCurrencyExDetail,
  delCurrencyExDetail,
} from "services/setting";
import { getLookup } from "services/lookup";
import Model from "models/currency";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogItem = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { settingAll, ToMySqlDate } = useContext(GblContext);
  const { SettingSystem } = settingAll;
  const { children, id, mode, setMode, open, onClose } = props;
  const [isBtnLoading, setBtnLoading] = React.useState(false);
  const [data, setData] = useStateWithCallbackLazy(Model);
  const [currencyLookup, setCurrencyLookUp] = useStateWithCallbackLazy([]);
  const methods = useForm({ defaultValues: data });

  const { handleSubmit, reset } = methods;

  const fetchDetailById = useCallback(async () => {
    if (id && id !== 0) {
      const response = await getCurrencyExDetail(id);
      if (response) {
        setData(response);
        reset(response);
      }
    } else {
      setData(Model);
      reset(Model);
    }
    setBtnLoading(false);
  }, [id, reset]);

  const fetchLookUp = useCallback(async () => {
    const r = await getLookup("Currency");
    const resultCodeOnly = r.filter((i) => i.Code !== SettingSystem.DefaultCurrencyCode).map((i) => i.Code);
    setCurrencyLookUp(resultCodeOnly);
  }, []);

  useEffect(() => {
    fetchDetailById();
    fetchLookUp();
  }, [fetchDetailById]);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    //Adjust parameter before save
    setData(
      (state) => ({
        ...state,
        ...values,
        CreateDate: mode === "edit" ? new Date() : state.CreateDate,
        UserModified: Model.UserModified,
      }),
      (nextState) => Save(nextState)
    );
  };

  const Save = async (values) => {
    if (mode === "edit") {
      values.Id = id;
      //Update
      const { Code, UserMessage, InternalMessage } = await updateCurrencyExDetail(values);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          handleClose(id);
        });
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    } else {
      const { Code, UserMessage, InternalMessage } = await createCurrencyExDetail(values);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          handleClose(parseInt(InternalMessage));
        });
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    }
  };

  const handleClose = (value) => {
    onClose(value);
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
      disabled: mode !== "view",
    },
    {
      name: "Delete",
      fnc: () => DelOrVoid(id),
      disabled: mode !== "view",
    },
  ];

  const DelOrVoid = async (id) => {
    const msg = translate("ra.question.confirmDel");
    SnackbarUtils.delConfirm(msg, async function () {
      const { Code, UserMessage } = await delCurrencyExDetail(id);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          handleClose(id);
        });
      }
    });
  };

  const CancelFnc = () => {
    if (id === 0) {
      onClose();
      return;
    }
    fetchDetailById();
    setMode("view");
  };

  const LoadingButton = ({ text, disabled }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} type="submit">
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event);
          }
        }}
        scroll={"paper"}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title" onClose={onClose}>
          {props.title}
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
          <DialogContent dividers className={classes.content}>
            <ActionMenu menuControl={menuControlProp} justifyContent="flex-start" />
            <div className={classes.root} style={{ marginTop: -46 }}>
              <Typography variant="body1">
                {mode === "add" ? (
                  data?.CreateDate ? (
                    `Update ${new Date(data.CreateDate).toString()}`
                  ) : (
                    <br />
                  )
                ) : data?.LastModified ? (
                  `Update ${new Date(data.LastModified).toString()}`
                ) : (
                  <br />
                )}
              </Typography>
            </div>
            <Divider />
            <div className={classes.root}>
              <Grid container spacing={1} justifyContent="flex-start">
                <Grid item xs={4} elevation={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="label" style={{ marginTop: data.CurrCode ? "2px" : "-4px" }}>
                      * From
                    </InputLabel>
                    <Select
                      labelId="label"
                      label="* From"
                      margin="dense"
                      disabled={mode !== "add"}
                      value={data.CurrCode}
                      onChange={async (e) => {
                        const date = ToMySqlDate(new Date());
                        const baseCurr = SettingSystem.DefaultCurrencyCode;
                        setBtnLoading(true);

                        try {
                          const response = await getExchangeRateByProvider(date, baseCurr, e.target.value);
                          if (response.Code < 0) {
                            throw response;
                          }

                          if (response) {
                            setData(
                              (state) => ({
                                ...state,
                                CurrCode: e.target.value,
                                BuyingRate: response[0]?.BuyingRate ?? 1,
                                SellingRate: response[0]?.SellingRate ?? 1,
                                AverageRate: response[0]?.AverageRate ?? 1,
                                //CurrDate: new Date(response[0].Timestamp),
                                CreateDate: new Date(response[0].Timestamp),
                              }),
                              () => setBtnLoading(false)
                            );
                            methods.setValue("BuyingRate", response[0]?.BuyingRate ?? 1);
                            methods.setValue("SellingRate", response[0]?.SellingRate ?? 1);
                            methods.setValue("AverageRate", response[0]?.AverageRate ?? 1);
                          } else {
                            SnackbarUtils.info("Not found this currency. Please add manual rate");
                            setData(
                              (state) => ({
                                ...state,
                                CurrCode: e.target.value,
                                // BuyingRate: response[0]?.BuyingRate ?? 1,
                                // SellingRate: response[0]?.SellingRate ?? 1,
                                // AverageRate: response[0]?.AverageRate ?? 1,
                                // //CurrDate: new Date(response[0].Timestamp),
                                // CreateDate: new Date(response[0].Timestamp),
                              }),
                              () => setBtnLoading(false)
                            );
                            setBtnLoading(false);
                          }
                        } catch (error) {
                          SnackbarUtils.info("Not found this currency. Please add manual rate");
                          setData(
                            (state) => ({
                              ...state,
                              CurrCode: e.target.value,
                              // BuyingRate: response[0]?.BuyingRate ?? 1,
                              // SellingRate: response[0]?.SellingRate ?? 1,
                              // AverageRate: response[0]?.AverageRate ?? 1,
                              // //CurrDate: new Date(response[0].Timestamp),
                              // CreateDate: new Date(response[0].Timestamp),
                            }),
                            () => setBtnLoading(false)
                          );
                          setBtnLoading(false);
                        }
                      }}
                    >
                      {currencyLookup
                        ? currencyLookup.map((item) => (
                            <MenuItem key={item?.toString()} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        : ""}
                    </Select>
                  </FormControl>
                  {/* <SelectInForm
										label="* From"
										name="CurrCode"
										methods={methods}
										options={currencyLookup}
										style={{ margin: 0 }}
										disabled={mode !== "add"}
									/> */}
                </Grid>
                <Grid item xs={4} elevation={2} style={{ margin: "-4px 0" }}>
                  <TextField
                    fullWidth
                    label="To"
                    variant="outlined"
                    margin="dense"
                    value={SettingSystem.DefaultCurrencyCode}
                    disabled
                  />
                </Grid>
                <Grid item xs={4} elevation={2} style={{ margin: "-4px 0" }}>
                  <DateInForm
                    label="Currency Date"
                    name="CurrDate"
                    methods={methods}
                    // minDate={new Date()}
                    // minDateMessage={"Date must be more than close period"}
                    disabled={mode !== "add" || isBtnLoading}
                    onChange={async (e) => {
                      const date = ToMySqlDate(e.target.value);
                      const selectedCurrCode = data.CurrCode;
                      const baseCurr = SettingSystem.DefaultCurrencyCode;
                      setBtnLoading(true);

                      try {
                        const response = await getExchangeRateByProvider(date, baseCurr, selectedCurrCode);
                        if (response) {
                          if (response.Code < 0) {
                            throw response;
                          }
                          setData(
                            (state) => ({
                              ...state,
                              BuyingRate: response[0]?.BuyingRate ?? 1,
                              SellingRate: response[0]?.SellingRate ?? 1,
                              AverageRate: response[0]?.AverageRate ?? 1,
                              CurrDate: e.target.value,
                              CreateDate: new Date(response[0].Timestamp),
                            }),
                            () => setBtnLoading(false)
                          );
                          methods.setValue("BuyingRate", response[0]?.BuyingRate ?? 1);
                          methods.setValue("SellingRate", response[0]?.SellingRate ?? 1);
                          methods.setValue("AverageRate", response[0]?.AverageRate ?? 1);
                        } else {
                          SnackbarUtils.info("Not found this currency. Please add manual rate");
                          setData(
                            (state) => ({
                              ...state,
                              CurrDate: e.target.value,
                              //CurrCode: e.target.value,
                              // BuyingRate: response[0]?.BuyingRate ?? 1,
                              // SellingRate: response[0]?.SellingRate ?? 1,
                              // AverageRate: response[0]?.AverageRate ?? 1,
                              // //CurrDate: new Date(response[0].Timestamp),
                              // CreateDate: new Date(response[0].Timestamp),
                            }),
                            () => setBtnLoading(false)
                          );
                          setBtnLoading(false);
                        }
                      } catch (error) {
                        SnackbarUtils.info("Not found this currency. Please add manual rate");
                        setData(
                          (state) => ({
                            ...state,
                            CurrDate: e.target.value,
                            //CurrCode: e.target.value,
                            // BuyingRate: response[0]?.BuyingRate ?? 1,
                            // SellingRate: response[0]?.SellingRate ?? 1,
                            // AverageRate: response[0]?.AverageRate ?? 1,
                            // //CurrDate: new Date(response[0].Timestamp),
                            // CreateDate: new Date(response[0].Timestamp),
                          }),
                          () => setBtnLoading(false)
                        );
                        setBtnLoading(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4} elevation={2}>
                  <NumberFormatInForm
                    label="Buying Rate"
                    name="BuyingRate"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    disabled={mode === "view"}
                    decimal={8}
                    // decimalSep={SettingSystem.DecimalSeparator}
                    // thousandSep={SettingSystem.ThousandSeparator}
                    rule={{
                      min: {
                        value: 0.000001,
                        message: "* Required",
                      },
                      maxLength: {
                        value: 26,
                        message: "maximum length is 26",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={4} elevation={2}>
                  <NumberFormatInForm
                    label="Selling Rate"
                    name="SellingRate"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    disabled={mode === "view"}
                    decimal={8}
                    // decimalSep={SettingSystem.DecimalSeparator}
                    // thousandSep={SettingSystem.ThousandSeparator}
                    rule={{
                      min: {
                        value: 0.000001,
                        message: "* Required",
                      },
                      maxLength: {
                        value: 26,
                        message: "maximum length is 26",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4} elevation={2}>
                  <NumberFormatInForm
                    label="Average Rate"
                    name="AverageRate"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    disabled={mode === "view"}
                    decimal={8}
                    // decimalSep={SettingSystem.DecimalSeparator}
                    // thousandSep={SettingSystem.ThousandSeparator}
                    rule={{
                      min: {
                        value: 0.000001,
                        message: "* Required",
                      },
                      maxLength: {
                        value: 26,
                        message: "maximum length is 26",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            {children}
            <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
          </DialogContent>
          {mode !== "view" ? (
            <DialogActions>
              <LoadingButton text={translate("ra.action.save")} disabled={isBtnLoading} />
              <Button variant="outlined" className={classes.textCancel} onClick={CancelFnc}>
                {translate("ra.action.cancel")}
              </Button>
              {/* <ButtonFooter noBorder disabled={isBtnLoading} SaveFnc={handleSubmit(onSubmit)} CancelFnc={CancelFnc} /> */}
            </DialogActions>
          ) : (
            ""
          )}
        </form>
      </Dialog>
    </div>
  );
};

export default DialogItem;
