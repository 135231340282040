import React, { useState, useEffect } from "react";
import { Loading, useTranslate } from "react-admin";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
	Grid,
	TextField,
	Button,
	Typography,
	IconButton,
} from "@material-ui/core";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import {
	getDashboardById,
	addDashboard,
	updateDashboard,
} from "services/callStoreProcedure";

const useStyles = makeStyles((theme) => ({
	centerContent: {
		display: "flex",
		flexDirection: "column",
		margin: "auto",
		//width: "fit-content",
		width: "80%",
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
}));

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	centerContent: {
		display: "flex",
		flexDirection: "column",
		margin: "auto",
		width: "fit-content",
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const ManageDashboard = (props) => {
	const translate = useTranslate();
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const { open, close, dashboardId, userId, modeManageDashboard } = props;
	const [tempParam, setTempParam] = useState({
		Id: 0,
		Name: "",
		User_Id: userId,
	});

	const fetchDashboardById = async (mounted) => {
		const { data } = await getDashboardById(dashboardId);
		setTempParam((state) => ({
			...state,
			Id: data.Id,
			Name: data.Name,
			User_Id: userId,
		}));

		if (mounted) {
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	useEffect(() => {
		let mounted = true;
		if (modeManageDashboard === "Manage Dashboard") {
			fetchDashboardById(mounted);
		} else {
			if (mounted) {
				setLoading(false);
			}
		}
		return function cleanup() {
			mounted = false;
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const addOrUpdateDashboard = async () => {
		//update
		if (modeManageDashboard === "Manage Dashboard") {
			const r = await updateDashboard(tempParam);
			close(r.data);
		}
		//create
		else {
			const r = await addDashboard(tempParam);
			close(r.data);
		}
	};

	return (
		<Dialog
			fullWidth
			maxWidth={"sm"}
			open={open}
			onClose={close}
			aria-labelledby="customized-dialog-title"
		>
			<DialogTitle id="customized-dialog-title" onClose={close}>
				{modeManageDashboard}
			</DialogTitle>
			<DialogContent className={classes.centerContent}>
				{loading ? (
					<Loading />
				) : (
					<>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									variant="outlined"
									margin="dense"
									label={translate("ra.field.Name")}
									value={tempParam.Name}
									onChange={(e) => {
										setTempParam((state) => ({
											...state,
											Name: e.target.value,
										}));
									}}
									autoFocus
								/>
							</Grid>
							{/* {currentUser === "admin" && (
                <Grid item xs={12}>
                  <MultiSelectListBox
                    className={"multi-select"}
                    overrideStrings={{
                      search: "Search...",
                      selectAll: "Select All",
                      removeAll: "Remove All",
                      selectedInfo: "Items selected",
                    }}
                    sortable={true}
                    options={userList}
                    textField="UserName"
                    valueField="UserName"
                    value={tempParam.User}
                    rowHeight={30}
                    onSelect={({ item, sortedList }) => {
                      setTempParam((state) => ({
                        ...state,
                        User: sortedList.map((i) => i.UserName),
                      }));
                    }}
                    onRemove={({ item }) => {
                      setTempParam((state) => ({
                        ...state,
                        User: [...tempParam.User.filter((i) => i !== item.UserName)],
                      }));
                    }}
                    onSelectAll={(selectedItems) => {
                      if (selectedItems.length > 0) {
                        const selected = [...selectedItems.map((item) => item.UserName)];
                        setTempParam((state) => ({
                          ...state,
                          User: selected,
                        }));
                      }
                    }}
                    onRemoveAll={() =>
                      setTempParam((state) => ({
                        ...state,
                        User: [],
                      }))
                    }
                    onSort={({ sortedList }) => {
                      setTempParam((state) => ({
                        ...state,
                        User: [...sortedList.map((i) => i.UserName)],
                      }));
                    }}
                    onSearch={({ items, textField, query }) =>
                      items.filter((i) => i.UserName.toLowerCase().includes(query.toLowerCase()))
                    }
                  />
                </Grid>
              )} */}
						</Grid>
					</>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					autoFocus
					onClick={addOrUpdateDashboard}
					color="primary"
					variant="contained"
				>
					{translate("ra.actionMenu.OK")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ManageDashboard;
