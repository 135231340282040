import axiosAuth from "../utils/request";

export async function getWorkflowByCode(code) {
  const { data } = await axiosAuth.get(`/api/Workflow/${code}`);
  return data;
}

export async function getWorkflowDetailByCode(code) {
  const { data } = await axiosAuth.get(`/api/Workflow/Detail/${code}`);
  return data;
}

export async function getWorkflowStep(code) {
  const { data } = await axiosAuth.get(`/api/Workflow/Steps/${code}`);
  return data;
}

export async function getWorkflowStepByUser(code) {
  const { data } = await axiosAuth.get(`/api/Workflow/StepsByCurrentUser/${code}`);
  return data;
}

export async function getWorkflowHistory(code, seq) {
  const { data } = await axiosAuth.get(`/api/Workflow/History/${code}/${seq}`);
  return data;
}

export async function postInitWorkflow(param) {
  const { data } = await axiosAuth.post("/api/Workflow", param);
  return data;
}

export async function updateWorkflowDetail(param) {
  const { data } = await axiosAuth.put(`/api/Workflow/Detail/${param.WfCode}`, param);
  return data;
}

export async function updateStatusWorkflow(param) {
  const { data } = await axiosAuth.put(`/api/Workflow/SetActive/${param.WfCode}?isActive=${param.Active}`);
  return data;
}

export async function approveWorkflow(code, param) {
  const { data } = await axiosAuth.put(`/api/Workflow/Approve/${code}`, param);
  return data;
}

export async function approveWorkflowBatch(code, param) {
  const { data } = await axiosAuth.put(`/api/Workflow/ApproveBatch/${code}`, param);
  return data;
}
