import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, IconButton, Typography, Chip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function ScrollDialog(props) {
  const { open, close } = props;

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={close}>
          Changelog
        </DialogTitle>
        <DialogContent dividers>
          {window.VersionList.map((item, index) => (
            <div key={index}>
              <div>
                <Chip label={item.Version} color="primary" />
                <Typography variant="caption" style={{ paddingLeft: 10 }}>
                  {item.UpdateDate}
                </Typography>
              </div>
              <div style={{ marginTop: "10px", textWrap: "pretty" }}>
                <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                  {item.Announce.map((i) => i).join("\n")}
                </DialogContentText>
                {item["New Features"].length > 0 && (
                  <>
                    <Typography component="h6" variant="h6">
                      New Features
                    </Typography>
                    <ul>
                      {item["New Features"].map((i, idx) => (
                        // <li key={idx}>{i}</li>
                        <li key={idx} dangerouslySetInnerHTML={{ __html: i }} />
                      ))}
                    </ul>
                  </>
                )}
                {item["Enhancements"].length > 0 && (
                  <>
                    <Typography component="h6" variant="h6">
                      Enhancements
                    </Typography>
                    <ul>
                      {item["Enhancements"].map((i, idx) => (
                        <li key={idx}>{i}</li>
                      ))}
                    </ul>
                  </>
                )}
                {item["Deprecated"].length > 0 && (
                  <>
                    <Typography component="h6" variant="h6">
                      Deprecated
                    </Typography>
                    <ul>
                      {item["Deprecated"].map((i, idx) => (
                        <li key={idx}>{i}</li>
                      ))}
                    </ul>
                  </>
                )}
                {item["Removed"].length > 0 && (
                  <>
                    <Typography component="h6" variant="h6">
                      Removed
                    </Typography>
                    <ul>
                      {item["Removed"].map((i, idx) => (
                        <li key={idx}>{i}</li>
                      ))}
                    </ul>
                  </>
                )}
                {item["Bug Fixes"].length > 0 && (
                  <>
                    <Typography component="h6" variant="h6">
                      Bug Fixes
                    </Typography>
                    <ul>
                      {item["Bug Fixes"].map((i, idx) => (
                        <li key={idx}>{i}</li>
                      ))}
                    </ul>
                  </>
                )}
                {item.LinkDoc && (
                  <div style={{ textAlign: "center" }}>
                    {/* <Button
                      style={{ textTransform: "none" }}
                      variant="text"
                      onClick={() => window.open(window.VersionList[0].LinkDoc, "_blank").focus()}
                    >
                      more detail
                    </Button> */}
                    <a href={`${item.LinkDoc}`} target="_blank" rel="noreferrer">
                      more detail
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
}
