import React, { useContext, useState } from "react";
import { useTranslate } from "react-admin";
import html2canvas from "html2canvas";
import { GblContext } from "providers/formatter";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  Icon,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import ConfigDashboard from "./Dialog/ConfigDashboard";
import DisplayValuePercent from "components/DisplayValuePercent";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import { deleteWidgetById } from "services/callStoreProcedure";

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  cardContent: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
  textRight: {
    paddingRight: 0,
    textAlign: "right",
  },
  option: {
    width: 500,
    fontSize: 14,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

const IncomeChart = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { NumberFormat } = useContext(GblContext);
  const [param, setParam] = useState({
    Id: props.Id,
    //date: props.date,
    name: props.name,
    Seq: props.Seq,
    FromDate: new Date(props.FromDate),
    Width: props.Width,
    configName: props.configName,
    Config: props.Config,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openWidgetMenu = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, data, dragHandle } = props;

  const updateChart = async (param) => {
    setLoading(true);
    let paramChart = {
      ...props,
      Id: param.Id,
      //date: param.date,
      name: param.name,
      Seq: parseInt(param.Seq),
      FromDate: param.FromDate,
      Width: param.Width,
      Config: param.Config,
    };
    //if (param.date !== "Invalid Date") {
    props.updateChart(paramChart);
    //}
  };

  const closeConfig = () => {
    setOpen(false);
  };

  function downloadBase64File(linkSource, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const SaveDashboardToImg = (id) => {
    const divBox = document.getElementById(id);
    html2canvas(divBox, {
      backgroundColor: "white",
      ignoreElements: (element) => {
        /* Remove element with id="MyElementIdHere" */
        if ("noPrint" === element.id) {
          return true;
        }
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      downloadBase64File(imgData, name);
    });
  };

  const columns = [
    {
      label: translate("ra.field.Name"),
      name: "Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      label: translate("ra.field.Dept."),
      name: "DeptCode",
      options: {
        filter: false,
        display: false,
        customBodyRender: (value, tableMeta) => {
          if (value === "All" || tableMeta.tableData[tableMeta.rowIndex]?.DeptDesc === null) {
            return "All";
          } else {
            return `${value} : ${tableMeta.tableData[tableMeta.rowIndex]?.DeptDesc}`;
          }
        },
      },
    },
    {
      label: translate("ra.field.MTD"),
      name: "MTD",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      label: translate("ra.field.YTD"),
      name: "YTD",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      label: translate("ra.field.YTD-Last Year"),
      name: "YTD_LYear",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      label: translate("ra.field.Variance (%)"),
      name: "YTDRate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customHeadLabelRender: (columnMeta) => {
          return (
            <Tooltip title="% Increase between YTD & YTD last year" placement="top">
              <div>{columnMeta.label}</div>
            </Tooltip>
          );
        },
        customBodyRender: (value) => {
          return <DisplayValuePercent value={value} />;
        },
      },
    },
    {
      label: translate("ra.field.YTD Budget"),
      name: "Budget",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      label: translate("ra.field.Variance (%)"),
      name: "BudgetRate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customHeadLabelRender: (columnMeta) => {
          return (
            <Tooltip title="% Increase between YTD & YTD Budget" placement="top">
              <div>{columnMeta.label}</div>
            </Tooltip>
          );
        },
        customBodyRender: (value) => {
          return <DisplayValuePercent value={value} />;
        },
      },
    },
  ];

  const options = {
    elevation: 0,
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    viewColumns: false,
    download: false,
    filter: false,
    print: false,
    search: false,
    pagination: false,
    // customToolbar: () => {
    //   return (
    //     <>
    //       <IconButton aria-label="settings" onClick={() => setOpen(true)}>
    //         <MoreVertIcon />
    //       </IconButton>
    //       {open && (
    //         <ConfigDashboard
    //           open={open}
    //           close={closeConfig}
    //           update={updateChart}
    //           name={newTitle}
    //           configName={configName}
    //           updateName={(e) => setNewTitle(e)}
    //           field={FieldForm}
    //         />
    //       )}
    //     </>
    //   );
    // },
    customToolbar: null,
  };

  if (!props.data) {
    return "";
  }

  return (
    <>
      {loading ? (
        <div className={classes.circulLoading}>
          <CircularProgress />
        </div>
      ) : (
        <div id={name}>
          <Card className={classes.card} elevation={6}>
            <CardHeader
              className={classes.cardHeader}
              action={
                <div id="noPrint">
                  <IconButton aria-label="settings" onClick={() => SaveDashboardToImg(name)}>
                    <Icon>save_alt_icon</Icon>
                  </IconButton>
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      param.FromDate = addDays(new Date(), -1);
                      updateChart(param);
                    }}
                  >
                    <Icon>refresh</Icon>
                  </IconButton>
                  <IconButton
                    id="basic-button"
                    aria-controls={openWidgetMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openWidgetMenu ? "true" : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <IconButton id="drag-button" style={{ cursor: "row-resize" }}>
                    {dragHandle}
                  </IconButton>
                  {open && (
                    <ConfigDashboard
                      open={open}
                      close={closeConfig}
                      updateChart={updateChart}
                      param={param}
                      updateParam={setParam}
                    />
                  )}
                </div>
              }
              title={
                <Typography variant="body1">
                  <b>{name}</b>
                </Typography>
              }
              subheader={
                <Typography variant="body2">
                  {param.FromDate &&
                    `${
                      param.FromDate.getMonth() < 9
                        ? `0${(param.FromDate.getMonth() + 1)}`
                        : param.FromDate.getMonth() + 1
                    }/${param.FromDate.getFullYear()}`}
                </Typography>
              }
            />
            <Menu id="widget-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem
                key={1}
                onClick={() => {
                  setOpen(true);
                  setAnchorEl(null);
                }}
              >
                Edit Widget
              </MenuItem>
              <MenuItem
                key={2}
                onClick={() => {
                  let msg = "Are you sure delete this widget?";
                  SnackbarUtils.loadingConfirm(
                    msg,
                    async function () {
                      await deleteWidgetById(props.Id);
                      return props.Id;
                    },
                    async function (id) {
                      props.removeChart(id);
                    }
                  );
                  setAnchorEl(null);
                }}
              >
                Delete Widget
              </MenuItem>
            </Menu>
            <CardContent className={classes.cardContent} style={{ padding: 0 }}>
              <MUIDataTable data={data} columns={columns} options={options} />
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default IncomeChart;
