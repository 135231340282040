import axiosAuth from "../utils/request";

export async function getEnumApInvoiceStatus() {
  const { data } = await axiosAuth.get("/api/enum/EnumApInvoiceStatus");
  return data;
}

export async function getEnumWorkflowModule() {
  const { data } = await axiosAuth.get("/api/enum/EnumWorkflowModule");
  return data;
}
