/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import { useLocale, useTranslate } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import { Grid, Divider, Typography, TextField } from "@material-ui/core";
import { TextFieldInForm } from "components/Form";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import {
  getDepartmentDetail,
  createDepartmentDetail,
  updateDepartmentDetail,
  delDepartmentDetail,
  getAccountCodeList,
} from "services/setting";
import MultiSelectListBox from "react-multiselect-listbox";
import Model from "models/department";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogItem = (props) => {
  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();
  const { children, id, mode, setMode, open, onClose } = props;
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [data, setData] = useStateWithCallbackLazy(Model);
  const [lookupList, setLookupList] = useState([]);
  const [defaultAcc, setDefaultAcc] = useState([]);
  const [vdefaultAcc, setVdefaultAcc] = useState([]);
  const [vdefaultAccLength, setVdefaultAccLength] = useState([]);
  const methods = useForm({ defaultValues: data });

  const { handleSubmit, reset } = methods;

  const fetchAccLookup = async () => {
    const { Data } = await getAccountCodeList();
    if (Data) {
      Data.forEach((item) => {
        item.AccFullName =
          locale === "en-US" ? item.AccCode + " : " + item.Description : item.AccCode + " : " + item.Description2;
      });
    }

    setLookupList(Data);
  };

  const fetchDetailById = useCallback(async () => {
    if (id && id !== 0) {
      const response = await getDepartmentDetail(id);
      if (response) {
        if (response.DefaultAccount !== "" && response.DefaultAccount !== "[]") {
          let newArr = [];
          let daccList = response?.DefaultAccount ? JSON.parse(response.DefaultAccount) : [];
          daccList.forEach((item) => {
            newArr.push(item.AccCode + " : " + item.Description);
          });
          setDefaultAcc(newArr);
          var formattedString = newArr.toString().split(",").join(",\n");
          setVdefaultAcc(formattedString);
          setVdefaultAccLength(newArr.length);
        }
        setData(response);
        reset(response);
      }
    } else {
      setData(Model);
      reset(Model);
    }
    setBtnLoading(false);
  }, [id, reset]);

  useEffect(() => {
    fetchAccLookup();
    fetchDetailById();
  }, [fetchDetailById]);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    let newArr = [];
    if (defaultAcc.length > 0) {
      defaultAcc.forEach((item) => {
        newArr.push({
          AccCode: item.split(" : ")[0],
          Description: item.split(" : ")[1],
        });
      });
    }

    //Adjust parameter before save
    setData(
      (state) => ({
        ...state,
        ...values,
        DefaultAccount: JSON.stringify(newArr),
        UserModified: Model.UserModified,
      }),
      (nextState) => Save(nextState)
    );
  };

  const Save = async (values) => {
    if (mode === "edit") {
      values.Id = id;
      //Update
      const { Code, InternalMessage, UserMessage } = await updateDepartmentDetail(values);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage);
        handleClose(id);
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    } else {
      const { Code, InternalMessage, UserMessage } = await createDepartmentDetail(values);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage);
        handleClose(parseInt(InternalMessage));
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    }
  };

  const handleClose = (value) => {
    onClose(value);
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
      disabled: mode !== "view",
    },
    {
      name: "Delete",
      fnc: () => DelOrVoid(id),
      disabled: mode !== "view",
    },
  ];

  const DelOrVoid = async (id) => {
    let msg = translate("ra.question.confirmDel");
    SnackbarUtils.delConfirm(msg, async function () {
      const { Code, UserMessage } = await delDepartmentDetail(id);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage);
        handleClose(id);
      }
    });
  };

  const CancelFnc = () => {
    if (id === 0) {
      onClose();
      return;
    }
    fetchDetailById();
    setMode("view");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event);
          }
        }}
        scroll={"paper"}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title" onClose={onClose}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <ActionMenu menuControl={menuControlProp} justifyContent="flex-start" />
          <Divider />
          <form onKeyDown={disableFormEnter}>
            <div className={classes.root}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={6} elevation={2}>
                  <TextFieldInForm
                    label={`* ${translate("ra.field.Code")}`}
                    name="DeptCode"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    disabled={mode !== "add"}
                    rule={{
                      required: {
                        value: true,
                        message: "* Required",
                      },
                      maxLength: {
                        value: 10,
                        message: "maximum length is 10",
                      },
                    }}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} elevation={2}>
                  <TextFieldInForm
                    label={translate("ra.field.Description")}
                    name="Description"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    disabled={mode === "view"}
                    rule={{
                      maxLength: {
                        value: 255,
                        message: "maximum length is 255",
                      },
                    }}
                  />
                </Grid>
                {mode === "view" && (
                  <Grid item xs={12} elevation={2}>
                    <TextField
                      style={{ width: "100%" }}
                      label={translate("ra.fieldAbbr.SelectAccountCode")}
                      variant="outlined"
                      value={vdefaultAcc}
                      margin="dense"
                      multiline
                      rows={vdefaultAccLength + 2}
                      disabled
                    />
                  </Grid>
                )}
              </Grid>
            </div>
            {children}
          </form>
          <div className={classes.root}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} elevation={2}>
                {mode !== "view" && (
                  <>
                    <Typography variant="h6">{translate("ra.fieldAbbr.SelectAccountCode")}</Typography>
                    <br />
                    <MultiSelectListBox
                      className={"multi-select"}
                      overrideStrings={{
                        search: "Search...",
                        selectAll: "Select All",
                        removeAll: "Remove All",
                        selectedInfo: "Items selected",
                      }}
                      sortable={true}
                      options={lookupList}
                      textField="AccFullName"
                      valueField="AccFullName"
                      value={defaultAcc}
                      rowHeight={30}
                      onSelect={({ item, sortedList }) => {
                        setDefaultAcc(sortedList.map((i) => i.AccFullName));
                      }}
                      onRemove={({ item }) => {
                        setDefaultAcc([...defaultAcc.filter((i) => i !== item.AccFullName)]);
                      }}
                      onSelectAll={(selectedItems) => {
                        if (selectedItems.length > 0) {
                          const selected = [...selectedItems.map((item) => item.AccFullName)];
                          setDefaultAcc(selected);
                        }
                      }}
                      onRemoveAll={() => setDefaultAcc([])}
                      onSort={({ sortedList }) => setDefaultAcc([...sortedList.map((i) => i.AccFullName)])}
                      onSearch={({ items, textField, query }) =>
                        items.filter((i) => i.AccFullName.toLowerCase().includes(query.toLowerCase()))
                      }
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
        </DialogContent>
        {mode !== "view" ? (
          <DialogActions>
            <ButtonFooter noBorder disabled={isBtnLoading} SaveFnc={handleSubmit(onSubmit)} CancelFnc={CancelFnc} />
          </DialogActions>
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
};

export default DialogItem;
