import { format } from "date-fns";

export default {
  InvhSeq: "Auto",
  VnCode: "",
  InvhDate: new Date(),
  InvhDesc: "",
  InvhSource: "",
  InvhInvNo: "",
  InvhInvDate: new Date(),
  InvhDueDate: new Date(),
  InvhCredit: 0,
  InvhRef: "",
  CurCode: "",
  CurRate: 0,
  InvhTaxT1: "None",
  InvhTaxR1: 0,
  InvhTaxT2: "None",
  InvhTaxR2: 0,
  InvhTotalAmt: 0,
  InvhTInvNo: "",
  InvhTInvDt: new Date(),
  TaxPeriod: format(new Date(), "MM/yyyy"),
  TaxStatus: "Pending",
  TaxId: "",
  BranchNo: "",
  InvWht: {
    WhtTypeCode: "",
    WhtTotalAmt: 0,
    WhtDeptCode: "",
    WhtRemark1: "",
    WhtRemark2: "",
    WhtNo: "",
    WhtTaxCr: "",
    Items: [
      {
        Id: 0,
        InvhSeq: 0,
        SeqNo: 0,
        WhtCode: "",
        WhtDesc: "",
        WhtRate: 0,
        Amount: 0,
        TaxAmt: 0,
      },
    ],
  },
  Detail: [],
  DimHList: {
    Dim: [],
  },
  UserModified: localStorage.getItem("UserName"),
};
