import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import TableS from "./table";
import { getFinancialReport, getFinancialReportList } from "services/financialdata";
import SortTree from "./sortTableTree";
import { Loading } from "react-admin";
import { getDepartmentList } from "services/setting";
import ListBox from "components/ListBox";
import PopperListBox from "components/PopperListBox";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { matchSorter } from "match-sorter";

const List = () => {
  const [isLoading, setLoading] = useState(true);
  const [selectedReport, setSelectedReport] = useState("PL");
  const [reportData, setReportData] = useState();
  const [titleHead, setTitleHead] = useState("Profits and loss");
  const [lookupData, setLookupData] = useState();
  const [lookupDept, setLookupDept] = useState();
  const [period, setPeriod] = useState(new Date());
  const [revision, setRevision] = useState(0);
  const [department, setDepartment] = useState();

  const fetchData = async (type) => {
    setLoading(true);
    const lookupData = await getFinancialReportList();
    setLookupData(lookupData);
    const getIdReport = lookupData.find((i) => i.Code === type)?.Id;

    let param = {
      ReportId: getIdReport,
      Year: period.getFullYear(),
      Period: period.getMonth() + 1,
      Revision: revision,
      Department: [],
    };
    try {
      switch (type) {
        case "PL":
          const plData = await getFinancialReport(param);
          setReportData(plData);
          setLoading(false);
          break;
        case "BS":
          const { Data } = await getDepartmentList();
          setLookupDept(Data);
          if (department) {
            param.Department = [department.DeptCode];
          } else {
            param.Department = [Data[0].DeptCode];
          }

          const bsData = await getFinancialReport(param);
          setReportData(bsData);
          setLoading(false);
          break;
        default:
          setLoading(false);
          break;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(selectedReport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department, period, revision, selectedReport]);

  const SwitchFormat = (name) => {
    switch (name) {
      case "Setting":
        return <SortTree lookupData={lookupData} />;
      default:
        return <TableS dataTable={reportData} title={titleHead} />;
    }
  };

  const handleButtonClick = (buttonName) => {
    if (buttonName === "PL") {
      setTitleHead("Profits and Loss");
    }
    if (buttonName === "BS") {
      setTitleHead("Balance Sheet");
    }
    setSelectedReport(buttonName);
  };

  if (isLoading) return <Loading />;

  const filterOptions = (options, { inputValue }, optKey, optDesc) => {
    return matchSorter(options, inputValue, {
      keys: [`${optKey}`, `${optDesc}`],
    });
  };

  return (
    <>
      <Box height={20} />
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {lookupData &&
              lookupData.map((item, idx) => (
                <Button
                  key={idx}
                  onClick={() => handleButtonClick(`${item.Code}`)}
                  variant={selectedReport === item.Code ? "contained" : "outlined"}
                >
                  {item.Name}
                </Button>
              ))}
            <Button
              onClick={() => handleButtonClick("Setting")}
              variant={selectedReport === "Setting" ? "contained" : "outlined"}
            >
              {"Setting"}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          {selectedReport !== "Setting" && (
            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
              {selectedReport === "BS" && lookupDept && (
                <Autocomplete
                  options={lookupDept}
                  disableClearable
                  disableListWrap
                  defaultValue={lookupDept[0]}
                  value={department}
                  onChange={(e, newItem) => {
                    setDepartment(newItem);
                  }}
                  ListboxComponent={ListBox}
                  PopperComponent={PopperListBox}
                  getOptionLabel={(option) => {
                    return typeof option === "object" ? option.DeptCode : option;
                  }}
                  getOptionSelected={(option, value) => option.DeptCode === value.DeptCode}
                  renderOption={(option, { inputValue }) => {
                    const mergestring = `${option.DeptCode} : ${option.Description}`;

                    const matches = match(mergestring, inputValue);
                    const parts = parse(mergestring, matches);
                    return (
                      <div>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}
                      </div>
                    );
                  }}
                  style={{
                    width: 210,
                    display: "inline-flex",
                    marginRight: 20,
                  }}
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Department"
                      margin="dense"
                      placeholder="Department"
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    filterOptions(options, { inputValue }, "DeptCode", "Description")
                  }
                />
              )}

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableFuture
                  openTo="year"
                  format={"MM/yyyy"}
                  views={["year", "month"]}
                  inputVariant="outlined"
                  margin="dense"
                  label="Select Period"
                  value={period}
                  onChange={setPeriod}
                  autoOk={true}
                  okLabel=""
                  cancelLabel=""
                  inputProps={{
                    style: { padding: 10 },
                  }}

                  //animateYearScrolling
                />
              </MuiPickersUtilsProvider>

              <FormControl variant="outlined" margin="dense" fullWidth style={{ width: 210, marginLeft: 20 }}>
                <InputLabel id="size-select">Select Budget (Revision)</InputLabel>
                <Select
                  labelId="size-select"
                  value={revision}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setRevision(e.target.value);
                  }}
                  label="Select Budget (Revision)"
                  style={{
                    width: 210,
                    padding: 2,
                  }}
                >
                  {[
                    { id: 0, name: "Annual" },
                    { id: 1, name: "Reivision 1" },
                    { id: 2, name: "Reivision 2" },
                    { id: 3, name: "Reivision 3" },
                    { id: 4, name: "Reivision 4" },
                  ].map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Grid>
      </Grid>

      <Box height={20} />
      {SwitchFormat(selectedReport)}
    </>
  );
};

export default List;
