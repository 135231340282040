/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Divider, Typography, Dialog, CircularProgress } from "@material-ui/core";
import { TextFieldInForm } from "components/Form";
import { useForm } from "react-hook-form";
import { FileIcon, defaultStyles } from "react-file-icon";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonUpload from "components/ButtonUpload";
import ButtonFooter from "components/ButtonFooter";
import {
  sendArInvoiceSendEmail,
  getArInvoiceEmailTempById,
  sendArReceiptSendEmail,
  getArReceiptEmailTempById,
} from "services/accountReceivable";
import { sendApPaymentSendEmail, getApPaymentEmailTempById } from "services/accountPayable";
import EmailEditor from "react-email-editor";
import fileReader from "utils/fileReader";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  rootTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonImage: {
    position: "relative",
    right: theme.spacing(-5),
    top: theme.spacing(-12),
    color: theme.palette.grey[500],
  },
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
  iconSize: {
    width: 48,
    textAlign: "center",
    cursor: "pointer",
  },
}));

const DialogSendEmail = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { onClose, open, data, keyId, module, title } = props;
  const [emailTemplate, setEmailTemplate] = useState();
  const [loading, setLoading] = useState(true);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [rndString, setRndString] = useState();
  const methods = useForm({ defaultValues: data });
  const { handleSubmit, reset } = methods;
  const emailEditorRef = React.useRef(null);

  const onSubmit = async () => {
    setBtnLoading(true);
    //emailTemplate.Body = emailTemplate.Body.replace(/\n/g, "<br/>");
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { html } = data;
      emailTemplate.Body = html;
    });

    let r;
    if (module === "AR_Invoice") {
      r = await sendArInvoiceSendEmail(emailTemplate);
    } else if (module === "AR_Receipt") {
      r = await sendArReceiptSendEmail(emailTemplate);
    } else if (module === "AP_Payment") {
      r = await sendApPaymentSendEmail(emailTemplate);
    } else {
      r = "";
    }

    if (r?.Code === 0) {
      setBtnLoading(false);
      onClose(r.Code, r.UserMessage);
    } else {
      setBtnLoading(false);
      //onClose(r.Code, r.UserMessage);
    }
  };

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.rootTitle} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const fetchEmailTempById = useCallback(async () => {
    let response;
    if (module === "AR_Invoice") {
      response = await getArInvoiceEmailTempById(data[keyId]);
    } else if (module === "AR_Receipt") {
      response = await getArReceiptEmailTempById(data[keyId]);
    } else if (module === "AP_Payment") {
      response = await getApPaymentEmailTempById(data[keyId]);
    } else {
      response = "";
    }

    if (response) {
      if (!response.MailFrom) {
        setEmailTemplate(undefined);
        reset(undefined);
        const msg = "Please contact admin to set up email.";
        SnackbarUtils.error(msg, function () {
          onClose();
        });
        return;
      }
      response.MailCc = response.MailCc == null ? "" : response.MailCc;
      response.MailTo = data.Email;
      setEmailTemplate(response);
      reset(response);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mounted = true;
    fetchEmailTempById(mounted);
    const r = (Math.random() + 1).toString(36).substring(7);
    setRndString(r);
    return function cleanup() {
      mounted = false;
    };
  }, [fetchEmailTempById]);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const handleCancel = () => {
    onClose();
  };

  const clickFile = async (item) => {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = window.atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };
    const typeImage = ["bmp", "jpg", "jpeg", "png", "gif"];
    if (typeImage.includes(item.FileExtension)) {
      const blob = b64toBlob(item.FileBase64, `image/${item.FileExtension}`);
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank").focus();
    } else {
      const view = b64toBlob(item.FileBase64, item.FileExtension);
      const blob = new Blob([view], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank").focus();
    }
  };

  const uploadFile = async (e) => {
    if (e.target.files.length > 0) {
      const msg = fileReader.CheckLimitImgSize(e.target.files);
      if (msg) {
        document.getElementById("fileAttach").value = "";
        SnackbarUtils.error(translate(msg, { size: "5" }));
        return;
      }
      const fileArray = Array.from(e.target.files);
      const newFileArray = [];
      const filePathsPromises = [];
      fileArray.forEach((file) => {
        const typeImage = ["bmp", "jpg", "jpeg", "png", "gif"];
        if (typeImage.includes(file.type)) {
          filePathsPromises.push(fileReader.ToBase64(file));
        } else {
          filePathsPromises.push(fileReader.ToURLBase64(file));
        }
      });
      const base64Array = await Promise.all(filePathsPromises);
      base64Array.forEach((base64Item, idx) => {
        newFileArray.push({
          FileName: fileArray[idx].name,
          FileBase64: base64Item,
          FileExtension: fileArray[idx].type.split("/")[1],
          FileType: fileArray[idx].type,
        });
      });
      if (emailTemplate.Attachments) {
        setEmailTemplate((state) => ({
          ...state,
          Attachments: [...state.Attachments].concat(newFileArray),
        }));
      } else {
        setEmailTemplate((state) => ({
          ...state,
          Attachments: newFileArray,
        }));
      }
    }
  };

  const removeFile = (item) => {
    const idx = emailTemplate.Attachments.indexOf(item);
    const temp = [...emailTemplate.Attachments];

    // removing the element using splice
    temp.splice(idx, 1);

    // updating the list
    setEmailTemplate((state) => ({
      ...state,
      Attachments: temp,
    }));
  };

  const onReady = () => {
    if (emailTemplate.Layout) {
      emailEditorRef.current.editor.loadDesign(emailTemplate.Layout);
    }
  };

  return (
    <Dialog maxWidth="lg" aria-labelledby="confirmation-dialog-title" open={open} scroll={"paper"}>
      <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
        {title}
      </DialogTitle>

      <DialogContent dividers>
        <form onKeyDown={disableFormEnter}>
          {loading ? (
            <div className={classes.circulLoading}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <TextFieldInForm
                fullWidth
                variant="outlined"
                margin="dense"
                label="ToEmail *"
                type="Email"
                name="MailTo"
                methods={methods}
                autoComplete={rndString}
                onChange={(e) =>
                  setEmailTemplate((state) => ({
                    ...state,
                    MailTo: e.target.value,
                  }))
                }
                rule={{
                  required: {
                    value: true,
                    message: "* Required",
                  },
                }}
              />
              <TextFieldInForm
                fullWidth
                variant="outlined"
                margin="dense"
                label="CcEmail"
                type="Email"
                name="MailCc"
                methods={methods}
                autoComplete="off"
                onChange={(e) =>
                  setEmailTemplate((state) => ({
                    ...state,
                    MailCc: e.target.value,
                  }))
                }
              />
              <TextFieldInForm
                fullWidth
                variant="outlined"
                margin="dense"
                label="Subject *"
                type="text"
                name="Subject"
                methods={methods}
                onChange={(e) =>
                  setEmailTemplate((state) => ({
                    ...state,
                    Subject: e.target.value,
                  }))
                }
                rule={{
                  required: {
                    value: true,
                    message: "* Required",
                  },
                }}
              />
              {/* <TextFieldInForm
                fullWidth
                variant="outlined"
                margin="dense"
                label="Content"
                type="text"
                multiline
                rows="10"
                name="Body"
                methods={methods}
                emailBox
                onChange={(e) =>
                  setEmailTemplate((state) => ({
                    ...state,
                    Body: e.target.value,
                  }))
                }
              /> */}

              {emailTemplate.Layout && <EmailEditor ref={emailEditorRef} onReady={onReady} minHeight={400} />}

              <Box p={1}>
                <Typography variant="body1">Attachments</Typography>
              </Box>
              <Divider style={{ marginBottom: 10 }} />
              <ButtonUpload
                id="fileAttach"
                name="fileAttach"
                style={{ display: "none" }}
                type="file"
                onChange={uploadFile}
              />
              <br />
              <Grid container spacing={1} style={{ marginTop: 10 }}>
                {emailTemplate?.Attachments?.map((item, idx) => (
                  <Grid item xs={3}>
                    <div className={classes.iconSize} onClick={() => clickFile(item)}>
                      <FileIcon extension={item.FileExtension} {...defaultStyles[item.FileExtension]} />
                      {item.FileName}
                      {/* <button   onClick={() => removeFile(item)}>remove</button> */}
                    </div>
                    <IconButton
                      aria-label="close"
                      className={classes.closeButtonImage}
                      onClick={() => removeFile(item)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonFooter noBorder disabled={isBtnLoading} SaveFnc={handleSubmit(onSubmit)} CancelFnc={handleCancel} />
      </DialogActions>
    </Dialog>
  );
};

export default DialogSendEmail;
