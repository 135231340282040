import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Select,
	MenuItem,
} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
	textCancel: {
		color: "inherit",
		border: "1px solid rgba(0, 0, 0, 0.23)",
	},
	wrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const DialogOption = (props) => {
	const classes = useStyles();
	const { headerText, open, onClose, options, isPeriodDialog, isLoading } =
		props;
	const [taxPeriod, setTaxPeriod] = React.useState(
		format(new Date(), "MM/yyyy"),
	);
	const [selectedOption, setSelectedOption] = React.useState(options[0]);
	const radioGroupRef = React.useRef(null);

	const handleEntering = () => {
		if (radioGroupRef.current != null) {
			radioGroupRef.current.focus();
		}
	};

	const handleCancel = () => {
		onClose();
	};

	const handleOk = (e) => {
		e.preventDefault();
		if (isPeriodDialog) {
			onClose(taxPeriod);
			return;
		}
		onClose(selectedOption);
	};

	const handleTypeChange = (e, value) => {
		e.persist();
		const name = e.target.name;
		setSelectedOption({ ...selectedOption, [name]: value });
	};

	const handlePeriodChange = (e, value) => {
		setTaxPeriod(e.target.value);
	};

	const LoadingButton = ({ text, disabled, fnc }) => {
		return (
			<div className={classes.wrapper}>
				<Button
					variant="contained"
					color="primary"
					disabled={disabled}
					onClick={fnc}
				>
					{text}
				</Button>
				{disabled && (
					<CircularProgress size={24} className={classes.buttonProgress} />
				)}
			</div>
		);
	};

	return (
		<Dialog
			disableEscapeKeyDown
			maxWidth="xs"
			TransitionProps={handleEntering}
			aria-labelledby="confirmation-dialog-title"
			open={open}
			onClose={(event, reason) => {
				if (reason !== "backdropClick") {
					onClose(event);
				}
			}}
		>
			<DialogTitle id="confirmation-dialog-title">{headerText}</DialogTitle>
			<DialogContent dividers>
				{isPeriodDialog ? (
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="label">Period</InputLabel>
						<Select
							labelId="label"
							variant="outlined"
							margin="dense"
							label="Period"
							style={{ width: 160, margin: "4px 0" }}
							value={taxPeriod}
							onChange={handlePeriodChange}
						>
							{options
								? options.map((item) => (
										<MenuItem key={item?.toString()} value={item}>
											{item}
										</MenuItem>
								  ))
								: ""}
						</Select>
					</FormControl>
				) : (
					<RadioGroup
						ref={radioGroupRef}
						aria-label="Type"
						name="value"
						value={selectedOption?.value}
						onChange={handleTypeChange}
					>
						{options.map((option, idx) => (
							<FormControlLabel
								label={option.desc}
								key={option.value}
								value={option.value}
								control={<Radio />}
							/>
						))}
					</RadioGroup>
				)}
			</DialogContent>
			<DialogActions>
				<LoadingButton text="OK" disabled={isLoading} fnc={handleOk} />
				<Button
					variant="outlined"
					className={classes.textCancel}
					onClick={handleCancel}
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogOption;
