/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { Loading } from "react-admin";
import { useTheme } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, DialogActions, FormControl, InputLabel, Select, MenuItem, TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import { getVatReconcile, addBatchVatReconcile } from "services/reconcile";
import { showReportByName } from "pages/Report/services";
import { Grid, Icon, IconButton } from "@material-ui/core";
import { MTableToolbar } from "material-table";
import MeterialtableTranslate from "components/MeterialtableTranslate";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DatePickerFormat from "components/DatePickerFormat";
import DialogOptions from "./DialogOptions";
import { InvoiceTaxStatusOptions } from "utils/options";
import { format, startOfMonth, lastDayOfMonth } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import EditVATTab from "./EditVATTab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rootTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    fontWeight: 400,
    textTransform: "none",
    padding: 20,
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
    whiteSpace: "pre",
    lineHeight: 1.3,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textFooter: {
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
}));

function getSteps() {
  return [
    "Reverse Pending Tax Invoice",
    "Add other tax invoices to final Input Tax Report",
    "Input Tax Reconciliation Completed",
  ];
}

const DialogVATRec = (props) => {
  const classes = useStyles();
  const { settingAll, DateToString, StringToDate, NumberFormat, ToMySqlDate, ToNumber } = useContext(GblContext);
  const { SettingAp } = settingAll;
  const { onClose, open, taxPeriodList, translate } = props;
  const theme = useTheme();
  const [selectedRow, setSelectedRow] = useState([]);
  const [Data, setData] = useState();
  const [summaryData, setSummaryData] = useState({
    totalNetAmt: 0,
    totalTaxAmt: 0,
    totalTotalAmt: 0,
  });
  const [isLoading, setLoading] = useState(true);
  const [isLoadingBtn, setLoadingBtn] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [defaultTaxPeriod, setDefaultTaxPeriod] = useState(format(new Date(), "MM/yyyy"));

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  // const menuControlProp = [
  //   {
  //     name: "Print",
  //     fnc: () => {
  //       setOpenDialog(true);
  //     },
  //   },
  // ];

  const fetchSearchList = async (mounted) => {
    const startDay = ToMySqlDate(startOfMonth(new Date()));
    const lastDay = ToMySqlDate(lastDayOfMonth(new Date()));
    const { Data } = await getVatReconcile(startDay, lastDay);
    if (Data) {
      const sumNetAmount = Data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.BfTaxAmt);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumTaxAmount = Data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.TaxAmt);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumTotal = Data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.TotalAmt);
        return NumberFormat(s ?? 0);
      }, 0);
      setSummaryData({
        totalNetAmt: sumNetAmount,
        totalTaxAmt: sumTaxAmount,
        totalTotalAmt: sumTotal,
      });

      Data.filter((i) => (i.InvhTInvDt = DateToString(i.InvhTInvDt)));
      setData(Data);
      setSelectedRow([]);
      setLoadingBtn(false);
    } else {
      setData([]);
      setLoadingBtn(false);
    }
    if (mounted) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchSearchList(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const selectionChange = (rows, selectedRows) => {
    //Select or UnSelect 1 Item
    if (selectedRows) {
      if (selectedRows.tableData.checked) {
        selectedRows.IsSelect = true;
        selectedRows.OldTaxStatus = selectedRows.OldTaxStatus ? selectedRows.OldTaxStatus : selectedRows.TaxStatus;
        selectedRows.OldTaxPeriod = selectedRows.TaxPeriod;
        selectedRows.OldInvhTInvNo = selectedRows.InvhTInvNo;
        selectedRows.OldInvhTInvDt = selectedRows.InvhTInvDt;
        selectedRows.TaxStatus = "Confirm";
        selectedRows.TaxPeriod = defaultTaxPeriod;
      } else {
        selectedRows.IsSelect = false;
        selectedRows.TaxStatus = selectedRows.OldTaxStatus;
        selectedRows.TaxPeriod = selectedRows.OldTaxPeriod;
        selectedRows.InvhTInvNo = selectedRows.OldInvhTInvNo;
        selectedRows.InvhTInvDt = selectedRows.OldInvhTInvDt;
      }
      Data[selectedRows.tableData.id] = selectedRows;
      setData([...Data]);

      const sumNetAmount = Data.filter((i) => i.tableData.checked).reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.BfTaxAmt);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumTaxAmount = Data.filter((i) => i.tableData.checked).reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.TaxAmt);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumTotal = Data.filter((i) => i.tableData.checked).reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.TotalAmt);
        return NumberFormat(s ?? 0);
      }, 0);
      setSummaryData({
        totalNetAmt: sumNetAmount,
        totalTaxAmt: sumTaxAmount,
        totalTotalAmt: sumTotal,
      });
    }
    //Select All
    if (rows.length > 0 && !selectedRows) {
      //Check All
      const newRows = rows.filter((item) => {
        item.IsSelect = true;
        //item.tableData = { checked: true };
        item.OldTaxStatus = item.OldTaxStatus ? item.OldTaxStatus : item.TaxStatus;
        item.OldTaxPeriod = item.TaxPeriod;
        item.OldInvhTInvNo = item.InvhTInvNo;
        item.OldInvhTInvDt = item.InvhTInvDt;
        item.TaxStatus = "Confirm";
        item.TaxPeriod = defaultTaxPeriod;
        return item;
      });
      setData([...newRows]);
    }
    //UnSelect All
    if (rows.length === 0 && !selectedRows) {
      Data.forEach((item) => {
        item.IsSelect = false;
        //item.tableData = { checked: false };
        item.TaxStatus = item.OldTaxStatus;
        item.TaxPeriod = item.OldTaxPeriod;
        item.InvhTInvNo = item.OldInvhTInvNo;
        item.InvhTInvDt = item.OldInvhTInvDt;
      });
      setData([...Data]);
    }
    const arrIndex = Array.from(rows.keys());
    setSelectedRow(arrIndex);
  };

  const columns = [
    {
      title: "Tax Status",
      field: "TaxStatus",
      sorting: true,
      render: (rowData) => {
        if (rowData.tableData.checked) {
          return (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label">{translate("ra.field.Tax Status")}</InputLabel>
              <Select
                labelId="label"
                variant="outlined"
                margin="dense"
                label={translate("ra.field.Tax Status")}
                style={{ width: 120 }}
                value={rowData.TaxStatus}
                onChange={(e) => {
                  rowData.TaxStatus = e.target.value;
                  setData([...Data]);
                }}
              >
                {InvoiceTaxStatusOptions
                  ? InvoiceTaxStatusOptions.filter((x) => x !== "None").map((item, idx) => (
                      <MenuItem key={item?.toString()} value={item}>
                        {item}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
          );
        } else {
          return rowData.TaxStatus;
        }
      },
    },
    {
      title: "Tax Period",
      field: "TaxPeriod",
      sorting: true,
      render: (rowData) => {
        if (rowData.tableData.checked) {
          return (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="label2">{translate("ra.field.Period")}</InputLabel>
              <Select
                labelId="label2"
                variant="outlined"
                margin="dense"
                label={translate("ra.field.Period")}
                style={{ width: 120 }}
                value={rowData.tableData.Period}
                defaultValue={defaultTaxPeriod}
                onChange={(e) => {
                  rowData.TaxPeriod = e.target.value;
                  setData([...Data]);
                }}
              >
                {taxPeriodList
                  ? taxPeriodList.map((item) => (
                      <MenuItem key={item?.toString()} value={item}>
                        {item}
                      </MenuItem>
                    ))
                  : ""}
              </Select>
            </FormControl>
          );
        } else {
          return rowData.TaxPeriod;
        }
      },
    },
    { title: "Invoice No.", field: "InvhInvNo", sorting: true },
    {
      title: "Tax Inv No.",
      field: "InvhTInvNo",
      sorting: true,
      render: (rowData) => {
        if (rowData.tableData.checked) {
          return (
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              label={translate("ra.field.Tax Inv No.")}
              value={rowData.InvhTInvNo}
              error={rowData.InvhTInvNo === ""}
              helperText={rowData.InvhTInvNo === "" ? "* Required" : null}
              onChange={(e) => {
                const value = e.target.value;
                Data[rowData.tableData.id].InvhTInvNo = value;
                setData([...Data]);
              }}
              style={{ width: 140 }}
            />
          );
        } else {
          return rowData.InvhTInvNo;
        }
      },
    },
    {
      title: "Tax Inv Date",
      field: "InvhTInvDt",
      sorting: true,
      render: (rowData) => {
        const value = rowData.InvhTInvDt;
        if (!rowData.InvhTInvDt) {
          return null;
        }
        if (rowData.tableData.checked) {
          let defaultTaxDate = StringToDate(`01/${defaultTaxPeriod}`);
          let endOfMonth = lastDayOfMonth(defaultTaxDate);
          return (
            <DatePickerFormat
              label={translate("ra.field.Tax Inv Date")}
              maxDate={endOfMonth}
              maxDateMessage={"Date should not allow greater than tax period date"}
              value={StringToDate(value)}
              onChange={(e) => {
                Data[rowData.tableData.id].InvhTInvDt = DateToString(e);
                setData([...Data]);
              }}
              style={{ width: 120 }}
            />
          );
        } else {
          //return DateToString(value ?? new Date());
          return value;
        }
      },
    },
    { title: "Vendor", field: "VnCode", sorting: true },
    {
      title: "Name",
      field: "VnName",
      sorting: true,
      cellStyle: { width: "240px" },
    },
    {
      title: "Net Amount",
      field: "BfTaxAmt",
      sorting: true,
      type: "numeric",
      render: (rowData) => NumberFormat(rowData.BfTaxAmt),
    },
    {
      title: "TAX",
      field: "TaxAmt",
      sorting: true,
      type: "numeric",
      render: (rowData) => NumberFormat(rowData.TaxAmt),
    },
    {
      title: "Total",
      field: "TotalAmt",
      sorting: true,
      type: "numeric",
      render: (rowData) => NumberFormat(rowData.TotalAmt),
    },
  ];

  const options = {
    draggable: false,
    headerStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF",
    },
    rowStyle: (item) => {
      if (item.tableData.id % 2) {
        return { backgroundColor: "#f2f2f2" };
      }
    },
    exportButton: {
      csv: true,
      pdf: false,
    },
    exportFileName: "Input Tax Reconciliation",
    exportAllData: true,
    padding: "dense",
    paging: true,
    pageSize: 15,
    pageSizeOptions: [15, 50, 100],
    selection: true,
    selectionProps: () => ({
      color: "primary",
    }),
  };

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.rootTitle} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const ConfirmSetting = () => {
    if (SettingAp.AutoPostingToGl) {
      const msg =
        "Vat reconciliation will automatic reverse and post to General Ledger Tax Reconcile Voucher.  Do you want to proceed?";
      const dialog = window.confirm(msg);
      if (dialog) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const ConfirmToSave = () => {
    const selectedData = Data.filter((item) => item.IsSelect);
    if (selectedData.length > 0) {
      const numOfConfirm = Data.filter((x) => x.TaxStatus === "Confirm").length;
      const numOfPending = Data.filter((x) => x.TaxStatus === "Pending").length;
      const numOfUnclaim = Data.filter((x) => x.TaxStatus === "Unclaimed").length;

      const all = numOfConfirm + numOfPending + numOfUnclaim;

      const msg = `Found updated data : ${all} transactions
      (Y) Confirm : ${numOfConfirm}
      (N) Not Confirm : ${numOfPending}
      (U) Unclaim : ${numOfUnclaim}`;

      const dialog = window.confirm(msg);
      if (dialog) {
        return selectedData;
      } else {
        return [];
      }
    }
  };

  const handleNext = async () => {
    setLoadingBtn(true);
    const findData = Data.filter((item) => item.IsSelect);
    //check field blank
    if (findData.find((i) => i.InvhTInvNo === "")) {
      setLoadingBtn(false);
      return false;
    }

    if (ConfirmSetting()) {
      const selectedData = ConfirmToSave();
      if (selectedData.length > 0) {
        const paramVAT = [];
        selectedData.forEach((item) => {
          paramVAT.push({
            InvhSeq: item.InvhSeq,
            TaxStatus: item.TaxStatus,
            TaxPeriod: item.TaxPeriod,
            GlAutoPost: SettingAp.AutoPostingToGl,
            GlDrAccCode: SettingAp.PostingAccount,
            GlDrDeptCode: SettingAp.PostingDepartment,
            FrDate: item.FrDate,
            ToDate: item.ToDate,
            InvhTInvNo: item.InvhTInvNo,
            InvhTInvDt: StringToDate(item.InvhTInvDt),
            InvhDesc: item.InvhDesc,
            VnName: item.VnName,
            BfTaxAmt: item.BfTaxAmt,
            TaxAmt: item.TaxAmt,
            TotalAmt: item.TotalAmt,
            TaxId: item.TaxId,
            BranchNo: item.BranchNo,
            UserModified: localStorage.getItem("UserName"),
          });
        });
        const { Code, UserMessage, InternalMessage } = await addBatchVatReconcile(paramVAT);
        if (Code === 0) {
          SnackbarUtils.success(UserMessage, function () {
            fetchSearchList();
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          });
        } else {
          SnackbarUtils.warning(InternalMessage, function () {
            fetchSearchList();
          });
        }
      } else {
        setLoading(false);
        setLoadingBtn(false);
      }
    } else {
      setLoading(false);
      setLoadingBtn(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const CustomFilterList = (props) => {
    return (
      <>
        <MTableToolbar {...props} />
        <Box display="flex" style={{ marginTop: props.selectedRows.length > 0 ? 0 : -50 }}>
          <Box p={1}>
            {taxPeriodList ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">{translate("ra.field.Default Tax Period")}</InputLabel>
                <Select
                  labelId="label"
                  variant="outlined"
                  margin="dense"
                  label={translate("ra.field.Default Tax Period")}
                  style={{ width: 160 }}
                  value={defaultTaxPeriod}
                  onChange={(e) => {
                    setDefaultTaxPeriod(e.target.value);
                  }}
                >
                  {taxPeriodList
                    ? taxPeriodList.map((item) => (
                        <MenuItem key={item?.toString()} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </>
    );
  };

  const onCloseDialog = async (period) => {
    if (period) {
      const splitPeriod = period.split("/");
      const frDate = `${splitPeriod[1]}-${splitPeriod[0]}-01`;
      const toDate = `${splitPeriod[1]}-${splitPeriod[0]}-30`;
      const param = [
        { Name: "DateFrom", Value: frDate },
        { Name: "Dateto", Value: toDate },
      ];
      setLoadingBtn(true);
      const status = showReportByName("AP_InputVAT", param, "pdf");

      if (status) {
        setLoadingBtn(false);
      }
    }
    setOpenDialog(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button disabled={disabled || selectedRow.length === 0} variant="contained" color="primary" onClick={fnc}>
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <Dialog fullWidth maxWidth="xl" aria-labelledby="confirmation-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
        {props.title}
      </DialogTitle>
      <DialogContent dividers>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton
                  onClick={() => {
                    setActiveStep(index);
                  }}
                >
                  {translate(`ra.ap.${label}`)}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 && (
          <div style={{ width: "100%", margin: "0 auto" }}>
            {/* <ActionMenu justifyContent="flex-start" menuControl={menuControlProp} /> */}
            <MeterialtableTranslate
              title=""
              columns={columns}
              data={Data}
              options={options}
              isLoading={isLoading}
              onSelectionChange={selectionChange}
              components={{
                Toolbar: CustomFilterList,
              }}
              icons={{
                Export: CloudDownloadIcon,
              }}
            />
            <Grid container justifyContent="flex-end" style={{ paddingTop: 20, gap: 10 }}>
              <Grid item className={classes.textFooter} style={{ width: "calc(126.5px)" }}>
                <TextField
                  label="Sum Net Amount"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    readOnly: true,
                    style: {
                      textAlign: "center",
                      padding: "6px 0px",
                      color: "rgb(52, 85, 139)",
                      fontWeight: "600",
                    },
                  }}
                  value={summaryData.totalNetAmt}
                />
              </Grid>
              <Grid item className={classes.textFooter} style={{ width: "calc(126.5px)" }}>
                <TextField
                  label="Sum TAX"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    readOnly: true,
                    style: {
                      textAlign: "center",
                      padding: "6px 0px",
                      color: "rgb(52, 85, 139)",
                      fontWeight: "600",
                    },
                  }}
                  value={summaryData.totalTaxAmt}
                />
              </Grid>
              <Grid item className={classes.textFooter} style={{ width: "calc(126.5px)" }}>
                <TextField
                  label="Sum Total"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    readOnly: true,
                    style: {
                      textAlign: "center",
                      padding: "6px 0px",
                      color: "rgb(52, 85, 139)",
                      fontWeight: "600",
                    },
                  }}
                  value={summaryData.totalTotalAmt}
                />
              </Grid>
            </Grid>
            {openDialog && (
              <DialogOptions
                open={openDialog}
                onClose={onCloseDialog}
                options={taxPeriodList}
                headerText="Select Period"
                isPeriodDialog
                isLoading={isLoadingBtn}
              />
            )}
          </div>
        )}
        {activeStep === 1 && (
          <div style={{ width: "100%", margin: "0 auto" }}>
            <EditVATTab taxPeriodList={taxPeriodList} />
          </div>
        )}
        {activeStep === 2 && (
          <div style={{ width: "100%", margin: "0 auto" }}>
            {isLoading && <Loading />}
            <Grid container justifyContent="center" alignContent="center" spacing={2}>
              <Box p={1}>
                {taxPeriodList ? (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="label">{translate("ra.field.Tax Period")}</InputLabel>
                    <Select
                      labelId="label"
                      variant="outlined"
                      margin="dense"
                      label={translate("ra.field.Tax Period")}
                      style={{ width: 160 }}
                      value={defaultTaxPeriod}
                      onChange={(e) => {
                        setDefaultTaxPeriod(e.target.value);
                      }}
                    >
                      {taxPeriodList
                        ? taxPeriodList.map((item) => (
                            <MenuItem key={item?.toString()} value={item}>
                              {item}
                            </MenuItem>
                          ))
                        : ""}
                    </Select>
                  </FormControl>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
            <br />
            <Grid container justifyContent="space-between" alignContent="center" spacing={2}>
              <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  classes={{ root: classes.button, label: classes.label }}
                  onClick={async () => {
                    setLoading(true);
                    let status = await showReportByName("AP_VatReconciliation", [
                      { Name: "Period", Value: defaultTaxPeriod },
                    ]);
                    if (status) {
                      setLoading(false);
                    }
                  }}
                >
                  <Icon className="material-icons-outlined" color={"primary"} style={{ fontSize: 40 }}>
                    print
                  </Icon>
                  Print Input TAX Report
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  classes={{ root: classes.button, label: classes.label }}
                  onClick={async () => {
                    setLoading(true);
                    let status = showReportByName(
                      "AP_VatReconciliation",
                      [{ Name: "Period", Value: defaultTaxPeriod }],
                      "xlsx"
                    );
                    if (status) {
                      setLoading(false);
                    }
                  }}
                >
                  <Icon className="material-icons-outlined" color={"primary"} style={{ fontSize: 40 }}>
                    cloud_download
                  </Icon>
                  Export Input TAX xlsx file
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          {activeStep === 0 && (
            <LoadingButton
              text={activeStep === steps.length - 1 ? "Finish" : "Next"}
              disabled={isLoadingBtn}
              fnc={handleNext}
            />
          )}
          {activeStep === 1 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }}
            >
              Finish
            </Button>
          )}
        </>
      </DialogActions>
    </Dialog>
  );
};

export default DialogVATRec;
