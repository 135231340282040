/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import { Loading } from "react-admin";
import AccessDenied from "layout/AccessDenied";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import MUIDataTable from "mui-datatables";
//import DialogItem from "./Dialog/LicenseAddon";
//import DialogUpdate from "./Dialog/LicenseUpdate";
import CardImage from "components/CardImage";
import Logo from "assets/Logo.jpg";
//import EditIcon from "@material-ui/icons/Edit";
import { getCompany, getUserSearchList, getLicenseListWithError } from "services/setting";

const useStyles = makeStyles((theme) => ({
  root: { textAlign: "center" },
  tabPanel: { width: "100%", margin: "0 20px" },
  button: { textTransform: "none" },
}));

const ProductLicense = (props) => {
  const { permissions, value, index } = props;

  let checkPermission = permissions?.find((i) => i.Name === "Sys.ProductLicense")?.View;

  const classes = useStyles();
  const { DateToString } = useContext(GblContext);
  const [data, setData] = useState();
  //const [openDialog, setOpenDialog] = useState(false);
  //const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  //   const [licenseType, setLicenseType] = useState(false);
  //   const [activeModule, setActiveModule] = useState(false);

  const [userActiveCount, setUserActiveCount] = useState();
  const [moduleList, setModuleList] = useState([]);
  const [addOnList, setAddOnList] = useState([]);

  const fetch = async () => {
    const r = await getCompany();
    let uriQueryString = {
      WhereRaw: "Active = true",
    };
    const ul = await getUserSearchList(uriQueryString);

    const ll = await getLicenseListWithError();

    if (r && ul.Data) {
      setData(r);
      setUserActiveCount(ul.Data?.length);
      if (ll) {
        let mList = ll.filter(
          (i) =>
            i.Description === "Income" ||
            i.Description === "AP" ||
            i.Description === "AR" ||
            i.Description === "Asset" ||
            i.Description === "GL"
        );
        setModuleList(mList);
        let aList = ll.filter(
          (i) =>
            i.Description === "Inventory" ||
            i.Description === "POS" ||
            i.Description === "PMS" ||
            i.Description === "HRM"
        );
        const newArr = [];
        aList.forEach((element) => {
          element.ActiveModule.forEach((aItem) => {
            newArr.push({
              ActiveModule: aItem,
              LicenseInterface: {
                ActiveCount: element.LicenseInterface.ActiveCount,
                ExpireDate: element.LicenseInterface.ExpireDate,
              },
            });
          });
        });
        setAddOnList(newArr);
      }
    } else {
      setData([]);
    }
  };

  React.useEffect(() => {
    if (value === index) {
      fetch();
    }
  }, [value]);

  const columns1 = [
    {
      name: "Description",
      label: "Subscription",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "LicenseInterface",
      label: "No. Of Units",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return value.ActiveCount;
        },
      },
    },
    {
      name: "LicenseInterface",
      label: "Expire Date",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          let v = new Date(value.ExpireDate);
          return DateToString(v);
        },
      },
    },
  ];

  const columns2 = [
    {
      name: "ActiveModule",
      label: "Interface Subscription",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          if (Array.isArray(value)) {
            return value.join(", ");
          } else {
            return value;
          }
        },
      },
    },
    {
      name: "",
      label: "Token",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "LicenseInterface",
      label: "No. Of Units",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return value.ActiveCount;
        },
      },
    },
    {
      name: "LicenseInterface",
      label: "Expire Date",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          let v = new Date(value.ExpireDate);
          return DateToString(v);
        },
      },
    },
  ];

  const options1 = {
    tableId: "table1",
    responsive: "standard",
    selectableRows: "none",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    customFooter: () => "",
  };

  const options2 = {
    tableId: "table2",
    responsive: "standard",
    selectableRows: "none",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    customFooter: () => "",
  };

  //   const handleCloseDialog = (status) => {
  //     setOpenDialog(false);
  //     if (status) {
  //       fetch();
  //     }
  //   };
  // const handleCloseUpdateDialog = (status) => {
  // 	setOpenUpdateDialog(false);
  // 	if (status) {
  // 		fetch();
  // 	}
  // };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {checkPermission && value === index ? (
        <>
          {data ? (
            <>
              <CardImage imgSrc={Logo} noBorder={true} customSize={{ width: 200, height: 65 }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1">
                        <b>Register ID :</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2">{data.RegTaxId}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1">
                        <b>Register Name :</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2">{data.RegName}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1">
                        <b>Product ID :</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2">{data.ProductId}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Card className={classes.root} variant="outlined">
                    <CardHeader
                      title={
                        <Typography variant="body1">
                          <b>Business Unit(s)</b>
                        </Typography>
                      }
                    />
                    <CardContent style={{ padding: 6 }}>
                      <Typography gutterBottom>{data.LicenseBuCount}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Card className={classes.root} variant="outlined">
                    <CardHeader
                      title={
                        <Typography variant="body1">
                          <b>Active User(s)</b>
                        </Typography>
                      }
                    />
                    <CardContent style={{ padding: 6 }}>
                      <Typography gutterBottom>
                        {userActiveCount - 1} / {data.LicenseUserCount - 1}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <br />
              <MUIDataTable data={moduleList} columns={columns1} options={options1} />
              <br />
              <MUIDataTable data={addOnList} columns={columns2} options={options2} />
              <br />
              {/* <Button
								variant="contained"
								color="primary"
								onClick={() => setOpenUpdateDialog(true)}
							>
								Update License ...
							</Button> */}
            </>
          ) : (
            <Loading />
          )}
        </>
      ) : (
        <AccessDenied />
      )}

      {/* {openDialog && (
        <DialogItem
          licenseType={licenseType}
          activeModule={activeModule}
          title={"Change Addons"}
          open={openDialog}
          onClose={handleCloseDialog}
        />
      )} */}
      {/* {openUpdateDialog && (
				<DialogUpdate
					title={"Update Product License"}
					open={openUpdateDialog}
					onClose={handleCloseUpdateDialog}
				/>
			)} */}
    </div>
  );
};

export default ProductLicense;
