import axiosAuth from "../utils/request";

export async function getCompany() {
  const { data } = await axiosAuth.get("/api/company");
  return data;
}

export async function updateCompany(param) {
  const { data } = await axiosAuth.put("/api/company", param);
  return data;
}

export async function getSettingAll() {
  const { data } = await axiosAuth.get("/api/setting/all");
  return data;
}

export async function getSettingClosePeriod() {
  const { data } = await axiosAuth.get("/api/setting/closePeriod");
  return data;
}

export async function getSettingSystem() {
  const { data } = await axiosAuth.get("/api/settingSystem");
  return data;
}

export async function getSettingAp() {
  const { data } = await axiosAuth.get("/api/settingAp");
  return data;
}

export async function updateSettingAp(param) {
  const { data } = await axiosAuth.post("/api/settingAp", param);
  return data;
}

export async function getSettingAr() {
  const { data } = await axiosAuth.get("/api/settingAr");
  return data;
}

export async function updateSettingAr(param) {
  const { data } = await axiosAuth.post("/api/settingAr", param);
  return data;
}

export async function getSettingAsset() {
  const { data } = await axiosAuth.get("/api/settingAsset");
  return data;
}

export async function updateSettingAsset(param) {
  const { data } = await axiosAuth.post("/api/settingAsset", param);
  return data;
}

export async function getYearList() {
  const { data } = await axiosAuth.get(`/api/glPeriod`);
  return data;
}

export async function getYearListByYear(year) {
  const { data } = await axiosAuth.get(`/api/glPeriod/year/${year}`);
  return data;
}

export async function getSettingGl() {
  const { data } = await axiosAuth.get("/api/settingGl");
  return data;
}

export async function updateSettingGl(param) {
  const { data } = await axiosAuth.post("/api/settingGl", param);
  return data;
}

export async function updateSettingSystem(param) {
  const { data } = await axiosAuth.post("/api/settingSystem", param);
  return data;
}

//---------------------------- List ----------------------------//

export async function getAccountCodeList(module) {
  let q;
  switch (module) {
    case "Ap":
      q = {
        Limit: 0,
        Page: 1,
        OrderBy: {},
        WhereGroupList: [
          {
            AndOr: "And",
            ConditionList: [
              {
                AndOr: "And",
                Field: "UseInAp",
                Operator: "=",
                Value: true,
              },
              {
                AndOr: "And",
                Field: "AccType",
                Operator: "!=",
                Value: "H",
              },
            ],
          },
        ],
      };
      break;
    case "Ar":
      q = {
        Limit: 0,
        Page: 1,
        OrderBy: {},
        WhereGroupList: [
          {
            AndOr: "And",
            ConditionList: [
              {
                AndOr: "And",
                Field: "UseInAr",
                Operator: "=",
                Value: true,
              },
              {
                AndOr: "And",
                Field: "AccType",
                Operator: "!=",
                Value: "H",
              },
            ],
          },
        ],
      };
      break;
    case "Gl":
      q = {
        Limit: 0,
        Page: 1,
        OrderBy: {},
        WhereGroupList: [
          {
            AndOr: "And",
            ConditionList: [
              {
                AndOr: "And",
                Field: "UseInGl",
                Operator: "=",
                Value: true,
              },
              {
                AndOr: "And",
                Field: "AccType",
                Operator: "!=",
                Value: "H",
              },
            ],
          },
        ],
      };
      break;
    case "Asset":
      q = {
        Limit: 0,
        Page: 1,
        OrderBy: {},
        WhereGroupList: [
          {
            AndOr: "And",
            ConditionList: [
              {
                AndOr: "And",
                Field: "UseInAsset",
                Operator: "=",
                Value: true,
              },
              {
                AndOr: "And",
                Field: "AccType",
                Operator: "!=",
                Value: "H",
              },
            ],
          },
        ],
      };
      break;
    default:
      q = {
        Limit: 0,
        Page: 1,
        OrderBy: {},
        WhereGroupList: [
          {
            AndOr: "And",
            ConditionList: [
              {
                AndOr: "And",
                Field: "AccType",
                Operator: "!=",
                Value: "H",
              },
            ],
          },
        ],
      };
      break;
  }
  const { data } = await axiosAuth.get(`/api/accountCode?q=${JSON.stringify(q)}`);
  return data;
}

export async function getDepartmentList() {
  const { data } = await axiosAuth.get("/api/department");
  return data;
}

export async function getCurrencyList() {
  const { data } = await axiosAuth.get("/api/currency");
  return data;
}

export async function getApPaymentTypeList() {
  const { data } = await axiosAuth.get("/api/apPaymentType");
  return data;
}

export async function getApWhtServiceTypeList() {
  const { data } = await axiosAuth.get("/api/apWht");
  return data;
}

export async function getApWhtTypeList() {
  const { data } = await axiosAuth.get("/api/apWhtType");
  return data;
}

export async function getArPaymentTypeList() {
  const { data } = await axiosAuth.get("/api/arPaymentType");
  return data;
}

export async function getUnitList() {
  const { data } = await axiosAuth.get("/api/unit");
  return data;
}

export async function getTenantList() {
  const { data } = await axiosAuth.get("/api/tenant");
  return data;
}

export async function getGblFileFromBank() {
  const { data } = await axiosAuth.get(`/api/gblfilefrombank?q=${encodeURI("{limit:0}")}`);
  return data;
}

//---------------------------- Search ----------------------------//
export async function getVdCategoryList() {
  const { data } = await axiosAuth.post("/api/vendorCategory/search", { limit: 0 });
  return data;
}

export async function getAccountCodeSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/accountCode/search", uriQueryString);
  return data;
}

export async function getCurrencySearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/currencyexchange/search", uriQueryString);
  return data;
}

export async function getDepartmentSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/department/search", uriQueryString);
  return data;
}

export async function getPaymentTypeSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/paymentType/search", uriQueryString);
  return data;
}

export async function getDimensionSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/dimension/search", uriQueryString);
  return data;
}

export async function getUnitSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/unit/search", uriQueryString);
  return data;
}

export async function getVdCategorySearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/vendorCategory/search", uriQueryString);
  return data;
}

export async function getUserSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/user/search", uriQueryString);
  return data;
}

export async function getSettingBudgetXls() {
  const { data } = await axiosAuth.get("/api/settingBudgetXls");
  return data;
}

export async function updateSettingBudgetXls(param) {
  const { data } = await axiosAuth.put("/api/settingBudgetXls", param);
  return data;
}

//---------------------------- Detail ----------------------------//

export async function getAccountCodeDetail(Id) {
  const { data } = await axiosAuth.get(`/api/accountCode/${Id}`);
  return data;
}

export async function createAccountCodeDetail(param) {
  const { data } = await axiosAuth.post("/api/accountCode", param);
  return data;
}

export async function updateAccountCodeDetail(param) {
  const { data } = await axiosAuth.put(`/api/accountCode/${param.Id}`, param);
  return data;
}

export async function delAccountCodeDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/accountCode/${Id}`);
  return data;
}

export async function getCurrencyDetail(Id) {
  const { data } = await axiosAuth.get(`/api/currency/${Id}`);
  return data;
}

export async function createCurrencyDetail(param) {
  const { data } = await axiosAuth.post("/api/currency", param);
  return data;
}
export async function updateCurrencyDetail(param) {
  const { data } = await axiosAuth.put(`/api/currency/${param.Id}`, param);
  return data;
}

export async function delCurrencyDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/currency/${Id}`);
  return data;
}

export async function getCurrencyExDetail(Id) {
  const { data } = await axiosAuth.get(`/api/currencyexchange/${Id}`);
  return data;
}

export async function createCurrencyExDetail(param) {
  const { data } = await axiosAuth.post("/api/currencyexchange", param);
  return data;
}

export async function updateCurrencyExDetail(param) {
  const { data } = await axiosAuth.put(`/api/currencyexchange/${param.Id}`, param);
  return data;
}

export async function delCurrencyExDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/currencyexchange/${Id}`);
  return data;
}

export async function getDepartmentDetail(Id) {
  const { data } = await axiosAuth.get(`/api/department/${Id}`);
  return data;
}

export async function createDepartmentDetail(param) {
  const { data } = await axiosAuth.post("/api/department", param);
  return data;
}

export async function updateDepartmentDetail(param) {
  const { data } = await axiosAuth.put(`/api/department/${param.Id}`, param);
  return data;
}

export async function delDepartmentDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/department/${Id}`);
  return data;
}

export async function getPaymentTypeDetail(Id) {
  const { data } = await axiosAuth.get(`/api/paymentType/${Id}`);
  return data;
}

export async function createPaymentTypeDetail(param) {
  const { data } = await axiosAuth.post("/api/paymentType", param);
  return data;
}

export async function updatePaymentTypeDetail(param) {
  const { data } = await axiosAuth.put(`/api/paymentType/${param.Id}`, param);
  return data;
}

export async function delPaymentTypeDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/paymentType/${Id}`);
  return data;
}

export async function getUnitDetail(Id) {
  const { data } = await axiosAuth.get(`/api/unit/${Id}`);
  return data;
}

export async function createUnitDetail(param) {
  const { data } = await axiosAuth.post("/api/unit", param);
  return data;
}

export async function updateUnitDetail(param) {
  const { data } = await axiosAuth.put(`/api/unit/${param.Id}`, param);
  return data;
}

export async function delUnitDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/unit/${Id}`);
  return data;
}

export async function getVdCategoryDetail(Id) {
  const { data } = await axiosAuth.get(`/api/vendorCategory/${Id}`);
  return data;
}

export async function createVdCategoryDetail(param) {
  const { data } = await axiosAuth.post("/api/vendorCategory", param);
  return data;
}

export async function updateVdCategoryDetail(param) {
  const { data } = await axiosAuth.put(`/api/vendorCategory/${param.Id}`, param);
  return data;
}

export async function delVdCategoryDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/vendorCategory/${Id}`);
  return data;
}

export async function getGblFileFromBankDetail(id) {
  const { data } = await axiosAuth.get(`/api/gblfilefrombank/${id}`);
  return data;
}

export async function createGblFileFromBankDetail(param) {
  const { data } = await axiosAuth.post("/api/gblfilefrombank", param);
  return data;
}

export async function updateGblFileFromBankDetail(param) {
  const { data } = await axiosAuth.put(`/api/gblfilefrombank/${param.FileId}`, param);
  return data;
}

export async function delGblFileFromBankDetail(id) {
  const { data } = await axiosAuth.delete(`/api/gblfilefrombank/${id}`);
  return data;
}

export async function getMailProfile(code = "NONE") {
  const { data } = await axiosAuth.get(`/api/Mail/Profile/${code}`);
  return data;
}

export async function updatMailProfileCode(code, param) {
  const { data } = await axiosAuth.put(`/api/Mail/Profile/${code}`, param);
  return data;
}

export async function getMailTemplate(code = "NONE") {
  const { data } = await axiosAuth.get(`/api/Mail/Template/${code}`);
  return data;
}

export async function updateMailTemplate(code, param) {
  const { data } = await axiosAuth.put(`/api/Mail/Template/${code}`, param);
  return data;
}

//---------------------------- License ----------------------------//
export async function getLicenseListWithError(showError = false) {
  const { data } = await axiosAuth.get(`/api/licenseInterface/${showError}`);
  return data;
}

export async function getLicenseListAddOnLookup(module = "Unknow") {
  const { data } = await axiosAuth.get(`/api/licenseInterface/interfaceList/${module}`);
  return data;
}

export async function getActiveModule() {
  const { data } = await axiosAuth.get("/api/licenseInterface/activeModule");
  return data;
}

export async function getLicenseList() {
  const { data } = await axiosAuth.get("/api/licenseInterface/license");
  return data;
}

export async function updateLicenseAddOns(module, param) {
  const { data } = await axiosAuth.put(`/api/licenseInterface/activeModule/${module}`, param);
  return data;
}

export async function updateLicenseModule(param) {
  const { data } = await axiosAuth.post("/api/licenseInterface/batch", param);
  return data;
}

//---------------------------- Template Permission ----------------------------//

export async function getTemplatePermissionByLicense() {
  const { data } = await axiosAuth.get("/api/permission/TemplateByLicense");
  return data;
}

export async function getPermissionByBuAndUser(bu, username) {
  const { data } = await axiosAuth.get(`/api/permission/${bu}/${username}`);
  return data;
}

export async function getUpdatePermission(param) {
  const { data } = await axiosAuth.put("/api/permission", param);
  return data;
}

//---------------------------- AddOrDelete ----------------------------//

export async function addOrDeleteTenant(username, tenantId, action) {
  const { data } = await axiosAuth.put(
    `/api/userTenant/addOrDelete?userName=${username}&tenant=${tenantId}&action=${action}`
  );
  return data;
}

//---------------------------- ProcessLog ----------------------------//
export async function getProcessLogList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/processLog/search", uriQueryString);
  return data;
}

//---------------------------- User ----------------------------------//

export async function getUser(username) {
  const q = { Limit: 0, WhereRaw: `username = '${username}'` };
  const { data } = await axiosAuth.get(`/api/user?q=${JSON.stringify(q)}`);
  return data;
}

export async function getUserByUserName(userName) {
  const { data } = await axiosAuth.get(`/api/userTenant/UserName/${userName}`);
  return data;
}

export async function getUserListByTenant(tenant) {
  const { data } = await axiosAuth.get(`/api/userTenant/userNameListBy/${tenant}`);
  return data;
}

export async function getTenantByUserName(userName) {
  const { data } = await axiosAuth.get(
    `/api/userTenant/tenantListIn/${localStorage.getItem("adminToken")}/${userName}`
  );
  return data;
}

export async function updateUserTenantToInActive(param) {
  const { data } = await axiosAuth.put(
    `/api/userTenant/updateToInActive?userName=${param.UserName}&tenant=${param.Tenant}`
  );
  return data;
}

export async function updateUserTenantToActive(param) {
  const { data } = await axiosAuth.put(
    `/api/userTenant/updateToActive?userName=${param.UserName}&tenant=${param.Tenant}`
  );
  return data;
}

export async function updatePassword(userId, param) {
  const { data } = await axiosAuth.put(`/api/user/changePassword/${userId}`, param);
  return data;
}

export async function getUserDetail(userId) {
  const { data } = await axiosAuth.get(`/api/user/${userId}`);
  return data;
}

export async function createUserDetail(param) {
  const { data } = await axiosAuth.post("/api/user", param);
  return data;
}

export async function updateUserDetail(param) {
  const { data } = await axiosAuth.put(`/api/user/${param.UserId}`, param);
  return data;
}

export async function delUserDetail(UserId) {
  const { data } = await axiosAuth.delete(`/api/user/${UserId}`);
  return data;
}

//---------------------------- CurrencyExchangeRate ----------------------------------//
export async function getExchangeRateByProvider(date, base, curr) {
  const fixProvider = "bot";
  const { data } = await axiosAuth.get(`/api/currencyexchange/${fixProvider}/${base}/${curr}?date=${date}`);
  return data;
}
