import React, { useContext } from "react";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Chip, TextField, Divider } from "@material-ui/core";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 200,
    //backgroundColor: theme.palette.type === "dark" ? "inherit" : "grey",
  },
  content: {
    padding: theme.spacing(0, 1),
  },
  chipAdd: {
    margin: "0 10px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  chipInclude: {
    margin: "0 10px",
    backgroundColor: "green",
    color: "white",
  },
  wrapTxt: {
    whiteSpace: "pre-wrap",
  },
}));

const SwitchTaxType = ({ type }) => {
  const classes = useStyles();
  switch (type) {
    case "Add":
      return (
        <>
          <Chip label={type} classes={{ root: classes.chipAdd }} size="small" />
        </>
      );
    case "Include":
      return (
        <>
          <Chip label={type} classes={{ root: classes.chipInclude }} size="small" />
        </>
      );
    default:
      return (
        <>
          <Chip label={type} size="small" />
        </>
      );
  }
};

const TaxVatContent = ({ data, module }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { NumberFormat } = useContext(GblContext);
  return (
    <React.Fragment>
      {data.TaxType1 !== "None" ? (
        <>
          <Divider />
          <Typography variant="h6" className={classes.content}>
            {translate("ra.field.Tax 1")}
          </Typography>
          <Box className={classes.content}>
            <React.Fragment>
              <Typography variant="body2" component="b">
                <b>{translate("ra.field.Tax Type")}</b>
                <SwitchTaxType type={data.TaxType1} />
                {data.TaxOverwrite1 === "O" ? (
                  <>
                    <Chip label="Overwrite" color="primary" size="small" />
                    <br />
                  </>
                ) : (
                  ""
                )}
                <b>{translate("ra.field.Tax Rate")} </b> {NumberFormat(data.TaxRate1)}
              </Typography>
              <Typography variant="body2" component="p" className={classes.wrapTxt} style={{ marginTop: 6 }}>
                {module === "AP_Invoice" ? (
                  <>
                    <TextField
                      label={translate(`ra.field.Tax Dr Dept. Code`) + "1"}
                      variant="outlined"
                      margin="dense"
                      disabled
                      fullWidth
                      value={
                        data.TaxDept1 ? `${data.TaxDept1} ${data.TaxDept1Desc ? ": " + data.TaxDept1Desc : ""}` : ""
                      }
                      style={{ marginBottom: 16 }}
                    />
                    <TextField
                      label={translate(`ra.field.Tax Dr Acc. Code`) + "1"}
                      variant="outlined"
                      margin="dense"
                      disabled
                      fullWidth
                      value={`${data.TaxAcc1} ${data.TaxAcc1Desc ? ": " + data.TaxAcc1Desc : ""}`}
                      style={{ marginBottom: 16 }}
                    />
                  </>
                ) : (
                  <TextField
                    label={translate(`ra.field.Tax Cr Acc. Code`) + "1"}
                    variant="outlined"
                    margin="dense"
                    disabled
                    fullWidth
                    value={`${data.TaxAcc1} ${data.TaxAcc1Desc ? ": " + data.TaxAcc1Desc : ""}`}
                    style={{ marginBottom: 16 }}
                  />
                )}
              </Typography>
            </React.Fragment>
          </Box>
        </>
      ) : (
        ""
      )}

      {data.TaxType2 !== "None" ? (
        <>
          <Divider />
          <Typography variant="h6" className={classes.content}>
            {translate("ra.field.Tax 2")}
          </Typography>
          <Box className={classes.content}>
            <React.Fragment>
              <Typography variant="body2" component="b">
                <b>{translate("ra.field.Tax Type")}</b>
                <SwitchTaxType type={data.TaxType2} />
                {data.TaxOverwrite2 === "O" ? (
                  <>
                    <Chip label="Overwrite" color="primary" size="small" />
                    <br />
                  </>
                ) : (
                  ""
                )}
                <b>{translate("ra.field.Tax Rate")} </b> {NumberFormat(data.TaxRate2)}
              </Typography>
              <Typography variant="body2" component="p" className={classes.wrapTxt} style={{ marginTop: 6 }}>
                {module === "AP_Invoice" ? (
                  <>
                    <TextField
                      label={translate(`ra.field.Tax Dr Dept. Code`) + "2"}
                      variant="outlined"
                      margin="dense"
                      disabled
                      fullWidth
                      value={
                        data.TaxDept2 ? `${data.TaxDept2} ${data.TaxDept2Desc ? ": " + data.TaxDept2Desc : ""}` : ""
                      }
                      style={{ marginBottom: 16 }}
                    />
                    <TextField
                      label={translate(`ra.field.Tax Dr Acc. Code`) + "2"}
                      variant="outlined"
                      margin="dense"
                      disabled
                      fullWidth
                      value={`${data.TaxAcc2} ${data.TaxAcc2Desc ? ": " + data.TaxAcc2Desc : ""}`}
                      style={{ marginBottom: 16 }}
                    />
                  </>
                ) : (
                  <TextField
                    label={translate(`ra.field.Tax Cr Acc. Code`) + "2"}
                    variant="outlined"
                    margin="dense"
                    disabled
                    fullWidth
                    value={`${data.TaxAcc2} ${data.TaxAcc2Desc ? ": " + data.TaxAcc2Desc : ""}`}
                    style={{ marginBottom: 16 }}
                  />
                )}
              </Typography>
            </React.Fragment>
          </Box>
        </>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default TaxVatContent;
