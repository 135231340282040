import axiosAuth from "utils/request";
export async function getLookup(lookupType) {
  const { data } = await axiosAuth.get(`/api/lookup/${lookupType}`);
  return data;
}

export async function getLookupCurrency(param) {
  const { data } = await axiosAuth.post("/api/lookup/currency", param);
  return data;
}

export async function getDataDic(module) {
  const { data } = await axiosAuth.get(`/api/datadic/${module}`);
  return data;
}
