import React, { useContext, useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Grid, Hidden } from "@material-ui/core";
import { TableFooter, TableRow, TableCell, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MuiTranslateTable from "components/MuiTranslateTable";
import PopupTable from "components/PopupTable";
import { GblContext } from "providers/formatter";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import { TextFieldInForm, DescInForm, MuiAutosuggest, NumberFormatInForm } from "components/Form";
import { getGlPrefix } from "services/generalLedger";
import { getAccountCodeList, getDepartmentList } from "services/setting";
import { getLookupCurrency } from "services/lookup";
import { getAllocationJvDetail } from "services/generalLedger";

const useStyles = makeStyles((theme) => ({
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const FormEditAllocation = ({ templateId, setAdjustData }) => {
  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();
  const { authenticated } = useAuthState();
  const { settingAll, ToMySqlDate, NumberFormat, ToNumber } = useContext(GblContext);
  const { SettingSystem } = settingAll;
  const [data, setData] = useState();
  const [lookupList, setLookupList] = useState({
    prefixList: [],
    accountCodeList: [],
    departmentList: [],
    currencyList: [],
  });
  const [oldAccList, setOldAccList] = useState([]);
  const fetchPrefixLookup = useCallback(async () => {
    const { Data } = await getGlPrefix();
    setLookupList((state) => ({
      ...state,
      prefixList: Data,
    }));
  }, []);
  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList("Gl");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  }, []);
  const fetchCurrencyLookup = useCallback(async () => {
    const p = {
      Module: "GL",
      CurrDate: ToMySqlDate(new Date()),
    };
    const arr = await getLookupCurrency(p);
    setLookupList((state) => ({
      ...state,
      currencyList: arr,
    }));
  }, [ToMySqlDate]);

  const methods = useForm({ defaultValues: data });

  const { reset } = methods;

  const fetchDetail = useCallback(async () => {
    const response = await getAllocationJvDetail(templateId);
    setData(response);
    reset(response);
  }, [templateId, reset]);

  const [showAdd, setShowAdd] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const UpdateRow = (value) => {
    setEditIndex(value);
    setShowAdd(true);
  };

  useEffect(() => {
    if (authenticated) {
      fetchPrefixLookup();
      fetchAccLookup();
      fetchDeptLookup();
      fetchCurrencyLookup();
      fetchDetail(templateId);
    }
  }, [authenticated, fetchPrefixLookup, fetchAccLookup, fetchDeptLookup, fetchCurrencyLookup, fetchDetail, templateId]);

  const labelList = {
    Prefix: translate("ra.fieldAbbr.prefix"),
    Type: translate("ra.fieldAbbr.type"),
    "Voucher No.": translate("ra.fieldAbbr.vouNo"),
    Date: translate("ra.fieldAbbr.date"),
    Description: translate("ra.fieldAbbr.desc"),
    Department: translate("ra.fieldAbbr.dept"),
    "Account #": translate("ra.fieldAbbr.account1"),
    Comment: translate("ra.fieldAbbr.comment"),
    Currency: translate("ra.fieldAbbr.currency"),
    Rate: translate("ra.fieldAbbr.rate"),
    Amount: translate("ra.fieldAbbr.amount"),
    Base: translate("ra.fieldAbbr.base"),
    "Dr.": translate("ra.fieldAbbr.dr"),
    "Cr.": translate("ra.fieldAbbr.cr"),
  };

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <>
              <EditIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => UpdateRow(value)}
              />
            </>
          );
        },
      },
    },
    {
      name: "DeptCode",
      label: "Dept.",
    },
    {
      name: "AccCode",
      label: "Account #",
    },
    {
      name: "AccDesc",
      label: "Account Name",
    },
    {
      name: "Description",
      label: "Comment",
      options: {
        display: false,
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "CurCode",
      label: "Currency",
    },
    {
      name: "CurRate",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "currency");
        },
      },
    },
    {
      name: "DrQty",
      label: "Unit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          if (value > 0) {
            return NumberFormat(value);
          } else {
            return NumberFormat(data.Detail[tableMeta.rowIndex].CrQty);
          }
        },
      },
    },
    {
      name: "DrAmount",
      label: "Dr. Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrAmount",
      label: "Cr. Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DrBase",
      label: "Dr. Base",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrBase",
      label: "Cr. Base",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    responsive: "standard",
    selectableRows: "multiple",
    fixedHeader: true,
    tableBodyHeight: "580px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      let sumDrAmt = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumCrAmt = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumDrBase = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[10]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumCrBase = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[11]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {/* Add TableCellEmpty For Summary Space */}
            <TableCell className={footerClasses} />
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                if (col.name === "DrAmount") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumDrAmt}
                    </TableCell>
                  );
                } else if (col.name === "CrAmount") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumCrAmt}
                    </TableCell>
                  );
                } else if (col.name === "DrBase") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumDrBase}
                    </TableCell>
                  );
                } else if (col.name === "CrBase") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumCrBase}
                    </TableCell>
                  );
                } else {
                  return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (var i = removeArray.length - 1; i >= 0; i--) data.Detail.splice(removeArray[i], 1);
    },
  };

  const formFieldsEdit = [
    {
      size: 6,
      field: <div />,
      implementation: "css",
      smDown: true,
      component: { Hidden },
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={`* ${translate("ra.field.Amount to be allocated")}`}
          name="AllocateAmt"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 3,
      field: (
        <NumberFormatInForm
          label={`* ${translate("ra.field.Allocate Unit")}`}
          name="AllocateUnit"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
  ];

  const formFieldsDetail = [
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`* ${labelList["Department"]}`}
          name="DeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "DeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeList: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("AccCode");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("AccCode", "");
                methods.setValue("AccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeList: oldAccList,
              }));
            }
          }}
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
        />
      ),
    },
    {
      size: 6,
      name: "DeptDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="DeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`* ${labelList["Account #"]}`}
          name="AccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[
            {
              key: "AccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
        />
      ),
    },
    {
      size: 6,
      name: "AccDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="AccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={`${labelList["Comment"]}`}
          name="Description"
          variant="outlined"
          margin="dense"
          multiline
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`* ${labelList["Currency"]}`}
          name="CurCode"
          optKey="CurrCode"
          optDesc="CurrRate"
          options={lookupList["currencyList"]}
          updateOtherField={[{ key: "CurRate", optKey: "CurrRate" }]}
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${labelList["Rate"]}`}
          name="CurRate"
          rule={{
            min: {
              value: 0.000001,
              message: `* ${translate("ra.validation.required")}`,
            },
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
          decimal={SettingSystem.CurrencyRateDecimal}
          // decimalSep={SettingSystem.DecimalSeparator}
          // thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${translate("ra.field.Allocate Unit")} ${labelList["Dr."]}`}
          name="DrQty"
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${translate("ra.field.Allocate Unit")} ${labelList["Cr."]}`}
          name="CrQty"
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${labelList["Amount"]} ${labelList["Dr."]}`}
          name="DrAmount"
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
          //readOnly={true}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${labelList["Amount"]} ${labelList["Cr."]}`}
          name="CrAmount"
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
          //readOnly={true}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${labelList["Base"]} ${labelList["Dr."]}`}
          name="DrBase"
          readOnly={true}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${labelList["Base"]} ${labelList["Cr."]}`}
          name="CrBase"
          readOnly={true}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
  ];

  const UpdateForm = (e) => {
    if (data.Detail.length !== 0) {
      const allocateAmt = ToNumber(methods.watch("AllocateAmt"));
      const allocateUnit = ToNumber(methods.watch("AllocateUnit"));

      data.Detail.forEach((i, idx) => {
        i.index = idx;
        const drQty = ToNumber(i["DrQty"]);
        const crQty = ToNumber(i["CrQty"]);
        const curRate = ToNumber(i["CurRate"]);
        if (drQty > 0) {
          const drAmount = (allocateAmt / allocateUnit) * drQty;
          const drBase = drAmount * curRate;
          i.DrAmount = drAmount;
          i.DrBase = drBase;
        }
        if (crQty > 0) {
          const crAmount = (allocateAmt / allocateUnit) * crQty;
          const crBase = crAmount * curRate;
          i.CrAmount = crAmount;
          i.CrBase = crBase;
        }
      });

      if (e.target.name === "AllocateAmt" || e.target.name === "AllocateUnit") {
        const sumDrBase = data.Detail.reduce((accu, item) => {
          const s = ToNumber(accu) + ToNumber(item.DrBase);
          return NumberFormat(s ?? 0);
        }, 0);

        if (sumDrBase > allocateAmt) {
          let excessAmt = sumDrBase - allocateAmt;
          let DIndex = data.Detail.findLastIndex((i) => i.DrQty > 0);
          let CIndex = data.Detail.findLastIndex((i) => i.CrQty > 0);
          let lastDebitDetail = data.Detail[DIndex];
          let lastCreditDetail = data.Detail[CIndex];

          if (lastDebitDetail?.DrAmount) {
            lastDebitDetail.DrAmount = lastDebitDetail.DrAmount - excessAmt;
            lastDebitDetail.DrBase = lastDebitDetail.DrAmount * lastDebitDetail.CurRate;
          }

          if (lastCreditDetail?.CrAmount) {
            lastCreditDetail.CrAmount = lastCreditDetail.CrAmount - excessAmt;
            lastCreditDetail.CrBase = lastCreditDetail.CrAmount * lastCreditDetail.CurRate;
          }
        }

        if (sumDrBase < allocateAmt) {
          let excessAmt = allocateAmt - sumDrBase;
          let DIndex = data.Detail.findLastIndex((i) => i.DrQty > 0);
          let CIndex = data.Detail.findLastIndex((i) => i.CrQty > 0);
          let lastDebitDetail = data.Detail[DIndex];
          let lastCreditDetail = data.Detail[CIndex];

          if (lastDebitDetail?.DrAmount) {
            lastDebitDetail.DrAmount = lastDebitDetail.DrAmount + excessAmt;
            lastDebitDetail.DrBase = lastDebitDetail.DrAmount * lastDebitDetail.CurRate;
          }
          if (lastCreditDetail?.CrAmount) {
            lastCreditDetail.CrAmount = lastCreditDetail.CrAmount + excessAmt;
            lastCreditDetail.CrBase = lastCreditDetail.CrAmount * lastCreditDetail.CurRate;
          }
        }
      }

      methods.setValue("AllocateAmt", allocateAmt);
      methods.setValue("AllocateUnit", allocateUnit);

      setAdjustData(data);
    }
  };

  const CheckDrCr = (field, m, row) => {
    let CurRate = ToNumber(row["CurRate"]);
    let allocateAmt = data["AllocateAmt"] ? ToNumber(data["AllocateAmt"]) : 0;
    let allocateUnit = data["AllocateUnit"] ? ToNumber(data["AllocateUnit"]) : 0;
    let drQty = ToNumber(row["DrQty"]);
    let crQty = ToNumber(row["CrQty"]);
    let drAmount = (allocateAmt / allocateUnit) * drQty;
    let crAmount = (allocateAmt / allocateUnit) * crQty;

    if (field === "DrAmount") {
      m.setValue("DrAmount", row["DrAmount"]);
      m.setValue("DrBase", ToNumber(row["DrAmount"]) * CurRate);
      m.setValue("CrQty", 0);
      m.setValue("CrAmount", 0);
      m.setValue("CrBase", 0);
    }

    if (field === "CrAmount") {
      m.setValue("CrAmount", row["CrAmount"]);
      m.setValue("CrBase", ToNumber(row["CrAmount"]) * CurRate);
      m.setValue("DrQty", 0);
      m.setValue("DrAmount", 0);
      m.setValue("DrBase", 0);
    }

    if (field === "DrQty" && row[field] !== 0) {
      m.setValue("DrAmount", drAmount);
      m.setValue("DrBase", drAmount * CurRate);
      m.setValue("CrQty", 0);
      m.setValue("CrAmount", 0);
      m.setValue("CrBase", 0);
    }
    if (field === "CrQty" && row[field] !== 0) {
      m.setValue("CrAmount", crAmount);
      m.setValue("CrBase", crAmount * CurRate);
      m.setValue("DrQty", 0);
      m.setValue("DrAmount", 0);
      m.setValue("DrBase", 0);
    }
  };

  const SaveFromPopup = (arr, row) => {
    const index = arr.Detail.findIndex((el) => el.index === editIndex);
    row.DrBase = NumberFormat(row.DrBase);
    row.CrBase = NumberFormat(row.CrBase);

    if (editIndex !== "") {
      //update
      arr.Detail[index] = row;
      setData(arr);
      setAdjustData(arr);
      setShowAdd(false);
    }
  };

  return (
    <>
      <Grid container alignItems="flex-start" spacing={1} style={{ padding: 16 }}>
        {formFieldsEdit
          ? formFieldsEdit.map((item, idx) => (
              <Grid item xs={item.size} key={idx}>
                {React.createElement(item.field.type, {
                  ...{
                    ...item.field.props,
                    methods,
                    key: item.field.props.name,
                    onChange: UpdateForm,
                  },
                })}
              </Grid>
            ))
          : ""}
      </Grid>
      <MuiTranslateTable data={data?.Detail} columns={columns} options={options} />
      {showAdd && (
        <PopupTable
          initialValues={data?.Detail.find((i) => i.index === editIndex)}
          formFields={formFieldsDetail}
          update={CheckDrCr}
          open={showAdd}
          save={(row) => SaveFromPopup(data, row)}
          cancel={() => setShowAdd(false)}
          //showDim
        />
      )}
    </>
  );
};

export default FormEditAllocation;
