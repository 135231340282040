export const uriQueryString = {
  Limit: 15,
  Page: 1,
  OrderBy: { LastModified: "desc" },
  WhereGroupList: [],
  Exclude: [],
  WhereRaw: "",
  WhereLike: "",
  WhereLikeFields: [],
};

export default uriQueryString;
