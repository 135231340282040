import axiosAuth from "../utils/request";

export async function getFinancialReport(param) {
  const { data } = await axiosAuth.post("/api/financialreport", param);
  return data;
}

export async function getFinancialReportList() {
  const { data } = await axiosAuth.get("/api/financialreport/reportlist");
  return data;
}

export async function getFinancialSetting(id) {
  const { data } = await axiosAuth.get(`/api/financialreport/setting/${id}`);
  return data;
}

export async function updateFinancialSetting(reportId, param) {
  const { data } = await axiosAuth.put(`/api/financialreport/setting/${reportId}`, param);
  return data;
}
