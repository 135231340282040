/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { Loading, useTranslate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import {
  getUserByUserName,
  getUserSearchList,
  getTenantList,
  updateUserDetail,
  addOrDeleteTenant,
  updateUserTenantToActive,
  updateUserTenantToInActive,
  getUser,
} from "services/setting";
import { useForm } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import { Tabs, Tab } from "@material-ui/core";
import {
  Box,
  Grid,
  Paper,
  Divider,
  Select,
  Checkbox,
  InputLabel,
  MenuItem,
  ListItemText,
  Button,
  Typography,
} from "@material-ui/core";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import { TextFieldInForm } from "components/Form";
import ChangePasswordDialog from "layout/ChangePasswordDialog";
import Activity from "./Activity";
import Notifications from "./Notifications";
//import Dashboard from "./Dashboard";
import BoxHeader from "components/BoxHeader";
import CardImage from "components/CardImage";
import PreviewUser from "assets/user.png";
import SignaturePlaceholder from "assets/signaturePlaceholder.png";
import ButtonUpload from "components/ButtonUpload";
import ButtonFooter from "components/ButtonFooter";
import SnackbarUtils from "utils/SnackbarUtils";
import fileReader from "utils/fileReader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = withStyles({
  root: {
    textTransform: "none",
  },
})((props) => <Tab {...props} />);

const Profile = () => {
  const translate = useTranslate();
  const [loading, setLoading] = useState(true);
  const [userDetail, setUserDetail] = useStateWithCallbackLazy();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [buDesc, setBuDesc] = useState([]);
  const [buList, setBuList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [valueOfTab, setValueOfTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueOfTab(newValue);
  };
  const methods = useForm({ defaultValues: userDetail });
  const { handleSubmit, reset } = methods;

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    //Adjust parameter before save
    setUserDetail(
      (state) => ({
        ...state,
        ...values,
        UserModified: localStorage.getItem("UserName"),
      }),
      (nextState) => Save(nextState)
    );
  };

  const fetchTenantList = async () => {
    const { Data } = await getTenantList();
    if (Data) {
      setBuList(Data);
    }
  };

  const fetchDetailByUsername = async () => {
    let username = localStorage.getItem("UserName");
    if (username && username !== "") {
      const u = await getUser(username);
      const { Data } = await getUserByUserName(username);
      const activeData = Data.filter((i) => i.Status.Active);

      let objSign = u.Data[0].Signature !== "" ? JSON.parse(u.Data[0]?.Signature) : null;
      if (activeData && activeData.length > 0) {
        const tenantList = activeData.map((i) => i.Tenant);
        const buDesc = activeData.map((i) => i.Tenant.Description);
        setBuDesc(buDesc);
        const newData = {
          ...activeData[0]?.User,
          KeepOldPassword: true,
          ...activeData[0]?.Status,
          tenantList,
          buDesc,
          Signature: typeof objSign === "object" ? objSign?.Signature : null,
        };
        setUserDetail(newData);
        reset(newData);
      } else {
        const qs = {
          Limit: 1,
          Page: 1,
          WhereLike: `%${username}%`,
          WhereLikeFields: ["UserName"],
        };
        const { Data } = await getUserSearchList(qs);
        if (Data && Data.length > 0) {
          const newData = {
            ...Data[0],
            KeepOldPassword: true,
            Signature: typeof objSign === "object" ? objSign?.Signature : null,
          };
          setUserDetail(newData);
          reset(newData);
        }
      }
    } else {
      setLoading(false);
    }
    setLoading(false);
    setBtnLoading(false);
  };

  useEffect(() => {
    fetchTenantList();
    fetchDetailByUsername();
  }, []);

  const UploadImg = (e) => {
    if (e.target.files[0]) {
      let msg = fileReader.CheckLimitImgSize(e.target.files);
      if (msg) {
        document.getElementById("user_img").value = "";
        SnackbarUtils.error(translate(msg, { size: "5" }));
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        setUserDetail((state) => ({
          ...state,
          Photo: base64String,
        }));
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const UploadSignature = (e) => {
    if (e.target.files[0]) {
      let msg = fileReader.CheckLimitImgSize(e.target.files);
      if (msg) {
        document.getElementById("signature_img").value = "";
        SnackbarUtils.error(translate(msg, { size: "5" }));
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        setUserDetail((state) => ({
          ...state,
          Signature: base64String,
        }));
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const setTenantParam = async (v, selectedBuDesc) => {
    const OldTenant = v?.tenantList ? v.tenantList : [];
    //1. loop OldTenant clear notIn selectTenant
    OldTenant.forEach(async (tItem) => {
      const notInList = selectedBuDesc.includes(tItem.Description);
      if (!notInList) {
        const { Code, UserMessage } = await addOrDeleteTenant(v.UserName, tItem.Tenant, "Delete");
        if (Code === 0) {
          console.log(UserMessage);
        }
      }
    });

    //2. add all selectedTenant
    selectedBuDesc.forEach(async (tenantDesc) => {
      const itemAdd = buList.find((i) => i.Description === tenantDesc);
      const { Code, UserMessage } = await addOrDeleteTenant(v.UserName, itemAdd.Tenant, "Add");
      if (Code === 0) {
        console.log(UserMessage);
      }
    });
  };

  const updateUserTenant = async (v, selectedBuDesc) => {
    selectedBuDesc.forEach(async (tenantDesc) => {
      const buItem = buList.find((i) => i.Description === tenantDesc);
      const param = {
        Tenant: buItem.Tenant,
        UserName: v.UserName,
      };
      if (v.Active) {
        const { Code, UserMessage } = await updateUserTenantToActive(param);
        if (Code === 0) {
          console.log(UserMessage, "after updateUserTenantToActive");
        }
      } else {
        const { Code, UserMessage } = await updateUserTenantToInActive(param);
        if (Code === 0) {
          console.log(UserMessage, "after updateUserTenantToInActive");
        }
      }
    });
  };

  const Save = async (v) => {
    let objSign = {
      Name: userDetail.Name,
      Signature: userDetail.Signature,
    };
    const paramUserDetail = Object.assign(
      {},
      {
        UserId: userDetail.UserId,
        UserName: userDetail.UserName,
        Active: true,
        Email: v.Email,
        Photo: userDetail.Photo,
        Signature: JSON.stringify(objSign),
        KeepOldPassword: true,
        UserModified: localStorage.getItem("UserName"),
      }
    );
    if (paramUserDetail.Photo) {
      localStorage.setItem("UserPhoto", paramUserDetail.Photo);
    }

    await setTenantParam(paramUserDetail, buDesc);
    //Update
    const { Code, InternalMessage, UserMessage } = await updateUserDetail(paramUserDetail);
    if (Code === 0) {
      await updateUserTenant(paramUserDetail, buDesc);
      SnackbarUtils.success(UserMessage, function () {
        window.location.reload();
      });
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
    }
  };

  const CancelFnc = () => {
    fetchDetailByUsername();
  };

  if (loading) return <Loading style={{ marginTop: 50 }} />;

  return (
    <div>
      <Tabs
        value={valueOfTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        style={{ marginBottom: 12, border: "1px solid #e0e0e3", borderRadius: "10px" }}
      >
        <StyledTab label="Profile" {...a11yProps(0)} />
        {/* <StyledTab label="Dashboard" {...a11yProps(1)} /> */}
        <StyledTab label="Activity Log" {...a11yProps(1)} />
        <StyledTab label="Notifications" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={valueOfTab} index={0} style={{ padding: "0 12px" }}>
        <BoxHeader header={"Profile"} />
        <form onKeyDown={disableFormEnter} autoComplete="off" onSubmit={Save}>
          {userDetail && (
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                <Paper style={{ padding: 8 }}>
                  {userDetail?.Photo ? (
                    <CardImage
                      base64Src={userDetail.Photo}
                      noBorder={true}
                      customSize={{ height: 200 }}
                      circle={true}
                    />
                  ) : (
                    <CardImage imgSrc={PreviewUser} noBorder={true} customSize={{ height: 200 }} circle={true} />
                  )}
                  <ButtonUpload
                    id="user_img"
                    name="user_img"
                    style={{ display: "none" }}
                    type="file"
                    onChange={UploadImg}
                    accept="image/png, image/gif, image/jpeg"
                    btntext="Change Image"
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper style={{ padding: 16 }}>
                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={3} style={{ textAlign: "right" }}>
                      <b style={{ fontSize: 16 }}>User ID</b>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={8} style={{ padding: 10 }}>
                      <span style={{ fontSize: 16 }}>{userDetail.UserId}</span>
                    </Grid>
                  </Grid>
                  <Divider style={{ margin: "10px 0 10px 0" }} />
                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item xs={3} style={{ textAlign: "right" }}>
                      <b style={{ fontSize: 16 }}>Username</b>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={8} style={{ padding: 10 }}>
                      <span style={{ fontSize: 16 }}>{userDetail.UserName}</span>
                    </Grid>
                  </Grid>
                  <Divider style={{ margin: "10px 0 10px 0" }} />
                  <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={3} style={{ textAlign: "right" }}>
                      <b style={{ fontSize: 16 }}>Email</b>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={8} style={{ padding: 10 }}>
                      <TextFieldInForm
                        label="Email"
                        name="Email"
                        type="email"
                        variant="outlined"
                        margin="dense"
                        methods={methods}
                        rule={{
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Divider style={{ margin: "10px 0 10px 0" }} />
                  <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={3} style={{ textAlign: "right" }}>
                      <b style={{ fontSize: 16 }}>Business Unit</b>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={8} style={{ padding: 10 }}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="label-bu" style={{ marginTop: buDesc === "" && -5 }}>
                          Business unit
                        </InputLabel>
                        <Select
                          variant="outlined"
                          margin="dense"
                          labelId="label-bu"
                          label={translate("ra.field.Business Unit")}
                          multiple
                          value={buDesc}
                          onChange={(e) => {
                            const desc = e.target.value;
                            setBuDesc(desc);
                          }}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {buList.map((item) => (
                            <MenuItem key={item.Tenant} value={item.Description}>
                              <Checkbox checked={buDesc.indexOf(item.Description) > -1} />
                              <ListItemText primary={item.Description} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid item style={{ textAlign: "center", marginTop: 16 }}>
                  <Button variant="outlined" onClick={() => setOpenDialog(true)}>
                    <VpnKeyOutlinedIcon style={{ marginRight: 10 }} />
                    Change Password
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box p={1} pb={0}>
                  <Typography variant="h6" gutterBottom>
                    Signature
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                <Paper style={{ padding: 8 }}>
                  {userDetail?.Signature ? (
                    <CardImage
                      base64Src={userDetail?.Signature}
                      noBorder={true}
                      customSize={{ height: 80 }}
                      circle={false}
                    />
                  ) : (
                    <CardImage
                      imgSrc={SignaturePlaceholder}
                      noBorder={true}
                      customSize={{ height: 80 }}
                      circle={false}
                    />
                  )}
                  <ButtonUpload
                    id="signature_img"
                    name="signature_img"
                    style={{ display: "none" }}
                    type="file"
                    onChange={UploadSignature}
                    accept="image/png, image/gif, image/jpeg"
                    btntext="Change Image"
                  />
                </Paper>
              </Grid>
            </Grid>
          )}
        </form>
        <br />
        <ButtonFooter
          disabled={isBtnLoading}
          SaveFnc={handleSubmit(onSubmit)}
          CancelFnc={CancelFnc}
          cancelTitle={"Discard Changes"}
        />
        {openDialog && <ChangePasswordDialog open={openDialog} onClose={() => setOpenDialog(false)} />}
      </TabPanel>
      {/* <TabPanel value={valueOfTab} index={1}>
        <Dashboard />
      </TabPanel> */}
      <TabPanel value={valueOfTab} index={1}>
        <Activity />
      </TabPanel>
      {process.env.NODE_ENV === "development" ? (
        <TabPanel value={valueOfTab} index={2}>
          <Notifications />
        </TabPanel>
      ) : null}
    </div>
  );
};

export default Profile;
