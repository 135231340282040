import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { Loading, useTranslate } from "react-admin";
import { Grid, Tooltip, IconButton, Divider, Container } from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MuiTranslateTable from "components/MuiTranslateTable";
import TextTopInGrid from "components/TextTopInGrid";
import BoxHeader from "components/BoxHeader";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import { getJvDetail } from "services/generalLedger";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const DialogViewJV = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { DateToString, NumberFormat, ToNumber } = useContext(GblContext);
  const { id, open, onClose } = props;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  // const [error] = useState();

  const fetchJvById = useCallback(async () => {
    setLoading(true);
    const response = await getJvDetail(id);
    if (response) {
      setData(response);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchJvById();
  }, [fetchJvById]);

  const columns = [
    {
      name: "DeptCode",
      label: "Dept.",
      options: {
        sort: true,
      },
    },
    {
      name: "AccCode",
      label: "Account #",
      options: {
        sort: true,
      },
    },
    {
      name: "AccDesc",
      label: "Account Name",
      options: {
        sort: true,
      },
    },
    {
      name: "Description",
      label: "Comment",
      options: {
        display: false,
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "CurCode",
      label: "Currency",
    },
    {
      name: "CurRate",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "currency");
        },
      },
    },
    {
      name: "DrAmount",
      label: "Dr. Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrAmount",
      label: "Cr. Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DrBase",
      label: "Dr. Base",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrBase",
      label: "Cr. Base",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    responsive: "standard",
    selectableRows: "none",
    // serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "580px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      let sumDrAmt = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[6]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumCrAmt = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[7]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumDrBase = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumCrBase = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                if (col.name === "DrAmount") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumDrAmt}
                    </TableCell>
                  );
                } else if (col.name === "CrAmount") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumCrAmt}
                    </TableCell>
                  );
                } else if (col.name === "DrBase") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumDrBase}
                    </TableCell>
                  );
                } else if (col.name === "CrBase") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumCrBase}
                    </TableCell>
                  );
                } else {
                  return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"lg"}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {"View Journal Voucher"}
          <IconButton color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {loading ? (
          <Container
            style={{
              height: "48rem",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading style={{ marginBottom: "auto" }} />
          </Container>
        ) : (
          <>
            <BoxHeader
              header={`Journal Voucher`}
              showSearch
              searchOption={{
                placeholder: `${translate("ra.fieldAbbr.searchby")} ${translate("ra.field.Voucher No.")}`,
                module: "GL_Jv",
                keyCode: "Prefix",
                keyName: "JvhNo",
                keyDesc: "Description",
              }}
              data={data}
              source={data?.JvhSource}
              status={data?.Status}
            />
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
              <TextTopInGrid sizeSm={1} label="Prefix" value={data?.Prefix} />
              <TextTopInGrid sizeSm={2} label="Voucher No." value={data?.JvhNo} />
              <TextTopInGrid sizeSm={2} label="Date" value={DateToString(data?.JvhDate ?? new Date())} />
              <TextTopInGrid sizeSm={7} label="Description" value={data?.Description} />
            </Grid>
            <MuiTranslateTable data={data?.Detail} columns={columns} options={options} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialogViewJV;
