import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { Loading, Error, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid, Tooltip } from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MuiTranslateTable from "components/MuiTranslateTable";
import TextTopInGrid from "components/TextTopInGrid";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import NavRight from "components/NavRightSide";
import DialogCopy from "./DialogCopy";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getAllocationJvDetail, delAllocationJvDetail } from "services/generalLedger";
import { permissionName } from "utils/constants";
//import { showReportByName } from "pages/Report/services";
import SnackbarUtils from "utils/SnackbarUtils";
import ReportBackDrop from "components/ReportBackDrop";
import DialogViewJVDetail from "components/DialogJVDetail";

const Show = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { NumberFormat, ToNumber } = useContext(GblContext);
  const { basePath, id, permissions } = props;

  const redirect = useRedirect();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [openDim, setOpenDim] = useState(false);
  const [dataDim, setDataDim] = useState();
  const [dataDetail, setDataDetail] = useState();
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);

  const CheckDisableBtn = () => data?.Status === "Void";

  const CloseCopyDialog = (mode) => {
    setOpenCopyDialog(false);

    if (mode) {
      localStorage.setItem("jvCopyMode", mode.value);
      localStorage.setItem("jvId", id);
      redirect("create", basePath);
    }
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", fnc: () => redirect("create", basePath) },
    {
      name: "Edit",
      disabled: CheckDisableBtn(),
      fnc: () => redirect("edit", basePath, id),
    },
    {
      name: "Delete",
      disabled: CheckDisableBtn(),
      fnc: () => DelOrVoid(),
    },
    { name: "Copy", fnc: () => setOpenCopyDialog(true) },
    // {
    //   name: "Print",
    //   fnc: async () => {
    //     props.showReportLoader();
    //     await showReportByName("GL_JV", [{ Name: "AJvhSeq", Value: id }]);
    //     setTimeout(() => {
    //       props.hideReportLoader();
    //     }, 2000);
    //   },
    // },
  ];

  const DelOrVoid = async () => {
    let msg = translate("ra.question.confirmDel");
    SnackbarUtils.delConfirm(msg, async function () {
      const { Code, InternalMessage, UserMessage } = await delAllocationJvDetail(id);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect("list", basePath);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    });
  };

  const fetchJvById = useCallback(async () => {
    const response = await getAllocationJvDetail(id);
    if (response) {
      setData(response);

      setDataDim(response.DimHList?.Dim);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    fetchJvById();
    setLoading(false);
  }, [fetchJvById]);

  const columns = [
    {
      name: "",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          let dataRow = data?.Detail[tableMeta.rowIndex];

          // let tempDataDetail = {
          //   ...dataRow,
          // };
          return (
            <>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDataDetail(dataRow);
                  // setDataDetail(tempDataDetail);
                  // ShowDim(tableMeta.rowData[12].Dim);
                  setOpenDialogDetail(!openDialogDetail);
                }}
              />
            </>
          );
        },
      },
    },
    {
      name: "DeptCode",
      label: "Dept.",
      options: {
        sort: true,
      },
    },
    {
      name: "AccCode",
      label: "Account #",
      options: {
        sort: true,
      },
    },
    {
      name: "AccDesc",
      label: "Account Name",
      options: {
        sort: true,
      },
    },
    {
      name: "Description",
      label: "Comment",
      options: {
        display: false,
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "CurCode",
      label: "Currency",
    },
    {
      name: "CurRate",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "currency");
        },
      },
    },
    {
      name: "DrQty",
      label: "Unit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          if (value > 0) {
            return NumberFormat(value);
          } else {
            return NumberFormat(data.Detail[tableMeta.rowIndex].CrQty);
          }
        },
      },
    },
    {
      name: "DrAmount",
      label: "Dr. Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrAmount",
      label: "Cr. Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DrBase",
      label: "Dr. Base",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrBase",
      label: "Cr. Base",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    responsive: "standard",
    selectableRows: "none",
    // serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "580px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      let sumDrAmt = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumCrAmt = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumDrBase = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[10]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumCrBase = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[11]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                if (col.name === "DrAmount") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumDrAmt}
                    </TableCell>
                  );
                } else if (col.name === "CrAmount") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumCrAmt}
                    </TableCell>
                  );
                } else if (col.name === "DrBase") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumDrBase}
                    </TableCell>
                  );
                } else if (col.name === "CrBase") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumCrBase}
                    </TableCell>
                  );
                } else {
                  return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
  };

  const ShowDim = (values) => {
    if (!values) {
      setDataDim(data.DimHList.Dim);
      setOpenDim(true);
    } else {
      setDataDim(values);
      setOpenDim(true);
    }
  };

  const SearchByNo = (e) => {
    if (e) {
      redirect("show", basePath, e.AJvhSeq);
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <div
      // className={clsx({
      //   [classes.drawerOpen]: openDim,
      //   [classes.drawerClose]: !openDim,
      // })}
      className={classes.drawerClose}
    >
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["GL.AllocationJv"])}
      />

      <Paper className={classes.root}>
        <BoxHeader
          header={"Allocation Voucher"}
          showSearch
          searchOption={{
            placeholder: `${translate("ra.fieldAbbr.searchby")} ${translate("ra.field.Voucher No.")}`,
            update: SearchByNo,
            module: "Allocation_Jv",
            keyCode: "Prefix",
            keyName: "JvhNo",
            keyDesc: "Description",
          }}
          data={data}
          source={data.JvhSource}
          status={data.Status}
        />
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
          <TextTopInGrid sizeSm={2} label="Prefix" value={data.Prefix} />

          <TextTopInGrid sizeSm={10} label="Description" value={data.Description} />
          <Grid item sm={6} />
          <TextTopInGrid sizeSm={3} label="Amount to be allocated" value={NumberFormat(data.AllocateAmt)} />
          <TextTopInGrid sizeSm={3} label="Allocate Unit" value={NumberFormat(data.AllocateUnit)} />
        </Grid>
        <MuiTranslateTable data={data.Detail} columns={columns} options={options} />
      </Paper>

      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => ShowDim()}
        dataDim={dataDim}
        dataDetail={dataDetail}
        module={"GL_AllocationJv"}
        moduleId={id}
      />

      {openDialogDetail && (
        <DialogViewJVDetail
          open={openDialogDetail}
          onClose={() => setOpenDialogDetail(false)}
          data={dataDetail}
          title={"Allocation Voucher"}
        />
      )}

      {openCopyDialog && <DialogCopy open={openCopyDialog} onClose={CloseCopyDialog} basePath={basePath} />}

      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
    </div>
  );
};

export default ReportBackDrop(Show);
