import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppBar, UserMenu, useRedirect, useTranslate } from "react-admin";
import Toolbar from "@material-ui/core/Toolbar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { makeStyles, Fab, Popover, MenuItem, IconButton, CssBaseline, Typography, Badge } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import NotificationsIcon from "@material-ui/icons/Notifications";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ScrollTop from "./ScrollTop";
import ReleaseNote from "../components/ReleaseNote";
const packageJson = require("../../package.json");

const packageJsonV = parseFloat(packageJson.version);

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  toolbar: {
    minHeight: 2,
  },
  typography: {
    padding: theme.spacing(2),
  },
  notiContent: {
    width: 320,
    height: 320,
  },
}));

const ConfigurationMenu = React.forwardRef((props, ref) => {
  return (
    <>
      <MenuItem
        onClick={() => {
          props.onClick();
          props.redirect("/profile");
        }}
      >
        <ListItemIcon style={{ minWidth: 40 }}>
          <AccountCircleIcon />
        </ListItemIcon>
        {props.translate("ra.module.Profile")}
      </MenuItem>

      <MenuItem
        onClick={() => {
          window.open("https://docscarmencloud.vercel.app/", "_blank");
        }}
      >
        <ListItemIcon style={{ minWidth: 40 }}>
          <HelpOutlineIcon />
        </ListItemIcon>
        {props.translate("ra.info.help")}
      </MenuItem>
    </>
  );
});

const CustomUserMenu = (props) => {
  return (
    <UserMenu {...props} icon={<AccountCircleIcon style={{ fontSize: 32 }} />}>
      <ConfigurationMenu {...props} />
    </UserMenu>
  );
};

const CustomAppBar = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const theme = useSelector((state) => state.theme);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openReleaseNote, setOpenReleaseNote] = React.useState(false);

  const checkLastSeenNoti = () => {
    if ((sessionStorage.getItem("userLastSeenNotification") ?? []).includes(localStorage.getItem("UserName"))) {
      if (parseFloat(sessionStorage.getItem("releaseNote") ?? 0) < packageJsonV) {
        return 1;
      } else {
        return null;
      }
    } else {
      return 1;
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    sessionStorage.setItem("releaseNote", packageJsonV);

    let arr = JSON.parse(sessionStorage.getItem("userLastSeenNotification") ?? "[]");
    arr.push(localStorage.getItem("UserName"));
    sessionStorage.setItem("userLastSeenNotification", JSON.stringify(arr));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const redirect = useRedirect();
  const ChangeTheme = (theme) => {
    dispatch({
      type: "CHANGE_THEME",
      payload: theme === "light" ? "dark" : "light",
    });
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        {...props}
        position="fixed"
        className={classes.appBar}
        elevation={1}
        userMenu={<CustomUserMenu redirect={redirect} logout={props.logout} translate={translate} />}
      >
        <span className={classes.spacer} />
        {/* TODO: Notification */}
        <IconButton aria-describedby="toggleNotifications" color="inherit" onClick={handleClick}>
          <Badge badgeContent={checkLastSeenNoti()} color="primary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Popover
          id="toggleNotifications"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{ className: classes.notiContent }}
          style={{ width: 640, height: 320 }}
        >
          <List dense aria-label="secondary mailbox folders">
            {window.VersionList.map((item, index) => (
              <ListItem
                button
                onClick={() => {
                  handleClose();
                  setOpenReleaseNote(true);
                }}
                key={index}
              >
                <ListItemText
                  primary={
                    <>
                      <Typography component="p" variant="body1" gutterBottom>
                        <b>
                          V {item.Version} ({item.UpdateDate})
                        </b>{" "}
                        {index === 0 && <>🆕</>}
                      </Typography>
                      <Typography component="p" variant="body2" gutterBottom>
                        <span id="notification-message">{item.Announce}</span>
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
            {window.VersionList.length > 2 && (
              <ListItem
                button
                onClick={() => {
                  handleClose();
                  setOpenReleaseNote(true);
                }}
              >
                <ListItemText primary={<div style={{ textAlign: "center" }}>View All</div>} />
              </ListItem>
            )}
          </List>
        </Popover>
        {openReleaseNote && (
          <ReleaseNote
            open={openReleaseNote}
            close={() => {
              setOpenReleaseNote(false);
            }}
          />
        )}
        <IconButton aria-label="ChangeTheme" color="inherit" onClick={() => ChangeTheme(theme)}>
          {theme === "light" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </AppBar>
      <Toolbar className={classes.toolbar} id="back-to-top-anchor" />
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default CustomAppBar;
