/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  textPrimary: {
    color: theme.palette.type === "dark" ? "inherit" : "#2196f3",
  },
  bgPrimary: {
    backgroundColor: "rgba(0, 0, 0, 0.06)",
  },
}));

export default function PermissionRow(props) {
  const classes = useStyles();
  const { rootArr, secondRootArr, listInRow, mode, index } = props;
  const [item, setItem] = useState();
  const [showCollapse, setShowCollapse] = useState([]);
  const [showSubItemCollapse, setShowSubItemCollapse] = useState([]);
  const [currentExpand, setCurrentExpand] = useState();
  const [subParentId, setSubParentId] = useState();

  function useForceUpdate() {
    let [value, setState] = useState(true);
    return () => setState(!value);
  }

  let forceUpdate = useForceUpdate();

  const onChange = (e, i) => {
    i[`${e.target.name}`] = e.target.checked;
    if (!e.target.checked && e.target.name === "View") {
      i.View = e.target.checked;
      i.Add = e.target.checked;
      i.Update = e.target.checked;
      i.Delete = e.target.checked;
      i[`${e.target.name}`] = e.target.checked;
    } else if (e.target.checked && e.target.name !== "View") {
      i.View = e.target.checked;
    }

    props.onChange(i);
    setItem((prevState) => {
      let newItem = { ...prevState, ...i };
      return newItem;
    });
  };

  const onRootSelectAll = (e, index) => {
    let listByName = listInRow.filter((i) => i.Id === rootArr[index]);
    listByName.forEach((i, idx) => {
      i[`${e.target.name}`] = e.target.checked;
      if (!e.target.checked && e.target.name === "View") {
        i.View = e.target.checked;
        i.Add = e.target.checked;
        i.Update = e.target.checked;
        i.Delete = e.target.checked;
      } else if (e.target.checked && e.target.name !== "View") {
        i.View = e.target.checked;
      }
      props.onChange(i);
    });
    setItem(listByName);
  };

  const onSecondRootSelectAll = (e, Id) => {
    let listByName = listInRow.filter((i) => i.ParentId === Id);
    let currentHeader = listInRow.find((i) => i.Id === Id);
    currentHeader[`${e.target.name}`] = e.target.checked;

    listByName.forEach((item) => {
      item[`${e.target.name}`] = e.target.checked;
      if (!e.target.checked && e.target.name === "View") {
        item.View = e.target.checked;
        item.Add = e.target.checked;
        item.Update = e.target.checked;
        item.Delete = e.target.checked;
      } else if (e.target.checked && e.target.name !== "View") {
        item.View = e.target.checked;
      }
    });
    setItem((prevState) => {
      let newItem = { ...prevState, currentHeader, ...listByName };
      props.onChange(newItem);
      return newItem;
    });
  };

  useEffect(() => {
    if (item) {
      setShowCollapse(rootArr.map(() => false));
      setShowSubItemCollapse(secondRootArr.map(() => false));
    }
  }, []);

  return (
    <React.Fragment>
      <TableRow className={classes.bgPrimary}>
        <TableCell style={{ width: 50 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              showCollapse[index] = !showCollapse[index];
              setShowCollapse(showCollapse);
              forceUpdate();
            }}
          >
            {showCollapse[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className={classes.textPrimary}>
          <b>{rootArr[index]}</b>
        </TableCell>
        <TableCell align="center">
          <Checkbox name="View" disabled={mode === "view"} onChange={(e) => onRootSelectAll(e, index)} />
        </TableCell>
        <TableCell align="center">
          <Checkbox name="Add" disabled={mode === "view"} onChange={(e) => onRootSelectAll(e, index)} />
        </TableCell>
        <TableCell align="center">
          <Checkbox name="Update" disabled={mode === "view"} onChange={(e) => onRootSelectAll(e, index)} />
        </TableCell>
        <TableCell align="center">
          <Checkbox name="Delete" disabled={mode === "view"} onChange={(e) => onRootSelectAll(e, index)} />
        </TableCell>
      </TableRow>

      <TableRow className={classes.root}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0 }} colSpan={6}>
          <Collapse in={showCollapse[index]} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                {listInRow
                  .filter((i) => i.ParentId === rootArr[index])
                  .map((subItem, idx) => (
                    <React.Fragment key={idx}>
                      <TableRow className={subItem.HaveChild ? classes.bgPrimary : null}>
                        {subItem.HaveChild ? (
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                setCurrentExpand(idx);
                                var falseArray = showSubItemCollapse.map(() => false);
                                if (currentExpand !== idx) {
                                  falseArray[idx] = !falseArray[idx];
                                  setShowSubItemCollapse(falseArray);
                                } else {
                                  showSubItemCollapse[idx] = !showSubItemCollapse[idx];
                                  setShowSubItemCollapse(showSubItemCollapse);
                                }
                                setSubParentId(subItem.Id);
                                forceUpdate();
                              }}
                            >
                              {showSubItemCollapse[idx] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                        ) : (
                          <TableCell style={{ width: 60 }}></TableCell>
                        )}
                        <TableCell component="th" scope="row">
                          {subItem.HaveChild ? <b className={classes.textPrimary}>{subItem.Name}</b> : subItem.Name}
                        </TableCell>
                        <TableCell style={{ width: 140 }} align="center">
                          <Checkbox
                            name="View"
                            disabled={mode === "view"}
                            checked={subItem.View}
                            onChange={(e) => {
                              if (subItem.HaveChild) {
                                onSecondRootSelectAll(e, subItem.Id);
                              } else {
                                onChange(e, subItem);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell style={{ width: 140 }} align="center">
                          {subItem.CanAdd && (
                            <Checkbox
                              name="Add"
                              disabled={mode === "view"}
                              checked={subItem.Add}
                              onChange={(e) => {
                                if (subItem.HaveChild) {
                                  onSecondRootSelectAll(e, subItem.Id);
                                } else {
                                  onChange(e, subItem);
                                }
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell style={{ width: 140 }} align="center">
                          {subItem.CanUpdate && (
                            <Checkbox
                              name="Update"
                              disabled={mode === "view"}
                              checked={subItem.Update}
                              onChange={(e) => {
                                if (subItem.HaveChild) {
                                  onSecondRootSelectAll(e, subItem.Id);
                                } else {
                                  onChange(e, subItem);
                                }
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell style={{ width: 140 }} align="center">
                          {subItem.CanDelete && (
                            <Checkbox
                              name="Delete"
                              disabled={mode === "view"}
                              checked={subItem.Delete}
                              onChange={(e) => {
                                if (subItem.HaveChild) {
                                  onSecondRootSelectAll(e, subItem.Id);
                                } else {
                                  onChange(e, subItem);
                                }
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow className={classes.root}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0 }} colSpan={6}>
                          <Collapse in={showSubItemCollapse[idx]} timeout="auto" unmountOnExit>
                            <Table size="small" aria-label="purchases">
                              <TableBody>
                                {listInRow
                                  .filter((i) => i.ParentId === subParentId)
                                  .filter((i) => i.LastChild === true)
                                  .map((subOfSubItem, iidx) => (
                                    <TableRow key={iidx}>
                                      <TableCell style={{ width: 100 }}></TableCell>
                                      <TableCell component="th" scope="row">
                                        {subOfSubItem.Name}
                                      </TableCell>
                                      <TableCell style={{ width: 140 }} align="center">
                                        <Checkbox
                                          name="View"
                                          disabled={mode === "view"}
                                          checked={subOfSubItem.View}
                                          onChange={(e) => onChange(e, subOfSubItem)}
                                        />
                                      </TableCell>
                                      <TableCell style={{ width: 140 }} align="center">
                                        {subOfSubItem.CanAdd && (
                                          <Checkbox
                                            name="Add"
                                            disabled={mode === "view"}
                                            checked={subOfSubItem.Add}
                                            onChange={(e) => onChange(e, subOfSubItem)}
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell style={{ width: 140 }} align="center">
                                        {subOfSubItem.CanUpdate && (
                                          <Checkbox
                                            name="Update"
                                            disabled={mode === "view"}
                                            checked={subOfSubItem.Update}
                                            onChange={(e) => onChange(e, subOfSubItem)}
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell style={{ width: 140 }} align="center">
                                        {subOfSubItem.CanDelete && (
                                          <Checkbox
                                            name="Delete"
                                            disabled={mode === "view"}
                                            checked={subOfSubItem.Delete}
                                            onChange={(e) => onChange(e, subOfSubItem)}
                                          />
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
