/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import AccessDenied from "layout/AccessDenied";
import { makeStyles } from "@material-ui/core/styles";
import UserList from "./UserList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    padding: theme.spacing(2),
  },
  tabPanel: { width: "100%", margin: "0 20px" },
  details: {
    display: "flex",
    alignItems: "center",
  },
  content: {
    flex: "1 0 auto",
  },
  contentEnd: {
    marginTop: "18px",
    paddingBottom: "16px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  atlarge: {
    margin: theme.spacing(2),
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  cover: {
    width: 151,
  },
  status: {
    display: "flex",
    //alignItems: "center",
    marginTop: theme.spacing(2),
  },
  menus: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    marginRight: theme.spacing(2),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  statusActive: {
    backgroundColor: "green",
    color: "white",
  },
  formControl: {
    margin: theme.spacing(0.5),
    minWidth: 120,
  },
}));

const Users = (props) => {
  const { permissions, value, index } = props;

  let checkPermission = permissions && permissions.find((i) => i.Name === "Sys.User")?.View;

  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {checkPermission && value === index ? <UserList {...props} /> : <AccessDenied />}
    </div>
  );
};

export default Users;
