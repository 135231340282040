import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Chip, Typography, Icon, CardActions } from "@material-ui/core";
//import {  IconButton, Collapse, CardContent } from "@material-ui/core";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperStatusOther: {
    padding: theme.spacing(1, 2),
    margin: "auto",
    maxWidth: 500,
    cursor: "pointer",
  },
  paperStatusNormal: {
    borderLeft: "4px solid #2196f3",
    padding: theme.spacing(1, 2),
    margin: "auto",
    maxWidth: 500,
    cursor: "pointer",
  },
  paperStatusVoid: {
    borderLeft: "4px solid #e57373",
    padding: theme.spacing(1, 2),
    margin: "auto",
    maxWidth: 500,
    cursor: "pointer",
  },
  statusOther: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  statusCurrency: {
    backgroundColor: "green",
    color: "white",
  },
  statusNormal: {
    backgroundColor: "#2196f3",
    color: "white",
  },
  statusVoid: {
    backgroundColor: "#e57373",
    color: "white",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default function MbCard(props) {
  const classes = useStyles();
  const { data } = props;
  //const [expanded, setExpanded] = React.useState(false);
  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  if (!data) {
    return (
      <div className={classes.root}>
        <Paper align="center">
          <br />
          <Icon fontSize="large">report</Icon>
          <br />
          <Typography gutterBottom variant="subtitle1">
            Sorry, no matching records found
          </Typography>
          <br />
        </Paper>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Paper
        className={clsx(classes.paperStatusOther, {
          [classes.paperStatusNormal]: data?.VdStatus === "Effective",
          [classes.paperStatusVoid]: data?.VdStatus === "Void",
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1">
                  Seq : {data.Id}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {`${data.VnCateCode} : ${data.VnCateDesc}`}
              </Typography>
            </Grid>
          </Grid>
          <div style={{ marginTop: -8, padding: "0 8px 8px 8px" }}>
            <Typography variant="body2" gutterBottom>
              {data.VnAttn} {data.VnCode} : {data.VnName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {data.VnEmail ? `Email : ${data.VnEmail}` : null}
              {data.VnEmail ? <br /> : null}
              {data.VnTel ? `Telephone : ${data.VnTel}` : null}
            </Typography>
          </div>
          <CardActions disableSpacing style={{ marginTop: -10, width: "100%", justifyContent: "space-between" }}>
            <div>
              <Chip
                label={data.VdStatus}
                size="small"
                className={clsx(classes.statusOther, {
                  [classes.statusNormal]: data.VdStatus === "Effective",
                  [classes.statusVoid]: data.VdStatus === "Void",
                })}
              />
            </div>
          </CardActions>
        </Grid>
      </Paper>
    </div>
  );
}
