export default {
  PyhSeq: "Auto",
  VnCode: "",
  PyhDate: new Date(),
  CurCode: "",
  CurRate: 0,
  DeptPayCode: "",
  PyhAmt: 0,
  PyhAbfTax: 0,
  AppyCode: "",
  PyhRef: "",
  PyhChqFr: "",
  PyhChqTo: "",
  PyhChqDt: null,
  PyhClearDt: null,
  PyhPostDt: new Date(),
  PyhClearAmt: 0,
  PyhCr: "",
  PyhDesc: "",
  CurAmt: 0,
  CurPayAmt: 0,
  GainLossDept: "",
  GainLossAcc: "",
  GainLossAmt: 0,
  // PayWht: {
  //   Payee: "",
  //   Address: "",
  //   TaxId: "",
  //   WhtTypeCode: "",
  //   WhtTotalAmt: 0,
  //   WhtDeptCode: "",
  //   WhtRemark1: "1",
  //   WhtRemark2: "",
  //   WhtNoAuto: true,
  //   WhtNo: "",
  //   WhtTaxCr: "",
  //   Items: [],
  // },
  DimHList: {
    Dim: [],
  },
  Detail: [],
  Payee: "",
  Address: "",
  TaxId: "",
  PrePayee:"",
  WhtTypeCode: null,
  WhtTotalAmt: 0,
  WhtDeptCode: "",
  WhtRemark1: "",
  WhtRemark2: "",
  WhtNoAuto: true,
  WhtNo: "",
  WhtTaxCr: "",
  Items: [],
  UserModified: localStorage.getItem("UserName"),
};
