/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { Checkbox } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { TextFieldInForm, MuiAutosuggest, SelectInForm } from "components/Form";
import ButtonFooter from "components/ButtonFooter";
import { getSettingInfBlueledger, updateSettingInfBlueledger } from "services/interface";
import { getDepartmentList, getAccountCodeList } from "services/setting";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  box: {
    border: "1px solid #a7a7a7",
    borderRadius: 8,
    marginBottom: 30,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const BL = (props) => {
  const classes = useStyles();
  const [lookupList, setLookupList] = useState({
    accountCodeListEx: [],
    accountCodeListRe: [],
    departmentList: [],
  });
  const { code, editMode, cancelFnc } = props;
  const [infDetail, setInfDetail] = useStateWithCallbackLazy();
  const [oldAccList, setOldAccList] = useState([]);
  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList();
    setLookupList((state) => ({
      ...state,
      accountCodeListEx: Data,
      accountCodeListRe: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  }, []);

  const methods = useForm({ defaultValues: infDetail });

  const { handleSubmit, reset } = methods;

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  React.useEffect(async () => {
    if (!infDetail) {
      if (code === "BL" || code.toLowerCase() === "blueledger" || code.toLowerCase() === "blueledgers") {
        const r = await getSettingInfBlueledger();
        if (r) {
          setInfDetail(r);
          reset(r);
        }
      }
      if (editMode) {
        fetchAccLookup();
        fetchDeptLookup();
      }
    }
  }, []);

  const onSubmit = (values) => {
    //Adjust parameter before save
    setInfDetail(
      (state) => ({
        ...state,
        ...values,
      }),
      async (nextState) => {
        SaveFnc(nextState);
      }
    );
  };

  const SaveFnc = async (values) => {
    const r = await updateSettingInfBlueledger(values);
    if (r.Code >= 0) {
      SnackbarUtils.success(r.UserMessage);
      cancelFnc();
    } else {
      SnackbarUtils.warning(r.InternalMessage);
    }
  };

  if (infDetail) {
    infDetail.ConfigExtraCost = infDetail.ConfigExtraCost ?? "";
    infDetail.ConfigInventory = infDetail.ConfigInventory ?? "";
    infDetail.ConfigReceiving = infDetail.ConfigReceiving ?? "";

    return (
      <div className={classes.box}>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
          <ListItem>
            <ListItemText primary="Url" />
            <div style={{ width: 320, display: "inline-flex" }}>
              <TextFieldInForm
                key="Url"
                name="Url"
                margin="dense"
                placeholder="Url"
                // value={tempUrl}
                methods={methods}
                style={{ width: 320, display: "inline-flex" }}
                onBlur={(e) => {
                  setInfDetail((state) => ({
                    ...state,
                    Url: e.target.value,
                  }));
                }}
                disabled={!editMode}
              />
            </div>
          </ListItem>
          <ListItem>
            <ListItemText primary="SecurityToken" />
            <div style={{ width: 320, display: "inline-flex" }}>
              <TextFieldInForm
                key="SecurityToken"
                name="SecurityToken"
                margin="dense"
                placeholder="SecurityToken"
                //value={tempSecurityToken}
                methods={methods}
                style={{ width: 320, display: "inline-flex" }}
                // onChange={(e) => {
                //   setTempSecurityToken(e.target.value);
                // }}
                // onBlur={(e) => {
                //   setInfDetail((state) => ({
                //     ...state,
                //     SecurityToken: e.target.value,
                //   }));
                // }}
                disabled={!editMode}
              />
            </div>
          </ListItem>
          <ListItem>
            <ListItemText primary="CheckPeriodInventoryCloseBeforePost" />
            <Checkbox
              checked={infDetail["CheckPeriodInventoryCloseBeforePost"]}
              color="primary"
              onChange={(e, newValue) => {
                setInfDetail((state) => ({
                  ...state,
                  CheckPeriodInventoryCloseBeforePost: newValue,
                }));
              }}
              disabled={!editMode}
            />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Config ExtraCost</b>} />
          </ListItem>

          <ListItem className={classes.nested}>
            <ListItemIcon>-</ListItemIcon>
            <ListItemText primary="ExtraCostPostType" />
            <div style={{ width: 210, display: "inline-flex" }}>
              {methods.watch()?.ConfigExtraCost?.PostType && (
                <SelectInForm
                  variant="outlined"
                  margin="dense"
                  name="ConfigExtraCost.PostType"
                  methods={methods}
                  options={["Summary", "Detail"]}
                  disabled={!editMode}
                />
              )}
            </div>
          </ListItem>

          <ListItem className={classes.nested}>
            <ListItemIcon>-</ListItemIcon>
            <ListItemText primary="Department" />
            {lookupList["departmentList"] && (
              <MuiAutosuggest
                label="Department Code"
                name="ConfigExtraCost.DeptCode"
                optKey="DeptCode"
                optDesc="Description"
                options={lookupList["departmentList"]}
                useFncUpdate={true}
                fncUpdate={(value, methods) => {
                  let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                  if (daccList?.length > 0) {
                    setLookupList((state) => ({
                      ...state,
                      accountCodeListEx: daccList,
                    }));
                    //remove acc if not in defaultaccount
                    let acc = methods.getValues("ConfigExtraCost.AccCode");
                    if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                      methods.setValue("ConfigExtraCost.AccCode", "");
                    }
                  } else {
                    setLookupList((state) => ({
                      ...state,
                      accountCodeListEx: oldAccList,
                    }));
                  }
                }}
                methods={methods}
                style={{ width: 210 }}
                disabled={!editMode}
              />
            )}
          </ListItem>
          <ListItem className={classes.nested}>
            <ListItemIcon>-</ListItemIcon>
            <ListItemText primary="Account Code" />
            {lookupList["accountCodeListEx"] && (
              <MuiAutosuggest
                label="Account Code"
                name="ConfigExtraCost.AccCode"
                optKey="AccCode"
                optDesc={localStorage.getItem("Language") === "en-US" ? "Description" : "Description2"}
                options={lookupList["accountCodeListEx"]}
                methods={methods}
                style={{ width: 210 }}
                disabled={!editMode}
              />
            )}
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Config Inventory</b>} />
          </ListItem>

          <ListItem className={classes.nested}>
            <ListItemIcon>-</ListItemIcon>
            <ListItemText primary="InventoryPostType" />
            <div style={{ width: 210, display: "inline-flex" }}>
              {methods.watch()?.ConfigInventory?.PostType && (
                <SelectInForm
                  variant="outlined"
                  margin="dense"
                  name="ConfigInventory.PostType"
                  methods={methods}
                  options={["Summary", "Detail"]}
                  disabled={!editMode}
                />
              )}
            </div>
          </ListItem>

          <ListItem button>
            <ListItemIcon>
              <NavigateNextIcon />
            </ListItemIcon>
            <ListItemText primary={<b>Config Receiving</b>} />
          </ListItem>

          <ListItem className={classes.nested}>
            <ListItemIcon>-</ListItemIcon>
            <ListItemText primary="Department" />
            {lookupList["departmentList"] && (
              <MuiAutosuggest
                label="Department Code"
                name="ConfigReceiving.DeptCode"
                optKey="DeptCode"
                optDesc="Description"
                options={lookupList["departmentList"]}
                useFncUpdate={true}
                fncUpdate={(value, methods) => {
                  let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                  if (daccList?.length > 0) {
                    setLookupList((state) => ({
                      ...state,
                      accountCodeListRe: daccList,
                    }));
                    //remove acc if not in defaultaccount
                    let acc = methods.getValues("ConfigReceiving.CrAccCode");
                    let taxacc = methods.getValues("ConfigReceiving.TaxAccCode");
                    if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                      methods.setValue("ConfigReceiving.CrAccCode", "");
                    }
                    if (taxacc !== "" && !daccList.find((i) => i.AccCode === taxacc)) {
                      methods.setValue("ConfigReceiving.TaxAccCode", "");
                    }
                  } else {
                    setLookupList((state) => ({
                      ...state,
                      accountCodeListRe: oldAccList,
                    }));
                  }
                }}
                methods={methods}
                style={{ width: 210 }}
                disabled={!editMode}
              />
            )}
          </ListItem>
          <ListItem className={classes.nested}>
            <ListItemIcon>-</ListItemIcon>
            <ListItemText primary="CreditAccount Code" />
            {lookupList["accountCodeListRe"] && (
              <MuiAutosuggest
                label="Account Code"
                name="ConfigReceiving.CrAccCode"
                optKey="AccCode"
                optDesc={localStorage.getItem("Language") === "en-US" ? "Description" : "Description2"}
                options={lookupList["accountCodeListRe"]}
                methods={methods}
                style={{ width: 210 }}
                disabled={!editMode}
              />
            )}
          </ListItem>
          <ListItem className={classes.nested}>
            <ListItemIcon>-</ListItemIcon>
            <ListItemText primary="TaxAccount Code" />
            {lookupList["accountCodeListRe"] && (
              <MuiAutosuggest
                label="Account Code"
                name="ConfigReceiving.TaxAccCode"
                optKey="AccCode"
                optDesc={localStorage.getItem("Language") === "en-US" ? "Description" : "Description2"}
                options={lookupList["accountCodeListRe"]}
                methods={methods}
                style={{ width: 210 }}
                disabled={!editMode}
              />
            )}
          </ListItem>

          {editMode && (
            <div
              style={{
                marginTop: 12,
                marginRight: 12,
                marginBottom: 12,
                display: "flex",
                justifyContent: " space-around",
              }}
            >
              <ButtonFooter noBorder CancelFnc={cancelFnc} />
            </div>
          )}
        </form>
      </div>
    );
  } else {
    return null;
  }
};

export default BL;
