import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useAuthState, useRedirect, useLocale, useTranslate } from "react-admin";
import { MuiAutosuggest, SelectInForm, DateInForm, DescInForm, NumberFormatInForm } from "components/Form";
import { getAccountCodeList, getDepartmentList } from "services/setting";
import List from "./List";
import Show from "./Show";
import Edit from "./Edit";
import Create from "./Create";
import { DisposalTypeOptions } from "utils/options";
import { makeStyles } from "@material-ui/core/styles";
import { addDays } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 12,
    marginBottom: 12,
  },
  heading: {
    marginTop: 12,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  content: {
    padding: 4,
  },
}));

const ListMode = (props) => {
  const [openSelectTypeDialog, setOpenSelectTypeDialog] = useState(false);
  const [openRegDialog, setOpenRegDialog] = useState(false);
  const redirect = useRedirect();
  const FncAddButton = () => {
    setOpenSelectTypeDialog(true);
  };

  const CloseRegisterDialog = (regId) => {
    setOpenRegDialog(false);
    if (regId) {
      localStorage.setItem("regId", regId);
      redirect("create", props.basePath);
    }
  };

  const CloseSelectTypeDialog = (status) => {
    setOpenSelectTypeDialog(false);
    if (status) {
      setOpenRegDialog(status);
    }
  };

  return (
    <List
      {...props}
      open={openRegDialog}
      onClose={CloseRegisterDialog}
      openSelectType={openSelectTypeDialog}
      onCloseSelectType={CloseSelectTypeDialog}
      FncAddButton={FncAddButton}
    />
  );
};

const ViewMode = (props) => {
  const [openSelectTypeDialog, setOpenSelectTypeDialog] = useState(false);
  const [openRegDialog, setOpenRegDialog] = useState(false);
  const redirect = useRedirect();
  const FncAddButton = () => {
    setOpenSelectTypeDialog(true);
  };

  const CloseRegisterDialog = (regId) => {
    setOpenRegDialog(false);
    if (regId) {
      localStorage.setItem("regId", regId);
      redirect("create", props.basePath);
    }
  };

  const CloseSelectTypeDialog = (status) => {
    setOpenSelectTypeDialog(false);
    if (status) {
      setOpenRegDialog(status);
    }
  };
  return (
    <Show
      {...props}
      open={openRegDialog}
      openSelectType={openSelectTypeDialog}
      onClose={CloseRegisterDialog}
      onCloseSelectType={CloseSelectTypeDialog}
      FncAddButton={FncAddButton}
      useStyles={useStyles}
    />
  );
};

const SwitchActionMode = (props) => {
  const locale = useLocale();
  const translate = useTranslate();
  const { authenticated } = useAuthState();
  const { settingAll } = useContext(GblContext);
  const { SettingClosePeriod, SettingSystem } = settingAll;
  const { ClosePeriodAsset } = SettingClosePeriod;
  let newClosePeriodAsset = addDays(new Date(ClosePeriodAsset), 1);
  const [oldAccList, setOldAccList] = useState([]);
  const addMode = props.location.pathname.search("create") !== -1;
  const [lookupList, setLookupList] = useState({
    accountCodeListGA: [],
    accountCodeListSA: [],
    departmentList: [],
  });
  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList("Asset");
    setLookupList((state) => ({
      ...state,
      accountCodeListGA: Data,
      accountCodeListSA: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  }, []);

  useEffect(() => {
    if (authenticated) {
      fetchAccLookup();
      fetchDeptLookup();
    }
  }, [authenticated, fetchAccLookup, fetchDeptLookup]);

  const formFieldsDisposalQty = [
    {
      size: 12,
      field: (
        <SelectInForm label={translate("ra.field.Disposal Type")} name="Type" options={DisposalTypeOptions} disabled />
      ),
    },
    {
      size: 12,
      field: (
        <DateInForm
          label={translate("ra.field.Disposal Date")}
          name="DisposalDate"
          minDate={new Date(newClosePeriodAsset)}
          customMinDate={"LastDisposalDate"}
          minDateMessage={"Date must be more than close period"}
          disabled={!addMode}
          required
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Amount/Unit")}
          name="AstAmt"
          readOnly
          decimal={SettingSystem.CostPerUnitBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
          useOnBlur
        />
      ),
      //style: { marginTop: -4 },
    },
    {
      size: 12,
      field: <NumberFormatInForm label={translate("ra.field.Qty")} name="Qty" decimal={0} useOnBlur />,
      style: { marginTop: -4 },
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Total Asset Value")}
          name="TotalCost"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
      style: { marginTop: -3 },
    },
  ];

  const formFieldsDisposalImp = [
    {
      size: 12,
      field: (
        <DateInForm
          label={translate("ra.field.Date")}
          name="DisposalDate"
          minDate={new Date(newClosePeriodAsset)}
          customMinDate={"LastDisposalDate"}
          minDateMessage={"Date must be more than close period"}
          disabled={!addMode}
          required
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Sale Amount")}
          name="SaleAmt"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
      style: { marginTop: -4 },
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Net Book Value")}
          name="NetBook"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
      style: { marginTop: -4 },
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Gain/Loss Amount")}
          name="GainLossAmt"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
      style: { marginTop: -3 },
    },
  ];

  const formFieldsSaleQty = [
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Sale Amount")}
          name="SaleAmt"
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
      style: { marginTop: -3 },
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Net Book Value")}
          name="NetBook"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
      style: { marginTop: -3.2 },
    },
    {
      size: 12,
      field: (
        <NumberFormatInForm
          label={translate("ra.field.Gain/Loss Amount")}
          name="GainLossAmt"
          readOnly
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
      style: { marginTop: -3.2 },
    },
  ];

  const formFieldsAccountQty = [
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Gain/Loss Department")}
          name="GainLossDeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "GainLossDeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListGA: daccList,
              }));
              //remove acc if not in defaultaccount
              let acc = methods.getValues("GainLossAccCode");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("GainLossAccCode", "");
                methods.setValue("GainLossAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListGA: oldAccList,
              }));
            }
          }}
          clearable
        />
      ),
    },
    {
      size: 3,
      name: "GainLossDeptDesc",
      field: (
        <DescInForm
          name="GainLossDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Sale Department")}`}
          name="SaleDeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "SaleDeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeListSA: daccList,
              }));
              //remove acc if not in defaultaccount
              let acc = methods.getValues("SaleAccCode");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("SaleAccCode", "");
                methods.setValue("SaleAccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeListSA: oldAccList,
              }));
            }
          }}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      name: "SaleDeptDesc",
      field: (
        <DescInForm
          name="SaleDeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={translate("ra.field.Gain/Loss Account")}
          name="GainLossAccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListGA"]}
          updateOtherField={[
            {
              key: "GainLossAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          clearable
        />
      ),
    },
    {
      size: 3,
      name: "GainLossAccDesc",
      field: (
        <DescInForm
          name="GainLossAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={`*${translate("ra.field.Sale Account")}`}
          name="SaleAccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeListSA"]}
          updateOtherField={[
            {
              key: "SaleAccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      name: "SaleAccDesc",
      field: (
        <DescInForm
          name="SaleAccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
  ];

  if (addMode) {
    let regId = localStorage.getItem("regId");
    return (
      <Create
        {...props}
        regId={regId ?? 0}
        formFieldsDisposalQty={formFieldsDisposalQty}
        formFieldsSaleQty={formFieldsSaleQty}
        formFieldsAccountQty={formFieldsAccountQty}
        formFieldsDisposalImp={formFieldsDisposalImp}
        useStyles={useStyles}
      />
    );
  } else {
    return (
      <Edit
        {...props}
        formFieldsDisposalQty={formFieldsDisposalQty}
        formFieldsSaleQty={formFieldsSaleQty}
        formFieldsAccountQty={formFieldsAccountQty}
        formFieldsDisposalImp={formFieldsDisposalImp}
        useStyles={useStyles}
      />
    );
  }
};

export default {
  list: ListMode,
  show: ViewMode,
  edit: SwitchActionMode,
  create: SwitchActionMode,
};
