import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { GblContext } from "providers/formatter";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Grid, IconButton, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import TextTopInGrid from "./TextTopInGrid";
import { useTranslate } from "react-admin";
import DialogViewAPInvoice from "./DialogViewAPInvoice";

function DialogViewPaymentAP(props) {
  const { data, open, onClose, Id } = props;

  const translate = useTranslate();
  const { NumberFormat, DateToString } = useContext(GblContext);
  const [openDialogInV, setOpenDialogInV] = useState(false);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "10px 24px" }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {"Payment Detail"}
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 5 }}>
            <Button
              variant="contained"
              style={{ marginBottom: 5, marginLeft: "auto" }}
              onClick={() => {
                setOpenDialogInV(!openDialogInV);
              }}
            >
              {translate("View Invoice")}
            </Button>
            <TextTopInGrid sizeSm={12} label="Invoice No." value={data.InvoiceHeader.InvhInvNo} />
            <TextTopInGrid sizeSm={12} label="Vendor" value={data.InvoiceHeader.VnCode} />
            <TextTopInGrid
              sizeSm={6}
              label="Currency"
              value={data.InvoiceHeader.CurCode}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={6}
              label="Rate"
              value={NumberFormat(data.InvoiceHeader.CurRate)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid sizeSm={6} label="Input Date" value={DateToString(data.InvoiceHeader.InvhDate)} />
            <TextTopInGrid sizeSm={6} label="Invoice Date" value={DateToString(data.InvoiceHeader.InvhInvDate)} />
            <TextTopInGrid sizeSm={6} label="Credit" value={data.InvoiceHeader.InvhCredit} />
            <TextTopInGrid sizeSm={6} label="Due Date" value={DateToString(data.InvoiceHeader.InvhDueDate)} />
            <TextTopInGrid sizeSm={6} label="Tax Inv No." value={data.InvoiceHeader.InvhTInvNo} />
            <TextTopInGrid sizeSm={6} label="Tax Status" value={data.InvoiceHeader.TaxStatus} />
            <TextTopInGrid sizeSm={6} label="Tax Inv Date" value={DateToString(data.InvoiceHeader.InvhTInvDt)} />
            <TextTopInGrid sizeSm={6} label="Tax Period" value={data.InvoiceHeader.TaxPeriod} />
            <TextTopInGrid sizeSm={12} label="Description" value={data.InvoiceDetail.InvdDesc} />
          </Grid>
        </DialogContent>
        <br />
      </Dialog>
      {openDialogInV && (
        <DialogViewAPInvoice open={openDialogInV} onClose={() => setOpenDialogInV(false)} id={`${Id}`} />
      )}
    </>
  );
}

export default DialogViewPaymentAP;
