/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Grid, Checkbox, Divider, Typography, Icon, IconButton, Select, MenuItem, InputLabel } from "@material-ui/core";
import { TextFieldInForm, NumberFormatInForm } from "components/Form";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import {
  getPaymentTypeDetail,
  createPaymentTypeDetail,
  updatePaymentTypeDetail,
  delPaymentTypeDetail,
  createGblFileFromBankDetail,
  updateGblFileFromBankDetail,
  delGblFileFromBankDetail,
} from "services/setting";
import { getSettingFileFromBank } from "services/reconcile";
import { AccModuleOptions } from "utils/options";
import SnackbarUtils from "utils/SnackbarUtils";
import Model from "models/paymentType";
import ModelBank from "models/gblFileFromBank";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogItem = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { children, id, mode, setMode, open, onClose } = props;
  const [isBtnLoading, setBtnLoading] = React.useState(false);
  const [data, setData] = useStateWithCallbackLazy(Model);
  const methods = useForm({ defaultValues: data });

  const { handleSubmit, reset, control } = methods;

  const fetchDetailById = useCallback(async () => {
    if (id && id !== 0) {
      const response = await getPaymentTypeDetail(id);
      if (response) {
        setData(response);
        reset(response);
      }
      const { Data } = await getSettingFileFromBank(response.Code);
      if (Data) {
        setData({ ...response, ...Data[0] });
        reset({ ...response, ...Data[0] });
      } else {
        ModelBank.FileId = id;
        ModelBank.AppyCode = response.Code;
        setData({ ...response, ...ModelBank });
        reset({ ...response, ...ModelBank });
      }
    } else {
      setData(Model);
      reset(Model);
    }

    setBtnLoading(false);
  }, [id, reset]);

  useEffect(() => {
    fetchDetailById();
  }, [fetchDetailById]);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    //Adjust parameter before save
    setData(
      (state) => ({
        ...state,
        ...values,
        UserModified: Model.UserModified,
      }),
      (nextState) => Save(nextState)
    );
  };

  const Save = async (values) => {
    values.Id = id;
    values.FileId = data.FileId;
    values.AppyCode = values.Code;
    if (mode === "edit") {
      //Update
      const { Code, InternalMessage, UserMessage } = await updatePaymentTypeDetail(values);
      if (Code === 0) {
        if (values.FileId) {
          await updateGblFileFromBankDetail(values).then(() => {
            SnackbarUtils.success(UserMessage, function () {
              handleClose(id);
            });
            setBtnLoading(false);
          });
        } else {
          await createGblFileFromBankDetail(values).then(() => {
            SnackbarUtils.success(UserMessage, function () {
              handleClose(id);
            });
            setBtnLoading(false);
          });
        }
      } else {
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    } else {
      const { Code, InternalMessage, UserMessage } = await createPaymentTypeDetail(values);
      if (Code === 0) {
        await createGblFileFromBankDetail(values).then(() => {
          SnackbarUtils.success(UserMessage, function () {
            handleClose(parseInt(InternalMessage));
          });
          setBtnLoading(false);
        });
      } else {
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    }
    setBtnLoading(false);
  };

  const handleClose = (value) => {
    onClose(value);
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
      disabled: mode !== "view",
    },
    {
      name: "Delete",
      fnc: () => DelOrVoid(id),
      disabled: mode !== "view",
    },
  ];

  const DelOrVoid = async (id) => {
    let msg = translate("ra.question.confirmDel");
    SnackbarUtils.delConfirm(msg, async function () {
      const { Code, UserMessage } = await delPaymentTypeDetail(id);
      if (Code === 0) {
        if (data.FileId) {
          await delGblFileFromBankDetail(data.FileId).then(() => {
            SnackbarUtils.success(UserMessage, function () {
              handleClose(id);
            });
          });
        } else {
          SnackbarUtils.success(UserMessage, function () {
            handleClose(id);
          });
        }
      }
    });
  };

  const CancelFnc = () => {
    if (id === 0) {
      onClose();
      return;
    }
    fetchDetailById();
    setMode("view");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event);
          }
        }}
        scroll={"paper"}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title" onClose={onClose}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <ActionMenu menuControl={menuControlProp} justifyContent="flex-start" />
          <Divider />
          <form onKeyDown={disableFormEnter}>
            <div className={classes.root}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={6} elevation={2}>
                  <TextFieldInForm
                    label="* Code"
                    name="Code"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    disabled={mode !== "add"}
                    rule={{
                      required: {
                        value: true,
                        message: "* Required",
                      },
                      maxLength: {
                        value: 10,
                        message: "maximum length is 10",
                      },
                    }}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} elevation={2}>
                  <TextFieldInForm
                    label="Description"
                    name="Desc"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    disabled={mode === "view"}
                    rule={{
                      maxLength: {
                        value: 50,
                        message: "maximum length is 50",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} elevation={2}>
                  <FormLabel component="legend">Available in modules</FormLabel>
                  {AccModuleOptions.map((item, idx) => {
                    if (item.value === "UseInAp" || item.value === "UseInAr") {
                      return (
                        <FormControl component="fieldset" key={idx}>
                          <Controller
                            name={item.value}
                            control={control}
                            defaultValue=""
                            render={(props) => {
                              return (
                                <FormControlLabel
                                  value={props.value}
                                  control={
                                    <Checkbox
                                      checked={typeof props.value === "boolean" ? props.value : false}
                                      onChange={(e, newValue) => props.onChange(newValue)}
                                      disabled={mode === "view"}
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            }}
                          />
                        </FormControl>
                      );
                    } else {
                      return "";
                    }
                  })}
                </Grid>

                <Grid item xs={12} elevation={2} style={{ marginTop: 4 }}>
                  <Accordion style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}>
                    <AccordionSummary
                      expandIcon={<Icon>expand_more</Icon>}
                      aria-controls="import-content"
                      id="import-header"
                    >
                      <Typography>Import Setting</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: 0 }}>
                      <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                        <Grid item xs={6} elevation={2}>
                          <TextFieldInForm
                            label="DateFormat"
                            name="DateFormat"
                            methods={methods}
                            disabled={mode === "view"}
                          />
                        </Grid>
                        <Grid item xs={6} elevation={2}>
                          <Controller
                            name="Language"
                            control={control}
                            defaultValue=""
                            render={(props) => {
                              return (
                                <FormControl variant="outlined" fullWidth>
                                  <InputLabel
                                    id="caption"
                                    style={props.value === "" ? { marginTop: -10 } : { marginTop: -4 }}
                                  >
                                    Language Of Date
                                  </InputLabel>
                                  <Select
                                    variant="outlined"
                                    margin="dense"
                                    labelId="caption"
                                    label="Language Of Date"
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                    style={{ marginTop: -4 }}
                                    disabled={mode === "view"}
                                  >
                                    <MenuItem key={"en-US"} value={"en-US"}>
                                      English
                                    </MenuItem>
                                    <MenuItem key={"th-TH"} value={"th-TH"}>
                                      Thai
                                    </MenuItem>
                                    <MenuItem key={"vi-VN"} value={"vi-VN"}>
                                      Vietnam
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} elevation={2}>
                          <NumberFormatInForm
                            label="Column Of CheqNo"
                            name="ColumnOfCheqNo"
                            methods={methods}
                            decimal={0}
                            disabled={mode === "view"}
                          />
                        </Grid>
                        <Grid item xs={4} elevation={2}>
                          <NumberFormatInForm
                            label="Column Of Clearing Date"
                            name="ColumnOfClearingDate"
                            methods={methods}
                            decimal={0}
                            disabled={mode === "view"}
                          />
                        </Grid>
                        <Grid item xs={4} elevation={2}>
                          <NumberFormatInForm
                            label="Column Of Clearing Amount"
                            name="ColumnOfClearingAmount"
                            methods={methods}
                            decimal={0}
                            disabled={mode === "view"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ margin: 16 }}>
                            <b>DateFormat</b>
                          </div>
                          <ul>
                            <li>
                              <p>
                                d = Represents the day of the month as a number from 1 through 31.
                                <br />
                                dd = Represents the day of the month as a number from 01 through 31.
                                <br />
                                ddd = Represents the abbreviated name of the day (e.g. Mon, Tues, Wed, etc).
                                <br />
                                dddd = Represents the full name of the day (e.g. Monday, Tuesday, etc).
                                <br />
                              </p>
                            </li>
                            <li>
                              <p>
                                M = Month number (e.g. 3).
                                <br />
                                MM = Month number with leading zero (e.g. 04)
                                <br />
                                MMM = Abbreviated Month Name (e.g. Dec)
                                <br />
                                MMMM = Full month name (e.g. December)
                                <br />
                              </p>
                            </li>
                            <li>
                              <p>
                                y = Year, no leading zero (e.g. 2015 would be 15)
                                <br />
                                yy = Year, leading zero (e.g. 2015 would be 015)
                                <br />
                                yyyy = Year, (e.g. 2015)
                                <br />
                              </p>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </div>
            {children}
          </form>

          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
        </DialogContent>
        {mode !== "view" ? (
          <DialogActions>
            <ButtonFooter noBorder disabled={isBtnLoading} SaveFnc={handleSubmit(onSubmit)} CancelFnc={CancelFnc} />
          </DialogActions>
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
};

export default DialogItem;
