import React, { useContext } from "react";
import { useTranslate } from "react-admin";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Box, Typography, TextField } from "@material-ui/core";
import NumberFormatInput from "components/NumberFormatInput";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 0.5),
    marginTop: 50,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  divIcon: {
    padding: theme.spacing(0, 0.5),
  },
  avtCenter: {
    margin: "0 auto",
    padding: theme.spacing(0.6),
    cursor: "pointer",
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 14,
  },
  content: {
    padding: 5,
  },
  contentRight: {
    textAlign: "right",
    marginRight: 10,
  },
}));

const RightSideDetail = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { DateToString } = useContext(GblContext);
  const { open, onClose, dataDetail } = props;

  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      <Box p={1}>
        <Typography variant="h6" className={classes.content}>
          {translate("ra.message.details")}
        </Typography>
        <TextField
          label={translate("ra.field.Seq")}
          variant="outlined"
          margin="dense"
          disabled
          inputProps={{ style: { textAlign: "right" } }}
          value={dataDetail?.PfmSeq}
        />
      </Box>
      <Box p={1}>
        <TextField
          label={translate("ra.field.Invoice Date")}
          variant="outlined"
          margin="dense"
          disabled
          inputProps={{ style: { textAlign: "right" } }}
          value={DateToString(dataDetail?.InvDate)}
        />
        <TextField
          label={translate("ra.field.Invoice No.")}
          variant="outlined"
          margin="dense"
          disabled
          style={{ marginLeft: 6 }}
          inputProps={{ style: { textAlign: "right" } }}
          value={dataDetail?.InvNo}
        />
      </Box>
      <Box p={1}>
        <TextField
          label={translate("ra.field.Amount")}
          variant="outlined"
          margin="dense"
          disabled
          InputProps={{
            inputComponent: NumberFormatInput,
          }}
          inputProps={{
            style: { textAlign: "right" },
            maxLength: 18,
            decimal: 2,
          }}
          value={dataDetail?.Amount}
        />
        <TextField
          label={translate("ra.field.Unpaid")}
          variant="outlined"
          margin="dense"
          disabled
          style={{ marginLeft: 6 }}
          InputProps={{
            inputComponent: NumberFormatInput,
          }}
          inputProps={{
            style: { textAlign: "right" },
            maxLength: 18,
            decimal: 2,
          }}
          value={dataDetail?.Unpaid}
        />
      </Box>
      <Box p={1}>
        <TextField
          label={translate("ra.field.Description")}
          variant="outlined"
          margin="dense"
          disabled
          //inputProps={{ style: { textAlign: "right" } }}
          value={dataDetail?.Description}
          fullWidth
        />
      </Box>
      <Box p={1}>
        <TextField
          label={translate("ra.field.Remark")}
          variant="outlined"
          margin="dense"
          disabled
          //inputProps={{ style: { textAlign: "right" } }}
          value={dataDetail?.Remark}
          fullWidth
        />
      </Box>
      {/* <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(dataDetail, 0, 2) : ""}</pre> */}
    </Drawer>
  );
};

export default RightSideDetail;
