/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { Loading, useTranslate } from "react-admin";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import LaunchIcon from "@material-ui/icons/Launch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Paper, Typography, Box, Divider, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import DatePickerFormat from "components/DatePickerFormat";
import { PeriodListWithYear } from "utils/options";
import { permissionName } from "utils/constants";
import { addDays, addMonths, startOfMonth, endOfMonth } from "date-fns";
import {
  applyAmortizeJv,
  applyStandardJv,
  closePeriodGl,
  postAr,
  postAp,
  postInventory,
  postExtraCost,
  postAssetDepre,
  postAssetDisposal,
  postAsset,
  getSettingInterfaceBlueLedgers,
} from "services/glProcedure";
import DialogPMS from "./Dialog/DialogPMS";
import ErrorListDialog from "components/ErrorList";
import ErrorTableDialog from "components/ErrorTable";
import { getSettingInfPost } from "services/interface";
import { getLicenseList, getSettingAll } from "services/setting";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    margin: theme.spacing(2, 0, 2),
  },
  title2: {
    fontSize: 14,
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),

    maxWidth: 500,
  },
  menuPaper: {
    maxHeight: 200,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  lucnchIconStyle: {
    marginLeft: 8,
    verticalAlign: "middle",
    cursor: "pointer",
  },
}));

const List = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { permissions } = props;
  const { settingAll, UpdateSettingAll, DateToString, ToMySqlDate } = useContext(GblContext);
  const { SettingClosePeriod } = settingAll;
  const { ClosePeriodGl } = SettingClosePeriod;
  const timer = React.useRef();
  const periodList = PeriodListWithYear(addDays(new Date(ClosePeriodGl), 1), 0, 4);
  const [loading, setLoading] = useState({
    page: true,
    applyAmortize: false,
    apply: false,
    closedPeriod: false,
  });
  const [openDialog, setOpenDialog] = useState();

  const [dPeriod, setDPeriod] = useState({
    last: new Date(ClosePeriodGl),
    current: endOfMonth(addMonths(new Date(ClosePeriodGl), 1)),
  });
  const [applyAmortizeDate, setApplyAmortizeDate] = useState(periodList[0]);
  const [applyDate, setApplyDate] = useState(periodList[0]);
  const [errorList, setErrorList] = useState(false);
  const [errorTable, setErrorTable] = useState(false);
  const [showErrorList, setShowErrorList] = useState(false);
  const [showErrorTable, setShowErrorTable] = useState(false);
  const [filterDate, setFilterDate] = useState({
    from: startOfMonth(addDays(new Date(ClosePeriodGl), 1)),
    to: endOfMonth(addDays(new Date(ClosePeriodGl), 1)),
  });
  const [interfacePermissionList, setInterfacePermissionList] = useState();
  const [settingInterfaceBlue, setSettingInterfaceBlue] = useState();
  const [settingInf, setSettingInf] = useState([]);

  const getLicenseInterface = useCallback(async () => {
    const { InterfacePermissionItems } = await getLicenseList();
    const newArr = InterfacePermissionItems.filter((item) => item.CanUse);
    const keyIdArr = newArr.map((item) => item.UniqueId.Id);

    keyIdArr.forEach((item) => {
      setLoading((state) => ({
        ...state,
        [item]: false,
      }));
    });

    const noHeaderCollapse = newArr.filter(
      (item) =>
        item.UniqueId.LicenseType === "INC" || item.UniqueId.LicenseType === "AP" || item.UniqueId.LicenseType === "AR"
    );

    const Asset = newArr.filter((item) => item.UniqueId.LicenseType === "Asset");
    const Inventory = newArr.filter((item) => item.UniqueId.LicenseType === "Inventory");

    const settingInfs = await getSettingInfPost("GL");
    if (settingInfs) {
      const PMS = settingInfs.filter((item) => item.InterfaceType === "PMS");
      const keyPMSArr = PMS.map((item) => item.Code);
      const POS = settingInfs.filter((item) => item.InterfaceType === "POS");
      const keyPOSArr = POS.map((item) => item.Code);
      const HRM = settingInfs.filter((item) => item.InterfaceType === "HRM");
      const keyHRMArr = HRM.map((item) => item.Code);
      const Oinventory = settingInfs.filter(
        (item) =>
          item.InterfaceType === "Inventory" &&
          item.InterfaceName.toLowerCase() !== "blueledger" &&
          item.InterfaceName.toLowerCase() !== "blueledgers"
      );
      const keyinventoryArr = Oinventory.map((item) => item.Code);

      if (Inventory.length > 0) {
        const response = await getSettingInterfaceBlueLedgers();
        setSettingInterfaceBlue(response);
      }
      if (PMS.length > 0) {
        PMS.forEach((element) => {
          if (element.IsActive) {
            PMS.HasChildren = true;
          }
        });
      }
      if (POS.length > 0) {
        POS.forEach((element) => {
          if (element.IsActive) {
            POS.HasChildren = true;
          }
        });
      }
      if (HRM.length > 0) {
        HRM.forEach((element) => {
          if (element.IsActive) {
            HRM.HasChildren = true;
          }
        });
      }
      if (Oinventory.length > 0) {
        Oinventory.forEach((element) => {
          if (element.IsActive) {
            Oinventory.HasChildren = true;
          }
        });
      }

      setSettingInf(settingInfs);
      setInterfacePermissionList((state) => ({
        ...state,
        noHeaderCollapse: noHeaderCollapse,
        Asset: Asset,
        Inventory: Inventory,
        OtherInventory: Oinventory,
        PMS: [],
        POS: [],
        HRM: [],
      }));

      keyPMSArr.forEach((item) => {
        setOpenDialog((state) => ({
          ...state,
          [item]: false,
        }));
      });
      keyPOSArr.forEach((item) => {
        setOpenDialog((state) => ({
          ...state,
          [item]: false,
        }));
      });
      keyHRMArr.forEach((item) => {
        setOpenDialog((state) => ({
          ...state,
          [item]: false,
        }));
      });
      keyinventoryArr.forEach((item) => {
        setOpenDialog((state) => ({
          ...state,
          [item]: false,
        }));
      });
      setInterfacePermissionList((state) => ({
        ...state,
        noHeaderCollapse: noHeaderCollapse,
        Asset: Asset,
        Inventory: Inventory,
        OtherInventory: Oinventory,
        PMS: PMS,
        POS: POS,
        HRM: HRM,
      }));

      setLoading((state) => ({
        ...state,
        page: false,
      }));
    } else {
      setInterfacePermissionList((state) => ({
        ...state,
        noHeaderCollapse: noHeaderCollapse,
        Asset: Asset,
        Inventory: Inventory,
        OtherInventory: [],
        PMS: [],
        POS: [],
        HRM: [],
      }));

      setLoading((state) => ({
        ...state,
        page: false,
      }));
    }
  }, []);

  useEffect(() => {
    getLicenseInterface();
    return () => {
      clearTimeout(timer.current);
    };
  }, [getLicenseInterface]);

  useEffect(() => {
    const currentGl = endOfMonth(addMonths(new Date(ClosePeriodGl), 1));
    setDPeriod((state) => ({
      ...state,
      last: new Date(ClosePeriodGl),
      current: currentGl,
    }));
  }, [ClosePeriodGl]);

  const handleClickApplyAmortize = async () => {
    if (!loading.applyAmortize) {
      if (!permissions.find((i) => i.Name === permissionName["GL.Procedure.ApplyAmortizeVoucher"])?.Update) {
        SnackbarUtils.error(translate("ra.permission.denied"));
        return;
      }
      setLoading((state) => ({
        ...state,
        applyAmortize: true,
      }));
      const splitDate = applyAmortizeDate.split("/");
      const atDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
      const { Code, UserMessage } = await applyAmortizeJv(atDate);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }

      timer.current = window.setTimeout(() => {
        setLoading((state) => ({
          ...state,
          applyAmortize: false,
        }));
      }, 1000);
    }
  };

  const handleClickApplyStd = async () => {
    if (!loading.apply) {
      if (!permissions.find((i) => i.Name === permissionName["GL.Procedure.ApplyStandardVoucher"])?.Update) {
        SnackbarUtils.error(translate("ra.permission.denied"));
        return;
      }
      setLoading((state) => ({
        ...state,
        apply: true,
      }));
      const splitDate = applyDate.split("/");
      const atDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
      const { Code, UserMessage } = await applyStandardJv(atDate);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
      timer.current = window.setTimeout(() => {
        setLoading((state) => ({
          ...state,
          apply: false,
        }));
      }, 1000);
    }
  };

  const handleClickClosePeriod = async () => {
    if (!permissions.find((i) => i.Name === permissionName["GL.Procedure.PeriodEnd"])?.Update) {
      SnackbarUtils.error(translate("ra.permission.denied"));
      return;
    }
    const currentGl = addMonths(new Date(ClosePeriodGl), 1);
    const msg = `Do you want to close period ${DateToString(endOfMonth(currentGl))} ?`;
    SnackbarUtils.confirm(msg, async function () {
      if (!loading.closedPeriod) {
        setLoading((state) => ({
          ...state,
          closedPeriod: true,
        }));

        const { Code, InternalMessage, UserMessage, ErrorList } = await closePeriodGl();
        if (Code >= 0) {
          SnackbarUtils.success(UserMessage);
          const setting = await getSettingAll();
          localStorage.setItem("SettingAll", JSON.stringify(setting));
          UpdateSettingAll(setting);

          const currentGl = addDays(new Date(setting.SettingClosePeriod.ClosePeriodGl), 1);

          setDPeriod((state) => ({
            ...state,
            last: new Date(setting.SettingClosePeriod.ClosePeriodGl),
            current: currentGl,
          }));

          setFilterDate((state) => ({
            ...state,
            from: currentGl,
            to: endOfMonth(currentGl),
          }));
        } else {
          SnackbarUtils.warning(InternalMessage);
          if (ErrorList) {
            setErrorList(ErrorList);
            setShowErrorList(true);
          }
        }
        timer.current = window.setTimeout(() => {
          setLoading((state) => ({
            ...state,
            closedPeriod: false,
          }));
        }, 1000);
      }
    });
  };

  //NEW CheckAlreadyPost
  const CheckResultShowMessage = async ({ Code, InternalMessage, UserMessage, ErrorList }) => {
    if (Code === null) {
      SnackbarUtils.warning("File Not Found");
    }
    switch (Code) {
      case 0:
        SnackbarUtils.success(UserMessage);
        if (ErrorList.length > 0) {
          setErrorTable(ErrorList);
          setShowErrorTable(true);
        }
        break;
      case -1:
        SnackbarUtils.warning(InternalMessage);
        if (ErrorList.length > 0) {
          setErrorTable(ErrorList);
          setShowErrorTable(true);
        }
        break;
      case -204:
        SnackbarUtils.warning(UserMessage);
        break;
      case -1100:
        SnackbarUtils.warning(UserMessage);
        break;
      default:
        break;
    }
    // if (Code === 0) {
    //   SnackbarUtils.success(UserMessage);
    //   if (ErrorList.length > 0) {
    //     setErrorTable(ErrorList);
    //     setShowErrorTable(true);
    //   }
    // }
    // if (Code === -1) {
    //   SnackbarUtils.warning(InternalMessage);
    //   if (ErrorList.length > 0) {
    //     setErrorTable(ErrorList);
    //     setShowErrorTable(true);
    //   }
    // }
    // if (Code === -204) {
    //   SnackbarUtils.warning(UserMessage);
    // }
  };

  //OLD
  const CheckPostResultShowMessage = async (postResult) => {
    switch (postResult?.Code) {
      case 0:
        SnackbarUtils.success(postResult?.UserMessage);
        break;
      case -1:
        SnackbarUtils.warning(postResult?.InternalMessage);
        break;
      case -204:
        SnackbarUtils.warning(postResult?.UserMessage);
        break;
      case -1100:
        SnackbarUtils.warning(postResult?.UserMessage);
        break;
      default:
        break;
    }
  };

  const handleClickPost = async (name) => {
    setLoading((state) => ({
      ...state,
      [name]: true,
    }));
    const fromDate = ToMySqlDate(filterDate.from);
    const toDate = ToMySqlDate(filterDate.to);
    switch (name) {
      case "INC":
        alert("Not Implement");
        setLoading((state) => ({
          ...state,
          [name]: false,
        }));
        break;
      case "AR": {
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.AR"])?.Update) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          setLoading((state) => ({
            ...state,
            [name]: false,
          }));
          return;
        }
        const r = await postAr(fromDate, toDate);
        CheckPostResultShowMessage(r);
        break;
      }
      case "AP": {
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.AP"])?.Update) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          setLoading((state) => ({
            ...state,
            [name]: false,
          }));
          return;
        }
        const r = await postAp(fromDate, toDate);
        CheckPostResultShowMessage(r);
        break;
      }
      case "AST_Depre": {
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.Asset"])?.Update) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          setLoading((state) => ({
            ...state,
            [name]: false,
          }));
          return;
        }
        const r = await postAssetDepre(fromDate, toDate);
        CheckPostResultShowMessage(r);
        break;
      }
      case "AST_Dis": {
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.Asset"])?.Update) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          setLoading((state) => ({
            ...state,
            [name]: false,
          }));
          return;
        }
        const r = await postAssetDisposal(fromDate, toDate);
        CheckPostResultShowMessage(r);
        break;
      }
      case "Asset": {
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.Asset"])?.Update) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          setLoading((state) => ({
            ...state,
            [name]: false,
          }));
          return;
        }
        const r = await postAsset(fromDate, toDate, false);
        if (r.Code === -200) {
          const msg = "Data already post. Do you want to repost ?";
          SnackbarUtils.loadingConfirm(
            msg,
            async function () {
              const rr = await postAsset(fromDate, toDate, true);
              CheckResultShowMessage(rr);
            },
            null
          );
        }
        CheckResultShowMessage(r);
        break;
      }
      case "ExtraCost": {
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.Invent"])?.Update) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          setLoading((state) => ({
            ...state,
            [name]: false,
          }));
          return;
        }
        const deptCode = settingInterfaceBlue.ConfigExtraCost.DeptCode;
        const accCode = settingInterfaceBlue.ConfigExtraCost.AccCode;
        const param = {
          FromDate: fromDate,
          ToDate: toDate,
          DeptCode: deptCode,
          AccCode: accCode,
        };
        const r = await postExtraCost(param, false);
        if (r.Code === -200) {
          const msg = "Data already post. Do you want to repost ?";
          SnackbarUtils.loadingConfirm(
            msg,
            async function () {
              const rr = await postExtraCost(param, true);
              CheckResultShowMessage(rr);
            },
            null
          );
        }
        CheckResultShowMessage(r);
        break;
      }
      case "INV": {
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.Invent"])?.Update) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          setLoading((state) => ({
            ...state,
            [name]: false,
          }));
          return;
        }
        const param = {
          FromDate: fromDate,
          ToDate: toDate,
        };

        const r = await postInventory(param, false);
        if (r.Code === -200) {
          const msg = "Data already post. Do you want to repost ?";
          SnackbarUtils.loadingConfirm(
            msg,
            async function () {
              const rr = await postInventory(param, true);
              CheckResultShowMessage(rr);
            },
            null
          );
        }
        CheckResultShowMessage(r);
        break;
      }
      default:
        break;
    }

    timer.current = window.setTimeout(() => {
      setLoading((state) => ({
        ...state,
        [name]: false,
      }));
    }, 1000);
  };

  const handleClickOpenDialog = (item) => {
    switch (item.InterfaceType) {
      case "PMS":
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.PMS"])?.View) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          return;
        } else {
          setOpenDialog((state) => ({
            ...state,
            [`${item.Code}`]: true,
          }));
        }
        break;
      case "POS":
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.POS"])?.View) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          return;
        } else {
          setOpenDialog((state) => ({
            ...state,
            [`${item.Code}`]: true,
          }));
        }
        break;
      case "HRM":
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.HRM"])?.View) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          return;
        } else {
          setOpenDialog((state) => ({
            ...state,
            [`${item.Code}`]: true,
          }));
        }
        break;
      case "Inventory":
        setOpenDialog((state) => ({
          ...state,
          [`${item.Code}`]: true,
        }));
        break;
      default:
        break;
    }
  };

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {translate(`ra.actionMenu.${text}`)}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  if (loading.page) return <Loading />;

  return (
    <div className={classes.root}>
      <Paper
        elevation={3}
        style={{
          paddingTop: 5,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
        }}
      >
        <Typography variant="h6" className={classes.title}>
          {translate("ra.module.Procedure")}
        </Typography>
        <Box p={1} display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <Typography variant="subtitle1">{translate("ra.module.Apply Amortization Template")}</Typography>
          </Box>
          <Box p={1} style={{ width: 180 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="simple-select-outlined-label">{translate("ra.field.Period")}</InputLabel>
              <Select
                labelId="simple-select-filled-label"
                variant="outlined"
                margin="dense"
                label={translate("ra.field.Period")}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                defaultValue={periodList?.[0]}
                value={applyAmortizeDate}
                onChange={(e) => {
                  setApplyAmortizeDate(e.target.value);
                }}
              >
                {periodList?.map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box p={1}>
            <LoadingButton text="APPLY" disabled={loading.applyAmortize} fnc={handleClickApplyAmortize} />
          </Box>
        </Box>
        <Divider />
        <Box p={1} display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <Typography variant="subtitle1">{translate("ra.module.Apply Recurring Template")}</Typography>
            {/* <Typography variant="caption">Apply Recurring</Typography> */}
          </Box>
          <Box p={1} style={{ width: 180 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="simple-select-outlined-label">{translate("ra.field.Period")}</InputLabel>
              <Select
                labelId="simple-select-filled-label"
                variant="outlined"
                margin="dense"
                label={translate("ra.field.Period")}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                defaultValue={periodList?.[0]}
                value={applyDate}
                onChange={(e) => {
                  setApplyDate(e.target.value);
                }}
              >
                {periodList?.map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box p={1}>
            <LoadingButton text="APPLY" disabled={loading.apply} fnc={handleClickApplyStd} />
          </Box>
        </Box>
        <Divider />
        {interfacePermissionList?.["noHeaderCollapse"].map((item, idx) => (
          <div key={idx}>
            <Box p={1} display="flex" alignItems="center">
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle1">
                  {translate("ra.module.Posting from") + translate(`ra.module.${item.UniqueId.Description}`)}
                </Typography>
                {/* <Typography variant="caption">Posting from Account Receivable</Typography> */}
              </Box>
              <Box p={1}>
                <DatePickerFormat
                  label={translate("ra.field.From")}
                  value={filterDate.from}
                  onChange={(e) => {
                    setFilterDate((state) => ({
                      ...state,
                      from: e,
                      to: e > filterDate.to ? e : filterDate.to,
                    }));
                  }}
                  style={{ width: 160, margin: "0 10px" }}
                />
                <DatePickerFormat
                  label={translate("ra.field.To")}
                  value={filterDate.to}
                  onChange={(e) => {
                    setFilterDate((state) => ({
                      ...state,
                      to: e,
                    }));
                  }}
                  minDate={filterDate.from}
                  minDateMessage={"Date must be more than from date"}
                  style={{ width: 160, margin: "0 10px" }}
                />
              </Box>
              <Divider />
              <Box p={1}>
                <LoadingButton
                  text="POST"
                  disabled={loading[item.UniqueId.Id]}
                  fnc={() => handleClickPost(item.UniqueId.Id)}
                />
              </Box>
            </Box>
            <Divider />
          </div>
        ))}
        {interfacePermissionList && interfacePermissionList["Inventory"].length > 0 && (
          <>
            <Box p={1} display="flex" alignItems="center">
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle1">
                  {`${translate("ra.module.Posting from")}${translate("ra.module.Receiving")} (Extra cost)`}
                </Typography>
                {/* <Typography variant="caption">Posting from Receiving (Extra cost)</Typography> */}
              </Box>
              <Box p={1}>
                <DatePickerFormat
                  label={translate("ra.field.From")}
                  value={filterDate.from}
                  onChange={(e) => {
                    setFilterDate((state) => ({
                      ...state,
                      from: e,
                      to: e > filterDate.to ? e : filterDate.to,
                    }));
                  }}
                  style={{ width: 160, margin: "0 10px" }}
                />
                <DatePickerFormat
                  label={translate("ra.field.To")}
                  value={filterDate.to}
                  onChange={(e) => {
                    setFilterDate((state) => ({
                      ...state,
                      to: e,
                    }));
                  }}
                  minDate={filterDate.from}
                  minDateMessage={"Date must be more than from date"}
                  style={{ width: 160, margin: "0 10px" }}
                />
              </Box>
              <Divider />
              <Box p={1}>
                <LoadingButton text="POST" disabled={loading.extraCost} fnc={() => handleClickPost("ExtraCost")} />
              </Box>
            </Box>
            <Divider />
            <Box p={1} display="flex" alignItems="center">
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle1">
                  {translate("ra.module.Posting from") + translate("ra.module.Inventory")}
                </Typography>
                {/* <Typography variant="caption">Posting from Inventory</Typography> */}
              </Box>
              <Box p={1}>
                <DatePickerFormat
                  label={translate("ra.field.From")}
                  value={filterDate.from}
                  onChange={(e) => {
                    setFilterDate((state) => ({
                      ...state,
                      from: e,
                      to: e > filterDate.to ? e : filterDate.to,
                    }));
                  }}
                  style={{ width: 160, margin: "0 10px" }}
                />
                <DatePickerFormat
                  label={translate("ra.field.To")}
                  value={filterDate.to}
                  onChange={(e) => {
                    setFilterDate((state) => ({
                      ...state,
                      to: e,
                    }));
                  }}
                  minDate={filterDate.from}
                  minDateMessage={"Date must be more than from date"}
                  style={{ width: 160, margin: "0 10px" }}
                />
              </Box>
              <Divider />
              <Box p={1}>
                <LoadingButton text="POST" disabled={loading.inv} fnc={() => handleClickPost("INV")} />
              </Box>
            </Box>
            <Divider />
          </>
        )}
        {interfacePermissionList && interfacePermissionList["Asset"].length > 0 && (
          <>
            <Box p={1} display="flex" alignItems="center">
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle1">
                  {translate("ra.module.Posting from") + translate("ra.module.Asset")}
                </Typography>
                {/* <Typography variant="caption">Posting from Asset Disposal</Typography> */}
              </Box>
              <Box p={1}>
                <DatePickerFormat
                  label={translate("ra.field.From")}
                  value={filterDate.from}
                  onChange={(e) => {
                    setFilterDate((state) => ({
                      ...state,
                      from: e,
                      to: e > filterDate.to ? e : filterDate.to,
                    }));
                  }}
                  style={{ width: 160, margin: "0 10px" }}
                />
                <DatePickerFormat
                  label={translate("ra.field.To")}
                  value={filterDate.to}
                  onChange={(e) => {
                    setFilterDate((state) => ({
                      ...state,
                      to: e,
                    }));
                  }}
                  minDate={filterDate.from}
                  minDateMessage={"Date must be more than from date"}
                  style={{ width: 160, margin: "0 10px" }}
                />
              </Box>
              <Divider />
              <Box p={1}>
                <LoadingButton text="POST" disabled={loading.Asset} fnc={() => handleClickPost("Asset")} />
              </Box>
            </Box>
            <Divider />
          </>
        )}
        {interfacePermissionList &&
          interfacePermissionList["OtherInventory"].length > 0 &&
          interfacePermissionList["OtherInventory"].HasChildren && (
            <>
              {interfacePermissionList["OtherInventory"].map(
                (item, idx) =>
                  item.IsActive && (
                    <div key={idx}>
                      <Box p={1} display="flex" alignItems="center">
                        <Box flexGrow={1}>
                          <Typography variant="h6" className={classes.title2}>
                            {translate("ra.module.Posting from") + item.Name}
                            <span className={classes.lucnchIconStyle}>
                              <LaunchIcon onClick={() => handleClickOpenDialog(item)} />
                            </span>
                          </Typography>
                          {/* <Typography variant="caption">Posting from Account Receivable</Typography> */}
                        </Box>
                      </Box>
                      <Divider />
                      {openDialog?.[`${item.Code}`] && (
                        <DialogPMS
                          id={item.Code}
                          title={item.Name}
                          open={openDialog[`${item.Code}`]}
                          onClose={() =>
                            setOpenDialog((state) => ({
                              ...state,
                              [`${item.Code}`]: false,
                            }))
                          }
                          setting={settingInf.find((s) => s.Code === item.Code)}
                        />
                      )}
                    </div>
                  )
              )}
            </>
          )}

        {interfacePermissionList &&
          interfacePermissionList["PMS"].length > 0 &&
          interfacePermissionList["PMS"].HasChildren && (
            <>
              {interfacePermissionList["PMS"].map(
                (item, idx) =>
                  item.IsActive && (
                    <div key={idx}>
                      <Box p={1} display="flex" alignItems="center">
                        <Box flexGrow={1}>
                          <Typography variant="h6" className={classes.title2}>
                            {translate("ra.module.Posting from") + item.Name}
                            <span className={classes.lucnchIconStyle}>
                              <LaunchIcon onClick={() => handleClickOpenDialog(item)} />
                            </span>
                          </Typography>
                          {/* <Typography variant="caption">Posting from Account Receivable</Typography> */}
                        </Box>
                      </Box>
                      <Divider />
                      {openDialog?.[`${item.Code}`] && (
                        <DialogPMS
                          id={item.Code}
                          title={item.Name}
                          open={openDialog[`${item.Code}`]}
                          onClose={() =>
                            setOpenDialog((state) => ({
                              ...state,
                              [`${item.Code}`]: false,
                            }))
                          }
                          setting={settingInf.find((s) => s.Code === item.Code)}
                        />
                      )}
                    </div>
                  )
              )}
            </>
          )}

        {interfacePermissionList &&
          interfacePermissionList["POS"].length > 0 &&
          interfacePermissionList["POS"].HasChildren && (
            <>
              {interfacePermissionList["POS"].map(
                (item, idx) =>
                  item.IsActive && (
                    <div key={idx}>
                      <Box p={1} display="flex" alignItems="center">
                        <Box flexGrow={1}>
                          <Typography variant="h6" className={classes.title2}>
                            {translate("ra.module.Posting from") + item.Name}
                            <span className={classes.lucnchIconStyle}>
                              <LaunchIcon onClick={() => handleClickOpenDialog(item)} />
                            </span>
                          </Typography>
                          {/* <Typography variant="caption">Posting from Account Receivable</Typography> */}
                        </Box>
                      </Box>
                      <Divider />
                      {openDialog?.[`${item.Code}`] && (
                        <DialogPMS
                          id={item.Code}
                          title={item.Name}
                          open={openDialog[`${item.Code}`]}
                          onClose={() =>
                            setOpenDialog((state) => ({
                              ...state,
                              [`${item.Code}`]: false,
                            }))
                          }
                          setting={settingInf.find((s) => s.Code === item.Code)}
                        />
                      )}
                    </div>
                  )
              )}
            </>
          )}

        {interfacePermissionList &&
          interfacePermissionList["HRM"].length > 0 &&
          interfacePermissionList["HRM"].HasChildren && (
            <>
              {interfacePermissionList["HRM"].map(
                (item, idx) =>
                  item.IsActive && (
                    <div key={idx}>
                      <Box p={1} display="flex" alignItems="center">
                        <Box flexGrow={1}>
                          <Typography variant="h6" className={classes.title2}>
                            {translate("ra.module.Posting from") + item.Name}
                            <span className={classes.lucnchIconStyle}>
                              <LaunchIcon onClick={() => handleClickOpenDialog(item)} />
                            </span>
                          </Typography>
                          {/* <Typography variant="caption">Posting from Account Receivable</Typography> */}
                        </Box>
                      </Box>
                      <Divider />
                      {openDialog?.[`${item.Code}`] && (
                        <DialogPMS
                          id={item.Code}
                          title={item.Name}
                          open={openDialog[`${item.Code}`]}
                          onClose={() =>
                            setOpenDialog((state) => ({
                              ...state,
                              [`${item.Code}`]: false,
                            }))
                          }
                          setting={settingInf.find((s) => s.Code === item.Code)}
                        />
                      )}
                    </div>
                  )
              )}
            </>
          )}

        <Box p={1} display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <Typography variant="h6" className={classes.title2} style={{ margin: 0 }}>
              {translate("ra.module.Close Period")}
            </Typography>
            {/* <Typography variant="caption">General Ledger Close Period</Typography> */}
          </Box>

          <Box p={1}>
            <DatePickerFormat
              label={translate("ra.field.Last Closed Period")}
              value={dPeriod.last}
              readOnly
              style={{ width: 160, margin: "0 10px" }}
            />
            <DatePickerFormat
              label={translate("ra.field.Current Period")}
              value={dPeriod.current}
              readOnly
              style={{ width: 160, margin: "0 10px" }}
            />
          </Box>
          {/* <b>Close to date &nbsp;: </b> &nbsp; {DateToString(endMonthCurrentGl)} */}

          <Box p={1} display="flex" alignItems="center">
            <LoadingButton text="CLOSE" disabled={loading.closedPeriod} fnc={handleClickClosePeriod} />
          </Box>
        </Box>
      </Paper>
      {showErrorList && (
        <ErrorListDialog open={showErrorList} onClose={() => setShowErrorList(false)} errorList={errorList} />
      )}
      {showErrorTable && (
        <ErrorTableDialog open={showErrorTable} onClose={() => setShowErrorTable(false)} errorTable={errorTable} />
      )}
    </div>
  );
};

export default List;
