/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function Check({ controlNo, name, value, text, setParameter }) {
  const [checked, setChecked] = useState(value === "true" ? true : false);

  useEffect(() => {
    setParameter(controlNo, name, value);
  }, []);

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={name}
          name={name}
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
            setParameter(controlNo, name, e.target.checked);
          }}
        />
      }
      label={text}
    />
  );
}
