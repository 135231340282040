/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Loading } from "react-admin";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Divider, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ActionMenu from "components/ActionMenu";
import BoxPMS from "./InterfaceDetail/PMS";
import BoxBL from "./InterfaceDetail/BL";
import { getLicenseList } from "services/setting";
import { getSettingInfPost } from "services/interface";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  tabPanel: { width: "100%", margin: "0 10px" },
  button: { textTransform: "none" },
}));

const Interface = (props) => {
  const classes = useStyles();
  const { children, value, index } = props;
  const [selectedIndex, setSelectedIndex] = useState();
  const [openLeftMenu, setOpenLeftMenu] = useState({
    GL: true,
    AP: false,
    AR: false,
    AS: false,
    INV: false,
  });
  const [tempRightSide, setTempRightSide] = useState();
  const [showRightSide, setShowRightSide] = useState();
  const [currentModule, setCurrentModule] = useState("GL");
  const [showBtn, setShowBtn] = useState(false);

  const handleClick = async (name, idx) => {
    const updatedModels = Object.entries(showRightSide).reduce((memo, [currKey]) => {
      if (currKey === name) {
        memo[currKey] = true;
      } else {
        memo[currKey] = false;
      }
      return memo;
    }, {});

    setShowRightSide(updatedModels);

    if (selectedIndex !== idx) {
      setShowBtn(false);
    }

    setSelectedIndex(idx);
  };

  const [invList, setInvList] = useStateWithCallbackLazy([]);
  const [glList, setGlList] = useStateWithCallbackLazy([]);
  const [apList, setApList] = useStateWithCallbackLazy([]);
  const [arList, setArList] = useStateWithCallbackLazy([]);

  const GetInterfaceByModule = async (status) => {
    const result = await getSettingInfPost(currentModule === "INV" ? "GL" : currentModule);
    if (result?.Code === -2147467259) {
      return null;
    }
    if (result) {
      if (currentModule === "GL") {
        setGlList(result);
      }
      if (currentModule === "AP") {
        setApList(result);
      }
      if (currentModule === "AR") {
        setArList(result);
      }
      if (currentModule === "INV") {
        fncSetInvList();
      }
      if (!status) {
        let rightSideObj = result
          .map((obj) => {
            return { [`${obj.Code}`]: false };
          })
          .reduce(function (result, current) {
            return Object.assign(result, current);
          }, {});

        rightSideObj.BL = false;
        // rightSideObj.push({ BL: false });
        setTempRightSide(rightSideObj);
        setShowRightSide(rightSideObj);
      }
    } else {
      let blArr = [];
      const r = await getLicenseList();
      const list = r?.InterfacePermissionItems;
      let listInventory = list.filter((i) => i.UniqueId.LicenseType === "Inventory" && i.CanUse === true);
      if (listInventory?.length > 0) {
        let newBlueledgerObj = {
          Code: "BL",
          Name: "BlueLedgers",
          Module: "GL",
          PostType: "Json",
          InterfaceType: "Inventory",
          InterfaceName: "BlueLedgers",
          DocType: "",
          Configuration: null,
        };
        blArr.push(newBlueledgerObj);
        //setLicenseList(newBlueledgerObj);
      }
      setInvList(blArr);
      //setGlList(blArr);
      if (!status) {
        let rightSideObj = blArr
          .map((obj) => {
            return { [`${obj.Code}`]: false };
          })
          .reduce(function (blArr, current) {
            return Object.assign(blArr, current);
          }, {});
        rightSideObj.BL = false;
        //rightSideObj.push({ BL: false });
        setTempRightSide(rightSideObj);
        setShowRightSide(rightSideObj);
      }
    }
  };

  React.useEffect(() => {
    if (value === index) {
      //if (glList.length === 0) {
      GetInterfaceByModule();
      //CheckProductLicense();
      //}
    }
  }, [value, currentModule]);

  const cancelAndUpdateData = (newData, status) => {
    if (status === "update") {
      GetInterfaceByModule(status);
    }
    setShowBtn(false);
  };

  const InterfaceDetail = (props) => {
    var keys = Object.keys(props);
    var filtered = keys.filter(function (key) {
      return props[key];
    });

    if (filtered[0] === "BL") {
      return (
        <BoxBL
          name={filtered[0]}
          //lookupList={lookupList}
          editMode={showBtn}
          cancelFnc={() => setShowBtn(false)}
        />
      );
    }

    if (props.currentModule === "GL") {
      return (
        <BoxPMS
          code={filtered[0]}
          currentModule={props.currentModule}
          //lookupList={lookupList}
          editMode={showBtn}
          cancelFnc={cancelAndUpdateData}
          data={glList.find((i) => i.Code === filtered[0])}
        />
      );
    } else if (props.currentModule === "AP") {
      return (
        <BoxPMS
          code={filtered[0]}
          currentModule={props.currentModule}
          //lookupList={lookupList}
          editMode={showBtn}
          cancelFnc={cancelAndUpdateData}
          data={apList.find((i) => i.Code === filtered[0])}
        />
      );
    } else {
      return (
        <BoxPMS
          code={filtered[0]}
          currentModule={props.currentModule}
          //lookupList={lookupList}
          editMode={showBtn}
          cancelFnc={cancelAndUpdateData}
          data={arList && arList?.find((i) => i.Code === filtered[0])}
        />
      );
    }
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setShowBtn(true);
      },
    },
  ];

  const fncSetInvList = async () => {
    let blArr = [];
    const r = await getLicenseList();
    const list = r?.InterfacePermissionItems;
    let listInventory = list.filter((i) => i.UniqueId.LicenseType === "Inventory" && i.CanUse === true);
    if (listInventory?.length > 0) {
      let newBlueledgerObj = {
        Code: "BL",
        Name: "BlueLedgers",
        Module: "GL",
        PostType: "Json",
        InterfaceType: "Inventory",
        InterfaceName: "BlueLedgers",
        DocType: "",
        Configuration: null,
      };
      blArr.push(newBlueledgerObj);
      //setLicenseList(newBlueledgerObj);
    }
    setInvList(blArr);
  };

  const setDataByModule = async (currentModule) => {
    if (openLeftMenu[currentModule]) {
      setShowRightSide(tempRightSide);

      setOpenLeftMenu((state) => ({
        ...state,
        [currentModule]: false,
      }));
    }

    if (!openLeftMenu[currentModule]) {
      const updatedModels = Object.entries(openLeftMenu).reduce((memo, [currKey]) => {
        if (currKey === currentModule) {
          memo[currKey] = true;
        } else {
          memo[currKey] = false;
        }
        return memo;
      }, {});

      setOpenLeftMenu(updatedModels);
      const result = await getSettingInfPost(currentModule === "INV" ? "GL" : currentModule);

      if (result?.Code === -2147467259) {
        return null;
      }
      if (result) {
        if (currentModule === "GL") {
          setGlList(result);
        }
        if (currentModule === "AP") {
          setApList(result);
        }
        if (currentModule === "AR") {
          setArList(result);
        }
        if (currentModule === "INV") {
          fncSetInvList();
        }
      } else {
        fncSetInvList();
      }
      setCurrentModule(currentModule);
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {value === index && (
        <>
          <Box p={3}>
            <Typography>
              <b>{children}</b>
            </Typography>
          </Box>
          <div style={{ marginTop: -74 }}>
            <ActionMenu menuControl={menuControlProp} />
          </div>
          <Divider />
          {glList || invList ? (
            <>
              <Grid container alignItems="flex-start" spacing={1} style={{ marginTop: 10 }}>
                <Grid item xs={12} md={4} style={{ border: "1px solid #a7a7a7", borderRadius: 8 }}>
                  {/* GL */}
                  <ListItem button onClick={() => setDataByModule("GL")}>
                    <ListItemIcon>{openLeftMenu["GL"] ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
                    <ListItemText primary="General Ledger" />
                  </ListItem>
                  <Collapse in={openLeftMenu["GL"]} timeout="auto" unmountOnExit>
                    {openLeftMenu["GL"] &&
                      glList.map((item, idx) => (
                        <div key={idx}>
                          <List component="div" disablePadding>
                            <ListItem
                              button
                              selected={selectedIndex === idx}
                              onClick={() => handleClick(item.Code, idx)}
                            >
                              <ListItemText
                                primary={
                                  <>
                                    <b>({item.InterfaceType}) </b>
                                    {item.InterfaceName}
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </div>
                      ))}
                    {/* {licenseList &&
                  licenseList.map((item, idx) => (
                    <div key={idx}>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          selected={selectedIndex === idx}
                          onClick={() => handleClick(item.UniqueId.Id, idx)}
                        >
                          <ListItemText
                            primary={
                              <>
                                <b>({item.UniqueId.LicenseType}) </b>
                                {item.UniqueId.Description}
                              </>
                            }
                          />
                        </ListItem>
                      </List>
                    </div>
                  ))} */}
                  </Collapse>
                  <Divider />
                  {/* AP */}
                  <ListItem button onClick={() => setDataByModule("AP")}>
                    <ListItemIcon>{openLeftMenu["AP"] ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
                    <ListItemText primary="Account Payable" />
                  </ListItem>
                  <Collapse in={openLeftMenu["AP"]} timeout="auto" unmountOnExit>
                    {openLeftMenu["AP"] &&
                      apList.map((item, idx) => (
                        <div key={idx}>
                          <List component="div" disablePadding>
                            <ListItem
                              button
                              selected={selectedIndex === idx}
                              onClick={() => handleClick(item.Code, idx)}
                            >
                              <ListItemText
                                primary={
                                  <>
                                    <b>({item.InterfaceType}) </b>
                                    {item.InterfaceName}
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </div>
                      ))}
                    {/* {licenseList &&
                  licenseList.map((item, idx) => (
                    <div key={idx}>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          selected={selectedIndex === idx}
                          onClick={() => handleClick(item.UniqueId.Id, idx)}
                        >
                          <ListItemText
                            primary={
                              <>
                                <b>({item.UniqueId.LicenseType}) </b>
                                {item.UniqueId.Description}
                              </>
                            }
                          />
                        </ListItem>
                      </List>
                    </div>
                  ))} */}
                  </Collapse>
                  <Divider />
                  {/* AR */}
                  <ListItem button onClick={() => setDataByModule("AR")}>
                    <ListItemIcon>{openLeftMenu["AR"] ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
                    <ListItemText primary="Account Receivable" />
                  </ListItem>
                  <Collapse in={openLeftMenu["AR"]} timeout="auto" unmountOnExit>
                    {openLeftMenu["AR"] &&
                      arList &&
                      arList.map((item, idx) => (
                        <div key={idx}>
                          <List component="div" disablePadding>
                            <ListItem
                              button
                              selected={selectedIndex === idx}
                              onClick={() => handleClick(item.Code, idx)}
                            >
                              <ListItemText
                                primary={
                                  <>
                                    <b>({item.InterfaceType}) </b>
                                    {item.InterfaceName}
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </div>
                      ))}
                  </Collapse>
                  {/* <Divider /> */}
                  {/* Inventory */}
                  {/* <ListItem button onClick={() => setDataByModule("INV")}>
                    <ListItemIcon>{openLeftMenu["INV"] ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
                    <ListItemText primary="Inventory" />
                  </ListItem>
                  <Collapse in={openLeftMenu["INV"]} timeout="auto" unmountOnExit>
                    {openLeftMenu["INV"] &&
                      invList.map((item, idx) => (
                        <div key={idx}>
                          <List component="div" disablePadding>
                            <ListItem
                              button
                              selected={selectedIndex === idx}
                              onClick={() => handleClick(item.Code, idx)}
                            >
                              <ListItemText
                                primary={
                                  <>
                                    <b>({item.InterfaceType}) </b>
                                    {item.InterfaceName}
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </div>
                      ))}
                  </Collapse> */}
                </Grid>

                <Grid item xs={12} md={8} style={{ marginTop: -4 }}>
                  <InterfaceDetail {...showRightSide} currentModule={currentModule} />
                </Grid>
              </Grid>
            </>
          ) : (
            <Loading />
          )}
        </>
      )}
    </div>
  );
};

export default Interface;
