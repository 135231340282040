import React, { useState, useEffect } from "react";
import { Loading, useLocale, useTranslate } from "react-admin";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Collapse,
  IconButton,
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ListBox from "components/ListBox";
import PopperListBox from "components/PopperListBox";
import {
  getTypeOfWidget,
  getAccCodeListWithSp,
  getDeptCodeListWithSp,
  getDimensionWithSp,
} from "services/callStoreProcedure";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DatePickerFormat from "components/DatePickerFormat";
import MultiSelectListBox from "react-multiselect-listbox";
import { PeriodWithText } from "utils/options";
import { addYears } from "date-fns/esm";

const useStyles = makeStyles((theme) => ({
  centerContent: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    //width: "fit-content",
    width: "80%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  button: {
    textTransform: "none",
    borderRadius: 4,
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
  },
  selected: {
    color: "white !important",
    backgroundColor: `${theme.palette.primary.main}!important`,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  centerContent: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ConfigDashboard = (props) => {
  const locale = useLocale();
  const classes = useStyles();
  const translate = useTranslate();
  const optionList = PeriodWithText();
  const [loading, setLoading] = useState(true);
  const { open, close, field, param, updateChart, updateParam, size } = props;

  const [tempParam, setTempParam] = useState({
    Id: param.Id,
    name: param.name,
    TypeOfDate: param.TypeOfDate,
    FromDate: param.FromDate,
    ToDate: param.ToDate,
    //prevDate: param.prevDate,
    //date: param.date,
    Seq: parseInt(param.Seq),
    Width: param.Width,
    Config: param.Config,
    configName: param.configName,
  });
  const [config, setConfig] = useState("");
  const [showCollapse, setShowCollapse] = useState([]);
  const [typeList, setTypeList] = useState([]);

  const [typeOfAccount, setTypeOfAccount] = useState();
  const onOptionChangeHandler = (e) => {
    if (config.Config) {
      if (e.target.value === "Summary") {
        config.Config.Revenue.Value = "";
        config.Config.Expense.Value = "";
        if (config.Config.AccountCode) {
          config.Config.AccountCode.IsSelect = true;
        } else {
          config.Config = {
            ...config.Config,
            AccountCode: {
              Value: "",
              Type: "Account",
              IsSelect: true,
            },
          };
        }
      } else {
        config.Config.AccountCode.Value = "";
        if (config.Config.AccountCode) {
          config.Config.AccountCode.IsSelect = false;
        } else {
          config.Config = {
            AccountCode: {
              Value: "",
              Type: "Account",
              IsSelect: false,
            },
          };
        }
      }
    }

    setTypeOfAccount(e.target.value);
    setConfig(config);
    fetchConfig(true);
  };

  function useForceUpdate() {
    const [value, setState] = useState(true);
    return () => setState(!value);
  }

  const forceUpdate = useForceUpdate();

  const [defaultValue, setDefaultValue] = useState({
    accLookup: [],
    deptLookup: [],
  });

  const fetchConfig = async (mounted) => {
    const typeOfChart = await getTypeOfWidget();
    setTypeList(typeOfChart);

    if (tempParam?.configName && tempParam.Config) {
      setConfig(tempParam);
      const config = tempParam.Config;

      const accList = await getAccCodeListWithSp();
      const deptList = await getDeptCodeListWithSp();
      let dimensionList = [];

      if (tempParam.configName === "Dimension Analysis") {
        dimensionList = await getDimensionWithSp();
      }

      if (tempParam.configName === "Budget Trend") {
        if (config?.AccountCode?.IsSelect) {
          setTypeOfAccount("Summary");
        } else {
          setTypeOfAccount("Net Off");
        }
      }

      //change lookup
      const newAccLookUp = accList?.map((el, idx) => {
        //var o = Object.assign({}, el);
        return Object.assign(
          {},
          {
            id: idx,
            value: el.AccCode,
            label: locale === "en-US" ? el.AccFullName : el.AccTFullName,
          }
        );
      });
      const newDeptLookUp = deptList.map((el, idx) => {
        return Object.assign(
          {},
          {
            id: idx,
            value: el.DeptCode,
            label: el.DeptFullName,
          }
        );
      });

      setDefaultValue((state) => ({
        ...state,
        accLookup: newAccLookUp,
        deptLookup: newDeptLookUp,
        dimLookup: dimensionList,
      }));

      if (mounted) {
        setLoading(false);
      }

      const arrKeyString = Object.keys(config);
      setShowCollapse(arrKeyString.map(() => false));

      arrKeyString.forEach((keyName, idx) => {
        let defaultValue = [];

        let arrString;
        if (!Array.isArray(config[keyName].Value)) {
          arrString = String(config[keyName].Value).split(",");
        }

        if (config[keyName].Type === "Account") {
          if (arrString && accList.length > 0) {
            arrString.forEach((item) => {
              const idx = accList.findIndex((i) => i.AccCode === String(item));
              if (idx !== -1) {
                defaultValue.push(accList[idx].AccCode);
              }
            });
          }
          setDefaultValue((state) => ({
            ...state,
            [`${idx}`]: defaultValue,
          }));
        }

        if (config[keyName].Type === "Department") {
          if (arrString && deptList.length > 0) {
            arrString.forEach((item) => {
              const idx = deptList.findIndex((i) => i.DeptCode === String(item));
              if (idx !== -1) {
                defaultValue.push(deptList[idx].DeptCode);
              }
            });
          }
          setDefaultValue((state) => ({
            ...state,
            [`${idx}`]: defaultValue,
          }));
        }

        if (config[keyName].Type === "Dimension") {
          if (arrString && dimensionList.length > 0) {
            defaultValue = arrString?.join();
          }
          setDefaultValue((state) => ({
            ...state,
            [`${idx}`]: defaultValue ?? [""],
          }));
        }

        if (config[keyName].Type === "Revision") {
          setDefaultValue((state) => ({
            ...state,
            [`${idx}`]: arrString.join(),
          }));
        }

        if (config[keyName].Type === "Table") {
          const accountList = config[keyName].Value.map((item) => {
            const accIdx = accList.findIndex((i) => i.AccCode === String(item.Account));
            if (accIdx !== -1) {
              item.arrAccValue = {
                id: accIdx,
                value: accList[accIdx].AccCode,
                label: locale === "en-US" ? accList[accIdx].AccFullName : accList[accIdx].AccTFullName,
              };
            }
            const deptIdx = deptList.findIndex((i) => i.DeptCode === String(item.Department));
            if (deptIdx !== -1) {
              item.arrDeptValue = {
                id: deptIdx,
                value: deptList[deptIdx].DeptCode,
                label: deptList[deptIdx].DeptFullName,
              };
            }
            return item;
          });
          setDefaultValue((state) => ({
            ...state,
            [`${idx}`]: accountList,
          }));
        }
      });

      forceUpdate();
    } else {
      setConfig([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchConfig(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, [tempParam?.configName]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      label: "Dept.",
      name: "arrDeptValue",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Autocomplete
              size="small"
              options={defaultValue.deptLookup}
              disableClearable
              disableListWrap
              ListboxComponent={ListBox}
              PopperComponent={PopperListBox}
              defaultValue={value}
              onChange={(e, newValue) => {
                const newArr = defaultValue["1"];
                newArr[tableMeta.rowIndex].Department = newValue.value;
                newArr[tableMeta.rowIndex].arrDeptValue = newValue;
                setDefaultValue((state) => ({
                  ...state,
                  1: newArr,
                }));
                forceUpdate();
              }}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.value === value.value}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          );
        },
      },
    },
    {
      label: "Acc.",
      name: "arrAccValue",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Autocomplete
              size="small"
              options={defaultValue.accLookup}
              disableClearable
              disableListWrap
              ListboxComponent={ListBox}
              PopperComponent={PopperListBox}
              defaultValue={value}
              onChange={(e, newValue) => {
                const newArr = defaultValue["1"];
                newArr[tableMeta.rowIndex].Account = newValue.value;
                newArr[tableMeta.rowIndex].arrAccValue = newValue;
                setDefaultValue((state) => ({
                  ...state,
                  1: newArr,
                }));
                forceUpdate();
              }}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option, value) => option.value === value.value}
              style={{ width: "100%" }}
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          );
        },
      },
    },
  ];

  const options = {
    responsive: "standard",
    selectableRows: "multiple",
    serverSide: false,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    viewColumns: false,
    download: false,
    filter: false,
    print: false,
    search: false,
    pagination: false,
    customToolbar: () => {
      return (
        <>
          <IconButton
            aria-label="settings"
            onClick={() => {
              const newArr = defaultValue["1"];
              newArr.push({
                Name: "",
                Department: "",
                Account: "",
              });
              setDefaultValue((state) => ({
                ...state,
                1: newArr,
              }));
              forceUpdate();
            }}
          >
            <Icon>add</Icon>
          </IconButton>
        </>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (let i = removeArray.length - 1; i >= 0; i--) defaultValue["1"].splice(removeArray[i], 1);

      if (defaultValue["1"].length === 0) {
        defaultValue["1"] = [{ Name: "", Department: "", Account: "" }];
      }

      setDefaultValue((state) => ({
        ...state,
        1: defaultValue["1"],
      }));
      forceUpdate();
    },
  };

  const handleChange = (selectedItems, index) => {
    setDefaultValue((state) => ({
      ...state,
      [`${index}`]: selectedItems,
    }));
    forceUpdate();
  };

  const ActualvsBudget = ({ data }) => {
    return (
      <Grid item xs={12} style={{ margin: "10px 0" }}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </Grid>
    );
  };

  const updateConfig = async () => {
    tempParam.Config = {};
    //update config
    if (tempParam.configName === "Statistical Highlight") {
      const dv0 = defaultValue[0]?.join();
      const dv1 = defaultValue[1]?.join();
      const dv2 = defaultValue[2]?.join();
      const dv3 = defaultValue[3]?.join();
      tempParam.Config = {
        "Room Occupy": { Value: dv0, Type: "Account" },
        "Room Available": { Value: dv1, Type: "Account" },
        "Room Revenue": { Value: dv2, Type: "Account" },
        "Total Revenue": { Value: dv3, Type: "Account" },
      };
    }
    if (tempParam.configName === "Dimension Analysis") {
      const dv0 = defaultValue[0]?.join();
      const dv1 = defaultValue[1]?.join();
      //change select one dimension only
      const dv2 = defaultValue[2];
      tempParam.Config = {
        Department: { Value: dv0, Type: "Department" },
        Account: { Value: dv1, Type: "Account" },
        Dimension: { Value: `${dv2}`, Type: "Dimension" },
      };
    }
    if (tempParam.configName === "Annual") {
      const dv0 = defaultValue[0]?.join();
      const dv1 = defaultValue[1]?.join();
      tempParam.Config = {
        Account: { Value: dv0, Type: "Account" },
        Department: { Value: dv1, Type: "Department" },
      };
    }
    if (tempParam.configName === "P&L Summary") {
      const dv0 = parseInt(defaultValue[0]);
      tempParam.Config = {
        Revision: { Value: dv0, Type: "Revision" },
      };
    }
    if (tempParam.configName === "Daily Monitor Account") {
      const dv0 = defaultValue[0]?.join();
      tempParam.Config = { Account: { Value: dv0, Type: "Account" } };
    }
    if (tempParam.configName === "ActualBudget") {
      //tempParam.Config = config.Config;
      const dv0 = parseInt(defaultValue[0]);
      const dv1 = defaultValue[1];
      tempParam.Config = {
        Revision: { Value: dv0, Type: "Revision" },
        AccountList: { Value: dv1, Type: "Table" },
      };
    }
    if (tempParam.configName === "Income Statement") {
      //revision
      const dv0 = parseInt(defaultValue[0]);
      const dv1 = defaultValue[1]?.join();
      const dv2 = defaultValue[2]?.join();
      const dv3 = defaultValue[3]?.join();
      const dv4 = defaultValue[4]?.join();
      const dv5 = defaultValue[5]?.join();
      tempParam.Config = {
        Revision: { Value: dv0, Type: "Revision" },
        Department: { Value: dv1, Type: "Department" },
        Revenue: { Value: dv2, Type: "Account" },
        Cost: { Value: dv3, Type: "Account" },
        "Operating Expenses": { Value: dv4, Type: "Account" },
        "Non-Operating Expenses": { Value: dv5, Type: "Account" },
      };
    }
    if (tempParam.configName === "Budget Trend") {
      const dv0 = parseInt(defaultValue[0]);
      const dv1 = defaultValue[1]?.join();
      const dv2 = defaultValue[2]?.join();
      const dv3 = defaultValue[3]?.join();
      const dv4 = defaultValue[4]?.join();
      tempParam.Config = {
        Revision: { Value: dv0, Type: "Revision" },
        Department: { Value: dv1, Type: "Department" },
        Revenue: { Value: dv2, Type: "Account" },
        Expense: { Value: dv3, Type: "Account" },
        AccountCode: {
          Value: dv4,
          Type: "Account",
          IsSelect: typeOfAccount === "Summary" ? true : false,
        },
      };
    }
    updateChart(tempParam);
    updateParam(tempParam);
    close();
  };

  const SwitchFormField = () => {
    switch (tempParam.configName) {
      case "Statistical Highlight":
      case "Dimension Analysis":
      case "Daily Monitor Account":
      case "ActualBudget":
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="viewMode">{translate("ra.field.Date")}</InputLabel>
                <Select
                  variant="outlined"
                  margin="dense"
                  labelId="viewMode"
                  label={translate("ra.field.Date")}
                  value={tempParam.TypeOfDate}
                  onChange={(e) => {
                    const newPeriod = optionList.find((i) => i.name === e.target.value);
                    setTempParam((state) => ({
                      ...state,
                      TypeOfDate: e.target.value,
                      FromDate: newPeriod.start !== "" ? newPeriod.start : tempParam.FromDate,
                      ToDate: newPeriod.end !== "" ? newPeriod.end : tempParam.ToDate,
                    }));
                  }}
                >
                  {optionList
                    ? optionList.map((item, idx) => {
                        return (
                          <MenuItem key={idx} value={item.name}>
                            {item.name}
                          </MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <DatePickerFormat
                label={translate("ra.field.From")}
                value={tempParam.FromDate}
                onChange={(e) => {
                  setTempParam((state) => ({
                    ...state,
                    TypeOfDate: "Custom",
                    FromDate: e,
                    ToDate: e > tempParam.ToDate ? e : tempParam.ToDate,
                  }));
                }}
                style={{ margin: 0 }}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerFormat
                label={translate("ra.field.To")}
                value={tempParam.ToDate}
                onChange={(e) => {
                  setTempParam((state) => ({
                    ...state,
                    TypeOfDate: "Custom",
                    ToDate: e,
                  }));
                }}
                minDate={tempParam.FromDate}
                minDateMessage={"Date must be more than from date"}
                style={{ margin: 0 }}
              />
            </Grid>
          </Grid>
        );
      case "Payable":
      case "Receivable":
        return (
          <DatePickerFormat
            label={"Date"}
            value={tempParam.FromDate}
            onChange={(e) =>
              setTempParam((state) => ({
                ...state,
                FromDate: e,
              }))
            }
            style={{ margin: 0 }}
          />
        );
      case "P&L Summary":
      case "Income Statement":
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              disableFuture
              openTo="year"
              format={"MM/yyyy"}
              views={["year", "month"]}
              inputVariant="outlined"
              margin="dense"
              label="Select Date"
              value={tempParam.FromDate}
              onChange={(e) =>
                setTempParam((state) => ({
                  ...state,
                  FromDate: e,
                }))
              }
              autoOk={true}
              okLabel=""
              cancelLabel=""
              //animateYearScrolling
            />
          </MuiPickersUtilsProvider>
        );
      case "Budget Trend":
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  //disabled={tempParam.prevDate >= tempParam.date}
                  maxDate={new Date(`${new Date(tempParam.ToDate).getFullYear() - 1}-12-31`)}
                  disableFuture
                  views={["year"]}
                  inputVariant="outlined"
                  margin="dense"
                  label="From Year"
                  value={tempParam.FromDate}
                  onChange={(e) =>
                    setTempParam((state) => ({
                      ...state,
                      FromDate: e,
                    }))
                  }
                  autoOk={true}
                  okLabel=""
                  cancelLabel=""
                  //animateYearScrolling
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  disableFuture
                  views={["year"]}
                  inputVariant="outlined"
                  margin="dense"
                  label="To Year"
                  value={tempParam.ToDate}
                  onChange={(e) =>
                    setTempParam((state) => ({
                      ...state,
                      ToDate: e,
                    }))
                  }
                  autoOk={true}
                  okLabel=""
                  cancelLabel=""
                  //animateYearScrolling
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        );
      case "Annual":
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  //disabled={tempParam.prevDate >= tempParam.date}
                  maxDate={new Date(`${new Date(tempParam.ToDate).getFullYear() - 1}-12-31`)}
                  disableFuture
                  views={["year"]}
                  inputVariant="outlined"
                  margin="dense"
                  label="Previous Year"
                  value={tempParam.FromDate}
                  onChange={(e) =>
                    setTempParam((state) => ({
                      ...state,
                      FromDate: e,
                    }))
                  }
                  autoOk={true}
                  okLabel=""
                  cancelLabel=""
                  //animateYearScrolling
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  disableFuture
                  views={["year"]}
                  inputVariant="outlined"
                  margin="dense"
                  label="This Year"
                  value={tempParam.ToDate}
                  onChange={(e) =>
                    setTempParam((state) => ({
                      ...state,
                      ToDate: e,
                    }))
                  }
                  autoOk={true}
                  okLabel=""
                  cancelLabel=""
                  //animateYearScrolling
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  const SwitchOptionField = (name) => {
    switch (typeOfAccount) {
      case "Summary":
        if (name === "Revenue" || name === "Expense") {
          return "none";
        }
        if (name === "AccountCode") {
          return "block";
        }
        break;
      default:
        if (name === "Revenue" || name === "Expense") {
          return "block";
        }
        if (name === "AccountCode") {
          return "none";
        }
        break;
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={size ? size : "md"}
      open={open}
      onClose={close}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={close}>
        {/* Config {name} */}
      </DialogTitle>
      <DialogContent className={classes.centerContent}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label={translate("ra.field.Title")}
                  value={tempParam.name}
                  onChange={(e) => {
                    setTempParam((state) => ({
                      ...state,
                      name: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={6} gutterBottom>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label={translate("ra.field.Seq")}
                  value={tempParam.Seq}
                  onChange={(e) =>
                    setTempParam((state) => ({
                      ...state,
                      Seq: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={6} style={{ marginBottom: 14 }}>
                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel id="size-select">{translate("ra.field.Width")}</InputLabel>
                  <Select
                    labelId="size-select"
                    value={tempParam.Width}
                    onChange={async (e) => {
                      if (e.target.value) {
                        setTempParam((state) => ({
                          ...state,
                          Width: e.target.value,
                        }));
                      } else {
                        e.preventDefault();
                      }
                    }}
                    label={translate("ra.field.Width")}
                  >
                    {["100", "50"].map((item) => (
                      <MenuItem key={item} value={item}>
                        {item} %
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" margin="dense" fullWidth>
                  <InputLabel id="type-select">{translate("ra.field.Type")}</InputLabel>
                  <Select
                    labelId="type-select"
                    value={tempParam.configName}
                    onChange={async (e) => {
                      let newConfig;

                      const foundItem = typeList.find((i) => i.Type === e.target.value)?.DefaultConfig ?? [];

                      if (foundItem && foundItem.length > 0) {
                        newConfig = JSON.parse(foundItem);
                      }

                      if (e.target.value) {
                        let fDate = tempParam.FromDate;
                        if (e.target.value === "Annual") {
                          fDate = addYears(new Date(), -1);
                        }
                        if (e.target.value === "Budget Trend") {
                          fDate = addYears(new Date(), -4);
                        }
                        setTempParam((state) => ({
                          ...state,
                          configName: e.target.value,
                          FromDate: fDate,
                          Config: newConfig,
                        }));
                      } else {
                        e.preventDefault();
                      }
                    }}
                    //style={{ width: 200 }}
                    label={translate("ra.field.Type")}
                  >
                    {typeList.map((item, idx) => (
                      <MenuItem key={idx} value={item.Type}>
                        {item.Type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <SwitchFormField />
            {field ? field : ""}

            {tempParam && tempParam.configName === "Budget Trend" ? (
              <FormControl variant="outlined" fullWidth style={{ marginTop: 12 }}>
                <InputLabel id="label">Select Type Of Account</InputLabel>
                <Select
                  labelId="label"
                  variant="outlined"
                  margin="dense"
                  label="Select Type Of Account"
                  value={typeOfAccount}
                  defaultValue={"Summary"}
                  onChange={onOptionChangeHandler}
                >
                  {["Summary", "Net Off"].map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}

            {config.Config ? (
              <Grid container style={{ marginBottom: 20 }}>
                {!Array.isArray(config.Config) ? (
                  Object.keys(config.Config).map((item, idx) => (
                    <Grid
                      xs={props.length > 2 ? 6 : 12}
                      key={idx}
                      style={{
                        margin: "2px 0",
                        display: SwitchOptionField(item),
                      }}
                    >
                      <Box display="flex">
                        <Typography variant="h6">
                          <b>{item === "Revision" ? "Budget" : item}</b>
                          <IconButton
                            className={clsx(classes.expand, {
                              [classes.expandOpen]: showCollapse[idx],
                            })}
                            aria-expanded={showCollapse[idx]}
                            aria-label="show more"
                            onClick={() => {
                              showCollapse[idx] = !showCollapse[idx];
                              setShowCollapse(showCollapse);
                              forceUpdate();
                            }}
                          >
                            <Icon>expand_more</Icon>
                          </IconButton>
                        </Typography>
                      </Box>

                      <Collapse in={showCollapse[idx]} timeout="auto" unmountOnExit>
                        {
                          {
                            Account: (
                              <MultiSelectListBox
                                className={"multi-select"}
                                overrideStrings={{
                                  search: "Search...",
                                  selectAll: "Select All",
                                  removeAll: "Remove All",
                                  selectedInfo: "Items selected",
                                }}
                                sortable={true}
                                options={defaultValue.accLookup}
                                textField="label"
                                valueField="value"
                                value={defaultValue[`${idx}`]}
                                rowHeight={30}
                                onSelect={({ item, sortedList }) => {
                                  handleChange(
                                    sortedList.map((i) => i.value),
                                    idx
                                  );
                                }}
                                onRemove={({ item }) => {
                                  handleChange([...defaultValue[`${idx}`].filter((i) => i !== item.value)], idx);
                                }}
                                onSelectAll={(selectedItems) => {
                                  const selected = [
                                    ...defaultValue[`${idx}`],
                                    ...selectedItems.map((item) => item.value),
                                  ];
                                  handleChange(selected, idx);
                                }}
                                onRemoveAll={() => handleChange([], idx)}
                                onSort={({ sortedList }) => handleChange([...sortedList.map((i) => i.value)])}
                                onSearch={({ items, textField, query }) =>
                                  items.filter((i) => i.label.toLowerCase().includes(query.toLowerCase()))
                                }
                              />
                            ),
                            Department: (
                              <MultiSelectListBox
                                className={"multi-select"}
                                overrideStrings={{
                                  search: "Search...",
                                  selectAll: "Select All",
                                  removeAll: "Remove All",
                                  selectedInfo: "Items selected",
                                }}
                                sortable={true}
                                options={defaultValue.deptLookup}
                                textField="label"
                                valueField="value"
                                value={defaultValue[`${idx}`]}
                                rowHeight={30}
                                onSelect={({ item, sortedList }) => {
                                  handleChange(
                                    sortedList.map((i) => i.value),
                                    idx
                                  );
                                }}
                                onRemove={({ item }) => {
                                  handleChange([...defaultValue[`${idx}`].filter((i) => i !== item.value)], idx);
                                }}
                                onSelectAll={(selectedItems) => {
                                  const selected = [
                                    ...defaultValue[`${idx}`],
                                    ...selectedItems.map((item) => item.value),
                                  ];
                                  handleChange(selected, idx);
                                }}
                                onRemoveAll={() => handleChange([], idx)}
                                onSort={({ sortedList }) => handleChange([...sortedList.map((i) => i.value)])}
                                onSearch={({ items, textField, query }) =>
                                  items.filter((i) => i.label.toLowerCase().includes(query.toLowerCase()))
                                }
                              />
                            ),
                            Dimension: (
                              <>
                                {defaultValue.dimLookup && (
                                  <FormControl variant="outlined" fullWidth>
                                    <InputLabel
                                      id="caption"
                                      style={{
                                        marginTop:
                                          defaultValue[`${idx}`] === "" ||
                                          defaultValue[`${idx}`] === null ||
                                          defaultValue[`${idx}`]?.length === 0
                                            ? -6
                                            : 0,
                                      }}
                                    >
                                      {translate("ra.module.Dimension")}
                                    </InputLabel>
                                    <Select
                                      variant="outlined"
                                      margin="dense"
                                      labelId="caption"
                                      label={translate("ra.module.Dimension")}
                                      value={defaultValue[`${idx}`]}
                                      onChange={(e) => {
                                        handleChange(e.target.value, idx);
                                      }}
                                      //style={{ width: 206 }}
                                    >
                                      {defaultValue.dimLookup.map((item, idx) => (
                                        <MenuItem key={item.Id} value={item.Id}>
                                          {item.Caption}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                )}
                                {!defaultValue.dimLookup && <>Not Found Dimension</>}
                              </>
                            ),
                            Revision: (
                              <>
                                <FormControl variant="outlined" margin="dense" fullWidth style={{ marginTop: 14 }}>
                                  <InputLabel id="size-select">Select Budget (Revision)</InputLabel>
                                  <Select
                                    labelId="size-select"
                                    value={defaultValue[`${idx}`]}
                                    onChange={async (e) => {
                                      handleChange(e.target.value, idx);
                                    }}
                                    label="Select Budget (Revision)"
                                  >
                                    {[
                                      { id: 0, name: "Annual" },
                                      { id: 1, name: "Reivision 1" },
                                      { id: 2, name: "Reivision 2" },
                                      { id: 3, name: "Reivision 3" },
                                      { id: 4, name: "Reivision 4" },
                                    ].map((item) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </>
                            ),
                            Table: <ActualvsBudget data={defaultValue[`${idx}`]} />,
                            // Color: <>Color</>,
                          }[config.Config[item].Type]
                        }
                      </Collapse>
                    </Grid>
                  ))
                ) : (
                  <>
                    {config.Config[0] &&
                      {
                        103: Object.keys(config.Config[0])
                          .sort()
                          .map((item, idx) => (
                            <>
                              {item !== "Name" ? (
                                <Grid item xs={props.length > 2 ? 6 : 12} key={idx} style={{ margin: "10px 0" }}>
                                  <Box display="flex">
                                    <Typography variant="h6">
                                      <b>{item}</b>
                                      <IconButton
                                        className={clsx(classes.expand, {
                                          [classes.expandOpen]: showCollapse[idx],
                                        })}
                                        aria-expanded={showCollapse[idx]}
                                        aria-label="show more"
                                        onClick={() => {
                                          showCollapse[idx] = !showCollapse[idx];
                                          setShowCollapse(showCollapse);
                                          forceUpdate();
                                        }}
                                      >
                                        <ExpandMoreIcon />
                                      </IconButton>
                                    </Typography>
                                  </Box>
                                  <Collapse in={showCollapse[idx]} timeout="auto" unmountOnExit>
                                    {
                                      {
                                        Account: (
                                          <MultiSelectListBox
                                            className={"multi-select"}
                                            overrideStrings={{
                                              search: "Search...",
                                              selectAll: "Select All",
                                              removeAll: "Remove All",
                                              selectedInfo: "Items selected",
                                            }}
                                            sortable={true}
                                            options={defaultValue.accLookup}
                                            textField="label"
                                            valueField="value"
                                            value={defaultValue[`${idx}`]}
                                            rowHeight={30}
                                            onSelect={({ item, sortedList }) => {
                                              handleChange(
                                                sortedList.map((i) => i.value),
                                                idx
                                              );
                                            }}
                                            onRemove={({ item }) => {
                                              handleChange(
                                                [...defaultValue[`${idx}`].filter((i) => i !== item.value)],
                                                idx
                                              );
                                            }}
                                            onSelectAll={(selectedItems) => {
                                              const selected = [
                                                ...defaultValue[`${idx}`],
                                                ...selectedItems.map((item) => item.value),
                                              ];
                                              handleChange(selected, idx);
                                            }}
                                            onRemoveAll={() => handleChange([], idx)}
                                            onSort={({ sortedList }) =>
                                              handleChange([...sortedList.map((i) => i.value)])
                                            }
                                            onSearch={({ items, textField, query }) =>
                                              items.filter((i) => i.label.toLowerCase().includes(query.toLowerCase()))
                                            }
                                          />
                                        ),
                                        Department: (
                                          <MultiSelectListBox
                                            className={"multi-select"}
                                            overrideStrings={{
                                              search: "Search...",
                                              selectAll: "Select All",
                                              removeAll: "Remove All",
                                              selectedInfo: "Items selected",
                                            }}
                                            sortable={true}
                                            options={defaultValue.deptLookup}
                                            textField="label"
                                            valueField="value"
                                            value={defaultValue[`${idx}`]}
                                            rowHeight={30}
                                            onSelect={({ item, sortedList }) => {
                                              handleChange(
                                                sortedList.map((i) => i.value),
                                                idx
                                              );
                                            }}
                                            onRemove={({ item }) => {
                                              handleChange(
                                                [...defaultValue[`${idx}`].filter((i) => i !== item.value)],
                                                idx
                                              );
                                            }}
                                            onSelectAll={(selectedItems) => {
                                              const selected = [
                                                ...defaultValue[`${idx}`],
                                                ...selectedItems.map((item) => item.value),
                                              ];
                                              handleChange(selected, idx);
                                            }}
                                            onRemoveAll={() => handleChange([], idx)}
                                            onSort={({ sortedList }) =>
                                              handleChange([...sortedList.map((i) => i.value)])
                                            }
                                            onSearch={({ items, textField, query }) =>
                                              items.filter((i) => i.label.toLowerCase().includes(query.toLowerCase()))
                                            }
                                          />
                                        ),
                                      }[item]
                                    }
                                  </Collapse>
                                </Grid>
                              ) : (
                                ""
                              )}
                            </>
                          )),
                        106: <ActualvsBudget {...config} />,
                      }[config.Id]}
                  </>
                )}
              </Grid>
            ) : null}
          </>
        )}
        {/* <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(defaultValue, 0, 2) : ""}</pre> */}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={updateConfig} color="primary" variant="contained">
          {translate("ra.actionMenu.OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigDashboard;
