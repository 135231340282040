import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Grid, IconButton, Button, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { GblContext } from "providers/formatter";
import TextTopInGrid from "components/TextTopInGrid";
import Divider from "@material-ui/core/Divider";
import { useTranslate } from "react-admin";
import DialogViewARInvoice from "./DialogViewARInvoice";

function DialogViewReceiptAR(props) {
  const { data, open, onClose, Rcpt } = props;
  const [openDialogIV, setOpenDialogIV] = useState(false);
  const translate = useTranslate();

  const { NumberFormat, DateToString } = useContext(GblContext);
  const labelList = {
    Amount: translate("ra.field.Amount"),
    Base: translate("ra.field.Base"),
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "10px 24px" }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {"Receipt Detail "}
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 5 }}>
            <Button
              variant="contained"
              style={{ marginBottom: 5, marginLeft: "auto" }}
              onClick={() => setOpenDialogIV(!openDialogIV)}
            >
              {translate("View Invoice")}
            </Button>
            <TextTopInGrid sizeSm={12} label="Invoice No." value={data.InvoiceHeader.InvNo} />
            <TextTopInGrid sizeSm={12} label="Date" value={DateToString(data.InvoiceHeader.InvhDate)} />
            <TextTopInGrid sizeSm={12} label="A/R No." value={data.InvoiceHeader.ArNo} />
            <TextTopInGrid
              sizeSm={6}
              label="Currency"
              value={Rcpt.RcptdCurrCode}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={6}
              label="Rate"
              value={NumberFormat(Rcpt.RcptdCurrRate)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid sizeSm={6} label="Due Date" value={DateToString(data.InvoiceHeader.InvhDueDate)} />
            <Grid xs={6}></Grid>
            <TextTopInGrid sizeSm={6} label="Tax Inv No." value={data.InvoiceHeader.InvhTaxNo} />
            <TextTopInGrid sizeSm={6} label="Tax Status" value={data.InvoiceHeader.TaxType} />
            <TextTopInGrid
              sizeSm={6}
              label="Amount"
              value={NumberFormat(Rcpt.InvAmount)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={6}
              label="Paid"
              value={NumberFormat(Rcpt.RcptdAmount)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <Grid item xs={12} sm={6}>
              <TextField
                label={`${labelList["Base"]}${labelList["Amount"]}`}
                value={NumberFormat(Rcpt.RcptdBaseAmount)}
                variant="outlined"
                margin="dense"
                readOnly
                fullWidth
                inputProps={{
                  style: { textAlign: "right" },
                }}
              />
            </Grid>
            <Grid xs={6}></Grid>
            <TextTopInGrid sizeSm={12} label="Description" value={data.InvoiceHeader.InvdDesc} />
          </Grid>
        </DialogContent>
        <br />
        {openDialogIV && (
          <DialogViewARInvoice open={openDialogIV} onClose={() => setOpenDialogIV(false)} id={`${Rcpt.InvhSeq}`} />
        )}
      </Dialog>
    </>
  );
}

export default DialogViewReceiptAR;
