/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useMediaQuery, Card, Button, Tooltip, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getWorkflowStep, getWorkflowStepByUser, approveWorkflow, approveWorkflowBatch } from "services/workflow";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  wfBtn: {
    padding: "8px 0 8px 8px",
    marginBottom: "10px",
    textAlign: "right",
  },
  iconContainer: {
    marginRight: "12px",
  },
  iconContrainerMobile: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const CustomToolbarSelect = (props) => {
  const classes = useStyles();
  const [wfSteps, setWfSteps] = useState();
  const [showBtn, setShowBtn] = useState(false);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const fetchWorkflowStep = async () => {
    const { data } = await getWorkflowStepByUser(props.wfCode);
    const d = data?.find((item) => item.StepNo === parseInt(props.status));
    if (!d) {
      setShowBtn(false);
    } else {
      setShowBtn(true);
    }

    const r = await getWorkflowStep(props.wfCode);
    setWfSteps(r.data);
  };

  useEffect(() => {
    fetchWorkflowStep();
  }, [props.status]);

  const fncApproveWorkflow = async (props, approveCode, result) => {
    let param = [];

    if (Array.isArray(props.selectedRows.data)) {
      //send array (props.selectedRows.data)
      props.selectedRows.data.forEach((row) => {
        const item = props.data[row.dataIndex];
        param.push({
          Id: item.RcpthSeq,
          StepNo: item.RcpthStatus,
          ApproveCode: approveCode === "Send Back" ? "SendBack" : approveCode,
          SendBackToStepNo: parseInt(result.SendBackToStepNo ?? 0),
          Remark: result.Remark,
        });
      });
      const { UserMessage } = await approveWorkflowBatch(props.wfCode, param);
      SnackbarUtils.success(UserMessage, async function () {
        props.fncSuccess();
      });
    } else {
      param = {
        Id: props.selectedRows.data.RcpthSeq,
        StepNo: props.selectedRows.data.RcpthStatus,
        ApproveCode: approveCode === "Send Back" ? "SendBack" : approveCode,
        SendBackToStepNo: parseInt(result.SendBackToStepNo ?? 0),
        Remark: result.Remark,
      };

      const { UserMessage } = await approveWorkflow(props.wfCode, param);
      SnackbarUtils.success(UserMessage, async function () {
        props.fncSuccess();
      });
    }
  };

  const handleClickSelected = async (approveCode) => {
    SnackbarUtils.wfConfirm(approveCode, "", props.status, wfSteps, async function (result) {
      fncApproveWorkflow(props, approveCode, result);
    });
  };

  if (!showBtn) return null;

  return (
    <>
      {props.noBorder ? (
        <div className={classes.iconContainer2}>
          <Tooltip title={"Approve"}>
            <Button
              color="primary"
              variant="outlined"
              style={{ marginRight: 6 }}
              onClick={() => handleClickSelected("Approve")}
            >
              <Icon>assignment_turned_in</Icon>&nbsp;Approve
            </Button>
          </Tooltip>
          <Tooltip title={"Reject"}>
            <Button
              variant="outlined"
              style={{ marginRight: 6, color: "red" }}
              onClick={() => handleClickSelected("Reject")}
            >
              <Icon>cancel_presentation</Icon>&nbsp;Reject
            </Button>
          </Tooltip>
          {props.status !== 1 && props.status !== "Step1" && (
            <Tooltip title={"Send Back"} variant="outlined">
              <Button variant="outlined" style={{ marginRight: 6 }} onClick={() => handleClickSelected("Send Back")}>
                <Icon>replay</Icon> Send Back
              </Button>
            </Tooltip>
          )}
        </div>
      ) : (
        <Card variant="outlined" className={classes.wfBtn}>
          <div className={!isXSmall ? classes.iconContainer : classes.iconContrainerMobile}>
            <Tooltip title={"Approve"}>
              <Button
                color="primary"
                variant="outlined"
                style={{ marginRight: 6 }}
                onClick={() => handleClickSelected("Approve")}
              >
                <Icon>assignment_turned_in</Icon>&nbsp;Approve
              </Button>
            </Tooltip>
            <Tooltip title={"Reject"}>
              <Button
                variant="outlined"
                style={{ marginRight: 6, color: "red" }}
                onClick={() => handleClickSelected("Reject")}
              >
                <Icon>cancel_presentation</Icon>&nbsp;Reject
              </Button>
            </Tooltip>
            {props.status !== 1 && props.status !== "Step1" && (
              <Tooltip title={"Send Back"} variant="outlined">
                <Button variant="outlined" style={{ marginRight: 6 }} onClick={() => handleClickSelected("Send Back")}>
                  <Icon>replay</Icon> Send Back
                </Button>
              </Tooltip>
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export default CustomToolbarSelect;
