/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from "react";
import { useTranslate } from "react-admin";
import AccessDenied from "layout/AccessDenied";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow, TableCell, Switch } from "@material-ui/core";
// import clsx from "clsx";
import MUIDataTable from "mui-datatables";
import ActionMenu from "components/ActionMenu";
import DialogItem from "./Dialog/Currency";
import DialogItemEx from "./Dialog/CurrencyEx";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getCurrencyList, getCurrencySearchList } from "services/setting";
import ModelUriQueryString from "models/uriQueryString";
import { permissionName } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  bullStatusActive: {
    height: 10,
    width: 10,
    backgroundColor: "green",
    borderRadius: "50%",
    display: "inline-block",
  },
  bullStatusInActive: {
    height: 10,
    width: 10,
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
  },
  tabPanel: { width: "100%", margin: "0 20px" },
  button: { textTransform: "none" },
}));

const Currency = (props) => {
  const translate = useTranslate();
  const { permissions, value, index } = props;

  const checkPermission = permissions?.find((i) => i.Name === "Sys.Currency")?.View;
  const { DateToString, NumberFormat, settingAll } = useContext(GblContext);
  const { SettingSystem } = settingAll;
  const classes = useStyles();
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const [Data, setData] = useState();
  const [DataEx, setDataEx] = useState();
  const [uriQueryString] = useState({
    Limit: ModelUriQueryString.Limit,
    Page: ModelUriQueryString.Page,
    OrderBy: ModelUriQueryString.OrderBy,
    WhereGroupList: ModelUriQueryString.WhereGroupList,
    Exclude: ModelUriQueryString.Exclude,
    WhereRaw: ModelUriQueryString.WhereRaw,
    WhereLike: ModelUriQueryString.WhereLike,
    WhereLikeFields: ModelUriQueryString.WhereLikeFields,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogEx, setOpenDialogEx] = useState(false);
  const [mode, setMode] = useState("view");
  const [itemId, setItemId] = useState(0);

  const handleModeView = (id) => {
    setItemId(id);
    setMode("view");
    setOpenDialog(true);
  };

  const handleCloseDialog = (value) => {
    setOpenDialog(false);
    if (typeof value === "number") {
      if (mode === "add") {
        uriQueryString.OrderBy = { CurrDate: "desc" };
        fetchSearchList(uriQueryString);
      } else {
        fetchSearchList(uriQueryString);
      }
    }
  };

  const fetchSearchList = async (uriQueryString) => {
    uriQueryString.Limit = 0;
    const { Data } = await getCurrencyList(uriQueryString);
    if (Data) {
      const filterData = Data.filter((i) => i.CurCode !== SettingSystem.DefaultCurrencyCode);
      setData(filterData);
      //setUriQueryString(uriQueryString);
    } else {
      setData([]);
    }
  };

  const handleModeViewEx = (id) => {
    setItemId(id);
    setMode("view");
    setOpenDialogEx(true);
  };

  const handleCloseDialogEx = (value) => {
    setOpenDialogEx(false);
    if (typeof value === "number") {
      if (mode === "add") {
        uriQueryString.OrderBy = { CurrDate: "desc" };
        fetchSearchListEx(uriQueryString);
      } else {
        fetchSearchListEx(uriQueryString);
      }
    }
  };

  const fetchSearchListEx = async (uriQueryString) => {
    uriQueryString.Limit = 0;
    const { Data } = await getCurrencySearchList(uriQueryString);
    if (Data) {
      const filterData = Data.filter((i) => i.CurrCode !== SettingSystem.DefaultCurrencyCode);
      const newData = filterData.reduce((acc, obj) => {
        let found = false;
        for (let i = 0; i < acc.length; i++) {
          if (acc[i].CurrCode === obj.CurrCode) {
            found = true;
            if (!acc[i].Detail) {
              acc[i].Detail = [];
            }
            acc[i].Detail.push(obj);
            acc[i].count++;
          }
        }
        if (!found) {
          obj.count = 1;
          acc.push(obj);
        }
        return acc;
      }, []);

      setDataEx(newData);
      //setUriQueryString(uriQueryString);
    } else {
      setDataEx([]);
    }
  };

  const handleRequestSort = (property, order) => {
    uriQueryString.OrderBy = { [property]: order };
    fetchSearchList(uriQueryString);
  };

  const handleRequestSortEx = (property, order) => {
    uriQueryString.OrderBy = { [property]: order };
    fetchSearchListEx(uriQueryString);
  };

  React.useEffect(() => {
    if (value === index) {
      fetchSearchList(uriQueryString);
      fetchSearchListEx(uriQueryString);
    }
  }, [value]);

  const columns = [
    {
      name: "Id",
      label: " ",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        download: false,
        customBodyRender: (value) => {
          return (
            <VisibilityIcon
              fontSize="small"
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => handleModeView(value)}
            />
          );
        },
      },
    },
    {
      name: "CurCode",
      label: "Currency Code",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "UnitStr",
      label: "Currency",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "SubUnitStr",
      label: "Decimal Currency",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "LastModified",
      label: "Last Update",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => DateToString(value),
      },
    },
    {
      name: "Active",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return <Switch checked={typeof value === "boolean" ? value : false} />;
        },
      },
    },
  ];

  const options = {
    id: "Currency",
    filter: false, // show the filter icon in the toolbar (true by default)
    search: false,
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    sortOrder: {
      name: Object.keys(uriQueryString.OrderBy)[0],
      direction: Object.values(uriQueryString.OrderBy)[0],
    },
    onColumnSortChange: (changedColumn, direction) => {
      handleRequestSort(changedColumn, direction);
    },
    customFooter: () => "",
  };

  const columnsEx = [
    {
      name: "CurrId",
      label: " ",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        download: false,
        customBodyRender: (value) => {
          return (
            <VisibilityIcon
              fontSize="small"
              color="primary"
              style={{ cursor: "pointer" }}
              onClick={() => handleModeViewEx(value)}
            />
          );
        },
      },
    },
    {
      name: "CurrCode",
      label: "Currency Code",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "CurrDate",
      label: "Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => DateToString(value),
      },
    },
    {
      name: "BuyingRate",
      label: "Buying Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => NumberFormat(value, "exchangeRate"),
      },
    },
    {
      name: "SellingRate",
      label: "Selling Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => NumberFormat(value, "exchangeRate"),
      },
    },
    {
      name: "AverageRate",
      label: "Average Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => NumberFormat(value, "exchangeRate"),
      },
    },
    {
      name: "LastModified",
      label: "Last Update",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => DateToString(value),
      },
    },
    {
      name: "Detail",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        display: false,
      },
    },
  ];

  const optionsEx = {
    id: "ExRate",
    filter: false, // show the filter icon in the toolbar (true by default)
    search: false,
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    confirmFilters: true,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    rowsExpanded: rowsExpanded,
    renderExpandableRow: (rowData) => {
      return (
        <>
          {rowData[7]?.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell />
                <TableCell>
                  <VisibilityIcon
                    fontSize="small"
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleModeViewEx(item.CurrId)}
                  />
                </TableCell>
                <TableCell>{item.CurrCode}</TableCell>
                <TableCell>{DateToString(item.CurrDate)}</TableCell>
                <TableCell style={{ textAlign: "right" }}>{NumberFormat(item.BuyingRate, "exchangeRate")}</TableCell>
                <TableCell style={{ textAlign: "right" }}>{NumberFormat(item.SellingRate, "exchangeRate")}</TableCell>
                <TableCell style={{ textAlign: "right" }}>{NumberFormat(item.AverageRate, "exchangeRate")}</TableCell>
                <TableCell>{DateToString(item.LastModified)}</TableCell>
              </TableRow>
            ))}
        </>
      );
    },
    onRowExpansionChange: (_, allRowsExpanded) => {
      setRowsExpanded(allRowsExpanded.slice(-1).map((item) => item.index));
    },
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    sortOrder: {
      name: Object.keys(uriQueryString.OrderBy)[0],
      direction: Object.values(uriQueryString.OrderBy)[0],
    },
    onColumnSortChange: (changedColumn, direction) => {
      handleRequestSortEx(changedColumn, direction);
    },
    customFooter: () => "",
  };

  const menuControlProp = [
    {
      name: "Add",
      fnc: () => {
        setItemId(0);
        setMode("add");
        setOpenDialog(true);
      },
    },
  ];

  const menuControlPropEx = [
    {
      name: "Add",
      fnc: () => {
        setItemId(0);
        setMode("add");
        setOpenDialogEx(true);
      },
    },
  ];

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {checkPermission && value === index ? (
        <>
          <ActionMenu
            menuControl={menuControlProp}
            permission={permissions.find((i) => i.Name === permissionName["Sys.Currency"])}
          />
          <MUIDataTable title={translate("ra.module.Currency")} data={Data} columns={columns} options={options} />
          {openDialog && (
            <DialogItem
              id={itemId}
              title={translate("ra.module.Currency")}
              mode={mode}
              setMode={setMode}
              open={openDialog}
              onClose={handleCloseDialog}
            />
          )}
          <ActionMenu
            menuControl={menuControlPropEx}
            permission={permissions.find((i) => i.Name === permissionName["Sys.Currency"])}
          />
          <MUIDataTable
            title={translate("ra.module.Currency Exchange Rate")}
            data={DataEx}
            columns={columnsEx}
            options={optionsEx}
          />
          {openDialogEx && (
            <DialogItemEx
              id={itemId}
              title={translate("ra.module.Currency Exchange Rate")}
              mode={mode}
              setMode={setMode}
              open={openDialogEx}
              onClose={handleCloseDialogEx}
            />
          )}
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(Data, 0, 2) : ""}</pre>
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(DataEx, 0, 2) : ""}</pre>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default Currency;
