export default {
  UserModified: localStorage.getItem("UserName"),
  VnCode: "",
  VnName: "",
  Active: true,
  VnCateCode: "",
  VnPayee: "",
  VnAttn: "",
  VnAdd1: "",
  VnAdd2: "",
  VnAdd3: "",
  VnAdd4: "",
  VnTel: "",
  VnFax: "",
  VnEmail: "",
  VnMapRef: "",
  Vn2Payee: "",
  Vn2Add1: "",
  Vn2Add2: "",
  Vn2Add3: "",
  Vn2Add4: "",
  Vn2Tel: "",
  Vn2Fax: "",
  Vn2Email: "",
  VnTerm: 0,
  VnDisTrm: 0,
  VnDisPct: 0,
  VnRegNo: "",
  VnTaxNo: "",
  VnVat1: "None",
  VnTaxR1: 0,
  VnVat2: "None",
  VnTaxR2: 0,
  VnWTax: 0,
  VnLastCls: new Date(),
  VnCurCl: new Date(),
  VnBfwd: 0,
  VnInvT: 0,
  VnPmtT: 0,
  VnCurBal: 0,
  VnAge1: 0,
  VnAge2: 0,
  VnAge3: 0,
  VnAge4: 0,
  VnAgeT: 0,
  VnAgeDate: new Date(),
  VnVat1DrAccCode: "",
  VnVat2DrAccCode: "",
  VnPayType: "",
  VnPayDeptCode: "",
  VnCrAccCode: "",
  VnVatCrAccCode: "",
  VnWhtDeptCode: "",
  VnWhtAccCode: "",
  BankAccountName: "",
  BankAccountNo: "",
  BankRef1: "",
  BankRef2: "",
  BankRef3: "",
  BankName: "",
  VnReference: "",
  BranchNo: "",
};
