import React, { useContext, useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import AccessDenied from "layout/AccessDenied";
import { GblContext } from "providers/formatter";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Divider } from "@material-ui/core";
import { SwitchInForm, SelectInForm } from "components/Form";
import { getSettingAsset, updateSettingAsset } from "services/setting";
import DialogAstCategory from "./Dialog/AstCategory";
import DialogAstDepartment from "./Dialog/AstDepartment";
import DialogAstLocation from "./Dialog/AstLocation";
import DialogSettingAccounts from "./Dialog/SettingAccountList";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import { AssetNumberFormat } from "utils/options";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  tabPanel: { width: "100%", margin: "0 20px" },
  button: { textTransform: "none" },
}));

const Asset = (props) => {
  const { permissions, value, index } = props;

  let checkPermission = permissions?.find(
    (i) => i.Name === "Ast.Category" || i.Name === "Ast.Department" || i.Name === "Ast.Location"
  )?.View;

  const classes = useStyles();
  const translate = useTranslate();
  const { UpdateSettingAll } = useContext(GblContext);
  const [setting, setSetting] = useState();
  const [mode, setMode] = useState("view");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [openAstCate, setOpenAstCate] = useState(false);
  const [openAstDept, setOpenAstDept] = useState(false);
  const [openAstLoc, setOpenAstLoc] = useState(false);
  const [openSettingAcc, setOpenSettingAcc] = useState(false);

  const methods = useForm({ defaultValues: setting });

  const { handleSubmit, reset } = methods;

  const fetchItem = async () => {
    const setting = await getSettingAsset();
    if (setting) {
      if (setting.ApPostingAccounts) {
        setting.ApPostingAccounts = JSON.parse(setting.ApPostingAccounts);
      }
      if (setting.IdFormat === "CategoryAndDepartment") {
        setting.IdFormat = "Category+Department";
      }
      setSetting(setting);
      reset(setting);
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    if (value === index) {
      fetchItem();
    }
  }, [value, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async (values) => {
    setBtnLoading(true);
    let settingAll = JSON.parse(localStorage.getItem("SettingAll"));
    settingAll.SettingAsset = values;
    values.ApPostingAccounts = JSON.stringify(values.ApPostingAccounts);

    if (values.IdFormat === "Category+Department") {
      values.IdFormat = "CategoryAndDepartment";
    }

    const { Code, UserMessage } = await updateSettingAsset(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
      localStorage.setItem("SettingAll", JSON.stringify(settingAll));
      UpdateSettingAll(settingAll);
      setMode("view");
      fetchItem();
    } else {
      SnackbarUtils.warning(UserMessage);
      setBtnLoading(false);
    }
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
    },
  ];

  const CancelFnc = () => {
    reset();
    setMode("view");
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}>
      {checkPermission && value === index ? (
        <>
          <ActionMenu menuControl={menuControlProp} justifyContent="flex-start" />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.asset.Asset Register Number Format")}</Typography>
                {/* <Typography variant="caption">
                  Set asset number by auto - generated from category + department + auto running number 4 digits
                </Typography> */}
              </Box>
              <Box style={{ width: 240 }}>
                {methods.watch("IdFormat") && (
                  <SelectInForm
                    label="Format"
                    name="IdFormat"
                    options={AssetNumberFormat}
                    methods={methods}
                    disabled={mode === "view"}
                  />
                )}
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.asset.Enable Asset Barcode")}</Typography>
                {/* <Typography variant="caption">Enable or disable barcode menu in asset</Typography> */}
              </Box>
              <Box>
                <SwitchInForm name="EnableAssetBarCode" methods={methods} disabled={mode === "view"} defaultChecked />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">
                  {translate("ra.asset.Reverse Assets and Acc. Depre. Account")}
                </Typography>
                {/* <Typography variant="caption">Reverse Assets and Acc. Depre. Account</Typography> */}
              </Box>
              <Box>
                <SwitchInForm
                  name="UsePostReverseAssetsAndAccDepre"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">
                  {translate("ra.ap.Allow create duplicate TaxID on vendor profile")}
                </Typography>
                {/* <Typography variant="caption">Allow create duplicate TaxID on vendor profile</Typography> */}
              </Box>
              <Box>
                <SwitchInForm
                  name="AllowVendorDuplicateTaxId"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.asset.Set Asset Account Code for Posting")}</Typography>
                {/* <Typography variant="caption">Set Asset Account Code for posting</Typography> */}
              </Box>
              <Box>
                <Button variant="outlined" onClick={() => setOpenSettingAcc(true)}>
                  {translate("ra.field.Account Code")}
                </Button>
              </Box>
            </Box>

            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.field.Setup Lookup")}</Typography>
                {/* <Typography variant="caption">add edit delete lookup</Typography> */}
              </Box>
              <Box px={1}>
                <Button variant="outlined" onClick={() => setOpenAstCate(true)}>
                  {translate("ra.field.Category")}
                </Button>
              </Box>
              <Box px={1}>
                <Button variant="outlined" onClick={() => setOpenAstDept(true)}>
                  {translate("ra.field.Department")}
                </Button>
              </Box>
              <Box pl={1}>
                <Button variant="outlined" onClick={() => setOpenAstLoc(true)}>
                  {translate("ra.field.Location")}
                </Button>
              </Box>
            </Box>
            {mode !== "view" && <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />}
          </form>
          <DialogAstCategory
            title={translate("ra.field.Category")}
            open={openAstCate}
            onClose={() => setOpenAstCate(false)}
          />
          <DialogAstDepartment
            title={translate("ra.field.Department")}
            open={openAstDept}
            onClose={() => setOpenAstDept(false)}
          />
          <DialogAstLocation
            title={translate("ra.field.Location")}
            open={openAstLoc}
            onClose={() => setOpenAstLoc(false)}
          />
          {setting && (
            <DialogSettingAccounts
              title={"Setting Account for Posting"}
              open={openSettingAcc}
              onClose={async (e, accList) => {
                setOpenSettingAcc(false);
                if (accList) {
                  setSetting((state) => ({
                    ...state,
                    IdFormat: state.IdFormat === "Category+Department" ? "CategoryAndDepartment" : setting.IdFormat,
                    ApPostingAccounts: accList,
                  }));
                  setBtnLoading(true);
                  setting.ApPostingAccounts = JSON.stringify(accList);
                  if (setting.IdFormat === "Category+Department") {
                    setting.IdFormat = "CategoryAndDepartment";
                  }
                  const { Code, UserMessage } = await updateSettingAsset(setting);
                  if (Code === 0) {
                    SnackbarUtils.success(UserMessage);
                    let settingAll = JSON.parse(localStorage.getItem("SettingAll"));
                    settingAll.SettingAsset = setting;
                    localStorage.setItem("SettingAll", JSON.stringify(settingAll));
                    UpdateSettingAll(settingAll);
                    setMode("view");
                    fetchItem();
                  } else {
                    SnackbarUtils.warning(UserMessage);
                    setBtnLoading(false);
                  }
                }
              }}
              currentAcc={setting.ApPostingAccounts}
            />
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default Asset;
