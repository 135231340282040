export default {
  ReqId: -1,
  Id: "",
  No: "Auto",
  InputDate: new Date(),
  AcquireDate: new Date(),
  TransferDate: new Date(),
  VnCode: "",
  VnName: "",
  CategoryCode: "",
  DepartmentCode: "",
  Barcode: "",
  Amount: 0,
  BaseAmount: 0,
  InitAccu: 0,
  InvoiceNo: "",
  Life: 1,
  LifeType: "Year",
  LocationCode: "",
  Name: "",
  Qty: 1,
  Remark: "",
  RemainDay: 0,
  CurCode: "",
  CurRate: 0,
  RemainNet: 0,
  RemainQty: 0,
  RemainTotalCost: 0,
  Salvage: 1,
  TotalSalvage: 1,
  SerialNo: "",
  Spec: "",
  TotalCost: 0,
  UnitCode: "",
  DepreDeptCode: "",
  DepreAccCode: "",
  AccuDeptCode: "",
  AccuAccCode: "",
  CostDeptCode: "",
  CostDeptDesc: "",
  CostAccCode: "",
  CostAccDesc: "",
  AstPhoto: "",
  DimList: {},
  UserModified: localStorage.getItem("UserName"),
};
