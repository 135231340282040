import React, { useState, useEffect } from "react";
import { Loading, useLocale } from "react-admin";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Typography, IconButton } from "@material-ui/core";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { getAccCodeListWithSp } from "services/callStoreProcedure";

import MultiSelectListBox from "react-multiselect-listbox";

const useStyles = makeStyles((theme) => ({
  centerContent: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    //width: "fit-content",
    width: "80%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogSettingAccountList = (props) => {
  const locale = useLocale();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { title, open, onClose, currentAcc } = props;
  function useForceUpdate() {
    let [value, setState] = useState(true);
    return () => setState(!value);
  }

  let forceUpdate = useForceUpdate();

  const [defaultValue, setDefaultValue] = useState({
    accValue:
      typeof currentAcc === "string" && currentAcc?.length > 0
        ? JSON.parse(currentAcc).map((i) => i.Code)
        : currentAcc?.length > 0
        ? currentAcc?.map((i) => i.Code)
        : [],
    accLookup: [],
  });

  const [selectedValue, setSelectedValue] = useState(
    typeof currentAcc === "string" && currentAcc?.length > 0
      ? JSON.parse(currentAcc)
      : currentAcc?.length > 0
      ? currentAcc
      : []
  );

  const fetchConfig = async (mounted) => {
    const accList = await getAccCodeListWithSp();
    //change lookup
    var newAccLookUp = accList?.map((el, idx) => {
      //var o = Object.assign({}, el);
      var o = Object.assign({});
      o.id = idx;
      o.Code = el.AccCode;
      o.Desc = el.AccDesc;
      o.label = locale === "en-US" ? el.AccFullName : el.AccTFullName;
      return o;
    });

    setDefaultValue((state) => ({
      ...state,
      accLookup: newAccLookUp,
    }));

    if (mounted) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchConfig(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (selectedItems) => {
    setDefaultValue((state) => ({
      ...state,
      accValue: selectedItems.map((i) => i.Code),
    }));
    setSelectedValue(selectedItems);
    forceUpdate();
  };

  const updateConfig = async (e) => {
    onClose(e, selectedValue);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          onClose(e);
        }
      }}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            onClose(e);
          }
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <MultiSelectListBox
              className={"multi-select"}
              overrideStrings={{
                search: "Search...",
                selectAll: "Select All",
                removeAll: "Remove All",
                selectedInfo: "Items selected",
              }}
              sortable={true}
              options={defaultValue.accLookup}
              textField="label"
              valueField="Code"
              value={defaultValue.accValue}
              rowHeight={30}
              onSelect={({ sortedList }) => {
                handleChange(sortedList);
              }}
              onRemove={({ item }) => {
                handleChange([...selectedValue.filter((i) => i.Code !== item.Code)]);
              }}
              onSelectAll={(selectedItems) => {
                const selected = [...selectedValue, ...selectedItems];
                handleChange(selected);
              }}
              onRemoveAll={() => handleChange([])}
              onSort={({ sortedList }) => handleChange([...sortedList])}
              onSearch={({ items, textField, query }) =>
                items.filter((i) => i.label?.toLowerCase().includes(query.toLowerCase()))
              }
            />
          </>
        )}
        {/* <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(defaultValue, 0, 2) : ""}</pre> */}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={updateConfig} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSettingAccountList;
