import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  textCancel: {
    color: "inherit",
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const options = [
  {
    index: 1,
    value: true,
    desc: "ฉบับที่ 1 (สำหรับ ผู้ถูกหัก ภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)",
  },
  {
    index: 2,
    value: true,
    desc: "ฉบับที่ 2 (สำหรับ ผู้ถูกหัก ภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน)",
  },
  {
    index: 3,
    value: true,
    desc: "ฉบับที่ 3 (สำหรับ ผู้หัก ภาษี ณ ที่จ่าย ใช้แนบพร้อมกับแบบแสดงรายการภาษี)",
  },
  {
    index: 4,
    value: true,
    desc: "ฉบับที่ 4 (สำหรับ ผู้หัก ภาษี ณ ที่จ่าย เก็บไว้เป็นหลักฐาน)",
  },
];

const DialogPrintOption = (props) => {
  const classes = useStyles();
  const { onClose, open, isLoading } = props;
  const [state, setState] = React.useState(options);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = (e) => {
    e.preventDefault();
    onClose(options);
  };

  const handleChange = (event, option) => {
    option.value = event.target.checked;
    setState({ ...state, option });
  };

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">Select Type</DialogTitle>
      <DialogContent dividers>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {options.map((option, idx) => (
              <FormControlLabel
                label={option.desc}
                key={idx}
                control={
                  <Checkbox checked={option.value} onChange={(e) => handleChange(e, option)} name={option.index} />
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <LoadingButton text="Print" disabled={isLoading} fnc={handleOk} />
        <Button variant="outlined" className={classes.textCancel} onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPrintOption;
