import { Resource } from "react-admin";
import { HiDatabase } from "react-icons/hi";
import { IoCalendarSharp } from "react-icons/io5";
import { IoAlbumsSharp } from "react-icons/io5";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";

import JournalVoucher from "pages/generalLedger/JournalVoucher";
import AllocationVoucher from "pages/generalLedger/AllocationVoucher";
import StdVoucher from "pages/generalLedger/StdVoucher";
import RecurringVoucher from "pages/generalLedger/RecurringVoucher";
import AmortizationVoucher from "pages/generalLedger/AmortizationVoucher";
import AccountSummary from "pages/generalLedger/AccountSummary";
import FinancialReport from "pages/generalLedger/FinancialReport";
import ChartOfAccount from "pages/generalLedger/ChartOfAccount";
import Budget from "pages/generalLedger/Budget";
import GlProcedure from "pages/generalLedger/Procedure";

import ApVendor from "pages/accountsPayable/ApVendor";
import ApInvoice from "pages/accountsPayable/ApInvoice";
import ApPayment from "pages/accountsPayable/ApPayment";
import ApProcedure from "pages/accountsPayable/Procedure";

import ArProfile from "pages/accountsReceivable/ArProfile";
import ArFolio from "pages/accountsReceivable/ArFolio";
import ArInvoice from "pages/accountsReceivable/ArInvoice";
import ArReceipt from "pages/accountsReceivable/ArReceipt";
import ArProcedure from "pages/accountsReceivable/Procedure";

import AssetVendor from "pages/asset/AssetVendor";
import PreAsset from "pages/asset/PreAsset";
import AssetRegister from "pages/asset/AssetRegister";
import AssetDisposal from "pages/asset/AssetDisposal";
//import AssetImpairment from "pages/asset/AssetImpairment";
import AssetProcedure from "pages/asset/Procedure";

import Report from "../pages/Report";

const avartarWidth = {
  width: 30,
  height: 30,
  backgroundColor: "#2196f3",
};

const avatarText = {
  fontSize: 14,
};

const ProcedureIcon = () => {
  return <Icon>assignment</Icon>;
};

const ReportIcon = () => {
  return <Icon>assessment</Icon>;
};

const resources = [
  <Resource
    key="GL"
    name="menuGl"
    options={{ id: "GL", label: "General Ledger", IsSubmenu: true }}
    icon={
      <Avatar style={avartarWidth}>
        <span style={avatarText}>GL</span>
      </Avatar>
    }
  />,
  <Resource
    key="glJv"
    name="glJv"
    options={{
      label: "Journal Voucher",
      parentName: "menuGl",
      permitName: "GL.Jv",
      headerTitle: "Voucher",
      endHeader: true,
    }}
    icon={() => <Icon>fact_check</Icon>}
    {...JournalVoucher}
  />,
  <Resource
    key="glJvFr"
    name="glJvFr"
    options={{
      label: "Template Voucher",
      parentName: "menuGl",
      permitName: "GL.StdJv",
      headerTitle: "Template",
    }}
    icon={() => <Icon>today</Icon>}
    {...StdVoucher}
  />,
  <Resource
    key="recurringJv"
    name="recurringJv"
    options={{
      label: "Recurring Voucher",
      parentName: "menuGl",
      permitName: "GL.RecurringStdJv",
    }}
    icon={() => <IoCalendarSharp size={21} />}
    {...RecurringVoucher}
  />,
  <Resource
    key="amortizeJv"
    name="amortizeJv"
    options={{
      label: "Amortization Voucher",
      parentName: "menuGl",
      permitName: "GL.AmortizationStdJv",
    }}
    icon={() => <IoAlbumsSharp size={21} />}
    {...AmortizationVoucher}
  />,
  <Resource
    key="allocateJv"
    name="allocateJv"
    options={{
      label: "Allocation Voucher",
      parentName: "menuGl",
      permitName: "GL.AllocationJv",
      endHeader: true,
    }}
    icon={() => <HiDatabase size={21} />}
    {...AllocationVoucher}
  />,
  <Resource
    key="accountSummary"
    name="accountSummary"
    options={{ label: "Account Summary", parentName: "menuGl" }}
    icon={() => <Icon>addchart</Icon>}
    {...AccountSummary}
  />,
  <Resource
    key="financialReport"
    name="financialReport"
    options={{ label: "Financial Report", parentName: "menuGl" }}
    icon={() => <Icon>article</Icon>}
    {...FinancialReport}
  />,
  <Resource
    key="chartOfAcc"
    name="accountCode"
    options={{
      label: "Chart of accounts",
      parentName: "menuGl",
      permitName: "GL.ChartOfAccount",
    }}
    icon={() => <Icon>view_list</Icon>}
    {...ChartOfAccount}
  />,
  <Resource
    key="budget"
    name="budget"
    options={{ label: "Budget", parentName: "menuGl", permitName: "GL.Budget" }}
    icon={() => <Icon>analytics</Icon>}
    {...Budget}
  />,
  <Resource
    key="menuProcedureGl"
    name="procedureGl"
    options={{ label: "Procedure", parentName: "menuGl" }}
    icon={ProcedureIcon}
    {...GlProcedure}
  />,
  <Resource
    key="reportGl"
    name="reportGl"
    options={{ label: "Report", parentName: "menuGl", groupName: "GL" }}
    icon={ReportIcon}
    {...Report}
  />,
  <Resource
    key="AP"
    name="menuAp"
    options={{ id: "AP", label: "Accounts Payable", IsSubmenu: true }}
    icon={
      <Avatar style={avartarWidth}>
        <span style={avatarText}>AP</span>
      </Avatar>
    }
  />,
  <Resource
    key="apVendor"
    name="apVendor"
    options={{ label: "Vendor", parentName: "menuAp", permitName: "AP.Vendor" }}
    icon={() => <Icon>supervised_user_circle</Icon>}
    {...ApVendor}
  />,
  <Resource
    key="apInvoice"
    name="apInvoice"
    options={{
      label: "Invoice",
      parentName: "menuAp",
      permitName: "AP.Invoice",
    }}
    icon={() => <Icon style={{ transform: "rotateY(180deg)" }}>sticky_note_2</Icon>}
    {...ApInvoice}
  />,
  <Resource
    key="apPayment"
    name="apPayment"
    options={{
      label: "Payment",
      parentName: "menuAp",
      permitName: "AP.Payment",
    }}
    icon={() => <Icon style={{ transform: "rotateY(180deg)" }}>view_sidebar</Icon>}
    {...ApPayment}
  />,
  <Resource
    key="menuProcedureAp"
    name="procedureAp"
    options={{ label: "Procedure", parentName: "menuAp" }}
    icon={ProcedureIcon}
    {...ApProcedure}
  />,
  <Resource
    key="reportAp"
    name="reportAp"
    options={{ label: "Report", parentName: "menuAp", groupName: "AP" }}
    icon={ReportIcon}
    {...Report}
  />,
  <Resource
    key="AR"
    name="menuAr"
    options={{ id: "AR", label: "Accounts Receivable", IsSubmenu: true }}
    icon={
      <Avatar style={avartarWidth}>
        <span style={avatarText}>AR</span>
      </Avatar>
    }
  />,
  <Resource
    key="arProfile"
    name="arProfile"
    options={{
      label: "A/R Profile",
      parentName: "menuAr",
      permitName: "AR.Profile",
    }}
    icon={() => <Icon>contact_page</Icon>}
    {...ArProfile}
  />,
  <Resource
    key="arFolio"
    name="arFolio"
    options={{ label: "Folio", parentName: "menuAr", permitName: "AR.Folio" }}
    icon={() => <Icon>shop_2</Icon>}
    {...ArFolio}
  />,
  <Resource
    key="arInvoice"
    name="arInvoice"
    options={{
      label: "Invoice",
      parentName: "menuAr",
      permitName: "AR.Invoice",
    }}
    icon={() => <Icon>shop</Icon>}
    {...ArInvoice}
  />,
  <Resource
    key="arReceipt"
    name="arReceipt"
    options={{
      label: "Receipt",
      parentName: "menuAr",
      permitName: "AR.Receipt",
    }}
    icon={() => <Icon>receipt</Icon>}
    {...ArReceipt}
  />,

  <Resource
    key="menuProcedureAr"
    name="procedureAr"
    options={{ label: "Procedure", parentName: "menuAr" }}
    icon={ProcedureIcon}
    {...ArProcedure}
  />,
  <Resource
    key="reportAr"
    name="reportAr"
    options={{ label: "Report", parentName: "menuAr", groupName: "AR" }}
    icon={ReportIcon}
    {...Report}
  />,
  <Resource
    key="AST"
    name="menuAsset"
    options={{ id: "AST", label: "Asset", IsSubmenu: true }}
    icon={
      <Avatar style={avartarWidth}>
        <span style={avatarText}>AS</span>
      </Avatar>
    }
  />,
  <Resource
    key="assetVendor"
    name="assetVendor"
    options={{
      label: "Vendor",
      parentName: "menuAsset",
      permitName: "Ast.Vendor",
    }}
    icon={() => <Icon>supervised_user_circle</Icon>}
    {...AssetVendor}
  />,
  <Resource
    key="preAsset"
    name="preAsset"
    options={{ label: "Pre-Register Asset", parentName: "menuAsset" }}
    icon={() => <Icon>web_asset</Icon>}
    {...PreAsset}
  />,
  <Resource
    key="assetRegister"
    name="assetRegister"
    options={{
      label: "Asset Register",
      parentName: "menuAsset",
      permitName: "Ast.Register",
    }}
    icon={() => <Icon>account_balance</Icon>}
    {...AssetRegister}
  />,
  <Resource
    key="assetDisposal"
    name="assetDisposal"
    options={{
      label: "Asset Disposal",
      parentName: "menuAsset",
      permitName: "Ast.Disposal",
    }}
    icon={() => <Icon>important_devices</Icon>}
    {...AssetDisposal}
  />,
  // <Resource
  //   key="assetImpairment"
  //   name="assetImpairment"
  //   options={{
  //     label: "Asset Impairment",
  //     parentName: "menuAsset",
  //     permitName: "Ast.Disposal",
  //   }}
  //   icon={() => <Icon>devices_other</Icon>}
  //   {...AssetImpairment}
  // />,
  <Resource
    key="menuProcedureAsset"
    name="procedureAsset"
    options={{ label: "Procedure", parentName: "menuAsset" }}
    icon={ProcedureIcon}
    {...AssetProcedure}
  />,
  <Resource
    key="reportAsset"
    name="reportAsset"
    options={{ label: "Report", parentName: "menuAsset", groupName: "ASSET" }}
    icon={ReportIcon}
    {...Report}
  />,
  // <Resource
  //   key="INC"
  //   name="menuIncome"
  //   options={{ id: "INC", label: "Income", IsSubmenu: true }}
  //   icon={
  //     <Avatar style={avartarWidth}>
  //       <span style={avatarText}>IC</span>
  //     </Avatar>
  //   }
  // />,
];

export default resources;
