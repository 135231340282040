import axiosAuth from "utils/request";
import { addDays, addYears } from "date-fns";

const formatDate = (date) => {
  date = date ?? new Date();

  const d = date.getDate().toString().padStart(2, "0");
  const m = (date.getMonth() + 1).toString().padStart(2, "0");
  const y = date.getFullYear();
  return `${y}-${m}-${d}`;
};

export async function getFrontSummary(fromDate, toDate, option) {
  fromDate = fromDate === null ? addDays(new Date(), -1) : new Date(fromDate);
  toDate = toDate === null ? addDays(new Date(), -1) : new Date(toDate);
  const fromFormatDate = fromDate === null ? formatDate(fromDate) : formatDate(fromDate);
  const toFormatDate = toDate === null ? formatDate(toDate) : formatDate(toDate);

  const spName = `widget_front_summary('${fromFormatDate}','${toFormatDate}','${option.Id}')`;
  const { data } = await axiosAuth.get(`/api/callStoreProc/${spName}`);

  if (!data) {
    return undefined;
  }

  const vnNameArr = [
    "Công suất phòng",
    "Giá phòng bình quân",
    "Doanh thu phòng trên mỗi phòng sẵn có",
    "Tổng doanh thu trên mỗi phòng sẵn có",
  ];

  //for vn database
  data.forEach((i, idx) => {
    if (localStorage.getItem("Language") === "vi-VN") {
      i.name = vnNameArr[idx];
    }
  });

  data[0].icon = "attach_money";
  data[1].icon = "bar_chart";
  data[2].icon = "insights";
  data[3].icon = "leaderboard";

  data[0].color = "#7BDFF2";
  data[1].color = "#C1FBA4";
  data[2].color = "#FBC3BC";
  data[3].color = "#E7C6FF";

  const res = {
    Id: option?.Id ?? 0,
    name: option?.Name ?? "Statistical Highlight",
    //date: data[0].date,
    data: data,
    configName: option?.Type ?? "Statistical Highlight",
    Seq: option ? option.Seq : 1,
    TypeOfDate: option.TypeOfDate,
    FromDate: fromDate,
    ToDate: toDate,
    Width: option?.Width ?? "100",
    Config: option?.Config ?? [],
  };
  return res;
}

export async function getDimensionAnalysis(fromDate, toDate, option) {
  fromDate = fromDate === null ? addDays(new Date(), -1) : new Date(fromDate);
  toDate = toDate === null ? addDays(new Date(), -1) : new Date(toDate);
  const fromFormatDate = fromDate === null ? formatDate(fromDate) : formatDate(fromDate);
  const toFormatDate = toDate === null ? formatDate(toDate) : formatDate(toDate);

  const spName = `widget_dimension_analysis('${fromFormatDate}','${toFormatDate}','${option.Id}')`;
  const { data } = await axiosAuth.get(`/api/callStoreProc/${spName}`);
  if (data !== null && data?.length > 0) {
    var rawData = data?.filter((i) => i.DimValue !== "" && i.DimValue !== null && i.Amount !== 0);

    var newArr = [["DimName", "Amount"]];

    rawData.forEach((element) => {
      newArr.push([element.DimValue, element.Amount]);
    });
  }

  const res = {
    Id: option?.Id ?? 0,
    name: option?.Name ?? "YTD - Room Revenue by Market Segment",
    //date: date,
    rawData: rawData ?? null,
    data: data?.length > 0 ? newArr : null,
    configName: option?.Type ?? "Dimension Analysis",
    Seq: option ? option.Seq : 2,
    TypeOfDate: option.TypeOfDate,
    FromDate: fromDate,
    ToDate: toDate,
    Width: option?.Width ?? "100",
    Config: option?.Config ?? [],
  };
  return res;
}

export async function getYearcomparision(fromDate, toDate, option) {
  fromDate = fromDate === null ? addYears(addDays(new Date(), -1), -1) : new Date(fromDate);
  toDate = toDate === null ? addDays(new Date(), -1) : new Date(toDate);
  let previousYear = fromDate.getFullYear();
  const thisYear = toDate.getFullYear();

  previousYear = previousYear === thisYear ? previousYear - 1 : previousYear;

  const spName = `widget_front_yearcomparision('${previousYear}','${thisYear}','${option.Id}')`;
  const { data } = await axiosAuth.get(`/api/callStoreProc/${spName}`);

  if (!data) {
    return undefined;
  }

  const arrLabel = Object.keys(data[0]).filter((e) => e !== "Year");
  const arrDataset0 = Object.values(data[0]).filter((e) => typeof e === "number");
  const arrDataset1 = Object.values(data[1]).filter((e) => typeof e === "number");
  const arrDataset2 = Object.values(data[2]).filter((e) => typeof e === "number");
  const newArr = [
    [
      "Month",
      localStorage.getItem("Language") === "vi-VN" ? "Năm trước" : "Previous Year",
      localStorage.getItem("Language") === "vi-VN" ? "Năm nay" : "This Year",
      localStorage.getItem("Language") === "vi-VN" ? "Ngân sách" : "Budget",
    ],
  ];

  arrLabel.forEach((element, idx) => {
    newArr.push([element, arrDataset0[idx], arrDataset1[idx], arrDataset2[idx]]);
  });

  const res = {
    Id: option?.Id ?? 0,
    name: option?.Name ?? "This Year VS Previous Year",
    data: newArr,
    configName: option?.Type ?? "Annual",
    Seq: option ? option.Seq : 3,
    FromDate: fromDate,
    ToDate: toDate,
    Width: option?.Width ?? "100",
    Config: option?.Config ?? [],
  };
  return res;
}

export async function getProfitSummary(fromDate, option) {
  fromDate = fromDate === null ? addDays(new Date(), -1) : new Date(fromDate);
  const month = new Date(fromDate).getMonth() + 1;
  const year = new Date(fromDate).getFullYear();
  const spName = `widget_pl_summary('${year}','${month}',${option.Id})`;
  const { data } = await axiosAuth.get(`/api/callStoreProc/${spName}`);

  if (!data) {
    return undefined;
  }

  const vnNameArr = ["Tổng doanh thu", "Tổng chi phí", "Tổng lợi nhuận", "Mục tiêu tháng"];

  //for vn database
  data.forEach((i, idx) => {
    if (localStorage.getItem("Language") === "vi-VN") {
      i.name = vnNameArr[idx];
    }
  });

  const res = {
    Id: option?.Id ?? 0,
    name: option?.Name ?? "Monthly P&L Summary",
    //date: fromDate,
    data: data,
    configName: option?.Type ?? "P&L Summary",
    Seq: option ? option.Seq : 4,
    TypeOfDate: option.TypeOfDate,
    FromDate: new Date(fromDate),
    ToDate: option.ToDate,
    Width: option?.Width ?? "100",
    Config: option?.Config ?? [],
  };

  return res;
}

export async function getDaily(fromDate, toDate, option) {
  fromDate = fromDate === null ? addDays(new Date(), -1) : new Date(fromDate);
  toDate = toDate === null ? addDays(new Date(), -1) : new Date(toDate);
  const fromFormatDate = fromDate === null ? formatDate(fromDate) : formatDate(fromDate);
  const toFormatDate = toDate === null ? formatDate(toDate) : formatDate(toDate);
  const spName = `widget_gl_dailyaccount('${fromFormatDate}','${toFormatDate}','${option.Id}')`;
  const { data } = await axiosAuth.get(`/api/callStoreProc/${spName}`);

  const res = {
    Id: option?.Id ?? 0,
    name: option?.Name ?? "Daily Monitor Account",
    //date: date,
    data: data === "" ? [] : data,
    configName: option?.Type ?? "Daily Monitor Account",
    Seq: option ? option.Seq : 5,
    TypeOfDate: option.TypeOfDate,
    FromDate: fromDate,
    ToDate: toDate,
    Width: option?.Width ?? "100",
    Config: option?.Config ?? [],
  };
  return res;
}

export async function getActualbudget(fromDate, toDate, option) {
  fromDate = fromDate === null ? addDays(new Date(), -1) : new Date(fromDate);
  toDate = toDate === null ? addDays(new Date(), -1) : new Date(toDate);
  const fromFormatDate = fromDate === null ? formatDate(fromDate) : formatDate(fromDate);
  const toFormatDate = toDate === null ? formatDate(toDate) : formatDate(toDate);

  const spName = `widget_actualbudget_new('${fromFormatDate}','${toFormatDate}','${option.Id}')`;
  const { data } = await axiosAuth.get(`/api/callStoreProc/${spName}`);

  if (!data) {
    return undefined;
  }

  const res = {
    Id: option?.Id ?? 0,
    name: option?.Name ?? "Actual vs Budget",
    //date: date,
    data: data === "" ? [] : data,
    configName: option?.Type ?? "ActualBudget",
    Seq: option ? option.Seq : 6,
    TypeOfDate: option.TypeOfDate,
    FromDate: fromDate,
    ToDate: toDate,
    Width: option?.Width ?? "100",
    Config: option?.Config ?? [],
  };
  return res;
}

export async function getPayable(fromDate, option) {
  let d;
  if (!fromDate) {
    fromDate = fromDate === null ? formatDate(addDays(new Date(), -1)) : formatDate(new Date(fromDate));
    d = {
      Undue: 0,
      Overdue: 0,
      Current: 0,
      Age30: 0,
      Age60: 0,
      Age90: 0,
      Age91: 0,
    };

    const newArr = [["Key", "Undue", "Unpaid"]];
    newArr.push(["Payable", d.Undue, d.Overdue]);

    const newArr2 = [["Key", "Current", "30", "60", "90", "91"]];
    newArr2.push(["Payable", d.Current, d.Age30, d.Age60, d.Age90, d.Age91]);

    return {
      Id: option?.Id ?? 0,
      name: option?.Name ?? "Payable",
      //date: new Date(),
      totalUnPaid: d.Unpaid,
      data1: newArr,
      colors1: ["#B8B5FF", "#7868E6"],
      data2: newArr2,
      colors2: ["#b0efeb", "#118ab2", "#ef476f", "#ffd166", "#06d6a0"],
      rawData: d,
      configName: option?.Type ?? "Payable",
      Seq: option ? option.Seq : 7,
      FromDate: fromDate,
      Width: option?.Width ?? "50",
      Config: option?.Config ?? [],
    };
  }

  fromDate = fromDate === null ? formatDate(addDays(new Date(), -1)) : formatDate(new Date(fromDate));
  const spName = `widget_payable('${fromDate}','${option.Id}')`;
  let { data } = await axiosAuth.get(`/api/callStoreProc/${spName}`);

  if (!data) {
    return undefined;
  }
  if (data == null) {
    data = [];
    d = [
      {
        Undue: 0,
        Overdue: 0,
        Current: 0,
        Age30: 0,
        Age60: 0,
        Age90: 0,
        Age91: 0,
      },
    ];
  } else {
    d = data[0];
  }

  const newArr = [["Key", "Undue", "Unpaid"]];
  newArr.push(["Payable", d.Undue, d.Overdue]);

  const newArr2 = [["Key", "Current", "30", "60", "90", "91"]];
  newArr2.push(["Payable", d.Current, d.Age30, d.Age60, d.Age90, d.Age91]);

  const res = {
    Id: option?.Id ?? 0,
    name: option?.Name ?? "Payable",
    // date: date,
    totalUnPaid: d.Unpaid,
    data1: newArr,
    colors1: ["#B8B5FF", "#7868E6"],
    data2: newArr2,
    colors2: ["#b0efeb", "#118ab2", "#ef476f", "#ffd166", "#06d6a0"],
    rawData: d,
    configName: option?.Type ?? "Payable",
    Seq: option ? option.Seq : 7,
    FromDate: fromDate,
    Width: option?.Width ?? "50",
    Config: option?.Config ?? [],
  };

  return res;
}

export async function getReceivable(fromDate, option) {
  let d;
  if (!fromDate) {
    fromDate = fromDate === null ? formatDate(addDays(new Date(), -1)) : formatDate(new Date(fromDate));
    d = {
      Undue: 0,
      Overdue: 0,
      Current: 0,
      Age30: 0,
      Age60: 0,
      Age90: 0,
      Age91: 0,
    };

    const newArr = [["Key", "Undue", "Unpaid"]];
    newArr.push(["Receivable", d.Undue, d.Overdue]);

    const newArr2 = [["Key", "Current", "30", "60", "90", "91"]];
    newArr2.push(["Receivable", d.Current, d.Age30, d.Age60, d.Age90, d.Age91]);

    return {
      Id: option?.Id ?? 0,
      name: option?.Name ?? "Receivable",
      //date: new Date(),
      totalUnPaid: d?.Unpaid ?? 0,
      data1: newArr,
      colors1: ["#FF99B9", "#FF4782"],
      data2: newArr2,
      colors2: ["#b0efeb", "#118ab2", "#ef476f", "#ffd166", "#06d6a0"],
      rawData: d,
      configName: "Receivable",
      Seq: option ? option.Seq : 8,
      FromDate: fromDate,
      Width: option?.Width ?? "50",
      Config: option?.Config ?? [],
    };
  }

  fromDate = fromDate === null ? formatDate(addDays(new Date(), -1)) : formatDate(new Date(fromDate));
  const spName = `widget_receivable('${fromDate}','${option.Id}')`;
  let { data } = await axiosAuth.get(`/api/callStoreProc/${spName}`);

  if (!data) {
    return undefined;
  }
  if (data == null) {
    data = [];
    d = [
      {
        Undue: 0,
        Overdue: 0,
        Current: 0,
        Age30: 0,
        Age60: 0,
        Age90: 0,
        Age91: 0,
      },
    ];
  } else {
    d = data[0];
  }

  const newArr = [["Key", "Undue", "Unpaid"]];
  newArr.push(["Receivable", d.Undue, d.Overdue]);

  const newArr2 = [["Key", "Current", "30", "60", "90", "91"]];
  newArr2.push(["Receivable", d.Current, d.Age30, d.Age60, d.Age90, d.Age91]);

  const res = {
    Id: option?.Id ?? 0,
    name: option?.Name ?? "Receivable",
    //date: date,
    totalUnPaid: d?.Unpaid,
    data1: newArr,
    colors1: ["#FF99B9", "#FF4782"],
    data2: newArr2,
    colors2: ["#b0efeb", "#118ab2", "#ef476f", "#ffd166", "#06d6a0"],
    rawData: d,
    configName: option?.Type ?? "Receivable",
    Seq: option ? option.Seq : 8,
    FromDate: fromDate,
    Width: option?.Width ?? "50",
    Config: option?.Config ?? [],
  };

  return res;
}

export async function getIncomeStatement(fromDate, option) {
  if (!fromDate) {
    fromDate = addDays(new Date(), -1);
    const month = fromDate.getMonth() + 1;
    const year = fromDate.getFullYear();
    return {
      Id: option?.Id ?? 0,
      name: option?.Name ?? "Income Statement",
      //date: date,
      data: [
        {
          Id: 1,
          Name: "Revenue",
          MTD: 0.0,
          YTD: 0.0,
          YTD_LYear: 0.0,
          YTDRate: 0.0,
          Budget: 0.0,
          BudgetRate: 0.0,
          Year: year,
          Month: month,
          DeptCode: "GEN",
          DeptDesc: "GENARAL",
        },
        {
          Id: 2,
          Name: "Cost of Sale",
          MTD: 0.0,
          YTD: 0.0,
          YTD_LYear: 0.0,
          YTDRate: 0.0,
          Budget: 0.0,
          BudgetRate: 0.0,
          Year: year,
          Month: month,
          DeptCode: "GEN",
          DeptDesc: "GENARAL",
        },
        {
          Id: 3,
          Name: "Operational Expense",
          MTD: 0.0,
          YTD: 0.0,
          YTD_LYear: 0.0,
          YTDRate: 0.0,
          Budget: 0.0,
          BudgetRate: 0.0,
          Year: year,
          Month: month,
          DeptCode: "GEN",
          DeptDesc: "GENARAL",
        },
        {
          Id: 4,
          Name: "Non-Operational Expense",
          MTD: 0.0,
          YTD: 0.0,
          YTD_LYear: 0.0,
          YTDRate: 0.0,
          Budget: 0.0,
          BudgetRate: 0.0,
          Year: year,
          Month: month,
          DeptCode: "GEN",
          DeptDesc: "GENARAL",
        },
        {
          Id: 5,
          Name: "Net Profit",
          MTD: 0.0,
          YTD: 0.0,
          YTD_LYear: 0.0,
          YTDRate: 0.0,
          Budget: 0.0,
          BudgetRate: 0.0,
          Year: year,
          Month: month,
          DeptCode: "GEN",
          DeptDesc: "GENARAL",
        },
      ],
      configName: option?.Type ?? "Income Statement",
      Seq: option ? option.Seq : 9,
      FromDate: fromDate,
      type: "list",
      Width: option?.Width ?? "100",
      Config: option?.Config ?? [],
    };
  }

  fromDate = fromDate === null ? addDays(new Date(), -1) : new Date(fromDate);
  const month = new Date(fromDate).getMonth() + 1;
  const year = new Date(fromDate).getFullYear();

  const spName = `widget_incomestatement('${year}','${month}','${option.Id}')`;
  const { data } = await axiosAuth.get(`/api/callStoreProc/${spName}`);

  if (!data) {
    return undefined;
  }

  const res = {
    Id: option?.Id ?? 0,
    name: option?.Name ?? "Income Statement",
    //date: date,
    data: data,
    configName: option?.Type ?? "Income Statement",
    Seq: option ? option.Seq : 9,
    FromDate: new Date(fromDate),
    Width: option?.Width ?? "100",
    Config: option?.Config ?? [],
  };
  return res;
}

export async function getBudgetTrend(fromDate, toDate, option) {
  fromDate = fromDate === null ? addYears(addDays(new Date(), -1), -4) : new Date(fromDate);
  toDate = toDate === null ? addDays(new Date(), -1) : new Date(toDate);
  const fromYear = fromDate.getFullYear();
  const toYear = toDate.getFullYear();

  const spName = `widget_gl_budgettrend('${fromYear}','${toYear}','${option.Id}')`;
  const { data } = await axiosAuth.get(`/api/callStoreProc/${spName}`);
  if (!data) {
    return undefined;
  }

  const newArr = [
    [
      "Date",
      localStorage.getItem("Language") === "vi-VN" ? "Thực tế" : "Actual",
      localStorage.getItem("Language") === "vi-VN" ? "Ngân sách" : "Budget",
    ],
  ];

  for (let y = fromYear; y <= toYear; y++) {
    const arrActualOfYear = data.filter((i) => i.Name === "Actual" && i.Year === y);
    const arrBudgetOfYear = data.filter((i) => i.Name === "Budget" && i.Year === y);
    const arrDataset0 = Object.values(arrActualOfYear[0]).filter((e) => typeof e === "number");
    const arrDataset1 = Object.values(arrBudgetOfYear[0]).filter((e) => typeof e === "number");
    arrDataset0.shift();
    arrDataset1.shift();
    for (let m = 1; m < 13; m++) {
      newArr.push([new Date(`${y}-${m}-01`), arrDataset0[m - 1], arrDataset1[m - 1]]);
    }
  }

  const res = {
    Id: option?.Id ?? 0,
    name: option?.Name ?? "5 Year Trends",
    data: newArr,
    configName: option?.Type ?? "Budget Trend",
    Seq: option ? option.Seq : 3,
    FromDate: fromDate,
    ToDate: toDate,
    Width: option?.Width ?? "100",
    Config: option?.Config ?? [],
  };

  return res;
}

export async function getTypeOfWidget() {
  const { data } = await axiosAuth.get("/api/callStoreProc/get_typeof_widget");
  return data;
}

export async function getListGlPeriod() {
  const { data } = await axiosAuth.get("/api/callStoreProc/rptGetlistglPeriod");
  return data;
}

export async function getListApPeriod() {
  const { data } = await axiosAuth.get("/api/callStoreProc/rptGetListApPeriod");
  return data;
}

export async function getPrefixListWithSp() {
  const { data } = await axiosAuth.get("/api/callStoreProc/rptGetListGlPrefix");
  return data;
}
export async function getAccCodeListWithSp() {
  const { data } = await axiosAuth.get("/api/callStoreProc/rptGetListAccountCode");
  return data;
}

export async function getDeptCodeListWithSp() {
  const { data } = await axiosAuth.get("/api/callStoreProc/rptGetListDepartment");
  return data;
}

export async function getDimensionWithSp() {
  const { data } = await axiosAuth.get("/api/callStoreProc/rptGetListDimension");
  return data;
}

export async function getDashboard() {
  const { data } = await axiosAuth.get("/api/dashboards");
  return data;
}

export async function getDashboardById(id) {
  const { data } = await axiosAuth.get(`/api/dashboards/${id}`);
  return data;
}

export async function addDashboard(param) {
  const { data } = await axiosAuth.post("/api/dashboards", param);
  return data;
}

export async function updateDashboard(param) {
  const { data } = await axiosAuth.put("/api/dashboards", param);
  return data;
}

export async function deleteDashboard(id) {
  const { data } = await axiosAuth.delete(`/api/dashboards/${id}`);
  return data;
}

export async function getWidgetByDashboardId(id) {
  const { data } = await axiosAuth.get(`/api/widgetsByDashboardId/${id}`);
  return data;
}

export async function updateWidgetByDashboardId(id, param) {
  const { data } = await axiosAuth.patch(`/api/widgetsByDashboardId/${id}`, param);
  return data;
}

export async function deleteWidgetById(id) {
  const { data } = await axiosAuth.delete(`/api/widgetsById/${id}`);
  return data;
}

export async function getConfigDashboardByName(name) {
  const { data } = await axiosAuth.get(`/api/dashboard/?idOrName=${name}`);
  return data;
}

export async function updateConfigDashboard(param) {
  const { data } = await axiosAuth.post("/api/callStoreProc", param);
  return data;
}

export async function getApWhtReconExpSp(period, id) {
  const { data } = await axiosAuth.get(`/api/callStoreProc/rptFormApWhtReconExp('${period}','${id}')`);
  return data;
}

export async function postApWhtReconExpSp(period, id) {
  const { data } = await axiosAuth.post(`/api/callStoreProc/`, {
    SpName: "rptFormApWhtReconExp",
    Params: {
      period: period,
      id: id,
    },
    Timeout: 0,
  });
  return data;
}

export async function getReportAccountDetail(accFrom, accTo = accFrom, deptFrom, deptTo, pfForm, pfTo, from, to) {
  const { data } = await axiosAuth.get(
    `/api/callStoreProc/rptGlAccountDetail('${from}','${to}','${deptFrom}','${deptTo}','${accFrom}','${accTo}','${pfForm}','${pfTo}','','ZZZ','Active','Date','D','False')`
  );

  return data;
}

export async function getReportTrialBalance(accFrom, accTo = accFrom, from, to) {
  const { data } = await axiosAuth.get(
    `/api/callStoreProc/rptGlTrialBalance('${from}','${to}','','','${accFrom}','${accTo}','AccDesc','A','Active','None')`
  );
  return data === "" ? [] : data;
}

export async function showReportPDF(param) {
  const { data, status } = await axiosAuth.post("api/report/web", param, {
    responseType: "blob",
  });
  if (status === 400) {
    return "No Data";
  }

  return data;
}

export async function callStoreReport(name, param) {
  const body = {
    SpName: name,
    Params: param ?? {},
    Timeout: 0,
  };

  const { data } = await axiosAuth.post("api/CallStoreProc", body);
  return data;
}

export async function callStoreDirect(fieldNames, module, listOfSeq) {
  var param = {};
  if (module === "AP_Invoice") {
    param = {
      ViewName: "vapinvoice",
      FieldNames: fieldNames.join(),
      Clause: `where invhseq in (${listOfSeq})`,
    };
  }
  const body = {
    SpName: "spGetViewByFields",
    Params: param ?? {},
    Timeout: 0,
  };

  const { data } = await axiosAuth.post("api/CallStoreProc", body);
  return data;
}

export default {
  getFrontSummary,
  getDimensionAnalysis,
  getYearcomparision,
  getProfitSummary,
  getDaily,
  getActualbudget,
  getPayable,
  getReceivable,
  getIncomeStatement,
  getBudgetTrend,
  updateWidgetByDashboardId,
};
