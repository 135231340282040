import axiosAuth from "utils/request";

//---------------------------- List ----------------------------//

export async function getJvSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/glJv/search", uriQueryString);
  return data;
}

export async function getAllocationJvSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/allocationJv/search", uriQueryString);
  return data;
}

export async function getAmortizeJvSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/amortizeStdJv/search", uriQueryString);
  return data;
}

export async function getAmortizeHistory(Id) {
  const { data } = await axiosAuth.get(`/api/amortizeHistory/${Id}`);
  return data;
}

export async function getJvFrSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/glJvFr/search", uriQueryString);
  return data;
}

export async function getBudgetList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/budget/search", uriQueryString);
  return data;
}

export async function getGlPrefix() {
  const { data } = await axiosAuth.get("/api/glPrefix");
  return data;
}

export async function getGlPrefixSearchList(uriQueryString) {
  const { data } = await axiosAuth.post("/api/glPrefix/search", uriQueryString);
  return data;
}

//---------------------------- Detail ----------------------------//

export async function getGlPrefixDetail(Id) {
  const { data } = await axiosAuth.get(`/api/glPrefix/${Id}`);
  return data;
}

export async function createGlPrefixDetail(param) {
  const { data } = await axiosAuth.post("/api/glPrefix", param);
  return data;
}

export async function updateGlPrefixDetail(param) {
  const { data } = await axiosAuth.put(`/api/glPrefix/${param.Id}`, param);
  return data;
}

export async function delGlPrefixDetail(Id) {
  const { data } = await axiosAuth.delete(`/api/glPrefix/${Id}`);
  return data;
}

export async function getJvDetail(JvhSeq) {
  const { data } = await axiosAuth.get(`/api/glJv/${JvhSeq}`);
  if (data?.Detail?.length > 0) {
    data.Detail.forEach((i, idx) => (i.index = idx));
  }
  return data;
}

export async function createJvDetail(param) {
  const { data } = await axiosAuth.post("/api/glJv", param);
  return data;
}

export async function updateJvDetail(param) {
  const { data } = await axiosAuth.put(`/api/glJv/${param.JvhSeq}`, param);
  return data;
}

export async function delJvDetail(JvhSeq, username, remark) {
  const { data } = await axiosAuth.delete(`/api/glJv/${JvhSeq}?user=${username}&voidRemark=${remark}`);
  return data;
}

export async function getAllocationJvDetail(AJvhSeq) {
  const { data } = await axiosAuth.get(`/api/allocationJv/${AJvhSeq}`);
  if (data?.Detail?.length > 0) {
    data.Detail.forEach((i, idx) => (i.index = idx));
  }
  return data;
}

export async function createAllocationJvDetail(param) {
  const { data } = await axiosAuth.post("/api/allocationJv", param);
  return data;
}

export async function updateAllocationJvDetail(param) {
  const { data } = await axiosAuth.put(`/api/allocationJv/${param.AJvhSeq}`, param);
  return data;
}

export async function delAllocationJvDetail(AJvhSeq, username, remark) {
  const { data } = await axiosAuth.delete(`/api/allocationJv/${AJvhSeq}?user=${username}&voidRemark=${remark}`);
  return data;
}

export async function getAmortizeStdJvDetail(FJvhSeq) {
  const { data } = await axiosAuth.get(`/api/amortizeStdJv/${FJvhSeq}`);
  if (data?.Detail?.length > 0) {
    data.Detail.forEach((i, idx) => (i.index = idx));
  }
  return data;
}

export async function createAmortizeStdJvDetail(param) {
  const { data } = await axiosAuth.post("/api/amortizeStdJv", param);
  return data;
}

export async function updateAmortizeStdJvDetail(param) {
  const { data } = await axiosAuth.put(`/api/amortizeStdJv/${param.FJvhSeq}`, param);
  return data;
}

export async function delAmortizeStdJvDetail(FJvhSeq) {
  const { data } = await axiosAuth.delete(`/api/amortizeStdJv/${FJvhSeq}`);
  return data;
}

export async function getJvFrDetail(FJvhSeq) {
  const { data } = await axiosAuth.get(`/api/glJvFr/${FJvhSeq}`);
  if (data?.Detail?.length > 0) {
    data.Detail.forEach((i, idx) => (i.index = idx));
  }
  return data;
}

export async function createJvFrDetail(param) {
  const { data } = await axiosAuth.post("/api/glJvFr", param);
  return data;
}

export async function updateJvFrDetail(param) {
  const { data } = await axiosAuth.put(`/api/glJvFr/${param.FJvhSeq}`, param);
  return data;
}

export async function delJvFrDetail(FJvhSeq) {
  const { data } = await axiosAuth.delete(`/api/glJvFr/${FJvhSeq}`);
  return data;
}

export async function getBudgetDetail(BudgetId) {
  const { data } = await axiosAuth.get(`/api/budget/${BudgetId}`);
  return data;
}

export async function createBudgetDetail(param) {
  const { data } = await axiosAuth.post("/api/budget", param);
  return data;
}

export async function updateBudgetDetail(param) {
  const { data } = await axiosAuth.put(`/api/budget/${param.BudgetId}`, param);
  return data;
}

export async function delBudgetDetail(BudgetId) {
  const { data } = await axiosAuth.delete(`/api/budget/${BudgetId}`);
  return data;
}
