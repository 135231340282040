/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useForm } from "react-hook-form";
import { getAccountCodeList, getDepartmentList } from "services/setting";
import { uploadFileInfPostGl, postInfPostGL } from "services/interface";
import DialogMapping from "../Dialog/DialogMapping";
import { Box, Button, Typography, CircularProgress } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DatePickerFormat from "components/DatePickerFormat";
import { MuiAutosuggest, DescInForm } from "components/Form";
import ErrorTableDialog from "components/ErrorTable";
import fileReader from "utils/fileReader";
import SnackbarUtils from "utils/SnackbarUtils";
import ButtonUpload from "components/ButtonUpload";
import gbl from "utils/formatter";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CardItem(props) {
  const translate = useTranslate();
  const classes = useStyles();
  const { ToMySqlDate } = useContext(GblContext);
  const [loading, setLoading] = useState(false);
  const [lookupList, setLookupList] = useState({
    accountCodeListRE: [],
    accountCodeListMA: [],
    accountCodeListST: [],
    departmentList: [],
  });
  const [idForPost, setIdForPost] = useState();
  const [filterDate, setFilterDate] = useState({
    from: new Date(),
    to: new Date(),
  });
  const [expanded, setExpanded] = useState("panel1");
  const [configuration, setConfiguration] = useStateWithCallbackLazy();
  const [docType, setDocType] = useState();
  const [postType, setPostType] = useState();
  const [fileNameRevenue, setFileNameRevenue] = useState();
  const [fileNameMarketSegment, setFileNameMarketSegment] = useState();
  const [fileNameStatistic, setFileNameStatistic] = useState();
  const [fileArray, setFileArray] = useState({
    Revenue: [],
    MarketSegment: [],
    Statistic: [],
  });
  const [errorTable, setErrorTable] = useState(false);
  const [showErrorTable, setShowErrorTable] = useState(false);
  const [oldAccList, setOldAccList] = useState([]);
  const [openMapping, setOpenMapping] = useState(false);
  const methods = useForm({ defaultValues: configuration });

  const { reset } = methods;

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const fetchAccLookup = async () => {
    const { Data } = await getAccountCodeList("Gl");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
      accountCodeListRE: Data,
      accountCodeListMA: Data,
      accountCodeListST: Data,
    }));
    setOldAccList(Data);
  };

  const fetchDeptLookup = async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  };

  const fetchSetting = () => {
    const r = props.setting;
    if (r) {
      setPostType(r.PostType);
      setConfiguration(r.Configuration ?? false);
      reset(r.Configuration);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    fetchAccLookup();
    fetchDeptLookup();
  }, []);

  useEffect(() => {
    fetchSetting();
  }, []);

  const uploadFile = async (e) => {
    if (e.target.files.length >= 1) {
      let msg = fileReader.CheckLimitImgSize(e.target.files);
      if (msg) {
        document.getElementById("fileTxt1").value = "";
        document.getElementById("fileTxt2").value = "";
        document.getElementById("fileTxt3").value = "";
        SnackbarUtils.error(translate(msg, { size: "5" }));
        return;
      }
      let files = Array.from(e.target.files);
      const filePathsPromises = [];
      files.forEach((file) => {
        filePathsPromises.push(fileReader.ToBase64(file));
      });
      var param = [];
      var txtArray = await Promise.all(filePathsPromises);
      txtArray.forEach((item, idx) => {
        param.push({
          FileName: files[idx].name,
          FileBase64: gbl.UnicodeToBase64(item),
        });
      });
      if (e.target.id === "fileTxt1") {
        let strName = param.map((i) => i.FileName).join(", ");
        setFileNameRevenue(strName);
        setFileArray((state) => ({
          ...state,
          Revenue: param,
        }));
        apiUploadFile(props.id, "REVENUE", param);
      }
      if (e.target.id === "fileTxt2") {
        let strName = param.map((i) => i.FileName).join(", ");
        setFileNameMarketSegment(strName);
        setFileArray((state) => ({
          ...state,
          MarketSegment: param,
        }));
        apiUploadFile(props.id, "MARKETSEGMENT", param);
      }
      if (e.target.id === "fileTxt3") {
        let strName = param.map((i) => i.FileName).join(", ");
        setFileNameStatistic(strName);
        setFileArray((state) => ({
          ...state,
          Statistic: param,
        }));
        apiUploadFile(props.id, "STATISTIC", param);
      }
    } else {
      if (e.target.id === "fileTxt1") {
        document.getElementById("fileTxt1").value = "";
      }
      if (e.target.id === "fileTxt2") {
        document.getElementById("fileTxt2").value = "";
      }
      if (e.target.id === "fileTxt3") {
        document.getElementById("fileTxt3").value = "";
      }
    }
  };

  const apiUploadFile = async (id, type, file) => {
    setLoading(true);
    if (file.length > 0) {
      let r = await uploadFileInfPostGl(id, type, file);
      if (r) {
        setIdForPost(r.id);
        if (r.Code) {
          SnackbarUtils.error(r.UserMessage);
          setLoading(false);
          return;
        }
        setFilterDate({
          from: new Date(r.DateFrom),
          to: new Date(r.DateTo),
        });
        setLoading(false);
        // SnackbarUtils.success(
        //   `Upload data from ${ToMySqlDate(new Date(r.DateFrom))} to ${ToMySqlDate(new Date(r.DateTo))}`
        // );
      }
    } else {
      setLoading(false);
      SnackbarUtils.warning("File Not Found");
    }
  };

  const CheckResultShowMessage = async ({ Code, InternalMessage, UserMessage, ErrorList }) => {
    if (Code === null) {
      SnackbarUtils.warning("File Not Found");
    }
    if (Code === 0 || Code === 1) {
      SnackbarUtils.success(InternalMessage);
    }
    if (Code === -1) {
      if (ErrorList?.length > 0) {
        var listError = [];
        if (ErrorList[0].Message) {
          ErrorList.forEach((i) => {
            i.Message = i.Message.concat("</br>");
            listError.push(Object.values(i).join("</br>"));
          });
          if (listError) {
            SnackbarUtils.warning(listError.join(""));
          }
        } else {
          SnackbarUtils.warning(InternalMessage);
          if (ErrorList.length > 0) {
            setErrorTable(ErrorList);
            setShowErrorTable(true);
          }
        }
      } else {
        SnackbarUtils.warning(InternalMessage);
      }
    }
    if (Code === -204) {
      SnackbarUtils.warning(UserMessage);
    }
    if (Code === -400) {
      SnackbarUtils.warning(UserMessage);
    }
    if (Code === -500) {
      SnackbarUtils.warning(UserMessage, function () {
        setOpenMapping(true);
      });
    }
    if (Code === -2146233079) {
      SnackbarUtils.error(UserMessage);
    }

    setLoading(false);
  };

  const post = async (id, type, file, config) => {
    setLoading(true);
    if (postType === "Json") {
      let param = {
        id: idForPost,
        Code: id,
        DateFrom: ToMySqlDate(filterDate.from),
        DateTo: ToMySqlDate(filterDate.to),
        DeptCode: config.DeptCode,
        AccCode: config.AccCode,
      };
      setDocType(type);
      let r = await postInfPostGL(type, param, false);
      if (r.Code === -200) {
        let msg = "Data already post. Do you want to repost ?";
        SnackbarUtils.loadingConfirm(
          msg,
          async function () {
            const rr = await postInfPostGL(type, param, true);
            CheckResultShowMessage(rr);
          },
          null
        );
      }
      CheckResultShowMessage(r);
    }

    if (postType !== "Json" && file.length > 0) {
      let param = {
        id: idForPost,
        Code: id,
        DateFrom: ToMySqlDate(filterDate.from),
        DateTo: ToMySqlDate(filterDate.to),
        DeptCode: config.DeptCode,
        AccCode: config.AccCode,
      };
      setDocType(type);
      let r = await postInfPostGL(type, param, false);
      if (r.Code === -200) {
        let msg = "Data already post. Do you want to repost ?";
        SnackbarUtils.loadingConfirm(
          msg,
          async function () {
            const rr = await postInfPostGL(type, param, true);
            CheckResultShowMessage(rr);
          },
          null
        );
      }
      CheckResultShowMessage(r);
    }
  };

  const LoadingButton = ({ text, disabled, fnc, defaultColor }) => {
    return (
      <div className={classes.wrapper}>
        <Button
          variant={defaultColor ? "outlined" : "contained"}
          color={defaultColor ? defaultColor : "primary"}
          disabled={disabled}
          onClick={fnc}
        >
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <div>
      {configuration && (
        <form onKeyDown={disableFormEnter}>
          {/* {postType !== "Json" && configuration?.Revenue?.ConfigBase64 ? (
            <Box p={1} align="left" style={{ paddingLeft: 0 }}>
              <DatePickerFormat
                label={translate("ra.field.From")}
                value={filterDate.from}
                style={{ width: 160, marginRight: "10px" }}
                disabled={true}
              />
              <DatePickerFormat
                label={translate("ra.field.To")}
                value={filterDate.to}
                minDate={filterDate.from}
                minDateMessage={"Date must be more than from date"}
                style={{ width: 160 }}
                disabled={true}
              />
            </Box>
          ) : (
            ""
          )} */}
          {postType === "Json" && configuration?.Revenue ? (
            <Box p={1} align="left" style={{ paddingLeft: 0 }}>
              <DatePickerFormat
                label="From"
                value={filterDate.from}
                onChange={(e) => {
                  setFilterDate((state) => ({
                    ...state,
                    from: e,
                    to: e > filterDate.to ? e : filterDate.to,
                  }));
                }}
                style={{ width: 160, marginRight: "10px" }}
              />
              <DatePickerFormat
                label="To"
                value={filterDate.to}
                onChange={(e) => {
                  setFilterDate((state) => ({
                    ...state,
                    to: e,
                  }));
                }}
                minDate={filterDate.from}
                minDateMessage={"Date must be more than from date"}
                style={{ width: 160 }}
              />
            </Box>
          ) : (
            ""
          )}
          <div>
            {configuration?.Revenue.Active && (
              <Accordion
                style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="rev-content" id="rev-header">
                  <Typography variant="h6">{translate("ra.field.Revenue")}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ paddingTop: 0, flexDirection: "column" }}>
                  {postType !== "Json" && (
                    <Box p={1} display="flex" style={{ padding: 0 }}>
                      <Box p={1} flexGrow={1}>
                        <ButtonUpload
                          id="fileTxt1"
                          name="fileTxt1"
                          style={{ display: "none" }}
                          type="file"
                          onChange={uploadFile}
                          accept=".csv,.txt,application/JSON,(*.*)"
                          multiple
                          fileUpload={fileNameRevenue}
                          cancelUpload={() => {
                            setFileNameRevenue();
                            setFileArray((state) => ({
                              ...state,
                              Revenue: [],
                            }));
                            document.getElementById("fileTxt1").value = "";
                          }}
                        />
                      </Box>
                      {/* <Box p={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => apiUploadFile(props.id, "REVENUE", fileArray.Revenue)}
                        >
                          Upload
                        </Button>
                      </Box> */}
                    </Box>
                  )}
                  {/* {postType !== "Json" && (
                    <Box p={1} display="flex">
                      <Box p={1} flexGrow={1}>
                        <ButtonUpload
                          id="fileTxt1"
                          name="fileTxt1"
                          style={{ display: "none" }}
                          type="file"
                          onChange={uploadFile}
                          accept=".csv,.txt,application/JSON,(*.*)"
                          multiple
                          fileUpload={fileNameRevenue}
                          cancelUpload={() => {
                            setFileNameRevenue();
                            setFileArray((state) => ({
                              ...state,
                              Revenue: [],
                            }));
                            document.getElementById("fileTxt1").value = "";
                          }}
                        />
                      </Box>
                    </Box>
                  )} */}
                  <Box p={1} align="left">
                    <Typography variant="body2">
                      <b>Select Balance Account</b>
                    </Typography>
                  </Box>
                  <Box p={1} align="center" style={{ padding: 0 }}>
                    <Box p={1} display="flex">
                      <MuiAutosuggest
                        label={translate("ra.field.Department")}
                        name="Revenue.DeptCode"
                        optKey="DeptCode"
                        optDesc="Description"
                        options={lookupList["departmentList"]}
                        updateOtherField={[{ key: "Revenue.DeptDesc", optKey: "Description" }]}
                        useFncUpdate={true}
                        fncUpdate={(value, methods) => {
                          let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                          if (daccList?.length > 0) {
                            setLookupList((state) => ({
                              ...state,
                              accountCodeListRE: daccList,
                            }));
                            //remove acc if not in defaultaccount
                            let acc = methods.getValues("Revenue.AccCode");
                            if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                              methods.setValue("Revenue.AccCode", "");
                              methods.setValue("Revenue.AccDesc", "");
                            }
                          } else {
                            setLookupList((state) => ({
                              ...state,
                              accountCodeListRE: oldAccList,
                            }));
                          }
                        }}
                        methods={methods}
                        style={{ width: 200 }}
                      />
                      <DescInForm
                        style={{ paddingLeft: 20 }}
                        name="Revenue.DeptDesc"
                        methods={methods}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                    <Box p={1} display="flex">
                      <MuiAutosuggest
                        label={translate("ra.field.Account")}
                        name="Revenue.AccCode"
                        optKey="AccCode"
                        optDesc={localStorage.getItem("Language") === "en-US" ? "Description" : "Description2"}
                        options={lookupList["accountCodeListRE"]}
                        updateOtherField={[
                          {
                            key: "Revenue.AccDesc",
                            optKey: localStorage.getItem("Language") === "en-US" ? "Description" : "Description2",
                          },
                        ]}
                        methods={methods}
                        style={{ width: 200 }}
                      />
                      <DescInForm
                        style={{ paddingLeft: 20 }}
                        name="Revenue.AccDesc"
                        methods={methods}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                  </Box>
                  {props.id !== "Opera.PMS" && (
                    <Box py={1} align="center">
                      <LoadingButton
                        text="Mapping code"
                        defaultColor="default"
                        disabled={lookupList["accountCodeListRE"].length > 0 ? false : true}
                        fnc={() => {
                          setDocType("REVENUE");
                          setOpenMapping(true);
                        }}
                      />
                    </Box>
                  )}
                  {postType !== "Json" && fileNameRevenue && (
                    <Box p={1} align="center" style={{ padding: 0 }}>
                      <LoadingButton
                        text={translate("ra.actionMenu.POST")}
                        disabled={loading}
                        fnc={() => post(props.id, "REVENUE", fileArray.Revenue, configuration.Revenue)}
                      />
                    </Box>
                  )}
                  {postType === "Json" && (
                    <Box p={1} align="center" style={{ padding: 0 }}>
                      <LoadingButton
                        text={translate("ra.actionMenu.POST")}
                        disabled={loading}
                        fnc={() => post(props.id, "REVENUE", fileArray.Revenue, configuration.Revenue)}
                      />
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
            {configuration?.MarketSegment.Active && (
              <Accordion
                style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="marketsegment-content"
                  id="marketsegment-header"
                  style={{ minHeight: "unset" }}
                >
                  <Typography variant="h6">{translate("ra.field.Market Segment")}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ paddingTop: 0, flexDirection: "column" }}>
                  {postType !== "Json" ? (
                    <Box p={1} display="flex" style={{ padding: 0 }}>
                      <Box p={1} flexGrow={1}>
                        <ButtonUpload
                          id="fileTxt2"
                          name="fileTxt2"
                          style={{ display: "none" }}
                          type="file"
                          onChange={uploadFile}
                          accept=".csv,.txt,application/JSON,(*.*)"
                          multiple
                          fileUpload={fileNameMarketSegment}
                          cancelUpload={() => {
                            setFileNameMarketSegment();
                            setFileArray((state) => ({
                              ...state,
                              MarketSegment: [],
                            }));
                            document.getElementById("fileTxt2").value = "";
                          }}
                        />
                      </Box>
                      {/* <Box p={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => apiUploadFile(props.id, "MARKETSEGMENT", fileArray.MarketSegment)}
                        >
                          Upload
                        </Button>
                      </Box> */}
                    </Box>
                  ) : (
                    ""
                  )}
                  <Box p={1} align="left">
                    <Typography variant="body2">
                      <b>Select Balance Account</b>
                    </Typography>
                  </Box>
                  <Box p={1} align="center" style={{ padding: 0 }}>
                    <Box p={1} display="flex">
                      <MuiAutosuggest
                        label={translate("ra.field.Department")}
                        name="MarketSegment.DeptCode"
                        optKey="DeptCode"
                        optDesc="Description"
                        options={lookupList["departmentList"]}
                        updateOtherField={[{ key: "MarketSegment.DeptDesc", optKey: "Description" }]}
                        useFncUpdate={true}
                        fncUpdate={(value, methods) => {
                          let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                          if (daccList?.length > 0) {
                            setLookupList((state) => ({
                              ...state,
                              accountCodeListMA: daccList,
                            }));
                            //remove acc if not in defaultaccount
                            let acc = methods.getValues("MarketSegment.AccCode");
                            if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                              methods.setValue("MarketSegment.AccCode", "");
                              methods.setValue("MarketSegment.AccDesc", "");
                            }
                          } else {
                            setLookupList((state) => ({
                              ...state,
                              accountCodeListMA: oldAccList,
                            }));
                          }
                        }}
                        methods={methods}
                        style={{ width: 200 }}
                      />
                      <DescInForm
                        style={{ paddingLeft: 20 }}
                        name="MarketSegment.DeptDesc"
                        methods={methods}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                    <Box p={1} display="flex">
                      <MuiAutosuggest
                        label={translate("ra.field.Account")}
                        name="MarketSegment.AccCode"
                        optKey="AccCode"
                        optDesc={localStorage.getItem("Language") === "en-US" ? "Description" : "Description2"}
                        options={lookupList["accountCodeListMA"]}
                        updateOtherField={[
                          {
                            key: "MarketSegment.AccDesc",
                            optKey: localStorage.getItem("Language") === "en-US" ? "Description" : "Description2",
                          },
                        ]}
                        methods={methods}
                        style={{ width: 200 }}
                      />
                      <DescInForm
                        style={{ paddingLeft: 20 }}
                        name="MarketSegment.AccDesc"
                        methods={methods}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                  </Box>
                  {props.id !== "Opera.PMS" && (
                    <Box py={1} align="center">
                      <LoadingButton
                        text="Mapping code"
                        defaultColor="default"
                        disabled={lookupList["accountCodeListMA"].length > 0 ? false : true}
                        fnc={() => {
                          setDocType("MARKETSEGMENT");
                          setOpenMapping(true);
                        }}
                      />
                    </Box>
                  )}
                  {postType !== "Json" && fileNameMarketSegment && (
                    <Box p={1} align="center" style={{ padding: 0 }}>
                      <LoadingButton
                        text={translate("ra.actionMenu.POST")}
                        disabled={loading}
                        fnc={() =>
                          post(props.id, "MARKETSEGMENT", fileArray.MarketSegment, configuration.MarketSegment)
                        }
                      />
                    </Box>
                  )}
                  {postType === "Json" && (
                    <Box p={1} align="center" style={{ padding: 0 }}>
                      <LoadingButton
                        text={translate("ra.actionMenu.POST")}
                        disabled={loading}
                        fnc={() =>
                          post(props.id, "MARKETSEGMENT", fileArray.MarketSegment, configuration.MarketSegment)
                        }
                      />
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
            {configuration?.Statistic.Active && (
              <Accordion
                style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="statistic-content"
                  id="statistic-header"
                >
                  <Typography variant="h6">{translate("ra.field.Statistical Data")}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ paddingTop: 0, flexDirection: "column" }}>
                  {postType !== "Json" ? (
                    <Box p={1} display="flex" style={{ padding: 0 }}>
                      <Box p={1} flexGrow={1}>
                        <ButtonUpload
                          id="fileTxt3"
                          name="fileTxt3"
                          style={{ display: "none" }}
                          type="file"
                          onChange={uploadFile}
                          accept=".csv,.txt,application/JSON,(*.*)"
                          multiple
                          fileUpload={fileNameStatistic}
                          cancelUpload={() => {
                            setFileNameStatistic();
                            setFileArray((state) => ({
                              ...state,
                              Statistic: [],
                            }));
                            document.getElementById("fileTxt3").value = "";
                          }}
                        />
                      </Box>
                      {/* <Box p={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => apiUploadFile(props.id, "STATISTIC", fileArray.Statistic)}
                        >
                          Upload
                        </Button>
                      </Box> */}
                    </Box>
                  ) : (
                    ""
                  )}
                  <Box p={1} align="left">
                    <Typography variant="body2">
                      <b>Select Balance Account</b>
                    </Typography>
                  </Box>
                  <Box p={1} align="center" style={{ padding: 0 }}>
                    <Box p={1} display="flex">
                      <MuiAutosuggest
                        label={translate("ra.field.Department")}
                        name="Statistic.DeptCode"
                        optKey="DeptCode"
                        optDesc="Description"
                        options={lookupList["departmentList"]}
                        updateOtherField={[{ key: "Statistic.DeptDesc", optKey: "Description" }]}
                        useFncUpdate={true}
                        fncUpdate={(value, methods) => {
                          let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                          if (daccList?.length > 0) {
                            setLookupList((state) => ({
                              ...state,
                              accountCodeListST: daccList,
                            }));
                            //remove acc if not in defaultaccount
                            let acc = methods.getValues("Statistic.AccCode");
                            if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                              methods.setValue("Statistic.AccCode", "");
                              methods.setValue("Statistic.AccDesc", "");
                            }
                          } else {
                            setLookupList((state) => ({
                              ...state,
                              accountCodeListST: oldAccList,
                            }));
                          }
                        }}
                        methods={methods}
                        style={{ width: 200 }}
                      />
                      <DescInForm
                        style={{ paddingLeft: 20 }}
                        name="Statistic.DeptDesc"
                        methods={methods}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                    <Box p={1} display="flex">
                      <MuiAutosuggest
                        label={translate("ra.field.Account")}
                        name="Statistic.AccCode"
                        optKey="AccCode"
                        optDesc={localStorage.getItem("Language") === "en-US" ? "Description" : "Description2"}
                        options={lookupList["accountCodeListST"]}
                        updateOtherField={[
                          {
                            key: "Statistic.AccDesc",
                            optKey: localStorage.getItem("Language") === "en-US" ? "Description" : "Description2",
                          },
                        ]}
                        methods={methods}
                        style={{ width: 200 }}
                      />
                      <DescInForm
                        style={{ paddingLeft: 20 }}
                        name="Statistic.AccDesc"
                        methods={methods}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                  </Box>
                  {props.id !== "Opera.PMS" && (
                    <Box py={1} align="center">
                      <LoadingButton
                        text="Mapping code"
                        defaultColor="default"
                        disabled={lookupList["accountCodeListST"].length > 0 ? false : true}
                        fnc={() => {
                          setDocType("STATISTIC");
                          setOpenMapping(true);
                        }}
                      />
                    </Box>
                  )}
                  {postType !== "Json" && fileNameStatistic && (
                    <Box p={1} align="center" style={{ padding: 0 }}>
                      <LoadingButton
                        text={translate("ra.actionMenu.POST")}
                        disabled={loading}
                        fnc={() => post(props.id, "STATISTIC", fileArray.Statistic, configuration.Statistic)}
                      />
                    </Box>
                  )}
                  {postType === "Json" && (
                    <Box p={1} align="center" style={{ padding: 0 }}>
                      <LoadingButton
                        text={translate("ra.actionMenu.POST")}
                        disabled={loading}
                        fnc={() => post(props.id, "STATISTIC", fileArray.Statistic, configuration.Statistic)}
                      />
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </form>
      )}

      {openMapping && (
        <DialogMapping
          open={openMapping}
          onClose={() => setOpenMapping(false)}
          lookupList={{
            departmentList: lookupList["departmentList"],
            accountCodeList: lookupList["accountCodeList"],
          }}
          id={props.id}
          postType={postType}
          docType={docType}
          configuration={() => {
            // eslint-disable-next-line default-case
            switch (docType) {
              case "REVENUE":
                return configuration.Revenue;
              case "STATISTIC":
                return configuration.Statistic;
              case "MARKETSEGMENT":
                return configuration.MarketSegment;
              default:
                return configuration;
            }
          }}
        />
      )}
      {showErrorTable && (
        <ErrorTableDialog open={showErrorTable} onClose={() => setShowErrorTable(false)} errorTable={errorTable} />
      )}
      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(configuration, 0, 2) : ""}</pre>
    </div>
  );
}
