import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import {
	TextFieldInForm,
	SelectInForm,
	DateInForm,
	MonthInForm,
	DescInForm,
	MuiAutosuggest,
	NumberFormatInForm,
} from "components/Form";
import { getGlPrefix } from "services/generalLedger";
import { getAccountCodeList, getDepartmentList } from "services/setting";
import { getLookupCurrency } from "services/lookup";
import { makeStyles } from "@material-ui/core/styles";
import List from "./List";
import Show from "./Show";
import Edit from "./Edit";
import Create from "./Create";
import { addDays } from "date-fns";
import { Typography } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: 12,
		marginBottom: 12,
	},
	footerCell: {
		backgroundColor: theme.palette.background.paper,
		borderTop: "2px solid rgba(224, 224, 224, 1)",
		borderBottom: "none",
	},
	stickyFooterCell: {
		position: "sticky",
		bottom: 0,
		zIndex: 100,
		textAlign: "right",
		fontSize: "0.9rem",
		fontWeight: 600,
		color: theme.palette.primary.main,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightBold,
	},
	drawerOpen: {
		marginRight: drawerWidth,
	},
	drawerClose: {
		marginRight: 54,
	},
	borderTableTop: {
		borderTop: "1px solid rgba(224, 224, 224, 1)",
	},
	borderTable: {
		border: "1px solid rgba(224, 224, 224, 1)",
	},
}));

const SwitchActionMode = (props) => {
	const locale = useLocale();
	const translate = useTranslate();
	const classes = useStyles();
	const { authenticated } = useAuthState();
	const { settingAll, ToMySqlDate } = useContext(GblContext);
	const { SettingClosePeriod, SettingSystem } = settingAll;
	const { ClosePeriodGl } = SettingClosePeriod;
	const newClosePeriodGl = addDays(new Date(ClosePeriodGl), 1);
	const addMode = props.location.pathname.search("create") !== -1;
	const viewMode = props.location.pathname.search("show") !== -1;
	const [lookupList, setLookupList] = useState({
		prefixList: [],
		accountCodeListDr: [],
		accountCodeListCr: [],
		departmentList: [],
		currencyList: [],
	});
	const [oldAccList, setOldAccList] = useState([]);
	const fetchPrefixLookup = useCallback(async () => {
		const { Data } = await getGlPrefix();
		setLookupList((state) => ({
			...state,
			prefixList: Data,
		}));
	}, []);
	const fetchAccLookup = useCallback(async () => {
		const { Data } = await getAccountCodeList("Gl");
		setLookupList((state) => ({
			...state,
			accountCodeListDr: Data,
			accountCodeListCr: Data,
		}));
		setOldAccList(Data);
	}, []);
	const fetchDeptLookup = useCallback(async () => {
		const { Data } = await getDepartmentList();
		setLookupList((state) => ({
			...state,
			departmentList: Data,
		}));
	}, []);
	const fetchCurrencyLookup = useCallback(async () => {
		const p = {
			Module: "GL",
			CurrDate: ToMySqlDate(new Date()),
		};
		const arr = await getLookupCurrency(p);
		setLookupList((state) => ({
			...state,
			currencyList: arr,
		}));
	}, [ToMySqlDate]);

	useEffect(() => {
		if (authenticated) {
			fetchPrefixLookup();
			fetchAccLookup();
			fetchDeptLookup();
			fetchCurrencyLookup();
		}
	}, [
		authenticated,
		fetchPrefixLookup,
		fetchAccLookup,
		fetchDeptLookup,
		fetchCurrencyLookup,
	]);

	const labelList = {
		Prefix: translate("ra.fieldAbbr.prefix"),
		Type: translate("ra.fieldAbbr.type"),
		"Voucher No.": translate("ra.fieldAbbr.vouNo"),
		Date: translate("ra.fieldAbbr.date"),
		Description: translate("ra.fieldAbbr.desc"),
		Department: translate("ra.fieldAbbr.dept"),
		"Account #": translate("ra.fieldAbbr.account1"),
		Currency: translate("ra.fieldAbbr.currency"),
		Rate: translate("ra.fieldAbbr.rate"),
		Amount: translate("ra.fieldAbbr.amount"),
		Base: translate("ra.fieldAbbr.base"),
		"Dr.": translate("ra.fieldAbbr.dr"),
		"Cr.": translate("ra.fieldAbbr.cr"),
	};

	const formFieldsEdit = [
		{
			size: 2,
			field: (
				<MuiAutosuggest
					label={`* ${labelList["Prefix"]}`}
					name="Prefix"
					optKey="PrefixName"
					optDesc="Description"
					options={lookupList["prefixList"]}
					updateOtherField={[{ key: "PrefixDesc", optKey: "Description" }]}
					disabled={!addMode}
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
				/>
			),
		},
		{
			size: 2,
			field: (
				<SelectInForm
					label={"Amortized by"}
					name="TypeOfAmortize"
					options={["Day In Month", "Monthly"]}
				/>
			),
		},
	];

	const formFieldsDaily = [
		{
			size: 2,
			field: (
				<DateInForm
					label={translate("ra.field.Start Date")}
					name="StartDate"
					minDate={new Date(newClosePeriodGl)}
					minDateMessage={"Date must be more than close period"}
					required
				/>
			),
		},
		{
			size: 2,
			field: (
				<DateInForm
					label={translate("ra.field.End Date")}
					name="EndDate"
					minDate={new Date(newClosePeriodGl)}
					customMinDate={"StartDate"}
					minDateMessage={"Date must be more than start date"}				
				/>
			),
		},
	];

	const formFieldsMonthly = [
		{
			size: 2,
			field: (
				<MonthInForm
					label={translate("ra.field.Start Date")}
					name="StartDate"
					minDate={new Date(newClosePeriodGl)}
					minDateMessage={"Date must be more than close period"}
					required
				/>
			),
		},
		{
			size: 2,
			field: (
				<MonthInForm
					disableFuture={false}
					openTo="year"
					format={"MM/yyyy"}
					views={["year", "month"]}
					label={translate("ra.field.End Date")}
					name="EndDate"
					minDate={new Date(newClosePeriodGl)}
					customMinDate={"StartDate"}
					minDateMessage={"Date must be more than start date"}
					required
				/>
			),
		},
	];

	const formFieldsInfo = [
		{
			size: 2,
			field: (
				<MuiAutosuggest
					label={`* ${labelList["Currency"]}`}
					name="CurCode"
					optKey="CurrCode"
					optDesc="CurrRate"
					options={lookupList["currencyList"]}
					updateOtherField={[{ key: "CurRate", optKey: "CurrRate" }]}
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
				/>
			),
		},
		{
			size: 2,
			field: (
				<NumberFormatInForm
					label={`* ${labelList["Rate"]}`}
					name="CurRate"
					rule={{
						min: {
							value: 0.000001,
							message: `* ${translate("ra.validation.required")}`,
						},
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
					decimal={SettingSystem.CurrencyRateDecimal}
					// decimalSep={SettingSystem.DecimalSeparator}
					// thousandSep={SettingSystem.ThousandSeparator}
				/>
			),
		},
		{
			size: 8,
			field: (
				<TextFieldInForm
					label={`${labelList["Description"]}`}
					name="Description"
					variant="outlined"
					margin="dense"
					rule={{
						maxLength: {
							value: 255,
							message: "maximum length is 255",
						},
					}}
				/>
			),
		},
		{
			size: 2,
			field: (
				<NumberFormatInForm
					label={"* Amount to Amortize"}
					name="AmortizeAmt"
					decimal={SettingSystem.CurrencyBaseDecimal}
					decimalSep={SettingSystem.DecimalSeparator}
					thousandSep={SettingSystem.ThousandSeparator}
				/>
			),
		},
		{
			size: 2,
			field: (
				<NumberFormatInForm
					label={"* Base Amount to Amortize"}
					name="AmortizeBAmt"
					decimal={SettingSystem.CurrencyBaseDecimal}
					decimalSep={SettingSystem.DecimalSeparator}
					thousandSep={SettingSystem.ThousandSeparator}
					disabled
				/>
			),
		},
	];

	const formFieldsAccount = [
		{
			size: 2,
			field: (
				<Typography className={classes.heading}>
					{translate("ra.field.Dr Account")}
				</Typography>
			),
		},
		{
			size: 2,
			field: (
				<MuiAutosuggest
					label={`*${translate("ra.field.Dr Dept. Code")}`}
					name="DrDeptCode"
					optKey="DeptCode"
					optDesc="Description"
					options={lookupList["departmentList"]}
					updateOtherField={[{ key: "DrDeptDesc", optKey: "Description" }]}
					useFncUpdate={true}
					fncUpdate={(value, methods) => {
						const daccList = value?.DefaultAccount
							? JSON.parse(value.DefaultAccount)
							: [];
						if (daccList?.length > 0) {
							setLookupList((state) => ({
								...state,
								accountCodeListDr: daccList,
							}));
							//remove acc if not in defaultaccount
							const acc = methods.getValues("DrAccCode");
							if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
								methods.setValue("DrAccCode", "");
								methods.setValue("DrAccDesc", "");
							}
						} else {
							setLookupList((state) => ({
								...state,
								accountCodeListDr: oldAccList,
							}));
						}
					}}
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
				/>
			),
		},
		{
			size: 2,
			name: "DrDeptDesc",
			field: (
				<DescInForm
					style={{ marginTop: 8 }}
					name="DrDeptDesc"
					InputProps={{
						readOnly: true,
					}}
				/>
			),
		},
		{
			size: 2,
			field: (
				<MuiAutosuggest
					label={`*${translate("ra.field.Dr Acc. Code")}`}
					name="DrAccCode"
					optKey="AccCode"
					optDesc={locale === "en-US" ? "Description" : "Description2"}
					options={lookupList["accountCodeListDr"]}
					updateOtherField={[
						{
							key: "DrAccDesc",
							optKey: locale === "en-US" ? "Description" : "Description2",
						},
					]}
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
				/>
			),
		},
		{
			size: 4,
			name: "DrAccDesc",
			field: (
				<DescInForm
					style={{ marginTop: 8 }}
					name="DrAccDesc"
					InputProps={{
						readOnly: true,
					}}
				/>
			),
		},
		{
			size: 2,
			field: (
				<Typography className={classes.heading}>
					{" "}
					{translate("ra.field.Cr Account")}{" "}
				</Typography>
			),
		},
		{
			size: 2,
			field: (
				<MuiAutosuggest
					label={`*${translate("ra.field.Cr Dept. Code")}`}
					name="CrDeptCode"
					optKey="DeptCode"
					optDesc="Description"
					options={lookupList["departmentList"]}
					updateOtherField={[{ key: "CrDeptDesc", optKey: "Description" }]}
					useFncUpdate={true}
					fncUpdate={(value, methods) => {
						const daccList = value?.DefaultAccount
							? JSON.parse(value.DefaultAccount)
							: [];
						if (daccList?.length > 0) {
							setLookupList((state) => ({
								...state,
								accountCodeListCr: daccList,
							}));
							//remove acc if not in defaultaccount
							const acc = methods.getValues("CrAccCode");
							if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
								methods.setValue("CrAccCode", "");
								methods.setValue("CrAccDesc", "");
							}
						} else {
							setLookupList((state) => ({
								...state,
								accountCodeListCr: oldAccList,
							}));
						}
					}}
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
				/>
			),
		},
		{
			size: 2,
			name: "CrDeptDesc",
			field: (
				<DescInForm
					style={{ marginTop: 8 }}
					name="CrDeptDesc"
					InputProps={{
						readOnly: true,
					}}
				/>
			),
		},
		{
			size: 2,
			field: (
				<MuiAutosuggest
					label={`*${translate("ra.field.Cr Acc. Code")}`}
					name="CrAccCode"
					optKey="AccCode"
					optDesc={locale === "en-US" ? "Description" : "Description2"}
					options={lookupList["accountCodeListCr"]}
					updateOtherField={[
						{
							key: "CrAccDesc",
							optKey: locale === "en-US" ? "Description" : "Description2",
						},
					]}
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
				/>
			),
		},
		{
			size: 4,
			name: "CrAccDesc",
			field: (
				<DescInForm
					style={{ marginTop: 8 }}
					name="CrAccDesc"
					InputProps={{
						readOnly: true,
					}}
				/>
			),
		},
	];

	if (addMode) {
		return (
			<Create
				{...props}
				formFields={formFieldsEdit}
				formFieldsDaily={formFieldsDaily}
				formFieldsMonthly={formFieldsMonthly}
				formFieldsInfo={formFieldsInfo}
				formFieldsAccount={formFieldsAccount}
				lookupList={lookupList}
				useStyles={useStyles}
			/>
		);
	} else if (viewMode) {
		return <Show {...props} useStyles={useStyles} />;
	} else {
		return (
			<Edit
				{...props}
				formFields={formFieldsEdit}
				formFieldsDaily={formFieldsDaily}
				formFieldsMonthly={formFieldsMonthly}
				formFieldsInfo={formFieldsInfo}
				formFieldsAccount={formFieldsAccount}
				lookupList={lookupList}
				useStyles={useStyles}
			/>
		);
	}
};

export default {
	list: List,
	show: SwitchActionMode,
	edit: SwitchActionMode,
	create: SwitchActionMode,
};
