import React, { useContext, useState, useCallback } from "react";
import { Loading, useTranslate } from "react-admin";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Paper, Typography, Box, Button, Divider } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { permissionName } from "utils/constants";
import { closePeriodAr } from "services/arProcedure";
import DatePickerFormat from "components/DatePickerFormat";
import { getLicenseList, getSettingAll } from "services/setting";
import { getSettingInfPost } from "services/interface";
import { addDays, addMonths, endOfMonth } from "date-fns";
import DialogARContractList from "./DialogARContractList";
import DialogPMS from "./Dialog/DialogPMS";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    margin: theme.spacing(2, 0, 2),
  },
  title2: {
    fontSize: 14,
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),

    maxWidth: 500,
  },
  menuPaper: {
    maxHeight: 200,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  lucnchIconStyle: {
    marginLeft: 8,
    verticalAlign: "middle",
    cursor: "pointer",
  },
}));

const List = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { permissions } = props;
  const { settingAll, UpdateSettingAll, DateToString, ToMySqlDate } = useContext(GblContext);
  const { ClosePeriodAr } = settingAll.SettingClosePeriod;
  const timer = React.useRef();

  const [loading, setLoading] = useState({
    page: true,
    closedPeriod: false,
    rec: false,
  });
  const [dPeriod, setDPeriod] = useState({
    last: new Date(ClosePeriodAr),
    current: endOfMonth(addMonths(new Date(ClosePeriodAr), 1)),
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [interfacePermissionList, setInterfacePermissionList] = useState();
  const [settingInf, setSettingInf] = useState([]);

  const getLicenseInterface = useCallback(async () => {
    const { InterfacePermissionItems } = await getLicenseList();
    const newArr = InterfacePermissionItems.filter((item) => item.CanUse);
    const keyIdArr = newArr.map((item) => item.UniqueId.Id);
    keyIdArr.forEach((item) => {
      setLoading((state) => ({
        ...state,
        [item]: false,
      }));
    });

    // const PMS = newArr.filter((item) => item.UniqueId.LicenseType === "PMS");
    // const keyPMSArr = PMS.map((item) => item.UniqueId.Id);
    // const POS = newArr.filter((item) => item.UniqueId.LicenseType === "POS");
    // const keyPOSArr = POS.map((item) => item.UniqueId.Id);

    const settingInfs = await getSettingInfPost("AR");
    if (settingInfs) {
      const PMS = settingInfs.filter((item) => item.InterfaceType === "PMS");
      const keyPMSArr = PMS.map((item) => item.Code);
      const POS = settingInfs.filter((item) => item.InterfaceType === "POS");
      const keyPOSArr = POS.map((item) => item.Code);

      if (PMS.length > 0) {
        // PMS.forEach((element) => {
        //   var item = settingInfs.find((i) => i.Code === element.UniqueId.Id);
        //   if (item) {
        //     element.IsActive = item.IsActive;
        //     element.UniqueId.Description = item.Name;
        //     if (item.IsActive) {
        //       PMS.HasChildren = true;
        //     }
        //   }
        // });
        PMS.forEach((element) => {
          if (element.IsActive) {
            PMS.HasChildren = true;
          }
        });
      }
      if (POS.length > 0) {
        // POS.forEach((element) => {
        //   var item = settingInfs.find((i) => i.Code === element.UniqueId.Id);
        //   if (item) {
        //     element.IsActive = item.IsActive;
        //     element.UniqueId.Description = item.Name;
        //     if (item.IsActive) {
        //       POS.HasChildren = true;
        //     }
        //   }
        // });
        POS.forEach((element) => {
          if (element.IsActive) {
            POS.HasChildren = true;
          }
        });
      }
      setSettingInf(settingInfs);

      setInterfacePermissionList((state) => ({
        ...state,
        PMS: [],
        POS: [],
      }));

      keyPMSArr.forEach((item) => {
        setOpenDialog((state) => ({
          ...state,
          [item]: false,
        }));
      });
      keyPOSArr.forEach((item) => {
        setOpenDialog((state) => ({
          ...state,
          [item]: false,
        }));
      });
      setInterfacePermissionList((state) => ({
        ...state,
        PMS: PMS,
        POS: POS,
      }));

      setLoading((state) => ({
        ...state,
        page: false,
      }));
    } else {
      setInterfacePermissionList((state) => ({
        ...state,
        PMS: [],
        POS: [],
      }));

      setLoading((state) => ({
        ...state,
        page: false,
      }));
    }
  }, []);

  React.useEffect(() => {
    getLicenseInterface();
    return () => {
      clearTimeout(timer.current);
    };
  }, [getLicenseInterface]);

  React.useEffect(() => {
    let currentAr = endOfMonth(addMonths(new Date(ClosePeriodAr), 1));
    setDPeriod((state) => ({
      ...state,
      last: new Date(ClosePeriodAr),
      current: currentAr,
    }));
  }, [ClosePeriodAr]);

  const handleClickClosePeriod = async () => {
    if (!permissions.find((i) => i.Name === permissionName["AR.Procedure.PeriodEnd"])?.Update) {
      SnackbarUtils.error(translate("ra.permission.denied"));
      return;
    }
    let currentAr = addMonths(new Date(ClosePeriodAr), 1);
    let msg = `Do you want to close period ${DateToString(endOfMonth(currentAr))} ?`;
    SnackbarUtils.confirm(msg, async function () {
      if (!loading.closedPeriod) {
        setLoading((state) => ({
          ...state,
          closedPeriod: true,
        }));
        let closeDate = ToMySqlDate(endOfMonth(currentAr));
        const { Code, UserMessage } = await closePeriodAr(closeDate);
        if (Code >= 0) {
          SnackbarUtils.success(UserMessage);
          const setting = await getSettingAll();
          localStorage.setItem("SettingAll", JSON.stringify(setting));
          UpdateSettingAll(setting);

          let currentAr = addDays(new Date(setting.SettingClosePeriod.ClosePeriodAr), 1);

          setDPeriod((state) => ({
            ...state,
            last: new Date(setting.SettingClosePeriod.ClosePeriodAr),
            current: currentAr,
          }));
        } else {
          SnackbarUtils.warning(UserMessage);
        }
        timer.current = window.setTimeout(() => {
          setLoading((state) => ({
            ...state,
            closedPeriod: false,
          }));
        }, 1000);
      }
    });
  };

  const handleClickOpenDialog = (item) => {
    switch (item.InterfaceType) {
      case "PMS":
        if (!permissions.find((i) => i.Name === permissionName["AR.Post.PMS"])?.View) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          return;
        } else {
          setOpenDialog((state) => ({
            ...state,
            [`${item.Code}`]: true,
          }));
        }
        break;
      case "POS":
        if (!permissions.find((i) => i.Name === permissionName["AR.Post.POS"])?.View) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          return;
        } else {
          setOpenDialog((state) => ({
            ...state,
            [`${item.Code}`]: true,
          }));
        }
        break;
      case "HRM":
        if (!permissions.find((i) => i.Name === permissionName["GL.Post.HRM"])?.View) {
          SnackbarUtils.error(translate("ra.permission.denied"));
          return;
        } else {
          setOpenDialog((state) => ({
            ...state,
            [`${item.Code}`]: true,
          }));
        }
        break;
      default:
        break;
    }
  };

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {translate(`ra.actionMenu.${text}`)}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  if (loading.page) return <Loading />;

  return (
    <div className={classes.root}>
      <Paper
        elevation={3}
        style={{
          paddingTop: 5,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
        }}
      >
        <Typography variant="h6" className={classes.title}>
          {translate("ra.module.Procedure")}
        </Typography>

        <Box p={1} display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <Typography variant="subtitle1">{translate("ra.module.Apply Contract")}</Typography>
            {/* <Typography variant="caption">Apply Contract</Typography> */}
          </Box>
          <Divider />
          <Box p={1}>
            <Button
              variant="outlined"
              onClick={() => {
                if (!permissions.find((i) => i.Name === permissionName["AR.Procedure.ApplyContract"])?.Update) {
                  SnackbarUtils.error(translate("ra.permission.denied"));
                  return;
                }
                setOpenDialog((state) => ({
                  ...state,
                  applyContract: true,
                }));
              }}
            >
              {/* Apply Contract */}
              {translate("ra.module.Apply Contract")}
            </Button>
          </Box>
        </Box>
        <Divider />

        {interfacePermissionList &&
          interfacePermissionList["PMS"].length > 0 &&
          interfacePermissionList["PMS"].HasChildren && (
            <>
              {interfacePermissionList["PMS"].map(
                (item, idx) =>
                  item.IsActive && (
                    <div key={idx}>
                      <Box p={1} display="flex" alignItems="center">
                        <Box flexGrow={1}>
                          <Typography variant="h6" className={classes.title2}>
                            {translate("ra.module.Posting from") + item.Name}
                            <span className={classes.lucnchIconStyle}>
                              <LaunchIcon onClick={() => handleClickOpenDialog(item)} />
                            </span>
                          </Typography>
                          {/* <Typography variant="caption">Posting from Account Receivable</Typography> */}
                        </Box>
                      </Box>
                      <Divider />
                      {openDialog?.[`${item.Code}`] && (
                        <DialogPMS
                          id={item.Code}
                          title={item.Name}
                          open={openDialog[`${item.Code}`]}
                          onClose={() =>
                            setOpenDialog((state) => ({
                              ...state,
                              [`${item.Code}`]: false,
                            }))
                          }
                          setting={settingInf.find((s) => s.Code === item.Code)}
                        />
                      )}
                    </div>
                  )
              )}
            </>
          )}

        {interfacePermissionList &&
          interfacePermissionList["POS"].length > 0 &&
          interfacePermissionList["POS"].HasChildren && (
            <>
              {interfacePermissionList["POS"].map(
                (item, idx) =>
                  item.IsActive && (
                    <div key={idx}>
                      <Box p={1} display="flex" alignItems="center">
                        <Box flexGrow={1}>
                          <Typography variant="h6" className={classes.title2}>
                            {item.Name}{" "}
                            <span className={classes.lucnchIconStyle}>
                              <LaunchIcon onClick={() => handleClickOpenDialog(item)} />
                            </span>
                          </Typography>
                          {/* <Typography variant="caption">Posting from Account Receivable</Typography> */}
                        </Box>
                      </Box>
                      <Divider />
                      {openDialog?.[`${item.Code}`] && (
                        <DialogPMS
                          id={item.Code}
                          title={item.Name}
                          open={openDialog[`${item.Code}`]}
                          onClose={() =>
                            setOpenDialog((state) => ({
                              ...state,
                              [`${item.Code}`]: false,
                            }))
                          }
                          setting={settingInf.find((s) => s.Code === item.Code)}
                        />
                      )}
                    </div>
                  )
              )}
            </>
          )}

        <Box p={1} display="flex" alignItems="center">
          <Box p={1} flexGrow={1}>
            <Typography variant="h6" className={classes.title2} style={{ margin: 0 }}>
              {translate("ra.module.Close Period")}
            </Typography>
            {/* <Typography variant="caption">Account Receivable Close Period</Typography> */}
          </Box>
          <Box p={1}>
            <DatePickerFormat
              label={translate("ra.field.Last Closed Period")}
              value={dPeriod.last}
              readOnly
              style={{ width: 160, margin: "0 10px" }}
            />
            <DatePickerFormat
              label={translate("ra.field.Current Period")}
              value={dPeriod.current}
              readOnly
              style={{ width: 160, margin: "0 10px" }}
            />
          </Box>
          <Box p={1} display="flex" alignItems="center">
            <LoadingButton text="CLOSE" disabled={loading.closedPeriod} fnc={handleClickClosePeriod} />
          </Box>
        </Box>
      </Paper>
      {openDialog.applyContract && (
        <DialogARContractList
          open={openDialog}
          onClose={() => {
            setOpenDialog((state) => ({
              ...state,
              applyContract: false,
            }));
          }}
        />
      )}
    </div>
  );
};

export default List;
