/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const initColumns = [
  {
    name: "Action",
    label: "Action",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "Type",
    label: "Type",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "Code",
    label: "Code",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "InfoHeader",
    label: "InfoHeader",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "InfoDetail",
    label: "InfoDetail",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export default function ErrorTable(props) {
  const classes = useStyles();
  const { open, onClose, errorTable, keyTable } = props;
  const [columns, setColumns] = React.useState(initColumns);

  React.useEffect(() => {
    if (keyTable?.length > 0) {
      const newColumns = [];
      keyTable.forEach((element) => {
        newColumns.push({
          name: element,
          label: element,
          options: {
            filter: false,
            sort: false,
          },
        });
      });
      setColumns(newColumns);
    }
  }, []);

  const options = {
    responsive: "standard",
    selectableRows: "none",
    fixedHeader: true,
    search: false,
    download: true,
		onDownload: (buildHead, buildBody, columns, data) => {
			return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
		},
    downloadOptions: {
      filename: "errorList.csv",
    },
    filter: false,
    print: true,
    viewColumns: false,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event);
          }
        }}
        scroll={"paper"}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title" onClose={onClose}>
          ErrorTable
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          {!errorTable ? (
            <div className={classes.circulLoading}>
              <CircularProgress />
            </div>
          ) : (
            <MUIDataTable data={errorTable} columns={columns} options={options} />
          )}
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(errorTable, 0, 2) : ""}</pre>
        </DialogContent>
      </Dialog>
    </div>
  );
}
