import React from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { DisposalTypeOptions } from "utils/options";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
	textCancel: {
		color: "inherit",
		border: "1px solid rgba(0, 0, 0, 0.23)",
	},
	wrapper: {
		position: "relative",
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const DialogSelectType = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const { onClose, open } = props;
	const [selectedOption, setSelectedOption] = React.useState(
		DisposalTypeOptions[0],
	);
	const radioGroupRef = React.useRef(null);

	const handleEntering = () => {
		if (radioGroupRef.current != null) {
			radioGroupRef.current.focus();
		}
	};

	const handleCancel = () => {
		onClose();
	};

	const handleOk = async () => {
		localStorage.setItem("disposalType", selectedOption);
		onClose(true);
	};

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="xs"
			onEntering={handleEntering}
			aria-labelledby="confirmation-dialog-title"
			open={open}
		>
			<DialogTitle id="confirmation-dialog-title">
				Select Disposal Type
			</DialogTitle>
			<DialogContent dividers>
				<RadioGroup
					ref={radioGroupRef}
					aria-label="Type"
					name="value"
					value={selectedOption}
					onChange={(e, newValue) => {
						e.persist();
						setSelectedOption(newValue);
					}}
				>
					{DisposalTypeOptions.map((option, idx) => (
						<FormControlLabel
							label={option}
							key={option}
							value={option}
							control={<Radio />}
							disabled={option === "Amount"}
						/>
					))}
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={handleOk}>
					{translate("ra.actionMenu.OK")}
				</Button>
				<Button
					variant="outlined"
					className={classes.textCancel}
					onClick={handleCancel}
				>
					{translate("ra.action.cancel")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogSelectType;
