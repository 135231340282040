/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid, Typography, FormControl, Select, InputLabel, MenuItem } from "@material-ui/core";
import { getUserSearchList, getTenantByUserName, getUserListByTenant } from "services/setting";
import ButtonFooter from "components/ButtonFooter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogItem = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [user, setUser] = useState();
  const [bu, setBu] = useState("");
  const [userList, setUserList] = useState([]);
  const [buList, setBuList] = useState([]);

  const { open, onClose, save } = props;

  const fetchUserActiveList = async () => {
    let uriQueryString = {
      WhereRaw: "Active = true",
    };
    const ul = await getUserSearchList(uriQueryString);

    if (ul?.Code === 403) {
      return;
    }

    if (ul) {
      let arrUsername = ul.Data.map((i) => i.UserName);

      if (localStorage.getItem("UserName") !== "admin") {
        arrUsername = arrUsername.filter((i) => i !== "admin");
      }
      setUserList(arrUsername);
    } else {
      setUserList([]);
    }
  };

  const fetchTenantActiveList = async (user) => {
    const tl = await getTenantByUserName(user);

    if (tl) {
      let arrTenant = tl;
      if (arrTenant.length > 0) {
        let currentBu = arrTenant.find((i) => i.Tenant === localStorage.getItem("Tenant"));

        if (currentBu) {
          setBu(currentBu);
        } else {
          setBu(arrTenant[0]);
        }
      }
      setBuList(arrTenant);
    } else {
      setBuList([]);
    }
  };

  React.useEffect(() => {
    fetchUserActiveList();
    if (user) {
      fetchTenantActiveList(user);
    }
  }, [user]);

  const Save = async () => {
    save(user, bu);
    onClose();
  };

  const CancelFnc = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event);
          }
        }}
        scroll={"paper"}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title" onClose={onClose}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <div className={classes.root}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6} elevation={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lUser" style={{ marginTop: user === "" || !user ? -6 : 0 }}>
                    {translate("ra.field.Username")}
                  </InputLabel>
                  <Select
                    labelId="lUser"
                    label={translate("ra.field.Username")}
                    variant="outlined"
                    margin="dense"
                    value={user}
                    onChange={(e) => {
                      setUser(e.target.value);
                    }}
                  >
                    {userList?.length > 0 &&
                      userList.map((item, idx) => (
                        <MenuItem key={idx} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6} elevation={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lBu" style={{ marginTop: !bu ? -6 : 0 }}>
                    {translate("ra.field.Business Unit")}
                  </InputLabel>
                  <Select
                    labelId="lBu"
                    label={translate("ra.field.Business Unit")}
                    variant="outlined"
                    margin="dense"
                    value={bu}
                    onChange={async (e) => {
                      const objItem = e.target.value;
                      const r = await getUserListByTenant(objItem.Tenant);
                      let uList = r.map((i) => i.UserName);
                      if (localStorage.getItem("UserName") !== "admin") {
                        uList = uList.filter((i) => i !== "admin");
                      }
                      setUserList(uList);
                      setBu(objItem);
                    }}
                  >
                    {buList.map((item) => (
                      <MenuItem key={item.TenantId} value={item}>
                        {item.Description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonFooter noBorder SaveFnc={Save} CancelFnc={CancelFnc} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogItem;
