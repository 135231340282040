/* eslint-disable eqeqeq */
/* eslint-disable no-eval */
import React, { useContext } from "react";
import { useTranslate } from "react-admin";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
//import { KeyboardDatePicker } from "@material-ui/pickers";
import { Switch, TextField, Select, MenuItem, InputBase, Tooltip, FormHelperText } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { Autocomplete } from "@material-ui/lab";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { matchSorter } from "match-sorter";
import NumberFormatInput from "components/NumberFormatInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import ListBox from "components/ListBox";
import PopperListBox from "components/PopperListBox";
import { endOfMonth } from "date-fns";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    padding: "4px !important",
  },
  option: {
    width: 500,
    fontSize: 14,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  menuPaper: {
    maxHeight: 200,
  },
  helperTextContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    whiteSpace: "normal",
  },
}));

export function SwitchInForm({ methods, name, rule, ...rest }) {
  const translate = useTranslate();
  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rule}
      defaultValue=""
      render={(props) => {
        const notNullValue = props.value === "" ? false : props.value;
        return (
          <FormControlLabel
            value={typeof props.value === "string" && props.value === "true" ? props.value : false}
            control={
              <Switch
                {...props}
                disabled={rest.disabled}
                checked={notNullValue}
                onChange={(e) => {
                  if (rest.disabled) {
                    e.preventDefault();
                  } else {
                    if (rest.onChange) {
                      rest.onChange(e.target.checked);
                    }
                    props.onChange(e.target.checked);
                  }
                }}
              />
            }
            label={
              props.value
                ? rest.labelOn
                  ? rest.labelOn
                  : translate("ra.fieldAbbr.active")
                : rest.labelOff
                ? rest.labelOff
                : translate("ra.fieldAbbr.inactive")
            }
            labelPlacement="start"
            color="primary"
          />
        );
      }}
    />
  );
}

export function TextFieldInForm({ methods, name, rule, ...rest }) {
  const classes = useStyles();
  const HelperText = (props) => {
    if (props.rule?.maxLength) {
      var countCharacter;
      if (methods.watch(name) && methods.watch(name) !== "") {
        countCharacter = `${methods.watch(name).toString().length}/${props.rule?.maxLength.value}`;
      } else {
        countCharacter = `0/${props.rule?.maxLength.value}`;
      }
    }

    return (
      <span className={classes.helperTextContainer}>
        <span>{props.msg ? props.msg.message : ""}</span>
        <span>{countCharacter ? countCharacter : ""}</span>
      </span>
    );
  };

  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={methods.control}
        rules={rule}
        defaultValue={rest.defaultValue ?? ""}
        render={(props) => {
          const notNullValue = props.value ?? "";
          return (
            <TextField
              {...props}
              {...rest}
              id={name}
              name={name}
              label={rest.label}
              variant="outlined"
              margin="dense"
              //inputRef={rule ? methods.register(rule) : methods.register}
              error={!!methods.errors[name]}
              helperText={<HelperText msg={methods.errors[name]} rule={rule} />}
              inputProps={
                !rest.emailBox ?? {
                  maxLength: rule?.maxLength ? rule?.maxLength.value : 255,
                  type: rest.type ? rest.type : "text",
                }
              }
              value={notNullValue}
              onChange={(e) => {
                let value = e.target.value;
                if (
                  name === "Code" ||
                  name === "AccCode" ||
                  name === "DeptCode" ||
                  name === "VnTaxNo" ||
                  name === "VnCode" ||
                  name === "ArNo" ||
                  name === "ContractNo" ||
                  name === "Id" ||
                  name === "PrefixName" ||
                  name === "VnCateCode" ||
                  name === "WhtTypeCode" ||
                  name === "WhtCode" ||
                  name === "ArTypeCode" ||
                  // name === "ArTitleCode" ||
                  name === "ArOwnerCode" ||
                  name === "ArPrjCode" ||
                  name === "CateCode" ||
                  name === "DepartmentCode" ||
                  name === "LocCode" ||
                  name === "CurrCode" ||
                  name === "UnitCode" ||
                  name === "UserName"
                ) {
                  value = value.replace(/[^-A-Za-z0-9/]/gi, "");
                }
                props.onChange(value);
                methods.setValue(name, value);
                if (rest.onChange) rest.onChange(e);
              }}
            />
          );
        }}
      />
    </FormControl>
  );
}

export function NumberFormatInForm({ methods, name, rule, ...rest }) {
  if (rest.disabledByOtherfield) {
    if (methods.watch(rest.disabledByOtherfield)) {
      rest.disabled = false;
    }
  }
  const classes = useStyles();
  const HelperText = (props) => {
    return (
      <span className={classes.helperTextContainer}>
        <span>{props.msg ? props.msg.message : ""}</span>
      </span>
    );
  };

  const isReadOnly = () => {
    if (methods.watch("Type")) {
      switch (name) {
        case "Qty":
          return rest.forceSetReadOnly;
        default:
          return rest.readOnly;
      }
    } else {
      return rest.readOnly;
    }
  };

  const handleFocus = (e) => {
    e.target.select();
    setTimeout(function () {
      e.target.setSelectionRange(0, e.target.value.length);
    }, 0);
  };

  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={methods.control}
        rules={rule}
        defaultValue={""}
        render={(props) => {
          return rest.toolTip ? (
            <Tooltip
              title={<span style={{ fontSize: 12 }}>{`suggestion amount : ${methods.watch(rest.toolTip)}`}</span>}
              arrow
            >
              <TextField
                id={name}
                name={name}
                label={rest.label}
                variant={isReadOnly() ? "filled" : "outlined"}
                margin="dense"
                error={!!methods.errors[name]}
                helperText={<HelperText msg={methods.errors[name]} rule={rule} />}
                InputProps={{
                  inputComponent: NumberFormatInput,
                }}
                inputProps={{
                  style: { textAlign: "right" },
                  maxLength: rule?.maxLength ? rule?.maxLength.value : 18,
                  decimal: rest.decimal,
                  decimalSep: rest.decimalSep ?? ".",
                  thousandSep: rest.thousandSep ?? ",",
                  readOnly: isReadOnly(),
                }}
                defaultValue={""}
                value={methods.watch(name)}
                disabled={rest.disabled}
                onChange={(e) => {
                  if (!rest.useOnBlur) {
                    props.onChange(e.target.value);
                    methods.setValue(name, e.target.value);
                    if (rest.onChange) rest.onChange(e);
                  }
                }}
                onBlur={(e) => {
                  if (rest.useOnBlur) {
                    props.onChange(e.target.value);
                    methods.setValue(name, e.target.value);
                    if (rest.onChange) rest.onChange(e);
                  }
                }}
                onFocus={handleFocus}
                style={rest.style}
              />
            </Tooltip>
          ) : (
            <TextField
              id={name}
              name={name}
              label={rest.label}
              variant={isReadOnly() ? "filled" : "outlined"}
              margin="dense"
              error={!!methods.errors[name]}
              helperText={<HelperText msg={methods.errors[name]} rule={rule} />}
              InputProps={{
                inputComponent: NumberFormatInput,
              }}
              inputProps={{
                style: { textAlign: "right" },
                maxLength: rule?.maxLength ? rule?.maxLength.value : 18,
                decimal: rest.decimal,
                decimalSep: rest.decimalSep ?? ".",
                thousandSep: rest.thousandSep ?? ",",
                readOnly: isReadOnly(),
              }}
              defaultValue={""}
              value={methods.watch(name)}
              disabled={rest.disabled}
              onChange={(e) => {
                if (!rest.useOnBlur) {
                  props.onChange(e.target.value);
                  methods.setValue(name, e.target.value);
                  if (rest.onChange) rest.onChange(e);
                }
              }}
              onBlur={(e) => {
                if (rest.useOnBlur) {
                  props.onChange(e.target.value);
                  methods.setValue(name, e.target.value);
                  if (rest.onChange) rest.onChange(e);
                }
              }}
              onFocus={handleFocus}
              style={rest.style}
            />
          );
        }}
      />
    </FormControl>
  );
}

export function DateInForm({ methods, name, rule, ...rest }) {
  const { settingAll } = useContext(GblContext);
  const { SettingSystem } = settingAll;
  const classes = useStyles();
  const HelperText = (props) => {
    return (
      <span className={classes.helperTextContainer}>
        <span>{props.msg ? props.msg.message : ""}</span>
      </span>
    );
  };

  const setMinDate = () => {
    if (rest.customMinDate) {
      if (methods.watch(rest.customMinDate) === null) {
        return methods.watch(rest.minDate);
      } else {
        if (new Date(methods.watch(rest.customMinDate)) < rest.minDate) {
          return rest.minDate;
        }
        return methods.watch(rest.customMinDate);
      }
    } else {
      return rest.minDate;
    }
  };

  const setMaxDate = () => {
    if (rest.customMaxDate) {
      if (methods.watch(rest.customMaxDate) === null) {
        return methods.watch(rest.maxDate);
      } else {
        if (rest.maxDate > new Date(methods.watch(rest.customMaxDate))) {
          return rest.maxDate;
        }
        return methods.watch(rest.customMaxDate);
      }
    } else {
      if (rest.endOfMonthMaxDate) {
        return endOfMonth(new Date(methods.watch(rest.maxDate)));
      }
      return rest.maxDate;
    }
  };

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rule}
      render={({ ref, ...detail }) => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              {...detail}
              label={rest.label}
              name={name}
              inputVariant="outlined"
              margin="dense"
              format={SettingSystem.DateFormat}
              required={rule?.required !== undefined ? true : false}
              error={rest.useHelperText && !!methods.errors[name]}
              helperText={rest.useHelperText && <HelperText msg={methods.errors[name]} rule={rule} />}
              autoOk={true}
              okLabel=""
              cancelLabel=""
              fullWidth
              showTodayButton
              minDate={setMinDate()}
              minDateMessage={rest.minDateMessage}
              maxDate={setMaxDate()}
              maxDateMessage={rest.maxDateMessage}
              disabled={rest.disabled}
              clearable={rest.clearable}
              onChange={(newValue) => {
                if (!isNaN(Date.parse(newValue))) {
                  const obj = { target: { name: name, value: newValue } };
                  detail.onChange(obj);
                  methods.setValue(name, newValue);
                  if (rest.onChange) rest.onChange(obj);
                } else {
                  detail.onChange(newValue);
                }
                if (rest.useFncUpdate && newValue !== "Invalid Date") {
                  rest.fncUpdate(newValue, methods);
                }
              }}
              style={methods.errors[name] ? null : rest.style}
              views={["year", "month", "date"]}
            />
            {/* <KeyboardDatePicker
              {...detail}
              label={rest.label}
              name={name}
              inputVariant="outlined"
              margin="dense"
              format={SettingSystem.DateFormat}
              required={rule?.required !== undefined ? true : false}
              //error={!!methods.errors[name]}
              //helperText={<HelperText msg={methods.errors[name]} rule={rule} />}
              autoOk={true}
              okLabel=""
              cancelLabel=""
              fullWidth
              showTodayButton
              minDate={setMinDate()}
              minDateMessage={rest.minDateMessage}
              maxDate={setMaxDate()}
              maxDateMessage={rest.maxDateMessage}
              disabled={rest.disabled}
              clearable={rest.clearable}
              onChange={(newValue) => {
                if (!isNaN(Date.parse(newValue))) {
                  const obj = { target: { name: name, value: newValue } };
                  detail.onChange(obj);
                  methods.setValue(name, newValue);
                  if (rest.onChange) rest.onChange(obj);
                } else {
                  detail.onChange(newValue);
                }
                if (rest.useFncUpdate && newValue != "Invalid Date") {
                  rest.fncUpdate(newValue, methods);
                }
              }}
              style={methods.errors[name] ? null : rest.style}
              views={["year", "month", "date"]}
            /> */}
          </MuiPickersUtilsProvider>
        );
      }}
    />
  );
}

export function MonthInForm({ methods, name, rule, ...rest }) {
  const setMinDate = () => {
    if (rest.customMinDate) {
      if (methods.watch(rest.customMinDate) === null) {
        return methods.watch(rest.minDate);
      } else {
        if (new Date(methods.watch(rest.customMinDate)) < rest.minDate) {
          return rest.minDate;
        }
        return methods.watch(rest.customMinDate);
      }
    } else {
      return rest.minDate;
    }
  };

  const setMaxDate = () => {
    if (rest.customMaxDate) {
      if (methods.watch(rest.customMaxDate) === null) {
        return methods.watch(rest.maxDate);
      } else {
        if (rest.maxDate > new Date(methods.watch(rest.customMaxDate))) {
          return rest.maxDate;
        }
        return methods.watch(rest.customMaxDate);
      }
    } else {
      return rest.maxDate;
    }
  };

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={rule}
      render={({ ref, ...detail }) => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              {...detail}
              label={rest.label}
              name={name}
              inputVariant="outlined"
              margin="dense"
              required={rule?.required !== undefined ? true : false}
              //error={!!methods.errors[name]}
              //helperText={<HelperText msg={methods.errors[name]} rule={rule} />}
              autoOk={true}
              okLabel=""
              cancelLabel=""
              fullWidth
              minDate={setMinDate()}
              minDateMessage={rest.minDateMessage}
              maxDate={setMaxDate()}
              maxDateMessage={rest.maxDateMessage}
              disabled={rest.disabled}
              clearable={rest.clearable}
              onChange={(newValue) => {
                if (!isNaN(Date.parse(newValue))) {
                  const obj = { target: { name: name, value: newValue } };
                  detail.onChange(obj);
                  methods.setValue(name, newValue);
                  if (rest.onChange) rest.onChange(obj);
                } else {
                  detail.onChange(newValue);
                }
                if (rest.useFncUpdate && newValue !== "Invalid Date") {
                  rest.fncUpdate(newValue, methods);
                }
              }}
              style={methods.errors[name] ? null : rest.style}
              disableFuture={rest.disableFuture}
              openTo="month"
              format={"MM/yyyy"}
              views={["year", "month"]}
            />
            {/* <KeyboardDatePicker
              {...detail}
              label={rest.label}
              name={name}
              inputVariant="outlined"
              margin="dense"
              format={SettingSystem.DateFormat}
              required={rule?.required !== undefined ? true : false}
              //error={!!methods.errors[name]}
              //helperText={<HelperText msg={methods.errors[name]} rule={rule} />}
              autoOk={true}
              okLabel=""
              cancelLabel=""
              fullWidth
              showTodayButton
              minDate={setMinDate()}
              minDateMessage={rest.minDateMessage}
              maxDate={setMaxDate()}
              maxDateMessage={rest.maxDateMessage}
              disabled={rest.disabled}
              clearable={rest.clearable}
              onChange={(newValue) => {
                if (!isNaN(Date.parse(newValue))) {
                  const obj = { target: { name: name, value: newValue } };
                  detail.onChange(obj);
                  methods.setValue(name, newValue);
                  if (rest.onChange) rest.onChange(obj);
                } else {
                  detail.onChange(newValue);
                }
                if (rest.useFncUpdate && newValue != "Invalid Date") {
                  rest.fncUpdate(newValue, methods);
                }
              }}
              style={methods.errors[name] ? null : rest.style}
              views={["year", "month", "date"]}
            /> */}
          </MuiPickersUtilsProvider>
        );
      }}
    />
  );
}

export function CheckBoxInForm({ methods, name, rule, ...rest }) {
  if (rest.disabledByTaxType) {
    if (methods.watch(rest.disabledByTaxType) === "None") {
      rest.disabled = true;
    }
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ ref, ...detail }) => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                {...detail}
                checked={Boolean(detail.value)}
                name={name}
                color="primary"
                onChange={(e, newValue) => {
                  detail.onChange(e.target.value);
                  methods.setValue(name, newValue);
                  if (rest.onChange) {
                    rest.onChange(e);
                  }
                }}
                disabled={rest.disabled}
              />
            }
            label={rest.label}
            style={rest.style}
          />
        );
      }}
    />
  );
}

const filterOptions = (options, { inputValue }, optKey, optDesc) => {
  return matchSorter(options, inputValue, {
    keys: [`${optKey}`, `${optDesc}`],
  });
};

export function MuiAutosuggest({ methods, name, optKey, optDesc, optDesc2, rule, ...rest }) {
  const classes = useStyles();
  const loading = rest?.options?.length === 0;

  const HelperText = (props) => {
    return (
      <span className={classes.helperTextContainer}>
        <span>{props.msg ? props.msg.message : ""}</span>
      </span>
    );
  };

  return (
    <Controller
      name={name}
      control={methods.control}
      defaultValue={rest.defaultValue ?? ""}
      rules={rule}
      render={(props) => {
        return (
          <Autocomplete
            {...props}
            style={methods.errors[name] ? { marginTop: 0 } : rest.style}
            disabled={rest.disabled}
            disableListWrap
            disableClearable={false}
            //disableClearable={rest.clearable ? false : true}
            ListboxComponent={ListBox}
            PopperComponent={PopperListBox}
            classes={{
              inputRoot: classes.inputRoot,
              option: classes.option,
            }}
            options={rest.options}
            loading={loading}
            autoHighlight
            freeSolo={props.value === "" || "string" ? true : false}
            forcePopupIcon={true}
            getOptionLabel={(option) => {
              return typeof option === "object" ? option[optKey] : option;
            }}
            getOptionDisabled={(option) => option.Active !== undefined && option.Active === false}
            // getOptionSelected={(option, value) => {
            //   return typeof value === "object"
            //     ? option[optKey] == value[optKey]
            //     : option[optKey] == value;
            // }}
            renderInput={(params) => {
              if (rest.InputProps?.startAdornment) {
                params.InputProps.startAdornment = rest.InputProps.startAdornment;
              }
              return (
                <TextField
                  {...params}
                  error={!!methods.errors[name]}
                  helperText={<HelperText msg={methods.errors[name]} rule={rule} />}
                  label={rest.label}
                  name={name}
                  variant="outlined"
                  margin="dense"
                  placeholder={rest.label}
                />
              );
            }}
            renderOption={(option, { inputValue }) => {
              const mergestring = rest.noCode
                ? option[optKey]
                : `${option[optKey]} ${
                    option[optDesc] ? `: ${option[optDesc]}` : option[optDesc2] ? `: ${option[optDesc2]}` : ""
                  }`;

              const matches = match(mergestring, inputValue);
              const parts = parse(mergestring, matches);
              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            onChange={(e, newValue) => {
              if (newValue) {
                props.onChange(newValue[optKey]);
                methods.setValue(name, newValue[optKey]);
              } else if (!newValue) {
                props.onChange(newValue);
                methods.setValue(name, newValue);
              }

              if (rest.updateOtherField?.length > 0 && newValue) {
                rest.updateOtherField.forEach((element) => {
                  if (!newValue[element.optKey]) {
                    methods.setValue(element.key, newValue[element?.optKey2 ? element?.optKey2 : element?.optKey]);
                  } else {
                    methods.setValue(element.key, newValue[element.optKey]);
                  }
                  if (element.useFncUpdate) {
                    const n = rest.fncUpdate(newValue);
                    methods.setValue(element.key, n);
                  }
                });
              } else {
                if (rest.updateOtherField && name !== "VnCode") {
                  rest.updateOtherField.forEach((element) => {
                    methods.setValue(element.key, newValue);
                  });
                }
              }
              if (rest.updateLookupItem) {
                localStorage.setItem("vdItem", JSON.stringify(newValue));
              }
              if (rest.useFncUpdate) {
                rest.fncUpdate(newValue, methods);
              }
              if (rest.onChange) rest.onChange(e);
            }}
            filterOptions={(options, { inputValue }) => filterOptions(options, { inputValue }, optKey, optDesc)}
          />
        );
      }}
    />
  );
}

export function SelectInForm({ methods, name, rule, clearOtherFieldError, ...rest }) {
  const classes = useStyles();

  const HelperText = (props) => {
    return (
      <span className={classes.helperTextContainer}>
        <span>{props.msg ? props.msg.message : ""}</span>
      </span>
    );
  };

  return (
    <Controller
      name={name}
      control={methods.control}
      render={(props) => {
        return (
          <FormControl variant="outlined" fullWidth error={!!methods.errors[name]}>
            <InputLabel id="label" style={rest.style ? rest.style : { margin: "4px 0" }}>
              {rest.label}
            </InputLabel>
            <Select
              {...props}
              labelId="label"
              variant="outlined"
              margin="dense"
              label={rest.label}
              style={{ margin: "4px 0" }}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              disabled={rest.disabled}
              onChange={async (e) => {
                props.onChange(e.target.value);
                methods.setValue(name, e.target.value);
                if (rest.onChange) rest.onChange(e);
                if (clearOtherFieldError) {
                  methods.clearErrors(clearOtherFieldError);
                }
              }}
            >
              {rest.emptyOption ?? rest.emptyOption}
              {rest.options
                ? rest.options.map((item, idx) => {
                    if (typeof item === "string") {
                      return (
                        <MenuItem key={idx} value={item}>
                          {item}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem key={idx} value={item.key}>
                          {rest.keyInDesc ? `${item.key} : ${item.value}` : item.value}
                        </MenuItem>
                      );
                    }
                  })
                : ""}
            </Select>
            <FormHelperText>
              <HelperText msg={methods.errors[name]} rule={rule} />
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}

export function DescInForm({ methods, name, ...rest }) {
  return (
    <Tooltip title={methods.watch(name) ?? ""}>
      <InputBase
        name={name}
        style={rest.style}
        inputRef={methods?.register}
        inputProps={rest.InputProps}
        fullWidth
        value={rest.value ?? undefined}
      />
    </Tooltip>
  );
}
