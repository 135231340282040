/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { useRedirect } from "react-admin";
import { Loading, Error, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { getArProfileSearchList } from "services/accountReceivable";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import CustomTablePagination from "components/CustomTablePagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ModelUriQueryString from "models/uriQueryString";
import { permissionName } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  bullStatusActive: {
    height: 10,
    width: 10,
    backgroundColor: "green",
    borderRadius: "50%",
    display: "inline-block",
  },
  bullStatusInActive: {
    height: 10,
    width: 10,
    backgroundColor: "red",
    borderRadius: "50%",
    display: "inline-block",
  },
}));

const List = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { DateToString } = useContext(GblContext);
  const { basePath, permissions } = props;

  //initial Value with window.sessionStorage
  const ws = window.sessionStorage;
  const filterValue =
    ws.getItem("basePath") === basePath && ws.getItem("searchDetails")
      ? JSON.parse(ws.getItem("searchDetails")).filterValue
      : undefined;
  const searchQuery =
    ws.getItem("basePath") === basePath && ws.getItem("searchDetails")
      ? JSON.parse(ws.getItem("searchDetails")).searchQuery
      : undefined;
  const searchResults =
    ws.getItem("basePath") === basePath && ws.getItem("searchDetails")
      ? JSON.parse(ws.getItem("searchDetails")).searchResults
      : undefined;

  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [Data, setData] = useState(searchResults ? searchResults : undefined);
  const [Paging, setPaging] = useState();
  const [uriQueryString, setUriQueryString] = useState({
    Limit: searchQuery ? searchQuery.Limit : ModelUriQueryString.Limit,
    Page: searchQuery ? searchQuery.Page : ModelUriQueryString.Page,
    OrderBy: searchQuery ? searchQuery.OrderBy : ModelUriQueryString.OrderBy,
    WhereGroupList: searchQuery ? searchQuery.WhereGroupList : ModelUriQueryString.WhereGroupList,
    Exclude: searchQuery ? searchQuery.Exclude : ModelUriQueryString.Exclude,
    WhereRaw: searchQuery ? searchQuery.WhereRaw : ModelUriQueryString.WhereRaw,
    WhereLike: searchQuery ? searchQuery.WhereLike : ModelUriQueryString.WhereLike,
    WhereLikeFields: searchQuery ? searchQuery.WhereLikeFields : ModelUriQueryString.WhereLikeFields,
  });
  const [searchText, setSearchText] = useState(filterValue ? filterValue.searchValue : undefined);

  const redirect = useRedirect();

  const fetchSearchList = async (uriQueryString) => {
    setLoading(true);
    uriQueryString.Exclude = ["ContractDetail", "UserModified"];
    const { Data, Paging } = await getArProfileSearchList(uriQueryString);
    if (Data) {
      setData(Data);
      setPaging(Paging);
      setUriQueryString(uriQueryString);
    } else {
      setData([]);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchSearchList(uriQueryString);
  }, []);

  const handleRequestSort = (property, order) => {
    uriQueryString.OrderBy = { [property]: order };
    fetchSearchList(uriQueryString);
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!Data) return null;

  const handleChangePage = (e, newPage) => {
    uriQueryString.Page = newPage + 1;
    fetchSearchList(uriQueryString);
  };

  const handleChangeRowsPerPage = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    uriQueryString.Limit = newRowsPerPage;
    fetchSearchList(uriQueryString);
  };

  const handleTableChange = (action, tableState) => {
    if (action === "onSearchClose") {
      //clear window.sessionstorage (when user click close icon)
      ws.clear();
      setSearchText();
      uriQueryString.WhereLike = "";
      fetchSearchList(uriQueryString);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    uriQueryString.WhereLike = `%${value}%`;
    uriQueryString.WhereLikeFields = [
      "ArProfileId",
      "ArNo",
      "DATE_FORMAT(RegDate, '%d/%m/%Y')",
      "FirstName",
      "LastName",
      "Title",
      "Company",
      "Tel",
      "concat(FirstName, LastName)",
    ];
    uriQueryString.Page = 1;
    fetchSearchList(uriQueryString);
  };

  const columns = [
    {
      name: "ArProfileId",
      label: " ",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <a href={`#${basePath}/${value}/show`}>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  //set window.storage value
                  const filterValueObj = {
                    searchValue: searchText,
                  };
                  ws.setItem("basePath", basePath);
                  ws.setItem(
                    "searchDetails",
                    JSON.stringify({
                      filterValue: filterValueObj,
                      searchQuery: uriQueryString,
                      searchDetails: Data,
                    })
                  );
                  redirect("show", basePath, value);
                }}
              />
            </a>
          );
        },
      },
    },
    {
      name: "ArNo",
      label: "A/R No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "RegDate",
      label: "Register Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value)) : null;
        },
      },
    },
    {
      name: "Title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "FirstName",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "LastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "Company",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Tel",
      label: "Telephone",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Active",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <span
                className={clsx({
                  [classes.bullStatusActive]: value,
                  [classes.bullStatusInActive]: !value,
                })}
              />{" "}
              {value ? translate("ra.fieldAbbr.active") : translate("ra.fieldAbbr.inactive")}
            </>
          );
        },
      },
    },
  ];

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    confirmFilters: true,
    searchText: searchText,
    searchProps: {
      onKeyUp: (e) => {
        if (e.keyCode === 13) {
          handleSearch(e.target.value);
        }
      },
    },
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
    },
    onTableChange: (action, tableState) => handleTableChange(action, tableState),
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    sortOrder: {
      name: Object.keys(uriQueryString.OrderBy)[0],
      direction: Object.values(uriQueryString.OrderBy)[0],
    },
    onColumnSortChange: (changedColumn, direction) => {
      handleRequestSort(changedColumn, direction);
    },
    customFooter: () => {
      return (
        <CustomTablePagination
          rowsPerPageOptions={[15, 50, 100]}
          component="div"
          count={Paging?.TotalRecordCount ?? 0}
          rowsPerPage={Paging?.Limit ?? 15}
          page={Paging?.Page ? Paging.Page - 1 : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      );
    },
  };

  const menuControlProp = [{ name: "Add", fnc: () => redirect("create", basePath) }];

  return (
    <div className={classes.root}>
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["AR.Profile"])}
      />
      <MuiTranslateTable title={"A/R Profile"} data={Data} columns={columns} options={options} />
    </div>
  );
};

export default List;
