/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import AccessDenied from "layout/AccessDenied";
import {
  Box,
  Divider,
  Typography,
  Grid,
  FormControl,
  Select,
  Switch,
  InputLabel,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { getMailProfile, updatMailProfileCode } from "services/setting";
import { TextFieldInForm, CheckBoxInForm, NumberFormatInForm, SwitchInForm } from "components/Form";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import { MailProfileCode } from "utils/options";
import Model from "models/emailProfile";
import SnackbarUtils from "utils/SnackbarUtils";
import { permissionName } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  tabPanel: { width: "100%", margin: "0 20px" },
  button: { textTransform: "none" },
}));

const Email = (props) => {
  const { children, value, index, permissions } = props;

  let checkPermission = permissions && permissions.find((i) => i.Name === "Sys.Email")?.View;

  const classes = useStyles();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [mode, setMode] = useState("view");
  const [data, setData] = useStateWithCallbackLazy(Model);
  const [profileCode, setProfileCode] = useState(MailProfileCode[0]);
  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm({ defaultValues: data });
  const { handleSubmit, control, reset } = methods;

  const fetch = async () => {
    const response = await getMailProfile(profileCode);
    if (response && response !== "") {
      response.KeepOldPassword = true;
      setData(response);
      reset(response);
    } else {
      let model = {
        KeepOldPassword: true,
        Authentication: false,
        Password: null,
        Port: 0,
        Sender: null,
        Server: null,
        Ssl: false,
        Username: null,
      };
      setData(model);
      reset(model);
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    if (value === index) {
      fetch();
    }
  }, [value, profileCode]);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async (values) => {
    setBtnLoading(true);
    values.Port = parseInt(values.Port);
    const { Code, UserMessage } = await updatMailProfileCode(profileCode, values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
      setMode("view");
      fetch();
    } else {
      SnackbarUtils.warning(UserMessage);
      setBtnLoading(false);
    }
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
      disabled: mode !== "view",
    },
  ];

  const CancelFnc = () => {
    setProfileCode("NONE");
    setMode("view");
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {checkPermission && value === index ? (
        <>
          <Box p={3}>
            <Typography>
              <b>{children}</b>
            </Typography>
          </Box>
          <div style={{ marginTop: -74 }}>
            <ActionMenu
              menuControl={menuControlProp}
              permission={permissions.find((i) => i.Name === permissionName["Sys.Email"])}
            />
          </div>
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
            <Grid container justifyContent="flex-start" spacing={1} style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid item xs={4} md={4} style={{ marginTop: 4 }}>
                <FormControl variant="outlined">
                  <InputLabel id="m">Available Module</InputLabel>
                  <Select
                    variant="outlined"
                    margin="dense"
                    labelId="m"
                    label="Available Module"
                    value={profileCode}
                    onChange={(e) => {
                      setProfileCode(e.target.value);
                    }}
                    style={{ width: 240 }}
                    disabled={mode === "view"}
                  >
                    {MailProfileCode
                      ? MailProfileCode.map((item, idx) => (
                          <MenuItem key={idx} value={item}>
                            {item}
                          </MenuItem>
                        ))
                      : ""}
                  </Select>
                </FormControl>
              </Grid>
              {profileCode !== "NONE" && (
                <Grid item sm={4} md={4}>
                  <SwitchInForm name="Active" methods={methods} disabled={mode === "view"} defaultChecked />
                </Grid>
              )}
              {profileCode !== "NONE" && (
                <>
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    <TextFieldInForm
                      label="* Sender email"
                      name="Sender"
                      variant="outlined"
                      margin="dense"
                      methods={methods}
                      placeholder={"e.g. user@example.com"}
                      // InputProps={{
                      //   readOnly: mode === "view",
                      // }}
                      disabled={mode === "view"}
                      type="email"
                      rule={{
                        required: {
                          value: true,
                          message: "* Required",
                        },
                        maxLength: {
                          value: 100,
                          message: "maximun length is 100",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldInForm
                      label="Host"
                      name="Server"
                      variant="outlined"
                      margin="dense"
                      methods={methods}
                      // InputProps={{
                      //   readOnly: mode === "view",
                      // }}
                      disabled={mode === "view"}
                      rule={{
                        maxLength: {
                          value: 100,
                          message: "maximun length is 100",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldInForm
                      label="Email"
                      name="Username"
                      variant="outlined"
                      margin="dense"
                      methods={methods}
                      // InputProps={{
                      //   readOnly: mode === "view",
                      // }}
                      disabled={mode === "view"}
                      placeholder={"e.g. user@example.com"}
                      rule={{
                        maxLength: {
                          value: 100,
                          message: "maximun length is 100",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined">
                      <Controller
                        name="KeepOldPassword"
                        control={control}
                        defaultValue=""
                        render={(props) => {
                          return (
                            <FormControlLabel
                              value={props.value}
                              control={
                                <Switch
                                  checked={typeof props.value === "boolean" ? props.value : false}
                                  onChange={(e, newValue) => props.onChange(newValue)}
                                  disabled={mode !== "edit"}
                                />
                              }
                              label={props.value ? "Keep current password" : "Keep current password"}
                              labelPlacement="start"
                              color="primary"
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldInForm
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      name="Password"
                      variant="outlined"
                      margin="dense"
                      methods={methods}
                      placeholder={"Blank to leave unchanged"}
                      required={!methods.watch("KeepOldPassword")}
                      // InputProps={{
                      //   readOnly: mode === "view",
                      // }}
                      disabled={mode === "view" || methods.watch("KeepOldPassword")}
                      rule={
                        !methods.watch("KeepOldPassword") && {
                          // validate: {
                          //   checkPass: (value) => {
                          //     var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                          //     if (!re.test(value)) {
                          //       return "Please choose a stronger password. Try a mix of letters, numbers, and symbols.";
                          //     }
                          //     return true;
                          //   },
                          // },
                          minLength: {
                            value: !methods.watch("KeepOldPassword") && 8,
                            message: "Password must have at least 8 characters",
                          },
                        }
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{ marginRight: 6 }}
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                              disabled={methods.watch("KeepOldPassword")}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onBlur={() => methods.trigger()}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NumberFormatInForm
                      label="Port"
                      name="Port"
                      methods={methods}
                      disabled={mode === "view"}
                      decimal={0}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckBoxInForm label="Ssl" name="Ssl" methods={methods} disabled={mode === "view"} />
                  </Grid>
                  <Grid item xs={12}>
                    <CheckBoxInForm
                      label="Authentication"
                      name="Authentication"
                      methods={methods}
                      disabled={mode === "view"}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {mode !== "view" && <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />}
          </form>
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default Email;
