import axiosAuth from "../../utils/request";
import SnackbarUtils from "utils/SnackbarUtils";
//import { resolveBrowserLocale } from "react-admin";

export async function getReportList(groupName) {
  let uri =
    groupName === undefined
      ? `/api/report`
      : `/api/report?q={"WhereRaw":"ReportGroup='${groupName}'", "exclude":["File"]}`;
  const { data } = await axiosAuth.get(uri);
  //data.Data = data.Data.filter((item) => item.IsSystem === true);
  return data;
}

export async function getReport(id) {
  const uri = `/api/report/${id}`;
  const { data } = await axiosAuth.get(uri);
  return data;
}

export async function callStoreProcedurePost(name, parameters) {
  const uri = "/api/CallStoreProc";
  // {
  //      "SpName": "name",
  //      "Params": {"key1":"value1","key2":"value2", ...},
  //      "Timeout": 0,
  // }
  const body = {
    SpName: name,
    Params: parameters ?? {},
    Timeout: 0,
  };

  const { data } = await axiosAuth.post(uri, body);
  return data;
}

export async function showReport(report, param, fileFormat, isSave) {
  var isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    any: function () {
      return isMobile.Android() || isMobile.iOS();
    },
  };

  const openFile = (data, reportName, fileFormat) => {
    var blob = new Blob([data], { type: `application/${fileFormat}` });
    const objUrl = window.URL.createObjectURL(blob);
    if (isMobile.any()) {
      const link = document.createElement("a");
      link.href = objUrl;
      link.target = "_blank";
      link.download = `${reportName}.${fileFormat}`;
      link.click();
    } else {
      //window.open(urlPdf);
      const link = document.createElement("a");
      link.href = objUrl;
      link.target = "_blank";
      link.click();
    }
    //URL.revokeObjectURL(urlPdf);
  };

  const saveFile = (data, reportName, fileFormat) => {
    let blob = new Blob([data], { type: `application/${fileFormat}` });
    const urlPdf = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = urlPdf;
    link.target = "_blank";
    link.download = `${reportName}.${fileFormat}`;
    link.click();
  };

  // const openHTML = (data) => {
  //   var newTab = window.open("", "_blank");
  //   newTab.document.write(data);
  //   newTab.document.close();
  // };

  /* ---------------------------------------------------------------- */
  if (report) {
    isSave = isSave ?? false;
    let { apiUrl, arrCompany } = window.config;
    let accessToken = localStorage.getItem("AccessToken");
    let aToken = localStorage.getItem("adminToken");
    if (aToken) {
      let obj = arrCompany.find((i) => i.adminToken === aToken);
      apiUrl = obj.apiUrl;
    }

    if (!param) param = [];
    const reportData = {
      Id: report.Id,
      Type: "Report",
      FileFormat: fileFormat ?? "pdf",
      Parameters: param,
      Language: localStorage.getItem("Language"),
    };

    return await fetch(`${apiUrl}/api/report/web`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportData),
    })
      //response
      .then(async (response) => {
        if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (contentType) {
            if (contentType.includes("json")) {
              return await response.json();
            } else if (contentType.includes("html")) {
              return await response.text();
            } else {
              return await response.blob();
            }
          }
        }
        // error
        return response.text().then((text) => {
          throw new Error(JSON.parse(text).Message);
        });
      })
      // data
      .then((data) => {
        if (data instanceof Blob) {
          // type = application/{?} such as pdf, xlsx, docx
          if (isSave) {
            saveFile(data, report.ReportName, fileFormat);
          } else {
            openFile(data, report.ReportName, fileFormat);
          }
        } else {
          if (data) return data.Message;
          else throw new Error("Internal Error!");
          //openHTML(data);
        }
      })
      .catch((error) => {
        return error.message;
      });
  } else {
    return `Report not found`;
  }
}

export async function saveReport(report, param, fileFormat) {
  return showReport(report, param, fileFormat, true);
}

export async function exportReport(report, param) {
  if (report) {
    let { apiUrl, arrCompany } = window.config;
    let accessToken = localStorage.getItem("AccessToken");
    let aToken = localStorage.getItem("adminToken");

    if (aToken) {
      let obj = arrCompany.find((i) => i.adminToken === aToken);
      apiUrl = obj.apiUrl;
    }

    if (!param) param = [];

    const reportData = {
      Id: report.Id,
      Type: "Export",
      FileFormat: "",
      Parameters: param,
      Language: localStorage.getItem("Language"),
    };

    return await fetch(`${apiUrl}/api/report/web`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reportData),
    })
      //response
      .then((res) => {
        if (res.ok) {
          return res.blob();
        }

        // error
        return res.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => {
        if (data) {
          const parser = new DOMParser();
          const dom = parser.parseFromString(report.Dialog, "text/xml");
          let files = dom.getElementsByTagName("File")[0];

          let fileName = files ? files.getAttribute("Type") : "xlsx";

          if (fileName.toLocaleLowerCase() === "excel") fileName = `${report.ReportName}.xlsx`;
          else fileName = `${report.ReportName}.${fileName}`;

          let a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = fileName;
          a.click();
        } else {
          return "Invalid data";
        }
      })
      .catch((error) => {
        var err = JSON.parse(error.message);
        return err.Message;
      });
  } else {
    return `Report not found`;
  }
}

export async function showReportByName(reportName, param, fileFormat = "pdf") {
  const q = `%7B%22WhereGroupList%22%3A%5B%7B%22AndOr%22%3A%22And%22%2C%22ConditionList%22%3A%5B%7B%22AndOr%22%3A%22And%22%2C%22Field%22%3A%22ReportGroup%22%2C%22Operator%22%3A%22%3D%22%2C%22Value%22%3A%22FORM%22%7D%2C%7B%22AndOr%22%3A%22And%22%2C%22Field%22%3A%22ReportName%22%2C%22Operator%22%3A%22%3D%22%2C%22Value%22%3A%22${reportName}%22%7D%5D%7D%5D%7D`;
  const { data } = await axiosAuth.get(`/api/report?q=${q}`);
  const report = data.Data.length > 0 ? data.Data[0] : undefined;
  const r = await showReport(report, param, fileFormat, fileFormat === "pdf" ? false : true);
  if (typeof r === "string") {
    SnackbarUtils.error(r);
  }
  return true;
}
