import React, { useContext, useState, useEffect } from "react";
import { useTranslate, useLocale } from "react-admin";
import AccessDenied from "layout/AccessDenied";
import { GblContext } from "providers/formatter";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Divider, Grid, Popover, IconButton } from "@material-ui/core";
import { SwitchInForm, MuiAutosuggest } from "components/Form";
import {
  getYearList,
  getSettingClosePeriod,
  getSettingGl,
  updateSettingGl,
  getAccountCodeList,
  getDepartmentList,
} from "services/setting";
import DialogPrefix from "./Dialog/Prefix";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import { addMonths, startOfMonth, endOfMonth } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  tabPanel: { width: "100%", margin: "0 20px" },
  button: { textTransform: "none" },
}));

const GeneralLedger = (props) => {
  const { permissions, value, index, licenseList } = props;
  const translate = useTranslate();
  const locale = useLocale();
  let checkPermission = permissions && permissions.find((i) => i.Name === "GL.Prefix")?.View;

  const [anchorElReverse, setAnchorElReverse] = useState(null);
  const openReverse = Boolean(anchorElReverse);

  const handleReverseOpen = (event) => {
    setAnchorElReverse(event.currentTarget);
  };

  const handleReverseClose = () => {
    setAnchorElReverse(null);
  };

  const [anchorElGL, setAnchorElGL] = useState(null);
  const openGL = Boolean(anchorElGL);

  const handleClickGL = (event) => {
    setAnchorElGL(event.currentTarget);
  };

  const handleCloseGL = () => {
    setAnchorElGL(null);
  };

  const classes = useStyles();
  const { UpdateSettingAll, DateToString } = useContext(GblContext);
  const [lookupList, setLookupList] = useState({
    accountCodeList: [],
    departmentList: [],
  });
  const [closePeriodList, setClosePeriodList] = useState({
    ClosePeriodIncome: "",
    ClosePeriodAp: "",
    ClosePeriodGl: "",
    ClosePeriodAsset: "",
    ClosePeriodAr: "",
  });
  const [currentPeriodList, setCurrentPeriodList] = useState({
    CurrentPeriodIncome: "",
    CurrentPeriodAp: "",
    CurrentPeriodGl: "",
    CurrentPeriodAsset: "",
    CurrentPeriodAr: "",
  });
  const [oldAccList, setOldAccList] = useState([]);
  const [setting, setSetting] = useState();
  const [mode, setMode] = useState("view");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [disableDept, setDisableDept] = useState(true);
  const [openDPrefix, setOpenDPrefix] = useState(false);

  const methods = useForm({ defaultValues: setting });

  const { handleSubmit, reset } = methods;

  const fetchAccLookup = async () => {
    const { Data } = await getAccountCodeList("Gl");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
    }));
    setOldAccList(Data);
  };

  const fetchDeptLookup = async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  };

  const fetchClosePeriodList = async () => {
    const setting = await getSettingClosePeriod();
    if (setting) {
      setClosePeriodList(setting);
      setCurrentPeriod(setting);
      //let glClosePeriod = new Date(setting.ClosePeriodGl);
      // var thisyear = new Date();
      // if (glClosePeriod !== null) {
      //   thisyear = glClosePeriod.getMonth() === 11 ? glClosePeriod.getFullYear() + 1 : glClosePeriod.getFullYear();
      // }
      // const periodList = await getYearList(thisyear);
      const periodList = await getYearList();
      if (periodList?.Data.length > 0) {
        let ff = startOfMonth(new Date(periodList.Data[0].GlpDate));
        let ft = endOfMonth(new Date(periodList.Data[11].GlpDate));
        setCurrentPeriodList((state) => ({
          ...state,
          FiscalFrom: ff,
          FiscalTo: ft,
        }));
      }
    }
  };

  const fetchItem = async () => {
    const setting = await getSettingGl();
    if (setting) {
      setSetting(setting);
      reset(setting);
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    if (value === index) {
      fetchItem();
      fetchAccLookup();
      fetchDeptLookup();
      fetchClosePeriodList();
    }
  }, [value, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async (values) => {
    setBtnLoading(true);
    if (values.ForceUpdateDeptCodeTypeB && !values.DeptCodeForTypeB) {
      SnackbarUtils.warning("Please in put department for GL posting account");
      setBtnLoading(false);
      return;
    }

    let settingAll = JSON.parse(localStorage.getItem("SettingAll"));
    settingAll.SettingGl = values;
    const { Code, UserMessage } = await updateSettingGl(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
      setMode("view");
      fetchItem();
      localStorage.setItem("SettingAll", JSON.stringify(settingAll));
      UpdateSettingAll(settingAll);
    } else {
      SnackbarUtils.warning(UserMessage);
      setBtnLoading(false);
    }
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
    },
  ];

  const CancelFnc = () => {
    reset();
    setMode("view");
  };

  function setCurrentPeriod(date) {
    let currentAp = endOfMonth(addMonths(new Date(date.ClosePeriodAp), 1));
    let currentAr = endOfMonth(addMonths(new Date(date.ClosePeriodAr), 1));
    let currentAsset = endOfMonth(addMonths(new Date(date.ClosePeriodAsset), 1));
    let currentGl = endOfMonth(addMonths(new Date(date.ClosePeriodGl), 1));
    let currentIncome = endOfMonth(addMonths(new Date(date.ClosePeriodIncome), 1));

    setCurrentPeriodList((state) => ({
      ...state,
      CurrentPeriodIncome: currentIncome,
      CurrentPeriodAp: currentAp,
      CurrentPeriodGl: currentGl,
      CurrentPeriodAsset: currentAsset,
      CurrentPeriodAr: currentAr,
    }));
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {checkPermission && value === index && licenseList ? (
        <>
          <ActionMenu menuControl={menuControlProp} justifyContent="flex-start" />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.gl.Allow edit JV. from posting")}</Typography>

                <Typography variant="caption" style={{ color: "#9D9D9D" }}>
                  {translate("ra.gl.Allow edit JV. from posting desc")}
                </Typography>
              </Box>
              <Box>
                <SwitchInForm
                  name="AllowEditJvPostingFromSource"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Grid container direction="row" justifyContent="start" alignItems="center">
                  <Typography variant="subtitle1">{translate("ra.gl.Reverse Account Nature When Negative")}</Typography>
                  <IconButton size="small" onClick={handleReverseOpen} color="default">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                  <Popover
                    size={"lg"}
                    open={openReverse}
                    anchorEl={anchorElReverse}
                    onClose={handleReverseClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                  >
                    <Typography style={{ padding: 10 }}>
                      {translate("ra.gl.Reverse Account Nature When Negative info")}
                    </Typography>
                  </Popover>
                </Grid>
                <Typography variant="caption" style={{ color: "#9D9D9D" }}>
                  {translate("ra.gl.Reverse Account Nature When Negative desc")}
                </Typography>
              </Box>
              <Box>
                <SwitchInForm
                  name="ChangeNatureIfNegative"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.gl.Retain Earning Account")}</Typography>
                {/* <Typography variant="caption">Identify Retained Earnings account</Typography> */}
              </Box>
              <Box px={1} style={{ width: 160 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Department")}
                  name="RetainEarningDeptCode"
                  optKey="DeptCode"
                  optDesc="Description"
                  options={lookupList["departmentList"]}
                  useFncUpdate={true}
                  fncUpdate={(value, methods) => {
                    let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                    if (daccList?.length > 0) {
                      setLookupList((state) => ({
                        ...state,
                        accountCodeList: daccList,
                      }));
                      //remove acc if not in defaultaccount
                      let acc = methods.getValues("RetainEarningAccCode");
                      if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                        methods.setValue("RetainEarningAccCode", "");
                      }
                    } else {
                      setLookupList((state) => ({
                        ...state,
                        accountCodeList: oldAccList,
                      }));
                    }
                  }}
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
              <Box px={1} style={{ width: 160 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Account Code")}
                  name="RetainEarningAccCode"
                  optKey="AccCode"
                  optDesc={locale === "en-US" ? "Description" : "Description2"}
                  options={lookupList["accountCodeList"]}
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Grid container direction="row" justifyContent="start" alignItems="center">
                  <Typography variant="subtitle1">{translate("ra.gl.Gl Posting Account")}</Typography>
                  <IconButton size="small" onClick={handleClickGL} color="default">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                  <Popover
                    open={openGL}
                    anchorEl={anchorElGL}
                    onClose={handleCloseGL}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                  >
                    <Typography style={{ padding: 10 }}>{translate("ra.gl.Gl Posting Account info")}</Typography>
                  </Popover>
                </Grid>
              </Box>
              <Box px={1}>
                <SwitchInForm
                  name="ForceUpdateDeptCodeTypeB"
                  methods={methods}
                  disabled={mode === "view"}
                  onChange={(e) => {
                    setDisableDept(e);
                  }}
                  defaultChecked
                />
              </Box>
              <Box px={1} style={{ width: 160 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Department")}
                  name="DeptCodeForTypeB"
                  optKey="DeptCode"
                  optDesc="Description"
                  options={lookupList["departmentList"]}
                  methods={methods}
                  disabled={mode === "view" ? true : !disableDept ? true : false}
                  clearable
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.field.Setup Lookup")}</Typography>
                {/* <Typography variant="caption">add edit delete lookup</Typography> */}
              </Box>
              <Box>
                <Button variant="outlined" onClick={() => setOpenDPrefix(true)}>
                  {translate("ra.field.Prefix")}
                </Button>
              </Box>
            </Box>
            <Divider />
            {licenseList?.length > 0 && (
              <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
                <Box flexGrow={1}>
                  <Typography variant="subtitle1">{translate("ra.gl.Fiscal Year")}</Typography>
                  {/* <Typography variant="caption">Fiscal Year</Typography> */}
                </Box>
                <Box p={1} style={{ margin: "auto 0" }}>
                  {DateToString(currentPeriodList.FiscalFrom)}
                </Box>
                <Box p={1} style={{ margin: "auto 0" }}>
                  To
                </Box>
                <Box p={1} style={{ margin: "auto 0" }}>
                  {DateToString(currentPeriodList.FiscalTo)}
                </Box>
              </Box>
            )}
            {licenseList?.length > 0 && (
              <Box display="flex">
                <Box p={1} width="50%">
                  <Typography variant="body1">
                    <b>{translate("ra.gl.Closed Period")}</b>
                  </Typography>
                  {licenseList.map((item, idx) => (
                    <Box key={idx} p={1} display="flex" justifyContent="space-between">
                      <Box>{item.UniqueId.Description}</Box>
                      <Box>
                        {
                          {
                            GL: DateToString(closePeriodList.ClosePeriodGl),
                            AP: DateToString(closePeriodList.ClosePeriodAp),
                            AST: DateToString(closePeriodList.ClosePeriodAsset),
                            AR: DateToString(closePeriodList.ClosePeriodAr),
                          }[item.UniqueId.Id]
                        }
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box p={1} flexShrink={1} width="50%">
                  <Typography variant="body1">
                    <b>{translate("ra.gl.Current Period")}</b>
                  </Typography>
                  {licenseList.map((item, idx) => (
                    <Box key={idx} p={1} display="flex" justifyContent="space-between">
                      <Box>{item.UniqueId.Description}</Box>
                      <Box>
                        {
                          {
                            GL: DateToString(currentPeriodList.CurrentPeriodGl),
                            AP: DateToString(currentPeriodList.CurrentPeriodAp),
                            AST: DateToString(currentPeriodList.CurrentPeriodAsset),
                            AR: DateToString(currentPeriodList.CurrentPeriodAr),
                          }[item.UniqueId.Id]
                        }
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {mode !== "view" && <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />}
          </form>
          <DialogPrefix title={translate("ra.field.Prefix")} open={openDPrefix} onClose={() => setOpenDPrefix(false)} />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default GeneralLedger;
