export const lightTheme = {
  palette: {
    type: "light",
    primary: {
      light: "#4f83cc",
      main: "#34558b",
      dark: "#335482",
      contrastText: "#fff",
    },
    secondary: {
      light: "#EDF2F9",
      main: "#2196f3",
      dark: "#335482",
      contrastText: "#fff",
    },
    background: {
      default: "#fcfcfe",
    },
  },
  typography: {
    htmlFontSize: 18,
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "1.5em",
        },
        "*::-webkit-scrollbar-track": {
          backgroundColor: "#e4e4e4",
          borderRadius: "100px",
        },
        "*::-webkit-scrollbar-thumb": {
          height: "80px",
          borderRadius: "100px",
          border: "3px solid transparent",
          backgroundClip: "content-box",
          backgroundColor: "#a7a7a7",
        },
      },
    },
    RaLayout: {
      content: {
        marginLeft: "0.4rem",
        width: 200,
        padding: 12,
      },
    },
    RaMenuItemLink: {
      active: {
        background: "rgba(0, 0, 0, 0.08)",
      },
    },
    // targeting refresh button
    RaAppBar: {
      toolbar: {
        "& button": {
          "&:nth-last-child(2)": {
            display: "none",
          },
        },
      },
    },
    RaLogout: {
      menuItem: {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    RaLoading: {
      container: {
        height: "50vh !important",
        marginTop: "-3em !important",
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: "16px !important",
        paddingRight: "10px !important",
      },
    },
    MuiInputBase: {
      input: {
        padding: "3px 0 7px",
        // fontWeight: 600,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        overflowY: "unset !important",
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: "#34558b",
      },
      checked: { color: "#2196f3 !important" },
    },
    MUIDataTableHeadCell: {
      root: {
        backgroundColor: "#34558b",
      },
      sortLabelRoot: {
        height: "unset",
      },
      sortAction: {
        color: "white",
      },
      sortActive: {
        color: "white",
      },
      contentWrapper: {
        color: "white",
        marginLeft: "0.4rem",
      },
      fixedHeader: {
        color: "white",
        backgroundColor: "#34558b",
        padding: "8px 12px !important",
      },
    },
    MUIDataTableBodyRow: {
      root: {
        "&:nth-child(even)": {
          backgroundColor: "#f2f2f2",
        },
      },
    },
    MUIDataTableFilterList: {
      root: {
        margin: "-10px 16px 10px 16px",
      },
    },
    MUIDataTableToolbar: {
      root: {
        display: "flex !important",
      },
      titleText: {
        textAlign: "left  !important",
      },
      actions: {
        textAlign: "right !important",
      },
    },
    MuiTableSortLabel: {
      icon: {
        color: "white !important",
      },
    },
    MuiTablePagination: {
      selectRoot: {
        paddingTop: 6,
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: "6px 12px 6px 12px",
      },
      paddingCheckbox: {
        padding: "0 12px 0 16px !important",
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 4,
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
      inputMarginDense: {
        paddingTop: 10,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 4,
      },
      adornedEnd: {
        paddingRight: 0,
      },
      inputMarginDense: {
        paddingBottom: 6,
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 16px) scale(1)",
      },
    },
    MuiFormHelperText: {
      root: {
        whiteSpace: "nowrap",
      },
      contained: {
        marginLeft: 0,
      },
    },
    MuiButton: {
      root: {
        fontSize: "0.7777777777777778rem !important",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.3333333333333333rem !important",
      },
      fontSizeSmall: {
        fontSize: "1.1111111111111112rem !important",
      },
    },
    MuiAccordion: {
      root: {
        "&$expanded": {
          margin: "0 !important",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        "&$expanded": {
          minHeight: 32,
        },
      },
      content: {
        "&$expanded": {
          margin: "10px 0",
        },
      },
    },
  },
};

export const darkTheme = {
  palette: {
    type: "dark", // Switching the dark mode on is a single property value change.
    primary: {
      light: "#EDF2F9",
      main: "#2196f3",
      dark: "#335482",
      contrastText: "#fff",
    },
    secondary: {
      light: "#EDF2F9",
      main: "#2196f3",
      dark: "#335482",
      contrastText: "#fff",
    },
    background: {
      default: "#121212",
    },
  },
  typography: {
    htmlFontSize: 18,
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          "scrollbar-width": "thin",
        },
        "*::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
        },
      },
    },
    RaLayout: {
      content: {
        marginLeft: "0.4rem",
        width: 200,
        padding: 12,
      },
    },
    RaMenuItemLink: {
      active: {
        background: "rgba(255, 255, 255, 0.16)",
      },
    },
    // targeting refresh button
    RaAppBar: {
      toolbar: {
        "& button": {
          "&:nth-last-child(2)": {
            display: "none",
          },
        },
      },
    },
    RaLogout: {
      menuItem: {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: "16px !important",
        paddingRight: "10px !important",
      },
    },
    MuiInputBase: {
      input: {
        padding: "3px 0 7px",
        // fontWeight: 700,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        overflowY: "unset !important",
      },
    },
    MUIDataTableToolbar: {
      root: {
        display: "flex !important",
      },
      titleText: {
        textAlign: "left  !important",
      },
      actions: {
        textAlign: "right !important",
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: "#34558b",
      },
      checked: { color: "#2196f3 !important" },
    },
    MUIDataTableHeadCell: {
      sortLabelRoot: {
        height: "unset",
      },
      sortActive: {
        color: "white",
      },
      contentWrapper: {
        color: "white",
      },
      fixedHeader: {
        color: "white",
        backgroundColor: "#34558b",
        padding: "8px 12px !important",
      },
    },
    MUIDataTableBodyRow: {
      root: {
        "&:nth-child(even)": {
          backgroundColor: "#a7a7a7",
        },
      },
    },
    MUIDataTableFilterList: {
      root: {
        margin: "-10px 16px 10px 16px",
      },
    },
    MuiTableSortLabel: {
      icon: {
        color: "white !important",
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: "6px 12px 6px 12px",
      },
      paddingCheckbox: {
        padding: "0 12px 0 16px !important",
      },
    },
    MuiTablePagination: {
      selectRoot: {
        paddingTop: 6,
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 4,
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
      inputMarginDense: {
        paddingTop: 10,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 4,
      },
      adornedEnd: {
        paddingRight: 0,
      },
      inputMarginDense: {
        paddingBottom: 6,
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 16px) scale(1)",
      },
    },
    MuiFormHelperText: {
      root: {
        whiteSpace: "nowrap",
      },
      contained: {
        marginLeft: 0,
      },
    },
    MuiButton: {
      root: {
        fontSize: "0.7777777777777778rem !important",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.3333333333333333rem !important",
      },
      fontSizeSmall: {
        fontSize: "1.1111111111111112rem !important",
      },
    },
    MuiAccordion: {
      root: {
        "&$expanded": {
          margin: "0 !important",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        "&$expanded": {
          minHeight: 32,
        },
      },
      content: {
        "&$expanded": {
          margin: "10px 0",
        },
      },
    },
  },
};
