export default {
  ArContractHId: -1,
  ArNo: "",
  DtlNo: 0,
  ConStart: new Date(),
  ConEnd: null,
  ConDue: "",
  LastDue: "",
  Periodic: 1,
  ConLive: "",
  LastInvSeq: null,
  ConNext: "",
  ContractNo: "",
  ContractDate: "",
  ConHDesc: "",
  Owner: "",
  ProjectCode: "",
  CurCode: "",
  CurRate: 0,
  TotalAmount: 0,
  TotalBaseAmount: 0,
  PeriodicMonth: 1,
  Active: true,
  UserModified: localStorage.getItem("UserName"),
  Detail: [],
};
