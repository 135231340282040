/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import clsx from "clsx";
import { GblContext } from "providers/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Dialog, Icon } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  rootTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  statusA: {
    color: "#a5dc86",
  },
  statusR: {
    color: "#e57373",
  },
  statusS: {
    color: "#2196f3",
  },
}));

const DialogWorkflowHis = (props) => {
  const classes = useStyles();
  const { onClose, open, data, title } = props;
  const { DateTimeToString } = useContext(GblContext);
  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.rootTitle} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
        {title}
      </DialogTitle>
      <DialogContent dividers style={{ padding: 0 }}>
        <Timeline align="alternate">
          {data.map((item, idx) => (
            <TimelineItem key={idx}>
              <TimelineSeparator>
                <TimelineDot>
                  <div style={{ padding: "2px 7.2px" }}>{idx + 1}</div>
                </TimelineDot>
                {idx === data.length - 1 ? null : <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Icon
                    className={clsx(classes.statusA, {
                      [classes.statusR]: item.ApprCode === "R",
                      [classes.statusS]: item.ApprCode === "S",
                    })}
                  >
                    {
                      {
                        A: "assignment_turned_in",
                        R: "cancel_presentation",
                        S: "replay",
                      }[item.ApprCode]
                    }
                  </Icon>
                  <Typography variant="caption" component="h1">
                    {
                      {
                        A: "(Approve)",
                        R: "(Reject)",
                        S: "(Send Back)",
                      }[item.ApprCode]
                    }
                  </Typography>
                  <Typography variant="h6" component="h1">
                    {item.FromStepNo} <Icon>redo</Icon> {item.ToStepNo}
                  </Typography>
                  <Typography variant="body1" component="p">
                    {item.Remark}
                  </Typography>
                  <Typography variant="caption" component="p">
                    By {item.ApprBy} : {DateTimeToString(item.ApprDate)}
                  </Typography>
                  {/* <Typography>
                    {item.Users?.length > 0 && <Icon style={{ lineHeight: 1.4 }}>how_to_reg_icon</Icon>}{" "}
                    <span>{item.Users?.join("")}</span>
                  </Typography> */}
                  {/* <Typography>{JSON.stringify(item.Rule, 0, 2)}</Typography> */}
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
          <br />
        </Timeline>
        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
      </DialogContent>
    </Dialog>
  );
};

export default DialogWorkflowHis;
