import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import en from "i18n/en-US";
import th from "i18n/th-TH";
import vn from "i18n/vi-VN";

var successTitle, warningTitle, infoTitle, errorTitle, delTitle, voidTitle;
switch (localStorage.getItem("Language")) {
  case "th-TH":
    successTitle = th.ra.info.Success;
    warningTitle = th.ra.info.Warning;
    infoTitle = th.ra.info.Information;
    errorTitle = th.ra.info.Error;
    delTitle = th.ra.actionMenu.Delete;
    voidTitle = th.ra.actionMenu.Void;
    break;
  case "vi-VN":
    successTitle = vn.ra.info.Success;
    warningTitle = vn.ra.info.Warning;
    infoTitle = vn.ra.info.Information;
    errorTitle = vn.ra.info.Error;
    delTitle = vn.ra.actionMenu.Delete;
    voidTitle = vn.ra.actionMenu.Void;
    break;
  default:
    successTitle = en.ra.info.Success;
    warningTitle = en.ra.info.Warning;
    infoTitle = en.ra.info.Information;
    errorTitle = en.ra.info.Error;
    delTitle = en.ra.actionMenu.Delete;
    voidTitle = en.ra.actionMenu.Void;
    break;
}

const MySwal = withReactContent(Swal);
class SnackbarUtils {
  async success(msg = "Success", fnc) {
    const result = await Swal.fire({
      position: "top",
      icon: "success",
      customClass: {
        container: "swalContainer",
      },
      title: successTitle,
      html: msg,
      allowOutsideClick: false,
      confirmButtonColor: "#3085d6",
    });
    if (result.isConfirmed && fnc) {
      fnc();
    }
    //return this.toast(msg, { ...options, variant: "success", autoHideDuration: 3000 });
  }
  async warning(msg, fnc) {
    const result = await Swal.fire({
      position: "top",
      icon: "warning",
      customClass: {
        container: "swalContainer",
      },
      title: warningTitle,
      //text: msg,
      html: msg,
      allowOutsideClick: false,
      confirmButtonColor: "#3085d6",
    });
    if (result.isConfirmed && fnc) {
      fnc();
    }
    //return this.toast(msg, { ...options, variant: "warning", autoHideDuration: 3000 });
  }
  async info(msg, fnc) {
    const result = await Swal.fire({
      position: "top",
      icon: "info",
      customClass: {
        container: "swalContainer",
      },
      title: infoTitle,
      text: msg,
      allowOutsideClick: false,
      confirmButtonColor: "#3085d6",
    });
    if (result.isConfirmed && fnc) {
      fnc();
    }
    //return this.toast(msg, { ...options, variant: "info", autoHideDuration: 3000 });
  }
  async error(msg, fnc) {
    return Swal.fire({
      position: "top",
      icon: "error",
      customClass: {
        container: "swalContainer",
      },
      title: errorTitle,
      html: msg,
      allowOutsideClick: false,
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      if (result.isConfirmed && fnc) {
        fnc();
      }
    });
    //return this.toast(msg, { ...options, variant: "error", autoHideDuration: 3000 });
  }
  async delConfirm(msg = "Delete", fnc) {
    const result = await MySwal.fire({
      position: "top",
      title: delTitle,
      text: msg,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
      icon: "warning",
      customClass: {
        container: "swalContainer",
      },
    });
    if (result.isConfirmed) {
      fnc();
    }
  }
  async voidConfirm(msg = "Delete", fnc) {
    const result = await MySwal.fire({
      position: "top",
      title: voidTitle,
      text: msg,
      input: "textarea",
      inputLabel: "Notes :",
      inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
      icon: "warning",
      customClass: {
        container: "swalContainer",
      },
    });
    if (result.isConfirmed) {
      fnc(result.value);
    }
  }
  async confirm(msg = "Information", fnc, fncCancel) {
    const result = await MySwal.fire({
      position: "top",
      title: infoTitle,
      text: msg,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
      icon: "info",
      customClass: {
        container: "swalContainer",
      },
    });
    if (result.isConfirmed) {
      fnc();
    }
    if (result.isDismissed && fncCancel) {
      fncCancel();
    }
  }
  async loadingConfirm(msg = "Information", fnc, callbackFnc, fncCancel) {
    Swal.fire({
      position: "top",
      title: infoTitle,
      text: msg,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
      icon: "info",
      customClass: {
        container: "swalContainer",
      },
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return fnc()
          .then((response) => {
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (callbackFnc) {
          callbackFnc(result.value);
        }
      }
      if (result.isDismissed && fncCancel) {
        fncCancel();
      }
    });
  }
  async wfConfirm(title, msg, currentWfStep, wfSteps, fnc) {
    var optionHtml = "";
    if (title === "Send Back") {
      // optionHtml += `<option value='0'>Revise</option>`;
      wfSteps.forEach((item) => {
        if (currentWfStep > item.StepNo) {
          optionHtml += `<option value='${item.StepNo}'> ${item.StepName} </option>`;
        }
      });
    }

    const result = await MySwal.fire({
      position: "top",
      title: title,
      text: "",
      html:
        title === "Send Back"
          ? `<div class="select">
              <select class="select-text" id="wfSendBack">
                ${optionHtml}
              </select>
             </div>`
          : "",
      focusConfirm: false,
      preConfirm: () => {
        if (title === "Send Back") {
          return {
            SendBackToStepNo: document.getElementById("wfSendBack").value,
            Remark: document.getElementById("swal2-input").value,
          };
        } else {
          return {
            Remark: document.getElementById("swal2-input").value,
          };
        }
      },
      input: "textarea",
      inputLabel: "Notes :",
      inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
      icon: "info",
      customClass: {
        container: "swalContainer",
      },
    });
    if (result.isConfirmed) {
      fnc(result.value);
    }
  }

  async custom(props, fnc) {
    return Swal.fire({
      ...props,
    }).then((result) => {
      if (result.isConfirmed && fnc) {
        fnc();
      }
    });
  }
}

export default new SnackbarUtils();
