import React, { useContext, useState, useEffect } from "react";
import { useTranslate, useLocale } from "react-admin";
import AccessDenied from "layout/AccessDenied";
import { GblContext } from "providers/formatter";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Divider } from "@material-ui/core";
import {
  SelectInForm,
  SwitchInForm,
  NumberFormatInForm,
  MuiAutosuggest,
  DescInForm,
  TextFieldInForm,
} from "components/Form";
import { getSettingAr, updateSettingAr, getDepartmentList, getAccountCodeList, getUnitList } from "services/setting";
import { VatTypeOptions } from "utils/options";
import DialogArType from "./Dialog/ArType";
import DialogArTitle from "./Dialog/ArTitle";
import DialogArProject from "./Dialog/ArProject";
import DialogArOwner from "./Dialog/ArOwner";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  tabPanel: { width: "100%", margin: "0 20px" },
  button: { textTransform: "none" },
}));

const AccountReceivable = (props) => {
  const translate = useTranslate();
  const { permissions, value, index } = props;
  const locale = useLocale();
  const checkPermission = permissions?.find(
    (i) => i.Name === "AR.Type" || i.Name === "AR.Title" || i.Name === "AR.Owner" || i.Name === "AR.Project"
  )?.View;
  const classes = useStyles();
  const { UpdateSettingAll, ToNumber } = useContext(GblContext);
  const [oldAccList, setOldAccList] = useState([]);
  const [lookupList, setLookupList] = useState({
    departmentList: [],
    accountCodeListINV: [],
    accountCodeListRE: [],
    unitList: [],
  });
  const [setting, setSetting] = useState();
  const [mode, setMode] = useState("view");
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [openArType, setOpenArType] = useState(false);
  const [openArTitle, setOpenArTitle] = useState(false);
  const [openArProject, setOpenArProject] = useState(false);
  const [openArOwner, setOpenArOwner] = useState(false);

  const methods = useForm({ defaultValues: setting });

  const { handleSubmit, reset } = methods;

  const fetchDeptLookup = async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  };
  const fetchAccLookup = async () => {
    const { Data } = await getAccountCodeList("Ar");
    setLookupList((state) => ({
      ...state,
      accountCodeListINV: Data,
      accountCodeListRE: Data,
    }));
    setOldAccList(Data);
  };
  const fetchUnitLookup = async () => {
    const { Data } = await getUnitList();
    setLookupList((state) => ({
      ...state,
      unitList: Data,
    }));
  };

  const fetchItem = async () => {
    const setting = await getSettingAr();
    if (setting) {
      setSetting(setting);
      reset(setting);
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    if (value === index) {
      fetchItem();
      fetchDeptLookup();
      fetchAccLookup();
      fetchUnitLookup();
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async (values) => {
    setBtnLoading(true);
    values.TaxRate1 = ToNumber(values.TaxRate1);
    values.TaxRate2 = ToNumber(values.TaxRate2);
    values.ReceiptTaxRate = ToNumber(values.ReceiptTaxRate);
    const settingAll = JSON.parse(localStorage.getItem("SettingAll"));
    settingAll.SettingAr = values;

    const { Code, UserMessage } = await updateSettingAr(values);
    if (Code === 0) {
      localStorage.setItem("SettingAll", JSON.stringify(settingAll));
      UpdateSettingAll(settingAll);
      SnackbarUtils.success(UserMessage);
      setMode("view");
      fetchItem();
    } else {
      SnackbarUtils.warning(UserMessage);
      setBtnLoading(false);
    }
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
    },
  ];

  const CancelFnc = () => {
    reset();
    setMode("view");
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {checkPermission && value === index ? (
        <>
          <ActionMenu menuControl={menuControlProp} justifyContent="flex-start" />
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
            <Box display="flex">
              <Box p={1} width="50%">
                <Typography variant="body1">
                  <b>{translate("ra.ar.Default Value (A/R Invoice)")}</b>
                </Typography>
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.field.Department")}</Typography>
                {/* <Typography variant="caption">Set default department</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Dept. Code")}
                  name="InvoiceDeptCode"
                  optKey="DeptCode"
                  optDesc="Description"
                  options={lookupList["departmentList"]}
                  updateOtherField={[{ key: "InvoiceDeptDesc", optKey: "Description" }]}
                  useFncUpdate={true}
                  fncUpdate={(value, methods) => {
                    const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                    if (daccList?.length > 0) {
                      setLookupList((state) => ({
                        ...state,
                        accountCodeListINV: daccList,
                      }));
                      //remove acc if not in defaultaccount
                      const drAcc = methods.getValues("InvoiceDrAcc");
                      const crAcc = methods.getValues("InvoiceCrAcc");
                      const drTax1Acc = methods.getValues("TaxAcc1");
                      const drTax2Acc = methods.getValues("TaxAcc2");
                      if (drAcc !== "" && !daccList.find((i) => i.AccCode === drAcc)) {
                        methods.setValue("InvoiceDrAcc", "");
                        methods.setValue("InvoiceDrAccDesc", "");
                      }
                      if (crAcc !== "" && !daccList.find((i) => i.AccCode === crAcc)) {
                        methods.setValue("InvoiceCrAcc", "");
                        methods.setValue("InvoiceCrAccDesc", "");
                      }
                      if (drTax1Acc !== "" && !daccList.find((i) => i.AccCode === drTax1Acc)) {
                        methods.setValue("TaxAcc1", "");
                        methods.setValue("TaxAcc1Desc", "");
                      }
                      if (drTax2Acc !== "" && !daccList.find((i) => i.AccCode === drTax2Acc)) {
                        methods.setValue("TaxAcc2", "");
                        methods.setValue("TaxAcc2Desc", "");
                      }
                    } else {
                      setLookupList((state) => ({
                        ...state,
                        accountCodeListINV: oldAccList,
                      }));
                    }
                  }}
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
              <Box px={1} style={{ width: 180 }}>
                <DescInForm
                  name="InvoiceDeptDesc"
                  methods={methods}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ar.(Debit) Account Code")}</Typography>
                {/* <Typography variant="caption">Set default debit accountcode</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Dr Acc. Code")}
                  name="InvoiceDrAcc"
                  optKey="AccCode"
                  optDesc={locale === "en-US" ? "Description" : "Description2"}
                  options={lookupList["accountCodeListINV"]}
                  updateOtherField={[
                    {
                      key: "InvoiceDrAccDesc",
                      optKey: locale === "en-US" ? "Description" : "Description2",
                    },
                  ]}
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
              <Box px={1} style={{ width: 180 }}>
                <DescInForm
                  name="InvoiceDrAccDesc"
                  methods={methods}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ar.(Credit) Account Code")}</Typography>
                {/* <Typography variant="caption">Set default credit accountcode</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Cr Acc. Code")}
                  name="InvoiceCrAcc"
                  optKey="AccCode"
                  optDesc={locale === "en-US" ? "Description" : "Description2"}
                  options={lookupList["accountCodeListINV"]}
                  updateOtherField={[
                    {
                      key: "InvoiceCrAccDesc",
                      optKey: locale === "en-US" ? "Description" : "Description2",
                    },
                  ]}
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
              <Box px={1} style={{ width: 180 }}>
                <DescInForm
                  name="InvoiceCrAccDesc"
                  methods={methods}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.field.Tax")} 1</Typography>
                {/* <Typography variant="caption">Set default Tax1</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                {methods.watch("TaxRate1") !== undefined && (
                  <SelectInForm
                    label={translate("ra.field.Tax Type")}
                    name="TaxType1"
                    options={VatTypeOptions}
                    methods={methods}
                    disabled={mode === "view"}
                    clearOtherFieldError={"TaxRate1"}
                  />
                )}
              </Box>
              <Box px={1} style={{ width: 180 }}>
                {methods.watch("TaxRate1") !== undefined && (
                  <NumberFormatInForm
                    style={{ marginTop: 8 }}
                    label={translate("ra.field.Tax Rate")}
                    name="TaxRate1"
                    methods={methods}
                    disabled={mode === "view"}
                    rule={{
                      min: {
                        value: methods.watch("TaxType1") !== "None" ? 1 : 0,
                        message: "* Required",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
            {methods.watch("TaxType1") !== "None" && (
              <Box p={1} display="flex" alignItems="center" justifyContent="flex-end">
                <Box style={{ width: 160 }}>
                  <MuiAutosuggest
                    label={translate("ra.field.Account Code")}
                    name="TaxAcc1"
                    optKey="AccCode"
                    optDesc={locale === "en-US" ? "Description" : "Description2"}
                    options={lookupList["accountCodeListINV"]}
                    updateOtherField={[
                      {
                        key: "TaxAcc1Desc",
                        optKey: locale === "en-US" ? "Description" : "Description2",
                      },
                    ]}
                    methods={methods}
                    disabled={mode === "view"}
                    rule={{
                      required: {
                        value: methods.watch("TaxType1") !== "None",
                        message: "* Required",
                      },
                    }}
                  />
                </Box>
                <Box px={1} style={{ width: 180 }}>
                  <DescInForm
                    name="TaxAcc1Desc"
                    methods={methods}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.field.Tax")} 2</Typography>
                {/* <Typography variant="caption">Set default Tax2</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                {methods.watch("TaxType2") && (
                  <SelectInForm
                    label={translate("ra.field.Tax Type")}
                    name="TaxType2"
                    options={VatTypeOptions}
                    methods={methods}
                    disabled={mode === "view"}
                    clearOtherFieldError={"TaxRate2"}
                  />
                )}
              </Box>
              <Box p={1} style={{ width: 180 }}>
                {methods.watch("TaxRate2") !== undefined && (
                  <NumberFormatInForm
                    style={{ marginTop: 8 }}
                    label={translate("ra.field.Tax Rate")}
                    name="TaxRate2"
                    methods={methods}
                    disabled={mode === "view"}
                    rule={{
                      min: {
                        value: methods.watch("TaxType2") !== "None" ? 1 : 0,
                        message: "* Required",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
            {methods.watch("TaxType2") !== "None" && (
              <Box p={1} display="flex" alignItems="center" justifyContent="flex-end">
                <Box style={{ width: 160 }}>
                  <MuiAutosuggest
                    label={translate("ra.field.Account Code")}
                    name="TaxAcc2"
                    optKey="AccCode"
                    optDesc={locale === "en-US" ? "Description" : "Description2"}
                    options={lookupList["accountCodeListINV"]}
                    updateOtherField={[
                      {
                        key: "TaxAcc2Desc",
                        optKey: locale === "en-US" ? "Description" : "Description2",
                      },
                    ]}
                    methods={methods}
                    disabled={mode === "view"}
                    rule={{
                      required: {
                        value: methods.watch("TaxType2") !== "None",
                        message: "* Required",
                      },
                    }}
                  />
                </Box>
                <Box px={1}>
                  <DescInForm
                    name="TaxAcc2Desc"
                    methods={methods}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Box>
            )}
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ar.Allow Edit Folio In (A/R Invoice)")}</Typography>
                {/* <Typography variant="caption">Copy Receipt Ref No. to Tax Invoice</Typography> */}
              </Box>
              <Box>
                <SwitchInForm
                  name="AllowEditFolioInInvoice"
                  methods={methods}
                  disabled={mode === "view"}
                  defaultChecked
                />
              </Box>
            </Box>
            <Divider />
            <Box display="flex">
              <Box p={1} width="50%">
                <Typography variant="body1">
                  <b>{translate("ra.ar.Default Value (A/R Receipt)")}</b>
                </Typography>
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.field.Department")}</Typography>
                {/* <Typography variant="caption">Set default department</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Dept. Code")}
                  name="ReceiptDeptCode"
                  optKey="DeptCode"
                  optDesc="Description"
                  options={lookupList["departmentList"]}
                  updateOtherField={[{ key: "ReceiptDeptDesc", optKey: "Description" }]}
                  useFncUpdate={true}
                  fncUpdate={(value, methods) => {
                    const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                    if (daccList?.length > 0) {
                      setLookupList((state) => ({
                        ...state,
                        accountCodeListRE: daccList,
                      }));
                      //remove acc if not in defaultaccount
                      const drAcc = methods.getValues("ReceiptDrAcc");
                      const crAcc = methods.getValues("ReceiptCrAcc");
                      if (drAcc !== "" && !daccList.find((i) => i.AccCode === drAcc)) {
                        methods.setValue("ReceiptDrAcc", "");
                        methods.setValue("ReceiptDrAccDesc", "");
                      }
                      if (crAcc !== "" && !daccList.find((i) => i.AccCode === crAcc)) {
                        methods.setValue("ReceiptCrAcc", "");
                        methods.setValue("ReceiptCrAccDesc", "");
                      }
                    } else {
                      setLookupList((state) => ({
                        ...state,
                        accountCodeListRE: oldAccList,
                      }));
                    }
                  }}
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
              <Box px={1} style={{ width: 180 }}>
                <DescInForm
                  name="ReceiptDeptDesc"
                  methods={methods}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">
                  {translate("ra.ar.(Debit) Account Code")}
                  {/* ({translate("ra.field.Debit")}) {translate("ra.field.Account Code")} */}
                </Typography>
                {/* <Typography variant="caption">Set default debit accountcode</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Dr Acc. Code")}
                  name="ReceiptDrAcc"
                  optKey="AccCode"
                  optDesc={locale === "en-US" ? "Description" : "Description2"}
                  options={lookupList["accountCodeListRE"]}
                  updateOtherField={[
                    {
                      key: "ReceiptDrAccDesc",
                      optKey: locale === "en-US" ? "Description" : "Description2",
                    },
                  ]}
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
              <Box px={1} style={{ width: 180 }}>
                <DescInForm
                  name="ReceiptDrAccDesc"
                  methods={methods}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ar.(Credit) Account Code")}</Typography>
                {/* <Typography variant="caption">Set default credit accountcode</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Cr Acc. Code")}
                  name="ReceiptCrAcc"
                  optKey="AccCode"
                  optDesc={locale === "en-US" ? "Description" : "Description2"}
                  options={lookupList["accountCodeListRE"]}
                  updateOtherField={[
                    {
                      key: "ReceiptCrAccDesc",
                      optKey: locale === "en-US" ? "Description" : "Description2",
                    },
                  ]}
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
              <Box px={1} style={{ width: 180 }}>
                <DescInForm
                  name="ReceiptCrAccDesc"
                  methods={methods}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.field.Tax")}</Typography>
                {/* <Typography variant="caption">Set default Tax</Typography> */}
              </Box>
              <Box style={{ width: 160 }}>
                {methods.watch("ReceiptTaxType") && (
                  <SelectInForm
                    label={translate("ra.field.Tax Type")}
                    name="ReceiptTaxType"
                    options={VatTypeOptions}
                    methods={methods}
                    disabled
                  />
                )}
              </Box>
              <Box p={1} style={{ width: 180 }}>
                {methods.watch("ReceiptTaxRate") !== undefined && (
                  <NumberFormatInForm
                    style={{ marginTop: 8 }}
                    label={translate("ra.field.Tax Rate")}
                    name="ReceiptTaxRate"
                    methods={methods}
                    disabled={mode === "view"}
                  />
                )}
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ar.Copy Receipt Ref No. to Tax Invoice")}</Typography>
                {/* <Typography variant="caption">Copy Receipt Ref No. to Tax Invoice</Typography> */}
              </Box>
              <Box>
                <SwitchInForm name="AutoTaxInv" methods={methods} disabled={mode === "view"} defaultChecked />
              </Box>
            </Box>
            <Divider />
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ap.Default Unit")}</Typography>
                {/* <Typography variant="caption">Set default unit</Typography> */}
              </Box>
              <Box style={{ width: 160 }} />
              <Box px={1} style={{ width: 180 }}>
                <MuiAutosuggest
                  label={translate("ra.field.Unit")}
                  name="DefaultUnit"
                  optKey="UnitCode"
                  optDesc="Description"
                  options={lookupList["unitList"]}
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
            </Box>
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.ar.Invoice - Tax Invoice Prefix")}</Typography>
              </Box>
              <Box style={{ width: 160 }} />
              <Box px={1} style={{ width: 180 }}>
                <TextFieldInForm
                  fullWidth
                  variant="outlined"
                  margin="dense"
				  label={translate("ra.field.Prefix")}
                  name="InvPrefix"
                  methods={methods}
                  disabled={mode === "view"}
                />
              </Box>
            </Box>
            <Box p={1} style={{ marginBottom: 20 }} display="flex" alignItems="center" justifyContent="space-between">
              <Box flexGrow={1}>
                <Typography variant="subtitle1">{translate("ra.field.Setup Lookup")}</Typography>
                {/* <Typography variant="caption">add edit delete lookup</Typography> */}
              </Box>
              <Box px={1}>
                <Button variant="outlined" onClick={() => setOpenArType(true)}>
                  {translate("ra.field.Type")}
                </Button>
              </Box>
              <Box px={1}>
                <Button variant="outlined" onClick={() => setOpenArTitle(true)}>
                  {translate("ra.field.Title")}
                </Button>
              </Box>
              <Box px={1}>
                <Button variant="outlined" onClick={() => setOpenArOwner(true)}>
                  {translate("ra.field.Owner")}
                </Button>
              </Box>
              <Box px={1}>
                <Button variant="outlined" onClick={() => setOpenArProject(true)}>
                  {translate("ra.field.Project")}
                </Button>
              </Box>
            </Box>

            {mode !== "view" && <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />}
          </form>
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(methods.watch(), 0, 2) : ""}</pre>
          <DialogArType title={translate("ra.field.Type")} open={openArType} onClose={() => setOpenArType(false)} />
          <DialogArTitle title={translate("ra.field.Title")} open={openArTitle} onClose={() => setOpenArTitle(false)} />

          <DialogArOwner title={translate("ra.field.Owner")} open={openArOwner} onClose={() => setOpenArOwner(false)} />
          <DialogArProject
            title={translate("ra.field.Project")}
            open={openArProject}
            onClose={() => setOpenArProject(false)}
          />
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default AccountReceivable;
