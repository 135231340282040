/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  Grid,
  Switch,
  Divider,
  Typography,
  Select,
  Checkbox,
  InputLabel,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import { TextFieldInForm } from "components/Form";
import CardImage from "components/CardImage";
import PreviewUser from "assets/user.png";
import ButtonUpload from "components/ButtonUpload";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import SnackbarUtils from "utils/SnackbarUtils";
import fileReader from "utils/fileReader";

import {
  getUserByUserName,
  getTenantList,
  getUserSearchList,
  addOrDeleteTenant,
  createUserDetail,
  updateUserDetail,
  updateUserTenantToActive,
  updateUserTenantToInActive,
  delUserDetail,
} from "services/setting";
import Model from "models/user";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogItem = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { children, username, mode, setMode, open, onClose, permission } = props;
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [buDesc, setBuDesc] = useState([]);
  const [buList, setBuList] = useState([]);
  const [data, setData] = useStateWithCallbackLazy(Model);
  const methods = useForm({ defaultValues: data });

  const { handleSubmit, reset, control } = methods;

  const fetchDetailByUsername = async () => {
    if (username && username !== "") {
      const { Data } = await getUserByUserName(username);
      const activeData = Data.filter((i) => i.Status.Active);
      if (activeData && activeData.length > 0) {
        const tenantList = activeData.map((i) => i.Tenant);
        const buDesc = activeData.map((i) => i.Tenant.Description);
        setBuDesc(buDesc);
        const newData = {
          ...activeData[0]?.User,
          KeepOldPassword: true,
          ...activeData[0]?.Status,
          tenantList,
          buDesc,
        };
        setData(newData);
        reset(newData);
      } else {
        const qs = {
          Limit: 1,
          Page: 1,
          WhereLike: `%${username}%`,
          WhereLikeFields: ["UserName"],
        };
        const { Data } = await getUserSearchList(qs);
        if (Data && Data.length > 0) {
          const newData = { ...Data[0], KeepOldPassword: true };
          setData(newData);
          reset(newData);
        }
      }
    } else {
      setData(Model);
      reset(Model);
    }
    setBtnLoading(false);
  };

  const fetchTenantList = async () => {
    const { Data } = await getTenantList();
    if (Data) {
      setBuList(Data);
    }
  };

  useEffect(() => {
    fetchDetailByUsername();
    fetchTenantList();
  }, []);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    //Adjust parameter before save
    setData(
      (state) => ({
        ...state,
        ...values,
        UserModified: Model.UserModified,
      }),
      (nextState) => Save(nextState)
    );
  };

  const setTenantParam = async (v, selectedBuDesc) => {
    const OldTenant = v?.tenantList ? v.tenantList : [];
    //1. loop OldTenant clear notIn selectTenant
    OldTenant.forEach(async (tItem) => {
      const notInList = selectedBuDesc.includes(tItem.Description);
      if (!notInList) {
        const { Code, UserMessage } = await addOrDeleteTenant(v.UserName, tItem.Tenant, "Delete");
        if (Code === 0) {
          console.log(UserMessage);
        }
      }
    });

    //2. add all selectedTenant
    selectedBuDesc.forEach(async (tenantDesc) => {
      const itemAdd = buList.find((i) => i.Description === tenantDesc);
      const { Code, UserMessage } = await addOrDeleteTenant(v.UserName, itemAdd.Tenant, "Add");
      if (Code === 0) {
        console.log(UserMessage);
      }
    });
  };

  const updateUserTenant = async (v, selectedBuDesc) => {
    selectedBuDesc.forEach(async (tenantDesc) => {
      const buItem = buList.find((i) => i.Description === tenantDesc);
      const param = {
        Tenant: buItem.Tenant,
        UserName: v.UserName,
      };
      if (v.Active) {
        const { Code, UserMessage } = await updateUserTenantToActive(param);
        if (Code === 0) {
          console.log(UserMessage, "after updateUserTenantToActive");
        }
      } else {
        const { Code, UserMessage } = await updateUserTenantToInActive(param);
        if (Code === 0) {
          console.log(UserMessage, "after updateUserTenantToInActive");
        }
      }
    });
  };

  const Save = async (v) => {
    const userDetail = Object.assign(
      {},
      {
        UserId: v.UserId,
        UserName: v.UserName,
        Active: v.Active,
        Email: v.Email,
        Photo: v.Photo,
        KeepOldPassword: v.KeepOldPassword,
        Password: v.Password,
        UserModified: localStorage.getItem("UserName"),
      }
    );
    await setTenantParam(v, buDesc);
    if (mode === "edit") {
      //await setTenantParam(v, buDesc);
      //Update
      const { Code, InternalMessage, UserMessage } = await updateUserDetail(userDetail);
      if (Code === 0) {
        await updateUserTenant(v, buDesc);
        SnackbarUtils.success(UserMessage, function () {
          handleClose(v.UserName);
        });
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    } else {
      const { Code, InternalMessage, UserMessage } = await createUserDetail(userDetail);
      if (Code === 0) {
        //await setTenantParam(v, buDesc);
        SnackbarUtils.success(UserMessage, function () {
          handleClose(0);
        });
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    }
  };

  const handleClose = (value) => {
    onClose(value);
  };

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
      disabled: mode !== "view",
    },
    {
      name: "Delete",
      fnc: () => CheckDeleteBtn(),
      disabled: mode !== "view",
    },
  ];

  const CheckDeleteBtn = () => {
    if (username === localStorage.getItem("UserName")) {
      SnackbarUtils.error("This user has been used in the system, cannot be delete");
    } else {
      DelOrVoid(data.UserId);
    }
  };

  const DelOrVoid = async (id) => {
    const msg = translate("ra.question.confirmDel");
    SnackbarUtils.delConfirm(msg, async function () {
      const { Code, UserMessage } = await delUserDetail(id);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          handleClose(id);
        });
      }
    });
  };

  const UploadImg = (e) => {
    if (e.target.files[0]) {
      const msg = fileReader.CheckLimitImgSize(e.target.files);
      if (msg) {
        document.getElementById("user_img").value = "";
        SnackbarUtils.error(translate(msg, { size: "5" }));
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        setData((state) => ({
          ...state,
          Photo: base64String,
        }));
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const CancelFnc = () => {
    if (username === "") {
      onClose();
      return;
    }
    fetchDetailByUsername(username);
    setMode("view");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            onClose(event);
          }
        }}
        scroll={"paper"}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title" onClose={onClose}>
          {props.title}
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <ActionMenu menuControl={menuControlProp} permission={permission} justifyContent="flex-start" />
          <Divider />
          <form onKeyDown={disableFormEnter} autoComplete="off">
            <div className={classes.root}>
              <Grid container spacing={1} justifyContent="flex-start">
                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                  {data?.Photo ? (
                    <CardImage base64Src={data.Photo} noBorder={true} customSize={{ height: 100 }} circle={true} />
                  ) : (
                    <CardImage imgSrc={PreviewUser} noBorder={true} customSize={{ height: 100 }} circle={true} />
                  )}
                </Grid>
                {mode !== "view" && (
                  <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                    <ButtonUpload
                      id="user_img"
                      name="user_img"
                      style={{ display: "none" }}
                      type="file"
                      onChange={UploadImg}
                      accept="image/png, image/gif, image/jpeg"
                      btntext="Change Image"
                    />
                  </Grid>
                )}
                <Grid item xs={6} elevation={2}>
                  <TextFieldInForm
                    label={`* ${translate("ra.field.Username")}`}
                    name="UserName"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    disabled={mode !== "add"}
                    autoComplete="off"
                    rule={{
                      required: {
                        value: true,
                        message: "* Required",
                      },
                      maxLength: {
                        value: 10,
                        message: "maximum length is 10",
                      },
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    onClick={(e) => e.target.removeAttribute("readonly")}
                  />
                </Grid>
                <Grid item xs={6} elevation={2}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="Active"
                      control={control}
                      defaultValue=""
                      render={(props) => {
                        return (
                          <FormControlLabel
                            value={props.value}
                            control={
                              <Switch
                                checked={typeof props.value === "boolean" ? props.value : false}
                                onChange={(e, newValue) => props.onChange(newValue)}
                                disabled={mode === "view"}
                              />
                            }
                            label={props.value ? translate("ra.field.Active") : translate("ra.field.In-Active")}
                            labelPlacement="start"
                            color="primary"
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} elevation={2}>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="KeepOldPassword"
                      control={control}
                      defaultValue=""
                      render={(props) => {
                        return (
                          <FormControlLabel
                            value={props.value}
                            control={
                              <Switch
                                checked={typeof props.value === "boolean" ? props.value : false}
                                onChange={(e, newValue) => props.onChange(newValue)}
                                disabled={mode !== "edit"}
                              />
                            }
                            label={props.value ? "Keep current password" : "Keep current password"}
                            labelPlacement="start"
                            color="primary"
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextFieldInForm
                    variant="outlined"
                    margin="dense"
                    required={!methods.watch("KeepOldPassword")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    label={`* ${translate("ra.field.Password")}`}
                    name="Password"
                    autoComplete="off"
                    methods={methods}
                    disabled={mode === "view" || methods.watch("KeepOldPassword")}
                    rule={
                      !methods.watch("KeepOldPassword") && {
                        validate: {
                          checkPass: (value) => {
                            const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                            if (!re.test(value)) {
                              return "Please choose a stronger password. Try a mix of letters, numbers, and symbols.";
                            }
                            return true;
                          },
                        },
                        minLength: {
                          value: !methods.watch("KeepOldPassword") && 8,
                          message: "Password must have at least 8 characters",
                        },
                      }
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{ marginRight: 6 }}
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            disabled={methods.watch("KeepOldPassword")}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onBlur={() => methods.trigger()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldInForm
                    variant="outlined"
                    margin="dense"
                    required={!methods.watch("KeepOldPassword")}
                    type={showRePassword ? "text" : "password"}
                    id="rePassword"
                    label={`* ${translate("ra.field.ConfirmPassword")}`}
                    name="RePassword"
                    autoComplete="off"
                    methods={methods}
                    disabled={mode === "view" || methods.watch("KeepOldPassword")}
                    rule={
                      !methods.watch("KeepOldPassword") && {
                        validate: {
                          checkPass: (value) => {
                            const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                            if (!re.test(value)) {
                              return "Please choose a stronger password. Try a mix of letters, numbers, and symbols.";
                            }
                            return true;
                          },
                          confirm: (value) => value === methods.watch("Password") || "The passwords do not match",
                        },
                        minLength: {
                          value: !methods.watch("KeepOldPassword") && 8,
                          message: "Password must have at least 8 characters",
                        },
                      }
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{ marginRight: 6 }}
                            aria-label="toggle password visibility"
                            onClick={() => setShowRePassword(!showRePassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            disabled={methods.watch("KeepOldPassword")}
                            edge="end"
                          >
                            {showRePassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onBlur={() => methods.trigger()}
                  />
                </Grid>
                <Grid item xs={12} elevation={2}>
                  <TextFieldInForm
                    label={translate("ra.field.Email")}
                    name="Email"
                    type="email"
                    variant="outlined"
                    margin="dense"
                    methods={methods}
                    disabled={mode === "view"}
                    rule={{
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email",
                      },
                      maxLength: {
                        value: 40,
                        message: "maximum length is 40",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} elevation={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="label-bu" style={{ marginTop: buDesc.length === 0 && -5 }}>
                      {translate("ra.field.Business Unit")}
                    </InputLabel>
                    <Select
                      variant="outlined"
                      margin="dense"
                      labelId="label-bu"
                      label={translate("ra.field.Business Unit")}
                      multiple
                      value={buDesc}
                      onChange={(e) => {
                        const desc = e.target.value;
                        setBuDesc(desc);
                      }}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      disabled={mode === "view"}
                    >
                      {buList.map((item) => (
                        <MenuItem key={item.Tenant} value={item.Description}>
                          <Checkbox checked={buDesc.indexOf(item.Description) > -1} />
                          <ListItemText primary={item.Description} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            {children}
          </form>
          <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(methods.errors, 0, 2) : ""}</pre>
        </DialogContent>
        {mode !== "view" ? (
          <DialogActions>
            <ButtonFooter noBorder disabled={isBtnLoading} SaveFnc={handleSubmit(onSubmit)} CancelFnc={CancelFnc} />
          </DialogActions>
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
};

export default DialogItem;
