/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { useTheme } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useTranslate } from "react-admin";
import { Box, FormControl, Select, InputLabel, MenuItem } from "@material-ui/core";

import {
  getEditVatReconcileByPrefix,
  exportVatReconcile,
  delEditVatReconcileDetail,
  updateEditVatReconcileDetail,
  createEditVatReconcileDetail,
} from "services/reconcile";
//import { showReportByName } from "pages/Report/services";
import { getApVendorList } from "services/accountPayable";
import { TableHead, TableRow, TableCell, Tooltip, Icon, IconButton } from "@material-ui/core";
import { MTableHeader, MTableToolbar } from "material-table";
import MeterialtableTranslate from "components/MeterialtableTranslate";
// import ActionMenu from "components/ActionMenu";
import PopupTable from "components/PopupTable";
import { TextFieldInForm, DateInForm, NumberFormatInForm, MuiAutosuggest } from "components/Form";
import { format, addDays, startOfMonth, endOfMonth } from "date-fns";
import Model from "models/editVatReconcile";
import SnackbarUtils from "utils/SnackbarUtils";

const EditVATTab = (props) => {
  const translate = useTranslate();
  const theme = useTheme();
  const { settingAll, DateToString, NumberFormat, ToMySqlDate, FindTaxAmount, ToNumber } = useContext(GblContext);
  const { SettingClosePeriod } = settingAll;
  const { ClosePeriodAp } = SettingClosePeriod;
  let newClosePeriodAp = addDays(new Date(ClosePeriodAp), 1);
  const { taxPeriodList } = props;
  const [initNewRow] = useStateWithCallbackLazy(Model);
  const [showAdd, setShowAdd] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [checkSource, setCheckSource] = useState(false);
  const [defaultTaxPeriod, setDefaultTaxPeriod] = useState(format(new Date(), "MM/yyyy"));
  const [filterDate, setFilterDate] = useState({
    from: "",
    to: "",
  });
  const [Data, setData] = useState();
  const [Prefix, setPrefix] = useState();
  const [isLoading, setLoading] = useState(true);
  const [lookupList, setLookupList] = useState({
    vendorList: [],
  });

  const handleFilterList = async (period = defaultTaxPeriod) => {
    var year = period.substring(3, 8);
    var month = period.substring(0, 2);
    var monthInt = parseInt(month) - 1;
    let filterDate = new Date(year, monthInt);
    let startDayOfMonth = ToMySqlDate(startOfMonth(filterDate));
    let endDayOfMonth = ToMySqlDate(endOfMonth(filterDate));
    setFilterDate((state) => ({
      ...state,
      from: startDayOfMonth,
      to: endDayOfMonth,
    }));

    const { Code } = await exportVatReconcile(startDayOfMonth, endDayOfMonth);
    if (Code === 0) {
      setPrefix(`vat${year}${month}`);
    }
  };

  // const menuControlProp = [
  //   {
  //     name: "Print",
  //     fnc: async () => {
  //       setLoading(true);
  //       let status = await showReportByName("AP_VatReconciliation", [{ Name: "Period", Value: defaultTaxPeriod }]);
  //       if (status) {
  //         setLoading(false);
  //       }
  //     },
  //     disabled: Data?.length < 1,
  //   },
  //   {
  //     name: "Export",
  //     fnc: async () => {
  //       setLoading(true);
  //       let status = showReportByName("AP_VatReconciliation", [{ Name: "Period", Value: defaultTaxPeriod }], "xlsx");
  //       if (status) {
  //         setLoading(false);
  //       }
  //     },
  //     disabled: Data?.length < 1,
  //   },
  // ];

  const fetchVendorLookup = useCallback(async () => {
    const { Data } = await getApVendorList();
    setLookupList((state) => ({
      ...state,
      vendorList: Data,
    }));
  }, []);

  const fetchSearchList = async (prefix = Prefix, mounted) => {
    const { Data } = await getEditVatReconcileByPrefix(prefix);
    if (Data) {
      setData(Data);
    } else {
      setData([]);
    }
    if (mounted) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchVendorLookup();
    if (Prefix) {
      fetchSearchList(Prefix, mounted);
    } else {
      handleFilterList();
    }
    return function cleanup() {
      mounted = false;
    };
  }, [Prefix]);

  const columns = [
    {
      title: " ",
      field: "Id",
      sorting: true,
      render: (rowData) => {
        return (
          <div style={{ display: "flex" }}>
            <Tooltip title={`Edit ${rowData.Id}`}>
              <IconButton
                size="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => UpdateRow(rowData.Id)}
              >
                <Icon>edit</Icon>
              </IconButton>
            </Tooltip>
            {rowData.Source !== "Exported" && (
              <Tooltip title={`Delete ${rowData.Id}`}>
                <IconButton
                  size="small"
                  color="primary"
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  onClick={async () => {
                    let msg = translate("ra.question.confirmDel");
                    SnackbarUtils.delConfirm(msg, async function () {
                      const { Code, UserMessage, InternalMessage } = await delEditVatReconcileDetail(rowData.Id);
                      if (Code === 0) {
                        SnackbarUtils.success(UserMessage, function () {
                          fetchSearchList();
                        });
                      } else {
                        SnackbarUtils.warning(InternalMessage, function () {
                          fetchSearchList();
                        });
                      }
                    });
                  }}
                >
                  <Icon>delete</Icon>
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    { title: "Source", field: "Source", sorting: true },
    { title: "Tax Inv No.", field: "InvhTInvNo", sorting: true },
    {
      title: "Tax Inv Date",
      field: "InvhTInvDt",
      sorting: true,
      render: (rowData) => {
        let value = rowData.InvhTInvDt;
        if (!rowData.InvhTInvDt) {
          return null;
        }
        return DateToString(value ?? new Date());
      },
    },
    {
      title: "Vendor Name",
      field: "VnName",
      sorting: true,
      cellStyle: {
        width: 200,
      },
    },
    { title: "TAX ID.", field: "TaxId", sorting: true },
    {
      title: "Branch No.",
      field: "BranchNo",
      sorting: true,
      render: (rowData) => rowData.BranchNo ?? "",
    },
    {
      title: "Description",
      field: "InvhDesc",
      sorting: true,
      cellStyle: {
        width: 200,
      },
    },
    {
      title: "Net Amount",
      field: "BfTaxAmt",
      sorting: true,
      type: "numeric",
      render: (rowData) => NumberFormat(rowData.BfTaxAmt),
    },
    {
      title: "Tax",
      field: "TaxAmt",
      sorting: true,
      type: "numeric",
      render: (rowData) => NumberFormat(rowData.TaxAmt),
    },
    {
      title: "Total",
      field: "TotalAmt",
      sorting: true,
      type: "numeric",
      render: (rowData) => NumberFormat(rowData.TotalAmt),
    },
  ];

  const options = {
    draggable: false,
    headerStyle: {
      backgroundColor: theme.palette.primary.main,
      color: "#FFF",
    },
    rowStyle: (item) => {
      if (item.tableData.id % 2) {
        return { backgroundColor: "#f2f2f2" };
      }
    },
    padding: "dense",
    paging: true,
    pageSize: 15,
    pageSizeOptions: [15, 50, 100],
    selection: false,
    selectionProps: () => ({
      color: "primary",
    }),
  };

  const CustomFilterList = (props) => {
    return (
      <>
        <MTableToolbar {...props} />
        <Box display="flex" style={{ marginTop: -50 }}>
          <Box p={1}>
            {taxPeriodList ? (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="label">filter data by tax period</InputLabel>
                <Select
                  labelId="label"
                  variant="outlined"
                  margin="dense"
                  label={"filter data by tax period"}
                  style={{ width: 160 }}
                  value={defaultTaxPeriod}
                  onChange={(e) => {
                    setDefaultTaxPeriod(e.target.value);
                    handleFilterList(e.target.value);
                  }}
                >
                  {taxPeriodList
                    ? taxPeriodList.map((item) => (
                        <MenuItem key={item?.toString()} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </>
    );
  };

  const CustomHeader = (props) => {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={1} style={{ padding: "4px 16px" }}>
              <IconButton size={"small"} onClick={AddNewRow} style={{ marginLeft: 6 }}>
                <Icon>add</Icon>
              </IconButton>
            </TableCell>
            <TableCell align="right" colSpan={10} />
          </TableRow>
        </TableHead>
        <MTableHeader {...props} />
      </>
    );
  };

  const AddNewRow = () => {
    setEditIndex("");
    setShowAdd(true);
    setCheckSource(false);
  };

  const UpdateRow = (index) => {
    setEditIndex(index);
    setShowAdd(true);
    let selectedItem = Data.find((item) => item.Id === index);
    setCheckSource(selectedItem.Source === "Exported");
  };

  const UpdateFromPopup = (currentField, m, data) => {
    if (currentField === "BfTaxAmt") {
      let TaxAmt = FindTaxAmount("Add", 7, data["BfTaxAmt"]);
      m.setValue("TaxAmt", TaxAmt);
      m.setValue("TotalAmt", ToNumber(data["BfTaxAmt"]) + ToNumber(TaxAmt));
    }
    if (currentField === "TaxAmt") {
      //let TaxAmt = FindTaxAmount("Add", data["TaxAmt"], data["BfTaxAmt"]);
      m.setValue("TotalAmt", ToNumber(data["BfTaxAmt"]) + ToNumber(data["TaxAmt"]));
    }
  };

  const SaveFromPopup = async (arr, row) => {
    row.FrDate = filterDate.from;
    row.ToDate = filterDate.to;
    if (editIndex !== "") {
      //update
      const { Code, InternalMessage, UserMessage } = await updateEditVatReconcileDetail(row);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          setShowAdd(false);
          fetchSearchList();
        });
      } else {
        SnackbarUtils.warning(InternalMessage, function () {
          fetchSearchList();
        });
      }
    } else {
      //create
      let newParam = {
        ...initNewRow,
        ...row,
        Prefix: Prefix,
      };
      const { Code, InternalMessage, UserMessage } = await createEditVatReconcileDetail(newParam);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          setShowAdd(false);
          fetchSearchList();
        });
      } else {
        SnackbarUtils.warning(InternalMessage, function () {
          fetchSearchList();
        });
      }
    }
  };

  const CancelFromPopup = () => {
    setShowAdd(false);
  };

  const formFieldsDetail = [
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={`*${translate("ra.field.Tax Inv No.")}`}
          name="InvhTInvNo"
          variant="outlined"
          margin="dense"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
          disabled={checkSource}
        />
      ),
    },
    {
      size: 3,
      field: (
        <DateInForm
          label={`*${translate("ra.field.Tax Inv Date")}`}
          name="InvhTInvDt"
          style={{ marginTop: -18 }}
          minDate={!checkSource ? new Date(newClosePeriodAp) : undefined}
          minDateMessage={"Date must be more than close period"}
          rule={
            checkSource
              ? {
                  required: {
                    value: true,
                    message: "* Required",
                  },
                }
              : undefined
          }
          disabled={checkSource}
        />
      ),
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={`*${translate("ra.field.TAX ID.")}`}
          name="TaxId"
          variant="outlined"
          margin="dense"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
            maxLength: {
              value: 30,
              message: "maximum length is 30",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Branch No.")}
          name="BranchNo"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 13,
              message: "maximum length is 13",
            },
          }}
        />
      ),
    },
    {
      size: 3,
      field: (
        <MuiAutosuggest
          label={`${translate("ra.field.Vendor")}`}
          name="VnCode"
          optKey="VnCode"
          optDesc="VnName"
          options={lookupList["vendorList"]}
          updateOtherField={[
            { key: "VnName", optKey: "VnName" },
            { key: "TaxId", optKey: "VnTaxNo" },
            { key: "BranchNo", optKey: "BranchNo" },
          ]}
          style={{marginBottom: 18}}
        />
      ),
    },
    {
      size: 9,
      field: (
        <TextFieldInForm
          label={`*${translate("ra.field.Vendor Name")}`}
          name="VnName"
          variant="outlined"
          margin="dense"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={translate("ra.field.Description")}
          name="InvhDesc"
          variant="outlined"
          margin="dense"
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
          disabled={checkSource}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Net Amount")}`}
          name="BfTaxAmt"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
          disabled={checkSource}
        />
      ),
    },
    {
      size: 4,
      field: (
        <NumberFormatInForm
          label={`*${translate("ra.field.Tax")}`}
          name="TaxAmt"
          rule={{
            required: {
              value: true,
              message: "* Required",
            },
          }}
          disabled={checkSource}
        />
      ),
    },
    {
      size: 4,
      field: <NumberFormatInForm label={translate("ra.field.Total")} name="TotalAmt" disabled={true} />,
    },
  ];

  return (
    <>
      {/* <ActionMenu justifyContent="flex-start" menuControl={menuControlProp} /> */}
      <MeterialtableTranslate
        title=""
        data={Data}
        columns={columns}
        options={options}
        isLoading={isLoading}
        components={{
          Header: CustomHeader,
          Toolbar: CustomFilterList,
        }}
      />
      {showAdd && (
        <PopupTable
          initialValues={editIndex !== "" ? Data.find((i) => i.Id === editIndex) : initNewRow}
          formFields={formFieldsDetail}
          update={UpdateFromPopup}
          open={showAdd}
          save={(row) => SaveFromPopup(Data, row)}
          cancel={CancelFromPopup}
          maxWidth={"md"}
        />
      )}
    </>
  );
};

export default EditVATTab;
