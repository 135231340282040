import React, { useState, useContext, useEffect } from "react";
import { Loading, useRedirect, useTranslate } from "react-admin";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import { IconButton, Icon, Link } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { getAccCodeListWithSp, getReportAccountDetail, showReportPDF } from "services/callStoreProcedure";
import { getJvSearchList } from "services/generalLedger";
import ActionMenu from "components/ActionMenu";
import MuiTranslateTable from "components/MuiTranslateTable";
import { getReportList, exportReport } from "pages/Report/services";
import DialogViewJV from "components/DialogViewJV";

const useStyles = makeStyles((theme) => ({
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const AccountDetail = (props) => {
  const translate = useTranslate();
  const objDate = {};
  if (props.history) {
    const paramString = props.history.location.search.split("?")[1];
    const queryString = new URLSearchParams(paramString);
    for (const pair of queryString.entries()) {
      // eslint-disable-next-line default-case
      switch (pair[0]) {
        case "from":
          objDate.from = new Date(pair[1]);
          break;
        case "to":
          objDate.to = new Date(pair[1]);
          break;
      }
    }
    //setFilterDate(objDate);
  }
  const [loading, setLoading] = useState(true);
  const [accountList, setAccountCodeList] = useState([]);
  const [reportDialog, setReportDialog] = useState([]);
  const [selectedAcc] = useState(props.id);
  const [filterDate] = useState(objDate);
  const redirect = useRedirect();
  const classes = useStyles();
  const { DateToString, NumberFormat, ToNumber, ToMySqlDate } = useContext(GblContext);
  const [data, setData] = useState([]);
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const [openDialogJV, setOpenDialogJV] = useState(false);
  const [selectSeq, setSelectSeq] = useState("");

  async function getAccountList() {
    const accList = await getAccCodeListWithSp();
    setAccountCodeList(accList);
  }

  function useForceUpdate() {
    const [value, setState] = useState(true);
    return () => setState(!value);
  }

  const forceUpdate = useForceUpdate();

  async function getReportDetail() {
    setLoading(true);
    const list = await getReportList("GL");
    const report = list?.Data.find((i) => i.ReportName === "Account Detail");
    setReportDialog(report);
    // const accFromId = localStorage.getItem("viewFromAccCode");
    const reportDetail = await getReportAccountDetail(
      selectedAcc,
      selectedAcc,
      "",
      "",
      "",
      "",
      ToMySqlDate(filterDate?.from ?? new Date()),
      ToMySqlDate(filterDate?.to ?? new Date())
    );
    const group = {};
    //var bf;
    let res;
    if (reportDetail.length > 0) {
      reportDetail.forEach(({ DeptCode, ...rest }, idx) => {
        if (idx === 1) {
          reportDetail[idx].SumBalance = rest.Balance;
          rest.SumBalance = rest.Balance;
        }
        if (idx > 1) {
          reportDetail[idx].SumBalance = rest.Balance + reportDetail[idx - 1]?.SumBalance;
          rest.SumBalance = rest.Balance + reportDetail[idx - 1]?.SumBalance;
          // rest.Balance = rest.Balance + rest.SumBalance;
          // rest.SumBalance = rest.Balance + rest.SumBalance;
        }

        group[DeptCode] = group[DeptCode] || {
          DeptCode,
          ...rest,
          subGroup: [],
        };
        group[DeptCode].JvhDate = "";
        group[DeptCode].JvhNo = "";
        group[DeptCode].JvhSource = "";
        group[DeptCode].JvhDesc = "";
        group[DeptCode].DrAmt += rest.JvhSource === "BF" ? 0 : rest.DrAmt;
        group[DeptCode].CrAmt += rest.JvhSource === "BF" ? 0 : rest.CrAmt;
        group[DeptCode].Balance += rest.JvhSource === "BF" ? 0 : rest.Balance;
        group[DeptCode].SumBalance += rest.JvhSource === "BF" ? 0 : rest.SumBalance;
        group[DeptCode].subGroup.push(rest);
      });
      res = Object.values(group);

      const arrIndex = Array.from(res.keys());
      setRowsExpanded(arrIndex);
    } else {
      res = [];
    }
    setData(res);
  }

  useEffect(() => {
    getAccountList();
  }, []);

  useEffect(() => {
    //getParameter();
    if (props.history) {
      getReportDetail().then(() => setLoading(false));
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAcc, filterDate]);

  const columns = [
    {
      name: "DeptCode",
      label: "Department",
      options: {
        filter: true,
        sort: true,
        empty: true,
      },
    },
    {
      name: "Prefix",
      label: "Prefix",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "JvhNo",
      label: "Voucher No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "JvhDate",
      label: "Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value)) : null;
        },
      },
    },
    {
      name: "JvhSource",
      label: "Source",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "JvhDesc",
      label: "Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "DrAmt",
      label: "Debit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return null;
        },
      },
    },
    {
      name: "CrAmt",
      label: "Credit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return null;
        },
      },
    },
    {
      name: "Balance",
      label: "Balance",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return null;
        },
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const ChildTable = (props) => {
    return props.item.map((item, idx) => {
      return (
        <>
          <TableRow idx={idx}>
            <TableCell colSpan={2} />
            <TableCell>{item.Prefix}</TableCell>
            <TableCell>
              <Link
                component="button"
                variant="body2"
                onClick={async () => {
                  const uriQueryString = {
                    Limit: 1,
                    Page: 1,
                    OrderBy: { LastModified: "desc" },
                    WhereGroupList: [
                      {
                        AndOr: "And",
                        ConditionList: [
                          {
                            AndOr: "And",
                            Field: "JvhDate",
                            Operator: ">=",
                            Value: item.JvhDate,
                          },
                          {
                            AndOr: "And",
                            Field: "Status",
                            Operator: "=",
                            Value: 1,
                          },
                        ],
                      },
                    ],
                    Exclude: ["Detail", "DimHList"],
                    WhereLike: `%${item.Prefix}${item.JvhNo}%`,
                    WhereLikeFields: [
                      "Prefix",
                      "JvhNo",
                      "JvhDesc",
                      "DATE_FORMAT(JvhDate, '%d/%m/%Y')",
                      "concat(Prefix, JvhNo)",
                    ],
                  };
                  const { Data } = await getJvSearchList(uriQueryString);
                  setSelectSeq(`${Data[0].JvhSeq}`);
                  setOpenDialogJV(!openDialogJV);
                  // if (Data) {
                  //   if (window.location.pathname.search("carmen") === -1) {
                  //     window.open(`${window.location.origin}/#/glJv/${Data[0].JvhSeq}/show`);
                  //   } else {
                  //     window.open(`${window.location.origin}/carmen/#/glJv/${Data[0].JvhSeq}/show`);
                  //   }
                  // }
                }}
                underline="always"
              >
                {item.JvhNo}
              </Link>
            </TableCell>
            <TableCell>{DateToString(item.JvhDate)}</TableCell>
            <TableCell>{item.JvhSource}</TableCell>
            <TableCell>{item.JvhDesc}</TableCell>
            <TableCell style={{ textAlign: "right" }}>{NumberFormat(item.DrAmt)}</TableCell>
            <TableCell style={{ textAlign: "right" }}>{NumberFormat(item.CrAmt)}</TableCell>
            <TableCell style={{ textAlign: "right" }}>
              {/* {idx > 1 ? NumberFormat(item.Balance + props.item[idx].Balance) : NumberFormat(item.Balance)} */}
              {NumberFormat(item.SumBalance)}
            </TableCell>
          </TableRow>
        </>
      );
    });
  };

  const options = {
    filter: false, // show the filter icon in the toolbar (true by default)
    responsive: "standard",
    selectableRows: "none",
    pagination: false,
    serverSide: true,
    confirmFilters: true,
    search: false,
    print: false,
    download: true,
    onDownload: () => {
      //return `\uFEFF${buildHead(columns)}${buildBody(data)}`;
      const param = {
        Id: 205,
        Type: "Export",
        FileFormat: "",
        Parameters: [
          {
            Name: "DateFrom",
            Value: ToMySqlDate(filterDate?.from ?? new Date()),
          },
          { Name: "DateTo", Value: ToMySqlDate(filterDate?.to ?? new Date()) },
          { Name: "DeptCodeFrom", Value: "" },
          { Name: "DeptCodeTo", Value: "" },
          {
            Name: "AccCodeFrom",
            Value: selectedAcc,
          },
          { Name: "AccCodeTo", Value: selectedAcc },
          { Name: "PrefixFrom", Value: "" },
          { Name: "PrefixTo", Value: "" },
          { Name: "SourceFrom", Value: "" },
          { Name: "SourceTo", Value: "zzz" },
          { Name: "ViewTransaction", Value: "Active" },
          { Name: "OrderBy", Value: "JvNol" },
        ],
      };
      setLoading(true);
      exportReport(reportDialog, param.Parameters);
      setLoading(false);
      return false;
    },
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    expandableRows: true,
    expandableRowsHeader: true,
    expandableRowsOnClick: true,
    rowsExpanded: rowsExpanded,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length - 2;

      const sumDebit = data[rowMeta.dataIndex].subGroup.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.DrAmt);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumCredit = data[rowMeta.dataIndex].subGroup.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.CrAmt);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumBalance = data[rowMeta.dataIndex].subGroup.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.Balance);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <>
          <ChildTable item={data[rowMeta.dataIndex].subGroup} />
          <TableRow>
            <TableCell className={footerClasses} style={{ textAlign: "center" }} colSpan={colSpan}>
              {translate("ra.field.Total")} (Exclude Brought Forward)
            </TableCell>
            <TableCell className={footerClasses} style={{ textAlign: "right" }}>
              {NumberFormat(ToNumber(sumDebit) - data[rowMeta.dataIndex].subGroup[0].DrAmt)}
            </TableCell>
            <TableCell className={footerClasses} style={{ textAlign: "right" }}>
              {NumberFormat(ToNumber(sumCredit) - data[rowMeta.dataIndex].subGroup[0].CrAmt)}
            </TableCell>
            <TableCell className={footerClasses} style={{ textAlign: "right" }}>
              {NumberFormat(ToNumber(sumBalance) - data[rowMeta.dataIndex].subGroup[0].Balance)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={footerClasses} style={{ textAlign: "center" }} colSpan={colSpan}>
              {translate("ra.field.Total")} Of Account# {selectedAcc}
            </TableCell>
            <TableCell className={footerClasses} style={{ textAlign: "right" }}>
              {sumDebit}
            </TableCell>
            <TableCell className={footerClasses} style={{ textAlign: "right" }}>
              {sumCredit}
            </TableCell>
            <TableCell className={footerClasses} style={{ textAlign: "right" }}>
              {NumberFormat(ToNumber(sumBalance) + data[rowMeta.dataIndex].subGroup[0].BfwAmt)}
            </TableCell>
          </TableRow>
        </>
      );
    },
    onRowExpansionChange: (_, allRowsExpanded) => {
      data.forEach((item) => {
        item.open = false;
      });
      if (allRowsExpanded.length > 0) {
        allRowsExpanded.forEach((item) => {
          data[item.index].open = true;
        });
      }
      setData(data);
      setRowsExpanded(allRowsExpanded.map((item) => item.index));
      forceUpdate();
    },
    customToolbar: ({ displayData }) => {
      const param = {
        Id: 208,
        Type: "Report",
        FileFormat: "pdf",
        Parameters: [
          {
            Name: "DateFrom",
            Value: ToMySqlDate(filterDate?.from ?? new Date()),
          },
          { Name: "DateTo", Value: ToMySqlDate(filterDate?.to ?? new Date()) },
          { Name: "DeptCodeFrom", Value: "" },
          { Name: "DeptCodeTo", Value: "" },
          {
            Name: "AccCodeFrom",
            Value: selectedAcc,
          },
          { Name: "AccCodeTo", Value: selectedAcc },
          { Name: "PrefixFrom", Value: "" },
          { Name: "PrefixTo", Value: "" },
          { Name: "SourceFrom", Value: "" },
          { Name: "SourceTo", Value: "zzz" },
          { Name: "ViewTransaction", Value: "Active" },
          { Name: "OrderBy", Value: "JvNol" },
        ],
      };
      return (
        <IconButton
          aria-label="print report Account Detail"
          onClick={async () => {
            setLoading(true);
            const data = await showReportPDF(param);
            const blob = new Blob([data], { type: "application/pdf" });
            const objUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = objUrl;
            link.target = "_blank";
            link.click();
            setLoading(false);
          }}
        >
          <Icon>print</Icon>
        </IconButton>
      );
    },
    customTableBodyFooterRender: () => null,
  };

  const menuControlProp = [{ name: "Back", fnc: () => redirect("list", "/accountSummary") }];

  if (loading) return <Loading style={{ marginTop: 50 }} />;

  return (
    <>
      <div style={{ marginBottom: 50 }}>
        <ActionMenu menuControl={menuControlProp} />
        {/* <CustomFilterList /> */}
        <div style={{ marginTop: 6 }}>
          <MuiTranslateTable
            notTranslateTitle
            title={accountList?.find((i) => i.AccCode === selectedAcc)?.AccFullName ?? selectedAcc}
            data={data}
            columns={columns}
            options={options}
          />
        </div>
      </div>
      {openDialogJV && <DialogViewJV open={openDialogJV} onClose={() => setOpenDialogJV(false)} id={selectSeq} />}
    </>
  );
};

export default AccountDetail;
