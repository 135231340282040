import axiosAuth from "utils/request";

export async function closePeriodAsset(closeDate) {
	const { data } = await axiosAuth.post(
		`/api/assetProcedure/closePeriod?closeDate=${closeDate}`,
	);
	return data;
}

export async function postAp(fromDate, toDate, forceUpdate = false) {
	const { data } = await axiosAuth.post(
		`/api/assetProcedure/postAp?fromDate=${fromDate}&toDate=${toDate}&forceUpdate=${forceUpdate}`,
	);
	return data;
}

export async function postReceiving(param, forceUpdate = false) {
	const { data } = await axiosAuth.post(
		`/api/interfaceBlueLedgers/postReceivingToAsset?forceUpdate=${forceUpdate}`,
		param,
	);
	return data;
}
