import React, { useContext, useState } from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import { TextField, Button, CircularProgress, Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DatePickerFormat from "components/DatePickerFormat";
import NumberFormatInput from "components/NumberFormatInput";
import { copyAssetRegister } from "services/asset";
import ModelUriQueryString from "models/uriQueryString";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  textCancel: {
    color: "inherit",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const DialogCopy = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { onClose, open, RegId, AssetId, AssetNo } = props;
  const [isLoading, setLoading] = useState(false);
  const { settingAll, ToNumber } = useContext(GblContext);
  const { SettingClosePeriod } = settingAll;
  const { ClosePeriodAsset } = SettingClosePeriod;
  let newClosePeriodAsset = addDays(new Date(ClosePeriodAsset), 1);
  const [inputDate, SetInputDate] = useState(new Date());
  const [acquireDate, SetAcquireDate] = useState(new Date());
  const [items, setItems] = useState(1);
  const [uriQueryString] = useState({
    Limit: ModelUriQueryString.Limit,
    Page: ModelUriQueryString.Page,
    OrderBy: ModelUriQueryString.OrderBy,
    WhereGroupList: ModelUriQueryString.WhereGroupList,
    Exclude: ModelUriQueryString.Exclude,
    WhereRaw: ModelUriQueryString.WhereRaw,
    WhereLike: ModelUriQueryString.WhereLike,
    WhereLikeFields: ModelUriQueryString.WhereLikeFields,
  });

  const LoadingButton = ({ text, disabled, fnc }) => {
    return (
      <div className={classes.wrapper}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={fnc}>
          {translate(`ra.actionMenu.${text}`)}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = async () => {
    setLoading(true);
    let param = {
      FromRegId: RegId,
      InputDate: inputDate,
      AcquireDate: acquireDate,
      Count: ToNumber(items),
      UserModified: localStorage.getItem("UserName"),
    };
    const { Code, UserMessage, InternalMessage } = await copyAssetRegister(param);
    if (Code === 0) {
      setLoading(false);
      SnackbarUtils.success(UserMessage, function () {
        uriQueryString.Limit = ToNumber(items);
        uriQueryString.OrderBy = { AstRegId: "desc" };
        onClose(uriQueryString);
      });
      // const newestAssetRegItem = await getAssetRegisterSearchList(uriQueryString);
      // setUriQueryString(uriQueryString);
      //onClose(newestAssetRegItem.Data[0].RegId);
    } else {
      setLoading(false);
      SnackbarUtils.error(InternalMessage);
    }
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">Copy Asset</DialogTitle>
      <DialogContent dividers>
        <TextField
          label="Item to Copy"
          value={items}
          variant="outlined"
          margin="dense"
          InputProps={{
            inputComponent: NumberFormatInput,
          }}
          inputProps={{
            style: { textAlign: "right", paddingRight: 16 },
            decimal: 0,
          }}
          onChange={(e) => setItems(e.target.value)}
        />
        <TextField
          label="Asset No."
          value={`${AssetId} - ${AssetNo}`}
          // variant="filled"
          // margin="dense"
          variant="outlined"
          margin="dense"
          inputProps={{
            style: { textAlign: "right", paddingRight: 16 },
            readOnly: true,
          }}
          disabled
          style={{ marginLeft: 20 }}
        />
        <br />
        <DatePickerFormat
          label="Input Date"
          minDate={new Date(newClosePeriodAsset)}
          minDateMessage={"Date must be more than close period"}
          value={inputDate}
          onChange={(e) => SetInputDate(e)}
        />
        <br />
        <DatePickerFormat
          label="AcquireDate"
          maxDate={inputDate}
          maxDateMessage={"AcquireDate cannot be more than Input Date"}
          value={acquireDate}
          onChange={(e) => SetAcquireDate(e)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton text="OK" disabled={isLoading} fnc={handleOk} />
        <Button variant="outlined" className={classes.textCancel} onClick={handleCancel}>
          {translate("ra.action.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCopy;
