import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
// import TextTopInGrid from "components/TextTopInGrid";
import { Grid, IconButton, TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { GblContext } from "providers/formatter";
import Divider from "@material-ui/core/Divider";
import TextTopInGrid from "./TextTopInGrid";
import { useTranslate } from "react-admin";
import TextView from "./TextView";

function DialogViewAPInvoiceDetail(props) {
  const { data, open, onClose, CurRate } = props;
  const translate = useTranslate();
  const { NumberFormat } = useContext(GblContext);
  const dimList = data?.DimList?.Dim || [];
  function dimensionContent() {
    if (dimList.length === 0) {
      return null;
    } else {
      const DimensionText = dimList.map((dim, index) => (
        <Grid style={{ marginBottom: 15 }}>
          <TextView key={index} sizeSm={12} label={dim.Caption} value={dim.Value} style={{ paddingRight: 7 }} />
        </Grid>
      ));
      return (
        <>
          <Grid xs={12} style={{ marginBottom: 7 }}>
            <Typography variant="h6" style={{ marginLeft: 7 }}>
              {translate("ra.module.Dimension")}
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: 7 }}>
            {DimensionText}
          </Grid>
        </>
      );
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "10px 24px" }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {"A/P Invoice Detail"}
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 5 }}>
            <TextTopInGrid sizeSm={12} label={"Comment"} value={data.InvdDesc} />
            <TextTopInGrid sizeSm={4} label={"Unit"} value={data.UnitCode} />
            <TextTopInGrid
              sizeSm={4}
              label={"Qty"}
              value={NumberFormat(data.InvdQty)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={4}
              label={"Price"}
              value={NumberFormat(data.InvdPrice)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={8}
              label={"Dr Dept. Code"}
              value={
                data.DeptCode !== null && data.DeptCode !== ""
                  ? data.DeptDesc !== null && data.DeptDesc !== ""
                    ? data.DeptCode + " : " + data.DeptDesc
                    : data.DeptCode
                  : ""
              }
            />
            <TextTopInGrid
              sizeSm={8}
              label={"Dr Acc. Code"}
              value={
                data.InvdBTaxDr !== null && data.InvdBTaxDr !== ""
                  ? data.InvdBTaxDrDesc !== null && data.InvdBTaxDrDesc !== ""
                    ? data.InvdBTaxDr + " : " + data.InvdBTaxDrDesc
                    : data.InvdBTaxDr
                  : ""
              }
            />
            <TextTopInGrid
              sizeSm={4}
              label={"Amount"}
              value={NumberFormat(data.NetAmt)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />

            <TextTopInGrid
              sizeSm={8}
              label={"Cr Dept. Code"}
              value={
                data.InvdBTaxCr1DeptCode !== null && data.InvdBTaxCr1DeptCode !== ""
                  ? data.InvdBTaxCr1DeptDesc !== null && data.InvdBTaxCr1DeptDesc !== ""
                    ? data.InvdBTaxCr1DeptCode + " : " + data.InvdBTaxCr1DeptDesc
                    : data.InvdBTaxCr1DeptCode
                  : ""
              }
            />
            <TextTopInGrid
              sizeSm={8}
              label={"Cr Acc. Code"}
              value={
                data.InvdBTaxCr1 !== null && data.InvdBTaxCr1 !== ""
                  ? data.InvdBTaxCr1Desc !== null && data.InvdBTaxCr1Desc !== ""
                    ? data.InvdBTaxCr1 + " : " + data.InvdBTaxCr1Desc
                    : data.InvdBTaxCr1
                  : ""
              }
            />

            <TextTopInGrid
              sizeSm={4}
              label={"Total"}
              value={NumberFormat(data.TotalAmt)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            {data.InvdTaxT1 !== "None" && (
              <>
                <Grid xs={12}>
                  <Typography variant="h6" style={{ marginLeft: 7 }}>
                    {translate("ra.field.Tax 1")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={`${translate("ra.field.Tax Type")}1`}
                    value={data.InvdTaxT1}
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={`${translate("ra.field.Tax Rate")}1`}
                    value={NumberFormat(data.InvdTaxR1)}
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </Grid>

                {data.InvdT1Cr === "O" ? (
                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      label="Overwrite"
                      control={<Checkbox checked={data.InvdT1Cr === "O"} color="primary" size="small" />}
                    />
                    <br />
                  </Grid>
                ) : (
                  ""
                )}

                <Grid item xs={12} sm={8}>
                  <TextField
                    label={`${translate("ra.field.Tax Dr Dept. Code")}1`}
                    value={
                      data.InvdT1DrDeptCode !== null && data.InvdT1DrDeptCode !== ""
                        ? data.InvdT1DrDeptDesc !== null && data.InvdT1DrDeptDesc !== ""
                          ? data.InvdT1DrDeptCode + " : " + data.InvdT1DrDeptDesc
                          : data.InvdT1DrDeptCode
                        : ""
                    }
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label={`${translate("ra.field.Tax Dr Acc. Code")}1`}
                    value={
                      data.InvdT1Dr !== null && data.InvdT1Dr !== ""
                        ? data.InvdT1DrDesc !== null && data.InvdT1DrDesc !== ""
                          ? data.InvdT1Dr + " : " + data.InvdT1DrDesc
                          : data.InvdT1Dr
                        : ""
                    }
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                  />
                </Grid>
                <TextTopInGrid
                  sizeSm={4}
                  label={"Tax 1"}
                  value={NumberFormat(data.InvdTaxC1)}
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                />
              </>
            )}

            {data.InvdTaxT2 !== "None" && (
              <>
                <Grid xs={12}>
                  <Typography variant="h6" style={{ marginLeft: 7 }}>
                    {translate("ra.field.Tax 2")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={`${translate("ra.field.Tax Type")}2`}
                    value={data.InvdTaxT2}
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={`${translate("ra.field.Tax Rate")}2`}
                    value={NumberFormat(data.InvdTaxR2)}
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label={`${translate("ra.field.Tax Dr Dept. Code")}2`}
                    value={
                      data.InvdT2DrDeptCode !== null && data.InvdT2DrDeptCode !== ""
                        ? data.InvdT2DrDeptDesc !== null && data.InvdT2DrDeptDesc !== ""
                          ? data.InvdT2DrDeptCode + " : " + data.InvdT2DrDeptDesc
                          : data.InvdT2DrDeptCode
                        : ""
                    }
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label={`${translate("ra.field.Tax Dr Acc. Code")}2`}
                    value={
                      data.InvdT2Dr !== null && data.InvdT2Dr !== ""
                        ? data.InvdT2DrDesc !== null && data.InvdT2DrDesc !== ""
                          ? data.InvdT2Dr + " : " + data.InvdT2DrDesc
                          : data.InvdT2Dr
                        : ""
                    }
                    variant="outlined"
                    margin="dense"
                    readOnly
                    fullWidth
                  />
                </Grid>

                <TextTopInGrid
                  sizeSm={4}
                  label={"Tax 2"}
                  value={NumberFormat(data.InvdTaxC2)}
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                />
              </>
            )}

            <Grid xs={12} container direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" style={{ marginLeft: 7 }}>
                {translate("ra.field.Base Summary")}
              </Typography>
              <Typography variant="body" style={{ marginRight: 7 }}>
                {translate("ra.field.Rate")} : {NumberFormat(CurRate)}
              </Typography>
            </Grid>
            <TextTopInGrid
              sizeSm={12}
              label={"Net Amount"}
              value={NumberFormat(data.NetBaseAmt)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={12}
              label={"Tax 1"}
              value={NumberFormat(data.InvdTaxA1)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            {data.InvdTaxT2 !== "None" && (
              <TextTopInGrid
                sizeSm={12}
                label={"Tax 2"}
                value={NumberFormat(data.InvdTaxA2)}
                inputProps={{
                  style: { textAlign: "right" },
                }}
              />
            )}
            <TextTopInGrid
              sizeSm={12}
              label={"Total"}
              value={NumberFormat(data.NetBaseAmt + data.InvdTaxA1 + data.InvdTaxA2)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />

            {dimensionContent()}
          </Grid>
        </DialogContent>
        <br />
      </Dialog>
    </>
  );
}

export default DialogViewAPInvoiceDetail;
