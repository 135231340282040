import React from "react";
import MaterialTable from "material-table";
import { useTranslate } from "react-admin";

const MeterialTranslateTable = (props) => {
  const translate = useTranslate();
  const notTranslateTitle = props.notTranslateTitle;
  props.columns.forEach((item) => (item.title = item.title !== "" ? translate(`ra.field.${item.title}`) : " "));
  return (
    <MaterialTable
      {...props}
      title={notTranslateTitle ? props.title : props.title ? translate(`ra.module.${props.title}`) : ""}
    />
  );
};

export default MeterialTranslateTable;
