import React, { useContext, useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { TableFooter, TableRow, TableCell, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MuiTranslateTable from "components/MuiTranslateTable";
import PopupTable from "components/PopupTable";
import { GblContext } from "providers/formatter";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import { TextFieldInForm, DescInForm, MuiAutosuggest, NumberFormatInForm } from "components/Form";
import { getGlPrefix } from "services/generalLedger";
import { getAccountCodeList, getDepartmentList } from "services/setting";
import { getLookupCurrency } from "services/lookup";
import { getJvFrDetail } from "services/generalLedger";

const useStyles = makeStyles((theme) => ({
  footerCell: {
    backgroundColor: theme.palette.background.paper,
    borderTop: "2px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    textAlign: "right",
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const FormEditTemplate = ({ templateId, setAdjustData }) => {
  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();
  const { authenticated } = useAuthState();
  const { settingAll, ToMySqlDate, NumberFormat, ToNumber } = useContext(GblContext);
  const { SettingSystem } = settingAll;
  const [data, setData] = useState();
  const [lookupList, setLookupList] = useState({
    prefixList: [],
    accountCodeList: [],
    departmentList: [],
    currencyList: [],
  });
  const [oldAccList, setOldAccList] = useState([]);
  const fetchPrefixLookup = useCallback(async () => {
    const { Data } = await getGlPrefix();
    setLookupList((state) => ({
      ...state,
      prefixList: Data,
    }));
  }, []);
  const fetchAccLookup = useCallback(async () => {
    const { Data } = await getAccountCodeList("Gl");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
    }));
    setOldAccList(Data);
  }, []);
  const fetchDeptLookup = useCallback(async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  }, []);
  const fetchCurrencyLookup = useCallback(async () => {
    const p = {
      Module: "GL",
      CurrDate: ToMySqlDate(new Date()),
    };
    const arr = await getLookupCurrency(p);
    setLookupList((state) => ({
      ...state,
      currencyList: arr,
    }));
  }, [ToMySqlDate]);

  const methods = useForm({ defaultValues: data });

  const { reset } = methods;

  const fetchDetail = useCallback(async () => {
    const response = await getJvFrDetail(templateId);
    setData(response);
    reset(response);
  }, [templateId, reset]);

  const [showAdd, setShowAdd] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const UpdateRow = (value) => {
    setEditIndex(value);
    setShowAdd(true);
  };

  useEffect(() => {
    if (authenticated) {
      fetchPrefixLookup();
      fetchAccLookup();
      fetchDeptLookup();
      fetchCurrencyLookup();
      fetchDetail(templateId);
    }
  }, [authenticated, fetchPrefixLookup, fetchAccLookup, fetchDeptLookup, fetchCurrencyLookup, fetchDetail, templateId]);

  const labelList = {
    Prefix: translate("ra.fieldAbbr.prefix"),
    Type: translate("ra.fieldAbbr.type"),
    "Voucher No.": translate("ra.fieldAbbr.vouNo"),
    Date: translate("ra.fieldAbbr.date"),
    Description: translate("ra.fieldAbbr.desc"),
    Department: translate("ra.fieldAbbr.dept"),
    "Account #": translate("ra.fieldAbbr.account1"),
    Comment: translate("ra.fieldAbbr.comment"),
    Currency: translate("ra.fieldAbbr.currency"),
    Rate: translate("ra.fieldAbbr.rate"),
    Amount: translate("ra.fieldAbbr.amount"),
    Base: translate("ra.fieldAbbr.base"),
    "Dr.": translate("ra.fieldAbbr.dr"),
    "Cr.": translate("ra.fieldAbbr.cr"),
  };

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <>
              <EditIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => UpdateRow(value)}
              />
            </>
          );
        },
      },
    },
    {
      name: "DeptCode",
      label: "Dept.",
    },
    {
      name: "AccCode",
      label: "Account #",
    },
    {
      name: "AccDesc",
      label: "Account Name",
    },
    {
      name: "Description",
      label: "Comment",
      options: {
        display: false,
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "CurCode",
      label: "Currency",
    },
    {
      name: "CurRate",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "currency");
        },
      },
    },
    {
      name: "DrAmount",
      label: "Dr. Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrAmount",
      label: "Cr. Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DrBase",
      label: "Dr. Base",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "CrBase",
      label: "Cr. Base",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    responsive: "standard",
    selectableRows: "multiple",
    fixedHeader: true,
    tableBodyHeight: "580px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      const sumDrAmt = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[7]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumCrAmt = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumDrBase = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumCrBase = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[10]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {/* Add TableCellEmpty For Summary Space */}
            <TableCell className={footerClasses} />
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                if (col.name === "DrAmount") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumDrAmt}
                    </TableCell>
                  );
                } else if (col.name === "CrAmount") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumCrAmt}
                    </TableCell>
                  );
                } else if (col.name === "DrBase") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumDrBase}
                    </TableCell>
                  );
                } else if (col.name === "CrBase") {
                  return (
                    <TableCell key={index} className={footerClasses}>
                      {sumCrBase}
                    </TableCell>
                  );
                } else {
                  return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (var i = removeArray.length - 1; i >= 0; i--) data.Detail.splice(removeArray[i], 1);
    },
  };

  const formFieldsDetail = [
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`* ${labelList["Department"]}`}
          name="DeptCode"
          optKey="DeptCode"
          optDesc="Description"
          options={lookupList["departmentList"]}
          updateOtherField={[{ key: "DeptDesc", optKey: "Description" }]}
          useFncUpdate={true}
          fncUpdate={(value, methods) => {
            const daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
            if (daccList?.length > 0) {
              setLookupList((state) => ({
                ...state,
                accountCodeList: daccList,
              }));
              //remove acc if not in defaultaccount
              const acc = methods.getValues("AccCode");
              if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                methods.setValue("AccCode", "");
                methods.setValue("AccDesc", "");
              }
            } else {
              setLookupList((state) => ({
                ...state,
                accountCodeList: oldAccList,
              }));
            }
          }}
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
        />
      ),
    },
    {
      size: 6,
      name: "DeptDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="DeptDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`* ${labelList["Account #"]}`}
          name="AccCode"
          optKey="AccCode"
          optDesc={locale === "en-US" ? "Description" : "Description2"}
          options={lookupList["accountCodeList"]}
          updateOtherField={[
            {
              key: "AccDesc",
              optKey: locale === "en-US" ? "Description" : "Description2",
            },
          ]}
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
        />
      ),
    },
    {
      size: 6,
      name: "AccDesc",
      field: (
        <DescInForm
          style={{ marginTop: 8 }}
          name="AccDesc"
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextFieldInForm
          label={`${labelList["Comment"]}`}
          name="Description"
          variant="outlined"
          margin="dense"
          multiline
          rule={{
            maxLength: {
              value: 255,
              message: "maximum length is 255",
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <MuiAutosuggest
          label={`* ${labelList["Currency"]}`}
          name="CurCode"
          optKey="CurrCode"
          optDesc="CurrRate"
          options={lookupList["currencyList"]}
          updateOtherField={[{ key: "CurRate", optKey: "CurrRate" }]}
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${labelList["Rate"]}`}
          name="CurRate"
          rule={{
            min: {
              value: 0.000001,
              message: `* ${translate("ra.validation.required")}`,
            },
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
          decimal={SettingSystem.CurrencyRateDecimal}
          // decimalSep={SettingSystem.DecimalSeparator}
          // thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${labelList["Amount"]} ${labelList["Dr."]}`}
          name="DrAmount"
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${labelList["Amount"]} ${labelList["Cr."]}`}
          name="CrAmount"
          rule={{
            required: {
              value: true,
              message: `* ${translate("ra.validation.required")}`,
            },
          }}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${labelList["Base"]} ${labelList["Dr."]}`}
          name="DrBase"
          readOnly={true}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
    {
      size: 6,
      field: (
        <NumberFormatInForm
          label={`* ${labelList["Amount"]} ${labelList["Cr."]}`}
          name="CrBase"
          readOnly={true}
          decimal={SettingSystem.CurrencyBaseDecimal}
          decimalSep={SettingSystem.DecimalSeparator}
          thousandSep={SettingSystem.ThousandSeparator}
        />
      ),
    },
  ];

  const CheckDrCr = (field, m, data) => {
    const CurRate = ToNumber(data["CurRate"]);
    const DrAmount = ToNumber(data["DrAmount"]);
    const CrAmount = ToNumber(data["CrAmount"]);
    m.setValue("DrBase", DrAmount * CurRate);
    m.setValue("CrBase", CrAmount * CurRate);
    if (field === "DrAmount" && data[field] !== 0) {
      m.setValue("DrBase", DrAmount * CurRate);
      m.setValue("CrAmount", 0);
      m.setValue("CrBase", 0);
    }
    if (field === "CrAmount" && data[field] !== 0) {
      m.setValue("CrBase", CrAmount * CurRate);
      m.setValue("DrAmount", 0);
      m.setValue("DrBase", 0);
    }
  };

  const SaveFromPopup = (arr, row) => {
    const index = arr.Detail.findIndex((el) => el.index === editIndex);
    row.DrBase = NumberFormat(row.DrBase);
    row.CrBase = NumberFormat(row.CrBase);

    if (editIndex !== "") {
      //update
      arr.Detail[index] = row;
      setData(arr);
      setAdjustData(arr);
      setShowAdd(false);
    }
  };

  return (
    <>
      <MuiTranslateTable data={data?.Detail} columns={columns} options={options} />
      {showAdd && (
        <PopupTable
          initialValues={data?.Detail.find((i) => i.index === editIndex)}
          formFields={formFieldsDetail}
          update={CheckDrCr}
          open={showAdd}
          save={(row) => SaveFromPopup(data, row)}
          cancel={() => setShowAdd(false)}
          //showDim
        />
      )}
    </>
  );
};

export default FormEditTemplate;
