/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { Loading, Error, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid } from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiTranslateTable from "components/MuiTranslateTable";
import TextTopInGrid from "components/TextTopInGrid";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import NavRight from "components/NavRightSide";
import { showReportByName } from "pages/Report/services";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { permissionName } from "utils/constants";
import { GetWfStatus } from "utils/options";
import { getArReceiptDetail, delArReceiptDetail } from "services/accountReceivable";
import { getWorkflowHistory } from "services/workflow";
import CustomToolbarSelect from "./CustomToolbarSelect";
import SnackbarUtils from "utils/SnackbarUtils";
import DialogSendEmail from "components/DialogSendEmail";
import ReportBackDrop from "components/ReportBackDrop";
import DialogViewReceiptAR from "components/DialogViewReceiptAR";

const Show = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { settingAll, DateToString, NumberFormat, ToNumber } = useContext(GblContext);
  const { SettingClosePeriod } = settingAll;
  const redirect = useRedirect();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [openDim, setOpenDim] = useState(false);
  const [dataDim, setDataDim] = useState();
  const [dataInvoice, setDataInvoice] = useState();
  const [dataId, setDataId] = useState();
  const [wfHisList, setWfHisList] = useState();
  const [openDialogIV, setOpenDialogIV] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const { basePath, id, permissions, hideMailBtn, wfActive, wfEnumStatus, wfSteps } = props;

  const CheckDisableEditBtn = () => {
    if (wfActive) {
      if (data?.RcpthStatus === "Effective") {
        return true;
      }
      //check step
      if (data?.RcpthStatus && wfEnumStatus) {
        if (data?.RcpthStatus === "Step0") {
          return false;
        }
        var haveItem = wfSteps?.find((item) => item.StepNo === parseInt(wfEnumStatus[data?.RcpthStatus]));
        if (typeof haveItem === "undefined") {
          return true;
        }
      }
    }
    if (data?.PyhStatus === "Void") {
      return true;
    }
    return false;
  };

  const CheckEdit = () => {
    const msgClosePeriod = translate("ra.closePeriod.warning", {
      name: "receipt",
      action: "edit",
    });
    if (data.RcpthDate < SettingClosePeriod.ClosePeriodAr) {
      //if (data.RcpthDate < SettingClosePeriod.ClosePeriodAr || ToNumber(data.AvlCrBaseAmt) === 0) {
      //ปิด period ไปแล้ว
      SnackbarUtils.warning(msgClosePeriod);
      return;
    } else {
      redirect("edit", basePath, id);
    }
  };

  const CheckDelete = () => {
    const msgClosePeriod = translate("ra.closePeriod.warning", {
      name: "receipt",
      action: "void",
    });
    if (data.RcpthDate < SettingClosePeriod.ClosePeriodAr) {
      //if (data.RcpthDate < SettingClosePeriod.ClosePeriodAr || ToNumber(data.AvlCrBaseAmt) === 0) {
      //ปิด period ไปแล้ว
      SnackbarUtils.warning(msgClosePeriod);
    } else {
      DelOrVoid();
    }
  };

  const onCloseDialogEmail = (statusCode, UserMessage) => {
    setLoading(true);
    if (statusCode === undefined) {
      setOpenEmailDialog(false);
      setLoading(false);
    }
    if (statusCode === 0) {
      SnackbarUtils.success(UserMessage);
      setOpenEmailDialog(false);
      setLoading(false);
    }
    if (statusCode !== 0 && statusCode !== undefined) {
      SnackbarUtils.error(UserMessage);
      setOpenEmailDialog(false);
      setLoading(false);
    }
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", fnc: () => redirect("create", basePath) },
    {
      name: "Edit",
      disabled: CheckDisableEditBtn(),
      fnc: CheckEdit,
    },
    { name: "Void", disabled: CheckDisableEditBtn(), fnc: CheckDelete },
    {
      name: "Print",
      fnc: () => {
        props.showReportLoader();
        showReportByName("AR_RECEIPT", [{ Name: "List", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
    {
      name: "Print Voucher",
      fnc: () => {
        props.showReportLoader();
        showReportByName("AR_RECEIPT_VOUCHER", [{ Name: "List", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
    {
      name: "Send Email",
      fnc: () => setOpenEmailDialog(true),
      hide: hideMailBtn,
    },
  ];

  const fetchReceiptById = useCallback(
    async (mounted) => {
      const response = await getArReceiptDetail(id);
      if (response) {
        setData(response);

        // let dataRow = response?.Detail[0]?.Info;
        // let tempDataInvoice = {
        //   ...dataRow,
        // };

        // setDataInvoice(tempDataInvoice);
      }
      if (mounted) {
        setLoading(false);
      }
    },
    [id]
  );

  const fetchWorkflowHistory = useCallback(
    async (wfCode) => {
      const { data } = await getWorkflowHistory(wfCode, id);
      if (data) {
        setWfHisList(data);
      }
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    fetchReceiptById(mounted);
    fetchWorkflowHistory("AR_RECEIPT");
    return function cleanup() {
      mounted = false;
    };
  }, [fetchReceiptById]);

  const DelOrVoid = async () => {
    const msg = translate("ra.question.confirmVoid", { module: "A/R Receipt" });
    SnackbarUtils.voidConfirm(msg, async function (remark) {
      const { Code, InternalMessage, UserMessage } = await delArReceiptDetail(
        id,
        localStorage.getItem("UserName"),
        remark
      );
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          fetchReceiptById(id);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    });
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          let dataRow = data?.Detail[tableMeta.rowIndex].Info;
          let dataId = data?.Detail[tableMeta.rowIndex];
          // let tempDataInvoice = {
          //   ...dataRow,
          // };
          return (
            <>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setDataId(dataId);
                  setOpenDialogIV(!openDialogIV);
                  setDataInvoice(dataRow);

                  // ShowDim(tableMeta.rowData[9].Dim);
                }}
              />
            </>
          );
        },
      },
    },
    {
      name: "Info",
      label: "Invoice No.",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? value?.InvoiceHeader.InvNo : "";
        },
      },
    },
    {
      name: "RcptdDate",
      label: "Date",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? DateToString(value?.InvoiceHeader.InvhDate) : DateToString(value);
        },
      },
    },
    {
      name: "Info",
      label: "Description",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? value?.InvoiceDetail.InvdDesc : "";
        },
      },
    },
    {
      name: "Info",
      label: "Currency",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? value?.InvoiceHeader.CurrCode : "";
        },
      },
    },
    {
      name: "Info",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return typeof value === "object" ? NumberFormat(value?.InvoiceHeader.CurrRate, "currency") : "";
        },
      },
    },
    {
      name: "InvAmount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => NumberFormat(value),
      },
    },
    {
      name: "RcptdAmount",
      label: "Paid",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => NumberFormat(value),
      },
    },
    {
      name: "RcptdBaseAmount",
      label: "Base Amt.",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => NumberFormat(value),
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "300px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      const sumPaid = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[7]);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumBase = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                switch (col.name) {
                  case "RcptdAmount":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumPaid}
                      </TableCell>
                    );
                  case "RcptdBaseAmount":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumBase}
                      </TableCell>
                    );
                  default:
                    return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
  };

  const ShowDim = (values) => {
    if (!values) {
      setDataDim();
      setOpenDim(true);
    } else {
      setDataDim();
      setOpenDim(true);
    }
  };

  const SearchByNo = (e) => {
    if (e) {
      redirect("show", basePath, e.RcpthSeq);
    }
  };

  const CheckNullFnc = (code, desc) => {
    if (code) {
      if (desc) {
        return `${code} : ${desc}`;
      } else {
        return `${code}`;
      }
    } else {
      return "";
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <div
      // className={clsx({
      //   [classes.drawerOpen]: openDim,
      //   [classes.drawerClose]: !openDim,
      // })}
      className={classes.drawerClose}
    >
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["AR.Receipt"])}
      />
      {data &&
        wfEnumStatus &&
        data.RcpthStatus !== "Effective" &&
        data.RcpthStatus !== "Void" &&
        data.RcpthStatus !== "Printed" &&
        data.RcpthStatus !== "Step0" && (
          <CustomToolbarSelect
            selectedRows={{ data: data }}
            status={parseInt(wfEnumStatus[data.RcpthStatus])}
            wfCode={"AR_RECEIPT"}
            fncSuccess={() => {
              fetchReceiptById();
              fetchWorkflowHistory("AR_RECEIPT");
            }}
          />
        )}
      <Paper className={classes.root}>
        <BoxHeader
          header={"Receipt"}
          showSearch
          searchOption={{
            placeholder: `${translate("ra.fieldAbbr.searchby")} ${translate("ra.field.Receipt Ref. No.")}`,
            update: SearchByNo,
            module: "AR_RECEIPT",
            keyCode: "RcptRefNo",
            keyDesc: "RcptDesc",
          }}
          data={data}
          wfStatus={GetWfStatus(wfSteps, wfEnumStatus, data.RcpthStatus)}
          status={
            GetWfStatus(wfSteps, wfEnumStatus, data.RcpthStatus)
              ? GetWfStatus(wfSteps, wfEnumStatus, data.RcpthStatus)
              : data.RcpthStatus
          }
        />
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
          <TextTopInGrid sizeSm={2} label="Receipt Ref. No." value={data.RcptRefNo} />
          <TextTopInGrid sizeSm={2} label="Receipt Date" value={DateToString(data.RcpthDate)} />
          <TextTopInGrid
            sizeSm={4}
            label="A/R No."
            value={data.ArNo ? `${data.ArNo} : ${data.Company ? data.Company : data.FirstName}` : ""}
          />
          <TextTopInGrid sizeSm={2} label="Currency" value={data.CurrCode} />
          <TextTopInGrid sizeSm={2} label="Rate" value={NumberFormat(data.CurrRate ?? 0, "currency")} />
          <TextTopInGrid sizeSm={2} label="Pay Ref No." value={data.PayRefNo} />
          <TextTopInGrid sizeSm={2} label="Pay Date" value={DateToString(data.PayDate)} />
          <TextTopInGrid
            sizeSm={4}
            label="Pay Type"
            value={data.PayType ? `${data.PayTypeDesc ? `${data.PayType} : ${data.PayTypeDesc}` : ""}` : ""}
          />
          <TextTopInGrid sizeSm={4} label="Description" multiline={true} value={data.RcptDesc} />
          <TextTopInGrid sizeSm={2} label="Tax Inv No." value={data.TaxInvNo} style={{ marginTop: -46 }} />
          <TextTopInGrid sizeSm={2} label="Rate (%)" value={NumberFormat(data.TaxRate)} style={{ marginTop: -46 }} />
          <TextTopInGrid sizeSm={2} label="Amount" value={NumberFormat(data.TaxAmt)} style={{ marginTop: -46 }} />
        </Grid>
        <MuiTranslateTable data={data.Detail} columns={columns} options={options} />
      </Paper>
      {/* Withholding Tax */}
      <Paper elevation={1} style={{ marginBottom: 12 }}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Wht-content" id="Wht-header">
            <Typography className={classes.heading}>{translate("ra.field.Withholding Tax")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
              <TextTopInGrid sizeSm={3} label="Dept." value={data.WhtDept && `${data.WhtDept} : ${data.WhtDeptDesc}`} />
              <TextTopInGrid sizeSm={3} label="Acc." value={data.WhtAcc && `${data.WhtAcc} : ${data.WhtAccDesc}`} />
              <TextTopInGrid sizeSm={2} label="Total" value={NumberFormat(data.AmtBfWht)} />
              <TextTopInGrid sizeSm={2} label="Rate" value={NumberFormat(data.WhtRate)} />
              <TextTopInGrid sizeSm={2} label="Amount" value={NumberFormat(data.WhtAmt)} />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
      {/* Billing To */}
      <Paper elevation={1} style={{ marginBottom: 12 }}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Billing-Content" id="Billing-header">
            <Typography className={classes.heading}>{translate("ra.field.Billing To")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
              <TextTopInGrid sizeSm={3} label="Name" value={data.BillToName} />
              <TextTopInGrid sizeSm={2} label="Billing To" value={data.BillTo} />
              <TextTopInGrid sizeSm={3} label="Company" value={data.BillToCompany} />
              <TextTopInGrid sizeSm={4} label="Address" value={data.BillToAddress} />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
      {/* Receipt Account */}
      <Paper className={classes.root}>
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
          <Grid item xs={8}>
            <Typography className={classes.heading} gutterBottom>
              {`${translate("ra.module.Receipt")} ${translate("ra.fieldAbbr.account")}`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Typography variant="body2">{translate("ra.field.Payment Amount")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {NumberFormat(data.Amount ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.heading}>{translate("ra.field.Cr Account")}</Typography>
              </Grid>
              <TextTopInGrid
                sizeSm={4}
                label={translate("Dept.")}
                value={`${data.DeptCr} ${data.DeptCrDesc ? `: ${data.DeptCrDesc}` : ""}`}
              />
              <TextTopInGrid
                sizeSm={5}
                label={translate("Acc.")}
                value={`${data.CrAcc} ${data.CrAccDesc ? `: ${data.CrAccDesc}` : ""}`}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center" style={{ marginTop: 8 }}>
              <Grid item xs={6}>
                <Typography variant="body2">{translate("ra.field.Settlement Base Amount")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {NumberFormat(data.BaseAmt ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.heading}>{translate("ra.field.Gain/Loss Account")}</Typography>
              </Grid>
              <TextTopInGrid
                sizeSm={4}
                label={translate("Dept.")}
                value={data.DeptGL ? `${data.DeptGL} ${data.DeptGLDesc ? `: ${data.DeptGLDesc}` : ""}` : ""}
              />
              <TextTopInGrid
                sizeSm={5}
                label={translate("Acc.")}
                value={
                  data.GainLossAcc
                    ? `${data.GainLossAcc} ${data.GainLossAccDesc ? `: ${data.GainLossAccDesc}` : ""}`
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center" style={{ marginTop: 8 }}>
              <Grid item xs={6}>
                <Typography variant="body2">{translate("ra.field.Gain/Loss Amount")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {NumberFormat(data.GainLossAmt ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.heading}>{translate("ra.field.WHT. Account")}</Typography>
              </Grid>
              <TextTopInGrid
                sizeSm={4}
                label={translate("Dept.")}
                value={`${data.WhtDept} ${data.WhtDeptDesc ? `: ${data.WhtDeptDesc}` : ""}`}
              />
              <TextTopInGrid
                sizeSm={5}
                label={translate("Acc.")}
                value={`${data.WhtAcc} ${data.WhtAccDesc ? `: ${data.WhtAccDesc}` : ""}`}
              />
              {/* <Grid item xs={3}>
                <Typography variant="body2" className="wrapText">
                  {data.WhtDept ? `${data.WhtDeptDesc} : ${data.WhtDeptDesc}` : ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" className="wrapText">
                  {data.WhtAcc ? `${data.WhtAccDesc} : ${data.WhtAccDesc}` : ""}
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center" style={{ marginTop: 8 }}>
              <Grid item xs={6}>
                <Typography variant="body2"> {translate("ra.field.Withholding Tax")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {NumberFormat(data.WhtAmt ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.heading}>
                  {`${translate("ra.field.Bank Charge")} ${translate("ra.fieldAbbr.account")}`}
                </Typography>
              </Grid>
              <TextTopInGrid
                sizeSm={4}
                label={translate("Dept.")}
                value={CheckNullFnc(data.BankChargeDept, data.BankChargeDeptDesc)}
              />
              <TextTopInGrid
                sizeSm={5}
                label={translate("Acc.")}
                value={CheckNullFnc(data.BankChargeAcc, data.BankChargeAccDesc)}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center" style={{ marginTop: 8 }}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  {`${translate("ra.field.Bank Charge")} ${translate("ra.field.Amount")}`}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {NumberFormat(data.BankChargeAmt ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography className={classes.heading}>{translate("ra.field.Dr Account")}</Typography>
              </Grid>
              <TextTopInGrid sizeSm={4} label={translate("Dept.")} value={CheckNullFnc(data.DeptDr, data.DeptDrDesc)} />
              <TextTopInGrid sizeSm={5} label={translate("Acc.")} value={CheckNullFnc(data.DrAcc, data.DrAccDesc)} />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center" style={{ marginTop: 8 }}>
              <Grid item xs={6}>
                <Typography variant="body2">
                  <b>{translate("ra.field.Net Payment")}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  <b>{NumberFormat(data.NetBaseAmt)}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8} />
          <Grid item xs={4}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6}>
                <Typography variant="body2">Available Credit</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" align="right">
                  {NumberFormat(data.AvlCrBaseAmt ?? 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => ShowDim()}
        dataDim={dataDim}
        dataInvoice={dataInvoice}
        module={"AR_RECEIPT"}
        moduleId={id}
        wfHisList={wfHisList}
      />
      {openEmailDialog && (
        <DialogSendEmail
          title={" Send A/R Receipt To Email"}
          open={openEmailDialog}
          onClose={onCloseDialogEmail}
          data={data}
          module={"AR_Receipt"}
          keyId="RcpthSeq"
        />
      )}
      {openDialogIV && (
        <DialogViewReceiptAR
          open={openDialogIV}
          onClose={() => setOpenDialogIV(false)}
          data={dataInvoice}
          Rcpt={dataId}
        />
      )}

      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
    </div>
  );
};

export default ReportBackDrop(Show);
