const thCustomMessages = {
  ra: {
    action: {
      delete: "ลบ",
      show: "แสดง",
      list: "รายการ",
      save: "บันทึก",
      create: "สร้าง",
      edit: "แก้ไข",
      cancel: "ยกเลิก",
      refresh: "รีเฟรช",
      add_filter: "เพิ่มตัวกรอง",
      remove_filter: "ลบตัวกรอง",
      back: "ย้อนกลับ",
    },
    boolean: {
      true: "ใช่",
      false: "ไม่ใช่",
    },
    page: {
      loading: "กำลังโหลดข้อมูล",
      list: "รายการ %{name}",
      edit: "แก้ไข %{name} #%{id}",
      show: "แสดง %{name} #%{id}",
      create: "สร้าง %{name}",
      delete: "ลบ %{name} #%{id}",
      dashboard: "แดชบอร์ด",
      error: "เกิดข้อผิดพลาด",
    },
    input: {
      image: {
        upload_several: "อัปโหลดหลายไฟล์",
        upload_single: "อัปโหลด",
      },
    },
    message: {
      loading: "กรุณา รอสักครู่",
      yes: "ใช่",
      no: "ไม่ใช่",
      are_you_sure: "คุณแน่ใจหรือไม่?",
      about: "เกี่ยวกับ",
      details: "รายละเอียด",
      error: "เกิดข้อผิดพลาด",
    },
    navigation: {
      page_out_of_boundaries: "เกินจำนวนหน้าทั้งหมด",
      page_out_from_end: "เกินจำนวนหน้าสุดท้าย",
      page_out_from_begin: "เกินจำนวนหน้าแรก",
      page_range_info: "%{offsetBegin}-%{offsetEnd} จาก %{total}",
      next: "ถัดไป",
      prev: "ย้อนกลับ",
    },
    auth: {
      username: "ชื่อผู้ใช้งาน",
      password: "รหัสผ่าน",
      sign_in: "เข้าระบบ",
      sign_in_error: "การเข้าระบบล้มเหลว",
      logout: "ออกจากระบบ",
    },
    notification: {
      updated: "แก้ไขข้อมูลแล้ว",
      created: "เพิ่มข้อมูลแล้ว",
      deleted: "ลบข้อมูลแล้ว",
      item_doesnt_exist: "ไม่มีรายการ",
      http_error: "การเชื่อมต่อล้มเหลว",
    },
    validation: {
      required: "จำเป็น",
      minLength: "ต้องมีอักขระอย่างน้อย %{max} ตัว",
      maxLength: "ต้องมีอักขระไม่เกิน %{max} ตัว",
      minValue: "ต้องมีอย่างน้อย %{max} ตัว",
      maxValue: "ต้องมีไม่เกิน %{max} ตัว",
      number: "ต้องเป็นตัวเลขเท่านั้น",
    },
    module: {
      "General Ledger": "บัญชีแยกประเภททั่วไป",
      "General Ledgers": "บัญชีแยกประเภททั่วไป",
      "Journal Voucher": "สมุดรายวันทั่วไป",
      "Allocation Voucher": "ใบสำคัญปันส่วนรายการ",
      "Standard Journal Voucher": "แม่แบบสมุดรายวันทั่วไป",
      "Template Voucher": "แม่แบบสมุดรายวันทั่วไป",
      "Recurring Voucher": "แม่แบบใบสำคัญตามรอบบัญชี",
      "Amortization Voucher": "ใบสำคัญการตัดบัญชี",
      "Amortization Voucher Template": "แม่แบบใบสำคัญการตัดบัญชี",
      "Amortize Account": "รหัสบัญชี",
      "Account Summary": "สรุปรายการบัญชี",
      "Financial Report": "Financial Report",
      "Chart of accounts": "ผังบัญชี",
      Budget: "งบประมาณ",
      "Account Payable": "บัญชีค้างจ่าย",
      "Accounts Payable": "บัญชีค้างจ่าย",
      Vendor: "ผู้ขาย",
      Information: "ข้อมูล",
      "Invoice Default": "ค่าเริ่มต้นบันทึกใบแจ้งหนี้",
      "Payment Default": "ค่าเริ่มต้นบันทึกชำระใบแจ้งหนี้",
      "AutoPay Information": "ข้อมูลสร้างไฟล์ธนาคารโอนชำระใบแจ้งหนี้",
      Invoice: "ใบแจ้งหนี้",
      Payment: "ชำระใบแจ้งหนี้",
      "Account Receivable": "บัญชีค้างรับ",
      "Accounts Receivable": "บัญชีค้างรับ",
      Profile: "ข้อมูลส่วนตัว",
      "A/P Invoice": "ข้อมูลใบแจ้งหนี้",
      "A/P Payment": "ข้อมูลการชำระใบแจ้งหนี้",
      "A/R Profile": "ข้อมูลบัญชีลูกหนี้",
      "A/R Contract": "สัญญา",
      "A/R Folio": "โฟลิโอ",
      "A/R Invoice": "ข้อมูลใบแจ้งหนี้",
      "A/R Receipt": "ข้อมูลใบเสร็จรับเงิน",
      Folio: "โฟลิโอ",
      Receipt: "ใบเสร็จรับเงิน",
      Asset: "การจัดการทรัพย์สิน",
      "Pre-Register Asset": "รอขึ้นทะเบียนทรัพย์สิน",
      "Asset Management": "การจัดการทรัพย์สิน",
      "Asset management": "การจัดการทรัพย์สิน",
      "Asset Register": "ทะเบียนทรัพย์สิน",
      "Asset Disposal": "ตัดจำหน่ายทรัพย์สิน",
      "Asset Impairment": "ด้อยค่าทรัพย์สิน",
      Procedure: "กระบวนการ",
      Receiving: "การรับสินค้า",
      Inventory: "รายการสินค้า",
      "Apply Recurring Template": "สร้างใบสำคัญรายวันตามแม่แบบ",
      "Apply Amortization Template": "สร้างใบสำคัญตัดจ่าย",
      "Posting from": "โอนข้อมูลจากระบบ ",
      "Close Period": "ปิดบัญชีสำหรับงวด",
      "Cheque Reconciliation": "ตรวจสอบยอดเช็คจ่าย",
      Reconciliation: "ตรวจสอบรายการ",
      "Input Tax Reconciliation": "แก้ไขและตรวจสอบรายการภาษีซื้อ",
      "Edit VAT Reconciliation": "แก้ไขและตรวจสอบรายการภาษีซื้อ",
      "Withholding TAX Reconciliation": "แก้ไขและตรวจสอบรายการภาษีหัก ณ ที่จ่าย",
      "Apply Contract": "สร้างใบแจ้งหนี้ตามสัญญา",
      Report: "รายงาน",
      Setting: "การตั้งค่า",
      Configuration: "การกำหนดค่า",
      "Product License": "เลขทะเบียนซอฟแวร์",
      "Company Profile": "ข้อมูลของบริษัท",
      "System Preference": "ตั้งค่ามาตรฐาน",
      Users: "ผู้ใช้งาน",
      Permissions: "การเข้าถึง",
      Department: "แผนก",
      "Payment Type": "วิธีชำระเงิน",
      Currency: "เงินตราต่างประเทศ",
      "Currency Exchange Rate": "อัตราแลกเปลี่ยน",
      Dimension: "ข้อมูลที่กำหนดขึ้นเอง",
      Unit: "หน่วย",
      Interface: "ระบบเชื่อมต่อ",
      Email: "อีเมล์",
      EmailTemplate: "ร่างข้อความอีเมล์",
      Workflow: "ขั้นตอนอนุมัติรายการ",
      "Activity Log": "บันทึกกิจกรรม",
      "Trial Balance": "งบทดลอง",
      "Account Detail": "บัญชีแยกประเภท",
      "Hotel Information": "ข้อมูลของโรงแรม",
      "Company Information": "ข้อมูลของบริษัท",
    },
    actionMenu: {
      Back: "ย้อนกลับ",
      Add: "เพิ่ม",
      Edit: "แก้ไข",
      Delete: "ลบ",
      Void: "ยกเลิก",
      Copy: "สำเนา",
      Template: "แม่แบบ",
      "Pre-Asset": "รอขึ้นทะเบียนทรัพย์สิน",
      Print: "พิมพ์",
      Printed: "พิมพ์แล้ว",
      Payment: "วิธีชำระ",
      Receipt: "ใบเสร็จรับเงิน",
      Disposal: "การตัดจำหน่าย",
      Effective: "ใช้งาน",
      Normal: "ใช้งาน",
      Draft: "ร่าง",
      Posted: "บันทึกแล้ว",
      POST: "โพสต์",
      CLOSE: "ปิด",
      APPLY: "นำมาใช้",
      OK: "ตกลง",
    },
    info: {
      Unbalanced: "Unbalanced. Please check debit or credit amount",
      notransaction: "ไม่พบรายการ",
      help: "ช่วยเหลือ",
      maxSizeUpload: "ไฟล์ที่สามารถบันทึกได้ต้องมีขนาดไม่เกิน %{size}Mb",
      reportNotFound: "ไม่พบรายงาน",
      Success: "สำเร็จ",
      Warning: "เตือน",
      Information: "ข้อมูล",
      Error: "ผิดพลาด",
    },
    question: {
      confirmDel: "ยืนยันการลบ?",
      confirmVoid: "ยืนยันการยกเลิกรายการ?",
      confirmPrintCheque: "ยืนยันการพิมพ์เช็ค?",
      currencyWarning: "ต้องการเปลี่ยนแปลงอัตราแลกเปลี่ยนหรือไม่?",
    },
    field: {
      " ": " ",
      "No.": "เลขที่",
      All: "ทั้งหมด",
      "Due in 7 days": "ครบกำหนดใน 7 วัน",
      "Due in this month": "ครบกำหนดในเดือนนี้",
      "Due in next month": "ครบกำหนดในเดือนหน้า",
      "Unpaid Invoice": "ใบแจ้งหนี้ที่ค้างชำระ",
      "Outstanding Invoice": "ใบแจ้งหนี้ค้างชำระ",
      "Paid Invoice": "ใบแจ้งหนี้ที่จ่ายแล้ว",
      "Settled Invoice": "ใบแจ้งหนี้ที่จ่ายแล้ว",
      "Open Period": "เดือนที่เปิดอยู่",
      "Period Date": "ช่วงวัน",
      User: "ผู้ใช้",
      Username: "ชื่อผู้ใช้",
      Password: "รหัสผ่าน",
      ConfirmPassword: "ยืนยันรหัสผ่าน",
      "Business Unit": "บริษัท",
      Language: "ภาษา",
      ViewBy: "เรียกดู",
      Date: "วันที่",
      Prefix: "รหัสนำ",
      "Journal Voucher": "ใบสำคัญทั่วไป",
      "Standard Journal Voucher": "แม่แบบใบสำคัญทั่วไป",
      "Journal Voucher No.": "เลขที่ใบสำคัญ",
      Description: "รายละเอียด",
      "Description (Local)": "รายละเอียด",
      "Voucher No.": "เลขที่ใบสำคัญ",
      "Recurring Every Period": "บันทึกทุกงวด",
      Code: "รหัส",
      Type: "ประเภท",
      From: "จาก",
      To: "ถึง",
      FromAcc: "จากรหัสบัญชี",
      ToAcc: "ถึงรหัสบัญชี",
      Source: "แหล่งที่มา",
      Status: "สถานะ",
      Active: "เปิดใช้งาน",
      "In-Active": "ปิดใช้งาน",
      Account: "รหัสบัญชี",
      "Account #": "รหัสบัญชี",
      Department: "รหัสแผนก",
      "Dept.": "รหัสแผนก",
      "Department Name": "ชื่อแผนก",
      "Acc.": "โค้ด",
      "Account Code": "รหัสบัญชี",
      "Account Name": "ชื่อบัญชี",
      Currency: "สกุลเงิน",
      Rate: "อัตราแลกเปลี่ยน",
      "Rate (%)": "อัตราแลกเปลี่ยน (%)",
      Nature: "ฝั่งบัญชีคู่",
      Debit: "เดบิต",
      Credit: "เครดิต",
      Address: "ที่อยู่",
      Amount: "จำนวนเงิน",
      "Dr. Amount": "จำนวนเงิน เดบิต",
      "Cr. Amount": "จำนวนเงิน เครดิต",
      Base: "จำนวน",
      "Dr. Base": "จำนวน เดบิต",
      "Cr. Base": "จำนวน เครดิต",
      Total: "จำนวนทั้งหมด",
      "Total Cost": "มูลค่าทรัพย์สินทั้งหมด",
      "Cheq. Date": "วันที่ออกเช็ค",
      "Cheq. No. From": "หมายเลขเช็คจาก",
      "Cheq. No. To": "หมายเลขเช็คถึง",
      "Clearing Date": "วันที่หักบัญชี",
      "Clearing Amount": "ยอดหักบัญชี",
      Comment: "หมายเหตุ",
      "Base Summary": "ยอดรวม",
      "A/R Profile": "ข้อมูลบัญชีลูกหนี้",
      "A/R No.": "รหัสลูกหนี้",
      "AR No.": "รหัสลูกหนี้",
      Title: "คำนำหน้าชื่อ",
      "First Name": "ชื่อ",
      "Last Name": "นามสกุล",
      "Register Date": "วันที่ลงทะเบียน",
      Name: "ชือ",
      "Contact Name": "ชื่อผู้ติดต่อ",
      "Company Name": "บริษัท",
      Company: "บริษัท",
      Position: "ตำแหน่ง",
      Telephone: "เบอร์โทร",
      "TAX ID.": "เลขประจำตัวของผู้เสียภาษี",
      "View Invoice": "ดูใบแจ้งหนี้",
      Email: "อีเมล์",
      Remark: "หมายเหตุ",
      Address1: "ที่อยู่ 1",
      Address2: "ที่อยู่ 2",
      Address3: "ที่อยู่ 3",
      Payment: "วิธีชำระ",
      "Credit Limit": "วงเงินเครดิต",
      "Credit Term": "ระยะเครดิต",
      Billing: "ใบแจ้งหนี้",
      "Billing To": "ที่อยู่ใบแจ้งหนี้",
      "Mailing To": "ที่อยู่ส่งเอกสาร",
      "Tax Invoice Address": "ที่อยู่ใบกำกับภาษี",
      "A/R Contract": "สัญญาลูกหนี้",
      Contract: "สัญญา",
      "Contract No.": "เลขที่สัญญา",
      "Start Date": "วันที่เริ่มต้น",
      "End Date": "วันสิ้นสุด",
      "Start Contract": "วันที่เริ่มสัญญา",
      "End Contract": "วันสิ้นสุดสัญญา",
      Owner: "เจ้าของ",
      Project: "โครงการ",
      "Charge Every Month": "ส่งใบแจ้งหนี้ทุกกี่เดือน",
      ThisYear: "ปีนี้",
      Year: "ปี",
      Revision: "การปรับปรุง",
      "Vendor No.": "รหัสผู้ขาย",
      "Vendor Name": "ชื่อผู้ขาย",
      "Vendor Category": "ประเภทของผู้ขาย",
      "WHT. Category": "ประเภทของภาษี",
      "WHT. Name": "ชื่อใบหักภาษี ณ​ ที่จ่าย",
      "Chq. Name": "ชื่อผู้รับบนหน้าเช็ค",
      Fax: "แฟกซ์",
      Reference: "เลขอ้างอิง",
      "Mapping Ref.": "รหัสอ้างอิง",
      "Branch No.": "รหัสสาขา",
      "Bus Reg No.": "เลขทะเบียนการค้า",
      "Discount Term": "เงื่อนไขส่วนลด",
      "Less (%)": "ลด (%)",
      "Vat Type 1": "ภาษี 1",
      "Vat Rate 1 (%)": "อัตราภาษี 1 (%)",
      "Dept. Dr Vat 1": "แผนกบัญชีภาษี 1",
      "Acc. Dr Vat 1": "รหัสบัญชีภาษี 1",
      "Cr A/P Dept.": "Cr. A/P Dept.",
      "Cr A/P Acc.": "Cr. A/P Acc.",
      "Vat Type 2": "ภาษี 2",
      "Vat Rate 2 (%)": "อัตราภาษี 2 (%)",
      "Dept. Dr Vat 2": "แผนกบัญชีภาษี 2",
      "Acc. Dr Vat 2": "รหัสบัญชีภาษี 2",
      "Cr. Payment Dept.": "รหัสแผนก",
      "Cr. Payment Acc.": "รหัสบัญชี",
      "Withholding Tax": "ภาษีหัก ณ ที่จ่าย",
      "WHT. Code": "WHT. Code",
      "Cr. WHT. Dept.": "รหัสแผนกภาษีหัก ณ ที่จ่าย",
      "Cr. WHT. Acc.": "รหัสบัญชีภาษีหัก ณ ที่จ่าย",
      "WHT. Account": "รหัสบัญชีภาษีหัก ณ ที่จ่าย",
      "Bank Charge": "ค่าธรรมเนียมธนาคาร",
      "Bank Name": "ชื่อธนาคาร",
      "Acc Name": "ชื่อบัญชีธนาคาร",
      "Acc No": "เลขที่บัญชีธนาคาร",
      "Bank Ref": "ข้อมูลอ้างอิงสำหรับตัดบัญชีธนาคาร",
      "Bank Ref 1": "ข้อมูลอ้างอิงสำหรับตัดบัญชีธนาคาร 1",
      "Bank Ref 2": "ข้อมูลอ้างอิงสำหรับตัดบัญชีธนาคาร 2",
      "Bank Ref 3": "ข้อมูลอ้างอิงสำหรับตัดบัญชีธนาคาร 3",
      Width: "ความกว้าง",
      Seq: "ลำดับ",
      "Invoice No.": "เลขที่ใบแจ้งหนี้",
      "Rcpt. Ref No.": "เลขที่ใบเสร็จรับเงิน",
      Vendor: "รหัสผู้ขาย",
      "Input Date": "วันที่บันทึกบัญชี",
      "Invoice Date": "วันที่ใบแจ้งหนี้",
      "Cheque No": "เลขที่เช็ค",
      "Cheque Date": "วันที่เช็ค",
      "Due Date": "วันที่ครบกำหนดชำระเงิน",
      FolioDate: "วันที่โฟลิโอ",
      "Folio Date": "วันที่โฟลิโอ",
      "Folio No.": "เลขที่โฟลิโอ",
      "Guest No.": "เลขประจำตัวผู้เข้าพัก",
      "Tax Inv No.": "เลขที่ใบกำกับภาษี",
      "Tax Status": "สถานะใบกำกับภาษี",
      "Tax Inv Date": "วันที่ใบกำกับภาษี",
      Period: "เดือนภาษี",
      "Tax Period": "เดือนภาษี",
      "Default Tax Period": "เดือนภาษีเริ่มต้น",
      "Default clearing date": "วันที่หักบัญชีเริ่มต้น",
      "Payment No.": "เลขที่ใบชำระเงิน",
      "Payment Date": "วันที่ชำระเงิน",
      "Payment Type": "วิธีชำระเงิน",
      Payee: "ชื่อผู้รับบนหน้าเช็ค",
      "WHT. Service Type": "WHT. Service Type",
      "WHT. Form": "แบบยื่น หัก ณ ที่จ่าย",
      "WHT. No": "เลขที่ใบหัก ณ ที่จ่าย",
      "WHT. Desc": "รายละเอียดใบหัก ณ ที่จ่าย",
      "WHT. Ref.": "เลขที่อ้างอิงใบหัก ณ ที่จ่าย",
      "WHT. Rate %": "อัตราภาษีใบหัก ณ ที่จ่าย",
      "WHT. Amount": "ยอดภาษีใบหัก ณ ที่จ่าย",
      "Withholding Tax Date": "Withholding Tax Date",
      "WHT Condition": "WHT Condition",
      Ref: "เลขที่อ้างอิง",
      "Ref No.": "เลขที่อ้างอิง",
      Paid: "จ่ายแล้ว",
      "Base Paid": "จ่ายแล้ว",
      Unpaid: "ค้างชำระ",
      "Base Unpaid": "ค้างชำระ",
      "Total Unpaid": "ยอดค้างชำระ",
      "Total Outstanding": "ยอดค้างรับ",
      "Payment Amount": "ยอดชำระ",
      "Settlement Base Amount": "จำนวนเงินที่ชำระ",
      "Settlement Amount": "จำนวนเงินที่ชำระ",
      "Net Amount": "ยอดเงินสุทธิ",
      "Net Payment": "ยอดจ่ายสุทธิ",
      "Payment Account": "บัญชีการชำระเงิน",
      Tax: "ภาษี",
      TAX: "ภาษี",
      "Tax Amount": "ภาษี",
      "Tax 1": "ภาษี 1",
      "Tax 2": "ภาษี 2",
      "Tax Base Amount": "จำนวนฐานภาษี",
      "Tax Type": "รูปแบบภาษี",
      "Tax Rate": "อัตราภาษี",
      "Tax Rate %": "อัตราภาษี %",
      "Tax Account Code": "รหัสบัญชีภาษี",
      "Dept. Code": "รหัสแผนก",
      "Acc. Code": "รหัสบัญชี",
      "Dr Account": "บัญชีเดบิท",
      "Dr Dept. Code": "รหัสแผนก",
      "Dr Acc. Code": "รหัสบัญชีเดบิท",
      "Cr Account": "บัญชีเครดิต",
      "Cr Dept. Code": "รหัสแผนก",
      "Cr Acc. Code": "รหัสบัญชีเครดิต",
      "Tax Dr Dept. Code": "Tax Dr. Dept. Code",
      "Tax Dr Acc. Code": "รหัสบัญชีภาษีเดบิท",
      "Tax Cr Dept. Code": "Tax Cr. Dept. Code",
      "Tax Cr Acc. Code": "รหัสบัญชีภาษีเครดิต",
      "Receipt Ref. No.": "เลขที่อ้างอิง",
      "Receipt Date": "วันที่ใบเสร็จรับเงิน",
      "Pay Ref No.": "เลขที่อ้างอิงการจ่าย",
      "Pay Date": "วันที่จ่าย",
      "Pay Type": "วิธีจ่าย",
      "Asset Name": "ชื่อทรัพย์สิน",
      "Asset No.": "เลขที่ทรัพย์สิน",
      "Acquire Date": "วันที่เริ่มต้นใช้งาน",
      BarCode: "รหัสบาร์โค้ด",
      Category: "ประเภท",
      Location: "สถานที่ตั้ง",
      "Serial No.": "Serial No.",
      "Transfer Date": "วันที่ย้ายโอน",
      Specification: "ลักษณะเฉพาะ",
      "Asset Life": "อายุการใช้งาน",
      "Init Accu Depre": "ค่าเสื่อมสะสมยกมา",
      "Salvage/Unit": "ค่าซากต่อชิ้น",
      "Total Salvage": "มูลค่าซาก",
      "Amount/Unit": "ราคาต่อหน่วย",
      "Price/Unit": "ราคาต่อหน่วย",
      Price: "ราคา",
      Group: "กลุ่ม",
      Unit: "หน่วย",
      Qty: "จำนวน",
      Overwrite: "Overwrite",
      Available: "จำนวนคงเหลือ",
      "Settle on": "Settle on",
      "Base Amt.": "จำนวนเงิน",
      "Base Amount": "จำนวนเงิน",
      "Net Book Value": "มูลค่าตามบัญชี",
      "Total Value": "มูลค่าสุทธิ",
      "Life (Days)": "อายุ (วัน)",
      "Cost Dept.": "รหัสแผนก",
      "Cost Acc.": "รหัสบัญชีทรัพย์สิน",
      "Asset Dept.": "รหัสแผนก",
      "Asset Acc.": "รหัสบัญชีทรัพย์สิน",
      "Accu Dept.": "รหัสแผนก",
      "Accu Acc.": "รหัสบัญชีค่าเสื่อมสะสม",
      Depreciation: "ค่าเสื่อมราคา",
      "Depre Dept.": "รหัสแผนก",
      "Depre Acc.": "รหัสบัญชีค่าเสื่อมราคา",
      "Accu Depre.": "ค่าเสื่อมราคาสะสม",
      LastCost: "มูลค่าทรัพย์สิน",
      "Disposal Account": "รหัสบัญชีการตัดจำหน่าย",
      "Disposal Amount": "มูลค่าการตัดจำหน่าย",
      "Disposal Type": "รูปแบบการตัดจำหน่าย",
      "Sale Amount": "มูลค่าขาย",
      "Impairment Value": "มูลค่าการด้อยค่า",
      "Impairment Amount": "มูลค่าการด้อยค่า",
      "Disposal Date": "วันที่ตัดจำหน่าย",
      "Gain/Loss Amount": "จำนวนเงินกำไร (ขาดทุน)",
      "Total Asset Value": "มูลค่าทรัพย์สินสุทธิ",
      "Asset Department": "แผนก",
      "Asset Account": "รหัสบัญชีทรัพย์สิน",
      "Accu Department": "แผนก",
      "Accu Account": "รหัสบัญชีค่าเสื่อมสะสม",
      "Gain/Loss Department": "แผนก",
      "Gain/Loss Account": "รหัสบัญชีกำไร(ขาดทุน)การจำหน่าย",
      "Sale Department": "แผนก",
      "Sale Account": "รหัสบัญชีทรัพย์สิน",
      "Impairment Account": "รหัสบัญชีการด้อยค่า",
      "Cr. Impairment Department": "แผนก",
      "Dr. Impairment Department": "แผนก",
      "Cr. Impairment Account": "Cr. ค่าเผื่อการด้อยค่า",
      "Dr. Impairment Account": "Dr. ขาดทุนจากการด้อยค่า",
      "Accu Depre Department": "แผนก",
      "Accu Depre Account": "รหัสบัญชีค่าเสื่อมสะสม",
      Actual: "ยอดงบประมาณ",
      "Variance (%)": "ส่วนต่าง (%)",
      "Last Year": "ปีที่แล้ว",
      "Annual Budget": "งบประมาณประจำปี",
      "Revision Budget": "การปรับปรุงงบประมาณ",
      "Hotel Name": "ชื่อโรงแรม",
      "Register Name": "ชื่อที่ลงทะเบียน",
      "Company Register ID": "เลขทะเบียนการค้า",
      "Branch ID": "รหัสสาขา",
      "Opening Balance": "ยอดยกมา",
      "This Month": "ยอดเดือนนี้",
      "Closing Balance": "ยอดยกไป",
      Balance: "Balance",
      "Last Closed Period": "วันที่ปิดบัญชีล่าสุด",
      "Current Period": "วันที่ปิดบัญชีปัจจุบัน",
      "Amount to be allocated": "จำนวนเงินเพื่อปันส่วน",
      "Allocate Unit": "จำนวนหน่วยเพื่อปันส่วน",
      "Amortize Amount": "จำนวนเงินเพื่อตัดบัญชี",
      "Amortized by": "รูปแบบตั้งค่าตัดบัญชี",
      "Amount to Amortize": "จำนวนเงินตัดบัญชี",
      "Base Amount to Amortize": "จำนวนเงินตัดบัญชี",
      "Use In A/P": "Use In A/P",
      "Use In A/R": "Use In A/R",
      "Previous Day": "เมื่อวาน",
      Summary: "รวม",
      Undue: "ยังไม่ถึงกำหนดชำระ",
      Overdue: "เกินกำหนดชำระ",
      Current: "ยังไม่ถึงกำหนดชำระ",
      MTD: "เดือนนี้",
      YTD: "ปีนี้",
      "YTD-Last Year": "ปีนี้ - ปีที่แล้ว",
      "YTD Budget": "งบประมาณปีนี้",
      "Setup Lookup": "ตั้งค่ารายการเพื่อเลือกใช้",
      Revenue: "รายได้",
      "Market Segment": "กลุ่มลูกค้า",
      "Statistical Data": "ข้อมูลสถิติ",
      "Days In Month": "Days In Month",
    },
    fieldAbbr: {
      select: "เลือก",
      searchby: "ค้นหาด้วย ",
      date: "วันที่",
      prefix: "คำนำหน้า",
      type: "ประเภท",
      jv: "สมุดรายวันทั่วไป",
      jvNo: "เลขที่ใบสำคัญรายวัน",
      desc: "รายละเอียด",
      vouNo: "เลขที่ใบสำคัญรายวัน",
      source: "แหล่งที่มา",
      status: "สถานะ",
      active: "เปิดใช้งาน",
      inactive: "ปิดใช้งาน",
      account: "รหัสบัญชี",
      account1: "รหัสบัญชี",
      dept: "แผนก",
      deptName: "ชื่อแผนก",
      SelectAccountCode: "เลือกรหัสบัญชี",
      accountCode: "รหัสบัญชี",
      accountName: "ชื่อบัญชี",
      currency: "สกุลเงิน",
      rate: "อัตราแลกเปลี่ยน",
      percent: "เปอเซนต์",
      ratePercent: "อัตราแลกเปลี่ยน (%)",
      nature: "Nature",
      dr: "เดบิต",
      cr: "เครดิต",
      address: "ที่อยู่",
      amount: "จำนวนเงิน",
      base: "จำนวน",
      totalAmount: "จำนวนทั้งหมด",
      cheqDate: "วันที่ออกเช็ค",
      cheqNoFrom: "หมายเลขเช็คจาก",
      cheqNoTo: "หมายเลขเช็คถึง",
      clearingDate: "วันที่หักบัญชี",
      comment: "หมายเหตุ",
      "Selected Folio": "โฟลิโอที่เลือก",
      "Unselected Folio": "ยกเลิกโฟลิโอที่เลือก",
    },
    gl: {
      allocateAmtWarnningZero: "Cannot process, allocate amount is zero",
      allocateUnitWarnningZero: "Cannot process, allocate unit is zero",
      "Allow edit JV. from posting": "อนุญาตให้แก้ไขใบสำคัญที่โอนมาจากส่วนงานอื่น",
      "Allow edit JV. from posting desc": "Allow to edit Journal Voucher that posted from other source",
      "Reverse Account Nature When Negative": "Reverse Account Nature When Negative",
      "Reverse Account Nature When Negative desc": "Allow entry negative amount in ledger",
      "Reverse Account Nature When Negative info":
        "Inactive : JV posted from other source if negative amount then auto change Dr. > Cr., Cr.<Dr. \nNot allow to save JV if amount is negative",
      "Retain Earning Account": "บัญขีกำไร (ขาดทุน) สะสม",
      "Gl Posting Account": "Gl Posting Account",
      "Gl Posting Account info":
        "CM4 force department code in transaction and all mapping from other system force to identify department of transaction so this configuration should be removed",
      "Fiscal Year": "ปีบัญชี",
      "Closed Period": "ดูปีที่ปิดแล้ว",
      "Current Period": "ดูปีปัจจุบัน",
    },
    ap: {
      "Aging Period": "อายุหนี้",
      "Payment Posting": "Payment Posting",
      "Default Tax Invoice Status": "ค่าเริ่มต้นสถานะใบกำกับภาษี",
      "Default Unit": "ค่าเริ่มต้นหน่วย",
      "Allow duplicate Cheque No.": "อนุญาตให้ใข้เลขที่เช็คซ้ำ",
      "Allow to edit posting invoice": "อนุญาตให้แก้ไขใบแจ้งหนี้ที่โอนมาจากส่วนงานอื่น",
      "Allow to delete posting invoice": "อนุญาตให้ลบใบแจ้งหนี้ที่โอนมาจากส่วนงานอื่น",
      "Allow re-print form cheque": "อนุญาตให้พิม์เช็คซ้ำ",
      "Allow create duplicate TaxID on vendor profile": "อนุญาตให้ใช้เลขประจำตัวผู้เสียภาษีซ้ำ",
      "Enable Thai Tax": "เปิดการใช้งานภาษีไทย",
      "Auto Posting Vat Reconcile JV": "ปรับปรุงบัญชีภาษีอัตโนมัติ",
      "Reverse Pending Tax Invoice": "เลือกปรับปรุงใบกำกับภาษี",
      "Add other tax invoices to final Input Tax Report": "เพิ่มรายการใบกำกับภาษีอื่น ๆ ถ้ามี",
      "Input Tax Reconciliation Completed": "เสร็จสิ้นการตรวจสอบรายการใบกำกับภาษี",
    },
    apInvoice: {
      title: "ใบแจ้งหนี้",
      delInvoiceSettled: "ไม่สามารถลบรายการนี้ได้ เนื่องจากใบแจ้งหนี้ได้ถูกชำระแล้ว",
      settleWarnning:
        "ใบแจ้งหนี้ได้ถูกชำระแล้ว หรือโอนเข้าจากระบบอื่น หรือ อยู่ในรอบบัญชีที่ปิดแล้ว ระบบอนุญาติให้แก้ไขได้เฉพาะข้อมูลใบกำกับภาษี กรุณายืนยันหากท่านต้องการทำการแก้ไข",
      invoiceAmountWarnningZero: "ไม่มีจำนวนเงินชำระ ไม่สามารถทำรายการต่อ",
      invoiceAmountWarnning1: "จำนวนเงินในใบแจ้งหนี้น้อยกว่ายอดที่ต้องการชำระ ไม่สามารถทำรายการต่อ",
      invoiceAmountWarnning2: "จำนวนเงินชำระมากกว่ายอดใบแจ้งหนี้",
    },
    apPayment: {
      title: "การชำระเงิน",
      closePeriodWarnning:
        "รายการที่ต้องการแก้ไขอยู่ในรอบบัญชีที่ปิดแล้ว ท่านสามารถแก้ไขได้เฉพาะข้อมูลเช็คจ่าย กรุณายืนยันหากท่านต้องการทำการแก้ไข",
      chequeWarnning: "Cheque number or cheque date cannot be empty.",
    },
    ar: {
      "Default Value (A/R Invoice)": "ค่าเริ่มต้น (ใบแจ้งหนี้)",
      "(Debit) Account Code": "รหัสบัญชีเดบิท",
      "(Credit) Account Code": "รหัสบัญชีเครดิต",
      "Default Value (A/R Receipt)": "ค่าเริ่มต้น (ใบเสร็จรับเงิน)",
      "Copy Receipt Ref No. to Tax Invoice": "ใช้เลขที่อ้างอิงเป็นเลขที่ใบกำกับภาษี",
      "Allow Edit Folio In (A/R Invoice)": "Allow Edit Folio In (A/R Invoice)",
      amountWarnningZero: "Cannot process, amount is zero.",
      receiptAmountWarnning1: "Cannot process, payment amount is less than settlement amount.",
      folioAmountWarnning1: "Cannot process, paid is more than unpaid.",
      "Invoice - Tax Invoice Prefix":"Invoice - Tax Invoice Prefix"
    },
    asset: {
      disposed: "สินทรัยพ์รายการนี้ได้ถูกตัดจำหน่ายออกแล้ว",
      "Asset Register Number Format": "ตั้งรูปแบบรหัสทรัพย์สิน",
      "Enable Asset Barcode": "เปิดใช้บาร์โค้ด",
      "Reverse Assets and Acc. Depre. Account": "กลับบัญชีทรัพย์สินและค่าเสื่อมสะสม",
      "Set Asset Account Code for Posting": "รหัสบัญชีทรัพย์สิน",
    },
    closePeriod: {
      warning: "ไม่สามารถแก้ไข ลบ หรือยกเลิกรายการนี้ได้ เนื่องจากวันที่บันทึกรายการอยู่ในรอบบัญชีที่ปิดแล้ว",
      dateWarning: "วันที่ชำระ, วันที่หักบัญชี หรือ วันที่เช็คอยู่ในรอบบัญชีที่ปิดแล้ว กรุณาใส่ให้ถูกต้อง",
      setclearing: "Set clearing date by bank statement file",
    },
    permission: {
      denied: "ไม่สามารถเข้าใช้งานได้",
      selectVendorFirst: "กรุณาเลือกผู้ขายก่อน",
      selectProfileFirst: "กรุณาเลือกข้อมูลบัญชีลูกหนี้ก่อน",
      selectWHTAccount: "กรุณาเลือกรหัสบัญชีภาษีหัก ณ ที่จ่าย",
      selectGainLossAccount: "กรุณาเลือกรหัสบัญชีกำไร(ขาดทุน)การจำหน่าย",
    },
  },
};

export default thCustomMessages;
