/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { GblContext } from "providers/formatter";
import MuiTranslateTable from "components/MuiTranslateTable";
import { makeStyles } from "@material-ui/core/styles";
import { Loading, Error } from "react-admin";
import { getAmortizeHistory } from "services/generalLedger";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  textRed: {
    color: "red",
  },
}));

const HisAmortize = ({ Id }) => {
  const classes = useStyles();
  const { DateToString, NumberFormat } = useContext(GblContext);
  const [Data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error] = useState();

  useEffect(() => {
    async function fetchList() {
      setLoading(true);
      const { data } = await getAmortizeHistory(Id);

      data.forEach((element) => {
        element.StartDate = element.StartDate ? DateToString(new Date(element.StartDate)) : null;
        element.EndDate = element.EndDate ? DateToString(new Date(element.EndDate)) : null;
      });

      setData(data);
      setLoading(false);
    }
    fetchList();
  }, [Id]);

  const columns = [
    {
      name: "Id",
      label: " ",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "StartDate",
      label: "Start Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "EndDate",
      label: "End Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "DayInMonth",
      label: "Days In Month",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
      },
    },
    {
      name: "TypeOfAmortize",
      label: "Amortized by",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "BaseAmount",
      label: "Base Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          return NumberFormat(value);
        },
      },
    },
  ];

  const options = {
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    pagination: false,
    //rowsPerPage: Paging?.TotalRecordCount ?? 10,
    //elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    //customFooter: () => <CustomFooter count={Paging.TotalRecordCount} />,
    customFooter: null,
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!Data) return null;

  return (
    <div className={classes.root}>
      <MuiTranslateTable
        title={"Amortization History"}
        notTranslateTitle
        data={Data}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default HisAmortize;
