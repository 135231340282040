/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Loading } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core/";
import { getTemplatePermissionByLicense, getPermissionByBuAndUser, getUpdatePermission } from "services/setting";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ActionMenu from "components/ActionMenu";
import ButtonFooter from "components/ButtonFooter";
import SnackbarUtils from "utils/SnackbarUtils";
import { permissionName } from "utils/constants";
import PermissionRow from "./PermissionRow";
import SelectUserPermission from "./Dialog/SelectUserPermission";
import { showReportByName } from "pages/Report/services";
import ReportBackDrop from "components/ReportBackDrop";

const useStyles = makeStyles((theme) => ({
  tabPanel: { width: "100%" },
  button: { textTransform: "none" },
  tableHead: {
    backgroundColor: theme.palette.type === "dark" ? "inherit" : theme.palette.primary.main,
  },
  textTableHead: {
    color: "white",
    marginRight: 10,
  },
}));

const PermissionList = (props) => {
  const { value, index, bu, user, permissions } = props;
  const { Tenant } = bu;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [permissionList, setPermissionList] = useState();
  const [mode, setMode] = useState("view");

  const menuControlProp = [
    {
      name: "Edit",
      fnc: () => {
        setMode("edit");
      },
    },
    {
      name: "Copy To",
      fnc: () => {
        setOpenCopyDialog(true);
      },
    },
    {
      name: "Print",
      fnc: async () => {
        // setLoading(true);
        // let status = await showReportByName("SYS_UserRight", [
        //   { Name: "username", Value: user },
        //   { Name: "tenant", Value: bu },
        // ]);
        // if (status) {
        //   setLoading(false);
        // }
        props.showReportLoader();
        showReportByName("SYS_UserRight", [
          { Name: "Username", Value: user },
          { Name: "Tenant", Value: Tenant },
        ]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
  ];

  const fetchPermissionList = async () => {
    const tList = await getTemplatePermissionByLicense();
    // tList.sort((a, b) => a.Seq - b.Seq);
    var pList = await getPermissionByBuAndUser(Tenant, user);

    if (!pList) {
      pList = [];
    }
    var newArray = [];
    if (tList) {
      // eslint-disable-next-line
      var filterTList = tList.filter((item) => {
        if (
          item.Name !== "GL.Config.PeriodSetup" &&
          item.Name !== "GL.Config.ExportSetup" &&
          item.Name !== "Inc.Config.Revenue" &&
          item.Name !== "Inc.Config.Category" &&
          item.Name !== "Inc.Config.PayCode" &&
          item.Name !== "Inc.Config.PayType" &&
          item.Name !== "AP.Procedure.AutoGeneratePayment" &&
          item.Name !== "AP.Procedure.ChequePrint"
        ) {
          return item;
        }
      });

      filterTList.forEach((tItem) => {
        var newItem = {
          Id: "",
          PermissionName: "",
          ParentId: "",
          Name: "",
          Description: "",
          DisplayPath: "",
        };

        var arrName = tItem.DisplayPath.split("|");

        switch (arrName.length) {
          case 1:
            newItem.Id = arrName[0];
            newItem.PermissionName = tItem.Name;
            newItem.ParentId = "Root";
            newItem.Name = arrName[0];
            newItem.Description = arrName[0];
            newItem.DisplayPath = arrName[0];
            break;
          case 2:
            newItem.Id = arrName[0];
            newItem.PermissionName = tItem.Name;
            newItem.ParentId = arrName[0];
            newItem.Name = arrName[1];
            newItem.Description = arrName[1];
            newItem.DisplayPath = arrName[1];
            break;
          case 3:
            var con2 = newArray.filter(
              (item) => item.Id === `${arrName[0]}|${arrName[1]}` && item.ParentId === arrName[0]
            ).length;
            if (con2 === 0) {
              newArray.push({
                HaveChild: true,
                Id: `${arrName[0]}|${arrName[1]}`,
                PermissionName: tItem.Name,
                ParentId: arrName[0],
                Name: arrName[1],
                CanAdd: tItem?.CanAdd ?? false,
                CanDelete: tItem?.CanDelete ?? false,
                CanUpdate: tItem?.CanUpdate ?? false,
                CanView: tItem?.CanView ?? false,
                CanExecute: tItem?.CanExecute ?? false,
                CanPrint: tItem?.CanView ?? false,

                Description: tItem.Description ?? "",
                DisplayPath: tItem.DisplayPath ?? "",

                Add: tItem?.Add ?? false,
                Delete: tItem?.Delete ?? false,
                Update: tItem?.Update ?? false,
                View: tItem?.View ?? false,
                Execute: tItem?.Execute ?? false,
                Print: tItem?.View ?? false,
              });
            }
            newItem.LastChild = true;
            newItem.Id = tItem.Name;
            newItem.PermissionName = tItem.Name;
            newItem.ParentId = `${arrName[0]}|${arrName[1]}`;
            newItem.Name = arrName[2];
            newItem.Description = arrName[2];
            newItem.DisplayPath = arrName[2];
            break;
          //no default
        }

        var selectItem = pList.find((item) => item.Name === newItem.PermissionName);

        newItem.CanAdd = tItem?.CanAdd ?? false;
        newItem.CanDelete = tItem?.CanDelete ?? false;
        newItem.CanUpdate = tItem?.CanUpdate ?? false;
        newItem.CanView = tItem?.CanView ?? false;
        newItem.CanExecute = tItem?.CanExecute ?? false;
        newItem.CanPrint = tItem?.CanView ?? false;

        newItem.Add = selectItem?.Add ?? false;
        newItem.Delete = selectItem?.Delete ?? false;
        newItem.Update = selectItem?.Update ?? false;
        newItem.View = selectItem?.View ?? false;
        newItem.Execute = selectItem?.Execute ?? false;
        newItem.Print = selectItem?.View ?? false;

        newArray.push(newItem);
      });

      // filter not used permission
      // eslint-disable-next-line
      var filterArray = newArray.filter((item) => {
        if (
          item.Id !== "Income" &&
          item.ParentId !== "Income" &&
          // item.Name !== "Inc.Config.Revenue" &&
          // item.Name !== "Inc.Config.Category" &&
          // item.Name !== "Inc.Config.PayCode" &&
          // item.Name !== "Inc.Config.PayType" &&
          // item.Name !== "GL.Config.PeriodSetup" &&
          // item.Name !== "GL.Config.ExportSetup" &&
          item.Name !== "Front" &&
          item.Name !== "AutoGeneratePayment" &&
          item.Name !== "VerifyDataIntegrity" &&
          item.Name !== "PeriodSetup" &&
          item.Name !== "ExportSetup" &&
          item.Name !== "ExportDBF" &&
          item.Name !== "Vtech" &&
          item.Name !== "DepreciationCalculation" &&
          item.Name !== "DisposalCalculation"
        ) {
          return item;
        }
      });
      setPermissionList(filterArray);
    } else {
      setPermissionList([]);
    }
  };

  React.useEffect(() => {
    let mounted = true;
    if (value === index) {
      fetchPermissionList();
      if (mounted) {
        setLoading(false);
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [value, user, Tenant]);

  if (loading) return <Loading />;
  if (!permissionList) return null;

  const onInputChange = (item) => {
    permissionList.map((obj) => {
      if (obj.PermissionName === item.PermissionName) {
        return item;
      }
      return obj;
    });
  };

  function SetOtherPermission(arr) {
    var perApVd = arr.find((i) => i.Name === "AP.Vendor");
    var perAstVd = arr.find((i) => i.Name === "Ast.Vendor");
    var perInv = arr.find((i) => i.Name === "AP.Invoice");
    var perPay = arr.find((i) => i.Name === "AP.Payment");
    var perAssetReg = arr.find((i) => i.Name === "Ast.Register");
    var perArProfile = arr.find((i) => i.Name === "AR.Profile");
    var perArInv = arr.find((i) => i.Name === "AR.Invoice");
    var perArRec = arr.find((i) => i.Name === "AR.Receipt");
    var perPref = arr.find((i) => i.Name === "Sys.Pref");
    var perDimension = arr.find((i) => i.Name === "Sys.Dimension");
    var perCurrency = arr.find((i) => i.Name === "Sys.Currency");
    var perDepartment = arr.find((i) => i.Name === "GL.Department");
    //perPref?.View = true;
    if (perPref) perPref.View = true;
    if (perCurrency) perCurrency.View = true;
    if (perDimension) perDimension.View = true;
    if (perDepartment) perDepartment.View = true;

    arr.forEach((o) => {
      if (perApVd && perApVd.View === true) {
        switch (o.Name) {
          case "AP.VendorCate":
            o.View = true;
            break;
          case "AP.PaymentType":
            o.View = true;
            break;
          //no default
        }
      }
      if (perAstVd && perAstVd.View === true) {
        switch (o.Name) {
          case "AP.VendorCate":
            o.View = true;
            break;
          case "AP.PaymentType":
            o.View = true;
            break;
          //no default
        }
      }
      if (perInv && perInv.View === true) {
        if (o.Name === "AP.Unit") o.View = true;
      }
      if (perPay && perPay.View === true) {
        switch (o.Name) {
          case "AP.PaymentType":
            o.View = true;
            break;
          case "AP.Config.WhtType":
            o.View = true;
            break;
          case "AP.Config.Wht":
            o.View = true;
            break;
          //no default
        }
      }
      if (perAssetReg && perAssetReg.View === true) {
        switch (o.Name) {
          case "Ast.VendorCate":
            o.View = true;
            break;
          case "AP.Unit":
            o.View = true;
            break;
          case "Ast.Category":
            o.View = true;
            break;
          case "Ast.Department":
            o.View = true;
            break;
          case "Ast.Location":
            o.View = true;
            break;
          //no default
        }
      }
      if (perArProfile && perArProfile.View === true) {
        switch (o.Name) {
          case "AP.PaymentType":
            o.View = true;
            break;
          case "AR.Type":
            o.View = true;
            break;
          case "AR.Title":
            o.View = true;
            break;
          case "AR.Project":
            o.View = true;
            break;
          case "AR.Owner":
            o.View = true;
            break;
          //no default
        }
      }
      if (perArInv && perArInv.View === true) {
        if (o.Name === "AP.Unit") o.View = true;
      }
      if (perArRec && perArRec.View === true) {
        if (o.Name === "AP.PaymentType") o.View = true;
      }
    });

    return arr;
  }

  const Save = async (copyUser, copyBu) => {
    var newPermissionList = [];

    permissionList.forEach((item) => {
      if (item.HaveChild !== true) {
        newPermissionList.push({
          Add: item.Add,
          Delete: item.Delete,
          Execute: item.Execute,
          Name: item.PermissionName,
          Print: item.Print,
          Update: item.Update,
          View: item.View,
        });
      }
    });

    var setSpecialcasePermission = SetOtherPermission(newPermissionList);

    let param = {
      Tenant: copyBu?.Tenant ?? Tenant,
      UserName: copyUser ?? user,
      PermissionInfos: setSpecialcasePermission,
      UserModified: localStorage.getItem("UserName"),
    };

    const { Code, UserMessage, InternalMessage } = await getUpdatePermission(param);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
      setMode("view");
      fetchPermissionList();
    } else {
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
      setMode("view");
      fetchPermissionList();
    }
  };

  const CancelFnc = () => {
    setMode("view");
    fetchPermissionList();
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {openCopyDialog && (
        <SelectUserPermission
          title={"Select user to copy same permission"}
          currentUser={user}
          save={Save}
          open={openCopyDialog}
          onClose={() => setOpenCopyDialog(false)}
        />
      )}
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["Sys.Permission"])}
      />

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell />
              <TableCell className={classes.textTableHead}>Name</TableCell>
              <TableCell style={{ width: 140 }} align="center">
                <span className={classes.textTableHead}>View</span>
              </TableCell>
              <TableCell style={{ width: 140 }} align="center">
                <span className={classes.textTableHead}>Add</span>
              </TableCell>
              <TableCell style={{ width: 140 }} align="center">
                <span className={classes.textTableHead}>Update</span>
              </TableCell>
              <TableCell style={{ width: 140 }} align="center">
                <span className={classes.textTableHead}>Delete</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissionList &&
              permissionList
                .filter((i) => i.ParentId === "Root")
                .map((item, index) => (
                  <PermissionRow
                    mode={mode}
                    key={item.Id}
                    //item={item}
                    index={index}
                    //id={item.Id}
                    onChange={onInputChange}
                    listInRow={permissionList.filter((i) => i.ParentId !== "Root")}
                    rootArr={permissionList.filter((i) => i.ParentId === "Root").map((i) => i.Id)}
                    secondRootArr={permissionList.filter((i) => i.HaveChild === true).map((i) => i.Id)}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {mode !== "view" && <ButtonFooter SaveFnc={() => Save()} CancelFnc={CancelFnc} />}
    </div>
  );
};

export default ReportBackDrop(PermissionList);
