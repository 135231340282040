
export default {
  AccCode: "",
  Description: "",
  Description2: "",
  Nature: "Debit",
  Type: "Balance Sheet",
  Active: true,
  UseInAp: true,
  UseInAr: true,
  UseInGl: true,
  UseInAsset: true,
  UserModified: localStorage.getItem("UserName"),
};
