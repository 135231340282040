/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import _ from "lodash";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useForm } from "react-hook-form";
import { Loading, useRedirect, useTranslate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Switch, Button, Tabs, Tab } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import { TableHead as MuiTableHead } from "mui-datatables";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
import ArContractForm from "./ArContractForm";
import { getArProfileDetail, updateArProfileDetail, checkDuplicateContract } from "services/accountReceivable";
import ModelDetail from "models/arContractDetail";
import SnackbarUtils from "utils/SnackbarUtils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = withStyles({
  root: {
    textTransform: "none",
  },
})((props) => <Tab {...props} />);

const Edit = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const {
    basePath,
    id,
    //formFields,
    formFieldsInfo,
    formFieldsBilling,
    formFieldsDetail,
    formFieldsContractDetail,
    formFieldsTax1,
    formFieldsTax2,
  } = props;
  const { settingAll, NumberFormat, DateToString } = useContext(GblContext);
  const { SettingSystem } = settingAll;
  const redirect = useRedirect();
  const [data, setData] = useStateWithCallbackLazy();
  const [initNewRow, setInitNewRow] = useStateWithCallbackLazy(ModelDetail);
  const [openContract, setOpenContract] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [loading, setLoading] = useState(true);
  const [valueOfTab, setValueOfTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueOfTab(newValue);
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Delete", disabled: true },
    { name: "Copy", disabled: true },
    { name: "Print", disabled: true },
  ];

  const methods = useForm({ defaultValues: data });

  const { handleSubmit, getValues, reset } = methods;

  const fetchArProfileById = useCallback(async () => {
    setLoading(true);
    const response = await getArProfileDetail(id);
    if (response) {
      response.MailTo = response.MailTo ? response.MailTo : "Address1";
      response.TaxTo = response.TaxTo ? response.TaxTo : "Address1";
      setData(response);
      reset(response);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [id, reset]);

  useEffect(() => {
    fetchArProfileById();
    ModelDetail.CurCode = SettingSystem.DefaultCurrencyCode;
    ModelDetail.CurRate = SettingSystem.DefaultCurrencyRate;
    setInitNewRow(ModelDetail);
  }, [fetchArProfileById]);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async () => {
    const values = getValues();
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    const newArrParamContract = [];
    if (data.ContractDetail.length > 0) {
      data.ContractDetail.forEach((element) => {
        newArrParamContract.push({
          ArContractHId: element.ArContractHId,
          ContractNo: element.ContractNo,
          ConStart: element.ConStart,
          ConEnd: element.ConEnd,
          Owner: element.Owner,
          ProjectCode: element.ProjectCode,
          CurCode: element.CurCode,
          CurRate: parseInt(element.CurRate),
          Periodic: element.Periodic,
          ConHDesc: element.ConHDesc,
          Detail: element.Detail,
          PeriodicMonth: parseInt(element.PeriodicMonth),
          TotalAmount: element.TotalAmount,
          TotalBaseAmount: element.TotalBaseAmount,
          Active: element.Active,
          UserModified: localStorage.getItem("UserName"),
        });
      });
    }

    setData(
      (state) => ({
        ...state,
        ...values,
        ContractDetail: newArrParamContract,
      }),
      (nextState) => {
        Save(nextState);
      }
    );
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <>
              <EditIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => UpdateContractRow(value)}
              />
            </>
          );
        },
      },
    },
    {
      name: "index",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <>
              <CopyIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => AddNewContractRow(value)}
              />
            </>
          );
        },
      },
    },
    {
      name: "Active",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return (
            <Switch
              checked={typeof value === "boolean" ? value : false}
              onChange={(e, newValue) => e.preventDefault()}
              //disabled={true}
            />
          );
        },
      },
    },
    {
      name: "ContractNo",
      label: "Contract No.",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "ConHDesc",
      label: "Description",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "CurCode",
      label: "Currency",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
      },
    },
    {
      name: "CurRate",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "currency");
        },
      },
    },
    {
      name: "ProjectCode",
      label: "Project",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Owner",
      label: "Owner",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "ConStart",
      label: "Start Contract",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value)) : null;
        },
      },
    },
    {
      name: "ConEnd",
      label: "End Contract",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          if (!value) {
            return "";
          }
          const v = new Date(value);
          return DateToString(v);
        },
      },
    },
    {
      name: "PeriodicMonth",
      label: "Charge Every Month",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
      },
    },
    {
      name: "TotalAmount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
  ];

  const options = {
    responsive: "standard",
    selectableRows: "none",
    fixedHeader: true,
    tableBodyHeight: "500px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (let i = removeArray.length - 1; i >= 0; i--) data.ContractDetail.splice(removeArray[i], 1);
    },
  };

  const CustomHeader = (props) => {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={3} className={classes.pdLeft0}>
              <Button
                variant="outlined"
                onClick={() => {
                  AddNewContractRow();
                }}
              >
                Add Contract
              </Button>
            </TableCell>
            <TableCell align="right" colSpan={8} />
          </TableRow>
        </TableHead>
        <MuiTableHead {...props} />
      </>
    );
  };

  const AddNewContractRow = (index) => {
    if (index || index === 0) {
      const newContact = _.cloneDeep(data.ContractDetail[index]);
      const newContactDetail = _.cloneDeep(data.ContractDetail[index].Detail);

      newContact.ArContractHId = -1;
      newContact.ContractNo = "";
      newContactDetail.forEach((i) => {
        i.ArContractHId = -1;
        i.ArContractDId = -1;
      });

      //copy
      setInitNewRow(
        (state) => ({
          ...state,
          ...newContact,
          Detail: [...newContactDetail],
        }),
        (_) => {
          setEditIndex("");
          setOpenContract(true);
        }
      );
    } else {
      setInitNewRow(
        (state) => ({
          ...state,
          ...ModelDetail,
          Detail: [],
          CurCode: SettingSystem.DefaultCurrencyCode,
          CurRate: SettingSystem.DefaultCurrencyRate,
        }),
        (n) => {
          setEditIndex("");
          setOpenContract(true);
        }
      );
    }
  };

  const UpdateContractRow = (index) => {
    setEditIndex(index);
    setOpenContract(true);
  };

  const SaveFromPopup = async (arr, row) => {
    if (row.Detail.length === 0) {
      SnackbarUtils.info(translate("ra.info.notransaction"));
      return;
    }
    //Check ContractNo Duplicate With ContractDetail
    if (arr.ContractDetail.length > 0 && row.ArContractHId === -1) {
      const foundItem = arr.ContractDetail.find((item) => item.ContractNo === row.ContractNo);
      if (foundItem && foundItem?.index !== row.index) {
        SnackbarUtils.warning(`Contract No. [${foundItem.ContractNo}] already exists.`);
        return;
      }
    }
    if (row.ArContractHId === -1) {
      //Check ContractNo Duplicate With API
      const { Code, UserMessage } = await checkDuplicateContract(row.ContractNo);
      if (Code !== 0) {
        SnackbarUtils.warning(UserMessage);
        return;
      }
    }

    if (editIndex !== "") {
      //update
      arr.ContractDetail[editIndex] = row;
      setData(arr);
      setOpenContract(false);
    } else {
      //create
      if (arr.ContractDetail) {
        row.index = arr.ContractDetail.length;
        arr.ContractDetail = [...arr.ContractDetail, row];
        setData(arr);
        setOpenContract(false);
      }
    }
  };

  const CancelFromPopup = () => {
    setOpenContract(false);
  };

  const Save = async (values) => {
    //Validate & CheckDetail;
    const { Code, InternalMessage, UserMessage } = await updateArProfileDetail(values);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage, function () {
        redirect("show", basePath, id, values);
      });
    } else {
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
    }
    setLoading(false);
  };

  const CancelFnc = () => {
    redirect("show", basePath, id);
  };

  if (loading) return <Loading />;
  if (!data) return null;

  const Contract = () => {
    return (
      <MuiTranslateTable
        data={data.ContractDetail}
        columns={columns}
        options={options}
        components={{
          TableHead: CustomHeader,
        }}
      />
    );
  };

  return (
    <div>
      <ActionMenu menuControl={menuControlProp} />

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Paper className={classes.root}>
          <BoxHeader header={"A/R Profile"} />

          <Tabs
            value={valueOfTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            style={{
              marginTop: 20,
              marginBottom: 12,
              border: "1px solid #e0e0e3",
              borderRadius: "10px",
            }}
          >
            <StyledTab label={translate("ra.module.Information")} {...a11yProps(0)} />
            <StyledTab label={translate("ra.field.Contract")} {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={valueOfTab} index={0}>
            {/* Information */}
            <Grid container alignItems="flex-start" spacing={1} style={{ margin: "0 0 12px 0" }}>
              <Grid container item xs={12} sm={12} spacing={1}>
                {formFieldsInfo
                  ? formFieldsInfo.map((item, idx) => (
                      <Grid item xs={item.size} key={idx} style={item.style}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                            // onChange: UpdateForm,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={valueOfTab} index={1}>
            {/* Contract */}
            <Contract />
          </TabPanel>

          {openContract && (
            <ArContractForm
              initialValues={editIndex !== "" ? data.ContractDetail[editIndex] : initNewRow}
              formFields={formFieldsDetail}
              formFieldsDetail={formFieldsContractDetail}
              formFieldsTax1={formFieldsTax1}
              formFieldsTax2={formFieldsTax2}
              open={openContract}
              save={(row) => SaveFromPopup(data, row)}
              cancel={CancelFromPopup}
              modify
            />
          )}
        </Paper>
        {/* Billing */}
        {valueOfTab === 0 && (
          <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  <Typography className={classes.heading}>{translate("ra.field.Billing")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container alignItems="flex-start" spacing={1} style={{ margin: "0 0 12px 0" }}>
                    {formFieldsBilling
                      ? formFieldsBilling.map((item, idx) => (
                          <Grid item xs={item.size} key={idx} style={item.style}>
                            {React.createElement(item.field.type, {
                              ...{
                                ...item.field.props,
                                methods,
                                key: item.field.props.name,
                                //onChange: UpdateForm,
                              },
                            })}
                          </Grid>
                        ))
                      : ""}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        )}

        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
        <ButtonFooter CancelFnc={CancelFnc} />
      </form>
    </div>
  );
};

export default Edit;
