export default {
  DisposalDate: new Date(),
  Id: "",
  No: "",
  Name: "",
  Type: "Quantity",
  LastCost: 0,
  Qty: 1,
  TotalCost: 0,
  NetBook: 0,
  SaleAmt: 0,
  AstAmt: 0,
  ReduceAmt: 0,
  Depre: 0,
  AccuDepreAmt: 0,
  GainLossAmt: 0,
  SaleDeptCode: "",
  SaleAccCode: "",
  GainLossDeptCode: "",
  GainLossAccCode: "",
  UserModified: localStorage.getItem("UserName"),
};
