import React from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Paper,
  InputBase,
  Divider,
  IconButton,
  Icon,
  Collapse,
} from "@material-ui/core";
import DatePickerFormat from "components/DatePickerFormat";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginRight: 16,
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  dividerFullWidth: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const MbTextFieldSearch = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const {
    optionDate,
    setOptionDate,
    arrFilterDate,
    filterDate,
    setFilterDate,
    status,
    setStatus,
    setShowCheckBox,
    wfSteps,
    searchFnc,
    searchText,
  } = props;

  return (
    <>
      <Paper component="div" className={classes.root}>
        <InputBase
          defaultValue={searchText}
          className={classes.input}
          placeholder="Search"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              searchFnc(e.target.value);
              e.preventDefault();
            }
          }}
          autoFocus
          inputProps={{ "aria-label": "search" }}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          onClick={() => {
            searchFnc(searchText);
          }}
          aria-label="search"
        >
          <Icon>search</Icon>
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          className={classes.iconButton}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <Icon>filter_list</Icon>
        </IconButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider className={classes.dividerFullWidth} />
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginLeft: 16 }}>
              <FormControl variant="outlined">
                <InputLabel id="viewMode">{translate("ra.field.ViewBy")}</InputLabel>
                <Select
                  labelId="viewMode"
                  label={translate("ra.field.ViewBy")}
                  variant="outlined"
                  margin="dense"
                  value={optionDate}
                  defaultValue={optionDate}
                  onChange={(e) => {
                    setOptionDate(e.target.value);
                  }}
                  style={{ width: 160 }}
                >
                  {arrFilterDate.map((item, idx) => (
                    <MenuItem key={idx} value={item}>
                      {translate(`ra.field.${item}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <DatePickerFormat
                label={translate("ra.field.From")}
                value={filterDate.from}
                onChange={(e) => {
                  setFilterDate((state) => ({
                    ...state,
                    from: e,
                    to: e > filterDate.to ? e : filterDate.to,
                  }));
                }}
                style={{ width: 160, marginLeft: 16 }}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePickerFormat
                label={translate("ra.field.To")}
                value={filterDate.to}
                onChange={(e) => {
                  setFilterDate((state) => ({
                    ...state,
                    to: e,
                  }));
                }}
                minDate={filterDate.from}
                minDateMessage={"Date must be more than from date"}
                style={{ width: 160, marginLeft: 24 }}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "0 0 10px 16px" }}>
              <FormControl variant="outlined">
                <InputLabel id="status">Status</InputLabel>
                <Select
                  variant="outlined"
                  margin="dense"
                  labelId="status"
                  label="Status"
                  value={status}
                  onChange={(e) => {
                    var showc = wfSteps?.find((item) => item.StepNo === parseInt(e.target.value));
                    if (showc && wfSteps) {
                      setShowCheckBox(true);
                    } else {
                      setShowCheckBox(false);
                    }
                    setStatus(e.target.value);
                  }}
                  style={{ width: 160 }}
                >
                  <MenuItem key={"All"} value={"All"}>
                    All
                  </MenuItem>
                  {/* {wfSteps && (
                    <MenuItem key={"0"} value={"0"}>
                      Revise
                    </MenuItem>
                  )} */}
                  {!wfSteps && (
                    <MenuItem key={"E"} value={"E"}>
                      Effective
                    </MenuItem>
                  )}
                  {!wfSteps && (
                    <MenuItem key={"V"} value={"V"}>
                      Void
                    </MenuItem>
                  )}
                  {wfSteps && (
                    <MenuItem key={"InProcess"} value={"InProcess"}>
                      In Process
                    </MenuItem>
                  )}
                  {wfSteps?.map((item, idx) => (
                      <MenuItem key={idx} value={item.StepNo.toString()}>
                        {item.StepName}
                      </MenuItem>
                    ))}

                  {wfSteps && (
                    <MenuItem key={"V"} value={"V"}>
                      Void
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Collapse>
      </Paper>
    </>
  );
};

export default MbTextFieldSearch;
