import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
//import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Loading, Error, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid, Typography, Tabs, Tab, Box, Card, CardContent } from "@material-ui/core";
import TextTopInGrid from "components/TextTopInGrid";
import HisDepre from "./HisDepre";
import HisDisposal from "./HisDisposal";
import HisLocation from "./HisLocation";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import NavRight from "components/NavRightSide";
import CardImage from "components/CardImage";
import PreviewImage from "assets/previewImage.png";
import DialogCopy from "./DialogCopy";
import DialogTemplate from "./DialogTemplate";
import DialogCopyResult from "./DialogCopyResult";
import DialogEditAfterClsPeriod from "./DialogEditAfterClsPeriod";
import DialogSelectType from "./DialogSelectType";
import { showReportByName } from "pages/Report/services";
import { permissionName } from "utils/constants";
import { getAssetRegDetail, delAssetRegDetail, checkRegisterDisposal } from "services/asset";
import { addDays } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import ReportBackDrop from "components/ReportBackDrop";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = withStyles({
  root: {
    textTransform: "none",
  },
})((props) => <Tab {...props} />);

const Show = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { basePath, id, permissions } = props;
  const redirect = useRedirect();
  const { settingAll, DateToString, NumberFormat } = useContext(GblContext);
  const { SettingClosePeriod } = settingAll;
  const { ClosePeriodAsset } = SettingClosePeriod;
  let newClosePeriodAsset = addDays(new Date(ClosePeriodAsset), 1);
  const [data, setData] = useState();
  const [isDisposaled, setIsDisposaled] = useState();
  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [openDim, setOpenDim] = useState(false);
  const [dataDim, setDataDim] = useState();
  const [uriQueryString, setUriQueryString] = useState();
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [openCopyResultDialog, setOpenCopyResultDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [openSelectTypeDialog, setOpenSelectTypeDialog] = useState(false);
  const [valueOfTab, setValueOfTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueOfTab(newValue);
  };

  const CheckEditBtn = () => {
    if (data) {
      if (isDisposaled) {
        let msg = translate("ra.asset.disposed");
        SnackbarUtils.warning(msg);
        return;
      }
      if (new Date(newClosePeriodAsset) > new Date(data.InputDate)) {
        let msg = "Input date within a closed period. Continue editing?";
        SnackbarUtils.confirm(msg, function () {
          setOpenEditDialog(true);
        });
      } else {
        redirect("edit", basePath, id);
      }
    }
  };

  const CheckDeleteBtn = () => {
    if (data) {
      if (isDisposaled) {
        let msg = translate("ra.asset.disposed");
        SnackbarUtils.warning(msg);
        return;
      }
      if (new Date(newClosePeriodAsset) > new Date(data.InputDate)) {
        var msgClosePeriod = translate("ra.closePeriod.warning", { name: "asset", action: "delete" });
        SnackbarUtils.warning(msgClosePeriod);
      } else {
        DelOrVoid();
      }
    }
  };

  const CheckBeforeDisposal = () => {
    if (data.RemainQty === 0) {
      let msg = translate("ra.asset.disposed");
      SnackbarUtils.warning(msg);
      return;
    }

    if (id) {
      setOpenSelectTypeDialog(true);
      localStorage.setItem("regId", id);
      //redirect("create", "/assetDisposal");
    }
  };

  const CloseSelectTypeDialog = (type) => {
    setOpenSelectTypeDialog(false);
    if (type) {
      localStorage.setItem("disposalType", type);
      redirect("create", `/assetDisposal`);
    }
  };

  const CloseCopyDialog = (adjUriQueryString) => {
    setUriQueryString(adjUriQueryString);
    setOpenCopyDialog(false);
    if (adjUriQueryString) {
      setOpenCopyResultDialog(true);
    }
  };

  const CloseCopyResultDialog = () => {
    setOpenCopyResultDialog(false);
  };

  const CloseEditDialog = (regId) => {
    setOpenEditDialog(false);
    if (regId) {
      fetchAssetById(regId);
    }
  };

  const CloseTemplateDialog = (templateId) => {
    setOpenTemplateDialog(false);
    if (templateId) {
      localStorage.setItem("templateId", templateId);
      redirect(`create`, basePath);
    }
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", fnc: () => redirect("create", basePath) },
    {
      name: "Edit",
      fnc: () => CheckEditBtn(),
    },
    {
      name: "Delete",
      fnc: () => CheckDeleteBtn(),
    },
    { name: "Copy", fnc: () => setOpenCopyDialog(true) },
    {
      name: "Disposal",
      fnc: () => CheckBeforeDisposal(),
    },
    { name: "Pre-Asset", fnc: () => setOpenTemplateDialog(true) },
    {
      name: "Print",
      fnc: () => {
        props.showReportLoader();
        showReportByName("AS_Register", [{ Name: "AstCode", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
    {
      name: "Print BarCode",
      fnc: () => {
        props.showReportLoader();
        showReportByName("AS_Barcode", [{ Name: "AstCode", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
  ];

  const fetchAssetById = useCallback(async () => {
    const response = await getAssetRegDetail(id);
    if (response) {
      setData(response);
      const { Result } = await checkRegisterDisposal(response.RegId);
      setIsDisposaled(Result);
      setDataDim(response.DimList.Dim);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    fetchAssetById();
    setLoading(false);
  }, [fetchAssetById]);

  const ShowDim = (values) => {
    if (!values) {
      setDataDim(data.DimList.Dim);
      setOpenDim(true);
    } else {
      setDataDim(values);
      setOpenDim(true);
    }
  };

  const DelOrVoid = async () => {
    if (isDisposaled) {
      let msg = translate("ra.asset.disposed");
      SnackbarUtils.warning(msg);
      return;
    }

    if (new Date(newClosePeriodAsset) > new Date(data.InputDate)) {
      let msg = "Input date within a closed period or Asset already disposed. Cannot delete.";
      SnackbarUtils.warning(msg);
    }

    let msg = translate("ra.question.confirmDel");
    SnackbarUtils.delConfirm(msg, async function () {
      const { Code, InternalMessage, UserMessage } = await delAssetRegDetail(id);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect("list", basePath);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    });
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  const TypeOfDate = (type) => {
    switch (type) {
      case 1:
        return "Month";
      case 2:
        return "Day";
      default:
        return "Year";
    }
  };

  const RegisterValue1 = () => {
    return (
      <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
        <TextTopInGrid sizeXs={12} sizeSm={6} label="Currency" value={data.CurCode} />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={6}
          label="Rate"
          value={NumberFormat(data.CurRate, "currency")}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid sizeXs={12} sizeSm={6} label="Unit" value={data.UnitCode} />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={6}
          label="Qty"
          value={NumberFormat(data.Qty, "qty")}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid sizeXs={12} sizeSm={6} label="Asset Life" value={`${data.Life} ${TypeOfDate(data.Type)}`} />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={6}
          label="Init Accu Depre"
          value={NumberFormat(data.InitAccu)}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
      </Grid>
    );
  };

  const RegisterValue2 = () => {
    return (
      <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
        <TextTopInGrid
          sizeXs={12}
          sizeSm={6}
          label="Amount/Unit"
          value={NumberFormat(data.Amount, "unit")}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={6}
          label="Base Amt."
          value={NumberFormat(data.BaseAmount)}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={6}
          label="Salvage/Unit"
          value={NumberFormat(data.Salvage, "unit")}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={6}
          label="Total Salvage"
          value={NumberFormat(data.Salvage * data.Qty)}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={6}
          label="Net Book Value"
          value={NumberFormat(data.RemainNet)}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={6}
          label="Total Value"
          value={NumberFormat(data.TotalCost)}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
      </Grid>
    );
  };

  const RemainValue = () => {
    return (
      <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }} columns={15}>
        <TextTopInGrid
          sizeXs={12}
          sizeSm={2}
          label="Qty"
          value={NumberFormat(data.RemainInfo.Qty, "qty")}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={2}
          label="Life (Days)"
          value={data.RemainInfo.Life}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={2}
          label="Total Value"
          value={NumberFormat(data.RemainInfo.TotalValue)}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={2}
          label="Accu Depre."
          value={NumberFormat(data.RemainInfo.AccuDepre)}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={2}
          label="Net Book Value"
          value={NumberFormat(data.RemainInfo.NetBookValue)}
          inputProps={{
            style: { textAlign: "right" },
          }}
        />
      </Grid>
    );
  };

  const AssetAccount = () => {
    return (
      <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
        <TextTopInGrid
          sizeXs={12}
          sizeSm={4}
          label="Asset Dept."
          value={`${data.CostDeptCode} ${data.CostDeptDesc ? `: ${data.CostDeptDesc}` : ""}`}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={4}
          label="Accu Dept."
          value={`${data.AccuDeptCode} ${data.AccuDeptDesc ? `: ${data.AccuDeptDesc}` : ""}`}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={4}
          label="Depre Dept."
          value={`${data.DepreDeptCode} ${data.DepreDeptDesc ? `: ${data.DepreDeptDesc}` : ""}`}
        />
        <TextTopInGrid
          sizeXs={12}
          sizeSm={4}
          label="Asset Acc."
          value={`${data.CostAccCode} ${data.CostAccDesc ? `: ${data.CostAccDesc}` : ""}`}
        />

        <TextTopInGrid
          sizeXs={12}
          sizeSm={4}
          label="Accu Acc."
          value={`${data.AccuAccCode} ${data.AccuAccDesc ? `: ${data.AccuAccDesc}` : ""}`}
        />

        <TextTopInGrid
          sizeXs={12}
          sizeSm={4}
          label="Depre Acc."
          value={`${data.DepreAccCode} ${data.DepreAccDesc ? `: ${data.DepreAccDesc}` : ""}`}
        />
      </Grid>
    );
  };

  const SearchByNo = (e) => {
    if (e) {
      redirect("show", basePath, e.RegId);
    }
  };

  return (
    <div
      // className={clsx({
      //   [classes.drawerOpen]: openDim,
      //   [classes.drawerClose]: !openDim,
      // })}
      className={classes.drawerClose}
    >
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["Ast.Register"])}
      />

      <Card>
        <CardContent>
          <BoxHeader
            header={"Asset Management"}
            showSearch
            searchOption={{
              placeholder: `${translate("ra.fieldAbbr.searchby")} ${translate("ra.field.Asset No.")}`,
              update: SearchByNo,
              module: "AST_Reg",
              keyCode: "Id",
              keyCode2: "No",
              keyDesc: "Name",
            }}
            data={data}
            status={data.Active}
          />
          <Tabs
            value={valueOfTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            style={{ marginTop: 20, marginBottom: 12, border: "1px solid #e0e0e3", borderRadius: "10px" }}
          >
            <StyledTab label="Register" {...a11yProps(0)} />
            <StyledTab label="History Depreciation" {...a11yProps(1)} />
            <StyledTab label="History Disposal" {...a11yProps(2)} />
            <StyledTab label="History Location" {...a11yProps(3)} />
          </Tabs>
        </CardContent>
      </Card>
      <Box height={4} />
      <TabPanel value={valueOfTab} index={0}>
        <Card>
          <CardContent>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
              <Grid item xs={9}>
                <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
                  <TextTopInGrid sizeXs={4} sizeSm={4} label="Asset No." value={`${data.Id} - ${data.No}`} />
                  <TextTopInGrid sizeXs={4} sizeSm={4} label="Name" value={data.Name} />
                  <TextTopInGrid sizeXs={4} sizeSm={4} label="BarCode" value={data.Barcode} />
                  <TextTopInGrid
                    sizeXs={2}
                    sizeSm={2}
                    label="Input Date"
                    value={DateToString(data.InputDate ?? new Date())}
                  />
                  <TextTopInGrid
                    sizeXs={2}
                    sizeSm={2}
                    label="Acquire Date"
                    value={DateToString(data.AcquireDate ?? new Date())}
                  />
                  <TextTopInGrid
                    sizeXs={2}
                    sizeSm={4}
                    label="Category"
                    value={`${data.CategoryCode ?? ""} ${data.AstCateDesc ? `: ${data.AstCateDesc}` : ""}`}
                  />
                  <TextTopInGrid
                    sizeXs={2}
                    sizeSm={4}
                    label="Department"
                    value={`${data.DepartmentCode ?? ""} ${data.AstDeptDesc ? `: ${data.AstDeptDesc}` : ""}`}
                  />
                  <TextTopInGrid sizeXs={2} sizeSm={2} label="Invoice No." value={data.InvoiceNo} />
                  <TextTopInGrid
                    sizeXs={2}
                    sizeSm={2}
                    label="Transfer Date"
                    value={DateToString(data.TransferDate ?? new Date())}
                  />
                  <TextTopInGrid
                    sizeXs={2}
                    sizeSm={4}
                    label="Location"
                    value={`${data.LocationCode ?? ""} ${data.AstLocDesc ? `: ${data.AstLocDesc}` : ""}`}
                  />
                  <TextTopInGrid
                    sizeXs={2}
                    sizeSm={4}
                    label="Vendor"
                    value={`${data.VnCode ?? ""} ${data.VnName ? `: ${data.VnName}` : ""}`}
                  />
                  <TextTopInGrid sizeXs={2} sizeSm={4} label="Serial No." value={data.SerialNo} />
                  <TextTopInGrid sizeXs={2} sizeSm={4} label="Specification" value={data.Spec} />
                  <TextTopInGrid sizeXs={2} sizeSm={4} label="Remark" value={data.Remark} />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Paper style={{ padding: 8, textAlign: "center" }}>
                  {data.AstPhoto ? (
                    <CardImage base64Src={data.AstPhoto} noBorder={true} customSize={{ height: 200 }} />
                  ) : (
                    <CardImage imgSrc={PreviewImage} noBorder={true} customSize={{ height: 200 }} />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box height={5} />

        <Card>
          <CardContent>
            <Grid container alignItems="flex-start" spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>Register Value</Typography>
              </Grid>

              <Grid item xs={6}>
                <RegisterValue1 />
              </Grid>
              <Grid item xs={6}>
                <RegisterValue2 />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>
                  Remain Value @{DateToString(data.RemainInfo.RemainAtDate)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RemainValue />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box height={5} />
        <Card>
          <CardContent>
            <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
              <Grid item xs={12}>
                <Typography className={classes.heading}>Asset Account</Typography>
              </Grid>
              <Grid item xs={12}>
                <AssetAccount />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </TabPanel>

      <TabPanel value={valueOfTab} index={1}>
        {valueOfTab === 1 ? <HisDepre Id={data.Id} No={data.No} /> : ""}
      </TabPanel>
      <TabPanel value={valueOfTab} index={2}>
        {valueOfTab === 2 ? <HisDisposal Id={data.Id} No={data.No} /> : ""}
      </TabPanel>
      <TabPanel value={valueOfTab} index={3}>
        {valueOfTab === 3 ? <HisLocation Id={data.Id} No={data.No} /> : ""}
      </TabPanel>

      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => ShowDim()}
        dataDim={dataDim}
        module={"AST_Reg"}
        moduleId={id}
      />

      {openCopyDialog && (
        <DialogCopy open={openCopyDialog} onClose={CloseCopyDialog} RegId={id} AssetId={data.Id} AssetNo={data.No} />
      )}
      {openEditDialog && (
        <DialogEditAfterClsPeriod open={openEditDialog} onClose={CloseEditDialog} RegId={id} {...data} />
      )}
      {openCopyResultDialog && (
        <DialogCopyResult
          title={`New Asset (${data.Id})`}
          open={openCopyResultDialog}
          onClose={CloseCopyResultDialog}
          basePath={basePath}
          adjustUriQueryString={uriQueryString}
        />
      )}
      {openTemplateDialog && <DialogTemplate open={openTemplateDialog} onClose={CloseTemplateDialog} />}
      {openSelectTypeDialog && (
        <DialogSelectType
          open={openSelectTypeDialog}
          onClose={CloseSelectTypeDialog}
          remainNet={data.RemainNet}
          salvage={data.Salvage * data.Qty}
        />
      )}
      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
    </div>
  );
};

export default ReportBackDrop(Show);
