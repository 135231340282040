import axiosAuth from "utils/request";

//---------------------------- Setting ----------------------------//

export async function getSessionToken(module) {
  const { data } = await axiosAuth.get(`/api/${module}/getSessionToken`);
  return data;
}

export async function getSettingInfGuestLine() {
  const { data } = await axiosAuth.get("/api/interfaceGuestLine/setting");
  return data;
}

export async function getSettingInfOpera() {
  const { data } = await axiosAuth.get("/api/interfaceOpera/setting");
  return data;
}

export async function getSettingInfHotelogix() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix/setting");
  return data;
}

export async function getSettingInfHotelogixB() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix2/setting");
  return data;
}

export async function getSettingInfHotelogixGl() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix/gl/setting");
  return data;
}

export async function getSettingInfHotelogixGlB() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix2/gl/setting");
  return data;
}

export async function getSettingInfHotelogixAr() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix/ar/setting");
  return data;
}

export async function updateSettingInfHotelogixAr(param) {
  const { data } = await axiosAuth.put("/api/interfaceHotelogix/ar/setting", param);
  return data;
}

export async function getSettingInfHotelogixArB() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix2/ar/setting");
  return data;
}

export async function getSettingInfBlueledger() {
  const { data } = await axiosAuth.get("/api/interfaceBlueLedgers/setting");
  return data;
}

export async function updateSettingInfGuestLine(param) {
  const { data } = await axiosAuth.put("/api/interfaceGuestLine/setting", param);
  return data;
}

export async function updateSettingInfBlueledger(param) {
  const { data } = await axiosAuth.put("/api/interfaceBlueLedgers/setting", param);
  return data;
}

export async function updateSettingInfHotelogix(param) {
  const { data } = await axiosAuth.put("/api/interfaceHotelogix/setting", param);
  return data;
}

export async function updateSettingInfHotelogixB(param) {
  const { data } = await axiosAuth.put("/api/interfaceHotelogix2/setting", param);
  return data;
}

//---------------------------- Mapping ----------------------------//

export async function getMappingGuestLine() {
  const { data } = await axiosAuth.get("/api/interfaceGuestLine/setting/mapping");
  return data;
}

export async function updateMappingGuestLine(param) {
  //typeof param === System.Data.DataTable
  const { data } = await axiosAuth.put("/api/interfaceGuestLine/setting/mapping", param);
  return data;
}

export async function getMappingHotelogix() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix/setting/mapping");
  return data;
}

export async function updateMappingHotelogix(param) {
  //typeof param === System.Data.DataTable
  const { data } = await axiosAuth.put("/api/interfaceHotelogix/setting/mapping", param);
  return data;
}

export async function getMappingHotelogixGl() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix/gl/setting/mapping/revenue");
  return data;
}

export async function updateMappingHotelogixGl(param) {
  //typeof param === System.Data.DataTable
  const { data } = await axiosAuth.put("/api/interfaceHotelogix/gl/setting/mapping/revenue", param);
  return data;
}

export async function getMappingHotelogixGlB() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix2/gl/setting/mapping/revenue");
  return data;
}

export async function updateMappingHotelogixGlB(param) {
  //typeof param === System.Data.DataTable
  const { data } = await axiosAuth.put("/api/interfaceHotelogix2/gl/setting/mapping/revenue", param);
  return data;
}

export async function getMappingHotelogixAr() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix/ar/setting/mapping");
  return data;
}

export async function updateMappingHotelogixAr(param) {
  //typeof param === System.Data.DataTable
  const { data } = await axiosAuth.put("/api/interfaceHotelogix/ar/setting/mapping", param);
  return data;
}

export async function getMappingHotelogixArB() {
  const { data } = await axiosAuth.get("/api/interfaceHotelogix2/ar/setting/mapping");
  return data;
}

export async function updateMappingHotelogixArB(param) {
  //typeof param === System.Data.DataTable
  const { data } = await axiosAuth.put("/api/interfaceHotelogix2/ar/setting/mapping", param);
  return data;
}

//---------------------------- Post ----------------------------//

export async function createSessionToken(module) {
  const { data } = await axiosAuth.post(`/api/${module}/createSessionToken`);
  return data;
}

export async function uploadFileHotelogixAr(param) {
  const { data } = await axiosAuth.post("api/interfaceHotelogix/ar/uploadfile", param);
  return data;
}
export async function uploadFileHotelogixArB(param) {
  const { data } = await axiosAuth.post("api/interfaceHotelogix2/ar/uploadfile", param);
  return data;
}

export async function postGuestLine(param, forceUpdate = false) {
  const { data } = await axiosAuth.post(`api/interfaceGuestLine/postGl?forceUpdate=${forceUpdate}`, param);
  return data;
}

export async function postHotelogixGl(param, forceUpdate = false) {
  const { data } = await axiosAuth.post(`api/interfaceHotelogix/gl/postGl?forceUpdate=${forceUpdate}`, param);
  return data;
}

export async function postHotelogixGlB(param, forceUpdate = false) {
  const { data } = await axiosAuth.post(`api/interfaceHotelogix2/gl/postGl?forceUpdate=${forceUpdate}`, param);
  return data;
}

export async function postHotelTimeGl(param, forceUpdate = false) {
  const { data } = await axiosAuth.post(`/api/interfaceHotelTime/gl/post?forceUpdate=${forceUpdate}`, param);
  return data;
}

//---------------------------- NewVerion API Interface ----------------------------//
export async function getTemplateInfPost(module, type) {
  const { data } = await axiosAuth.get(`/api/interfacePost/ConfigTemplate/${module}/${type}`);
  return data;
}

export async function getMappingInfByCode(code, type, module) {
  const { data } = await axiosAuth.get(`/api/interfacePost/mapping/${code}/${type}/${module}`);
  return data;
}

export async function updateMappingInfByCode(code, type, module, dataTable) {
  const { data } = await axiosAuth.put(`/api/interfacePost/mapping/${code}/${type}/${module}`, dataTable);
  return data;
}

export async function getSettingInfPost(module) {
  if (!module) {
    const { data } = await axiosAuth.get(`/api/interfacePost/Setting`);
    return data;
  }

  if (module) {
    const { data } = await axiosAuth.get(`/api/interfacePost/Setting/${module}`);
    return data;
  }
}

export async function getSettingInfPostByCode(code, module) {
  const { data } = await axiosAuth.get(`/api/interfacePost/Setting/${code}/${module}`);
  return data;
}

export async function updateSettingInfPost(code, module, param) {
  const { data } = await axiosAuth.put(`/api/interfacePost/Setting/${code}/${module}`, param);
  return data;
}

export async function uploadFileInfPostGl(code, postType, param) {
  const { data } = await axiosAuth.post(`/api/interfacePostGL/UploadFiles?code=${code}&postType=${postType}`, param);
  return data;
}

export async function postInfPostGL(postType, param, forceUpdate = false) {
  const { data } = await axiosAuth.post(`/api/interfacePostGL/${postType}?forceUpdate=${forceUpdate}`, param);
  return data;
}

export async function uploadFileInfPostAR(code, postType, param) {
  const { data } = await axiosAuth.post(`/api/interfacePostAR/UploadFiles?code=${code}&postType=${postType}`, param);
  return data;
}

export async function postInfPostAR(param, forceUpdate = false) {
  const { data } = await axiosAuth.post(`/api/interfacePostAR?forceUpdate=${forceUpdate}`, param);
  return data;
}

export async function postInfPostARsaveToFolio(param) {
  const { data } = await axiosAuth.post("/api/interfacePostAR/saveToFolio", param);
  return data;
}

export async function uploadFileInfPostAP(code, postType, param) {
  const { data } = await axiosAuth.post(`/api/interfacePostAP/UploadFiles?code=${code}&postType=${postType}`, param);
  return data;
}

export async function postInfPostAP(param, postType, forceUpdate = false) {
  const { data } = await axiosAuth.post(`/api/interfacePostAP/${postType}?forceUpdate=${forceUpdate}`, param);
  return data;
}
