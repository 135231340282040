import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { GblContext } from "providers/formatter";
import { Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import TextTopInGrid from "components/TextTopInGrid";

function DialogFolioDetail(props) {
  const { data, open, onClose } = props;
  const { NumberFormat, DateToString } = useContext(GblContext);
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "10px 24px" }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {"Folio Detail"}
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />

        <DialogContent>
          <Grid container spacing={2} style={{ marginTop: 5 }}>
            <TextTopInGrid sizeSm={12} label="Seq" value={data?.PfmSeq} />
            <TextTopInGrid sizeSm={6} label="Invoice Date" value={DateToString(data?.InvDate)} />
            <TextTopInGrid sizeSm={6} label="Invoice No." value={data?.InvNo} />
            <TextTopInGrid
              sizeSm={6}
              label="Amount"
              value={NumberFormat(data?.Amount)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid
              sizeSm={6}
              label="Unpaid"
              value={NumberFormat(data?.Unpaid)}
              inputProps={{
                style: { textAlign: "right" },
              }}
            />
            <TextTopInGrid sizeSm={12} label="Description" value={data?.Description} />
            <TextTopInGrid sizeSm={12} label="Remark" value={data?.Remark} />
          </Grid>
        </DialogContent>
        <br />
      </Dialog>
    </>
  );
}

export default DialogFolioDetail;
