/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useForm } from "react-hook-form";
import { getAccountCodeList, getDepartmentList } from "services/setting";
import { uploadFileInfPostGl, postInfPostGL } from "services/interface";
import DialogMapping from "../Dialog/DialogMapping";
import { Box, Button, Typography, CircularProgress } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DatePickerFormat from "components/DatePickerFormat";
import { MuiAutosuggest, DescInForm } from "components/Form";
import ErrorTableDialog from "components/ErrorTable";
import fileReader from "utils/fileReader";
import SnackbarUtils from "utils/SnackbarUtils";
import ButtonUpload from "components/ButtonUpload";
import gbl from "utils/formatter";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function CardItem(props) {
  const translate = useTranslate();
  const classes = useStyles();
  const { ToMySqlDate } = useContext(GblContext);
  const [loading, setLoading] = useState(false);
  const [lookupList, setLookupList] = useState({
    accountCodeList: [],
    departmentList: [],
  });
  const [idForPost, setIdForPost] = useState();
  const [filterDate, setFilterDate] = useState({
    from: new Date(),
    to: new Date(),
  });

  const [configuration, setConfiguration] = useStateWithCallbackLazy();
  const [docType, setDocType] = useState();
  const [postType, setPostType] = useState();
  const [fileName, setFileName] = useState();
  const [fileArray, setFileArray] = useState({
    Inventory: [],
  });
  const [errorTable, setErrorTable] = useState(false);
  const [showErrorTable, setShowErrorTable] = useState(false);
  const [oldAccList, setOldAccList] = useState([]);
  const [openMapping, setOpenMapping] = useState(false);

  const methods = useForm({ defaultValues: configuration });

  const { reset } = methods;

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const fetchAccLookup = async () => {
    const { Data } = await getAccountCodeList("Gl");
    setLookupList((state) => ({
      ...state,
      accountCodeList: Data,
    }));
    setOldAccList(Data);
  };

  const fetchDeptLookup = async () => {
    const { Data } = await getDepartmentList();
    setLookupList((state) => ({
      ...state,
      departmentList: Data,
    }));
  };

  const fetchSetting = () => {
    const r = props.setting;
    if (r) {
      setPostType(r.PostType);
      setConfiguration(r.Configuration.Inventory ?? false);
      reset(r.Configuration.Inventory);
    }
  };

  useEffect(() => {
    fetchAccLookup();
    fetchDeptLookup();
  }, []);

  useEffect(() => {
    fetchSetting();
  }, []);

  const uploadFile = async (e) => {
    if (e.target.files.length >= 1) {
      let msg = fileReader.CheckLimitImgSize(e.target.files);
      if (msg) {
        document.getElementById("fileTxt").value = "";
        SnackbarUtils.error(translate(msg, { size: "5" }));
        return;
      }
      let files = Array.from(e.target.files);
      const filePathsPromises = [];
      files.forEach((file) => {
        filePathsPromises.push(fileReader.ToBase64(file));
      });
      var param = [];
      var txtArray = await Promise.all(filePathsPromises);
      txtArray.forEach((item, idx) => {
        param.push({
          FileName: files[idx].name,
          FileBase64: gbl.UnicodeToBase64(item),
        });
      });
      if (e.target.id === "fileTxt") {
        let strName = param.map((i) => i.FileName).join(", ");
        setFileName(strName);
        setFileArray((state) => ({
          ...state,
          Inventory: param,
        }));
        apiUploadFile(props.id, "Inventory", param);
      }
    } else {
      document.getElementById("fileTxt").value = "";
    }
  };

  const apiUploadFile = async (id, type, file) => {
    setLoading(true);
    if (file.length > 0) {
      let r = await uploadFileInfPostGl(id, type, file);
      if (r) {
        setIdForPost(r.id);
        if (r.Code) {
          SnackbarUtils.error(r.UserMessage);
          setLoading(false);
          return;
        }
        setFilterDate({
          from: new Date(r.DateFrom),
          to: new Date(r.DateTo),
        });
        setLoading(false);
        // SnackbarUtils.success(
        //   `Upload data from ${ToMySqlDate(new Date(r.DateFrom))} to ${ToMySqlDate(new Date(r.DateTo))}`
        // );
      }
    } else {
      setLoading(false);
      SnackbarUtils.warning("File Not Found");
    }
  };

  const CheckResultShowMessage = async ({ Code, InternalMessage, UserMessage, ErrorList }) => {
    if (Code === null) {
      SnackbarUtils.warning("File Not Found");
    }
    if (Code === 0 || Code === 1) {
      SnackbarUtils.success(InternalMessage);
    }
    if (Code === -1) {
      if (ErrorList?.length > 0) {
        var listError = [];
        if (ErrorList[0].Message) {
          ErrorList.forEach((i) => {
            i.Message = i.Message.concat("</br>");
            listError.push(Object.values(i).join("</br>"));
          });
          if (listError) {
            SnackbarUtils.warning(listError.join(""));
          }
        } else {
          SnackbarUtils.warning(InternalMessage);
          if (ErrorList.length > 0) {
            setErrorTable(ErrorList);
            setShowErrorTable(true);
          }
        }
      } else {
        SnackbarUtils.warning(InternalMessage);
      }
    }
    if (Code === -204) {
      SnackbarUtils.warning(UserMessage);
    }
    if (Code === -400) {
      SnackbarUtils.warning(UserMessage);
    }
    if (Code === -500) {
      SnackbarUtils.warning(UserMessage, function () {
        setOpenMapping(true);
      });
    }
    if (Code === -2146233079) {
      SnackbarUtils.error(UserMessage);
    }

    setLoading(false);
  };

  const post = async (id, type, file, config) => {
    setLoading(true);
    if (postType === "Json") {
      let param = {
        id: idForPost,
        Code: id,
        DateFrom: ToMySqlDate(filterDate.from),
        DateTo: ToMySqlDate(filterDate.to),
        DeptCode: config.DeptCode,
        AccCode: config.AccCode,
      };
      setDocType(type);
      let r = await postInfPostGL(type, param, false);
      if (r.Code === -200) {
        let msg = "Data already post. Do you want to repost ?";
        SnackbarUtils.loadingConfirm(
          msg,
          async function () {
            const rr = await postInfPostGL(type, param, true);
            CheckResultShowMessage(rr);
          },
          null
        );
      }
      CheckResultShowMessage(r);
    }

    if (postType !== "Json" && file.length > 0) {
      let param = {
        id: idForPost,
        Code: id,
        DateFrom: ToMySqlDate(filterDate.from),
        DateTo: ToMySqlDate(filterDate.to),
        DeptCode: config.DeptCode,
        AccCode: config.AccCode,
      };
      setDocType(type);
      let r = await postInfPostGL(type, param, false);
      if (r.Code === -200) {
        let msg = "Data already post. Do you want to repost ?";
        SnackbarUtils.loadingConfirm(
          msg,
          async function () {
            const rr = await postInfPostGL(type, param, true);
            CheckResultShowMessage(rr);
          },
          null
        );
      }
      CheckResultShowMessage(r);
    }
  };

  const LoadingButton = ({ text, disabled, fnc, defaultColor }) => {
    return (
      <div className={classes.wrapper}>
        <Button
          variant={defaultColor ? "outlined" : "contained"}
          color={defaultColor ? defaultColor : "primary"}
          disabled={disabled}
          onClick={fnc}
        >
          {text}
        </Button>
        {disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  };

  return (
    <div>
      {configuration && (
        <form onKeyDown={disableFormEnter}>
          {postType === "Json" && configuration?.Inventory ? (
            <Box p={1} align="left" style={{ paddingLeft: 0 }}>
              <DatePickerFormat
                label="From"
                value={filterDate.from}
                onChange={(e) => {
                  setFilterDate((state) => ({
                    ...state,
                    from: e,
                    to: e > filterDate.to ? e : filterDate.to,
                  }));
                }}
                style={{ width: 160, marginRight: "10px" }}
              />
              <DatePickerFormat
                label="To"
                value={filterDate.to}
                onChange={(e) => {
                  setFilterDate((state) => ({
                    ...state,
                    to: e,
                  }));
                }}
                minDate={filterDate.from}
                minDateMessage={"Date must be more than from date"}
                style={{ width: 160 }}
              />
            </Box>
          ) : (
            ""
          )}
          <div>
            <Accordion style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }} expanded={"panel1"}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="rev-content" id="rev-header">
                <Typography variant="h6">Inventory</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ paddingTop: 0, flexDirection: "column" }}>
                {postType !== "Json" && (
                  <Box p={1} display="flex" style={{ padding: 0 }}>
                    <Box p={1} flexGrow={1}>
                      <ButtonUpload
                        id="fileTxt"
                        name="fileTxt"
                        style={{ display: "none" }}
                        type="file"
                        onChange={uploadFile}
                        accept=".csv,.txt,application/JSON,(*.*)"
                        multiple
                        fileUpload={fileName}
                        cancelUpload={() => {
                          setFileName();
                          setFileArray((state) => ({
                            ...state,
                            Inventory: [],
                          }));
                          document.getElementById("fileTxt").value = "";
                        }}
                      />
                    </Box>
                  </Box>
                )}

                <Box p={1} align="left">
                  <Typography variant="body2">
                    <b>Select Balance Account</b>
                  </Typography>
                </Box>
                <Box p={1} align="center" style={{ padding: 0 }}>
                  <Box p={1} display="flex">
                    <MuiAutosuggest
                      label={translate("ra.field.Department")}
                      name="DeptCode"
                      optKey="DeptCode"
                      optDesc="Description"
                      options={lookupList["departmentList"]}
                      updateOtherField={[{ key: "DeptDesc", optKey: "Description" }]}
                      useFncUpdate={true}
                      fncUpdate={(value, methods) => {
                        let daccList = value?.DefaultAccount ? JSON.parse(value.DefaultAccount) : [];
                        if (daccList?.length > 0) {
                          setLookupList((state) => ({
                            ...state,
                            accountCodeListRE: daccList,
                          }));
                          //remove acc if not in defaultaccount
                          let acc = methods.getValues("AccCode");
                          if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
                            methods.setValue("AccCode", "");
                            methods.setValue("AccDesc", "");
                          }
                        } else {
                          setLookupList((state) => ({
                            ...state,
                            accountCodeListRE: oldAccList,
                          }));
                        }
                      }}
                      methods={methods}
                      style={{ width: 200 }}
                    />
                    <DescInForm
                      style={{ paddingLeft: 20 }}
                      name="Inventory.DeptDesc"
                      methods={methods}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                  <Box p={1} display="flex">
                    <MuiAutosuggest
                      label={translate("ra.field.Account")}
                      name="AccCode"
                      optKey="AccCode"
                      optDesc={localStorage.getItem("Language") === "en-US" ? "Description" : "Description2"}
                      options={lookupList["accountCodeList"]}
                      updateOtherField={[
                        {
                          key: "AccDesc",
                          optKey: localStorage.getItem("Language") === "en-US" ? "Description" : "Description2",
                        },
                      ]}
                      methods={methods}
                      style={{ width: 200 }}
                    />
                    <DescInForm
                      style={{ paddingLeft: 20 }}
                      name="AccDesc"
                      methods={methods}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Box>

                {postType !== "Json" && fileName && (
                  <Box p={1} align="center" style={{ padding: 0 }}>
                    <LoadingButton
                      text={translate("ra.actionMenu.POST")}
                      disabled={loading}
                      fnc={() => post(props.id, "Inventory", fileArray.Inventory, configuration.Inventory)}
                    />
                  </Box>
                )}
                {postType === "Json" && (
                  <Box p={1} align="center" style={{ padding: 0 }}>
                    <LoadingButton
                      text={translate("ra.actionMenu.POST")}
                      disabled={loading}
                      fnc={() => post(props.id, "Inventory", fileArray.Inventory, configuration.Inventory)}
                    />
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        </form>
      )}

      {openMapping && (
        <DialogMapping
          open={openMapping}
          onClose={() => setOpenMapping(false)}
          lookupList={{
            departmentList: lookupList["departmentList"],
            accountCodeList: lookupList["accountCodeList"],
          }}
          id={props.id}
          postType={postType}
          docType={docType}
          configuration={configuration.Inventory}
        />
      )}
      {showErrorTable && (
        <ErrorTableDialog open={showErrorTable} onClose={() => setShowErrorTable(false)} errorTable={errorTable} />
      )}
      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(configuration, 0, 2) : ""}</pre>
    </div>
  );
}
