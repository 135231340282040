import * as React from "react";
import { Route } from "react-router-dom";
import { RouteWithoutLayout } from "react-admin";
// import Dashboard from "pages/dashboard/Dashboard";
import { Profile } from "pages/profile";
import { Configuration } from "pages/configuration";
import { Setting } from "pages/settings";
import ResetPassword from "pages/ResetPassword";

const routeConfig = [
  // <Route exact path={`/dashboard`} render={() => <Dashboard />} />,
  <Route exact path={"/profile"} render={() => <Profile />} />,
  <Route exact path={"/administrator"} render={() => <Configuration />} />,
  <Route exact path={"/setting"} render={() => <Setting />} />,
  <RouteWithoutLayout path={"/resetpassword"} render={() => <ResetPassword />} />,
];

export default routeConfig;
