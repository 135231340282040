import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useAuthState, useTranslate, useLocale } from "react-admin";
import {
	TextFieldInForm,
	SelectInForm,
	DescInForm,
	MuiAutosuggest,
	NumberFormatInForm,
} from "components/Form";
import { getGlPrefix } from "services/generalLedger";
import { getAccountCodeList, getDepartmentList } from "services/setting";
import { getLookupCurrency } from "services/lookup";
import { makeStyles } from "@material-ui/core/styles";
import List from "./List";
import Show from "./Show";
import Edit from "./Edit";
import Create from "./Create";
import { PeriodList } from "utils/options";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: 12,
		marginBottom: 12,
	},
	footerCell: {
		backgroundColor: theme.palette.background.paper,
		borderTop: "2px solid rgba(224, 224, 224, 1)",
		borderBottom: "none",
	},
	stickyFooterCell: {
		position: "sticky",
		bottom: 0,
		zIndex: 100,
		textAlign: "right",
		fontSize: "0.9rem",
		fontWeight: 600,
		color: theme.palette.primary.main,
	},
	drawerOpen: {
		marginRight: drawerWidth,
	},
	drawerClose: {
		marginRight: 54,
	},
	borderTableTop: {
		borderTop: "1px solid rgba(224, 224, 224, 1)",
	},
	borderTable: {
		border: "1px solid rgba(224, 224, 224, 1)",
	},
}));

const SwitchActionMode = (props) => {
	const locale = useLocale();
	const translate = useTranslate();
	const { authenticated } = useAuthState();
	const { settingAll, ToMySqlDate } = useContext(GblContext);
	const { SettingSystem } = settingAll;
	const addMode = props.location.pathname.search("create") !== -1;
	const viewMode = props.location.pathname.search("show") !== -1;
	const [lookupList, setLookupList] = useState({
		prefixList: [],
		accountCodeList: [],
		departmentList: [],
		currencyList: [],
	});
	const [oldAccList, setOldAccList] = useState([]);
	const fetchPrefixLookup = useCallback(async () => {
		const { Data } = await getGlPrefix();
		setLookupList((state) => ({
			...state,
			prefixList: Data,
		}));
	}, []);
	const fetchAccLookup = useCallback(async () => {
		const { Data } = await getAccountCodeList("Gl");
		setLookupList((state) => ({
			...state,
			accountCodeList: Data,
		}));
		setOldAccList(Data);
	}, []);
	const fetchDeptLookup = useCallback(async () => {
		const { Data } = await getDepartmentList();
		setLookupList((state) => ({
			...state,
			departmentList: Data,
		}));
	}, []);
	const fetchCurrencyLookup = useCallback(async () => {
		const p = {
			Module: "GL",
			CurrDate: ToMySqlDate(new Date()),
		};
		const arr = await getLookupCurrency(p);
		setLookupList((state) => ({
			...state,
			currencyList: arr,
		}));
	}, [ToMySqlDate]);

	useEffect(() => {
		if (authenticated) {
			fetchPrefixLookup();
			fetchAccLookup();
			fetchDeptLookup();
			fetchCurrencyLookup();
		}
	}, [
		authenticated,
		fetchPrefixLookup,
		fetchAccLookup,
		fetchDeptLookup,
		fetchCurrencyLookup,
	]);

	const labelList = {
		Prefix: translate("ra.fieldAbbr.prefix"),
		Type: translate("ra.fieldAbbr.type"),
		"Voucher No.": translate("ra.fieldAbbr.vouNo"),
		Date: translate("ra.fieldAbbr.date"),
		Description: translate("ra.fieldAbbr.desc"),
		Department: translate("ra.fieldAbbr.dept"),
		"Account #": translate("ra.fieldAbbr.account1"),
		Comment: translate("ra.fieldAbbr.comment"),
		Currency: translate("ra.fieldAbbr.currency"),
		Rate: translate("ra.fieldAbbr.rate"),
		Amount: translate("ra.fieldAbbr.amount"),
		Base: translate("ra.fieldAbbr.base"),
		"Dr.": translate("ra.fieldAbbr.dr"),
		"Cr.": translate("ra.fieldAbbr.cr"),
	};

	const formFieldsEdit = [
		{
			size: 2,
			field: (
				<MuiAutosuggest
					label={`* ${labelList["Prefix"]}`}
					name="Prefix"
					optKey="PrefixName"
					optDesc="Description"
					options={lookupList["prefixList"]}
					updateOtherField={[{ key: "PrefixDesc", optKey: "Description" }]}
					disabled={!addMode}
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
				/>
			),
		},
		{
			size: 2,
			field: (
				<SelectInForm
					label={`${labelList["Type"]}`}
					name="Type"
					options={["Recurring"]}
					disabled
				/>
			),
		},
		{
			size: 8,
			field: (
				<TextFieldInForm
					label={`${labelList["Description"]}`}
					name="Description"
					variant="outlined"
					margin="dense"
					rule={{
						maxLength: {
							value: 255,
							message: "maximum length is 255",
						},
					}}
				/>
			),
		},
	];

	const formFieldsRecurring = [
		{
			size: 2,
			field: (
				<SelectInForm
					label={translate("ra.field.From")}
					name="FJvhFrom"
					options={PeriodList()}
				/>
			),
			style: { marginTop: -20 },
		},
		{
			size: 2,
			field: (
				<SelectInForm
					label={translate("ra.field.To")}
					name="FJvhTo"
					options={PeriodList()}
					customMinDate={"FJvhFrom"}
					minDateMessage={"Date must be more than from date"}
				/>
			),
			style: { marginTop: -20 },
		},
		{
			size: 2,
			field: (
				<SelectInForm
					label="Recurring Every Period"
					name="FJvhFreq"
					options={[
						"1",
						"2",
						"3",
						"4",
						"5",
						"6",
						"7",
						"8",
						"9",
						"10",
						"11",
						"12",
					]}
				/>
			),
			style: { marginTop: -20 },
		},
	];

	const formFieldsDetail = [
		{
			size: 6,
			field: (
				<MuiAutosuggest
					label={`* ${labelList["Department"]}`}
					name="DeptCode"
					optKey="DeptCode"
					optDesc="Description"
					options={lookupList["departmentList"]}
					updateOtherField={[{ key: "DeptDesc", optKey: "Description" }]}
					useFncUpdate={true}
					fncUpdate={(value, methods) => {
						const daccList = value?.DefaultAccount
							? JSON.parse(value.DefaultAccount)
							: [];
						if (daccList?.length > 0) {
							setLookupList((state) => ({
								...state,
								accountCodeList: daccList,
							}));
							//remove acc if not in defaultaccount
							const acc = methods.getValues("AccCode");
							if (acc !== "" && !daccList.find((i) => i.AccCode === acc)) {
								methods.setValue("AccCode", "");
								methods.setValue("AccDesc", "");
							}
						} else {
							setLookupList((state) => ({
								...state,
								accountCodeList: oldAccList,
							}));
						}
					}}
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
				/>
			),
		},
		{
			size: 6,
			name: "DeptDesc",
			field: (
				<DescInForm
					style={{ marginTop: 8 }}
					name="DeptDesc"
					InputProps={{
						readOnly: true,
					}}
				/>
			),
		},
		{
			size: 6,
			field: (
				<MuiAutosuggest
					label={`* ${labelList["Account #"]}`}
					name="AccCode"
					optKey="AccCode"
					optDesc={locale === "en-US" ? "Description" : "Description2"}
					options={lookupList["accountCodeList"]}
					updateOtherField={[
						{
							key: "AccDesc",
							optKey: locale === "en-US" ? "Description" : "Description2",
						},
					]}
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
				/>
			),
		},
		{
			size: 6,
			name: "AccDesc",
			field: (
				<DescInForm
					style={{ marginTop: 8 }}
					name="AccDesc"
					InputProps={{
						readOnly: true,
					}}
				/>
			),
		},
		{
			size: 12,
			field: (
				<TextFieldInForm
					label={`${labelList["Comment"]}`}
					name="Description"
					variant="outlined"
					margin="dense"
					multiline
					rule={{
						maxLength: {
							value: 255,
							message: "maximum length is 255",
						},
					}}
				/>
			),
		},
		{
			size: 6,
			field: (
				<MuiAutosuggest
					label={`* ${labelList["Currency"]}`}
					name="CurCode"
					optKey="CurrCode"
					optDesc="CurrRate"
					options={lookupList["currencyList"]}
					updateOtherField={[{ key: "CurRate", optKey: "CurrRate" }]}
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
				/>
			),
		},
		{
			size: 6,
			field: (
				<NumberFormatInForm
					label={`* ${labelList["Rate"]}`}
					name="CurRate"
					rule={{
						min: {
							value: 0.000001,
							message: `* ${translate("ra.validation.required")}`,
						},
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
					decimal={SettingSystem.CurrencyRateDecimal}
					// decimalSep={SettingSystem.DecimalSeparator}
					// thousandSep={SettingSystem.ThousandSeparator}
				/>
			),
		},
		{
			size: 6,
			field: (
				<NumberFormatInForm
					label={`* ${labelList["Amount"]} ${labelList["Dr."]}`}
					name="DrAmount"
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
					decimal={SettingSystem.CurrencyBaseDecimal}
					decimalSep={SettingSystem.DecimalSeparator}
					thousandSep={SettingSystem.ThousandSeparator}
				/>
			),
		},
		{
			size: 6,
			field: (
				<NumberFormatInForm
					label={`* ${labelList["Amount"]} ${labelList["Cr."]}`}
					name="CrAmount"
					rule={{
						required: {
							value: true,
							message: `* ${translate("ra.validation.required")}`,
						},
					}}
					decimal={SettingSystem.CurrencyBaseDecimal}
					decimalSep={SettingSystem.DecimalSeparator}
					thousandSep={SettingSystem.ThousandSeparator}
				/>
			),
		},
		{
			size: 6,
			field: (
				<NumberFormatInForm
					label={`* ${labelList["Base"]} ${labelList["Dr."]}`}
					name="DrBase"
					readOnly={true}
					decimal={SettingSystem.CurrencyBaseDecimal}
					decimalSep={SettingSystem.DecimalSeparator}
					thousandSep={SettingSystem.ThousandSeparator}
				/>
			),
		},
		{
			size: 6,
			field: (
				<NumberFormatInForm
					label={`* ${labelList["Amount"]} ${labelList["Cr."]}`}
					name="CrBase"
					readOnly={true}
					decimal={SettingSystem.CurrencyBaseDecimal}
					decimalSep={SettingSystem.DecimalSeparator}
					thousandSep={SettingSystem.ThousandSeparator}
				/>
			),
		},
	];

	if (addMode) {
		return (
			<Create
				{...props}
				formFields={formFieldsEdit}
				formFieldsDetail={formFieldsDetail}
				formFieldsRecurring={formFieldsRecurring}
				lookupList={lookupList}
				useStyles={useStyles}
			/>
		);
	} else if (viewMode) {
		return <Show {...props} useStyles={useStyles} />;
	} else {
		return (
			<Edit
				{...props}
				formFields={formFieldsEdit}
				formFieldsDetail={formFieldsDetail}
				formFieldsRecurring={formFieldsRecurring}
				lookupList={lookupList}
				useStyles={useStyles}
			/>
		);
	}
};

export default {
	list: List,
	show: SwitchActionMode,
	edit: SwitchActionMode,
	create: SwitchActionMode,
};
