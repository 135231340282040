import React from "react";
import { useTranslate } from "react-admin";
import { Box, TextField } from "@material-ui/core";
import NumberFormatInput from "components/NumberFormatInput";

const SummaryRightSide = (props) => {
  const translate = useTranslate();
  //const classes = useStyles();
  const { data } = props;
  //const { NumberFormat } = useContext(GblContext);
  let total = data.NetBaseAmt + data.TaxBaseAmt1 + data.TaxBaseAmt2;
  return (
    <React.Fragment>
      {/* <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ margin: "0px -8px 10px -8px" }}>
        <Grid item xs={6}>
          <Typography variant="body2" align="right">
            <b>{translate("ra.field.Net Amount")} :</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.contentRight}>
            {NumberFormat(data.NetBaseAmt)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" align="right">
            <b>{translate("ra.field.Tax")} 1:</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.contentRight}>
            {NumberFormat(data.TaxBaseAmt1)}
          </Typography>
        </Grid>
        {data.TaxType2 !== "None" ? (
          <React.Fragment>
            {" "}
            <Grid item xs={6}>
              <Typography variant="body2" align="right">
                <b>{translate("ra.field.Tax")} 2:</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" className={classes.contentRight}>
                {NumberFormat(data.TaxBaseAmt2)}
              </Typography>
            </Grid>
          </React.Fragment>
        ) : (
          ""
        )}
        <Grid item xs={6}>
          <Typography variant="body2" align="right">
            <b>{translate("ra.field.Total")} :</b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.contentRight}>
            {NumberFormat(data.NetBaseAmt + data.TaxBaseAmt1 + data.TaxBaseAmt2)}
          </Typography>
        </Grid>
      </Grid> */}
      <Box mx={0.5} p={0.5}>
        <TextField
          label={translate("ra.field.Net Amount")}
          variant="outlined"
          margin="dense"
          disabled
          inputProps={{ style: { textAlign: "right" } }}
          InputProps={{
            inputComponent: NumberFormatInput,
          }}
          value={data.NetBaseAmt}
          fullWidth
        />
      </Box>
      <Box mx={0.5} p={0.5}>
        <TextField
          label={translate("ra.field.Tax 1")}
          variant="outlined"
          margin="dense"
          disabled
          inputProps={{ style: { textAlign: "right" } }}
          InputProps={{
            inputComponent: NumberFormatInput,
          }}
          value={data.TaxBaseAmt1}
          fullWidth
        />
      </Box>
      {data.TaxType2 !== "None" && (
        <Box mx={0.5} p={0.5}>
          <TextField
            label={translate("ra.field.Tax 2")}
            variant="outlined"
            margin="dense"
            disabled
            inputProps={{ style: { textAlign: "right" } }}
            InputProps={{
              inputComponent: NumberFormatInput,
            }}
            value={data.TaxBaseAmt2}
            fullWidth
          />
        </Box>
      )}
      <Box mx={0.5} p={0.5}>
        <TextField
          label={translate("ra.field.Total")}
          variant="outlined"
          margin="dense"
          disabled
          inputProps={{ style: { textAlign: "right" } }}
          InputProps={{
            inputComponent: NumberFormatInput,
          }}
          value={total}
          fullWidth
        />
      </Box>
    </React.Fragment>
  );
};

export default SummaryRightSide;
