import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { useTranslate } from "react-admin";

const TextTopInGrid = ({ sizeSm, label, style, value, multiline, inputProps }) => {
  const translate = useTranslate();

  return (
    <Grid item xs={12} sm={sizeSm}>
      <TextField
        style={style}
        inputProps={inputProps}
        label={translate(`ra.field.${label}`)}
        variant="outlined"
        margin="dense"
        readOnly
        fullWidth
        multiline={multiline ?? false}
        rows={multiline ? 4 : 0}
        value={value || value === 0 ? value : ""}
      />
    </Grid>
  );
};

export default TextTopInGrid;
