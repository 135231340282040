import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Loading, Error, useRedirect, useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { Paper, Grid, Switch, Tabs, Tab } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextTopInGrid from "components/TextTopInGrid";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import NavRight from "components/NavRightSide";
import ArContractDetail from "./ArContractDetail";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { permissionName } from "utils/constants";
import { showReportByName } from "pages/Report/services";
import { getArProfileDetail, delArProfileDetail } from "services/accountReceivable";
import SnackbarUtils from "utils/SnackbarUtils";
import ReportBackDrop from "components/ReportBackDrop";
import DialogArContractDetail from "./DialogArContractDetail";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = withStyles({
  root: {
    textTransform: "none",
  },
})((props) => <Tab {...props} />);

const Show = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { DateToString, NumberFormat } = useContext(GblContext);
  const { basePath, id, permissions } = props;
  const redirect = useRedirect();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error] = useState();
  const [openDim, setOpenDim] = useState(false);
  const [openContract, setOpenContract] = useState(false);
  const [dataContract, setDataContract] = useState();
  const [valueOfTab, setValueOfTab] = useState(0);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setValueOfTab(newValue);
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", fnc: () => redirect("create", basePath) },
    { name: "Edit", fnc: () => redirect("edit", basePath, id) },
    { name: "Delete", fnc: () => DelOrVoid() },
    {
      name: "Print",
      fnc: () => {
        props.showReportLoader();
        showReportByName("AR_PROFILE", [{ Name: "List", Value: id }]);
        setTimeout(() => {
          props.hideReportLoader();
        }, 2000);
      },
    },
  ];

  const fetchById = useCallback(
    async (mounted) => {
      const response = await getArProfileDetail(id);
      if (response) {
        setData(response);
      }
      if (mounted) {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [id]
  );

  useEffect(() => {
    let mounted = true;
    fetchById(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, [fetchById]);

  const DelOrVoid = async () => {
    const msg = translate("ra.question.confirmDel");
    SnackbarUtils.delConfirm(msg, async function () {
      const { Code, InternalMessage, UserMessage } = await delArProfileDetail(id);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect("list", basePath);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    });
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value) => {
          return (
            <>
              <VisibilityIcon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  ShowContract(value);
                }}
              />
            </>
          );
        },
      },
    },
    {
      name: "Active",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Switch
              checked={typeof value === "boolean" ? value : false}
              onChange={(e, newValue) => e.preventDefault()}
              //disabled={true}
            />
          );
        },
      },
    },
    {
      name: "ContractNo",
      label: "Contract No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ConHDesc",
      label: "Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "CurCode",
      label: "Currency",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
      },
    },
    {
      name: "CurRate",
      label: "Rate",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "currency");
        },
      },
    },
    {
      name: "ProjectCode",
      label: "Project",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Owner",
      label: "Owner",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ConStart",
      label: "Start Contract",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return value ? DateToString(new Date(value)) : null;
        },
      },
    },
    {
      name: "ConEnd",
      label: "End Contract",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          if (!value) {
            return "";
          }
          const v = new Date(value);
          return DateToString(v);
        },
      },
    },
    {
      name: "PeriodicMonth",
      label: "Charge Every Month",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
      },
    },
    {
      name: "TotalAmount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
  ];

  const options = {
    responsive: "standard",
    selectableRows: "none",
    fixedHeader: true,
    tableBodyHeight: "500px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (let i = removeArray.length - 1; i >= 0; i--) data.Detail.splice(removeArray[i], 1);
    },
  };

  const ShowContract = (index) => {
    setDataContract(data.ContractDetail[index]);
    setOpenDialogDetail(!openDialogDetail);
  };

  const ShowDim = () => {
    setOpenDim(!openDim);
  };

  const SearchByNo = (e) => {
    if (e) {
      redirect("show", basePath, e.ArProfileId);
    }
  };

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!data) return null;

  const InformationContent = () => {
    return (
      <>
        {/* <BoxHeader header={"Information"} /> */}
        <Grid container alignItems="flex-start" spacing={1} style={{ margin: "0 0 12px 0" }}>
          <Grid container item xs={12} sm={12} spacing={1}>
            <TextTopInGrid sizeSm={2} label="A/R No." value={data.ArNo} />
            <TextTopInGrid sizeSm={2} label="Type" value={data.ArType ? `${data.ArType} : ${data.ArTypeDesc}` : ""} />
            <TextTopInGrid sizeSm={2} label="Title" value={data.Title} />
            <TextTopInGrid sizeSm={3} label="First Name" value={data.FirstName} />
            <TextTopInGrid sizeSm={3} label="Last Name" value={data.LastName} />
            <TextTopInGrid sizeSm={2} label="Register Date" value={DateToString(data.RegDate)} />

            <TextTopInGrid sizeSm={10} label="Company Name" value={data.Company} />
          </Grid>
          <Grid container item xs={12} sm={12} spacing={1}>
            <TextTopInGrid sizeSm={6} label="Position" value={data.Position} />
            <TextTopInGrid sizeSm={3} label="Telephone" value={data.Tel} />
            <TextTopInGrid sizeSm={3} label="Fax" value={data.Fax} />
            <TextTopInGrid sizeSm={3} label="TAX ID." value={data.TaxNo} />
            <TextTopInGrid sizeSm={3} label="Branch No." value={data.BranchNo} />
            <TextTopInGrid sizeSm={3} label="Email" value={data.Email} />
            <TextTopInGrid sizeSm={3} label="Remark" value={data.Remark} />
          </Grid>
          <Grid container item xs={12} sm={12} spacing={1}>
            <TextTopInGrid sizeSm={12} label="Address1" value={data.AddressInfo.Address1} />
            <TextTopInGrid sizeSm={12} label="Address2" value={data.AddressInfo.Address2} />
            <TextTopInGrid sizeSm={12} label="Address3" value={data.AddressInfo.Address3} />
          </Grid>
          {/* <Grid container item xs={12} sm={12} spacing={1}>
            <TextTopInGrid sizeSm={12} label="Remark" value={data.Remark} />
          </Grid> */}
        </Grid>
      </>
    );
  };

  const Billing = () => {
    return (
      <Grid container alignItems="flex-start" spacing={1} style={{ margin: "0 0 12px 0" }}>
        <TextTopInGrid
          sizeSm={6}
          label="Payment"
          value={data.PaymentCode ? `${data.PaymentCode} : ${data.PaymentDesc}` : ""}
        />
        <TextTopInGrid sizeSm={6} label="Billing To" value={data.BillTo} />
        <TextTopInGrid sizeSm={6} label="Credit Limit" value={`${NumberFormat(data.CreditLimit)} THB`} />
        <TextTopInGrid sizeSm={6} label="Mailing To" value={data.MailTo} />
        <TextTopInGrid sizeSm={6} label="Credit Term" value={`${data.CreditTerm ?? 0} Day`} />
        <TextTopInGrid sizeSm={6} label="Tax Invoice Address" value={data.TaxTo} />
      </Grid>
    );
  };

  const Contract = () => <MuiTranslateTable data={data.ContractDetail} columns={columns} options={options} />;

  return (
    <div
      className={clsx({
        [classes.drawerOpen]: openDim,
        [classes.drawerClose]: !openDim,
      })}
    >
      <ActionMenu
        menuControl={menuControlProp}
        permission={permissions.find((i) => i.Name === permissionName["AR.Profile"])}
      />

      <Paper className={classes.root}>
        <BoxHeader
          header={"A/R Profile"}
          showSearch
          searchOption={{
            placeholder: `${translate("ra.fieldAbbr.searchby")} ${translate("ra.field.A/R No.")}`,
            update: SearchByNo,
            module: "AR_Profile",
            keyCode: "ArNo",
            keyDesc: "Company",
          }}
          data={data}
          status={data.Active}
        />

        <Tabs
          value={valueOfTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          style={{
            marginTop: 20,
            marginBottom: 12,
            border: "1px solid #e0e0e3",
            borderRadius: "10px",
          }}
        >
          <StyledTab label={translate("ra.module.Information")} {...a11yProps(0)} />
          <StyledTab label={translate("ra.field.Contract")} {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={valueOfTab} index={0}>
          {/* Information */}
          <Grid container justifyContent="flex-start" spacing={1}>
            <Grid item xs={12}>
              <InformationContent />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={valueOfTab} index={1}>
          {/* Contract */}
          <Contract />
        </TabPanel>
      </Paper>

      {/* Billing */}
      {valueOfTab === 0 && (
        <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 12 }}>
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                <Typography className={classes.heading}> {translate("ra.field.Billing")} </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Billing />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}

      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => ShowDim()}
        module={"AR_Profile"}
        moduleId={id}
      />

      {openContract && (
        <ArContractDetail open={openContract} close={() => setOpenContract(false)} data={dataContract} modify={false} />
      )}

      {openDialogDetail && (
        <DialogArContractDetail
          open={openDialogDetail}
          onClose={() => setOpenDialogDetail(false)}
          data={dataContract}
          modify={false}
        />
      )}

      <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
    </div>
  );
};

export default ReportBackDrop(Show);
