import React, { useState } from "react";
import html2canvas from "html2canvas";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Card, CardHeader, CardContent, Typography, Menu, MenuItem, Icon, IconButton } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { Chart } from "react-google-charts";
import { makeStyles } from "@material-ui/core/styles";
import ConfigDashboard from "./Dialog/ConfigDashboard";
import { addDays, addYears } from "date-fns";
import SnackbarUtils from "utils/SnackbarUtils";
import { deleteWidgetById } from "services/callStoreProcedure";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    display: "flex",
    flexDirection: "column",
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  main: () => ({
    overflow: "inherit",
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      color: theme.palette.type === "dark" ? "inherit" : "#dc2440",
    },
  }),
  cardHeader: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  cardContent: {
    background: theme.palette.type === "dark" ? "inherit" : "white",
  },
  circulLoading: {
    margin: 50,
    display: "flex",
    justifyContent: "center",
  },
}));

const ComboChart = (props) => {
  const classes = useStyles();
  const [param, setParam] = useState({
    Id: props.Id,
    // prevDate: props.prevDate,
    // date: props.date,
    name: props.name,
    Seq: props.Seq,
    FromDate: props.FromDate,
    ToDate: props.ToDate,
    Width: props.Width,
    configName: props.configName,
    Config: props.Config,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openWidgetMenu = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const { name, data, dragHandle } = props;
  const updateChart = async (param) => {
    setLoading(true);
    let paramChart = {
      ...props,
      Id: param.Id,
      name: param.name,
      // prevDate: param.prevDate,
      // date: param.date,
      Seq: parseInt(param.Seq),
      FromDate: param.FromDate,
      ToDate: param.ToDate,
      Width: param.Width,
      Config: param.Config,
    };
    //if (param.date !== "Invalid Date") {
    props.updateChart(paramChart);
    //}
  };

  const closeConfig = () => {
    setOpen(false);
  };

  function downloadBase64File(linkSource, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const SaveDashboardToImg = (id) => {
    const divBox = document.getElementById(id);
    html2canvas(divBox, {
      backgroundColor: "white",
      ignoreElements: (element) => {
        /* Remove element with id="MyElementIdHere" */
        if ("noPrint" === element.id) {
          return true;
        }
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      downloadBase64File(imgData, name);
    });
  };

  return (
    <>
      {loading ? (
        <div className={classes.circulLoading}>
          <CircularProgress />
        </div>
      ) : (
        <div id={name}>
          <Card className={classes.card} elevation={6}>
            <CardHeader
              className={classes.cardHeader}
              action={
                <div id="noPrint">
                  {/* <IconButton
                    aria-label="print report Trial Balance"
                    onClick={async () => {
                      let currentYear = param.FromDate.getFullYear();
                      let currentMonth = param.ToDate.getMonth() + 1;
                      let fromDate = `${currentYear}-01`;
                      let toDate = `${currentYear}-${currentMonth}`;

                      let accList = param.Config.Account.Value.split(",");
                      let deptList = param.Config.Department.Value.split(",");

                      const paramReportTrialBalance = {
                        Id: 205,
                        Type: "Report",
                        FileFormat: "pdf",
                        Parameters: [
                          { Name: "DateFrom", Value: fromDate },
                          { Name: "DateTo", Value: toDate },
                          { Name: "DeptCodeFrom", Value: deptList[0] },
                          { Name: "DeptCodeTo", Value: deptList[deptList.length - 1] },
                          { Name: "AccCodeFrom", Value: accList[0] },
                          { Name: "AccCodeTo", Value: accList[accList.length - 1] },
                          { Name: "AccType", Value: "A" },
                          { Name: "AccStatus", Value: "All" },
                          { Name: "GroupBy", Value: "None" },
                        ],
                      };

                      let data = await showReportPDF(paramReportTrialBalance);
                      var blob = new Blob([data], { type: "application/pdf" });
                      const objUrl = window.URL.createObjectURL(blob);
                      const link = document.createElement("a");
                      link.href = objUrl;
                      link.target = "_blank";
                      link.click();
                    }}
                  >
                    <Icon>print</Icon>
                  </IconButton> */}
                  <IconButton aria-label="settings" onClick={() => SaveDashboardToImg(name)}>
                    <Icon>save_alt_icon</Icon>
                  </IconButton>
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      param.FromDate = addYears(addDays(new Date(), -1), -4);
                      param.ToDate = addDays(new Date(), -1);
                      updateChart(param);
                    }}
                  >
                    <Icon>refresh</Icon>
                  </IconButton>
                  <IconButton
                    id="basic-button"
                    aria-controls={openWidgetMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openWidgetMenu ? "true" : undefined}
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <IconButton id="drag-button" style={{ cursor: "row-resize" }}>
                    {dragHandle}
                  </IconButton>
                  {open && (
                    <ConfigDashboard
                      open={open}
                      close={closeConfig}
                      updateChart={updateChart}
                      param={param}
                      updateParam={setParam}
                    />
                  )}
                </div>
              }
              title={
                <Typography variant="body1">
                  <b>{name}</b>
                </Typography>
              }
              subheader={
                <Typography variant="body2">
                  {param.FromDate ? new Date(param.FromDate).getFullYear() : ""} -{" "}
                  {param.ToDate ? new Date(param.ToDate).getFullYear() : ""}
                </Typography>
              }
            />
            <Menu
              id="widget-menu"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                key={1}
                onClick={() => {
                  setOpen(true);
                  setAnchorEl(null);
                }}
              >
                Edit Widget
              </MenuItem>
              <MenuItem
                key={2}
                onClick={() => {
                  let msg = "Are you sure delete this widget?";
                  SnackbarUtils.loadingConfirm(
                    msg,
                    async function () {
                      await deleteWidgetById(props.Id);
                      return props.Id;
                    },
                    async function (id) {
                      props.removeChart(id);
                    }
                  );
                  setAnchorEl(null);
                }}
              >
                Delete Widget
              </MenuItem>
            </Menu>
            <CardContent className={classes.cardContent} style={{ marginBottom: 20 }}>
              {data ? (
                <Chart
                  width="100%"
                  height="400px"
                  chartType="ComboChart"
                  //chartType="Bar"
                  loader={<div>Loading Chart</div>}
                  data={data}
                  options={{
                    explorer: {
                      actions: ["dragToZoom", "rightClickToReset"],
                      axis: "horizontal",
                      keepInBounds: true,
                    },
                    vAxis: { format: isXSmall ? "short" : "decimal" },
                    hAxis: {
                      //title: "X",
                      format: "MM/yyyy",
                      textStyle: { fontSize: 12 },
                    },

                    //colors: ["#bdbdbd", "#2196f3", "#FEDE00"],
                    legend: { position: "top", alignment: "center" },
                    chartArea: {
                      width: "80%",
                    },
                    pointSize: 3,
                  }}
                  chartPackages={["corechart", "controls"]}
                  controls={[
                    {
                      controlType: "ChartRangeFilter",
                      options: {
                        filterColumnIndex: 0,
                        ui: {
                          chartType: "LineChart",
                          chartOptions: {
                            height: 50,
                            chartArea: { width: "80%" },
                            hAxis: {
                              baselineColor: "none",
                              format: "MM/yy",
                              textStyle: { fontSize: 12 },
                            },
                          },
                          snapToData: true,
                        },
                      },
                      controlPosition: "top",
                      // controlWrapperParams: {
                      //   state: {
                      //     range: {
                      //       start,
                      //       end,
                      //     },
                      //   },
                      // },
                    },
                  ]}
                />
              ) : (
                ""
              )}
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default ComboChart;
