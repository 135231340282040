/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { GblContext } from "providers/formatter";
import { useRedirect, useTranslate } from "react-admin";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import clsx from "clsx";
import { Paper, Grid, Button, Typography, Icon } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { TableHead, TableFooter, TableRow, TableCell } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { TableHead as MuiTableHead } from "mui-datatables";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
//import NavRight from "components/NavRightSide";
import PopupTable from "components/PopupTable";
import PopupSettlement from "components/PopupSettlement";
import { createPaymentDetail } from "services/accountPayable";
//import { getActiveDimListByModuleName } from "services/dimension";
import Model from "models/apPayment";
import SnackbarUtils from "utils/SnackbarUtils";

const Create = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const {
    basePath,
    formFields,
    formFieldsPaymentAccount,
    formFieldsGainLossAccount,
    formFiledWhtAccount,
    formFieldsWht,
    formFieldsWhtDetail,
    vendorList,
    apWhtList,
  } = props;
  const redirect = useRedirect();

  const { settingAll, NumberFormat, ToNumber } = useContext(GblContext);
  const { SettingSystem } = settingAll;
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [initData, setInitData] = useStateWithCallbackLazy(Model);
  const [initDataDetail, setInitDataDetail] = useState([]);
  const [unselectInvoice, setUnselectInvoice] = useState([]);
  const [initNewWhtRow, setInitNewWhtRow] = useState({
    Id: 0,
    PyhSeq: 0,
    WhtCode: "",
    WhtDesc: "",
    WhtRate: 0,
    SuggestionAmt: 0,
    Amount: 0,
    TaxAmt: 0,
  });

  const methods = useForm({ defaultValues: initData });

  const { handleSubmit, getValues, setValue, reset } = methods;

  useEffect(() => {
    Model.CurCode = SettingSystem.DefaultCurrencyCode;
    Model.CurRate = SettingSystem.DefaultCurrencyRate;
    const cloneModel = _.cloneDeep(Model);
    setInitData(cloneModel);
    setInitDataDetail(cloneModel.Detail);
    reset(cloneModel);
  }, []);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async () => {
    const values = getValues();
    const isCheckGLAccountPass = CheckGLAccount(initData.GainLossAmt);
    const isCheckWhtAccountPass = CheckWhtAccount();

    //Adjust parameter before save
    if (isCheckGLAccountPass && isCheckWhtAccountPass && Object.keys(methods.errors).length === 0) {
      setBtnLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
      setInitData(
        (state) => ({
          ...state,
          ...values,
          PayWht: {
            WhtTypeCode: values.WhtTypeCode,
            TaxId: values.TaxId,
            BranchNo: values.BranchNo,
            PrePayee: values.PrePayee,
            Payee: values.Payee,
            Address: values.Address,
            WhtNo: values.WhtNo,
            WhtRemark1: values.WhtRemark1,
            WhtRemark2: values.WhtRemark2,
            WhtDeptCode: values.WhtDeptCode,
            WhtTaxCr: values.WhtTaxCr,
            WhtTotalAmt: ToNumber(state.WhtTotalAmt),
            Items: [...state.Items],
          },
          CurRate: ToNumber(values.CurRate),
          GainLossAmt: ToNumber(state.GainLossAmt),
          CurPayAmt: ToNumber(state.CurPayAmt),
          PyhAmt: ToNumber(state.PyhAmt),
          PyhAbfTax: ToNumber(state.PyhAbfTax),
        }),
        (nextState) => Save(nextState)
      );
    } else {
      return false;
    }
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Void", disabled: true },
    { name: "Print", disabled: true },
  ];

  const [showAdd, setShowAdd] = useState(false);
  const [showWhtService, setShowWhtService] = useState(false);
  const [editWhtIndex, setEditWhtIndex] = useState("");

  const CheckSelectedVendor = () => {
    const vnCode = getValues("VnCode");
    if (vnCode && vnCode !== "") {
      AddNewRow();
    } else {
      SnackbarUtils.warning(translate("ra.permission.selectVendorFirst"));
    }
  };

  const CustomHeader = (props) => {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6} style={{ paddingLeft: 0 }}>
              <Button variant="outlined" onClick={CheckSelectedVendor} startIcon={<Icon>add</Icon>}>
                Select Invoice For Settlement
              </Button>
            </TableCell>
            <TableCell align="right" colSpan={5} />
          </TableRow>
        </TableHead>
        <MuiTableHead {...props} />
      </>
    );
  };

  const AddNewRow = () => {
    setShowAdd(true);
  };

  const SaveFromPopup = (arr, rows) => {
    const arrayOfNewRows = [];
    //adjustRows
    rows.forEach((item) => {
      arrayOfNewRows.push({
        ...item,
        PydSeq: item.PydSeq ? item.PydSeq : undefined,
        PyhSeq: item.PyhSeq ? item.PyhSeq : undefined,
        InvhSeq: item.InvhSeq,
        InvdSeq: item.InvdSeq,
        CurCode: item.CurCode,
        CurRate: item.CurRate,
        Paid: item.Paid,
        PaidBAmt: item.PaidBAmt,
        TotalPrice: item.TotalPrice,
        Info: item.Info ? item.Info : item,
        IsPaid: item.IsPaid,
      });
    });

    //selected
    const selected = arrayOfNewRows.filter((item) => item.IsPaid);
    //unselect
    const unselect = arrayOfNewRows.filter((item) => !item.IsPaid);
    arr.Detail = [...selected];
    setUnselectInvoice(unselect);

    if (arr.Items.length > 0) {
      const sumInvdAbfTax = arr.Detail.reduce((accu, item) => {
        let paidBAmt = ToNumber(item.PaidBAmt);
        const taxAmt1 = item.Info?.InvoiceDetail?.InvdTaxC1;
        if (taxAmt1 === 0) {
          paidBAmt = 0;
        }
        const s = ToNumber(accu) + ToNumber(paidBAmt);
        return s;
      }, 0);

      let sugAmt = NumberFormat((sumInvdAbfTax * 100) / 107);

      arr.Items.forEach((i) => {
        i.SuggestionAmt = sugAmt;
        i.Amount = sugAmt;
        i.TaxAmt = NumberFormat((ToNumber(sugAmt) * i.WhtRate) / 100);
      });

      const sumWhtAmt = arr.Items.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.TaxAmt);
        return NumberFormat(s ?? 0);
      }, 0);
      arr.WhtTotalAmt = sumWhtAmt;
      setInitData((state) => ({
        ...state,
        WhtTotalAmt: sumWhtAmt,
        Items: [...arr.Items],
      }));
    }

    CalculatePayment(arr, "afterselect");
    setShowAdd(false);
  };

  const CancelFromPopup = (resetData) => {
    setInitData((state) => ({
      ...state,
      Detail: resetData,
    }));
    setShowAdd(false);
  };

  const CalculatePayment = (data, checkAfterSelectSettle) => {
    if (data) {
      const sumPaidAmt = data.Detail.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.Paid);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumPaidBAmt = data.Detail.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.PaidBAmt);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumInvdAbfTax = data.Detail.reduce((accu, item) => {
        let paidBAmt = ToNumber(item.PaidBAmt);
        const taxAmt1 = item.Info?.InvoiceDetail?.InvdTaxC1;
        if (taxAmt1 === 0) {
          paidBAmt = 0;
        }
        const s = ToNumber(accu) + ToNumber(paidBAmt);
        return s;
      }, 0);

      const numInvAmt = ToNumber(sumPaidBAmt);
      let numCurAmt = 0;
      if (SettingSystem.DefaultCurrencyCode === methods.watch("CurCode")) {
        numCurAmt = ToNumber(sumPaidBAmt);
      } else {
        numCurAmt = ToNumber(sumPaidAmt) * ToNumber(data.CurRate);
      }
      const numWhtAmt = ToNumber(data?.WhtTotalAmt ?? 0);
      if (checkAfterSelectSettle) {
        data.CurAmt = numCurAmt;
        data.CurPayAmt = NumberFormat(numInvAmt);
      }
      data.GainLossAmt = NumberFormat(numInvAmt - numCurAmt);
      data.PyhAmt = NumberFormat(numCurAmt - numWhtAmt);
      data.PyhAbfTax = NumberFormat(numCurAmt);

      let sugAmt = NumberFormat((sumInvdAbfTax * 100) / 107);

      setInitNewWhtRow((state) => ({ ...state, SuggestionAmt: sugAmt }));
      setInitData(data);
    }
  };

  const AdjustPaymentAmount = (numInvAmt, numCurAmt, numWhtAmt) => {
    const numCurRate = ToNumber(methods.watch("CurRate"));
    const newCurAmt = ToNumber(numCurAmt);
    setInitData((state) => ({
      ...state,
      CurRate: numCurRate,
      CurAmt: numInvAmt,
      PyhAbfTax: newCurAmt,
      CurPayAmt: numInvAmt,
      GainLossAmt: NumberFormat(numInvAmt - newCurAmt),
      PyhAmt: NumberFormat(newCurAmt - numWhtAmt),
    }));
    if (numInvAmt - newCurAmt !== 0) {
      CheckGLAccount(numInvAmt - newCurAmt);
      CheckWhtAccount();
    } else {
      methods.clearErrors("GainLossDept");
      methods.clearErrors("GainLossAcc");
    }
  };

  const UpdateForm = (e) => {
    const values = getValues();
    if (values.VnCode !== "") {
      methods.clearErrors();
    }

    const vnCode = getValues("VnCode");
    if (vnCode && vnCode !== initData.VnCode) {
      const vendorItem = vendorList.find((item) => item.VnCode == values.VnCode);
      const whtItem = apWhtList.find((i) => i.Description === vendorItem.VnWhtCode);
      if (vendorItem?.VnWhtTypeCode) {
        CheckWhtAccount();
      }
      setInitData((state) => ({
        ...state,
        VnCode: vnCode,
        Detail: [],
        CurAmt: 0,
        PyhAbfTax: 0,
        CurPayAmt: 0,
        GainLossAmt: 0,
        PyhAmt: 0,
        Items: vendorItem.VnWhtCode
          ? [
              {
                Id: 0,
                PyhSeq: 0,
                WhtCode: whtItem?.WhtCode ?? "",
                WhtDesc: whtItem?.Description ?? "",
                WhtRate: vendorItem?.VnWhtRate ?? 0,
                SuggestionAmt: 0,
                Amount: 0,
                TaxAmt: 0,
              },
            ]
          : [],
      }));
    }

    if (e.target.name === "CurRate") {
      const sumPaidAmt = initData.Detail.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.Paid);
        return NumberFormat(s ?? 0);
      }, 0);
      const sumPaidBAmt = initData.Detail.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.PaidBAmt);
        return NumberFormat(s ?? 0);
      }, 0);
      const numInvAmt = ToNumber(sumPaidBAmt);
      const numCurAmt = ToNumber(sumPaidAmt);
      const numWhtAmt = ToNumber(initData?.WhtTotalAmt ?? 0);
      const numPayAmt = ToNumber(numCurAmt * ToNumber(e.target.value));
      AdjustPaymentAmount(numInvAmt, numPayAmt, numWhtAmt);
    }
  };

  const UpdateFormWht = async (e) => {
    const values = getValues();
    const whtType = values.WhtTypeCode ?? "";
    if (whtType !== "") {
      methods.clearErrors("TaxId");
      methods.clearErrors("BranchNo");
      methods.clearErrors("Payee");
      methods.clearErrors("Address");
      methods.clearErrors("WhtRemark1");
    }
    if (e.target.name === "TaxId") {
      methods.clearErrors("TaxId");
    }
    if (e.target.name === "BranchNo") {
      methods.clearErrors("BranchNo");
    }
    if (e.target.name === "Payee") {
      methods.clearErrors("Payee");
    }
    if (e.target.name === "Address") {
      methods.clearErrors("Address");
    }
    if (e.target.name === "WhtRemark1") {
      methods.clearErrors("WhtRemark1");
    }

    //set some input readonly
    if (e.target.name === "WhtNoAuto") {
      if (e.target.value === "true") {
        document.getElementsByName("WhtNo")[0].parentNode.parentNode.firstChild.classList.remove("Mui-disabled");
        document.getElementsByName("WhtNo")[0].parentNode.classList.remove("Mui-disabled");
        document.getElementsByName("WhtNo")[0].disabled = false;
        document.getElementsByName("WhtNo")[0].focus();
        setValue("WhtNo", "");
      } else {
        document.getElementsByName("WhtNo")[0].parentNode.parentNode.firstChild.classList.add("Mui-disabled");
        document.getElementsByName("WhtNo")[0].parentNode.classList.add("Mui-disabled");
        document.getElementsByName("WhtNo")[0].disabled = true;
        setValue("WhtNo", "");
      }
    }
  };

  const CheckGLAccount = (glAmt) => {
    const vGlDept = getValues("GainLossDept");
    const vGlAcc = getValues("GainLossAcc");
    const gainLossAmt = glAmt ? ToNumber(glAmt) : ToNumber(initData.GainLossAmt);
    if (gainLossAmt > 0 || gainLossAmt < 0) {
      if (!!vGlDept === false && ToNumber(gainLossAmt) !== 0) {
        methods.setError("GainLossDept", {
          type: "required",
          message: "* Required",
        });
      }
      if (!!vGlAcc === false && ToNumber(gainLossAmt) !== 0) {
        methods.setError("GainLossAcc", {
          type: "required",
          message: "* Required",
        });
        return false;
      }
      if (!!vGlDept === true) {
        methods.clearErrors("GainLossDept");
      }
      if (!!vGlAcc === true) {
        methods.clearErrors("GainLossAcc");
        return true;
      }
    } else {
      return true;
    }
  };

  const CheckWhtAccount = () => {
    const values = getValues();
    const whtDept = values.WhtDeptCode;
    const whtAcc = values.WhtTaxCr;
    const whtType = values.WhtTypeCode ?? "";
    const whtAmt = ToNumber(initData?.WhtTotalAmt);

    if (whtAmt !== 0) {
      if (values.TaxId === "" || values.TaxId === null) {
        methods.setError("TaxId", {
          type: "required",
          message: "* Required",
        });
      } else if (values.BranchNo === "" || values.BranchNo === null) {
        methods.setError("BranchNo", {
          type: "required",
          message: "* Required",
        });
      } else if (values.Payee === "" || values.Payee === null) {
        methods.setError("Payee", {
          type: "required",
          message: "* Required",
        });
      } else if (values.Address === "" || values.Address === null) {
        methods.setError("Address", {
          type: "required",
          message: "* Required",
        });
      } else if (!!whtDept === false) {
        methods.setError("WhtDeptCode", {
          type: "required",
          message: "* Required",
        });
      } else if (!!whtAcc === false) {
        methods.setError("WhtTaxCr", {
          type: "required",
          message: "* Required",
        });
      }
    }

    if (whtAmt !== 0) {
      if (values.WhtRemark1 === "" || values.WhtRemark1 === null) {
        methods.setError("WhtRemark1", {
          type: "required",
          message: "* Required",
        });
      } else {
        return true;
      }
      return false;
    }

    if (!!whtDept) {
      methods.clearErrors("WhtDeptCode");
    }
    if (!!whtAcc) {
      methods.clearErrors("WhtTaxCr");
    }
    if (whtType === null || whtType === "" || whtAmt === 0) {
      methods.clearErrors("TaxId");
      methods.clearErrors("BranchNo");
      methods.clearErrors("Payee");
      methods.clearErrors("Address");
      methods.clearErrors("WhtRemark1");
      return true;
    }
    // if (!!whtType) {
    //   methods.clearErrors("WhtTypeCode");
    //   return true;
    // }
    return true;
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        display: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "InvhInvNo",
      label: "Invoice No.",
      options: {
        sort: false,
      },
    },
    {
      name: "InvhInvDate",
      label: "Date",
      // options: {
      //   sort: false,
      //   customBodyRender: (value) => {
      //     return DateToString(value);
      //   },
      // },
    },
    {
      name: "InvdDesc",
      label: "Description",
      options: {
        sort: false,
      },
    },
    {
      name: "CurCode",
      label: "Currency",
      options: {
        sort: false,
      },
    },
    {
      name: "CurRate",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "currency");
        },
      },
    },
    {
      name: "Info",
      label: "Net Amount",
      options: {
        id: "NetAmount",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return typeof value === "object" ? NumberFormat(value?.InvoiceDetail?.NetBaseAmt) : "";
        },
      },
    },
    {
      name: "Info",
      label: "Tax Amount",
      options: {
        id: "TaxAmount",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return typeof value === "object"
            ? NumberFormat(value?.InvoiceDetail.InvdTaxC1 + value?.InvoiceDetail.InvdTaxC2)
            : "";
        },
      },
    },
    {
      name: "Info",
      label: "Total",
      options: {
        id: "Total",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return typeof value === "object" ? NumberFormat(value?.TotalPrice) : "";
        },
      },
    },
    {
      name: "Paid",
      label: "Paid",
      options: {
        id: "Paid",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "PaidBAmt",
      label: "Base Amt.",
      options: {
        id: "PaidBAmt",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    tableId: "detail",
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "300px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      let sumNetAmount = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[6]);
        return NumberFormat(s ?? 0);
      }, 0);
      let sumTaxAmount = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[7]);
        return NumberFormat(s ?? 0);
      }, 0);
      let sumAmount = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);
      let sumPaid = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      let sumBase = opts.data.reduce((accu, item) => {
        let s = ToNumber(accu) + ToNumber(item.data[10]);
        return NumberFormat(s ?? 0);
      }, 0);

      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col) => {
              if (col.display === "true") {
                switch (col.label) {
                  case "Net Amount":
                    return (
                      <TableCell key={col.label?.toString()} className={footerClasses}>
                        {sumNetAmount}
                      </TableCell>
                    );
                  case "Tax Amount":
                    return (
                      <TableCell key={col.label?.toString()} className={footerClasses}>
                        {sumTaxAmount}
                      </TableCell>
                    );
                  case "Total":
                    return (
                      <TableCell key={col.label?.toString()} className={footerClasses}>
                        {sumAmount}
                      </TableCell>
                    );
                  case "Paid":
                    return (
                      <TableCell key={col.label?.toString()} className={footerClasses}>
                        {sumPaid}
                      </TableCell>
                    );
                  case "Base Amt.":
                    return (
                      <TableCell key={col.label?.toString()} className={footerClasses}>
                        {sumBase}
                      </TableCell>
                    );
                  default:
                    return <TableCell key={col.label?.toString()} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
  };

  const columnsWht = [
    {
      name: "Id",
      label: " ",
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Icon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => UpdateWhtRow(value)}
              >
                edit
              </Icon>
              <Icon
                fontSize="small"
                color="primary"
                style={{ cursor: "pointer", marginLeft: 10 }}
                onClick={() => {
                  initData.Items.splice(tableMeta.rowIndex, 1);
                  if (initData.Items.length > 0) {
                    const sumWhtAmt = initData.Items.reduce((accu, item) => {
                      const s = ToNumber(accu) + ToNumber(item.TaxAmt);
                      return NumberFormat(s ?? 0);
                    }, 0);
                    initData.WhtTotalAmt = sumWhtAmt;
                  } else {
                    initData.WhtTotalAmt = 0;
                  }
                  CalculatePayment(initData);
                  setInitData(initData);
                  CheckWhtAccount();
                }}
              >
                delete
              </Icon>
            </>
          );
        },
      },
    },
    {
      name: "WhtCode",
      label: "WHT. Service Type",
    },
    {
      name: "WhtDesc",
      label: "Description",
    },
    {
      name: "WhtRate",
      label: "Tax Rate %",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "Amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "TaxAmt",
      label: "Tax",
      options: {
        id: "Tax",
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
  ];

  const optionsWht = {
    tableId: "detailWht",
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "200px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      const sumTaxamt = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[5]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                if (col.label === "Tax") {
                  return (
                    <TableCell key={col.label?.toString()} className={footerClasses}>
                      {sumTaxamt}
                    </TableCell>
                  );
                } else {
                  return <TableCell key={col.label?.toString()} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.Id);
      for (let i = removeArray.length - 1; i >= 0; i--) initData.Items.splice(removeArray[i], 1);
    },
  };

  const AddNewWhtRow = () => {
    setEditWhtIndex("");
    setShowWhtService(true);
  };

  const UpdateWhtRow = (value) => {
    setEditWhtIndex(value);
    setShowWhtService(true);
  };

  const SaveFromPopupWht = (arr, row) => {
    if (editWhtIndex !== "") {
      //update
      arr.Items[editWhtIndex] = row;
    } else {
      //create
      if (arr.Items) {
        row.index = arr.Items.length;
        arr.Items = [...arr.Items, row];
      }
    }
    if (arr.Items.length > 0) {
      const sumWhtAmt = arr.Items.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.TaxAmt);
        return NumberFormat(s ?? 0);
      }, 0);
      arr.WhtTotalAmt = sumWhtAmt;
      setInitData((state) => ({
        ...state,
        WhtTotalAmt: sumWhtAmt,
        Items: [...arr.Items],
      }));
    } else {
      arr.WhtTotalAmt = 0;
    }
    CalculatePayment(arr);
    CheckWhtAccount();
    setShowWhtService(false);
  };

  const CancelFromPopupWht = () => {
    setShowWhtService(false);
  };

  const CustomHeaderWht = (props) => {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell colSpan={6} style={{ paddingLeft: 0 }}>
              <Button variant="outlined" onClick={AddNewWhtRow} startIcon={<Icon>add</Icon>}>
                WHT. Service Type
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <MuiTableHead {...props} />
      </>
    );
  };

  const Save = async (values) => {
    //check payment amount > 0
    if (values.PyhAmt < 0) {
      SnackbarUtils.warning("Net Payment can't be negative value.");
      setBtnLoading(false);
      return;
    }

    if (values.Detail.length > 0) {
      //Validate & CheckDetail
      values.Pyhstatus = "Effective";
      const { Code, InternalMessage, UserMessage } = await createPaymentDetail(values);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect(`${InternalMessage}/show`);
        });
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    } else {
      SnackbarUtils.info(translate("ra.info.notransaction"));
      setBtnLoading(false);
      return;
    }
  };

  // const ShowDim = (values) => {
  //   if (!values) {
  //     setDataDim(initData.DimHList.Dim);
  //     setOpenDim(!openDim);
  //   } else {
  //     setDataDim(values);
  //     setOpenDim(!openDim);
  //   }
  // };

  const CancelFnc = () => {
    redirect("list", basePath);
  };

  const OnChangeWht = (field, m, data) => {
    const WhtRate = ToNumber(data["WhtRate"]);
    const Amount = ToNumber(data["Amount"]);
    const calWht = (Amount * WhtRate) / 100;

    if (field !== "TaxAmt") {
      m.setValue("TaxAmt", NumberFormat(calWht));
    }
  };

  return (
    <div
    // className={clsx(classes.drawer, {
    //   [classes.drawerOpen]: openDim,
    //   [classes.drawerClose]: !openDim,
    // })}
    >
      <ActionMenu menuControl={menuControlProp} />

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Paper className={classes.root}>
          <BoxHeader header={"Payment"} status={initData.PyhStatus} />
          <Grid container alignItems="flex-start" spacing={1}>
            {formFields
              ? formFields.map((item) => (
                  <Grid item xs={item.size} key={item.field.props.name?.toString()} style={item.style}>
                    {React.createElement(item.field.type, {
                      ...{
                        ...item.field.props,
                        methods,
                        key: item.field.props.name,
                        onChange: UpdateForm,
                      },
                    })}
                  </Grid>
                ))
              : ""}
          </Grid>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              <MuiTranslateTable
                data={initData.Detail}
                columns={columns}
                options={options}
                components={{
                  TableHead: CustomHeader,
                }}
              />
              {showAdd && (
                <PopupSettlement
                  initialValues={initDataDetail.length === 0 ? initData.Detail : initDataDetail}
                  unselectInvoice={unselectInvoice}
                  code={getValues("VnCode")}
                  paymentDate={getValues("PyhDate")}
                  paymentCurCode={getValues("CurCode")}
                  paymentRate={getValues("CurRate")}
                  open={showAdd}
                  save={(row) => SaveFromPopup(initData, row)}
                  cancel={CancelFromPopup}
                  maxWidth={"xl"}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
        {/* Withholding Tax */}
        <Paper elevation={1} style={{ marginBottom: 6 }}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<Icon>expand_more</Icon>} aria-controls="panel1-content" id="panel1-header">
              <Typography className={classes.heading}>{translate("ra.field.Withholding Tax")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
                {formFieldsWht
                  ? formFieldsWht.map((item) => (
                      <Grid item xs={item.size} key={item.field.props.name?.toString()} style={item.style}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                            onChange: UpdateFormWht,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
                <Grid item xs={12}>
                  <MuiTranslateTable
                    data={initData.Items}
                    columns={columnsWht}
                    options={optionsWht}
                    components={{
                      TableHead: CustomHeaderWht,
                    }}
                  />
                </Grid>
                {showWhtService && (
                  <PopupTable
                    initialValues={editWhtIndex !== "" ? initData.Items[editWhtIndex] : initNewWhtRow}
                    formFields={formFieldsWhtDetail}
                    update={OnChangeWht}
                    open={showWhtService}
                    save={(row) => SaveFromPopupWht(initData, row)}
                    cancel={CancelFromPopupWht}
                    maxWidth={"md"}
                  />
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Paper>
        {/* Payment Account */}
        <Paper elevation={1} className={classes.root}>
          <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
            <Grid item xs={8} />
            <Grid item xs={4}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ marginTop: 8 }}>
                    {translate("ra.field.Payment Amount")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {/* <TextField
										name="CurAmt"
										variant="outlined"
										margin="dense"
										InputProps={{
											inputComponent: NumberFormatInput,
										}}
										inputProps={{
											style: { textAlign: "right" },
											maxLength: 18,
											decimal: 2,
										}}
										defaultValue={0}
										value={initData.CurAmt}
										onChange={(e) => {
											const numInvAmt = ToNumber(sumInvAmt);
											const numCurAmt = e.target.value;
											const numWhtAmt = ToNumber(
												initData.PayWht?.WhtTotalAmt ?? 0,
											);
											AdjustPaymentAmount(numInvAmt, numCurAmt, numWhtAmt);
										}}
										
										fullWidth
									/> */}
                  <Typography variant="body1" align="right" style={{ marginRight: 14 }}>
                    {NumberFormat(initData.CurAmt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8}>
              <Grid container spacing={1} alignItems="center">
                {formFieldsPaymentAccount
                  ? formFieldsPaymentAccount.map((item) => (
                      <Grid item xs={item.size} key={item.field.props.name?.toString()}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 10 }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="body2"> {translate("ra.field.Settlement Base Amount")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right" style={{ marginRight: 14 }}>
                    {NumberFormat(initData.CurPayAmt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8}>
              <Grid container spacing={1} alignItems="center">
                {formFieldsGainLossAccount
                  ? formFieldsGainLossAccount.map((item) => (
                      <Grid item xs={item.size} key={item.field.props.name?.toString()}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 10 }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="body2">{translate("ra.field.Gain/Loss Amount")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right" style={{ marginRight: 14 }}>
                    {NumberFormat(initData.GainLossAmt ?? 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8}>
              <Grid container spacing={1} alignItems="center">
                {formFiledWhtAccount
                  ? formFiledWhtAccount.map((item) => (
                      <Grid item xs={item.size} key={item.field.props.name?.toString()}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                            onChange: CheckWhtAccount,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 10 }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="body2">{translate("ra.field.Withholding Tax")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right" style={{ marginRight: 14 }}>
                    {NumberFormat(initData?.WhtTotalAmt ?? 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8}>
              <Grid container spacing={1} alignItems="center" />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6} style={{ margin: "8px 0 8px 0" }}>
                  <Typography variant="body2">
                    <b>{translate("ra.field.Net Payment")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right" style={{ marginRight: 14 }}>
                    <b>{NumberFormat(initData.PyhAmt)}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(initData, 0, 2) : ""}</pre>
        <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />
      </form>
    </div>
  );
};

export default Create;
