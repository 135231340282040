/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useForm } from "react-hook-form";
import { Grid, Typography } from "@material-ui/core";
import { TextFieldInForm } from "components/Form";
import ButtonFooter from "components/ButtonFooter";
import { getUserByUserName, updatePassword } from "services/setting";
import SnackbarUtils from "utils/SnackbarUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20,
  },
  content: {
    padding: 4,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ChangePasswordDialog = (props) => {
  const classes = useStyles();
  const { onClose, open } = props;
  const [userId, setUserId] = useState();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [data, setData] = useStateWithCallbackLazy({
    OldPassword: "",
    NewPassword: "",
    UserModified: "",
  });
  const [isBtnLoading, setBtnLoading] = useState(false);
  const methods = useForm({ defaultValues: data });

  const { handleSubmit } = methods;

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  React.useEffect(async () => {
    let username = localStorage.getItem("UserName");
    const r = await getUserByUserName(username);
    if (r.Data) {
      setUserId(r.Data[0].User.UserId);
    }
  }, []);

  const onSubmit = async (values) => {
    setBtnLoading(true);
    //Adjust parameter before save
    setData(
      (state) => ({
        ...state,
        ...values,
        UserModified: "dev",
      }),
      (nextState) => Save(nextState)
    );
  };

  const Save = async (v) => {
    let param = {
      OldPassword: v.OldPassword,
      NewPassword: v.NewPassword,
      UserModified: v.UserModified,
    };

    const { Code, InternalMessage, UserMessage } = await updatePassword(userId, param);
    if (Code === 0) {
      SnackbarUtils.success(UserMessage);
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      if (InternalMessage) {
        SnackbarUtils.error(InternalMessage);
      } else {
        SnackbarUtils.warning(UserMessage);
      }
    }
  };

  const CancelFnc = () => {
    onClose();
  };

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          onClose(event);
        }
      }}
      scroll={"paper"}
    >
      <DialogTitle id="scroll-dialog-title" onClose={onClose}>
        Change Password
      </DialogTitle>

      <DialogContent dividers>
        <form onKeyDown={disableFormEnter} autoComplete="off">
          <ul>
            <li>
              <p>Use 8 characters or more for your password</p>
            </li>
            <li>
              <p>Use a mix of letters (uppercase and lowercase), numbers, and symbols.</p>
            </li>
            <li>
              <p>Avoid predictable passwords such as "password", "12345".</p>
            </li>
          </ul>
          <div className={classes.root}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12}>
                <TextFieldInForm
                  variant="outlined"
                  margin="dense"
                  required
                  type={showOldPassword ? "text" : "password"}
                  id="OldPassword"
                  label="Current Password"
                  name="OldPassword"
                  autoComplete="off"
                  methods={methods}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ marginRight: 6 }}
                          aria-label="toggle password visibility"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldInForm
                  variant="outlined"
                  margin="dense"
                  required
                  type={showPassword ? "text" : "password"}
                  id="NewPassword"
                  label="New Password"
                  name="NewPassword"
                  autoComplete="off"
                  methods={methods}
                  rule={{
                    validate: {
                      checkPass: (value) => {
                        var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                        if (!re.test(value)) {
                          return "Please choose a stronger password. Try a mix of letters, numbers, and symbols.";
                        }
                        return true;
                      },
                    },
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ marginRight: 6 }}
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldInForm
                  variant="outlined"
                  margin="dense"
                  required
                  type={showRePassword ? "text" : "password"}
                  id="rePassword"
                  label="Confirm Password"
                  name="RePassword"
                  autoComplete="off"
                  methods={methods}
                  rule={{
                    validate: {
                      checkPass: (value) => {
                        var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                        if (!re.test(value)) {
                          return "Please choose a stronger password. Try a mix of letters, numbers, and symbols.";
                        }
                        return true;
                      },
                      confirm: (value) => value === methods.watch("NewPassword") || "The passwords do not match",
                    },
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ marginRight: 6 }}
                          aria-label="toggle password visibility"
                          onClick={() => setShowRePassword(!showRePassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showRePassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonFooter noBorder disabled={isBtnLoading} SaveFnc={handleSubmit(onSubmit)} CancelFnc={CancelFnc} />
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
