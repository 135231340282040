/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AccessDenied from "layout/AccessDenied";
import { Box, Grid, FormControl, Select, InputLabel, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PermissionList from "./PermissionList";
import { getUserSearchList, getTenantByUserName, getUserListByTenant } from "services/setting";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    padding: theme.spacing(2),
  },
  tabPanel: { width: "100%", margin: "0 20px" },
  details: {
    display: "flex",
    alignItems: "center",
  },
  content: {
    flex: "1 0 auto",
  },
  contentEnd: {
    marginTop: "18px",
    paddingBottom: "16px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  atlarge: {
    margin: theme.spacing(2),
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  cover: {
    width: 151,
  },
  status: {
    display: "flex",
    //alignItems: "center",
    marginTop: theme.spacing(2),
  },
  menus: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    marginRight: theme.spacing(2),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  statusActive: {
    backgroundColor: "green",
    color: "white",
  },
  formControl: {
    margin: theme.spacing(0.5),
    minWidth: 120,
  },
}));

const Permission = (props) => {
  const { permissions, value, index } = props;

  let checkPermission = permissions && permissions.find((i) => i.Name === "Sys.User")?.View;
  const translate = useTranslate();
  const classes = useStyles();
  const [user, setUser] = useState(localStorage.getItem("UserName") ?? "admin");
  const [bu, setBu] = useState("");
  const [userList, setUserList] = useState([]);
  const [buList, setBuList] = useState([]);

  const fetchUserActiveList = async () => {
    let uriQueryString = {
      WhereRaw: "Active = true",
    };
    const ul = await getUserSearchList(uriQueryString);
    if (ul?.Code === 403) {
      return;
    }
    if (ul) {
      let arrUsername = ul.Data.map((i) => i.UserName);
      if (localStorage.getItem("UserName") !== "admin") {
        arrUsername = arrUsername.filter((i) => i !== "admin");
      }
      setUserList(arrUsername);
    } else {
      setUserList([]);
    }
  };

  const fetchTenantActiveList = async (user) => {
    const tl = await getTenantByUserName(user);
    if (tl) {
      let arrTenant = tl;
      if (arrTenant.length > 0) {
        let currentBu = arrTenant.find((i) => i.Tenant === localStorage.getItem("Tenant"));
        if (currentBu) {
          setBu(currentBu);
        } else {
          setBu(arrTenant[0]);
        }
      }
      setBuList(arrTenant);
    } else {
      setBuList([]);
    }
  };

  React.useEffect(() => {
    if (value === index) {
      fetchUserActiveList();
      fetchTenantActiveList(user);
    }
  }, [value, user]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
    >
      {checkPermission && value === index ? (
        <>
          <Grid container justifyContent={"center"} spacing={1}>
            <Grid item xs={4}>
              <Box p={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lUser">{translate("ra.field.Username")}</InputLabel>
                  <Select
                    labelId="lUser"
                    label={translate("ra.field.Username")}
                    variant="outlined"
                    margin="dense"
                    value={user}
                    onChange={(e) => {
                      setUser(e.target.value);
                    }}
                  >
                    {userList?.length > 0 &&
                      userList.map((item, idx) => (
                        <MenuItem key={idx} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box p={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="lBu" style={{ marginTop: !bu ? -6 : 0 }}>
                    {translate("ra.field.Business Unit")}
                  </InputLabel>
                  <Select
                    labelId="lBu"
                    label={translate("ra.field.Business Unit")}
                    variant="outlined"
                    margin="dense"
                    value={bu}
                    onChange={async (e) => {
                      const objItem = e.target.value;
                      const r = await getUserListByTenant(objItem.Tenant);
                      let uList = r.map((i) => i.UserName);
                      if (localStorage.getItem("UserName") !== "admin") {
                        uList = uList.filter((i) => i !== "admin");
                      }
                      setUserList(uList);
                      setBu(objItem);
                    }}
                  >
                    {buList.map((item) => (
                      <MenuItem key={item.TenantId} value={item}>
                        {item.Description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {bu && <PermissionList {...props} bu={bu} user={user} />}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
};

export default Permission;
